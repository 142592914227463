import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  ToTopOutlined,
  InfoCircleOutlined,
  CloseCircleFilled,
  HistoryOutlined,
  PaperClipOutlined,
  InfoCircleFilled,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileExcelOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileOutlined,
  FileImageOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { VideoTime, ArchiveTick, FolderAdd, Convertshape } from "iconsax-react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Table,
  Tooltip,
  Collapse,
  Image,
  Descriptions,
  Divider,
  Space,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import {
  saveFile,
  statusRes,
  renderOption,
  MSG_CATCH,
  showMessage,
  showMessageChange,
  checkInteger,
  isImage,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classPopup from "../../models/control/popup";
import classText from "../../models/control/text";
import "./machine.scss";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/machineManagement";
import moment from "moment";
import BoxContent from "@src/component/Desktop/content";
import FooterPage from "../../component/footer/footerPage";
import { isEmpty, isNumber, isObject, isString } from "lodash";
import { checkRoleScreen } from "../../utils/roles";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx/xlsx.mjs";
import * as fs from "fs";
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
import { Readable } from "stream";
import queryString from "query-string";
import { useMediaQuery } from "react-responsive";
import { ColorPicker } from "@src/page/DynamicChartManagement/add";
import rolesConst from "@src/routers/roles/roles.const";

XLSX.set_fs(fs);
XLSX.stream.set_readable(Readable);
XLSX.set_cptable(cpexcel);

const { Text } = Typography;
const { Panel } = Collapse;

export function FormInput({
  box,
  text,
  required,
  labelSpan = 0,
  leftLabel,
  mlLabel,
  isPopup,
  ...props
}) {
  return (
    <Row align="top">
      <Col
        span={labelSpan}
        className={`pr10 ${!leftLabel ? "right" : ""} ${
          labelSpan >= 24 ? "left mb5" : ""
        } mt7`}
      >
        <label style={{ fontSize: isPopup && 12 }} className="row-input-label ">
          {props.name}
        </label>
        {required && <label className="blod  mark-required">*</label>}
      </Col>

      <Col
        span={labelSpan >= 24 ? 24 : 24 - labelSpan}
        className="row-input-col-2"
      >
        {props.children}
      </Col>
    </Row>
  );
}

const getIconByFileType = (name) => {
  if (isImage(name)) return <FileImageOutlined className="first-icon" />;
  const splitArray = name.split(".");
  const fileType = splitArray[splitArray.length - 1];

  switch (fileType) {
    case "zip":
    case "rar":
      return <FileZipOutlined className="first-icon" />;
    case "xlsx":
    case "xlsm":
    case "xls":
    case "xml":
      return <FileExcelOutlined className="first-icon" />;
    case "txt":
    case "csv":
      return <FileTextOutlined className="first-icon" />;
    case "pdf":
      return <FilePdfOutlined className="first-icon" />;
    case "docx":
    case "doc":
      return <FileWordOutlined className="first-icon" />;
    default:
      return <FileOutlined className="first-icon" />;
  }
};

export function FileAttachment({ item, index }) {
  const name = item.attachFileName.split(".");
  const fileType = name.pop();
  return (
    <>
      {getIconByFileType(item.attachFileName)}
      <a
        href={item.attachFileUrl}
        title={item.attachFileName}
        style={{ display: "flex" }}
      >
        <p className="text-1line mb0">{name}</p>
        <span>.{fileType}</span>
      </a>
    </>
  );
}

function AddMachine(props) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  // Màn bé hơn 1500
  const isScreenSmall = useMediaQuery({
    query: "(max-device-width: 1499px)",
  });

  let modeUpdate = null;
  const { id } = useParams();
  const { clone_id } = queryString.parse(props.location.search);
  modeUpdate = id || clone_id;
  const history = useHistory();
  //Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => setShowLoading(true);
  const closeLoadingSpinner = () => setShowLoading(false);

  const [machineData, setMachineData] = useState({});
  const [machineForSiteOfficeName, setMachineForSiteOfficeName] = useState(
    new classText()
  );
  const [manufactureName, setManufactureName] = useState(new classText());
  const [facility, setFacility] = useState(new classDdl());
  const [building, setBuilding] = useState(new classDdl());
  const [floor, setFloor] = useState(new classDdl());
  const [room, setRoom] = useState(new classDdl());
  const [process, setProcess] = useState(new classDdl());
  const [modelNumber, setModelNumber] = useState(new classText());
  const [seriNumber, setSeriNumber] = useState(new classText());
  const [ddlStatusMachine, setDdlStatusMachine] = useState(
    new classDdl(null, lang.MACHINE_STATUS_VALUE)
  );
  const [applyDate, setApplyDate] = useState(new classText());
  const [specification, setSpecification] = useState(new classText());
  const [serviceLife, setServieLife] = useState(new classText());
  const [drawingNumber, setDrawingNumber] = useState(new classText());
  const [made, setMade] = useState(new classText());
  const [using, setUsing] = useState(new classText());
  const [serviceLifeInHouse, setServiceLifeInHouse] = useState(new classText());
  const [yearOfManufacture, setYearOfManufacture] = useState(new classText());
  const [duability, setDuability] = useState(new classText());
  const [replacementDisposalYear, setReplacementDisposalYear] = useState(
    new classText()
  );
  const [condition, setCondition] = useState(new classText());
  const [remark, setRemark] = useState(new classText());
  const fileMachineRef = useRef(null);
  const [listAttachMachine, setListAttachMachine] = useState([]);
  const [dataSource, setDataSource] = useState([
    {
      key: Date.now(),
      checkItemCode: "",
      checkItemName: "",
      controlValueLow: "",
      controlValueUpper: "",
      easyDriveLow: "",
      easyDriveUpper: "",
      inputFormat: "",
      unit: "",
      selection: "",
      inspectionFryquency: "",
    },
  ]);
  const [listMaintenance, setListMaintenance] = useState([
    {
      key: Date.now(),
      machineForSiteOfficeCode: "",
      dateImplementation: "",
      division: "",
      overview: "",
      workContent: "",
      contractor: "",
      listAttachment: [],
    },
  ]);
  const [listPlanMaintenance, setListPlanMaintenance] = useState([
    {
      key: Date.now(),
      machineForSiteOfficeCode: "",
      dateImplementation: "",
      division: "",
      overview: "",
      workContent: "",
      contractor: "",
      repairFrequency: "",
    },
  ]);
  //Modal
  const [popupUpdate, setPopupUpdate] = useState(new classPopup());
  const [updateContent, setUpdateContent] = useState(new classText());
  function showPopupUpdate() {
    // Show popup
    setPopupUpdate({ ...popupUpdate, show: true });
  }
  function hidePopupUpdate() {
    // Show popup
    setPopupUpdate({ ...popupUpdate, show: false });
  }

  const [popupAttach, setPopupAttach] = useState(new classPopup());
  const fileEl = useRef(null);
  const fileElUpload = useRef(null);
  const location = useLocation();
  const { allowRoute, roles } = useSelector((state) => state.auth);

  async function readAttachFileUrl(e) {
    const file = e.target.files[0];
    if (!file) return;
    const fileRes = await uploadFile(file, 4);

    const newListAttachMachine = [...listMaintenance];
    const this_maintenance = newListAttachMachine.find(
      (x) => x.key == popupAttach.show
    );
    this_maintenance.listAttachment.push(fileRes);

    setListMaintenance(newListAttachMachine);
    fileEl.current.value = null;
  }

  const columnsAttach = [
    {
      title: "No",
      render: (_, _2, index) => {
        return <div className="center">{++index}</div>;
      },
    },
    {
      title: "プレビュー",
      dataIndex: "attachFileUrl",
      render: (url, row) => {
        return (
          <div className="center">
            <a href={url} title={row.attachFileName}>
              <PaperClipOutlined className="blue iconM" />
            </a>
          </div>
        );
      },
    },
    {
      title: "ファイル名",
      dataIndex: "attachFileName",
      render: (text) => {
        return <Text>{text}</Text>;
      },
    },
    {
      title: "削除",
      align: "center",
      render: (_, row) => {
        return (
          <DeleteOutlined
            className="iconM red"
            onClick={() => {
              const list = [...modal_attach_data].filter(
                (item) => item.attachFileUrl !== row.attachFileUrl
              );
              const new_list_maintenance = [...listMaintenance];
              const this_maintenance = new_list_maintenance.find(
                (x) => x.key == popupAttach.show
              );
              this_maintenance.listAttachment = list;

              setListMaintenance(new_list_maintenance);
            }}
          />
        );
      },
    },
  ];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const newListAttachMachine = json
          .filter((item) => {
            if (
              !item[lang.MAINTAINCE_DATE] &&
              !item[lang.MAINTAINCE_DIVISION] &&
              !item[lang.MAINTAINCE_OVERVIEW] &&
              !item[lang.MAINTAINCE_WORK_CONTENT] &&
              !item[lang.MAINTAINCE_PIC]
            )
              return false;
            return true;
          })
          .map((item, index) => {
            const key = Date.now() + index;
            const thisContractor = ddlClassification.options.find(x => {
              return x.label === item[lang.MAINTAINCE_PIC]
            });
            return {
              key,
              machineForSiteOfficeCode: "",
              dateImplementation: item[lang.MAINTAINCE_DATE]
                ? moment(item[lang.MAINTAINCE_DATE])
                : null,
              division: item[lang.MAINTAINCE_DIVISION],
              overview: item[lang.MAINTAINCE_OVERVIEW],
              workContent: item[lang.MAINTAINCE_WORK_CONTENT],
              contractor: thisContractor ? thisContractor.value : null,
              listAttachment: [],
            };
          });
        if (isEmpty(newListAttachMachine)) {
          showMessage(
            KEY.ERROR,
            "入力ファイルのフォーマットが間違っているので、確認してください。"
          );
          return;
        }
        setListMaintenance(
          [...listMaintenance.filter(filterEmptyDataSource)].concat(
            newListAttachMachine
          )
        );
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    fileElUpload.current.value = null;
  };
  const funcReset = (preV) => ({ ...preV, disabled: true, value: null });
  const getDetail = async () => {
    try {
      const res = await API.getMachine(modeUpdate);
      const data = res.data;

      if (!res.data) {
        history.push("/machineManagement");
        return;
      }
      setMachineData(data);
      setMachineForSiteOfficeName((preV) => ({
        ...preV,
        value: data.machineForSiteOfficeName,
      }));
      setManufactureName((preV) => ({ ...preV, value: data.manufactureName }));
      setModelNumber((preV) => ({ ...preV, value: data.modelNumber }));
      setSeriNumber((preV) => ({ ...preV, value: data.sn }));
      setFacility((preV) => ({ ...preV, value: data.facilityCode }));
      setBuilding((preV) => ({ ...preV, value: data.buildingCode }));
      setFloor((preV) => ({ ...preV, value: data.floorCode }));
      setRoom((preV) => ({ ...preV, value: data.roomCode }));
      setProcess((preV) => ({ ...preV, value: data.processId }));
      setDdlStatusMachine({ ...ddlStatusMachine, value: data.status });
      setApplyDate((preV) => ({
        ...preV,
        value: data.applyDate ? moment(data.applyDate) : null,
      }));
      setSpecification((preV) => ({ ...preV, value: data.specification }));
      setSpecification((preV) => ({ ...preV, value: data.specification }));
      setServieLife((preV) => ({ ...preV, value: data.serviceLife }));
      setDrawingNumber((preV) => ({
        ...preV,
        value: data.drawingLineItemNumber,
      }));
      setMade((preV) => ({ ...preV, value: data.saiban }));
      setUsing((preV) => ({ ...preV, value: data.use }));
      setServiceLifeInHouse((preV) => ({ ...preV, value: data.enduranceYear }));
      setYearOfManufacture((preV) => ({
        ...preV,
        value: data.yearOfManufacture,
      }));
      setDuability((preV) => ({ ...preV, value: data.serviceTime }));
      setReplacementDisposalYear((preV) => ({
        ...preV,
        value: data.replacementDate ? moment(data.replacementDate) : null,
      }));
      setCondition((preV) => ({ ...preV, value: data.condition }));
      setRemark((preV) => ({ ...preV, value: data.note }));
      if (clone_id) {
        setMachineData({ ...data, imageUrl: "" });
        return;
      }
      setDataSource(
        (data.checkItems || []).map((x) => ({
          id: x.id,
          key: x.checkItemCode,
          checkItemCode: x.checkItemCode,
          checkItemName: x.checkItemName || "",
          controlValueLow: x.lowerControlValue,
          controlValueUpper: x.upperControlValue,
          easyDriveLow: x.normalLowerControlValue,
          easyDriveUpper: x.normalUpperControlValue,
          inputFormat: x.mode,
          unit: x.unit,
          selection: x.choices,
          inspectionFryquency: x.inspectionTiming,
        }))
      );
      setListAttachMachine(data.listAttachment || []);
      setListMaintenance(
        (data.listMaintenance || [])
          .sort((a, b) => a.dateImplementation > b.dateImplementation ? 1 : -1)
          .map((maintenance) => {
            return {
              ...maintenance,
              key: maintenance.id,
              dateImplementation: maintenance.dateImplementation
                ? moment(maintenance.dateImplementation * 1000)
                : null,
            };
          })
      );
      setListPlanMaintenance(
        (data.listPlanMaintenance || []).map((maintenance) => {
          return {
            ...maintenance,
            key: maintenance.id,
            dateImplementation: maintenance.dateImplementation
              ? moment(maintenance.dateImplementation * 1000)
              : null,
          };
        })
      );
    } catch (err) {
      history.push("/machineManagement");
    }
  };
  const inputFormatOptions = [
    {
      value: "数字",
      key: "数字",
    },
    {
      value: "選択",
      key: "選択",
    },
    {
      value: "文字",
      key: "文字",
    },
    {
      value: "無/有",
      key: "無/有",
    },
    {
      value: "備考",
      key: "備考",
    },
  ];
  const inspectionFryquencyOptions = [
    {
      value: "日",
      key: "1",
    },
    {
      value: "週",
      key: "2",
    },
    {
      value: "月",
      key: "3",
    },
    {
      value: "年",
      key: "4",
    },
  ];
  const onChangeFiledInputColum = (key, nameColums, newValue) => {
    const index = dataSource.findIndex((item) => item.key === key);
    const newObject = {
      [nameColums]: newValue,
    };

    if (nameColums === "inputFormat") {
      newObject["unit"] = "";
      newObject["selection"] = "";
    }
    const newDataSource = [
      ...dataSource.slice(0, index),
      { ...dataSource[index], ...newObject },
      ...dataSource.slice(index + 1),
    ];
    setDataSource(newDataSource);
  };
  const onChangeMaintaince = (key, nameColums, newValue) => {
    const index = listMaintenance.findIndex((item) => item.key === key);
    const newObject = {
      [nameColums]: newValue,
    };

    const newDataSource = [
      ...listMaintenance.slice(0, index),
      { ...listMaintenance[index], ...newObject },
      ...listMaintenance.slice(index + 1),
    ];
    setListMaintenance(newDataSource);
  };
  const onChangePlanMaintaince = (key, nameColums, newValue) => {
    const index = listPlanMaintenance.findIndex((item) => item.key === key);
    const newObject = {
      [nameColums]: newValue,
    };

    const newDataSource = [
      ...listPlanMaintenance.slice(0, index),
      { ...listPlanMaintenance[index], ...newObject },
      ...listPlanMaintenance.slice(index + 1),
    ];
    setListPlanMaintenance(newDataSource);
  };

  const [ddlClassification, setDdlClassification] = useState(new classDdl());
  const fetchClassification = async (value) => {
    try {
      const res = await API.getClassification();
      if (res && !isEmpty(res.data)) {
        setDdlClassification({
          ...ddlClassification,
          options: res.data.map((item) => ({
            ...item,
            label: item.classification,
            value: item.id,
          })),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      title: " ",
      dataIndex: "delete",
      width: "5%",
      render: (_, row) => {
        return (
          <Button
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRow(row.key)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.INSPECTION_TIME_NAME}
          <label className="blod  mark-required">*</label>
        </span>
      ),
      dataIndex: "checkItemName",
      width: "20%",
      align: "center",
      render: (_, row) => {
        return (
          <Input
            value={row.checkItemName}
            className={_ === null ? STYLE.BORDER_RED : ""}
            onChange={(e) =>
              onChangeFiledInputColum(row.key, "checkItemName", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          管理値
          <br />
          (下限値)
        </span>
      ),
      dataIndex: "controlValueLow",
      width: "9%",
      align: "center",

      render: (_, row) => {
        return (
          <Input
            type="number"
            value={row.controlValueLow}
            onChange={(e) =>
              onChangeFiledInputColum(
                row.key,
                "controlValueLow",
                e.target.value
              )
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          管理値
          <br />
          (上限値)
        </span>
      ),
      dataIndex: "controlValueUpper",
      width: "9%",
      align: "start",

      render: (_, row) => {
        return (
          <Input
            type="number"
            value={row.controlValueUpper}
            onChange={(e) =>
              onChangeFiledInputColum(
                row.key,
                "controlValueUpper",
                e.target.value
              )
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          運転めやす
          <br />
          (下限値)
        </span>
      ),
      dataIndex: "easyDriveLow",
      width: "9%",
      align: "start",
      render: (_, row) => {
        return (
          <Input
            type="number"
            value={row.easyDriveLow}
            onChange={(e) =>
              onChangeFiledInputColum(row.key, "easyDriveLow", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          運転めやす
          <br />
          (上限値)
        </span>
      ),
      dataIndex: "easyDriveUpper",
      width: "9%",
      align: "start",
      render: (_, row) => {
        return (
          <Input
            type="number"
            value={row.easyDriveUpper}
            onChange={(e) =>
              onChangeFiledInputColum(row.key, "easyDriveUpper", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.INPUT_FORMAT}
          <label className="blod  mark-required">*</label>
        </span>
      ),
      dataIndex: "inputFormat",
      width: "9%",
      align: "start",
      render: (_, row) => {
        return (
          <Select
            className={(_ === false ? STYLE.BORDER_RED : "") + " w100"}
            value={row.inputFormat}
            onChange={(v) => onChangeFiledInputColum(row.key, "inputFormat", v)}
          >
            {renderOption(inputFormatOptions, false)}
          </Select>
        );
      },
    },
    {
      title: lang.UNIT,
      dataIndex: "unit",
      align: "start",
      width: "10%",
      render: (_, row) => {
        return (
          <Input
            value={row.unit}
            disabled={
              !["文字", "数字"].some((mode) => mode === row.inputFormat)
            }
            onChange={(e) =>
              onChangeFiledInputColum(row.key, "unit", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <Tooltip title={"選択肢の間に「;」（半角）を入力します。"}>
          <span>{lang.SELECTION}</span>
        </Tooltip>
      ),
      dataIndex: "selection",
      width: "10%",
      align: "start",
      render: (_, row) => {
        return (
          <Tooltip title={"選択肢の間に「;」（半角）を入力します。"}>
            <Input
              value={row.selection}
              disabled={!["選択"].some((mode) => mode === row.inputFormat)}
              onChange={(e) =>
                onChangeFiledInputColum(row.key, "selection", e.target.value)
              }
            />
          </Tooltip>
        );
      },
    },
    {
      title: (
        <span>
          {lang.INSPECTION_FREQUENCY}
          <label className="blod  mark-required">*</label>
        </span>
      ),
      dataIndex: "inspectionFryquency",
      width: "7%",
      align: "start",
      render: (_, row) => {
        return (
          <Select
            className={(_ === false ? STYLE.BORDER_RED : "") + " w100"}
            value={row.inspectionFryquency}
            onChange={(v) =>
              onChangeFiledInputColum(row.key, "inspectionFryquency", v)
            }
          >
            {renderOption(inspectionFryquencyOptions, false)}
          </Select>
        );
      },
    },
  ];
  const column_maintaince_history = [
    {
      title: " ",
      dataIndex: "delete",
      width: "5%",
      render: (_, row) => {
        return (
          <Button
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRowMaintaince(row.key)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.MAINTAINCE_DATE}
          <label className="blod ml5 mark-required">*</label>
        </span>
      ),
      dataIndex: "dateImplementation",
      key: "dateImplementation",
      width: "165px",
      render: (_, row) => {
        return (
          <DatePicker
            value={moment(_).isValid() ? moment(_) : null}
            className={_ === false ? STYLE.BORDER_RED + " w100" : "w100"}
            disabledDate={(current) => current > moment().endOf("day")}
            placeholder={""}
            onChange={(v, dateString) => {
              onChangeMaintaince(row.key, "dateImplementation", v);
            }}
          />
        );
      },
    },
    {
      title: lang.MAINTAINCE_DIVISION,
      key: "division",
      width: "15%",
      dataIndex: "division",
      render: (_, row) => {
        return (
          <Input
            value={_}
            onChange={(e) =>
              onChangeMaintaince(row.key, "division", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <>
          {lang.MAINTAINCE_PIC}
          <label className="blod mark-required">*</label>{" "}
          <ButtonModalClassification callback={fetchClassification} />
        </>
      ),
      key: "contractor",
      width: "15%",
      dataIndex: "contractor",
      render: (_, row) => {
        return (
          <Select
            placeholder="区分"
            onChange={(value) => {
              onChangeMaintaince(row.key, "contractor", value);
            }}
            allowClear={true}
            value={_}
            className={_ === null ? STYLE.BORDER_RED + " w100" : "w100"}
          >
            {ddlClassification.options.map((item) => (
              <Select.Option key={item.id} value={item.value} style={{}}>
                <div
                  className="mr8"
                  style={{
                    width: 14,
                    height: 14,
                    borderRadius: "50%",
                    backgroundColor: item.color,
                    display: "inline-block",
                  }}
                ></div>
                {item.classification}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: (
        <span>
          {lang.MAINTAINCE_OVERVIEW}
          <label className="blod ml5 mark-required">*</label>
        </span>
      ),

      key: "overview",
      dataIndex: "overview",
      align: "left",
      render: (_, row) => {
        return (
          <Input
            className={_ === null ? STYLE.BORDER_RED + " w100" : "w100"}
            value={_}
            onChange={(e) =>
              onChangeMaintaince(row.key, "overview", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.MAINTAINCE_WORK_CONTENT}
          <label className="blod ml5 mark-required">*</label>
        </span>
      ),

      key: "workContent",
      dataIndex: "workContent",
      align: "left",
      render: (_, row) => {
        return (
          <TextArea
            className={_ === null ? STYLE.BORDER_RED + " w100" : "w100"}
            rows={1}
            value={_}
            onChange={(e) =>
              onChangeMaintaince(row.key, "workContent", e.target.value)
            }
          />
        );
      },
    },
    {
      title: lang.ATTACH,
      dataIndex: "listAttachment",
      key: "listAttachment",
      width: "105px",
      align: "center",
      render: (listAttachment, row, index) => (
        <Button
          className="buttonPC button--outline --todo-- wAuto"
          onClick={() => {
            setPopupAttach({ ...popupAttach, show: row.key });
          }}
        >
          {listAttachment.length} <PaperClipOutlined className="orange " />
        </Button>
      ),
    },
  ];
  const column_plan_maintaince_history = [
    {
      title: " ",
      dataIndex: "delete",
      width: "5%",
      render: (_, row) => {
        return (
          <Button
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRowPlanMaintaince(row.key)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.MAINTAINCE_DATE}
          <label className="blod ml5 mark-required">*</label>
        </span>
      ),
      dataIndex: "dateImplementation",
      key: "dateImplementation",
      width: "165px",
      render: (_, row) => {
        return (
          <DatePicker
            value={moment(_).isValid() ? moment(_) : null}
            className={_ === false ? STYLE.BORDER_RED + " w100" : "w100"}
            disabledDate={(current) => current < moment().startOf("day")}
            placeholder={""}
            onChange={(v, dateString) => {
              onChangePlanMaintaince(row.key, "dateImplementation", v);
            }}
          />
        );
      },
    },
    {
      title: lang.MAINTAINCE_DIVISION,
      key: "division",
      width: "15%",
      dataIndex: "division",
      render: (_, row) => {
        return (
          <Input
            value={_}
            onChange={(e) =>
              onChangePlanMaintaince(row.key, "division", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <>
          {lang.MAINTAINCE_PIC}
          <label className="blod mark-required">*</label>{" "}
          <ButtonModalClassification callback={fetchClassification} />
        </>
      ),
      key: "contractor",
      width: "15%",
      dataIndex: "contractor",
      render: (_, row) => {
        return (
          <Select
            className="w100"
            placeholder="区分"
            onChange={(value) => {
              onChangePlanMaintaince(row.key, "contractor", value);
            }}
            allowClear={true}
            value={_}
          >
            {ddlClassification.options.map((item) => (
              <Select.Option key={item.id} value={item.value} style={{}}>
                <div
                  className="mr8"
                  style={{
                    width: 14,
                    height: 14,
                    borderRadius: "50%",
                    backgroundColor: item.color,
                    display: "inline-block",
                  }}
                ></div>
                {item.classification}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: (
        <span>
          {lang.MAINTAINCE_OVERVIEW}
          <label className="blod ml5 mark-required">*</label>
        </span>
      ),

      key: "overview",
      dataIndex: "overview",
      align: "left",
      render: (_, row) => {
        return (
          <Input
            className={_ === null ? STYLE.BORDER_RED + " w100" : "w100"}
            value={_}
            onChange={(e) =>
              onChangePlanMaintaince(row.key, "overview", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.MAINTAINCE_WORK_CONTENT}
          <label className="blod ml5 mark-required">*</label>
        </span>
      ),
      key: "workContent",
      dataIndex: "workContent",
      align: "left",
      render: (_, row) => {
        return (
          <TextArea
            className={_ === null ? STYLE.BORDER_RED + " w100" : "w100"}
            rows={1}
            value={_}
            onChange={(e) =>
              onChangePlanMaintaince(row.key, "workContent", e.target.value)
            }
          />
        );
      },
    },
    {
      title: "修理頻度",
      dataIndex: "repairFrequency",
      key: "repairFrequency",
      width: "155px",
      align: "center",
      render: (value, row, index) => {
        return (
          <Select
            // value={value}
            defaultValue={value}
            onChange={(e) => {
              onChangePlanMaintaince(row.key, "repairFrequency", e);
            }}
            options={[
              { value: null, label: "セットなし" },
              { value: 3, label: "3か月" },
              { value: 6, label: "6か月" },
              { value: 12, label: "1年" },
            ]}
            style={{ width: 135 }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "105px",
      align: "center",
      render: (value, row, index) => {
        return (
          <>
            <Button
              className="buttonPC button--outline --todo-- wAuto"
              onClick={() => {
                handleAddRowMaintaince(row);
                onChangePlanMaintaince(
                  row.key,
                  "dateImplementation",
                  moment(row.dateImplementation).add(row.repairFrequency, "M")
                );
              }}
            >
              <ToTopOutlined
                className="orange"
                style={{ fontSize: "28px", cursor: "pointer" }}
              />
            </Button>
          </>
        );
      },
    },
  ];
  const handleAddRow = () => {
    const key = Date.now();
    const newRow = {
      key,
      checkItemName: "",
      controlValueLowe: "",
      controlValueUpper: "",
      easyDriveLow: "",
      easyDriveUpper: "",
      inputFormat: "",
      unit: "",
      selection: "",
      inspectionFryquency: "",
    };
    setDataSource([...dataSource, newRow]);
  };
  const handleDeleteRow = (key) => {
    const newDataSource = dataSource.filter((item) => {
      return item.key !== key;
    });
    setDataSource(newDataSource);
  };
  const handleAddRowMaintaince = (item) => {
    const key = Date.now();
    const newRow = {
      machineForSiteOfficeCode: "",
      dateImplementation: "",
      division: "",
      overview: "",
      workContent: "",
      contractor: "",
      ...(item || {}),
      listAttachment: [],
      key,
      id: null,
    };
    setListMaintenance([...listMaintenance, newRow]);
  };
  const handleAddRowPlanMaintaince = () => {
    const key = Date.now();
    const newRow = {
      key,
      machineForSiteOfficeCode: "",
      dateImplementation: "",
      division: "",
      overview: "",
      workContent: "",
      contractor: "",
      repairFrequency: "",
    };
    setListPlanMaintenance([...listPlanMaintenance, newRow]);
  };
  const handleDeleteRowMaintaince = (key) => {
    const newListAttachMachine = listMaintenance.filter((item) => {
      return item.key !== key;
    });
    setListMaintenance(newListAttachMachine);
  };
  const handleDeleteRowPlanMaintaince = (key) => {
    const newListAttachMachine = listPlanMaintenance.filter((item) => {
      return item.key !== key;
    });
    setListPlanMaintenance(newListAttachMachine);
  };
  const filterEmptyDataSource = (dataSource) => {
    return !Object.values({ ...dataSource, key: null, id: null }).every(
      (value) => !value || isEmpty(value)
    );
  };
  const validate = () => {
    let flagErr = false;

    if (
      machineForSiteOfficeName.value === null ||
      machineForSiteOfficeName.value === ""
    ) {
      flagErr = true;
      setMachineForSiteOfficeName({ ...machineForSiteOfficeName, error: true });
    }
    if (facility.value === null || facility.value === "") {
      flagErr = true;
      setFacility({ ...facility, error: true });
    }
    if (process.value === null || process.value === "") {
      flagErr = true;
      setProcess({ ...process, error: true });
    }
    if (modelNumber.value === null || modelNumber.value === "") {
      flagErr = true;
      setModelNumber({ ...modelNumber, error: true });
    }

    const dataSource_remove_empty = dataSource.filter(filterEmptyDataSource);
    if (
      dataSource_remove_empty.some(
        (x) =>
          x.inputFormat === "" ||
          x.inputFormat === null ||
          x.inputFormat === false ||
          x.checkItemName === "" ||
          x.checkItemName === null ||
          x.checkItemName === false ||
          x.inspectionFryquency === "" ||
          x.inspectionFryquency === null ||
          x.inspectionFryquency === false
      )
    ) {
      flagErr = true;
      const tempDataSource = [...dataSource_remove_empty];
      tempDataSource.forEach((x) => {
        x.inputFormat = x.inputFormat || false;
        x.checkItemName = x.checkItemName || null;
        x.inspectionFryquency = x.inspectionFryquency || false;
      });
      setDataSource(tempDataSource);
    }
    if (
      listMaintenance
        .filter(filterEmptyDataSource)
        .some(
          (x) =>
            !x.dateImplementation ||
            !x.workContent ||
            !x.overview ||
            !x.contractor
        )
    ) {
      flagErr = true;
      const tempDataSource = [...listMaintenance];
      tempDataSource.forEach((x) => {
        x.dateImplementation = x.dateImplementation || false;
        x.workContent = x.workContent || null;
        x.overview = x.overview || null;
        x.contractor = x.contractor || null;
      });
      setListMaintenance(tempDataSource);
    }
    if (
      listPlanMaintenance
        .filter(filterEmptyDataSource)
        .some(
          (x) =>
            !x.dateImplementation ||
            !x.workContent ||
            !x.overview ||
            !x.contractor
        )
    ) {
      flagErr = true;
      const tempDataSource = [...listPlanMaintenance];
      tempDataSource.forEach((x) => {
        x.dateImplementation = x.dateImplementation || false;
        x.workContent = x.workContent || null;
        x.overview = x.overview || null;
      });
      setListPlanMaintenance(tempDataSource);
    }

    if (flagErr) hidePopupUpdate();

    if (id && popupUpdate.show) {
      if (updateContent.value === null || updateContent.value === "") {
        flagErr = true;
        setUpdateContent({ ...updateContent, error: true });
      }
    }

    return !flagErr;
  };
  const submit = async () => {
    if (!validate()) {
      return;
    }

    const checkItemList = dataSource
      .filter(filterEmptyDataSource)
      .map((checkItem) => {
        const data = {
          checkItemCode: checkItem.checkItemCode,
          CheckItemName: checkItem.checkItemName,
          LowerControlValue: checkItem.controlValueLow,
          UpperControlValue: checkItem.controlValueUpper,
          NormalLowerControlValue: checkItem.easyDriveLow,
          NormalUpperControlValue: checkItem.easyDriveUpper,
          Mode: checkItem.inputFormat,
          Unit: checkItem.unit,
          Choices: checkItem.selection,
          InspectionTiming: checkItem.inspectionFryquency,
        };
        if (checkItem.id) data.id = checkItem.id;
        return data;
      });
    const submitListMaintenance = listMaintenance
      .filter((item) => {
        const arrayProps = Object.keys(item);
        return arrayProps.some((x) => {
          return x != "key" && !isEmpty(item[x]);
        });
      })
      .sort((a, b) => a.dateImplementation > b.dateImplementation ? 1 : -1)
      .map((item) => {
        return {
          ID: item.id,
          MachineForSiteOfficeCode: item.machineForSiteOfficeCode,
          DateImplementation: moment(item.dateImplementation).unix(),
          Division: item.division,
          Overview: item.overview,
          WorkContent: item.workContent,
          Contractor: item.contractor,
          ListAttachment: item.listAttachment,
        };
      });
    const submitListPlanMaintenance = listPlanMaintenance
      .filter((item) => {
        const arrayProps = Object.keys(item);
        return arrayProps.some((x) => {
          return x != "key" && !isEmpty(item[x]);
        });
      })
      .map((item) => {
        return {
          ID: item.id,
          MachineForSiteOfficeCode: item.machineForSiteOfficeCode,
          DateImplementation: moment(item.dateImplementation).unix(),
          Division: item.division,
          Overview: item.overview,
          WorkContent: item.workContent,
          Contractor: item.contractor,
          RepairFrequency: item.repairFrequency,
        };
      });
    const body = {
      MachineForSiteOfficeCode: id,
      MachineForSiteOfficeName: machineForSiteOfficeName.value,
      ManufactureName: manufactureName.value,
      FacilityCode: facility.value,
      BuildingCode: building.value,
      FloorCode: floor.value,
      RoomCode: room.value,
      ProcessId: process.value,
      ModelNumber: modelNumber.value,
      Sn: seriNumber.value,
      Status: ddlStatusMachine.value,
      ApplyDate: applyDate.value
        ? applyDate.value.startOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      Specification: specification.value,
      ServiceLife: serviceLife.value ? +serviceLife.value : null,
      DrawingLineItemNumber: drawingNumber.value,
      Saiban: made.value,
      Use: using.value,
      EnduranceYear: serviceLifeInHouse.value
        ? +serviceLifeInHouse.value
        : null,
      YearOfManufacture: yearOfManufacture.value
        ? +yearOfManufacture.value
        : null,
      ServiceTime: duability.value ? +duability.value : null,
      ReplacementDate: replacementDisposalYear.value
        ? replacementDisposalYear.value
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss")
        : null,
      Condition: condition.value,
      Note: remark.value,
      CheckItems: checkItemList,
      ImageUrl: avatarUrl,
      ListMaintenance: submitListMaintenance,
      listPlanMaintenance: submitListPlanMaintenance,
      ListAttachment:
        listAttachMachine.length > 0
          ? listAttachMachine
              .filter((x) => x.type != 2)
              .map((x) => {
                if (x.attachFileName) return x;
                return {
                  AttachFileName: x.name || x.attachFileName,
                  Type: x.pomType || 1,
                };
              })
          : null,
    };
    const formData = new FormData();
    showMessageChange(async () => {
      try {
        if (id) {
          body.UpdateDetail = updateContent.value;
          formData.append("data", JSON.stringify(body));
          await API.update(formData);
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
            history.goBack();
          });
        } else {
          formData.append("data", JSON.stringify(body));
          const res = await API.save(formData);
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
            history.push(`/ViewMachine/${res.data.machineForSiteOfficeCode}`);
          });
        }
      } catch (err) {
        let msg = MSG_CATCH();
        if (
          !isEmpty(err.response) &&
          isString(err.response.data) &&
          !isEmpty(err.response.data)
        ) {
          msg = err.response.data;
        }
        if (
          !isEmpty(err.response) &&
          isObject(err.response.data) &&
          isString(err.response.data.msg)
        ) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg);
      } finally {
        closeLoadingSpinner();
      }
    }, COMMON_CONSTANT.BOM_C008);
  };
  const uploadFile = async (file, type) => {
    const formData = new FormData();
    const body = {
      attachFileName: file.name,
      type: type,
    };
    formData.append("data", JSON.stringify(body));
    formData.append("file", file);

    try {
      const res = await API.uploadFile(formData);
      const newListAttachMachine = [...listAttachMachine];
      if (type == 1) {
        newListAttachMachine.push(res.data);
        setListAttachMachine([...newListAttachMachine]);
      }

      if (type == 3) {
        setAvatarUrl(res.data.attachFileUrl);
      }
      return res.data;
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    }
  };
  const handleDeleteFile = (item) => {
    const newListAttachMachine = [...listAttachMachine];
    setListAttachMachine(
      newListAttachMachine.filter((x) => x.attachFileUrl != item.attachFileUrl)
    );
  };
  const handleChangeAttachMachine = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const exceedAmountFile =
      listAttachMachine.length + listAttachMachine.length >= 30;
    const exceedSizeFile = file.size > 100 * 1024 * 1024;
    const contentPopup = exceedAmountFile
      ? "ファイル数は 30 を超えないようにしてください"
      : "ファイルサイズは 100MB を超えないようにしてください";
    if (exceedAmountFile || exceedSizeFile) {
      e.preventDefault();
      Modal.destroyAll();
      Modal.confirm({
        centered: true,
        title: lang.WARMING,
        content: contentPopup,
        okText: lang.OK,
        cancelText: lang.CANCEL,
      });
      return;
    }

    uploadFile(file, 1);
    fileMachineRef.current.value = null;
  };

  const avatarElRef = useRef(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const handleChangeMachineImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const exceedAmountFile =
      listAttachMachine.length + listAttachMachine.length >= 30;
    const exceedSizeFile = file.size > 100 * 1024 * 1024;
    const contentPopup = exceedAmountFile
      ? "ファイル数は 30 を超えないようにしてください"
      : "ファイルサイズは 100MB を超えないようにしてください";
    if (exceedAmountFile || exceedSizeFile) {
      e.preventDefault();
      Modal.destroyAll();
      Modal.confirm({
        centered: true,
        title: lang.WARMING,
        content: contentPopup,
        okText: lang.OK,
        cancelText: lang.CANCEL,
      });
      return;
    }

    uploadFile(file, 3);
    avatarElRef.current.value = null;
  };

  const deleteAvatar = () => {
    setAvatarUrl(null);
  };
  const resetAvatar = () => {
    setAvatarUrl(machineData.imageUrl);
  };

  const getFacility = async () => {
    try {
      const res = await KBN.getFacility();
      const options = res.data.map((v) => {
        return {
          key: v.facilityCode,
          value: v.facilityName,
        };
      });
      setFacility({
        ...facility,
        value: null,
        options: options,
      });
    } catch (err) {
      throw err;
    }
  };
  const getProcess = async (facilityId) => {
    try {
      const res = await KBN.getProcess(facilityId);
      const options = res.data.map((v) => {
        return {
          key: v.id,
          value: v.processName,
        };
      });
      let oldProcess = null;
      if (
        machineData.processId &&
        res.data.some((x) => x.id == machineData.processId)
      ) {
        oldProcess = machineData.processId;
      }

      setProcess({
        ...process,
        value: oldProcess || null,
        options: options,
        disabled: false,
      });
    } catch (err) {
      throw err;
    }
  };
  const getBuilding = async (facilityId) => {
    try {
      const res = await KBN.getBuilding(facilityId);
      const options = res.data.map((v) => {
        return {
          key: v.buildingCode,
          value: v.buildingName,
        };
      });
      let oldBuilding = null;
      if (
        machineData.buildingCode &&
        res.data.some((x) => x.buildingCode == machineData.buildingCode)
      ) {
        oldBuilding = machineData.buildingCode;
      }
      setBuilding({
        ...building,
        value: oldBuilding || null,
        options: options,
        disabled: false,
      });
    } catch (err) {
      throw err;
    }
  };
  const getFloor = async (buildingId) => {
    try {
      const res = await KBN.getFloor(buildingId);
      const options = res.data.map((v) => {
        return {
          key: v.floorCode,
          value: v.floorName,
        };
      });
      let oldFloor = null;
      if (
        machineData.floorCode &&
        res.data.some((x) => x.floorCode == machineData.floorCode)
      ) {
        oldFloor = machineData.floorCode;
      }
      setFloor({
        ...floor,
        value: oldFloor || null,
        options: options,
        disabled: false,
      });
    } catch (err) {
      throw err;
    }
  };
  const getRoom = async (floorId) => {
    try {
      const res = await KBN.getRoom(floorId);
      const options = res.data.map((v) => {
        return {
          key: v.roomCode,
          value: v.roomName,
        };
      });
      let oldRoom = null;
      if (
        machineData.roomCode &&
        res.data.some((x) => x.roomCode == machineData.roomCode)
      ) {
        oldRoom = machineData.roomCode;
      }

      setRoom({
        ...room,
        value: oldRoom || null,
        options: options,
        disabled: false,
      });
    } catch (err) {
      throw err;
    }
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      openLoadingSpinner();
      try {
        Promise.all([getFacility(), fetchClassification()]);
        closeLoadingSpinner();
      } catch (e) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    }
  }, [allowRoute]);
  useEffect(() => {
    if (facility.value && facility.value.length > 0) {
      getBuilding(facility.value);
      getProcess(facility.value);
    } else {
      setBuilding(funcReset);
      setProcess(funcReset);
    }
  }, [facility.value]);
  useEffect(() => {
    if (building.value && building.value.length > 0) {
      getFloor(building.value);
    } else {
      setFloor(funcReset);
    }
  }, [building.value]);
  useEffect(() => {
    if (floor.value && floor.value.length > 0) {
      getRoom(floor.value);
    } else {
      setRoom(funcReset);
    }
  }, [floor.value]);
  useEffect(() => {
    if (facility.options.length <= 0) return;
    if (!modeUpdate) return;
    openLoadingSpinner();
    try {
      Promise.all([getDetail()]);
      closeLoadingSpinner();
    } catch (e) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  }, [facility.options]);
  useEffect(() => {
    if (!machineData.imageUrl) return;
    if (clone_id) return;
    setAvatarUrl(machineData.imageUrl);
  }, [machineData]);

  const [modal_attach_data, set_modal_attach_data] = useState([]);
  useEffect(() => {
    if (!isNumber(popupAttach.show)) {
      return;
    }
    const current_maintenance = listMaintenance.find(
      (x) => x.key == popupAttach.show
    );
    set_modal_attach_data([...current_maintenance.listAttachment]);
  }, [listMaintenance, popupAttach.show]);

  const [urlTemplate, setUrlTemplates] = useState();
  useEffect(() => {
    const getUrlTemplate = async () => {
      const res = await API.downloadTemplateMachineMaintenance();
      // saveFile(res);
      setUrlTemplates(res.data);
    };
    getUrlTemplate();
  }, []);

  return (
    <div className="des-content machine-content">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={id ? lang.MACHINE_LEDGER_UPDATE : lang.MACHINE_LEDGER_ADD}
      />
      <Row
        gutter={0}
        style={{ margin: "0px auto", padding: "0px 8px", maxWidth: "1400px" }}
      >
        <Col lg={8} md={24} xs={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <input
              type="file"
              className="d-none"
              ref={avatarElRef}
              accept="image/*"
              onChange={handleChangeMachineImage}
            />
            {!avatarUrl && (
              <Button
                onClick={() => {
                  avatarElRef.current.click();
                }}
                style={{ width: "100%", height: "200px" }}
              >
                <b>
                  <PlusOutlined /> 機器写真
                </b>
              </Button>
            )}
            {avatarUrl && (
              <>
                <div className="avatar">
                  <Image
                    style={{ cursor: "pointer" }}
                    onClick={() => avatarElRef.current.click()}
                    preview={false}
                    width={"100%"}
                    src={avatarUrl}
                  />
                </div>
                <Tooltip title="Remove image">
                  <CloseCircleFilled
                    onClick={deleteAvatar}
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "30px",
                      fontSize: 23,
                      cursor: "pointer",
                      color: "#f7f9fc",
                    }}
                  />
                </Tooltip>
                {machineData.imageUrl && (
                  <Tooltip title="Reset image">
                    <HistoryOutlined
                      onClick={resetAvatar}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "60px",
                        fontSize: 23,
                        cursor: "pointer",
                        color: "#f7f9fc",
                      }}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </BoxContent>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <FolderAdd variant="Bold" className="first-icon" />
                    {lang.ATTACH_FILE}
                  </>
                }
                key="1"
              >
                <input
                  type="file"
                  className="d-none"
                  hidden={true}
                  ref={fileMachineRef}
                  onChange={handleChangeAttachMachine}
                />
                <Row className="mt8">
                  <Col span={24}>
                    <Button
                      disabled={listAttachMachine.length >= 30}
                      type="link"
                      onClick={(e) => fileMachineRef.current.click()}
                      className="buttonPC button--outline --todo-- wAuto"
                      style={{
                        backgroundColor: "#f7f9fc",
                        marginBottom: 10,
                        borderColor: "lightgray",
                      }}
                    >
                      <ToTopOutlined />
                      {lang.ATTACH_FILE}
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Text type="danger" style={{ fontSize: "12px" }}>
                      {lang.ATTACH_NOTE}
                    </Text>
                  </Col>
                  <Col
                    span={24}
                    style={{ maxHeight: "190px", overflow: "auto" }}
                  >
                    {listAttachMachine.length > 0 &&
                      listAttachMachine.map((item, index) => {
                        if (item.type == 2 || item.type == 3) return <></>;
                        return (
                          <Row className="wrap-namefile mb10" key={index}>
                            <Col span={21} className="machine-file">
                              <FileAttachment item={item} index={index} />
                            </Col>
                            <Col span={3}>
                              <Button
                                onClick={() => handleDeleteFile(item)}
                                className="btn-deletefile"
                                style={{ background: "#f7f9fc" }}
                                icon={<DeleteOutlined />}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
        <Col lg={16} md={24} xs={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <InfoCircleFilled className="first-icon" /> 機器情報
                  </>
                }
                key="1"
              >
                <Descriptions
                  size="small"
                  column={2}
                  bordered
                  style={{ maxHeight: "720px", overflow: "auto" }}
                >
                  <Descriptions.Item
                    span={2}
                    label={
                      <>
                        {lang.MACHINE_NAME}
                        <label className="blod ml5 mark-required">*</label>
                      </>
                    }
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      className={
                        machineForSiteOfficeName.error ? STYLE.BORDER_RED : ""
                      }
                      value={machineForSiteOfficeName.value}
                      onChange={(e) =>
                        setMachineForSiteOfficeName({
                          ...machineForSiteOfficeName,
                          value: e.target.value,
                          error: e.target.value ? false : true,
                        })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={
                      <>
                        {lang.NAME_FACILITY}
                        <label className="blod ml5 mark-required">*</label>
                      </>
                    }
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Select
                      value={facility.value}
                      className={
                        (facility.error ? STYLE.BORDER_RED : "") + " w100"
                      }
                      onChange={(v) => {
                        setFacility({
                          ...facility,
                          value: v,
                          error: v ? false : true,
                        });
                      }}
                    >
                      {renderOption(facility.options, false)}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.NAME_BUILDING}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Select
                      value={building.value}
                      className={
                        (building.error ? STYLE.BORDER_RED : "") + " w100"
                      }
                      onChange={(v) => {
                        setBuilding({
                          ...building,
                          value: v,
                          error: v ? false : true,
                        });
                      }}
                    >
                      {renderOption(building.options, false)}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.NAME_FLOOR}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Select
                      value={floor.value}
                      className="w100"
                      disabled={floor.disabled}
                      onChange={(v) => {
                        setFloor({
                          ...floor,
                          value: v,
                        });
                      }}
                    >
                      {renderOption(floor.options)}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.NAME_ROOM}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Select
                      value={room.value}
                      className="w100"
                      disabled={room.disabled}
                      onChange={(v) => {
                        setRoom({
                          ...room,
                          value: v,
                        });
                      }}
                    >
                      {renderOption(room.options)}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={
                      <>
                        {lang.NAME_PROCESS}
                        <label className="blod ml5 mark-required">*</label>
                      </>
                    }
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Select
                      value={process.value}
                      className={
                        (process.error ? STYLE.BORDER_RED : "") + " w100"
                      }
                      disabled={process.disabled}
                      onChange={(v) => {
                        setProcess({
                          ...process,
                          value: v,
                          error: v ? false : true,
                        });
                      }}
                    >
                      {renderOption(
                        process.options,
                        false,
                        isScreenSmall ? 30 : 50
                      )}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={<>{lang.MACHINE_MANUFACTURE_NAME}</>}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      className={manufactureName.error ? STYLE.BORDER_RED : ""}
                      value={manufactureName.value}
                      onChange={(e) =>
                        setManufactureName({
                          ...manufactureName,
                          value: e.target.value,
                          error: e.target.value ? false : true,
                        })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={
                      <>
                        {lang.MODEL}
                        <label className="blod ml5 mark-required">*</label>
                      </>
                    }
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      className={modelNumber.error ? STYLE.BORDER_RED : ""}
                      value={modelNumber.value}
                      onChange={(e) =>
                        setModelNumber({
                          ...modelNumber,
                          value: e.target.value,
                          error: e.target.value ? false : true,
                        })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.SERI_NUMBER}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      className={seriNumber.error ? STYLE.BORDER_RED : ""}
                      value={seriNumber.value}
                      onChange={(e) =>
                        setSeriNumber({ ...seriNumber, value: e.target.value })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.STATUS_MACHINE}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Select
                      value={ddlStatusMachine.value}
                      className="w100"
                      onChange={(v) => {
                        setDdlStatusMachine({
                          ...ddlStatusMachine,
                          value: v,
                        });
                      }}
                    >
                      {renderOption(ddlStatusMachine.options)}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.SET_TIME}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <DatePicker
                      value={applyDate.value}
                      className="w100"
                      placeholder={""}
                      onChange={(v, dateString) => {
                        setApplyDate({
                          ...applyDate,
                          value: v,
                        });
                      }}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.REPLACEMENT_DISPOSAL_YEAR}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <DatePicker
                      value={replacementDisposalYear.value}
                      className="w100"
                      placeholder={""}
                      onChange={(v, dateString) => {
                        setReplacementDisposalYear({
                          ...replacementDisposalYear,
                          value: v,
                        });
                      }}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={lang.SPECIFICATIONS}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <TextArea
                      rows={3}
                      maxLength={255}
                      value={specification.value}
                      onChange={(e) =>
                        setSpecification({
                          ...specification,
                          value: e.target.value,
                        })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.SERVICE_LIFE}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      type="number"
                      value={serviceLife.value}
                      onChange={(e) =>
                        checkInteger(e.target.value, () =>
                          setServieLife({
                            ...serviceLife,
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.YEAR_OF_MANUFACTURE}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      type="number"
                      value={yearOfManufacture.value}
                      onChange={(e) =>
                        checkInteger(e.target.value, () =>
                          setYearOfManufacture({
                            ...yearOfManufacture,
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.SERVICE_LIFE_IN_HOUSE}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      type="number"
                      value={serviceLifeInHouse.value}
                      onChange={(e) =>
                        checkInteger(e.target.value, () =>
                          setServiceLifeInHouse({
                            ...serviceLifeInHouse,
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.DUABILITY}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      type="number"
                      value={duability.value}
                      onChange={(e) =>
                        checkInteger(e.target.value, () =>
                          setDuability({
                            ...duability,
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.DRAWING_NUMBER}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      value={drawingNumber.value}
                      onChange={(e) =>
                        setDrawingNumber({
                          ...drawingNumber,
                          value: e.target.value,
                        })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.MADE}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      value={made.value}
                      onChange={(e) =>
                        setMade({ ...made, value: e.target.value })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.USE}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      value={using.value}
                      onChange={(e) =>
                        setUsing({ ...using, value: e.target.value })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label={lang.CONDITION}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <Input
                      maxLength={255}
                      value={condition.value}
                      onChange={(e) =>
                        setCondition({ ...condition, value: e.target.value })
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={lang.REMARK}
                    labelStyle={{
                      width: "20%",
                      fontWeight: "bold",
                      padding: "4px 4px",
                    }}
                    contentStyle={{ padding: "4px 4px" }}
                  >
                    <TextArea
                      rows={3}
                      maxLength={255}
                      value={remark.value}
                      onChange={(e) =>
                        setRemark({ ...remark, value: e.target.value })
                      }
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
        <Col span={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <ArchiveTick className="first-icon" variant="Bold" />
                    {lang.INSPECTION_ITEM}
                  </>
                }
                key="1"
              >
                <Table
                  scroll={
                    dataSource && dataSource.length > 0 && { y: 300, x: 768 }
                  }
                  locale={{
                    emptyText: "",
                  }}
                  pagination={false}
                  dataSource={dataSource}
                  className="tableAddMachine"
                  columns={columns}
                />
                <Button
                  style={{
                    background: "#f7f9fc",
                    width: "auto",
                    minWidth: "auto",
                  }}
                  className="pr10 pl10 mt4 mb4 ml10"
                  onClick={() => handleAddRow()}
                  icon={<PlusOutlined />}
                ></Button>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
        <Col span={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <VideoTime className="first-icon" variant="Bold" />
                    {lang.MAINTAINCE_HISTORY}
                  </>
                }
                key="1"
              >
                <Table
                  scroll={!isEmpty(listMaintenance) && { y: 300, x: 768 }}
                  locale={{
                    emptyText: "",
                  }}
                  pagination={false}
                  dataSource={listMaintenance}
                  className="tableAddMachine"
                  columns={column_maintaince_history}
                />
                <div className="mt8">
                  <Button
                    style={{
                      background: "#f7f9fc",
                      width: "auto",
                      minWidth: "auto",
                    }}
                    className="pr10 pl10 mt4 mb4 ml10"
                    onClick={() => handleAddRowMaintaince()}
                    icon={<PlusOutlined />}
                  ></Button>
                  <Button
                    style={{
                      background: "#f7f9fc",
                      width: "auto",
                      minWidth: "auto",
                      color: "#0080cb",
                    }}
                    className="pr10 pl10 mt4 mb4 ml10"
                    onClick={() => {
                      fileElUpload.current.click();
                    }}
                    icon={
                      <CloudUploadOutlined
                        style={{
                          color: "#f50",
                        }}
                      />
                    }
                  >
                    保全履歴入力
                  </Button>
                  <a href={urlTemplate} className="ml4">
                    <Button
                      style={{
                        background: "#f7f9fc",
                        width: "auto",
                        minWidth: "auto",
                        color: "#0080cb",
                      }}
                      className="pr10 pl10 mt4 mb4 ml10"
                      icon={
                        <CloudDownloadOutlined
                          style={{
                            color: "#f50",
                          }}
                        />
                      }
                    >
                      {lang.MAINTAINCE_TEMPLATE}
                    </Button>
                  </a>
                  <input
                    type="file"
                    className="d-none"
                    ref={fileElUpload}
                    onChange={readUploadFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </div>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
        <Col span={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <Convertshape className="first-icon" variant="Bold" />
                    {lang.REPAIR_PLAN}
                  </>
                }
                key="1"
              >
                <Table
                  scroll={!isEmpty(listPlanMaintenance) && { y: 300, x: 1000 }}
                  locale={{
                    emptyText: "",
                  }}
                  pagination={false}
                  dataSource={listPlanMaintenance}
                  className="tableAddMachine"
                  columns={column_plan_maintaince_history}
                />
                <div className="mt8">
                  <Button
                    style={{
                      background: "#f7f9fc",
                      width: "auto",
                      minWidth: "auto",
                    }}
                    className="pr10 pl10 mt4 mb4 ml10"
                    onClick={() => handleAddRowPlanMaintaince()}
                    icon={<PlusOutlined />}
                  />
                </div>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
      </Row>

      <Modal
        centered
        width="40%"
        bodyStyle={{ height: "400px", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.ATTACH}</div>}
        visible={isNumber(popupAttach.show)}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              setPopupAttach({ ...popupAttach, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <div className="mb15">
          新規挿入:
          <Button
            type="link"
            onClick={() => {
              fileEl.current.click();
            }}
          >
            <PaperClipOutlined className="iconM blue" />
          </Button>
        </div>
        <input
          type="file"
          className="d-none"
          ref={fileEl}
          onChange={readAttachFileUrl}
        />
        {
          <Table
            className="tbl-original"
            rowKey="ItemOrder"
            dataSource={modal_attach_data}
            columns={columnsAttach}
            pagination={false}
            bordered
          />
        }
      </Modal>

      <Modal
        centered
        width={isPC ? "50%" : "70%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        visible={popupUpdate.show}
        footer={[
          <Button
            className="buttonPC button--info wAuto"
            onClick={() => {
              submit();
            }}
          >
            {lang.OK}
          </Button>,
          <Button
            onClick={() => setPopupUpdate({ ...popupUpdate, show: false })}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <Row>
          <Col span={3} className="center">
            <InfoCircleOutlined style={{ fontSize: 50, color: "#faad14" }} />
          </Col>
          <Col span={21} className="mb20">
            この機器情報を更新してもよろしいでしょうか。
            <br />
            更新が完了するために、更新内容を入力してから、「OK」ボタンを押下してください。
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          <Col span="24">
            <FormInput
              isPopup
              name={lang.UPDATE_CONTENT}
              required
              labelSpan={3}
            >
              <Row>
                <Col span={24}>
                  <FormInput>
                    <TextArea
                      rows={4}
                      maxLength={255}
                      className={updateContent.error ? STYLE.BORDER_RED : ""}
                      value={updateContent.value}
                      onChange={(e) =>
                        setUpdateContent({
                          ...updateContent,
                          value: e.target.value,
                          error: e.target.value ? false : true,
                        })
                      }
                    />
                  </FormInput>
                </Col>
              </Row>
            </FormInput>
          </Col>
        </Row>
      </Modal>

      <FooterPage>
        <Row justify="space-between">
          <Col></Col>
          <Col>
            <Button
              onClick={
                id
                  ? () => {
                      validate() && showPopupUpdate();
                    }
                  : submit
              }
              className="buttonPC button--info wAuto ml15"
            >
              <SaveOutlined />
              {COMMON_CONSTANT.SAVE}
            </Button>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15"
              onClick={() => history.goBack()}
            >
              <CloseCircleOutlined />
              {lang.CANCEL}
            </Button>
          </Col>
        </Row>
      </FooterPage>
    </div>
  );
}

export default AddMachine;

export const ButtonModalClassification = ({ callback, fullButton = false }) => {
  const { roles } = useSelector((state) => state.auth);
  const isAdmin = [
    rolesConst.OFFICE_ADMIN,
    rolesConst.HEAD_OFFICE_ADMIN,
  ].includes(roles);

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [visible, setVisible] = useState(false);
  const [classification, setClassification] = useState([]);
  const classRef = useRef(null);

  const getClassification = async () => {
    try {
      const res = await API.getClassification();
      setClassification(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const updateClassification = async () => {
    try {
      await API.updateClassification(classification);
      Modal.success({
        centered: true,
        title: <span style={{ fontWeight: "bold" }}>{lang.SUCCESS}</span>,
        content: COMMON_CONSTANT.BOM_C002,
        okText: lang.OK,
        onOk: callback,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const setModalOn = () => {
    getClassification();
    setVisible(true);
  };

  const setModalOff = () => {
    setVisible(false);
  };

  const handleOK = async () => {
    await updateClassification();
    setVisible(false);
  };

  const column = [
    {
      title: "色",
      dataIndex: "color",
      key: "color",
      width: "10%",
      render: (color, record, index) => {
        return (
          <ColorPicker
            color={color}
            onChange={(color) => {
              const newClassification = [...classification];
              newClassification[index].color = color;
              setClassification(newClassification);
            }}
          />
        );
      },
    },
    {
      title: "区分",
      dataIndex: "classification",
      key: "classification",
      render: (text, record, index) => {
        return (
          <Input
            ref={classRef}
            value={text}
            onChange={(e) => {
              const newClassification = [...classification];
              newClassification[index].classification = e.target.value;
              setClassification(newClassification);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "70px",
      render: (id, record, index) => {
        return (
          <Button
            disabled={!record.canDelete}
            className="btnDelete"
            onClick={() => {
              const newClassification = [...classification];
              newClassification.splice(index, 1);
              setClassification(newClassification);
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      isEmpty(classification) ||
      !classRef ||
      (classRef.current &&
        classRef.current.props &&
        classRef.current.props.value)
    )
      return;
    if (classRef.current) {
      classRef.current.focus();
    }
  }, [classification]);

  if (!isAdmin) return <></>;

  return (
    <>
      {!fullButton ? (
        <Button className="blue p0" onClick={setModalOn} type="link">
          <FormOutlined />
        </Button>
      ) : (
        <Button
          onClick={setModalOn}
          className="buttonPC button--info wAuto ml12"
        >
          <FormOutlined />
          {lang.MAINTAINCE_PIC}
        </Button>
      )}
      <Modal
        centered
        width={isPC ? "60%" : "100%"}
        closeIcon={<></>}
        className=""
        title={null}
        visible={visible}
        onCancel={setModalOff}
        footer={
          <Row>
            <Col span={4} style={{ textAlign: "left" }}>
              <Button
                style={{
                  background: "#f7f9fc",
                  width: "auto",
                  minWidth: "auto",
                }}
                className="pr10 pl10"
                onClick={() => {
                  const newClassification = [...classification];
                  newClassification.push({
                    color: "#ffffff",
                    name: "",
                    canDelete: true,
                  });
                  setClassification(newClassification);
                }}
                icon={<PlusOutlined />}
              />
            </Col>
            <Col span={20} style={{ textAlign: "right" }}>
              <Button
                key={1}
                className="buttonPC button--info wAuto"
                onClick={() => {
                  handleOK();
                }}
              >
                {lang.OK}
              </Button>
              <Button
                onClick={setModalOff}
                className="buttonPC --todo-- wAuto  button--outline"
              >
                {lang.CANCEL}
              </Button>
            </Col>
          </Row>
        }
      >
        <Table
          className="tableAddMachine"
          scroll={{ y: 300 }}
          rowKey="ItemOrder"
          dataSource={classification}
          columns={column}
          pagination={false}
          bordered
        />
      </Modal>
    </>
  );
};
