import { Col, Tabs, Row, Empty, Select, Button } from "antd";
import React, { useState } from "react";
import GridCheckListPreCheck from "./gridAddPreCheck"; // Grid
import { lang } from "../../constants/common.const";
import { ReactComponent as EditIcon } from "@src/styles/icon/edit.svg";

import { PlusOutlined } from "@ant-design/icons";

import TabsCustom from "@src/component/TabsComponent/TabsCustom";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";
import classItemPreCheck from "../../models/itemPreCheck";

const { TabPane } = Tabs;
const position = {
  TOP: "TOP",
  LEFT: "LEFT",
  ITEM: "ITEM",
};
const { Option } = Select;

function TabCheckListPreCheck({
  panes,
  setPanes,
  activeKey,
  setActiveKey,
  onAddTab,
  onUpdateTab,
  onRemoveLeft,
  showLocation,
  MULTIPLE_TIME,
  listTimeCheck = [],
  onAddRow,
  onDeleteRow,
  onAddListTimeCheck,
  onRemoveListTimeCheck,
  onUpdateTimeCheck,
  accumulatedTypeList,
  defaultInspectionTiming,
  defaultTypeList,
  defaultJudgement,
  defaultInputMode,
  ddlTypeChecklist,
  ...other
}) {
  let [tabIndex, setTabIndex] = useState(panes[0].ID || 1);

  const onChangeCheckItemName = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.CheckItemName = value;
      }
    });
    setPanes(d);
  };

  const onChangeAccumulatedType = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.AccumulatedType = value;
        if (Number(value) === 4) {
          if (!item.Multiplier || item.Multiplier === "") {
            item.Multiplier = 1;
          }
        } else {
          item.Multiplier = "";
        }
      }
    });
    setPanes(d);
  };

  const onChangeInspectionTiming = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.InspectionTiming = value;
        if (Number(value) === 1 || Number(value) === 2) {
          item.DefaultValue = "";
        }
      }
    });
    setPanes(d);
  };

  const onChangeDefaultType = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.DefaultType = value;
        if (Number(value) === 1 || Number(value) === 2) {
          item.DefaultValue = "";
        }
      }
    });
    setPanes(d);
  };

  const onChangeItemJudgement = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.ItemJudgement = value;
      }
    });
    setPanes(d);
  };

  const onChangeDefaultValue = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.DefaultValue = value;
      }
    });
    setPanes(d);
  };

  const onChangeAccumulatedGroupItemCodes = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.AccumulatedGroupItemCodes = value;
      }
    });
    setPanes(d);
  };
  const onChangeNormalLowerControlValue = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.NormalLowerControlValue = value;
      }
    });
    setPanes(d);
  };

  const onChangeNormalUpperControlValue = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.NormalUpperControlValue = value;
      }
    });
    setPanes(d);
  };

  const onChangeMode = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.Mode = value;
      }
    });
    setPanes(d);
  };

  const onChangeUnit = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.Unit = value;
      }
    });
    setPanes(d);
  };

  const onChangeChoices = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.Choices = value;
      }
    });
    setPanes(d);
  };

  const onChangeMultiplier = (value, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.Multiplier = value;
      }
    });
    setPanes(d);
  };

  const onCheckAllTime = (checked, rowData, indexTabTop) => {
    let d = [...panes];
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        listTimeCheck.forEach((v, i) => {
          item.TimeArr[i] = checked;
        });
      }
    });
    return d;
  };

  const onSwitchTimeCheck = (checked, rowData, indexTabTop) => {
    const d = [...panes];
    d[indexTabTop].Items.forEach((v) => {
      if (v === rowData) {
        v.MultipleTime = checked;
        v.AccumulatedType = "1";
        v.Multiplier = "";
      }
    });
    setPanes(d);
  };

  // Check time v2
  const onCheckTime = (checked, indexCheck, rowData, indexTabTop) => {
    let d = [...panes];
    let count = 0;
    d[indexTabTop].Items.forEach((item = new classItemPreCheck()) => {
      if (item.ID === rowData.ID) {
        item.TimeArr[indexCheck] = checked;

        item.TimeArr.forEach((v) => {
          v && count++;
        });
      }
    });

    // Uncheck all time OR first check time
    if (count === 0 || (checked && count === 1)) {
      d = cbGridBase(
        checked,
        rowData,
        d[indexTabTop],
        indexCheck,
        indexTabTop,
        true
      );
    }

    setPanes(d);
  };

  // change
  const changeOrder = (value, rowData, list, where, indexTab) => {
    let pageY = window.pageYOffset; // Get Y
    // let pageX = window.pageXOffset; // Get X

    const listNew = [...list];
    const indexNew = value - 1;
    let indexOld = rowData.Order - 1;

    // Remove
    listNew.splice(indexOld, 1);
    // Add
    listNew.splice(indexNew, 0, rowData);
    // Re-order
    listNew.forEach((el, i) => {
      el.Order = i + 1;
    });

    // TOP
    if (where === position.TOP) {
      setPanes(listNew);
      // setActiveKey(rowData.ID);
    }
    if (where === position.LEFT) {
      // LEFT
      panes[indexTab].Machines = listNew;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    if (where === position.ITEM) {
      // ITEM
      panes[indexTab].Items = listNew;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    // Re - Scroll
    setTimeout(() => {
      window.scrollTo(0, pageY);
    }, 0);

    return false;
  };

  const changeOrderItem = (value, rowData, list, where, indexTab) => {
    let pageY = window.pageYOffset; // Get Y
    // let pageX = window.pageXOffset; // Get X
    const newList = [...list];
    const indexNew = value - 1;
    let indexOld = list.findIndex((x) => x.ID == rowData.ID);
    // Remove
    newList.splice(indexOld, 1);
    // Add
    newList.splice(indexNew, 0, rowData);
    // Re-order
    newList.forEach((el, i) => {
      el.Order = i + 1;
    });

    // TOP
    if (where === position.ITEM) {
      // ITEM
      panes[indexTab].Items = newList;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    // Re - Scroll
    setTimeout(() => {
      window.scrollTo(0, pageY);
    }, 0);

    return false;
  };

  // RENDER - Tab button
  const renderTabTitle = (
    dataRow = new classGroup(),
    isLeft,
    list,
    where,
    indexTab
  ) => {
    // Show title when
    let title = () => {
      // Tab LEFT
      if (isLeft) {
        return (
          <span
            className="textOverflow"
            // style={{
            //   maxWidth: "142px",
            // }}
          >
            {dataRow.Name}
          </span>
        );
      }
      // Tab TOP
      // Case active & !tabInit
      if (Number(activeKey) === Number(dataRow.ID)) {
        // REMOVE
        let funcDel = () => {
          // Remove tab group
          let obj = remove(dataRow.ID, list);
          setPanes(obj.data);
          setActiveKey(obj.data[obj.index].ID);
          return dataRow; // Return group del
        };
        // RE-NAME
        let funcUpdateName = (newNameGroup) => {
          let dGroup = panes;
          dGroup[indexTab].Name = newNameGroup;
          setPanes(dGroup);
        };
        return (
          <div
            className="d-inline-block btnLink-active pointer content-center"
            onClick={(eUpdate) => onUpdateTab(dataRow, funcDel, funcUpdateName)}
          >
            <span
              className="textOverflow d-inline-block"
              style={{
                maxWidth: "137px",
              }}
            >
              {dataRow.Name}
            </span>
            <EditIcon className="ml5" />
          </div>
        );
      } else {
        // in-active
        return (
          <span
            className="textOverflow"
            style={{
              maxWidth: "142px",
            }}
          >
            {dataRow.Name}
          </span>
        );
      }
    };

    // Render tab
    return (
      <Row
        // justify="space-between"
        className="left"
      >
        <Col
          span={24}
          // style={isLeft && { width: "189px" }}
          className="align-center"
        >
          <Select
            className="d-inline-block mr5 selectOrder"
            size="small"
            onChange={(v) => {
              changeOrder(v, dataRow, list, where, indexTab);
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              return false;
            }}
            value={dataRow.Order}
          >
            {list.map((el, i) => {
              // if (++i === e.Order)
              // return (<option key={el.Order} value={el.Order} selected>{el.Order}</option>);
              return (
                <Option key={el.Order} value={el.Order}>
                  {el.Order}
                </Option>
              );
            })}
          </Select>
          {title()}
        </Col>
      </Row>
    );
  };

  // REMOVE
  const remove = (key, data) => {
    let lastIndex;

    // 1. Get tab active
    data.forEach((e, i) => {
      if (e.ID === key) {
        // Pre-tab
        lastIndex = i - 1;
      }
    });
    // 2. Delete tab
    data = data.filter((e) => e.ID !== key);
    // 3. Re-Order tab
    data.forEach((e, i) => {
      e.Order = i + 1;
    });

    return {
      index: lastIndex < 0 ? 0 : lastIndex,
      data: data,
    };
  };

  // TOP ------------ CRUD
  // Active tab
  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  // Update tab
  const onEdit = (key, action) => {
    if (action === "add") {
      add();
    }
    // else {
    //     // REMOVE
    //     debugger;
    //     let obj = remove(key, panes);
    //     setPanes(obj.data);
    //     setActiveKey(obj.data[obj.index].ID);
    // }
  };
  // ADD
  const add = () => {
    tabIndex = ++tabIndex;
    setTabIndex(tabIndex);
    onAddTab(tabIndex); // Add to Group tab
  };

  // LEFT ------------- CRUD

  const delTabLeft = (idRow, idParent, leftData) => {
    // REMOVE
    let obj = remove(idRow, leftData);

    const newP = panes.map((e) => {
      if (e.ID === idParent) {
        e.Machines = obj.data;
      }
      return e;
    });
    setPanes(newP);
  };
  // Update tab
  const onEditLeft = (key, action) => {
    // Get tab
    let idParent;
    let rowData;
    let leftData;
    panes.forEach((e) => {
      e.Machines &&
        e.Machines.length > 0 &&
        e.Machines.forEach((e2) => {
          if (e.ID + "" + e2.ID === String(key)) {
            rowData = e2;
            idParent = e.ID;
            leftData = e.Machines;
          }
        });
    });

    if (action === "add") {
    } else {
      onRemoveLeft(delTabLeft, rowData, idParent, leftData);
    }
  };

  // Tab-left: Update checkbox
  // Uncheck all tab other
  // Check item click
  const cbGrid = (checked, row, machine, iRow, iTabTop) => {
    setPanes(cbGridBase(checked, row, machine, iRow, iTabTop));
  };
  const cbGridBase = (checked, row, machine, iRow, iTabTop, onlyCheckRow) => {
    const data = onlyCheckRow
      ? [...panes]
      : onCheckAllTime(checked, row, iTabTop);
    const idRow = row.ID;
    // Uncheck group other
    data.forEach((g = new classGroup(), i1) => {
      if (String(i1) !== String(iTabTop)) {
        // Group other
        g.Machines.forEach((m = new classMachine(), i2) => {
          if (m.ID === machine.ID) {
            // Is Machine
            m.Items.forEach((i = new classItemPreCheck()) => {
              if (idRow === i.ID) {
                i.checked = false;
              }
            });
          }
        });
      }
    });
    // Checked row
    data[iTabTop].Items.forEach((i = new classItemPreCheck()) => {
      if (idRow === i.ID) {
        i.checked = checked;
      }
    });

    return data;
  };

  // Render------ Tab LEFT
  const renderTabLeft = (subData, indexTabTop, idTabTop) => {
    return (
      <>
        <GridCheckListPreCheck
          ddlTypeChecklist={ddlTypeChecklist}
          rowKey="ID"
          dataSource={subData}
          //  dataMachine={subData}
          //
          MULTIPLE_TIME={MULTIPLE_TIME}
          listTimeCheck={listTimeCheck}
          onAddListTimeCheck={onAddListTimeCheck}
          onRemoveListTimeCheck={onRemoveListTimeCheck}
          onUpdateTimeCheck={onUpdateTimeCheck}
          accumulatedTypeList={accumulatedTypeList}
          defaultInspectionTiming={defaultInspectionTiming}
          defaultTypeList={defaultTypeList}
          defaultJudgement={defaultJudgement}
          defaultInputMode={defaultInputMode}
          onDeleteRow={(v) => {
            onDeleteRow(indexTabTop, v);
          }}
          onChangeCheckItemName={(v, rowData) => {
            onChangeCheckItemName(v, rowData, indexTabTop);
          }}
          onChangeNormalLowerControlValue={(v, rowData) => {
            onChangeNormalLowerControlValue(v, rowData, indexTabTop);
          }}
          onChangeNormalUpperControlValue={(v, rowData) => {
            onChangeNormalUpperControlValue(v, rowData, indexTabTop);
          }}
          onChangeMode={(v, rowData) => {
            onChangeMode(v, rowData, indexTabTop);
          }}
          onChangeUnit={(v, rowData) => {
            onChangeUnit(v, rowData, indexTabTop);
          }}
          onChangeChoices={(v, rowData) => {
            onChangeChoices(v, rowData, indexTabTop);
          }}
          onChangeInspectionTiming={(v, rowData) => {
            onChangeInspectionTiming(v, rowData, indexTabTop);
          }}
          onChangeItemJudgement={(v, rowData) => {
            onChangeItemJudgement(v, rowData, indexTabTop);
          }}
          onChangeDefaultType={(v, rowData) => {
            onChangeDefaultType(v, rowData, indexTabTop);
          }}
          onChangeDefaultValue={(v, rowData) => {
            onChangeDefaultValue(v, rowData, indexTabTop);
          }}
          onChangeAccumulatedType={(v, rowData) => {
            onChangeAccumulatedType(v, rowData, indexTabTop);
          }}
          onChangeAccumulatedGroupItemCodes={(v, rowData) => {
            onChangeAccumulatedGroupItemCodes(v, rowData, indexTabTop);
          }}
          onChangeMultiplier={(v, rowData) => {
            onChangeMultiplier(v, rowData, indexTabTop);
          }}
          // Order item
          onChangeOrderRow={(v, rowData, listItem) => {
            changeOrderItem(v, rowData, listItem, position.ITEM, indexTabTop);
          }}
          // switch Time
          onSwitchTimeCheck={(checked, rowData) => {
            onSwitchTimeCheck(checked, rowData, indexTabTop);
          }}
          onCheckAllTime={(checked, rowData) => {
            onCheckAllTime(checked, rowData, indexTabTop);
          }}
          onCheckTime={(checked, indexCheck, rowData) => {
            onCheckTime(checked, indexCheck, rowData, indexTabTop);
          }}
          scroll={{ x: "auto", y: "auto" }}

          // onCheckAllItem={(ev) => {
          //   onCheckAllItem(
          //     ev,
          //     machine.Items,
          // //    indexTabLeft,
          //     indexTabTop,
          //     funcUncheckTabOther
          //   );
          // }}
        />

        <div>
          <Button
            className="buttonPC button--outline"
            style={{
              height: 24,
              width: 35,
              minWidth: 35,
              marginLeft: 10,
              marginTop: 10,
            }}
            onClick={() => {
              onAddRow(indexTabTop);
            }}
          >
            <PlusOutlined style={{ position: "absolute", top: 4, left: 10 }} />
          </Button>
        </div>
      </>
    );
  };

  // Render Tab TOP
  return (
    <TabsCustom
      style={{ minHeight: "500px" }}
      className="tbl-createCL"
      type="editable-card"
      onChange={onChange}
      activeKey={activeKey + ""}
      onEdit={onEdit}
      // Button add
      // addIcon={
      //   <span className="">
      //     <PlusOutlined className="orange" /> グループを追加
      //   </span>
      // }
      onTabClick={(key, event) => {
        // Empty
      }}
      {...other}
    >
      {panes.map((pane, indexTab) => {
        return (
          <TabPane
            tab={renderTabTitle(pane, false, panes, position.TOP, indexTab)}
            key={pane.ID}
            closable={false}
          >
            {renderTabLeft(pane.Items, indexTab, pane.ID)}
          </TabPane>
        );
      })}
    </TabsCustom>
  );
}

export default TabCheckListPreCheck;
