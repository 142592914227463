import React, { useState, useEffect } from "react";
import { Row, Input, Col, Button, Modal } from "antd";
import { COMMON_CONSTANT, STYLE } from "../../../constants/common.const";
import MessageInput from "../messageInput";
import classText from "../../../models/control/text";

import { PlusOutlined } from "@ant-design/icons";

function AddGroupModal({
  showPopup,
  newGroupName = new classText(),
  setNewGroupName,
  funcCreateGroup,
  ...other
}) {
  let [show, setShow] = useState(showPopup);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Modal
      centered
      title={COMMON_CONSTANT.TITLE_CREATE_GROUP}
      visible={show}
      {...other}
    >
      <Row>
        <Col span={5} className="content-center">
          {COMMON_CONSTANT.GROUP_NAME}
        </Col>
        <Col span={11}>
          <Input
            maxLength={255}
            className={newGroupName.error ? STYLE.BORDER_RED : ""}
            value={newGroupName.value}
            onChange={(e) => {
              setNewGroupName({
                ...newGroupName,
                value: e.target.value,
                error: false,
              });
            }}
          />
          <MessageInput data={newGroupName} />
        </Col>
        <Col span={4}>
          <Button
            className="buttonPC button--info ml10"
            onClick={() => {
              funcCreateGroup();
            }}
          >
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default AddGroupModal;
