import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setListInvenBanner,
  setShowBannerInventory,
} from "../../redux/homeBanner";
import { checkRoleScreen } from "../../utils/roles";
import API from "./../../api/backend/inventory";
import "./bannerMotion.scss";
import { CloseOutlined } from "@ant-design/icons";

function BannerMotion() {
  const { allowRoute } = useSelector((state) => state.auth);
  const checkRole = allowRoute.some((item) => item.menuCode === "M083");
  const { listInventory, isShowBannerInvenrory } = useSelector(
    (state) => state.homeBanner
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMotion, setShowMotion] = useState(false);
  useEffect(() => {
    const getListInventory = async () => {
      const res = await API.GetDataForBannerWarning();

      const listInventory = res.data.data || [];
      const listInventoryName = listInventory.map(
        (item) => item.inventoryItemName
      );
      dispatch(setListInvenBanner(listInventoryName));
    };
    if (checkRoleScreen("/InventoryData", allowRoute)) {
      getListInventory();
    }
  }, [allowRoute]);
  const renderTextNotify = () => {
    let text = "";
    const notify =
      " は最小在庫数量より在庫数量のほうが少なくなりました。確認してください";
    if (listInventory.length > 0) {
      const listName = listInventory.map((item, index) => (
        <span className="nameItem" key={index}>
          {item} {index !== listInventory.length - 1 && ","}
        </span>
      ));

      text = (
        <span
          onClick={() => history.push("/InventoryData?status=true")}
          className="wrapNameItem"
        >
          <span>{listName}</span>
          {notify}
        </span>
      );
    }
    return text;
  };
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowMotion(true);
    }, 40000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  return checkRole && renderTextNotify() && isShowBannerInvenrory ? (
    <div className="BannerMotion">
      {showMotion && (
        <marquee direction="left" scrollamount={4}>
          <div className="textmarquee">{renderTextNotify()}</div>
        </marquee>
      )}
      {!showMotion && (
        <div className="wrapMotion">
          <div className="textMotion">{renderTextNotify()}</div>
        </div>
      )}
      <div
        onClick={() => dispatch(setShowBannerInventory(false))}
        className="wrapClearIcon"
      >
        <CloseOutlined />
      </div>
    </div>
  ) : null;
}

export default BannerMotion;
