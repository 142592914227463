import {
  ClearOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ButtonModalClassification } from "@src/page/Machine_Management/add1";
import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { isEmpty, isObject, isString } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApiMachine from "../../api/backend/machineManagement";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";
import {
  MSG_CATCH,
  enterSearch,
  getQuerySearch,
  pushObject2Query,
  pushObject2QueryReplace,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import { checkRoleScreen } from "../../utils/roles";

const thisYear = new Date().getFullYear();
const getListYear = (yearStart, yearEnd) => {
  let listYear = [];
  listYear.length = yearEnd - yearStart + 1;
  listYear = [...listYear].map((_, i) => yearStart + i);
  return listYear;
};
const getYearStart = (data) => {
  let yearStart = thisYear;
  data.forEach((item) => {
    Object.keys(item.histories).forEach((year) => {
      yearStart = Math.min(yearStart, +year);
    });
  });
  return yearStart;
};
const getYearEnd = (data) => {
  let yearEnd = thisYear;
  data.forEach((item) => {
    Object.keys(item.histories).forEach((year) => {
      yearEnd = Math.max(yearEnd, +year);
    });
  });
  return yearEnd;
};

const DIVISION_PLAN = "#9fc5e8";

function Machine_list({ isEndUser }) {
  const history = useHistory();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [formSearch] = Form.useForm();
  const objSearch = getQuerySearch();

  const [grid, setGrid] = useState(new classGrid());
  const [page, setPage] = useState(Number(objSearch.page) - 1 || 0);
  const [totalItem, setTotalItem] = useState(0);
  const [selectMaintain, setSelectMaintain] = useState(null);
  const location = useLocation();

  const buildingCodeInit = new URLSearchParams(location.search).get(
    "buildingName"
  );
  const floorCodeInit = new URLSearchParams(location.search).get("floorName");
  const roomCodeInit = new URLSearchParams(location.search).get("roomName");
  const facilityCodeInit = new URLSearchParams(location.search).get(
    "facilityName"
  );

  // Input Search
  const [txtMachine, setTxtMachine] = useState(
    new classText(objSearch.machineName || "")
  );
  const [ddlClassification, setDdlClassification] = useState(
    new classDdl(objSearch.classification || null)
  );
  const [showLoading, setShowLoading] = useState(false);
  const { selectedFactory, allowRoute, isLeader } = useSelector(
    (state) => state.auth
  );

  const initSearch = {
    machineName: txtMachine.value ? txtMachine.value.trim() : null,
    classification: ddlClassification.value,
  };
  // query endUser
  const queryEndUser = isEndUser ? "?isEndUser=true" : "";
  const objClear = {
    value: null,
  };
  const initRef = useRef({
    building: buildingCodeInit,
    floor: floorCodeInit,
    room: roomCodeInit,
    facility: facilityCodeInit,
  });

  const clearInputSearch = () => {
    setTxtMachine({ ...txtMachine, ...objClear });
    setDdlClassification({ ...ddlClassification, ...objClear });
    formSearch.resetFields();
    search_onClick(0, true);
    pushObject2QueryReplace({
      machineName: null,
      classification: null,
    });
  };
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const search_onClick = async (pageNumber = 0, noParam = false) => {
    setPage(pageNumber || 0);
    pushObject2QueryReplace({
      ...initSearch,
      page: pageNumber + 1,
    }); //search
    try {
      setShowLoading(true);
      const responSearch = await ApiMachine.maintainanceHistory({
        ...(noParam
          ? {}
          : {
              machineName: txtMachine.value ? txtMachine.value.trim() : null,
              classification: ddlClassification.value,
            }),
        limit: PAGE_SIZE,
        offset: pageNumber * PAGE_SIZE,
      });
      if (statusRes(responSearch)) {
        const { count, data } = responSearch.data;
        setTotalItem(count);
        const listGridSearch = data.map((machineItem) => ({
          machineCode: machineItem.machineForSiteOfficeCode,
          name: machineItem.machineForSiteOfficeName,
          ...machineItem,
        }));
        setGrid({
          ...grid,
          data: listGridSearch,
          count,
        });
      }
    } catch (error) {
      console.log(error);
      showMessageError(MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  const handleDeleteMachine = (code) => {
    const deleteFunc = async () => {
      try {
        setShowLoading(true);
        const responseDelete = await ApiMachine.deleteMaintainanceHistory({
          machineCode: code,
        });
        if (statusRes(responseDelete)) {
          await search_onClick();
          //success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
        } else throw new Error(responseDelete);
      } catch (err) {
        let msg = MSG_CATCH();
        if (
          !isEmpty(err.response) &&
          isString(err.response.data) &&
          !isEmpty(err.response.data)
        ) {
          msg = err.response.data;
        }
        if (
          !isEmpty(err.response) &&
          isObject(err.response.data) &&
          isString(err.response.data.msg)
        ) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg);
      } finally {
        setShowLoading(false);
      }
    };
    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: deleteFunc,
      centered: true,
    });
  };
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      // clearInputSearch();
      fetchClassification();
      search_onClick(page || 0);
    }
  }, [allowRoute, selectedFactory]);

  const listYear = getListYear(getYearStart(grid.data), getYearEnd(grid.data));
  const initColumns = [
    {
      title: lang.MACHINE_NAME,
      dataIndex: "name",
      width: "20%",
      align: "center",
      fixed: "left",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "100px" }}
          >
            <Col span={24}>
              <Link
                to={"/ViewMachine/" + row.machineCode + queryEndUser}
                className="white-space display-2-line"
              >
                <Tooltip title={text}>{text}</Tooltip>
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "メーカー",
      dataIndex: "manufactureName",
      width: "10%",
      align: "center",
      fixed: "left",
      render: (text) => {
        return displayText2Line(text, "130px");
      },
    },
    {
      title: "型式仕様",
      dataIndex: "modelNumber",
      width: "10%",
      align: "left",
      fixed: "left",
      render: (text) => {
        return displayText2Line(text, "130px");
      },
    },
    {
      title: "製造番号",
      dataIndex: "sn",
      width: "8%",
      align: "center",
      fixed: "left",
      render: (text) => {
        return displayText2Line(text, "130px");
      },
    },
    {
      title: (
        <>
          設置年月日
          <br />
          交換年月日
        </>
      ),
      dataIndex: "dateImplements",
      width: "10%",
      align: "center",
      fixed: "left",
      render: (text, row) => {
        const listHistories = !isEmpty(row.histories)
          ? Object.values(row.histories)
          : [];
        return (
          <div>
            {listHistories.map((year, i) => {
              return year.map((item, index) => {
                if (
                  item.contractorValue === "交換" ||
                  item.contractorValue === "設置"
                ) {
                  return (
                    <div key={"" + i + index}>
                      {item.dateImplementation &&
                        moment
                          .unix(item.dateImplementation)
                          .format("YYYY/MM/DD")}
                    </div>
                  );
                }
                return null;
              });
            })}
          </div>
        );
      },
    },
    {
      title: (
        <>
          <p>修繕履歴</p>
          <ul className="pl0 mb0 mt8" style={{ listStyle: "none" }}>
            {listYear.map((year, i) => {
              return (
                <li
                  key={i}
                  className="pt5 pb5"
                  style={{
                    display: "inline-block",
                    width: `calc(100%/${listYear.length}`,
                    border: "1px solid #d9d9d9",
                  }}
                >
                  {year}
                </li>
              );
            })}
          </ul>
        </>
      ),
      dataIndex: "histories",
      width: "60%",
      minWidth: listYear.length * 50 + "px",
      align: "center",
      className: "timeline-history",
      render: (histories, row) => {
        let maxLength = 1;
        !isEmpty(histories) &&
          Object.values(histories).forEach((item) => {
            if (item.length > maxLength) maxLength = item.length;
          });
        return (
          <ul
            className="pl0 mb0"
            style={{ listStyle: "none", minWidth: listYear.length * 50 + "px" }}
          >
            {listYear.map((year, i) => {
              let historyInYear = histories[year];
              if (!historyInYear) {
                historyInYear = [];
              }
              historyInYear = historyInYear.concat(
                new Array(maxLength - historyInYear.length).fill({})
              );

              return (
                <li
                  key={i}
                  className=""
                  style={{
                    display: "inline-block",
                    width: `calc(100%/${listYear.length}`,
                  }}
                >
                  <ul className="pl0 mb0" style={{ listStyle: "none" }}>
                    {historyInYear.map((item, index) => {
                      const thisClassification = ddlClassification.options.find(
                        (x) => x.classification === item.contractorValue
                      );
                      return (
                        <li
                          key={index}
                          className="w100 pt5 pb5"
                          style={{
                            border:
                              item.contractorValue !== undefined
                                ? `1px solid black`
                                : "1px solid #d9d9d9",
                            backgroundColor:
                              item.type == 2
                                ? DIVISION_PLAN
                                : thisClassification
                                ? thisClassification.color
                                : "white",
                            cursor:
                              item.contractorValue !== undefined
                                ? "pointer"
                                : "default",
                          }}
                          onClick={() => {
                            if (item.contractorValue !== undefined) {
                              setSelectMaintain({
                                ...item,
                                machineForSiteOfficeName:
                                  row.machineForSiteOfficeName,
                              });
                            }
                          }}
                        >
                          <Tooltip title={item.contractorValue}>
                            <p className="display-1-line mb0">
                              {item.contractorValue || <>&nbsp;</>}
                            </p>
                          </Tooltip>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      width: "5%",
      align: "center",
      render: (text, row) => {
        return (
          <>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              {isLeader && (
                <Button
                  onClick={() => handleDeleteMachine(row.machineCode)}
                  className="mr5 btnDelete"
                >
                  <DeleteOutlined />
                </Button>
              )}
            </Tooltip>
          </>
        );
      },
    },
  ];
  if (isEndUser) {
    initColumns.pop();
  }

  const fetchClassification = async (value) => {
    try {
      const res = await ApiMachine.getClassification();
      if (res && !isEmpty(res.data)) {
        setDdlClassification({
          ...ddlClassification,
          options: res.data.map((item) => ({
            ...item,
            label: item.classification,
            value: item.id,
          })),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="des-content machine-content">
      <TitlePage name={lang.MAINTAINCE_HISTORY} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        {/* Group input */}
        <div>
          <Form
            onFinish={search_onClick}
            name="searchRole"
            layout={"vertical"}
            form={formSearch}
            className="formStyle"
          >
            <Row align="stretch" gutter={[8, 8]}>
              <Col span={24} className="mr10">
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={12}>
                    <Input
                      placeholder="機器名"
                      value={txtMachine.value}
                      onChange={(v) => {
                        setTxtMachine({ ...txtMachine, value: v.target.value });
                      }}
                      maxLength={255}
                      {...enterSearch(search_onClick)}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Select
                      className="w100"
                      placeholder="区分"
                      onChange={(value) => {
                        setDdlClassification({
                          ...ddlClassification,
                          value: value,
                        });
                      }}
                      allowClear={true}
                      value={
                        ddlClassification.value ? ddlClassification.value : null
                      }
                    >
                      {ddlClassification.options.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.value}
                          style={{}}
                        >
                          <div
                            className="mr8"
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: "50%",
                              backgroundColor: item.color,
                              display: "inline-block",
                            }}
                          ></div>
                          {item.classification}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Button
                      // htmlType="submit"
                      className="buttonPC button--info wAuto mr12"
                      onClick={() => search_onClick()}
                      style={isPC ? {} : { minWidth: "unset" }}
                    >
                      <SearchOutlined className="" />
                      {isPC && lang.SEARCH}
                    </Button>
                    <Button
                      className="buttonPC button--outline --todo-- wAuto"
                      onClick={() => clearInputSearch()}
                      style={isPC ? {} : { minWidth: "unset" }}
                    >
                      <ClearOutlined />
                      {isPC && lang.CLEAR}
                    </Button>
                    {!isEndUser && (
                      <ButtonModalClassification
                        callback={fetchClassification}
                        fullButton={true}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        {/*grid*/}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch list-machine"
          columns={initColumns}
          dataSource={grid.data}
          pagination={false}
          scroll={{ x: "auto" }}
        />
        {grid.data.length > 0 && (
          <Row justify="center" className="mt20">
            <Pagination
              showSizeChanger
              onChange={(value) => {
                search_onClick(value - 1);
                pushObject2QueryReplace({ page: value });
              }}
              defaultCurrent={Number(page + 1) || 1}
              current={page + 1}
              total={totalItem}
              pageSize={PAGE_SIZE}
            />
          </Row>
        )}
      </Content>
      <Loading show={showLoading}></Loading>
      {selectMaintain && (
        <Drawer
          title={
            <p className="mb0 display-2-line">
              {selectMaintain.machineForSiteOfficeName}
            </p>
          }
          width={335}
          onClose={() => setSelectMaintain(null)}
          open={selectMaintain}
          visible={selectMaintain}
          bodyStyle={{ padding: 0 }}
        >
          <Descriptions size="small" column={1} bordered>
            <Descriptions.Item
              label={lang.MACHINE_NAME}
              labelStyle={{ width: 120, fontWeight: "bold" }}
            >
              {selectMaintain.machineForSiteOfficeName}
            </Descriptions.Item>
            <Descriptions.Item
              label="実施年月日"
              labelStyle={{ width: 120, fontWeight: "bold" }}
            >
              {selectMaintain.dateImplementation
                ? moment
                    .unix(selectMaintain.dateImplementation)
                    .format("YYYY/MM/DD")
                : ""}
            </Descriptions.Item>
            <Descriptions.Item
              label={lang.MAINTAINCE_DIVISION}
              labelStyle={{ width: 120, fontWeight: "bold" }}
            >
              {selectMaintain.division}
            </Descriptions.Item>
            <Descriptions.Item
              label={lang.MAINTAINCE_PIC}
              labelStyle={{ width: 120, fontWeight: "bold" }}
            >
              {selectMaintain.contractorValue}
            </Descriptions.Item>
            <Descriptions.Item
              label={lang.MAINTAINCE_OVERVIEW}
              labelStyle={{ width: 120, fontWeight: "bold" }}
            >
              {selectMaintain.overview}
            </Descriptions.Item>
            <Descriptions.Item
              label={lang.MAINTAINCE_WORK_CONTENT}
              labelStyle={{ width: 120, fontWeight: "bold" }}
            >
              {selectMaintain.workContent}
            </Descriptions.Item>
          </Descriptions>
        </Drawer>
      )}
    </div>
  );
}

export default Machine_list;

const displayText2Line = (text, maxWidth = "150px") => {
  return (
    <p className="display-2-line mb0" style={{ maxWidth: maxWidth }}>
      <Tooltip title={text}>{text}</Tooltip>
    </p>
  );
};
