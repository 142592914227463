class itemPreCheck {
  constructor(
    ID,
    Order,
    CheckOrder = "",
    Checked = true,
    CheckItemCode = "",
    CheckItemName = "",
    NormalLowerControlValue = "",
    NormalUpperControlValue = "",
    Mode = "",
    Unit = "",
    Choices = "",
    InspectionTiming = "",
    ItemJudgement = "",
    DefaultType = "",
    DefaultValue = "",
    AccumulatedType = "",
    AccumulatedTypeValue = "",
    AccumulatedGroupItemCodes = "",
    Multiplier = "",
    MultipleTime = false,
    TimeArr = []
  ) {
    this.ID = ID;
    this.Order = Order;
    this.CheckOrder = CheckOrder;
    this.Checked = Checked;
    this.CheckItemCode = CheckItemCode;
    this.CheckItemName = CheckItemName;
    this.NormalLowerControlValue = NormalLowerControlValue;
    this.NormalUpperControlValue = NormalUpperControlValue;
    this.Mode = Mode;
    this.Unit = Unit;
    this.Choices = Choices;
    this.InspectionTiming = InspectionTiming;
    this.ItemJudgement = ItemJudgement;
    this.DefaultType = DefaultType;
    this.DefaultValue = DefaultValue;
    this.AccumulatedType = AccumulatedType;
    this.AccumulatedTypeValue = AccumulatedTypeValue;
    this.AccumulatedGroupItemCodes = AccumulatedGroupItemCodes;
    this.Multiplier = Multiplier;
    this.MultipleTime = MultipleTime;
    this.TimeArr = TimeArr;
  }
}

export default itemPreCheck;
