import React, { useState, useRef, useEffect } from "react";
import "./registration.scss";
import API from "@src/api/backend/registration";
import { Row, Col, Button, Input, Table, Select, message, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { loadingDesktop } from "@src/redux/loading";
import { saveFile } from "@src/constants/utils";
import { getFactories } from "@src/redux/auth";
import FactoryClass from "@src/models/factory";
import { lang, COMMON_CONSTANT } from "@src/constants/common.const";
// import { openPopupDesktop } from "@src/redux/confirm";
import BoxContent from "@src/component/Desktop/content";
import {
  CloudDownloadOutlined,
  ClearOutlined,
  CloudUploadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
function ChecklistRegistration() {
  const columns = [
    {
      title: lang.NAME_SHEET,
      dataIndex: "SheetName",
      width: "30%",
      align: "center",
    },
    {
      title: lang.NUMBER_ROW,
      dataIndex: "RowNum",
      width: "20%",
      align: "center",
    },
    {
      title: lang.MESSAGE,
      dataIndex: "ErrorMessage",
      width: "50%",
      align: "center",
    },
  ];
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [data, updateData] = useState([]);
  const [nameFile, updateNameFile] = useState(null);
  const [dataFileReader, updateFileReader] = useState(null);
  const [factory, updateFactory] = useState(null);
  const [factoryName, updateFactoryName] = useState(null);
  const { factories } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getFactories());
  }, [dispatch]);
  const uploadFile = () => {
    clearFile();
    inputFile.current.click();
  };
  const clearFile = () => {
    updateFileReader(null);
    updateNameFile(null);
    inputFile.current.value = null;
  };
  const getFactoryCode = (factoryCode) => {
    updateFactory(factoryCode);
    factories.forEach((item) => {
      if (Number(item.siteOfficeMasterCode) === Number(factoryCode)) {
        updateFactoryName(item.siteOfficeMasterName);
      }
    });
  };
  const onImportExcel = (file) => {
    updateFileReader(null);
    // Get the uploaded file object
    const { files } = file.target;
    updateNameFile(files[0]);
    // Read the file through the FileReader object
    if (files[0]) {
      const fileReader = new FileReader();
      // Open the file in base64 mode
      fileReader.readAsDataURL(files[0]);
      updateFileReader(fileReader);
    }
  };
  const callGetStatus = (id, isFirstCall) => {
    if (isFirstCall) {
      API.checkStatus(id, isFirstCall);
    }
    setTimeout(function () {
      API.checkStatus(id)
        .then((statusImport) => {
          if (statusImport && statusImport.status === 200) {
            switch (Number(statusImport.data.status)) {
              case 0:
                callGetStatus(id);
                break;
              case 1:
                dispatch(loadingDesktop(false));
                Modal.success({
                  centered: true,
                  title: (
                    <span style={{ fontWeight: "bold" }}>{lang.SUCCESS}</span>
                  ),
                  content: COMMON_CONSTANT.BOM_C002,
                  okText: lang.OK,
                });
                updateData(null);
                break;
              case 2:
                dispatch(loadingDesktop(false));
                Modal.error({
                  centered: true,
                  title: (
                    <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>
                  ),
                  content: COMMON_CONSTANT.BOM_E029,
                  okText: lang.OK,
                });
                updateData(JSON.parse(statusImport.data.content));
                break;
              default:
                break;
            }
          }
        })
        .catch((error) => {
          dispatch(loadingDesktop(false));
          Modal.error({
            centered: true,
            title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
            content: COMMON_CONSTANT.BOM_E029,
            okText: lang.OK,
          });
        });
    }, 1000);
  };
  // download file
  const downloadFileChecklist = () => {
    if (factory) {
      dispatch(loadingDesktop(true));
      API.exportChecklist({
        SiteOfficeMasterCode: factory,
        SiteOfficeMasterName: factoryName,
      })
        .then((res) => {
          dispatch(loadingDesktop(false));
          if (res && res.status === 200) {
            saveFile(res);
          }
        })
        .catch(() => {
          dispatch(loadingDesktop(false));
        });
    } else {
      message.error(lang.PLEASE_SELECT_SITE);
    }
  };
  const importChecklist = () => {
    if (dataFileReader) {
      dispatch(loadingDesktop(true));
      API.importChecklist({
        ImportType: "IMPORT_CHECKLIST",
        FileData: dataFileReader.result,
        FileName: nameFile.name,
        SiteOfficeMasterCode: factory,
        SiteOfficeMasterName: factoryName,
      })
        .then((res) => {
          // dispatch(loadingDesktop(false));
          if (res && res.status === 200) {
            callGetStatus(res.data, true);
          } else {
            Modal.error({
              centered: true,
              title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
              content: COMMON_CONSTANT.BOM_E029,
              okText: lang.OK,
            });
          }
        })
        .catch((error) => {
          dispatch(loadingDesktop(false));
          if (error.response) {
            let arrMessage = error.response.data;
            Modal.error({
              centered: true,
              title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
              content: arrMessage.msg,
              okText: lang.OK,
            });
          }
        });
    } else {
      message.error(lang.PLEASE_CHOOSE_FILE);
    }
  };
  // render option select
  const renderOptionSelect = (arrFactories) => {
    let arrayOption = [];
    if (arrFactories) {
      arrFactories.forEach((item) => {
        item = new FactoryClass(item);
        arrayOption.push(
          <Select.Option key={item.factoryCode} value={item.factoryCode}>
            {item.factoryName}
          </Select.Option>
        );
      });
      return arrayOption;
    } else {
      return [];
    }
  };

  return (
    <div className="des-content">
      <BoxContent>
        <Row gutter={[32, 32]} className="rowNotMargin">
          <Col span={24}>
            <Row gutter={[16, 0]} align="middle" justify="center">
              <Col span={5} className="text-end">
                <Button
                  className="buttonPC button--info"
                  onClick={uploadFile}
                  icon={<UploadOutlined />}
                >
                  {lang.SELECT_FILE}
                </Button>
              </Col>
              <Col span={11}>
                <Input
                  type="text"
                  disabled
                  value={nameFile ? nameFile.name : ""}
                  suffix={
                    <ClearOutlined
                      onClick={clearFile}
                      style={{ color: "red" }}
                    />
                  }
                />
                <input
                  type="file"
                  hidden
                  accept=".xlsx, .xls"
                  onChange={onImportExcel}
                  ref={inputFile}
                />
              </Col>
            </Row>
            <Row
              gutter={[16, 0]}
              align="middle"
              justify="center"
              style={{ paddingTop: "16px" }}
            >
              <Col className="titleText text-end" span={5}>
                {lang.NAME_OFFICE_SITE}
              </Col>
              <Col span={11}>
                <Select
                  onChange={getFactoryCode}
                  style={{ width: "100%" }}
                  placeholder={lang.NAME_OFFICE_SITE}
                >
                  {renderOptionSelect(factories)}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              className="gridSearch"
              columns={columns}
              dataSource={data}
              pagination={{ position: ["bottomCenter"], pageSize: 10 }}
            />
          </Col>
          <Col span={24}>
            <Row className="rowNotMargin" gutter={[12, 0]}>
              <Col flex="auto" />
              <Col flex="none">
                <Button
                  className="buttonPC button--outline --todo-- wAuto"
                  onClick={downloadFileChecklist}
                  icon={
                    <CloudDownloadOutlined
                      style={{
                        color: "#f50",
                      }}
                    />
                  }
                >
                  {lang.EXPORT_REPORT_CHECKLIST}
                </Button>
              </Col>
              <Col flex="none" style={{ paddingRight: 0 }}>
                <Button
                  className="buttonPC button--info"
                  onClick={importChecklist}
                  icon={<CloudUploadOutlined />}
                >
                  {lang.REGISTRY}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </BoxContent>
    </div>
  );
}

export default ChecklistRegistration;
