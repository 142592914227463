import React, { useState, useEffect } from "react";
import { Row, Button, Image, Descriptions, Col } from "antd";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import { KEY, lang } from "../../constants/common.const";
import API from "../../api/backend/officeDetail";
import BreakLine from "../../component/Desktop/breakLine";
import TitlePage from "../../component/Desktop/titlePage";
import BoxContent from "@src/component/Desktop/content";

import {
  isAdminOffice,
  isHeadOffice,
  MSG_CATCH,
  showMessage,
} from "../../constants/utils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RollbackOutlined, EditOutlined } from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
import { useMediaQuery } from "react-responsive";

function ViewOffice({ isEndUser }) {
  let { id: modeEdit, id } = useParams();
  const history = useHistory();
  const { selectedFactory } = useSelector((state) => state.auth);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  let [showLoading, setShowLoading] = useState(false);

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  const [dataDetail, setDataDetail] = useState({});
  const [txtOfficeID, settxtOfficeID] = useState(new classText());
  const [txtTitle, settxtTitle] = useState(new classText());
  const [txtOfficeServer, settxtOfficeServer] = useState(new classDdl());
  const [txtNote, settxtNote] = useState(new classDdl());
  const [txtZipCode, settxtZipCode] = useState(new classText());
  const [txtAddress, settxtAddress] = useState(new classText());
  const [txtState, settxtState] = useState(new classText());
  const [ddlStatus, setddlStatus] = useState(new classText());
  const [txtEffectStartDate, settxtEffectStartDate] = useState(new classText());
  const [txtEffectEndDate, settxtEffectEndDate] = useState(new classText());
  const [imgAvatar, setimgAvatar] = useState(new classText());
  const [custormerApprove, setCustormerApprove] = useState(false);

  const listField = [
    {
      title: "事業所コード",
      value: txtOfficeID.value,
      inlineDisplay: true,
    },
    // {
    //   title: "事業所名",
    //   value: txtTitle.value,
    // },
    {
      title: "郵便番号",
      value: txtZipCode.value,
      inlineDisplay: true,
    },
    {
      title: "住所",
      value: txtAddress.value,
    },
    {
      title: "都道府県名",
      value: txtState.value,
      inlineDisplay: true,
    },
    {
      title: lang.STATUS,
      value: ddlStatus.value,
      inlineDisplay: true,
    },
    {
      title: "契約開始日",
      value: txtEffectStartDate.value,
      inlineDisplay: true,
    },
    {
      title: "契約終了日",
      value: txtEffectEndDate.value,
      inlineDisplay: true,
    },
    {
      title: "事業所長",
      value: dataDetail.businessManagerName,
    },
    {
      title: "副所長",
      value: dataDetail.depucityDirector1Name,
    },
    {
      title: "本社担当",
      value: dataDetail.headquartersChargeName,
    },

    {
      title: lang.CUSTORMER_APPROVE,
      value: custormerApprove ? lang.YES : lang.NO,
    },
    {
      title: "備考",
      value: txtNote.value,
      blockDisplay: true,
    },
  ];
  const renderFiledModeEndUser = listField.map((item, index) => {
    return (
      <Descriptions.Item
        span={item.inlineDisplay ? 1 : 2}
        key={index}
        label={item.title}
        labelStyle={{
          width: isPC ? "20%" : "30%",
          fontWeight: "bold",
          padding: "8px 8px",
        }}
        contentStyle={{
          padding: "8px 8px",
          whiteSpace: "pre-wrap",
        }}
      >
        {item.value || "-"}
      </Descriptions.Item>
    );
  });

  const renderViewDataModeEndUser = (
    <Row style={{ paddingTop: 0 }} gutter={[8, 8]}>
      <Col span={24}>
        <h1
          style={{ textAlign: "center", marginBottom: 20 }}
          className="bold white-space"
        >
          {txtTitle.value}
        </h1>
      </Col>
      <Col span={isPC ? 8 : 12}>
        <BoxContent>
          <div className="avatar">
            <Image width={"100%"} src={imgAvatar.value || "/img/default.png"} />
          </div>
        </BoxContent>
      </Col>
      <Col span={isPC ? 16 : 12}>
        <BoxContent>
          <Descriptions
            size="small"
            column={2}
            bordered
            style={{ maxHeight: "720px", overflow: "auto" }}
          >
            {renderFiledModeEndUser}
          </Descriptions>
        </BoxContent>
      </Col>
    </Row>
  );
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const init = () => {
    // Open loading
    openLoadingSpinner();

    Promise.all([modeEdit ? API.getDetailByID(id) : API.getDetail()])
      .then((res) => {
        if (res[0] && !res[0].data && id) {
          history.push("/OfficeManagement");
          return;
        }
        let data = res[0] ? res[0].data : null;

        if (data) {
          setDataDetail(data);
          settxtOfficeID({ ...txtOfficeID, value: data.siteOfficeMasterCode });
          settxtTitle({ ...txtTitle, value: data.siteOfficeMasterName });
          settxtOfficeServer({ ...txtOfficeServer, value: data.officeServer }); // 事業所サーバー
          settxtNote({ ...txtNote, value: data.note });
          settxtZipCode({
            ...txtZipCode,
            value: data.zipCode1 + " - " + data.zipCode2,
          });
          settxtAddress({ ...txtAddress, value: data.address });
          settxtState({ ...txtState, value: data.prefectureName });
          setddlStatus({ ...ddlStatus, value: data.statusValue });
          settxtEffectStartDate({
            ...txtEffectStartDate,
            value: data.startDate,
          });
          settxtEffectEndDate({ ...txtEffectEndDate, value: data.endDate });
          setimgAvatar({ ...imgAvatar, value: data.imageUrl });
          setCustormerApprove(data.customerApprove);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      init();
    }
  }, [allowRoute]);

  function backTo() {
    modeEdit ? history.goBack() : history.push("/OfficeDetail");
  }

  function onEdit() {
    history.push("/EditOffice");
  }

  return (
    <div className="des-content mt70">
      <Loading show={showLoading}></Loading>
      {!isEndUser ? (
        <>
          <TitlePage name={lang.OFFICE_DETAIL} />
          <GroupItem center>
            <div className="title-page d-block center mt0 mb0">
              <h1 className="bold white-space">{lang.OFFICE_DETAIL}</h1>
            </div>
            <div className="mt15"></div>
            {/* List item */}
            <BreakLine />
            <RowInput name="事業所コード" box>
              {txtOfficeID.value}
            </RowInput>
            <BreakLine />
            <RowInput name="事業所名" box>
              <div className="white-space">{txtTitle.value}</div>
            </RowInput>
            <BreakLine />
            {/* <RowInput name="事業所サーバー" box>
          {txtOfficeServer.value}
        </RowInput>
        <BreakLine /> */}

            <RowInput name="郵便番号" box>
              {txtZipCode.value}
            </RowInput>
            <BreakLine />
            <RowInput name="住所" box>
              <div className="white-space">{txtAddress.value}</div>
            </RowInput>
            <BreakLine />
            <RowInput name="都道府県名" box>
              {txtState.value}
            </RowInput>
            <BreakLine />
            <RowInput name={lang.STATUS} box>
              {ddlStatus.value}
            </RowInput>
            <BreakLine />
            <RowInput name="契約開始日" box>
              {txtEffectStartDate.value}
            </RowInput>
            <BreakLine />
            <RowInput name="契約終了日" box>
              {txtEffectEndDate.value}
            </RowInput>
            <BreakLine />
            <RowInput name="事業所長" box>
              {dataDetail.businessManagerName}
            </RowInput>
            <BreakLine />
            <RowInput name="副所長" box>
              {dataDetail.depucityDirector1Name}
            </RowInput>
            <BreakLine />
            <RowInput name="本社担当" box>
              {dataDetail.headquartersChargeName}
            </RowInput>
            <BreakLine />
            <RowInput name="備考" box>
              <div className="white-space">{txtNote.value}</div>
            </RowInput>
            <BreakLine />
            <RowInput name={lang.CUSTORMER_APPROVE} box>
              <div className="white-space">
                {custormerApprove ? lang.YES : lang.NO}
              </div>
            </RowInput>
            <BreakLine />
            <RowInput name="写真" box>
              <Image
                style={{
                  maxWidth: "500px",
                  width: "auto",
                  maxHeight: "150px",
                }}
                src={imgAvatar.value}
              />
            </RowInput>
            <BreakLine />

            <Row justify="end" className="mt10">
              {(isAdminOffice() || (isHeadOffice() && !modeEdit)) &&
                !isEndUser && (
                  <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={onEdit}
                  >
                    <EditOutlined />
                    {lang.EDIT}
                  </Button>
                )}
              {!isEndUser && (
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml15"
                  onClick={backTo}
                >
                  <RollbackOutlined />
                  {lang.BACK}
                </Button>
              )}
            </Row>
          </GroupItem>
        </>
      ) : (
        renderViewDataModeEndUser
      )}
    </div>
  );
}

export default ViewOffice;
