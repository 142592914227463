import React from "react";
import "./footerPage.scss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";
import rolesConst from "../../routers/roles/roles.const";
import { useLocation } from "react-router-dom";

function FooterPage({ children }) {
  const { collapsed } = useSelector((state) => state.sideBar);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const queryIsEndUSer = urlParams.get("isEndUser");

  const { roles } = useSelector((state) => state.auth);
  const isEndUser =
    [rolesConst.CUSTOMER, rolesConst.SPECIAL_CUSTOMER].includes(roles) ||
    location.pathname === "/EndUser" ||
    queryIsEndUSer == "true";
  return (
    <>
      <div className="footer-page-top"></div>
      <div
        className="footer-page"
        style={{
          width:
            isPC && !isEndUser
              ? `calc(100vw - ${collapsed ? "70px" : "220px"})`
              : "100%",
          height: "auto",
        }}
      >
        {children}
      </div>
    </>
  );
}

export default FooterPage;
