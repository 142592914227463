import React from "react";
import { Checkbox, Row, Divider, Col } from "antd";
import "./comboCheckbox.scss";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";
import { lang } from "../../constants/common.const";
import InfiniteScroll from "react-infinite-scroller";
import LoadingInline from "../../component/Popup/loading-inline";

function ComboCheckbox({
  options = [],
  checkedList = [],
  indeterminate,
  dataGroup = [new classGroup()],
  checkAll,
  onCheck,
  onCheckAll,
  msg,
  loadMore,
  stateData,
  ...other
}) {
  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={!stateData.loading && stateData.hasMore}
      useWindow={false}
    >
      {msg ? (
        msg
      ) : options.length > 0 ? (
        <div className="comboCheckboxRow" {...other}>
          {/* Select all */}
          <div className="checkbox-all">
            <Checkbox
              indeterminate={false}
              onChange={onCheckAll}
              checked={checkAll}
            >
              <b>全て</b>
            </Checkbox>
          </div>
          {/* Select option */}
          <div className="checkbox-list">
            {options.map((o, index) => {
              let checked = false,
                notAdd = true;
              checkedList.forEach((id) => {
                if (id === o.value) {
                  checked = true;
                }
              });
              dataGroup.forEach((g = new classGroup()) => {
                g.Machines.forEach((m = new classMachine()) => {
                  if (m.ID === o.value) {
                    notAdd = false;
                  }
                });
              });

              return (
                <div key={index}>
                  <label className={"m " + (notAdd ? "" : "chooseMachine")}>
                    <div className="m-title d-flex">
                      <Checkbox
                        onChange={(e) => {
                          // Checked
                          if (e.target.checked) {
                            checkedList.push(o.value);
                          } else {
                            // Uncheck
                            // Delete option
                            checkedList = checkedList.filter((v) => {
                              return v !== o.value;
                            });
                          }
                          // Update state
                          onCheck(checkedList);
                        }}
                        checked={checked}
                      />
                      <Row
                        justify="space-between"
                        className="align-center w100 ml5"
                      >
                        <Col className={"bold"}>{o.label}</Col>
                        <Col>{notAdd ? lang.NOT_ADDED : lang.ADDED}</Col>
                      </Row>
                    </div>
                    <Row className="m-more info-more">
                      {o.other.facilityName} • {o.other.buildingName} •{" "}
                      {o.other.floorName} • {o.other.roomName} •{" "}
                      {o.other.processName}
                    </Row>
                  </label>
                  <Divider className="m-space" />
                </div>
              );
            })}
          </div>
          <div className="content-center w100">
            <LoadingInline isLoadingInline={stateData.loading} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </InfiniteScroll>
  );
}

export default ComboCheckbox;
