/* eslint-disable react-hooks/exhaustive-deps */
import { getDataNotification } from "@src/redux/homeBanner";
import { Button, Col, Input, Modal, Row, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import API from "../../api/backend/homeBanner";
import KBN from "../../api/backend/kbn";
import BreakLine from "../../component/Desktop/breakLine";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";
import Roles, { RoleHeadOffice } from "@src/routers/roles/roles.const";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import {
  getDotFile,
  getNameFile,
  hasPreview,
  isImage,
  MSG_CATCH,
  showMessage,
  statusRes,
} from "../../constants/utils";
import ListHistoryCL from "../../page/History_CL/listPopup";

import {
  CloseCircleOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  PlusOutlined,
  SaveOutlined,
  ToTopOutlined,
} from "@ant-design/icons";

import StatusConst from "../../constants/status.const";

import PickUser from "../../component/homebanner/pickuser";
import classDdl from "../../models/control/dropdownlist";
import classPopup from "../../models/control/popup";
import classText from "../../models/control/text";
import { checkRoleScreen } from "../../utils/roles";

//Declare global variables
const { TextArea } = Input;
let FLAG_CHANGE_DATA = false;
const formDataAttach = new FormData();
let indexFile = 0;

function CreateHomeBanner() {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  const { allowRoute, headOfficeRole, roles } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();
  const { Option } = Select;

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  let [hdnChecklistID, sethdnChecklistID] = useState(new classText());
  let txtNameEl = useRef(null);

  // Focus
  const fileEl = useRef(null);
  const fileElManagement = useRef(null);

  let [txtTitle, settxtTitle] = useState(new classText()); //Required
  let [ddlType, setddlType] = useState(new classDdl()); //Required
  let [sender, setSender] = useState(new classDdl()); //Required
  let [txtContent, settxtContent] = useState(new classText()); //Required
  let [ddlModeUser, setDdlModeUser] = useState(
    new classDdl("1", [
      { key: "1", value: lang.EVERYONE },
      { key: "0", value: lang.DESIGNATED_PERSON },
    ])
  );
  let [txtUser, setTxtUser] = useState(new classText());
  let [txtUserRef, setTxtUserRef] = useState(null);

  let txtTitleEl = useRef(null);
  let ddlTypeEl = useRef(null);
  let senderEl = useRef(null);
  let txtContentEl = useRef(null);

  // Popup
  let [popupHistoryCL, setPopupHistoryCL] = useState(new classPopup());
  let [popupAttach, setPopupAttach] = useState(new classPopup());
  const [idSelect, setIdSelect] = useState(null);
  const [dataSelected, setDataSelected] = useState(null);
  // In-Popup
  let [tblAttach, setTblAttach] = useState([]);
  useEffect(() => {
    API.searchUserForBanner();
  }, []);

  // Column Attach
  const columnsAttach = [
    {
      title: "No",
      render: (text, item, index) => {
        return <div className="center">{++index}</div>;
      },
    },
    {
      title: "プレビュー",
      dataIndex: "FileName",
      render: (text, row) => {
        let urlFile = row.file
          ? URL.createObjectURL(row.file)
          : row.attachmentUrl;
        if (isImage(row.DotName)) {
          return (
            <div className="center">
              <a target="_blank" rel="noopener noreferrer" href={urlFile}>
                <img
                  width="48"
                  height="48"
                  border="0"
                  alt="プレビュー"
                  title={row.FileName}
                  dragid="0"
                  draggable="true"
                  src={urlFile}
                />
              </a>
            </div>
          );
        } else {
          return (
            <div className="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFile}
                title={row.FileName}
                download={hasPreview(row.DotName) ? null : row.FileName}
              >
                <PaperClipOutlined className="blue iconM" />
              </a>
            </div>
          );
        }
      },
    },
    {
      title: "ファイル名",
      dataIndex: "FileName",
      render: (text, row, index) => {
        return (
          <Input
            maxLength={255}
            defaultValue={text}
            onChange={(v) => {
              tblAttach[index]["FileName"] = v.target.value;
              setTblAttach(tblAttach);
            }}
          />
        );
      },
    },
    {
      title: "備考とメヂイアの挿入と操作",
      dataIndex: "Description",
      render: (text, row, index) => {
        return (
          <Input
            maxLength={255}
            defaultValue={text}
            onChange={(v) => {
              tblAttach[index]["Description"] = v.target.value;
              setTblAttach(tblAttach);
            }}
          />
        );
      },
    },
    {
      title: "削除",
      align: "center",
      render: (text, row, index) => {
        return (
          <DeleteOutlined
            className="iconM red"
            onClick={() => {
              let list = [...tblAttach].filter(
                (item) => item["ItemOrder"] !== row["ItemOrder"]
              );
              setTblAttach(list);
            }}
          />
        );
      },
    },
  ];

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [hdnChecklistID, txtTitle, txtContent, ddlType, txtNameEl]);

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      openLoadingSpinner();
      setInitPage(true);

      // Detail
      Promise.all([
        id ? API.getDetail(id) : null,
        KBN.GetHomeBannerType(),
        KBN.getHomeBannerSender(),
      ])
        .then((res) => {
          if (res[0] && !res[0].data && id) {
            history.push("/HomeBannerManagement");
            return;
          }
          let d1 = res[0] ? res[0].data : {};
          let typeOption = res[1] ? res[1].data.data : []; // ddlType
          let typeSender = res[2] ? res[2].data.data : [];
          // Data detail
          if (id) {
            setddlType({ ...ddlType, options: typeOption, value: d1.type });
            setSender({ ...sender, options: typeSender, value: d1.sender });
            // Detail - 0
            showDetail(d1);
          } else {
            setddlType({ ...ddlType, options: typeOption });
            if (roles === Roles.OFFICE_ADMIN) {
              setSender({
                ...sender,
                options: typeSender,
                value: typeSender[0].key,
              });
            } else if (RoleHeadOffice.INFO_SYSTEM_G === headOfficeRole) {
              setSender({
                ...sender,
                options: typeSender,
                value: typeSender[1].key,
              });
            } else if (
              RoleHeadOffice.OPERATION_MANAGE_UNIT === headOfficeRole
            ) {
              setSender({
                ...sender,
                options: typeSender,
                value: typeSender[3].key,
              });
            } else {
              setSender({ ...sender, options: typeSender });
            }
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          setTimeout(() => {
            setInitPage(false);
          }, 500); // waiting for setup
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);

  const showDetail = async (data) => {
    settxtTitle({ ...txtTitle, value: data.title });
    //settxtType({ ...txtType, value: data.type });
    settxtContent({ ...txtContent, value: data.content });

    // Popup attach
    data.listHomeBannerAttachment = data.listHomeBannerAttachment.map((v) => {
      let row = {};
      row["ItemOrder"] = v.itemOrder; // Key
      row["FileName"] = getNameFile(v.fileName);
      row["DotName"] = getDotFile(v.fileName);
      row["Description"] = v.description;
      row["attachmentUrl"] = v.attachmentUrl;
      return row;
    });
    indexFile = data.listHomeBannerAttachment.length + 1;
    setTblAttach(data.listHomeBannerAttachment);

    // user
    setDdlModeUser({ ...ddlModeUser, value: data.receiver });
    setTxtUser({ ...txtUser, users: data.receiverDetailValue });
  };

  // Popup common
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  const onCloseSearchHistory = () => {
    setPopupHistoryCL({ ...popupHistoryCL, show: false });
    setDataSelected(null);
    setIdSelect(null);
  };

  //Save function: 1
  function confirmSaveData() {
    if (validateSave()) {
      showConfirmMessage(COMMON_CONSTANT.BOM_C008, function () {
        openLoadingSpinner();
        // Create Form data
        const formData = new FormData();

        // Create Object
        let formInput = {
          Title: txtTitle.value,
          Type: ddlType.value,
          Sender: sender.value,
          Content: txtContent.value,
        };

        // FILE
        if (tblAttach.length > 0) {
          // Add to obj
          formInput["ListAttachment"] = tblAttach.map((v) => {
            v["FileName"] = v.FileName + "." + v.DotName; // Full file name
            return v;
          });
          // Add list file to FormDATA
          tblAttach.forEach((v, i) => {
            formData.append(`file[${i}]`, v.file);
          });
        }

        // User
        formInput["receiver"] = ddlModeUser.value;
        formInput["receiverDetail"] = Array.from(new Set(txtUser.users));

        formData.append("data", JSON.stringify(formInput));
        // Sent data to Server
        API.save(formData, id)
          .then((res) => {
            if (statusRes(res)) {
              // Reload notify
              dispatch(
                getDataNotification({
                  params: {
                    readFlag: StatusConst.STATUS_HOME_BANNER.NOTSEEN,
                    offset: 0,
                    limit: 100,
                  },
                })
              );

              // Success
              showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backToSearch);
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            let msg = "";
            if (
              err.response &&
              err.response.status === 400 &&
              err.response.data
            ) {
              msg = err.response.data.msg;
            } else {
              msg = MSG_CATCH();
            }
            showMessage(KEY.ERROR, msg);
          })
          .then(() => {
            closeLoadingSpinner();
          });
      });
    }
    return false;
  }

  function validateSave() {
    let flagErr = false,
      elmFocus;

    txtTitle = { ...txtTitle, value: txtTitle.value.trim(), error: false };
    ddlType = { ...ddlType, error: false };
    txtContent = {
      ...txtContent,
      value: txtContent.value.trim(),
      error: false,
    };
    txtUser = { ...txtUser, error: false };

    if (ddlModeUser.value === "0") {
      if (!txtUser.users || (txtUser.users && txtUser.users.length) === 0) {
        flagErr = true;
        // Border red
        txtUser = { ...txtUser, error: true };
        elmFocus = txtUserRef;
      }
    }

    if (txtContent.value.length === 0) {
      flagErr = true;
      // Border red
      txtContent = { ...txtContent, error: true };
      elmFocus = txtContentEl;
    }

    if (!ddlType.value || ddlType.value === "") {
      flagErr = true;
      // Border red
      ddlType = { ...ddlType, error: true };
      elmFocus = ddlTypeEl;
    }
    if (!sender.value || sender.value === "") {
      flagErr = true;
      // Border red
      sender = { ...sender, error: true };
      elmFocus = senderEl;
    }
    if (txtTitle.value.length === 0) {
      flagErr = true;
      // Border red
      txtTitle = { ...txtTitle, error: true };
      elmFocus = txtTitleEl;
    }

    // Focus item error
    elmFocus && elmFocus.current.focus();

    // Update state
    settxtTitle(txtTitle);
    setddlType(ddlType);

    settxtContent(txtContent);
    setTxtUser(txtUser);
    setSender(sender);
    if (!flagErr) {
      let require = false;
      let exceed = false;
      let min = false;

      // Show message err
      if (require || min || exceed) {
        const msg = [];
        const firstLine = "・";
        require && msg.push(firstLine + COMMON_CONSTANT.BOM_E031);
        exceed && msg.push(firstLine + COMMON_CONSTANT.BOM_E032);
        min && msg.push(firstLine + COMMON_CONSTANT.BOM_E034);

        // Set fail
        flagErr = true;
        // Show message
        showMessage(
          KEY.ERROR,
          <div className="white-space">{msg.join("\n")}</div>
        );
      }
    }

    return !flagErr;
  }

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      // No update
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(
        COMMON_CONSTANT.BOM_C018,
        function () {
          backToSearch();
        },
        function () {
          FLAG_CHANGE_DATA = true;
        }
      );
    }
  }

  // Start: Tbl Attach
  function showAttachFileLst() {
    // Show popup
    setPopupAttach({ ...popupAttach, show: true });
  }

  function readAttachFileUrl(ev) {
    let el = ev.target;
    if (el.files && el.files[0]) {
      formDataAttach.append(indexFile++, el.files[0]);
      el.value = ""; // Clear file
      // Render
      renderTableAttach();
    }
  }

  function renderTableAttach() {
    let list = [...tblAttach];
    let id = indexFile - 1;
    let file = formDataAttach.get(id);
    let row = {};
    row["ItemOrder"] = id; // Key
    row["FileName"] = getNameFile(file.name);
    row["DotName"] = getDotFile(file.name);
    row["Description"] = "";
    row["file"] = file; // File
    list.push(row);
    setTblAttach(list);
  }
  // End: Tbl Attach

  //  Render Option
  const renderOption = (options, optionBlank = true, senderType = false) => {
    if (options && options.length > 0) {
      if (senderType) {
        if (roles === Roles.OFFICE_ADMIN) {
          const arrSelect = options.filter(
            (item) => item.value === lang.OFFICE
          );
          return arrSelect.map((e) => (
            <Option value={e.key || e.id} key={e.key || e.id}>
              {e.value || e.name}
            </Option>
          ));
        } else {
          if (headOfficeRole === RoleHeadOffice.OPERATION_MANAGE_UNIT) {
            const arrSelect = options.filter(
              (item) =>
                item.value === lang.OFFICE ||
                item.value === RoleHeadOffice.OPERATION_MANAGE_UNIT
            );
            return (
              <>
                {arrSelect.map((e) => (
                  <Option value={e.key || e.id} key={e.key || e.id}>
                    {e.value || e.name}
                  </Option>
                ))}
              </>
            );
          } else {
            return (
              <>
                {options.map((e) => (
                  <Option value={e.key || e.id} key={e.key || e.id}>
                    {e.value || e.name}
                  </Option>
                ))}
              </>
            );
          }
        }
      } else {
        return (
          <>
            {optionBlank && <Option value="" key="blank"></Option>}
            {options.map((e) => (
              <Option value={e.key || e.id} key={e.key || e.id}>
                {e.value || e.name}
              </Option>
            ))}
          </>
        );
      }
    }
  };
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={id ? lang.HOME_BANNER_UPDATE : lang.HOME_BANNER_CREATE}
      />

      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">
            {id ? lang.HOME_BANNER_UPDATE : lang.HOME_BANNER_CREATE}
          </h1>
        </div>
        <div className="mt15"></div>
        <BreakLine />
        <RowInput name={lang.TITLE} required box>
          <Input
            ref={txtTitleEl}
            className={txtTitle.error ? STYLE.BORDER_RED : ""}
            value={txtTitle.value}
            onChange={(v) => {
              settxtTitle({
                ...txtTitle,
                value: v.target.value,
              });
            }}
          />
        </RowInput>
        <BreakLine />
        <RowInput name={lang.TYPE} required box>
          <Select
            ref={ddlTypeEl}
            value={ddlType.value}
            className={ddlType.error ? `${STYLE.BORDER_RED} w100` : "w100"}
            onChange={(v) => {
              setddlType({ ...ddlType, value: v });
            }}
          >
            {renderOption(ddlType.options)}
          </Select>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SENDER} required box>
          <Select
            ref={senderEl}
            value={sender.value}
            className={sender.error ? `${STYLE.BORDER_RED} w100` : "w100"}
            onChange={(v) => {
              setSender({ ...sender, value: v });
            }}
          >
            {renderOption(sender.options, true, true)}
          </Select>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.CONTENT} required box>
          <TextArea
            ref={txtContentEl}
            className={txtContent.error ? STYLE.BORDER_RED : ""}
            value={txtContent.value}
            onChange={(v) => {
              settxtContent({
                ...txtContent,
                value: v.target.value,
              });
            }}
            rows={12}
          />
        </RowInput>
        <BreakLine />
        <RowInput name={lang.ATTACH} box>
          <>
            <input
              type="file"
              className="d-none"
              // accept=".xls,.xlsx"
              ref={fileEl}
              onChange={readAttachFileUrl}
            />
            <Button
              type="link"
              onClick={() => fileEl.current.click()}
              className="buttonPC button--outline --todo-- wAuto"
              style={{
                marginBottom: 10,
              }}
            >
              <ToTopOutlined />
              {lang.ATTACH_FILE}
            </Button>

            {tblAttach.length > 0 &&
              tblAttach.map((item, index) => {
                let urlFile = item.file
                  ? URL.createObjectURL(item.file)
                  : item.attachmentUrl;

                return (
                  <Row
                    className="mb10"
                    key={index}
                    style={{ alignItems: "center" }}
                  >
                    <Col span={2}>
                      <Button
                        onClick={() => {
                          let list = [...tblAttach].filter(
                            (x) => x["ItemOrder"] !== item["ItemOrder"]
                          );
                          setTblAttach(list);
                        }}
                        className="btn-deletefile mr8"
                        style={{
                          background: "#f7f9fc",
                          minWidth: "unset",
                          height: 30,
                        }}
                        icon={<DeleteOutlined />}
                      />
                    </Col>
                    <Col>
                      <a href={urlFile}>
                        {item.FileName + "." + item.DotName}{" "}
                      </a>
                    </Col>
                  </Row>
                );
              })}
          </>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.RECIPIENT} box>
          <Select
            defaultValue={"1"}
            value={ddlModeUser.value}
            className={ddlModeUser.error ? `${STYLE.BORDER_RED} w100` : "w100"}
            onChange={(v) => {
              setDdlModeUser({ ...ddlModeUser, value: v });
            }}
          >
            {ddlModeUser.options.map((item) => (
              <Select.Option value={item.key}>{item.value}</Select.Option>
            ))}
          </Select>
        </RowInput>
        <BreakLine />
        {ddlModeUser.value === "0" && (
          <>
            <RowInput name={lang.USER_NAME} required box>
              <PickUser
                id={id || null}
                className={txtUser.error ? STYLE.BORDER_RED : ""}
                setRef={setTxtUserRef}
                get={txtUser}
                set={setTxtUser}
              ></PickUser>
            </RowInput>
            <BreakLine />
          </>
        )}

        {/* Button footer */}
        <Row justify="end" className="mt10 mb10">
          {/* <Button
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              showAttachFileLst();
            }}
          >
            <PaperClipOutlined className="orange " /> {lang.ATTACH}
          </Button> */}
          <Button
            className="buttonPC button--info wAuto ml15"
            onClick={() => {
              confirmSaveData();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={cancelSaveData}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
      {/* Search history check */}
      {popupHistoryCL.show && (
        <Modal
          centered
          width={1300}
          onCancel={onCloseSearchHistory}
          className="modal-msg modal-screen"
          title={<div className="center">{lang.HISTORY_CHECKLIST}</div>}
          visible={true}
          footer={[
            <Button
              disabled={!dataSelected}
              key={0}
              className="buttonPC button--info ml10"
              onClick={() => {
                sethdnChecklistID({
                  ...hdnChecklistID,
                  value: dataSelected.id,
                  message: dataSelected.checklistName,
                });
                onCloseSearchHistory();
              }}
            >
              <PlusOutlined />
              {COMMON_CONSTANT.ADD}
            </Button>,
            <Button
              key={1}
              className="buttonPC button--outline --todo-- wAuto"
              onClick={onCloseSearchHistory}
            >
              <CloseCircleOutlined />
              {lang.CLOSE}
            </Button>,
          ]}
        >
          <ListHistoryCL
            screen={false}
            setDataSelected={setDataSelected}
            idSelect={idSelect}
            setIdSelect={setIdSelect}
          />
        </Modal>
      )}
      {/* attachFileModal */}
      <Modal
        centered
        width="50%"
        bodyStyle={{ height: "400px", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.ATTACH}</div>}
        visible={popupAttach.show}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              setPopupAttach({ ...popupAttach, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <div className="mb15">
          新規挿入:
          <Button
            type="link"
            onClick={() => {
              fileEl.current.click();
            }}
          >
            <PaperClipOutlined className="iconM blue" />
          </Button>
          {/* <Button type="link">
            <CameraOutlined className="iconM blue" />
          </Button> */}
        </div>
        <input
          type="file"
          className="d-none"
          // accept=".xls,.xlsx"
          ref={fileEl}
          onChange={readAttachFileUrl}
        />
        <input
          type="file"
          multiple
          className="d-none"
          // accept=".xls,.xlsx"
          ref={fileElManagement}
        />
        <Table
          className="tbl-original"
          rowKey="ItemOrder"
          dataSource={tblAttach}
          columns={columnsAttach}
          pagination={false}
        />
      </Modal>
    </div>
  );
}

export default CreateHomeBanner;
