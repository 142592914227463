import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  DatePicker,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ApiMachine from "../../api/backend/machineManagement";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  PAGE_SIZE,
} from "../../constants/common.const";
import {
  enterSearch,
  getQuerySearch,
  MSG_CATCH,
  pushObject2Query,
  pushObject2QueryReplace,
  renderOption,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import { isEmpty, isNumber, isObject, isString } from "lodash";
import {
  ClearOutlined,
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
  CloudUploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { checkRoleScreen } from "../../utils/roles";
import moment from "moment";
import { DebounceSelect } from "@src/page/Calendar/index";
import API_CL from "../../api/backend/managementCL";
import rolesConst from "../../routers/roles/roles.const";

function Machine_list() {
  const history = useHistory();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const objQuery = getQuerySearch();

  const [formSearch] = Form.useForm();
  const [grid, setGrid] = useState(new classGrid());
  const [page, setPage] = useState(Number(objQuery.page) - 1 || 0);
  const [totalItem, setTotalItem] = useState(0);
  const location = useLocation();

  const [initRender, setInitRender] = useState(true);
  // Input Search
  const [txtMachine, setTxtMachine] = useState(
    new classText(objQuery.machineName || "")
  );
  const [ddlFacility, setDdlFacility] = useState(
    new classDdl(objQuery.facilityName || null)
  );
  const [ddlBuilding, setDdlBuilding] = useState(
    new classDdl(objQuery.buildingName || null)
  );
  const [ddlFloor, setDdlFloor] = useState(
    new classDdl(objQuery.floorName || null)
  );
  const [ddlRoom, setDdlRoom] = useState(
    new classDdl(objQuery.roomName || null)
  );
  const [ddlProcess, setDdlProcess] = useState(
    new classDdl(objQuery.processName || null)
  );
  const [checkListName, setCheckListName] = useState(
    new classDdl({
      label: objQuery.checkListName || null,
      value: objQuery.checkListName || null,
    })
  );
  const [workContent, setWorkContent] = useState(
    new classText(objQuery.workContent || null)
  );
  const [dateImplementationStart, setDateImplementationStart] = useState(
    new classText(
      objQuery.dateImplementationStart
        ? moment(objQuery.dateImplementationStart)
        : null
    )
  );
  const [dateImplementationEnd, setDateImplementationEnd] = useState(
    new classText(
      objQuery.dateImplementationEnd
        ? moment(objQuery.dateImplementationEnd)
        : null
    )
  );
  const [showLoading, setShowLoading] = useState(false);
  const { selectedFactory, allowRoute, isLeader } = useSelector(
    (state) => state.auth
  );
  const objClear = {
    value: null,
  };

  const checkFirstRender = useRef({
    page: 0,
    building: 0,
    floor: 0,
    room: 0,
    process: 0,
    count: 0,
  });
  const initRef = useRef({
    building: objQuery.buildingName,
    floor: objQuery.floorName,
    room: objQuery.roomName,
    facility: objQuery.facilityName,
    process: objQuery.processName,
  });

  const clearInputSearch = () => {
    setTxtMachine({ ...txtMachine, ...objClear });
    setCheckListName({ ...checkListName, ...objClear });
    setWorkContent({ ...workContent, ...objClear });
    setDateImplementationStart({ ...dateImplementationStart, ...objClear });
    setDateImplementationEnd({ ...dateImplementationEnd, ...objClear });
    setDdlBuilding({ ...ddlBuilding, ...objClear });
    setDdlFacility({ ...ddlFacility, ...objClear });
    setDdlFloor({ ...ddlFloor, ...objClear });
    setDdlProcess({ ...ddlProcess, ...objClear });
    setDdlRoom({ ...ddlRoom, ...objClear });
    formSearch.resetFields();
    //

    for (let i in searchParams) {
      if (searchParams.hasOwnProperty(i)) {
        searchParams[i] = null;
      }
    }
    search_onClick();
  };
  const listSelectSearch = [
    {
      name: "Facility",
      label: "施設",
      objectValue: ddlFacility,
      onchange: (value) => setDdlFacility({ ...ddlFacility, value }),
    },
    {
      name: "Process",
      label: "プロセス",
      objectValue: ddlProcess,
      onchange: (value) => setDdlProcess({ ...ddlProcess, value }),
    },
    {
      name: "Building",
      label: "棟",
      objectValue: ddlBuilding,
      onchange: (value) => setDdlBuilding({ ...ddlBuilding, value }),
    },
    {
      name: "Floor",
      label: "階",
      objectValue: ddlFloor,
      onchange: (value) => setDdlFloor({ ...ddlFloor, value }),
    },
    {
      name: "Room",
      label: "部屋",
      objectValue: ddlRoom,
      onchange: (value) => setDdlRoom({ ...ddlRoom, value }),
    },
  ];
  const renderListSelectSearch = () =>
    listSelectSearch.map((selectItem, index) => {
      return (
        <Col key={index} span={isPC ? 8 : 12}>
          <Select
            defaultValue={selectItem.objectValue.value}
            placeholder={selectItem.label}
            disabled={selectItem.objectValue.disabled}
            className="w100"
            value={selectItem.objectValue.value}
            onChange={selectItem.onchange}
            {...enterSearch(search_onClick)}
          >
            {renderOption(selectItem.objectValue.options)}
          </Select>
        </Col>
      );
    });
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Search Params
  const searchParams = {
    machineName: txtMachine.value ? txtMachine.value.trim() : null,
    checkListName:
      checkListName.value && checkListName.value.label
        ? checkListName.value.label.trim()
        : null,
    workContent: workContent.value ? workContent.value.trim() : null,
    dateImplementationStart: dateImplementationStart.value
      ? dateImplementationStart.value.format(KEY.DATE_DEFAULT)
      : null,
    dateImplementationEnd: dateImplementationEnd.value
      ? dateImplementationEnd.value.format(KEY.DATE_DEFAULT)
      : null,
    facilityName: ddlFacility.value,
    buildingName: ddlBuilding.value,
    floorName: ddlFloor.value,
    roomName: ddlRoom.value,
    processName: ddlProcess.value,
  };

  const search_onClick = async (pageNumber = 0) => {
    setPage(pageNumber || 0);
    //search
    try {
      setShowLoading(true);
      const responSearch = await ApiMachine.searchMachine({
        ...searchParams,
        limit: PAGE_SIZE,
        offset: pageNumber * PAGE_SIZE,
      });
      if (statusRes(responSearch)) {
        const { data, count } = responSearch.data;
        setTotalItem(count);
        const listGridSearch = data.map((machineItem) => ({
          ...machineItem,
          machineCode: machineItem.machineForSiteOfficeCode,
          name: machineItem.machineForSiteOfficeName,
          status: machineItem.status,
          institution: machineItem.facilityName,
          displayOnHistoryFlag: machineItem.displayOnHistoryFlag,
        }));
        setGrid({
          ...grid,
          data: listGridSearch,
          count,
        });
        pushObject2Query({
          ...searchParams,
          page: pageNumber + 1,
        });
      }
    } catch (error) {
      showMessageError(MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  const handleDeleteMachine = (code) => {
    const deleteFunc = async () => {
      try {
        setShowLoading(true);
        const responseDelete = await ApiMachine.deleteMachine({
          code,
        });
        if (statusRes(responseDelete)) {
          await search_onClick();
          //success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
        } else throw new Error(responseDelete);
      } catch (err) {
        let msg = MSG_CATCH();
        if (
          !isEmpty(err.response) &&
          isString(err.response.data) &&
          !isEmpty(err.response.data)
        ) {
          msg = err.response.data;
        }
        if (
          !isEmpty(err.response) &&
          isObject(err.response.data) &&
          isString(err.response.data.msg)
        ) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg);
      } finally {
        setShowLoading(false);
      }
    };
    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: deleteFunc,
      centered: true,
    });
  };
  const handleAddMaintainanceHistory = (code) => {
    const addFunc = async () => {
      try {
        setShowLoading(true);
        const responseDelete = await ApiMachine.addMaintainanceHistory({
          machineCode: code,
        });
        if (statusRes(responseDelete)) {
          await search_onClick();
          //success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
        } else throw new Error(responseDelete);
      } catch (err) {
        let msg = MSG_CATCH();
        if (
          !isEmpty(err.response) &&
          isString(err.response.data) &&
          !isEmpty(err.response.data)
        ) {
          msg = err.response.data;
        }
        if (
          !isEmpty(err.response) &&
          isObject(err.response.data) &&
          isString(err.response.data.msg)
        ) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg);
      } finally {
        setShowLoading(false);
      }
    };
    Modal.confirm({
      title: "アップデート",
      content: COMMON_CONSTANT.BOM_C009,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: addFunc,
      centered: true,
    });
  };
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      const fetchFacility = async () => {
        try {
          const resFacility = await ApiMachine.getFacility({
            siteOfficeMasterCode: selectedFactory,
          });
          const { data } = resFacility;
          const listOptionFacility = data.map((item) => ({
            key: item.facilityName,
            value: item.facilityName,
            code: item.facilityCode,
          }));
          setDdlFacility({
            ...ddlFacility,
            options: listOptionFacility,
            disabled: false,
            value: initRef.current.facility || null,
          });
        } catch (error) {
          showMessageError(MSG_CATCH());
        }
      };
      fetchFacility();
    }
  }, [allowRoute]);

  useEffect(() => {
    const fetchBuilding = async () => {
      if (ddlFacility && ddlFacility.value) {
        let currentFacilityCode = null;
        const currentFacility = ddlFacility.options.find(
          (item) => item.key === ddlFacility.value
        );
        if (currentFacility) {
          currentFacilityCode = currentFacility.code;
        }

        const resBuilding = await ApiMachine.getBuilding({
          siteOfficeMasterCode: selectedFactory,
          facilityCode: currentFacilityCode,
        });
        const { data } = resBuilding;
        const listBuildingOptions = data.map((item) => ({
          key: item.buildingName,
          value: item.buildingName,
          code: item.buildingCode,
        }));
        setDdlBuilding({
          ...ddlBuilding,
          options: listBuildingOptions,
          disabled: false,
          value: initRef.current.building || null,
        });
      } else {
        setDdlBuilding(new classDdl());
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchBuilding();
  }, [ddlFacility, allowRoute]);

  //fill floorOption
  useEffect(() => {
    const fetchFloor = async () => {
      if (ddlBuilding && ddlBuilding.value) {
        const currentBuilding = ddlBuilding.options.find(
          (item) => item.key === ddlBuilding.value
        );
        let currentBuildingCode = currentBuilding ? currentBuilding.code : null;
        const resFloor = await ApiMachine.getFloor({
          siteOfficeMasterCode: selectedFactory,
          buildingCode: currentBuildingCode,
        });
        const { data } = resFloor;
        const listFloorOptions = data.map((item) => ({
          key: item.floorName,
          value: item.floorName,
          code: item.floorCode,
        }));
        setDdlFloor({
          ...ddlFloor,
          options: listFloorOptions,
          disabled: false,
          value: initRef.current.floor || null,
        });
      } else {
        setDdlFloor(new classDdl());
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchFloor();
  }, [ddlBuilding, allowRoute]);

  //fill room Option
  useEffect(() => {
    const fetchRoom = async () => {
      if (ddlFloor && ddlFloor.value) {
        const currentFloor = ddlFloor.options.find(
          (item) => item.key === ddlFloor.value
        );
        let currentFloorCode = currentFloor ? currentFloor.code : null;
        const resRoom = await ApiMachine.getRooms({
          siteOfficeMasterCode: selectedFactory,
          floorCode: currentFloorCode,
        });
        const { data } = resRoom;
        const listRoomOptions = data.map((item) => ({
          key: item.roomName,
          value: item.roomName,
          code: item.roomCode,
        }));
        setDdlRoom({
          ...ddlRoom,
          options: listRoomOptions,
          disabled: false,
          value: initRef.current.room || null,
        });
      } else {
        setDdlRoom(new classDdl());
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchRoom();
  }, [ddlFloor, allowRoute]);

  // fill process
  useEffect(() => {
    const fetchProcess = async () => {
      if (ddlFacility && ddlFacility.value) {
        const currentFacility = ddlFacility.options.find(
          (item) => item.key === ddlFacility.value
        );
        let currentFacilityCode = null;
        if (currentFacility) {
          currentFacilityCode = currentFacility.code;
        }

        const resProcess = await ApiMachine.getProcess({
          siteOfficeMasterCode: selectedFactory,
          facilityCode: currentFacilityCode,
        });
        const { data } = resProcess;
        const listProcessOption = data.map((item) => ({
          key: item.processName,
          value: item.processName,
          code: item.processCode,
        }));
        setDdlProcess({
          ...ddlProcess,
          options: listProcessOption,
          disabled: false,
          value: initRef.current.process || null,
        });
      } else {
        setDdlProcess(new classDdl());
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchProcess();
  }, [ddlFacility, allowRoute]);

  useEffect(() => {
    if (checkFirstRender.current.building++ >= 2) {
      initRef.current.building = null;
      initRef.current.facility = null;
    }
  }, [ddlBuilding]);
  useEffect(() => {
    if (checkFirstRender.current.floor++ >= 3) {
      initRef.current.floor = null;
      initRef.current.facility = null;
    }
  }, [ddlFloor]);
  useEffect(() => {
    // window.history.replaceState(null, "", "/machineManagement");
  }, []);
  useEffect(() => {
    if (checkFirstRender.current.room++ >= 4) {
      initRef.current.room = null;
      initRef.current.facility = null;
    }
  }, [ddlRoom]);
  // useEffect(() => {
  //   if (checkRoleScreen(location.pathname, allowRoute)) {
  //     if (
  //       !initRef.current.facility &&
  //       !initRef.current.building &&
  //       !initRef.current.floor &&
  //       !initRef.current.room
  //     ) {
  //       // clearInputSearch();
  //     }
  //   }
  // }, [allowRoute, selectedFactory]);
  useEffect(() => {
    if (!checkRoleScreen(location.pathname, allowRoute)) return;

    if (initRender) {
      setInitRender(false);
      search_onClick(page || 0);
    } else {
      clearInputSearch();
    }
  }, [allowRoute, selectedFactory]);
  const initColumns = [
    {
      title: lang.MACHINE_CODE,
      dataIndex: "machineCode",
      align: "center",
      width: "15%",
    },
    {
      title: lang.MACHINE_NAME,
      dataIndex: "name",
      width: "30%",
      align: "center",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              <Link
                to={"/ViewMachine/" + row.machineCode}
                className="white-space"
              >
                {text}
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: lang.MACHINE_STATUS,
      dataIndex: "status",
      width: "15%",
      align: "center",
    },
    {
      title: lang.MACHINE_INSTITUTION,
      dataIndex: "institution",
      width: "15%",
      align: "center",
    },
    {
      title: "点検表名",
      dataIndex: "checklistName",
      width: "15%",
      align: "center",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 180,
      render: (text, row) => {
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={!isLeader} className="mr5 btnEdit">
                <Link to={"/EditMachine/" + row.machineCode} className="w100">
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>

            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={!isLeader}
                onClick={() => handleDeleteMachine(row.machineCode)}
                className="mr5 btnDelete"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>

            {/* Add */}
            <Button
              disabled={row.displayOnHistoryFlag || !isLeader}
              onClick={() => handleAddMaintainanceHistory(row.machineCode)}
              className="mr5 btnClone"
            >
              <PlusOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const fetchCheckList = async (value) => {
    try {
      const res = await API_CL.getData({ params: { checklistName: value } });
      if (res && !isEmpty(res.data)) {
        return res.data.data.map((item) => ({
          label: item.checklistName,
          value: item.id,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="des-content machine-content">
      <TitlePage name={lang.MACHINE_MANAGEMENT} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        {/* Group input */}
        <div>
          <Form
            name="searchRole"
            layout={"vertical"}
            form={formSearch}
            className="formStyle"
          >
            <Row align="stretch" gutter={[8, 8]}>
              <Col span={24} className="mr10">
                <Row gutter={[8, 8]}>
                  <Col span={isPC ? 8 : 24}>
                    <Input
                      defaultValue={txtMachine.value}
                      placeholder="機器名"
                      value={txtMachine.value}
                      onChange={(v) => {
                        setTxtMachine({ ...txtMachine, value: v.target.value });
                      }}
                      maxLength={255}
                      {...enterSearch(search_onClick)}
                    />
                  </Col>
                  {renderListSelectSearch()}
                  <Col span={isPC ? 8 : 24}>
                    <DebounceSelect
                      className="w100"
                      fetchOptions={fetchCheckList}
                      onChange={(newValue) => {
                        setCheckListName({
                          ...checkListName,
                          value: newValue,
                        });
                      }}
                      allowClear={true}
                      value={
                        checkListName.value
                          ? checkListName.value
                          : { value: null }
                      }
                      placeholder="点検表名"
                    />
                  </Col>
                  <Col span={isPC ? 8 : 24}>
                    <Input
                      defaultValue={workContent.value}
                      placeholder="修繕内容"
                      value={workContent.value}
                      onChange={(v) => {
                        setWorkContent({
                          ...workContent,
                          value: v.target.value,
                        });
                      }}
                      maxLength={255}
                      {...enterSearch(search_onClick)}
                    />
                  </Col>
                  <Col span={isPC ? 8 : 24}>
                    <DatePicker
                      defaultValue={
                        objQuery.dateImplementationStart
                          ? moment(objQuery.dateImplementationStart)
                          : null
                      }
                      value={dateImplementationStart.value}
                      style={{ width: "47%" }}
                      placeholder="日付から"
                      // value={dateImplementationStart.value}
                      onChange={(v) => {
                        setDateImplementationStart({
                          ...dateImplementationStart,
                          value: v,
                        });
                      }}
                      disabledDate={(currentDate) => {
                        return (
                          dateImplementationEnd.value &&
                          dateImplementationEnd.value.valueOf() <
                            currentDate.valueOf()
                        );
                      }}
                    />
                    <div
                      className="text-center"
                      style={{ width: "6%", display: "inline-block" }}
                    >
                      ~
                    </div>
                    <DatePicker
                      disabledDate={(currentDate) => {
                        return (
                          dateImplementationStart.value &&
                          dateImplementationStart.value.valueOf() >
                            currentDate.valueOf()
                        );
                      }}
                      defaultValue={
                        objQuery.dateImplementationEnd
                          ? moment(objQuery.dateImplementationEnd)
                          : null
                      }
                      value={dateImplementationEnd.value}
                      style={{ width: "47%" }}
                      placeholder="日付まで"
                      onChange={(v) => {
                        setDateImplementationEnd({
                          ...dateImplementationEnd,
                          value: v,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col span={24} className="right">
              <Button
                className="buttonPC button--info wAuto mr8"
                onClick={() => search_onClick()}
              >
                <SearchOutlined className="" />
                {lang.SEARCH}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto"
                onClick={() => clearInputSearch()}
              >
                <ClearOutlined />
                {lang.CLEAR}
              </Button>
            </Col>
          </Form>
        </div>
      </Content>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Row justify="space-between ">
          <Col span={12}>
            {isLeader && (
              <>
                <Button
                  type="link"
                  className="ant-btn mb15 buttonPC button--outline --todo-- wAuto"
                  onClick={() => {
                    history.push("/AddMachine");
                  }}
                >
                  <PlusOutlined />
                  {lang.ADD}
                </Button>

                <Button
                  type="link"
                  className="ant-btn mb15 ml15 buttonPC button--outline --todo-- wAuto"
                  onClick={() => {
                    history.push("/ImportMachine");
                  }}
                >
                  <CloudUploadOutlined />
                  機器台帳一括作成
                </Button>
              </>
            )}
          </Col>

          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>

        {/*grid*/}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch list-machine"
          columns={initColumns}
          dataSource={grid.data}
          pagination={false}
        />
        {grid.data.length > 0 && (
          <Row justify="center" className="mt20">
            <Pagination
              showSizeChanger
              onChange={(value) => {
                search_onClick(value - 1);
                pushObject2QueryReplace({ page: value });
              }}
              defaultCurrent={Number(page + 1) || 1}
              current={page + 1}
              total={totalItem}
              pageSize={PAGE_SIZE}
            />
          </Row>
        )}
      </Content>
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default Machine_list;
