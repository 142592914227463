class TemplateChecklist {
  constructor(data = {}) {
    this.id = data.id;
    this.tempName = data.title;
    this.createTime = data.created;
    this.updateTime = data.updated;
    this.tempCode = data.id;
    this.category = data.reportType;
    this.categoryValue = data.reportTypeValue;
    this.attachment = data.originalName;
    this.linkDown = data.downloadUrl;
    this.user = data.updator;
  }
}
export default TemplateChecklist;
