import {
  HistoryOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Image,
  List,
  Modal,
  Row,
  Timeline,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { UserMangementApi } from "../../api/backend/userManagement";
import BreakLine from "../../component/Desktop/breakLine";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";
import FooterPage from "../../component/footer/footerPage";
import {
  COMMON_CONSTANT,
  KEY,
  SIZE_IMAGE,
  lang,
} from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import classText from "../../models/control/text";
import { changeAvatar } from "../../redux/auth";
import ROLES from "./../../routers/roles/roles.const";
import FileImageInput from "./FileImageInput";

function ViewUserInfo() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  // Get params
  const { userName } = useParams();

  const isLeader = [ROLES[1], ROLES[2]].includes(authState.roles);
  const isBoss = authState.roles === ROLES[1];

  const isOwnInfomation = !userName || userName === authState.username;

  //Neu ko phai truong nha may thi ko xem duoc info cua user khac
  useMemo(() => {
    if (!isOwnInfomation && !isLeader) {
      history.push("/OfficeDetail");
    }
  }, [userName]);

  // User state
  const [displayName, setDisplayName] = useState(new classText());
  const [email, setEmail] = useState(new classText());
  const [signatureUrl, setSignatureUrl] = useState(new classText());
  const [avatarUrl, setAvatarUrl] = useState(new classText());
  const [avatarName, setAvatarName] = useState(new classText());
  const [signatureName, setSignatureName] = useState(new classText());
  const [username, setUserName] = useState(new classText());
  const [data, setData] = useState();
  const [timeline_detail, setTimeline_detail] = useState(null);

  // Loading state
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Change url image
  const changeSignatureUrl = (newUrl, newName) => {
    setSignatureUrl({ ...signatureUrl, value: newUrl });
    setSignatureName({ ...signatureName, value: newName });
  };
  const changeAvatarUrl = (newUrl, newName) => {
    setAvatarUrl({ ...avatarUrl, value: newUrl });
    setAvatarName({ ...avatarName, value: newName });
  };

  // List data
  const listDataText = [
    {
      title: lang.AVATAR_USER,
      value: avatarUrl.value,
      type: "image",
      setImageUrl: changeAvatarUrl,
      name: "avatar",
    },
    {
      title: lang.USER_NAME,
      value: username.value,
      type: "text",
    },
    {
      title: lang.DISPLAYNAME_USER,
      value: displayName.value,
      type: "text",
    },
    {
      title: lang.EMAIL_USER,
      value: email.value,
      type: "text",
    },
    {
      title: lang.STAMP_USER,
      value: signatureUrl.value,
      type: "image",
      setImageUrl: changeSignatureUrl,
    },
  ];

  // Render list Data
  const renderData = listDataText.map((item, index) => (
    <div key={index}>
      <BreakLine />
      <RowInput name={item.title} box>
        {item.type === "text" ? (
          <span>{item.value}</span>
        ) : isOwnInfomation ? (
          <FileImageInput
            imageUrl={item.value}
            setImageUrl={item.setImageUrl}
            title={item.title}
          />
        ) : (
          <Image
            width={SIZE_IMAGE}
            height={SIZE_IMAGE}
            src={item.value || "/img/default.png"}
          />
        )}
      </RowInput>
      <BreakLine />
    </div>
  ));

  // Call api get info user
  const getInforUserByUserName = async () => {
    try {
      setLoading(true);
      const userDetailRes =
        isLeader && !isOwnInfomation
          ? await UserMangementApi.getInforUserByUserName({
              UserName: userName,
            })
          : await UserMangementApi.getOwnInformation();
      if (userDetailRes.data) {
        setDetailUser(userDetailRes.data);
      }
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  const setDetailUser = (data) => {
    setDisplayName({ ...displayName, value: data.displayName });
    setEmail({ ...email, value: data.email });
    setAvatarUrl({ ...avatarUrl, value: data.avatarUrl });
    setSignatureUrl({ ...signatureUrl, value: data.signatureUrl });
    setSignatureName({ ...signatureName, value: data.signatureName });
    setAvatarName({ ...avatarName, value: data.avatarName });
    setUserName({ ...username, value: data.userName });
    setData(data);
  };

  // Call api updateUser
  const updateUser = async () => {
    const body = {
      ...data,
      displayName: displayName.value,
      email: email.value,
      avatarUrl: avatarUrl.value,
      signatureUrl: signatureUrl.value,
      signatureName: signatureName.value,
      avatarName: avatarName.value,
    };

    try {
      setLoading(true);
      await UserMangementApi.updateUser(body);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      dispatch(
        changeAvatar({
          url: avatarUrl.value,
        })
      );
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  // Save Data
  const handleSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: updateUser,
    });
  };

  useEffect(() => {
    getInforUserByUserName();
    if (!userName) {
      history.push(`/ViewUser/${authState.username}`);
    }
  }, [userName]);

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };

  const column_maintaince_history = [
    {
      title: lang.MAINTAINCE_DATE,
      dataIndex: "updateDateTime",
      key: "updateDateTime",
      width: "120px",
    },
    {
      title: lang.UPDATER,
      dataIndex: "updater",
      key: "updater",
      width: "120px",
    },
    {
      title: lang.ROLES,
      key: "roles",
      width: "120px",
      dataIndex: "roles",
    },
  ];

  const handleSetTimelineDetail = (timelineItem) => {
    // console.log(timelineItem);
    // timelineItem.roles = timelineItem.roles.map((item) => item.role).join(",");
    // timelineItem.siteOffices = timelineItem.siteOffices
    //   .map((item) => item.siteOfficeMasterName)
    //   .join(",");

    setTimeline_detail(timelineItem);
  };
  return (
    <div className="des-content">
      <TitlePage name={lang.USER_INFORMATION} />
      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">{lang.USER_INFORMATION}</h1>
        </div>
        <div className="mt15"></div>
        {renderData}
      </GroupItem>
      <FooterPage>
        <Row justify="space-between">
          <Col></Col>
          <Col>
            {isBoss && (
              <Button
                type="primary"
                className="buttonPC button--info wAuto mr10"
                onClick={onOpen}
              >
                <HistoryOutlined />
                {lang.CHANGE_LOG}
              </Button>
            )}
            {isOwnInfomation && (
              <Button
                onClick={() => handleSaveData()}
                className="buttonPC button--info wAuto mr10"
              >
                <SaveOutlined />
                {lang.SAVE}
              </Button>
            )}
            <Button
              className="buttonPC button--outline --todo-- wAuto"
              onClick={() => history.goBack()}
            >
              <RollbackOutlined />
              {lang.BACK}
            </Button>
          </Col>
        </Row>
      </FooterPage>
      <Loading show={loading} />
      <Drawer
        title={lang.CHANGE_LOG}
        width={335}
        placement="right"
        onClose={onClose}
        open={open}
        getContainer={false}
        visible={open}
      >
        {data && data.roleHistories && data.roleHistories.length ? (
          <Timeline mode={"left"}>
            {data.roleHistories.map((item, index) => {
              const label = (
                <a target="_blank" rel="noopener noreferrer">
                  <Typography.Text>{item.updateDateTime}</Typography.Text>
                  <br />
                </a>
              );
              const dot = (
                <p>
                  <HistoryOutlined />
                </p>
              );
              return (
                <Timeline.Item
                  key={index}
                  label={label}
                  dot={dot}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleSetTimelineDetail(item);
                  }}
                >
                  <p>
                    <Typography.Title level={5}>
                      {item.updater}
                    </Typography.Title>
                    <Typography.Text>{item.updateContent}</Typography.Text>
                  </p>
                </Timeline.Item>
              );
            })}
          </Timeline>
        ) : (
          <p style={{ textAlign: "center" }}>{COMMON_CONSTANT.EMPTY_DATA}</p>
        )}
        <Drawer
          title={timeline_detail && timeline_detail.updateDateTime}
          width={335}
          onClose={() => setTimeline_detail(null)}
          open={timeline_detail}
          visible={timeline_detail}
          bodyStyle={{ padding: 0 }}
        >
          {timeline_detail && (
            <Descriptions size="small" column={1} bordered>
              {column_maintaince_history.map((item, index) => {
                let content = timeline_detail[item.dataIndex] || "-";
                if (Array.isArray(content)) {
                  if (content.length > 0) {
                    const data = content.map((roleItem) => ({
                      role: roleItem.role,
                      office: roleItem.siteOffices
                        .map((item) => item.siteOfficeMasterName)
                        .join(", "),
                    }));
                    return (
                      <Descriptions.Item
                        key={index}
                        label={item.title}
                        labelStyle={{ width: 120, fontWeight: "bold" }}
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={data}
                          renderItem={(item, index) => (
                            <List.Item>
                              <List.Item.Meta
                                title={
                                  <span className="bold">{item.role}</span>
                                }
                                description={
                                  <span
                                    style={{
                                      color: "#000000D9",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {item.office}
                                  </span>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </Descriptions.Item>
                    );
                  }
                }

                return (
                  <>
                    <Descriptions.Item
                      key={index}
                      label={item.title}
                      labelStyle={{ width: 120, fontWeight: "bold" }}
                    >
                      {content}
                    </Descriptions.Item>
                  </>
                );
              })}
            </Descriptions>
          )}
        </Drawer>
      </Drawer>
    </div>
  );
}

export default ViewUserInfo;
