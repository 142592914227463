/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Modal,
  Input,
  Button,
  Image,
  Tag,
  Drawer,
  Collapse,
  Tabs,
  Switch,
  Empty,
  Card,
  Radio,
  Typography,
} from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import BoxContent from "../../component/Desktop/content";
import FooterPage from "../../component/footer/footerPage";
import { useMediaQuery } from "react-responsive";
import Loading from "../../component/Desktop/loading/loading";
import StatusConst from "../../constants/status.const";
import TabsCustom from "@src/component/TabsComponent/TabsCustom"; // Tab
import {
  lang,
  COMMON_CONSTANT,
  KEY,
  STYLE,
  TYPE_INPUT,
} from "../../constants/common.const";
import { ReactComponent as ChevronDown } from "@src/styles/icon/chevron-down.svg";
import { ReactComponent as MoreIcon } from "@src/styles/icon/more.svg";
import { ReactComponent as ChevronUp } from "@src/styles/icon/chevron-up.svg";
import {
  statusRes,
  MSG_CATCH,
  saveFile,
  renderOption,
  showMessage,
  getRange,
  sttMachine,
  markMachine,
  sttCellInput,
  hwEnabled,
  isAdminOffice,
  isHeadOffice,
} from "../../constants/utils";
import API from "../../api/backend/historyCL";
import KBN from "../../api/backend/kbn";
import API_workReport from "../../api/backend/workReport";
import { useHistory, useParams } from "react-router-dom";

import classPopup from "../../models/control/popup";
import classText from "../../models/control/text";
import classButton from "../../models/control/button";
import {
  CloudDownloadOutlined,
  CloseCircleOutlined,
  InfoCircleFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
  StopOutlined,
  RollbackOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentRouter } from "@src/redux/checkList";
import { ReactComponent as ErrorCheckIcon } from "@src/styles/icon/error-check.svg";
import { ReactComponent as HasCheckIcon } from "@src/styles/icon/has-check.svg";
import { ReactComponent as DisableCheckIcon } from "@src/styles/icon/disable-check.svg";
import { ReactComponent as CompleteCheckIcon } from "@src/styles/icon/complete-check.svg";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";
import classDdl from "../../models/control/dropdownlist";
import classItem from "../../models/itemMachine_view";
import classItemPreCheck from "../../models/itemPreCheck_view";

import rolesConst from "../../routers/roles/roles.const";
import "@src/page/InspectionChecklist/DetailItemPreCheck/detailItemPreCheck.scss";
const { Paragraph } = Typography;

const { TextArea } = Input;
const { Panel } = Collapse;
let dataScreen;
let objSttWorking = {};

function ViewHistory({ isEndUser }) {
  // let idTabMachine;
  const history = useHistory();
  let { id } = useParams();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [initPage, setInitPage] = useState(true);
  const dispatch = useDispatch();

  const [lblChecklistName, setLblChecklistName] = useState("");
  const [lblVer, setlblVer] = useState("");
  const [txtStatus, settxtStatus] = useState("");
  const [rejectReason, updateRejectReason] = useState("");
  // Info
  const [txtStartDate, setTxtStartDate] = useState("");
  const [txtEndDate, setTxtEndDate] = useState("");
  const [txtInpector, setTxtInpector] = useState("");
  const [txtApprover, setTxtApprover] = useState("");
  const [txtInpectort2, setTxtInpectort2] = useState("");
  const [TYPECHECKLIST, setTYPECHECKLIST] = useState(1);
  const [ddlWorkReport, setddlWorkReport] = useState(new classDdl());
  const [listCheckTime, setlistCheckTime] = useState([]);

  // Group Tab
  const [GROUP_LIST, setGROUP_LIST] = useState([]);
  const [GROUP_LIST_PRE_CHECK, setGROUP_LIST_PRE_CHECK] = useState([]);

  // Button
  const [btnExport, setBtnExport] = useState(new classButton());
  const [btnApprove, setBtnApprove] = useState(new classButton());
  const [btnReject, setBtnReject] = useState(new classButton());
  const [btnUnApprove, setBtnUnApprove] = useState(new classButton());

  const [popupActionMobile, setPopupActionMobile] = useState(new classPopup());
  const [showLoading, setShowLoading] = useState(false);

  // Popup
  const [popupLocationMachine, setPopupLocationMachine] = useState(
    new classPopup()
  );
  const [popupConfirm, setPopupConfirm] = useState(new classPopup());
  // In-Popup
  const [txtReason, setTxtReason] = useState(new classText());
  const { selectedFactory, factories, roles } = useSelector(
    (state) => state.auth
  );
  let customerApprove = false;
  const curentOffice = factories.find(
    (item) => item.siteOfficeMasterCode === selectedFactory
  );
  if (curentOffice) {
    customerApprove = curentOffice.customerApprove;
  }

  // Admin using endUserScren
  const isAdminUsingEndUserScren =
    isEndUser &&
    [rolesConst.OFFICE_ADMIN, rolesConst.HEAD_OFFICE_ADMIN].includes(roles);

  // padding endUser Scrren
  const isPCScreen = useMediaQuery({
    query: "(min-device-width: 1500px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1200px) and (max-device-width: 1499px)",
  });
  const padding = isPCScreen ? 200 : isLaptop ? 50 : 0;
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Init
  useEffect(() => {
    window.scrollTo(0, 0);
    // Load info
    openLoadingSpinner();

    Promise.all([
      API.getDetail(id),
      API_workReport.getWorkReportByCheckResultId(id),
      KBN.getWorkingStatus(),
    ])
      .then((res) => {
        showDetail(res[0]);
        showListWorkReport(res[1]);
        // Get list kbn status working
        res[2].data &&
          res[2].data.data &&
          res[2].data.data.forEach((v) => {
            objSttWorking[v.key] = v.value;
          });

        // Setup done
        setInitPage(false);
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  }, [id, selectedFactory]);

  const showDetail = (res) => {
    let data = res.data.data;
    dataScreen = res.data.data;

    // Title ---
    setLblChecklistName(data.checklistName);
    setlblVer(data.checklistVersion);
    settxtStatus(data.status);
    updateRejectReason(data.rejectReason);
    // Info ---
    setTxtStartDate(data.startDate);
    setTxtEndDate(data.endDate);
    setTxtInpector(data.inspector);
    setTxtApprover(data.approver);
    setTxtInpectort2(data.inspector2);
    data.times && setlistCheckTime(data.times.split(","));
    setTYPECHECKLIST(data.typeValue);

    // Group ---
    let listGroup = [];
    // Mapping
    data.groups.forEach((grp) => {
      let group = new classGroup();
      group.ID = grp["groupId"];
      group.Name = grp["groupName"];
      group.Machines = [];
      group.PreCheckItems = [];

      if (data.isPreUseChecklist === KEY.TYPE_PRE_USE_CHECKLIST) {
        // Pre check item
        group.PreCheckItems = grp["checklistItemPreChecks"];

        group.PreCheckItems.forEach((item = new classItemPreCheck()) => {
          let {
            normalUpperControlValue,
            normalLowerControlValue,
            value,
            typeValue,
          } = item;

          // Check value
          typeValue = Number(typeValue);
          item.error = checkValue(
            normalUpperControlValue,
            normalLowerControlValue,
            value,
            typeValue
          );
        });

        // Push
        listGroup.push(group);
        setGROUP_LIST_PRE_CHECK(listGroup);
      } else {
        // Machines
        grp["checklistMachines"].forEach((mc) => {
          let machine = new classMachine();
          machine = mc;
          machine.ID = mc["checklistMachineId"];
          machine.Name = mc["machineforSiteOfficeName"];
          machine.Remark = mc["note"];
          machine.MachineforSiteOfficeId = mc["machineforSiteOfficeCode"];

          // Check error item
          machine.Items = mc["checklistItems"].map((item = new classItem()) => {
            let {
              normalUpperControlValue,
              normalLowerControlValue,
              value,
              typeValue,
            } = item;

            // Check value
            typeValue = Number(typeValue);
            item.error = checkValue(
              normalUpperControlValue,
              normalLowerControlValue,
              value,
              typeValue
            );

            return item;
          });

          // Start: check color
          let statusMC;
          let countCheck = 0;
          let totalCheck = 0;
          // Check error
          machine.Items.forEach((item = new classItem()) => {
            if (Number(item.typeValue) === 2) {
              // Fix
              // multiple check
              // Check error
              for (let v in item.error) {
                if (item.error.hasOwnProperty(v) && item.error[v]) {
                  statusMC = KEY.ERROR;
                }
              }
              // Count
              if (item.value !== null) {
                totalCheck += Object.keys(item.value).length;

                for (let v in item.value) {
                  if (
                    item.value.hasOwnProperty(v) &&
                    item.value[v] !== null &&
                    item.value[v] !== ""
                  ) {
                    countCheck++;
                  }
                }
              } else {
                totalCheck += item.times.split(",").length;
              }
            } else {
              // Only check
              totalCheck++;
              if (item.value) {
                countCheck++;
              }
              if (item.error) {
                statusMC = KEY.ERROR;
              }
            }
          });
          // Case warning
          if (
            statusMC !== KEY.ERROR &&
            countCheck > 0 &&
            countCheck < totalCheck
          ) {
            statusMC = KEY.WARMING;
          }
          // Case info
          if (statusMC !== KEY.ERROR && countCheck === totalCheck) {
            statusMC = KEY.DONE;
          }
          // End: check color

          machine.counts = {
            notCheck: totalCheck - countCheck,
            total: totalCheck,
          };
          machine.status = statusMC;

          group.Machines.push(machine);
        });

        // Default: show machine first
        group.machineShow = [];
        // idTabMachine = group.Machines[0].ID;

        // Push
        listGroup.push(group);
        setGROUP_LIST(listGroup);
      }
    });

    // Button
    CheckStatusChecklist(data.status);
  };

  // Show count item notCheck
  const count = (m = new classMachine()) => {
    if (m.counts.notCheck !== 0 && m.counts.notCheck !== m.counts.total) {
      return (
        <div className="info-more fs12 mt5">
          {m.counts.notCheck} / {m.counts.total} {lang.INCOMPLETE}
        </div>
      );
    }
  };

  // Start: Check error item
  const checkValue = (
    normalUpperControlValue,
    normalLowerControlValue,
    value,
    typeValue
  ) => {
    if (typeValue === 2) {
      // Multiple
      let arr = {};
      for (const key in value) {
        arr[key] = checkRange(
          normalUpperControlValue,
          normalLowerControlValue,
          value[key]
        );
      }
      return arr;
    } else {
      // Only time check
      return checkRange(
        normalUpperControlValue,
        normalLowerControlValue,
        value
      );
    }
  };

  const checkRange = (
    normalUpperControlValue,
    normalLowerControlValue,
    value
  ) => {
    let isError = false;
    if (!value) {
      return null; // Case No input
    }
    if (
      normalUpperControlValue !== null &&
      normalUpperControlValue !== "" &&
      normalLowerControlValue !== null &&
      normalLowerControlValue !== "" &&
      !(
        Number(normalLowerControlValue) <= value &&
        value <= Number(normalUpperControlValue)
      )
    ) {
      isError = true;
    }

    // Min
    if (
      (normalUpperControlValue === null || normalUpperControlValue === "") &&
      normalLowerControlValue !== null &&
      normalLowerControlValue !== "" &&
      value < Number(normalLowerControlValue)
    ) {
      isError = true;
    }

    // Max
    if (
      normalUpperControlValue !== null &&
      normalUpperControlValue !== "" &&
      (normalLowerControlValue === null || normalLowerControlValue === "") &&
      value > Number(normalUpperControlValue)
    ) {
      isError = true;
    }
    return isError;
  };
  // End: Check error item

  const showListWorkReport = (res) => {
    let list = res.data;
    setddlWorkReport({
      ...ddlWorkReport,
      options: list,
      value: list.length > 0 ? list[0].id : "",
      show: list.length > 0,
    });
  };

  const CheckStatusChecklist = (status) => {
    btnUnApprove.visible = false;
    btnApprove.visible = false;
    btnReject.visible = false;

    switch (status) {
      case KEY.APPROVE:
        btnUnApprove.visible =
          isEndUser && !isAdminUsingEndUserScren ? false : true;
        if (isEndUser && !isAdminUsingEndUserScren) {
          btnApprove.visible = true;
          btnReject.visible = true;
        }

        break;

      case KEY.CUSTORMER_APPROVE:
        btnUnApprove.visible =
          isEndUser && !isAdminUsingEndUserScren ? true : false;
        break;
      case KEY.DONE:
      case KEY.DONE_AB:
        btnApprove.visible = true;
        btnReject.visible = true;
        break;
      default:
    }
    setBtnExport({ ...btnExport, visible: true }); // Default show
    setBtnApprove({ ...btnApprove });
    setBtnReject({ ...btnReject });
    setBtnUnApprove({ ...btnUnApprove });
  };

  const onShowAllMachine = (checked, index) => {
    const lNew = JSON.parse(JSON.stringify(GROUP_LIST));
    const listID = [];

    if (checked) {
      // show all machine
      GROUP_LIST[index].Machines &&
        GROUP_LIST[index].Machines.forEach((m = new classMachine()) => {
          listID.push(m.ID);
        });
    }

    lNew[index].machineShow = listID;
    setGROUP_LIST(lNew);
  };

  const onShowMachine = (keys, index) => {
    const lNew = JSON.parse(JSON.stringify(GROUP_LIST));
    lNew[index].machineShow = keys;
    setGROUP_LIST(lNew);
  };

  const onShowOneMachine = (keys, index) => {
    //  Show all not active OR exist one machine
    if (
      GROUP_LIST[index].machineShow.length !==
        GROUP_LIST[index].Machines.length ||
      GROUP_LIST[index].Machines.length === 1
    ) {
      if (keys.length > 0) {
        keys = [keys[keys.length - 1]];
      }
      onShowMachine(keys, index);
    }
  };
  const decimalCount = (num) => {
    const numStr = String(num);
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    return 0;
  };
  const round = (num, fixed) => {
    const temp = num * Math.pow(10, fixed);
    const temp2 = Math.round(temp);
    return temp2 / Math.pow(10, fixed);
  };
  const renderValueRealTime = (item) => {
    const valueInput = decimalCount(item.value);
    const preValue = decimalCount(item.previousValue);
    let fixed = 0;
    if (valueInput >= preValue) {
      fixed = valueInput;
    } else {
      fixed = preValue;
    }
    if (!isNaN(Number(item.value)) && !isNaN(Number(item.previousValue))) {
      if (
        Number(item.accumulatedType) === 2 ||
        Number(item.accumulatedType) === 6
      ) {
        return (
          <>
            <Col
              flex="90px"
              className={!isPC ? "text-mobile previousValue" : "previousValue"}
            >
              <Row className="wrap">
                <Col>
                  {Number(item.accumulatedType) === 6 ? "前回差" : "使用量"} :
                </Col>
                <Col>
                  {item.value !== null && item.value !== ""
                    ? round(
                        Number(item.value) - Number(item.previousValue),
                        fixed
                      )
                    : 0}
                </Col>
              </Row>
            </Col>
          </>
        );
      } else if (Number(item.accumulatedType) === 5) {
        return (
          <>
            <Col
              flex="140px"
              className={!isPC ? "text-mobile previousValue" : "previousValue"}
            >
              <Row className="wrap">
                <Col> 使用量 :</Col>
                <Col>
                  {item.value !== null && item.value !== ""
                    ? round(
                        Number(item.previousValue) - Number(item.value),
                        fixed
                      )
                    : 0}
                </Col>
              </Row>
            </Col>
          </>
        );
      } else {
        // Empty
      }
    } else {
      return null;
    }
  };
  const focusTabMachine = () => {
    setTimeout(() => {
      if (document.querySelector(".ant-collapse-item-active")) {
        window.scrollTo({
          top:
            document.querySelector(".ant-collapse-item-active").offsetTop - 160,
          behavior: "smooth",
        });
      }
    }, 280);
  };
  useEffect(() => {
    if (!initPage) {
      // const idTabMachine = String(keys[keys.length - 1]);
      focusTabMachine();
    }
  }, [GROUP_LIST]);

  const renderStatusMachine = (m = new classMachine()) => {
    let icon;
    if (Number(m.workingStatus) === 1 || !m.workingStatus) {
      //  Machine active
      switch (m.status) {
        case KEY.WARMING:
          icon = <HasCheckIcon />;
          break;
        case KEY.ERROR:
          icon = <ErrorCheckIcon />;
          break;

        case KEY.DONE:
          icon = <CompleteCheckIcon />;
          break;
        default:
          icon = null;
      }
    } else {
      // Disable Machine
      icon = <DisableCheckIcon />;
      // if (Number(m.workingStatus) === 2 || Number(m.workingStatus) === 3) {
      //   icon = <DisableCheckIcon />;
      // } else {
      //   icon = "／";
      // }
    }
    return icon;
  };

  /* Start get location of machine*/
  function showLocation(machine = new classMachine()) {
    // Show popup location
    setPopupLocationMachine({
      ...popupLocationMachine,
      show: true,
      body: (
        <div className="tableHtml">
          <Row gutter={[16, 16]}>
            <Col lg={4} xs={6}>
              <b>施設</b>
            </Col>
            <Col lg={20} xs={18}>
              {machine.facilityName}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={4} xs={6}>
              <b>棟</b>
            </Col>
            <Col lg={20} xs={18}>
              {machine.buildingName}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={4} xs={6}>
              <b>階</b>
            </Col>
            <Col lg={20} xs={18}>
              {machine.floorName}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={4} xs={6}>
              <b>部屋</b>
            </Col>
            <Col lg={20} xs={18}>
              {machine.roomName}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={4} xs={6}>
              <b>プロセス</b>
            </Col>
            <Col lg={20} xs={18}>
              {machine.processName}
            </Col>
          </Row>
        </div>
      ),
    });
  }
  /* End get location of machine*/

  // Approve, Reject checklist
  const showMessageConfirm = (commandId) => {
    // Box reason
    let customTitle = false;
    setTxtReason({ ...txtReason, value: "", error: false });

    // $("[id$=txtHdCommand]").val(commandId);
    let lblConfirmMessagesModal;

    if (commandId === "0") {
      lblConfirmMessagesModal = COMMON_CONSTANT.BOM_C013;
    } else if (commandId === "2") {
      lblConfirmMessagesModal = COMMON_CONSTANT.BOM_C038;
    } else if (commandId === "1") {
      // Reject
      lblConfirmMessagesModal = COMMON_CONSTANT.BOM_C019;
      customTitle = true;

      // Show popup confirm
      setPopupConfirm({
        ...popupConfirm,
        show: true,
        title: lblConfirmMessagesModal,
        customTitle: customTitle,
        commandId: commandId,
      });
      return;
    } else {
      // Empty
    }

    // Warning
    Modal.confirm({
      centered: true,
      title: lang.WARMING,
      content: lblConfirmMessagesModal,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        checkServerStatus("0", commandId);
      },
    });
  };

  // Check connect to server
  // Check access
  const checkServerStatus = (action, commandId) => {
    // Close popup action mobile
    actionMobile.close();

    // Execute
    switch (action) {
      // Approve, Reject, UnApprove
      case "0":
        // Approve/UnApprove
        if (commandId === "0" || commandId === "2") {
          callExecuteChangeStatus(commandId);
        } else if (commandId === "1") {
          // Reject
          if (txtReason.value && txtReason.value.trim() !== "") {
            callExecuteChangeStatus(commandId);
          } else {
            setTxtReason({ ...txtReason, error: true });
            return false;
          }
        } else {
          // Empty
        }
        break;

      // Export
      case "1":
        exportChecklistTemplate();
        break;
      default:
    }
  };

  function callExecuteChangeStatus(commandId) {
    // Hide popup confirm
    Modal.destroyAll();
    setPopupConfirm({ ...popupConfirm, show: false });

    openLoadingSpinner();
    // Call API change
    API.changeStatus({
      id: id,
      command: commandId,
      rejectReason: txtReason.value,
    })
      .then(async (res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, res.data.msg, async () => {
            //  Reject voi tu cach endUser
            if (
              (commandId === "1" && isEndUser) ||
              (commandId === "2" && isAdminUsingEndUserScren)
            ) {
              history.goBack();
            } else {
              const result = await API.getDetail(id);
              showDetail(result);
            }
          });

          // CheckStatusChecklist(res.data.statusLatest);
          // settxtStatus(res.data.statusLatest);
          // updateRejectReason(txtReason.value);
        }
      })
      .catch((err) => {
        console.log(err);
        let msg = "";
        if (err.response.status === 400) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg || MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  }

  function exportChecklistTemplate() {
    openLoadingSpinner();
    // Call API change
    API.download(dataScreen, isAdminUsingEndUserScren)
      .then((res) => {
        if (statusRes(res)) {
          saveFile(res);
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C005);
        } else {
          showMessage(KEY.ERROR, res.data.msg);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  }

  function onViewWorkReport() {
    let id = ddlWorkReport.value;
    const win = window.open(`/ViewReport/${id}`, "_blank");
    win.focus();
  }

  let showStatus = (sttCode) => {
    let o;
    StatusConst.STATUS_LIST.forEach((v) => {
      if (Number(v.key) === Number(sttCode)) {
        o = v;
      }
    });
    return (
      o.label && (
        <Tag color={o.color + "1a"} className="tag-label">
          <span style={{ color: o.color, fontWeight: "bold" }}>{o.label}</span>
        </Tag>
      )
    );
  };

  // Action popup action for mobile
  const actionMobile = {
    show: () => {
      setPopupActionMobile({
        ...popupActionMobile,
        show: true,
        title: lang.ACTION,
      });
    },
    close: () => {
      setPopupActionMobile({ ...popupActionMobile, show: false });
    },
  };
  // Action: Reject, Approve,..
  const nodeAction = () => {
    const classBtn = isPC
      ? "buttonPC button--info wAuto mr15"
      : "buttonPC button--info w100 mb5";
    return (
      <>
        {(isAdminOffice() ||
          isHeadOffice() ||
          (isEndUser && customerApprove)) && (
          <span>
            {btnApprove.visible && (
              <Button
                className={classBtn}
                onClick={() => {
                  showMessageConfirm("0");
                }}
              >
                <CheckCircleOutlined />
                {lang.APPROVE}
              </Button>
            )}
            {btnReject.visible && (
              <Button
                className={classBtn}
                onClick={() => {
                  showMessageConfirm("1");
                }}
              >
                <MinusCircleOutlined />
                {lang.REJECT}
              </Button>
            )}
            {btnUnApprove.visible && (
              <Button
                className={classBtn}
                onClick={() => {
                  showMessageConfirm("2");
                }}
              >
                <StopOutlined />
                {lang.UNAPPROVE}
              </Button>
            )}
          </span>
        )}
      </>
    );
  };
  // Action: Export
  const nodeExport = () => {
    const classBtn = isPC
      ? "buttonPC button--outline wAuto"
      : "buttonPC button--outline w100";

    return (
      btnExport.visible && (
        <Button
          className={classBtn}
          onClick={() => {
            checkServerStatus("1");
          }}
        >
          <CloudDownloadOutlined className="orange" /> {lang.EXPORT}
        </Button>
      )
    );
  };

  // Layout
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      {txtStartDate && <TitlePage name={lang.HISTORY_CHECKLIST} />}
      <div style={{ padding: `0 ${isEndUser ? padding : 0}px` }}>
        <BoxContent>
          {txtStartDate && (
            <>
              <h1 className="white-space">
                {lblChecklistName}
                <span className="title-ver">{lang.VERSION + lblVer}</span>
              </h1>
              {/* Detail */}
              <Row className="mb15" gutter={[16, 16]}>
                <Col span={24}>
                  <Row>
                    <Col lg={4} xs={10}>
                      <span className="bold">{lang.STATUS}</span>
                      {lang.COLON}
                    </Col>
                    <Col>{showStatus(txtStatus)}</Col>
                  </Row>
                </Col>
                {(Number(txtStatus) === 2 ||
                  (Number(txtStatus) === 1 && rejectReason)) && (
                  <Col span={24}>
                    <Row>
                      <Col lg={4} xs={10}>
                        <span className="bold">{lang.REJECT_REASON}</span>
                        {lang.COLON}
                      </Col>
                      <Col>
                        <Paragraph
                          ellipsis={{
                            expandable: true,
                            symbol: lang.SHOW,
                          }}
                          style={{ color: "red", whiteSpace: "pre" }}
                        >
                          {rejectReason}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={24}>
                  <Row>
                    <Col lg={4} xs={10}>
                      <span className="bold">{lang.BEGIN_CHECK}</span>
                      {lang.COLON}
                    </Col>
                    <Col>{txtStartDate}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col lg={4} xs={10}>
                      <span className="bold">
                        {lang.END_DATE_CHECK_CHECKLIST}
                      </span>
                      {lang.COLON}
                    </Col>
                    <Col>{txtEndDate}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col lg={4} xs={10}>
                      <span className="bold">{lang.MAIN_CHECKER}</span>
                      {lang.COLON}
                    </Col>
                    <Col>{txtInpector}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col lg={4} xs={10}>
                      <span className="bold">{lang.APPROVER}</span>
                      {lang.COLON}
                    </Col>
                    <Col>{txtApprover}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col lg={4} xs={10}>
                      <span className="bold">{lang.SUPPORT_CHECK_PERSON}</span>
                      {lang.COLON}
                    </Col>
                    <Col>{txtInpectort2}</Col>
                  </Row>
                </Col>
                {Number(TYPECHECKLIST) === 2 && (
                  <Col span={24}>
                    <Row>
                      <Col lg={4} xs={10}>
                        <span className="bold">チェック回数</span>
                        {lang.COLON}
                      </Col>
                      <Col>{listCheckTime.length}</Col>
                    </Row>
                  </Col>
                )}

                {ddlWorkReport.show && (
                  <Col span={24}>
                    <Row className="align-center" gutter={!isPC && [8, 8]}>
                      <Col lg={4} xs={24} sm={10}>
                        <span className="bold">
                          報告名 ({ddlWorkReport.options.length})
                        </span>
                        ：
                      </Col>
                      <Col lg={20} xs={24} sm={14}>
                        <Select
                          style={
                            isPC
                              ? {
                                  maxWidth: "calc(100% - 200px)",
                                  minWidth: "200px",
                                }
                              : {
                                  width: "calc(100% - 80px)",
                                }
                          }
                          className="mr5"
                          value={ddlWorkReport.value}
                          onChange={(v) => {
                            // debugger;
                            setddlWorkReport({
                              ...ddlWorkReport,
                              value: v,
                            });
                          }}
                        >
                          {renderOption(ddlWorkReport.options, false)}
                        </Select>
                        <Button
                          className={"buttonPC button--info m-wAuto"}
                          onClick={() => {
                            dispatch(
                              updateCurrentRouter(
                                `/ViewReport/${ddlWorkReport.value}`,
                                "_blank"
                              )
                            );
                            onViewWorkReport();
                          }}
                        >
                          {lang.VIEW}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </>
          )}
          {/* Table */}
          {GROUP_LIST.length > 0 && (
            <>
              <TabsCustom
                sticky={true}
                type="card"
                onChange={() => {
                  focusTabMachine();
                }}
              >
                {GROUP_LIST.map((grp = new classGroup(), index) => {
                  return (
                    <Tabs.TabPane tab={grp.Name} key={grp.ID} closable={false}>
                      {/* EXPEND ALL */}
                      <Row justify="end" className="mb15">
                        <Col>
                          {lang.EXPEND_ALL}{" "}
                          <Switch
                            checked={
                              grp.machineShow.length === grp.Machines.length
                            }
                            onChange={(checked) => {
                              onShowAllMachine(checked, index);
                            }}
                          />
                        </Col>
                      </Row>
                      {/* Machine */}
                      <Collapse
                        className="tabMachine"
                        expandIconPosition="right"
                        expandIcon={({ isActive }) =>
                          isActive ? <ChevronDown /> : <ChevronUp />
                        }
                        activeKey={grp.machineShow}
                        onChange={(keys, a, b) => {
                          onShowOneMachine(keys, index);

                          // idTabMachine = String(keys[keys.length - 1]);
                        }}
                      >
                        {grp.Machines.map((m = new classMachine()) => {
                          const sttM = sttMachine(m);
                          return (
                            <Panel
                              className={"titleMachine_" + sttM}
                              header={
                                <>
                                  <Row>
                                    <Col
                                      style={{
                                        width:
                                          `calc(100% - ` +
                                          (isPC ? "118px" : "52px") +
                                          `)`,
                                      }}
                                      className="bold"
                                    >
                                      {markMachine(m)} {m.Name}
                                    </Col>
                                    <Col
                                      className="align-center left titleMachine--info"
                                      onClick={(e) => {
                                        showLocation(m);
                                        e.stopPropagation();
                                      }}
                                    >
                                      <InfoCircleFilled className="content-center mr5" />
                                      {isPC && lang.LOCATION_MACHINE}
                                    </Col>
                                  </Row>
                                  <Row>
                                    {hwEnabled(m) && count(m) && (
                                      <Col xs={12} lg={6}>
                                        {hwEnabled(m) && count(m)}
                                      </Col>
                                    )}
                                    <Col
                                      xs={12}
                                      lg={16}
                                      className={
                                        "align-center sttMachine-" + sttM
                                      }
                                    >
                                      {(m.status ||
                                        (m.workingStatus &&
                                          Number(m.workingStatus) !== 1)) && (
                                        <>
                                          {lang.JUDGEMENT}
                                          {lang.COLON}
                                          {renderStatusMachine(m)}
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              }
                              key={m.ID}
                            >
                              {/* Item */}
                              {m.Items.map((item = new classItem(), key) => {
                                const align = isPC
                                  ? "right ml55 info-more"
                                  : " mr20 fs12 info-more";
                                const nullValue = () => {
                                  return hwEnabled(m)
                                    ? ""
                                    : Number(m.workingStatus) === 3
                                    ? "☆"
                                    : "ー";
                                };
                                const elemValue =
                                  Number(item.typeValue) === 2 ? (
                                    // Multiple time
                                    <Row>
                                      {item.times.split(",").map((no, key) => {
                                        const value = item.value
                                          ? item.value[no]
                                          : null;
                                        const valueReplenishment =
                                          item.replenishmentAmount
                                            ? item.replenishmentAmount[no]
                                            : null;
                                        const note = item.note
                                          ? item.note[no]
                                          : null;
                                        const mode = item.mode
                                          ? item.mode[no]
                                          : null;
                                        const accumulatedType =
                                          item.accumulatedType
                                            ? item.accumulatedType[no]
                                            : null;
                                        const timeInput =
                                          (isHeadOffice() || isAdminOffice()) &&
                                          item.inspectionTime &&
                                          item.inspectionTime[no]
                                            ? item.inspectionTime[no]
                                            : null;
                                        const error = item.error
                                          ? item.error[no]
                                          : null;
                                        return (
                                          <Col
                                            key={key}
                                            className="cardValueCheck"
                                            style={{ width: "210px" }}
                                          >
                                            <Card
                                              type="inner"
                                              className="center white-space"
                                              title={
                                                <b>{listCheckTime[no - 1]}</b>
                                              }
                                            >
                                              {(isHeadOffice() ||
                                                isAdminOffice()) && (
                                                <Row
                                                  className="nowrap info-more fs12"
                                                  justify="space-between"
                                                >
                                                  {item.mode ===
                                                    COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                                  Number(
                                                    item.accumulatedType
                                                  ) !== 1 &&
                                                  Number(
                                                    item.accumulatedType
                                                  ) !== 6 ? (
                                                    <Col>
                                                      {lang.ACCUMULATED_VALUE}
                                                      {lang.COLON}{" "}
                                                      {item.accumulatedValue}
                                                    </Col>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <Col>
                                                    {lang.TIME_CHECKING +
                                                      lang.COLON +
                                                      (timeInput || "")}
                                                  </Col>
                                                </Row>
                                              )}
                                              {mode !==
                                              COMMON_CONSTANT.INPUT_FORMAT_YESNO ? (
                                                <Input
                                                  className={
                                                    "bg-disable inputView inputView--" +
                                                    sttCellInput(value, error) +
                                                    " w100"
                                                  }
                                                  readOnly
                                                  value={
                                                    value ? value : nullValue()
                                                  }
                                                ></Input>
                                              ) : (
                                                <Radio.Group
                                                  id={id}
                                                  value={value}
                                                  disabled
                                                  className="big-radio"
                                                >
                                                  <Radio value={"0"}>
                                                    <span className="text-radio">
                                                      {lang.NO}
                                                    </span>
                                                  </Radio>
                                                  <Radio value={"1"}>
                                                    <span className="text-radio">
                                                      {lang.YES}
                                                    </span>
                                                  </Radio>
                                                </Radio.Group>
                                              )}
                                              <Input
                                                className={
                                                  "bg-disable inputView inputView--" +
                                                  sttCellInput(value, error) +
                                                  " w100"
                                                }
                                                readOnly
                                                value={
                                                  value ? value : nullValue()
                                                }
                                              ></Input>
                                              <Row className="nowrap info-more fs12">
                                                {item.mode ===
                                                  COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                                (Number(
                                                  item.accumulatedType
                                                ) === 2 ||
                                                  Number(
                                                    item.accumulatedType
                                                  ) === 5 ||
                                                  Number(
                                                    item.accumulatedType
                                                  ) === 6) ? (
                                                  renderValueRealTime(item)
                                                ) : (
                                                  <></>
                                                )}
                                                <Col flex="auto"></Col>
                                                <Col>
                                                  {lang.PREVIOUS_VALUE}
                                                  {lang.COLON}{" "}
                                                  {item.previousValue
                                                    ? mode ===
                                                      COMMON_CONSTANT.INPUT_FORMAT_YESNO
                                                      ? Number(
                                                          item.previousValue
                                                        ) === 0
                                                        ? lang.NO
                                                        : lang.YES
                                                      : item.previousValue
                                                    : "-"}
                                                </Col>
                                              </Row>
                                              {Number(accumulatedType) === 3 ? (
                                                <>
                                                  <Row className="nowrap info-more fs12">
                                                    <Col>
                                                      {lang.REPLENISHMENT}
                                                    </Col>
                                                  </Row>
                                                  <Input
                                                    className={
                                                      "bg-disable inputView inputView--" +
                                                      sttCellInput(
                                                        value,
                                                        error
                                                      ) +
                                                      " w100"
                                                    }
                                                    value={
                                                      valueReplenishment
                                                        ? valueReplenishment
                                                        : nullValue()
                                                    }
                                                  ></Input>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {note !== "" && note !== null ? (
                                                <>
                                                  <Row className="nowrap info-more fs12">
                                                    <Col>
                                                      {lang.ADD_COMMENT}
                                                    </Col>
                                                  </Row>
                                                  <TextArea
                                                    className={
                                                      "bg-disable textAreaView inputView--" +
                                                      sttCellInput(
                                                        value,
                                                        error
                                                      ) +
                                                      " w100"
                                                    }
                                                    readOnly
                                                    autoSize={{
                                                      minRows: 1,
                                                      maxRows: 6,
                                                    }}
                                                    value={note}
                                                  ></TextArea>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </Card>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  ) : (
                                    // Only time
                                    <Row gutter={[16, 16]} align="top">
                                      {/* Value */}
                                      <Col xs={24} lg={7}>
                                        <div>
                                          {item.mode ===
                                            COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                          Number(item.accumulatedType) !== 1 &&
                                          Number(item.accumulatedType) !== 6 ? (
                                            <>
                                              {lang.ACCUMULATED_VALUE}
                                              {lang.COLON}{" "}
                                              {item.accumulatedValue}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        {item.mode !==
                                        COMMON_CONSTANT.INPUT_FORMAT_YESNO ? (
                                          <Input
                                            className={
                                              "w100 bg-disable inputView inputView--" +
                                              sttCellInput(
                                                item.value,
                                                item.error
                                              )
                                            }
                                            readOnly
                                            value={
                                              item.value
                                                ? item.value
                                                : nullValue()
                                            }
                                          ></Input>
                                        ) : (
                                          <Radio.Group
                                            id={id}
                                            value={item.value}
                                            disabled
                                            className="big-radio"
                                          >
                                            <Radio value={"0"}>
                                              <span className="text-radio">
                                                {lang.NO}
                                              </span>
                                            </Radio>
                                            <Radio value={"1"}>
                                              <span className="text-radio">
                                                {lang.YES}
                                              </span>
                                            </Radio>
                                          </Radio.Group>
                                        )}
                                        <Row className="rowNotMargin">
                                          {item.mode ===
                                            COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                          (Number(item.accumulatedType) === 2 ||
                                            Number(item.accumulatedType) ===
                                              5 ||
                                            Number(item.accumulatedType) ===
                                              6) ? (
                                            renderValueRealTime(item)
                                          ) : (
                                            <></>
                                          )}
                                          <Col flex="auto"></Col>
                                          <Col>
                                            {lang.PREVIOUS_VALUE}
                                            {lang.COLON}{" "}
                                            {item.previousValue
                                              ? item.mode ===
                                                COMMON_CONSTANT.INPUT_FORMAT_YESNO
                                                ? Number(item.previousValue) ===
                                                  0
                                                  ? lang.NO
                                                  : lang.YES
                                                : item.previousValue
                                              : "-"}
                                          </Col>
                                        </Row>
                                      </Col>
                                      {Number(item.accumulatedType) === 3 ? (
                                        <Col xs={24} lg={7}>
                                          <div>{lang.REPLENISHMENT}</div>
                                          <Input
                                            className={
                                              "w100 bg-disable inputView inputView--" +
                                              (!isPC ? " w100" : "")
                                            }
                                            readOnly
                                            value={
                                              item.replenishmentAmount
                                                ? item.replenishmentAmount
                                                : nullValue()
                                            }
                                          ></Input>
                                        </Col>
                                      ) : (
                                        <></>
                                      )}
                                      {item.note !== "" &&
                                      item.note !== null ? (
                                        <Col xs={24} lg={7}>
                                          <TextArea
                                            className={
                                              "w100 bg-disable textAreaView inputView--" +
                                              (!isPC ? " w100" : "")
                                            }
                                            style={{
                                              padding: "5px",
                                              textAlign: "left",
                                            }}
                                            readOnly
                                            autoSize={{
                                              minRows: 1,
                                              maxRows: 6,
                                            }}
                                            value={item.note}
                                          ></TextArea>
                                        </Col>
                                      ) : (
                                        <></>
                                      )}
                                    </Row>
                                  );
                                return (
                                  <div className="itemOfMachine" key={key}>
                                    <Row
                                      gutter={[0, isPC ? 20 : 10]}
                                      justify="space-between"
                                    >
                                      <Col lg={8} xs={24} className="bold">
                                        {item.checkItemName}
                                      </Col>
                                      <Col lg={16} xs={24}>
                                        <Row justify={isPC && "end"}>
                                          {Number(item.typeValue) === 1 &&
                                            (isHeadOffice() ||
                                              isAdminOffice()) && (
                                              <Col className={align}>
                                                {lang.TIME_CHECKING}
                                                {lang.COLON}{" "}
                                                {item.inspectionTime
                                                  ? item.inspectionTime
                                                  : ""}
                                              </Col>
                                            )}
                                          <Col className={align}>
                                            単位{lang.COLON} {item.unit}
                                          </Col>
                                          <Col className={align}>
                                            運転めやす{lang.COLON}{" "}
                                            {getRange(
                                              item.normalLowerControlValue,
                                              item.normalUpperControlValue
                                            )}
                                          </Col>
                                          <Col className={align}>
                                            入力形式{lang.COLON} {item.mode}
                                          </Col>
                                          {item.inputJudgment ? (
                                            <Col className={align}>
                                              {lang.JUDGEMENT}
                                              {lang.COLON} {item.inputJudgment}
                                            </Col>
                                          ) : (
                                            <></>
                                          )}
                                        </Row>
                                      </Col>
                                    </Row>
                                    {/* Result check */}
                                    {elemValue}
                                  </div>
                                );
                              })}

                              {/* Note, attach, img */}
                              <Row className="itemOfMachine">
                                <Col xs={24} className="bold">
                                  備考
                                </Col>
                                <Col xs={24} className="mt20">
                                  <Input
                                    readOnly
                                    className="w100 bg-disable  inputView"
                                    value={objSttWorking[m.workingStatus] || ""}
                                  ></Input>
                                  <div
                                    className="oss-label divText bg-disable overflow-auto textNote"
                                    style={{ height: "70px" }}
                                  >
                                    {m.Remark}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="itemOfMachine">
                                <Col span={24} className="bold">
                                  {lang.ATTACH}
                                </Col>
                                <Col span={24} className="mt20">
                                  {m.attachments && m.attachments.length > 0 ? (
                                    m.attachments.map((att, key) => {
                                      return (
                                        <Image
                                          key={key}
                                          src={att.attachmentUrl}
                                        />
                                      );
                                    })
                                  ) : (
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      className="mt0 mb0"
                                    />
                                  )}
                                </Col>
                              </Row>
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </Tabs.TabPane>
                  );
                })}
              </TabsCustom>
            </>
          )}

          {/* Table */}
          {GROUP_LIST_PRE_CHECK.length > 0 && (
            <>
              <TabsCustom
                sticky={true}
                type="card"
                onChange={() => {
                  focusTabMachine();
                }}
              >
                {GROUP_LIST_PRE_CHECK.map((grp = new classGroup(), index) => {
                  return (
                    <Tabs.TabPane
                      tab={grp.Name}
                      key={grp.ID}
                      closable={false}
                      className="detailPreCheckItem"
                    >
                      {/* Pre check item */}
                      {grp.PreCheckItems.map(
                        (item = new classItemPreCheck(), key) => {
                          const align = isPC
                            ? "right ml55 info-more"
                            : " mr20 fs12 info-more";
                          const nullValue = () => {
                            return "";
                          };
                          const elemValue =
                            Number(item.typeValue) === 2 ? (
                              // Multiple time
                              <Row>
                                {item.times &&
                                  item.times.split(",").map((no, key) => {
                                    const value = item.value
                                      ? item.value[no]
                                      : null;
                                    const valueReplenishment =
                                      item.replenishmentAmount
                                        ? item.replenishmentAmount[no]
                                        : null;
                                    const note = item.note
                                      ? item.note[no]
                                      : null;
                                    const mode = item.mode
                                      ? item.mode[no]
                                      : null;
                                    const accumulatedType = item.accumulatedType
                                      ? item.accumulatedType[no]
                                      : null;
                                    const timeInput =
                                      (isHeadOffice() || isAdminOffice()) &&
                                      item.inspectionTime &&
                                      item.inspectionTime[no]
                                        ? item.inspectionTime[no]
                                        : null;
                                    const error = item.error
                                      ? item.error[no]
                                      : null;
                                    return (
                                      <Col
                                        key={key}
                                        className="cardValueCheck"
                                        style={{ width: "210px" }}
                                      >
                                        <Card
                                          type="inner"
                                          className="center white-space"
                                          title={<b>{listCheckTime[no - 1]}</b>}
                                        >
                                          {(isHeadOffice() ||
                                            isAdminOffice()) && (
                                            <Row
                                              className="nowrap info-more fs12"
                                              justify="space-between"
                                            >
                                              {item.mode ===
                                                COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                              Number(item.accumulatedType) !==
                                                1 &&
                                              Number(item.accumulatedType) !==
                                                6 ? (
                                                <Col>
                                                  {lang.ACCUMULATED_VALUE}
                                                  {lang.COLON}{" "}
                                                  {item.accumulatedValue}
                                                </Col>
                                              ) : (
                                                <></>
                                              )}
                                              <Col>
                                                {lang.TIME_CHECKING +
                                                  lang.COLON +
                                                  (timeInput || "")}
                                              </Col>
                                            </Row>
                                          )}
                                          {mode !==
                                          COMMON_CONSTANT.INPUT_FORMAT_YESNO ? (
                                            <Input
                                              className={
                                                "bg-disable inputView inputView--" +
                                                sttCellInput(value, error) +
                                                " w100"
                                              }
                                              readOnly
                                              value={
                                                value ? value : nullValue()
                                              }
                                            ></Input>
                                          ) : (
                                            <Radio.Group
                                              id={id}
                                              value={value}
                                              disabled
                                              className="big-radio"
                                            >
                                              <Radio value={"0"}>
                                                <span className="text-radio">
                                                  {lang.NO}
                                                </span>
                                              </Radio>
                                              <Radio value={"1"}>
                                                <span className="text-radio">
                                                  {lang.YES}
                                                </span>
                                              </Radio>
                                            </Radio.Group>
                                          )}
                                          <Input
                                            className={
                                              "bg-disable inputView inputView--" +
                                              sttCellInput(value, error) +
                                              " w100"
                                            }
                                            readOnly
                                            value={value ? value : nullValue()}
                                          ></Input>
                                          <Row className="nowrap info-more fs12">
                                            {item.mode ===
                                              COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                            (Number(item.accumulatedType) ===
                                              2 ||
                                              Number(item.accumulatedType) ===
                                                5 ||
                                              Number(item.accumulatedType) ===
                                                6) ? (
                                              renderValueRealTime(item)
                                            ) : (
                                              <></>
                                            )}
                                            <Col flex="auto"></Col>
                                            <Col>
                                              {lang.PREVIOUS_VALUE}
                                              {lang.COLON}{" "}
                                              {item.previousValue
                                                ? mode ===
                                                  COMMON_CONSTANT.INPUT_FORMAT_YESNO
                                                  ? Number(
                                                      item.previousValue
                                                    ) === 0
                                                    ? lang.NO
                                                    : lang.YES
                                                  : item.previousValue
                                                : "-"}
                                            </Col>
                                          </Row>
                                          {Number(accumulatedType) === 3 ? (
                                            <>
                                              <Row className="nowrap info-more fs12">
                                                <Col>{lang.REPLENISHMENT}</Col>
                                              </Row>
                                              <Input
                                                className={
                                                  "bg-disable inputView inputView--" +
                                                  sttCellInput(value, error) +
                                                  " w100"
                                                }
                                                value={
                                                  valueReplenishment
                                                    ? valueReplenishment
                                                    : nullValue()
                                                }
                                              ></Input>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {note !== "" && note !== null ? (
                                            <>
                                              <Row className="nowrap info-more fs12">
                                                <Col>{lang.ADD_COMMENT}</Col>
                                              </Row>
                                              <TextArea
                                                className={
                                                  "bg-disable textAreaView inputView--" +
                                                  sttCellInput(value, error) +
                                                  " w100"
                                                }
                                                readOnly
                                                autoSize={{
                                                  minRows: 1,
                                                  maxRows: 6,
                                                }}
                                                value={note}
                                              ></TextArea>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Card>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            ) : (
                              // Only time
                              <Row gutter={[16, 16]} align="top">
                                {/* Value */}
                                <Col xs={24} lg={7}>
                                  <div>
                                    {item.mode ===
                                      COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                    Number(item.accumulatedType) !== 1 &&
                                    Number(item.accumulatedType) !== 6 ? (
                                      <>
                                        {lang.ACCUMULATED_VALUE}
                                        {lang.COLON} {item.accumulatedValue}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {item.mode !==
                                  COMMON_CONSTANT.INPUT_FORMAT_YESNO ? (
                                    <Input
                                      className={
                                        "w100 bg-disable inputView inputView--" +
                                        sttCellInput(item.value, item.error)
                                      }
                                      readOnly
                                      value={
                                        item.value ? item.value : nullValue()
                                      }
                                    ></Input>
                                  ) : (
                                    <Radio.Group
                                      id={id}
                                      value={item.value}
                                      disabled
                                      className="big-radio"
                                    >
                                      <Radio value={"0"}>
                                        <span className="text-radio">
                                          {lang.NO}
                                        </span>
                                      </Radio>
                                      <Radio value={"1"}>
                                        <span className="text-radio">
                                          {lang.YES}
                                        </span>
                                      </Radio>
                                    </Radio.Group>
                                  )}
                                  <Row className="rowNotMargin">
                                    {item.mode ===
                                      COMMON_CONSTANT.INPUT_FORMAT_NUM &&
                                    (Number(item.accumulatedType) === 2 ||
                                      Number(item.accumulatedType) === 5 ||
                                      Number(item.accumulatedType) === 6) ? (
                                      renderValueRealTime(item)
                                    ) : (
                                      <></>
                                    )}
                                    <Col flex="auto"></Col>
                                    <Col>
                                      {lang.PREVIOUS_VALUE}
                                      {lang.COLON}{" "}
                                      {item.previousValue
                                        ? item.mode ===
                                          COMMON_CONSTANT.INPUT_FORMAT_YESNO
                                          ? Number(item.previousValue) === 0
                                            ? lang.NO
                                            : lang.YES
                                          : item.previousValue
                                        : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                                {Number(item.accumulatedType) === 3 ? (
                                  <Col xs={24} lg={7}>
                                    <div>{lang.REPLENISHMENT}</div>
                                    <Input
                                      className={
                                        "w100 bg-disable inputView inputView--" +
                                        (!isPC ? " w100" : "")
                                      }
                                      readOnly
                                      value={
                                        item.replenishmentAmount
                                          ? item.replenishmentAmount
                                          : nullValue()
                                      }
                                    ></Input>
                                  </Col>
                                ) : (
                                  <></>
                                )}
                                {item.note !== "" && item.note !== null ? (
                                  <Col xs={24} lg={7}>
                                    <TextArea
                                      className={
                                        "w100 bg-disable textAreaView inputView--" +
                                        (!isPC ? " w100" : "")
                                      }
                                      style={{
                                        padding: "5px",
                                        textAlign: "left",
                                      }}
                                      readOnly
                                      autoSize={{ minRows: 1, maxRows: 6 }}
                                      value={item.note}
                                    ></TextArea>
                                  </Col>
                                ) : (
                                  <></>
                                )}
                              </Row>
                            );
                          return (
                            <div className="part" key={key}>
                              <Row
                                gutter={[0, isPC ? 20 : 10]}
                                justify="space-between"
                              >
                                <Col lg={8} xs={24} className="bold">
                                  {item.checkItemName}
                                </Col>
                                <Col lg={16} xs={24}>
                                  <Row justify={isPC && "end"}>
                                    {Number(item.typeValue) === 1 &&
                                      (isHeadOffice() || isAdminOffice()) && (
                                        <Col className={align}>
                                          {lang.TIME_CHECKING}
                                          {lang.COLON}{" "}
                                          {item.inspectionTime
                                            ? item.inspectionTime
                                            : ""}
                                        </Col>
                                      )}
                                    <Col className={align}>
                                      単位{lang.COLON} {item.unit}
                                    </Col>
                                    <Col className={align}>
                                      運転めやす{lang.COLON}{" "}
                                      {getRange(
                                        item.normalLowerControlValue,
                                        item.normalUpperControlValue
                                      )}
                                    </Col>
                                    <Col className={align}>
                                      入力形式{lang.COLON} {item.mode}
                                    </Col>
                                    {item.inputJudgment ? (
                                      <Col className={align}>
                                        {lang.JUDGEMENT}
                                        {lang.COLON} {item.inputJudgment}
                                      </Col>
                                    ) : (
                                      <></>
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                              {/* Result check */}
                              {elemValue}
                            </div>
                          );
                        }
                      )}
                    </Tabs.TabPane>
                  );
                })}
              </TabsCustom>
            </>
          )}
        </BoxContent>
      </div>
      <FooterPage>
        {isPC ? (
          <Row gutter={[8, 0]} justify="space-between">
            <Col>{nodeAction()}</Col>
            <Col>
              {nodeExport()}
              <Button
                className="buttonPC button--outline wAuto ml15"
                onClick={() => {
                  history.goBack();
                }}
              >
                <RollbackOutlined />
                {lang.BACK}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Row className="menuBottom-footer">
              <Col span={12}>
                <div
                  className="menuBottom-footer-btnCancel content-center"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {lang.BACK}
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="menuBottom-footer-btnConfirm content-center "
                  onClick={actionMobile.show}
                >
                  <MoreIcon className="svg-white" />
                </div>
              </Col>
            </Row>
          </Row>
        )}
      </FooterPage>

      {/* Button Action for Mobile */}
      {!isPC && (
        <Drawer
          height={270}
          className="menuBottom menuBottomAuto"
          title={popupActionMobile.title}
          placement="bottom"
          onClose={actionMobile.close}
          visible={popupActionMobile.show}
        >
          {nodeAction()}
          {nodeExport()}
          <Row className="menuBottom-footer">
            <Col span={24}>
              <div
                className="menuBottom-footer-btnCancel content-center"
                onClick={actionMobile.close}
              >
                {lang.CANCEL}
              </div>
            </Col>
          </Row>
        </Drawer>
      )}
      {/* Location Machine */}
      <Modal
        centered
        width="590px"
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.LOCATION_MACHINE}</div>}
        visible={popupLocationMachine.show}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline wAuto"
            onClick={() => {
              setPopupLocationMachine({ ...popupLocationMachine, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        {popupLocationMachine.body}
      </Modal>
      {/* Popup confirm */}
      <Modal
        centered
        closeIcon={<></>}
        className="modal-msg modal-warning2"
        title={
          <>
            <ExclamationCircleOutlined />
            <div className="modal-custom-content">
              {popupConfirm.title}
              {popupConfirm.customTitle && (
                <>
                  <Row>
                    <span className="fs12 pt5 mb5">
                      {lang.REASON_FOR_REJECT}
                      <label className="blod ml5 mark-required">*</label>
                    </span>
                  </Row>
                  <Row>
                    <TextArea
                      rows={3}
                      className={txtReason.error ? STYLE.BORDER_RED : ""}
                      maxLength={255}
                      value={txtReason.value}
                      onChange={(e) => {
                        setTxtReason({
                          ...txtReason,
                          value: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </Row>
                </>
              )}
            </div>
          </>
        }
        visible={popupConfirm.show}
        footer={[
          <Button
            key={2}
            onClick={() => {
              setPopupConfirm({ ...popupConfirm, show: false });
            }}
          >
            {/* <CloseCircleOutlined /> */}
            {lang.CANCEL}
          </Button>,
          <Button
            key={1}
            className="buttonPC button--warning2 wAuto"
            onClick={() => {
              checkServerStatus("0", popupConfirm.commandId);
            }}
          >
            {lang.OK}
          </Button>,
        ]}
      ></Modal>
    </div>
  );
}

export default ViewHistory;
