import React from "react";
import { Checkbox } from "antd";
import "./comboCheckbox.scss";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";

// const CheckboxGroup = Checkbox.Group;

function ComboCheckbox({
  options = [],
  checkedList = [],
  indeterminate,
  dataGroup = [new classGroup()],
  checkAll,
  onCheck,
  onCheckAll,
  msg,
  ...other
}) {
  return (
    <div>
      {" "}
      {msg ? (
        msg
      ) : options.length > 0 ? (
        <div className="comboCheckbox" {...other}>
          <div className="checkbox-all">
            <Checkbox
              indeterminate={false}
              onChange={onCheckAll}
              checked={checkAll}
            >
              <b>全て</b>
            </Checkbox>
          </div>
          <div className="checkbox-list">
            {options.map((o, index) => {
              let checked = false,
                blod = true;
              checkedList.forEach((id) => {
                if (id === o.value) {
                  checked = true;
                }
              });
              dataGroup.forEach((g = new classGroup()) => {
                g.Machines.forEach((m = new classMachine()) => {
                  if (m.ID === o.value) {
                    blod = false;
                  }
                });
              });

              return (
                <label key={index}>
                  <Checkbox
                    onChange={(e) => {
                      // Checked
                      if (e.target.checked) {
                        checkedList.push(o.value);
                      } else {
                        // Uncheck
                        // Delete option
                        checkedList = checkedList.filter((v) => {
                          return v !== o.value;
                        });
                      }
                      // Update state
                      onCheck(checkedList);
                    }}
                    checked={checked}
                  >
                    {blod ? (
                      o.label
                    ) : (
                      <span className="chooseMachine">{o.label}</span>
                    )}
                  </Checkbox>
                </label>
              );
            })}
          </div>
          {/*                 
                <CheckboxGroup
                    className="checkbox-list"
                    options={options}
                    value={checkedList}
                    onChange={onCheck}
                /> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ComboCheckbox;
