import React from "react";
import { Checkbox, Row, Divider, Col } from "antd";
import "./comboCheckbox.scss";
import { lang } from "../../constants/common.const";
import InfiniteScroll from "react-infinite-scroller";
import LoadingInline from "../Popup/loading-inline";

export default function ListMachine({
  options = [],
  checkedList = [],
  listFilter = [],
  checkAll,
  onCheck,
  onCheckAll,
  msg,
  loadMore,
  stateData,
  ...other
}) {
 
  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={!stateData.loading && stateData.hasMore}
      useWindow={false}
    >
      {msg ? (
        msg
      ) : options.length > 0 ? (
        <div className="comboCheckboxRow" {...other}>
          {/* Select all */}
          <div className="checkbox-all">
            <Checkbox
              indeterminate={false}
              onChange={onCheckAll}
              checked={checkAll}
            >
              <b>{lang.ALL}</b>
            </Checkbox>
          </div>
          {/* Select option */}
          <div className="checkbox-list">
            {options.map((o, index) => {
              let checked = false,
                added = false;
              (checkedList || []).forEach((obj) => {
                if (obj[o.machineForSiteOfficeCode]) {
                  checked = true;
                }
              });
              listFilter.forEach((code) => {
                if (
                  String(o.machineForSiteOfficeCode) ===
                  String(code.machineForSiteOfficeCode)
                ) {
                  added = true;
                }
              });

              return (
                <div key={index}>
                  <label className={"m " + (added ? "chooseMachine" : "")}>
                    <div className="m-title d-flex">
                      <Checkbox
                        onChange={(e) => {
                          // Checked
                          if (e.target.checked) {
                            const obj = {};
                            obj[o.machineForSiteOfficeCode] = o;
                            checkedList.push(obj);
                          } else {
                            // Uncheck
                            // Delete option
                            checkedList = checkedList.filter((obj) => {
                              return !obj[o.machineForSiteOfficeCode];
                            });
                          }
                          // Update state
                          onCheck(checkedList);
                        }}
                        checked={checked || added}
                        disabled={added}
                      />
                      <Row
                        justify="space-between"
                        className="align-center w100 ml5"
                      >
                        <Col className={"bold"}>
                          {o.machineForSiteOfficeName}
                        </Col>
                        <Col>{added ? lang.ADDED : lang.NOT_ADDED}</Col>
                      </Row>
                    </div>
                    <Row className="m-more info-more">
                      {o.facilityName} • {o.buildingName} • {o.floorName} •{" "}
                      {o.roomName} • {o.processName}
                    </Row>
                  </label>
                  <Divider className="m-space" />
                </div>
              );
            })}
          </div>
          <div className="content-center w100">
            <LoadingInline isLoadingInline={stateData.loading} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </InfiniteScroll>
  );
}
