import pdfMake from "pdfmake/build/pdfmake";
import pdfFont from "./../../assets/vfs_fonts";
import { lang } from "../../constants/common.const";
import { defaultUrlBase64ImageInfomachine } from "../../assets/base64/urlBase64DefaultImage";
export default async (
  dataTableMaMaintenanceHistory,
  dataTableInfoMachine,
  dataBodyTableProject,
  dataBodyTableInventoryLinkage,
  dataBodyTableRepairPlan,
  urlImage,
  listFile,
  nameFile
) => {
  
  pdfMake.vfs = pdfFont.pdfMake.vfs;
  pdfMake.fonts = {
    MPLUS1p: {
      normal: "MPLUS1p-Regular.ttf",
      bold: "MPLUS1p-Regular.ttf",
      italics: "MPLUS1p-Regular.ttf",
      bolditalics: "MPLUS1p-Regular.ttf",
    },
  };
  pdfMake.tableLayouts = {
    tableLayout: getBaseLayOutTable(),
    infoMachineTablelayout: getBaseLayOutTable(),
    tableAttach: {
      hLineWidth: function (i, node) {
        return listFile.length ?0 : 1;
      },
      vLineWidth: function (i, node) {
        return listFile.length ?0 : 1;
      },
      hLineColor: function (i) {
        return "#f0f0f0";
      },
      vLineColor: function (i) {
        return "#f0f0f0";
      },
      
      paddingTop: function (i, node) {
        return 5;
      },
      paddingBottom: function (i, node) {
        return 5;
      },
    },
  };
  function getBaseLayOutTable() {
    return {
      hLineWidth: function (i, node) {
        return 1;
      },
      vLineWidth: function (i, node) {
        return 1;
      },
      hLineColor: function (i) {
        return "#f0f0f0";
      },
      vLineColor: function (i) {
        return "#f0f0f0";
      },
      paddingLeft: function (i) {
        return 5;
      },
      paddingRight: function (i, node) {
        return 5;
      },
      paddingTop: function (i, node) {
        return 5;
      },
      paddingBottom: function (i, node) {
        return 5;
      },
    };
  }
  const docDefinition = {
    pageSize: "A4",
    content: [
      {
        text: lang.MACHINE_LEDGER_INFOMATION,
        fontSize: 20,
        // bold: true,
        alignment: "center",
        margin: [0, 0, 0, 20]
      },
      {
        columns: [
          {
            width: "50%",
            image:urlImage?await getBase64ImageFromURL(urlImage):defaultUrlBase64ImageInfomachine,
            fit: [200, 200],
          },
          {
            layout: "tableAttach",
            width: "*",

            table: {
              widths: ["100%"],
              body:
                listFile.length === 0
                  ? [
                      [
                        {
                          text: `${lang.ATTACH_FILE} : (無し)`,
                          fillColor: "#0080CB",
                          color: "#fff",
                          // bold: true,
                        },
                      ],
                    ]
                  : [
                      [
                        {
                          text: `${lang.ATTACH_FILE}`,
                          fillColor: "#0080CB",
                          color: "#fff",
                          // bold: true,
                        },
                      ],
                      ...listFile.map((item) => [
                        {
                          text: item.attachFileName,
                          color: "#0080CB",
                          link: item.attachFileUrl,
                        },
                      ]),
                    ],
            },
          },
        ],
      },

      {
        margin: [0, 20, 0, 0],
        layout: "infoMachineTablelayout",
        table: {
          widths: ["20%", "40%", "20%", "20%"],
          body: renderBodyTableInfoMachine(dataTableInfoMachine),
        },
      },
      renderTable([
        "20%",
        "10%",
        "10%",
        "10%",
        "10%",
        "10%",
        "10%",
        "10%",
        "10%",
      ],lang.INSPECTION_TIME_NAME,dataBodyTableProject,[7]),
     
      renderTable(["20%", "20%", "20%", "20%", "20%"],lang.INVENTORY_LINKAGE,dataBodyTableInventoryLinkage),
     
      renderTable(["5%", "15%", "15%", "15%", "15%", "15%", "20%"],lang.MAINTAINCE_HISTORY,dataTableMaMaintenanceHistory),
     
      renderTable([
        "5%",
        "15%",
        "10%",
        "10%",
        "10%",
        "10%",
        "40%",
        
      ],lang.REPAIR_PLAN,dataBodyTableRepairPlan),
    ],
    footer: function (currentPage, pageCount) {
      return {
        text: currentPage.toString() + " / " + pageCount,
        alignment: "center",
      };
    },
    styles: {
      header: {
        fillColor: "#f5f5f5",
        fontWeight:true
      },
    },
    defaultStyle: {
      font: "MPLUS1p",
      fontSize: 9,
    },
  };
  const pdfDoc = pdfMake.createPdf(docDefinition);
  pdfDoc.download(nameFile.substring(0,20))
};
const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
const renderBodyTableInfoMachine = (dataTableInfoMachine) => {
  const body = [
    [
      { text: "機器情報", colSpan: 4, color: "#fff", fillColor: "#0080CB" },
      "",
      "",
      "",
    ],
  ];
  for (let i = 0; i < dataTableInfoMachine.length; i++) {
    if (
      dataTableInfoMachine[i].inlineDisplay &&
      dataTableInfoMachine[i + 1].inlineDisplay
    ) {
      body.push([
        { text: dataTableInfoMachine[i].title, style: "header" },
        dataTableInfoMachine[i].content || "",
        { text: dataTableInfoMachine[i + 1].title || "", style: "header" },
        dataTableInfoMachine[i + 1].content || "",
      ]);
      i++;
    } else {
      body.push([
        { text: dataTableInfoMachine[i].title, style: "header" },
        { text: dataTableInfoMachine[i].content || "", colSpan: 3 },
        "",
        "",
      ]);
    }
  }
  return body;
};
const renderBodyTable = (body,fieldList) => {
  return body.map((bodyItem, index) => {
    bodyItem = bodyItem.map((item,indexItem) => {
      if (Array.isArray(item)) {
        return {
          stack: item.map((i) => ({
            text: i.attachFileName,
            link: i.attachFileUrl,
            decoration: "underline",
            color: "#0080CB",
          })),
          fillColor: index % 2 !== 0 ? "#f5f5f5" : "#fff",
        };
      }
      if(fieldList && Array.isArray(fieldList) && index!==0){
        if(fieldList.includes(indexItem)){
          const arraySplit = item.split(";")
          return {
            
              // to treat a paragraph as a bulleted list, set an array of items under the ul key
              ul: arraySplit
            
          }
        }
      }
      return {
        text: item,
        fillColor: index % 2 !== 0 ? "#f5f5f5" : "#fff",
        // bold: index === 0 ? true : false,
      };
    });
    return bodyItem;
  });
};

 

const renderTable = (widths,title,dataTable,fieldList=null) => {
return  {
  layout: "tableLayout",
  margin: [0, 20, 0, 0],
  table: {
    widths,
    body:
    dataTable.length === 1
        ? [
            [
              {
                text: `${title} : (無し)`,
                fillColor: "#0080CB",
                color: "#fff",
                colSpan: widths.length,
              },...Array(widths.length -1).fill('')
             
            ],
          ]
        : [
            [
              {
                text: `${title}`,
                fillColor: "#0080CB",
                color: "#fff",
                // bold: true,
                colSpan: widths.length,
              },
              ...Array(widths.length -1).fill('')
            ],
          ].concat(renderBodyTable(dataTable,fieldList)),
  },
}
}