import {
  CheckCircleOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  HistoryOutlined,
  InfoCircleFilled,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Drawer,
  Row,
  Table,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import { ArchiveTick } from "iconsax-react";
import { groupBy } from "lodash";
import { useLocation } from "react-router-dom";

import BoxContent from "@src/component/Desktop/content";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ApiCalibration } from "../../api/backend/calibration";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import FooterPage from "../../component/footer/footerPage";
import { KEY, lang } from "../../constants/common.const";
import { MSG_CATCH, isImage, showMessage } from "../../constants/utils";
import { checkRoleScreen } from "../../utils/roles";
import "./Calibration.scss";
import "./../Machine_Management/machine.scss";
const { Panel } = Collapse;

function CalibrationView() {
  const { id } = useParams();
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [timeline_detail, setTimeline_detail] = useState(null);
  const [data, setData] = useState({
    machineForSiteOfficeName: "",
    owner: "",
    manufactureName: "",
    rating: "",
    modelNumber: "",
    sn: "",
    firstRegistDate: "",
    calibrationCertNumber: "",
    calibrationCycle: "",
    ownerValue: "",
    remarks: "",
  });
  const [visible, setVisible] = useState(false);

  // List Field
  const listField = [
    {
      title: lang.INSTRUMENT_NAME,
      value: data.machineForSiteOfficeName,
      blockDisplay: true,
    },
    {
      title: lang.OWNER,
      value: data.ownerValue,
    },
    {
      title: lang.MANUFACTURE_NAME,
      value: data.manufactureName,
    },
    {
      title: lang.RATING,
      value: data.rating,
    },
    {
      title: lang.MODEL_NUMBER,
      value: data.modelNumber,
    },
    {
      title: lang.SN,
      value: data.sn,
    },
    {
      title: lang.FIRST_REGIST_DATE,
      value: data.firstRegistDate,
    },
    {
      title: lang.CALIBRATION_CERT_NUMBER,
      value: data.calibrationCertNumber,
    },
    {
      title: lang.CALIBRATION_CYCLE,
      value: data.calibrationCycle,
    },
    {
      title: lang.REMARK,
      value: data.remarks,
      blockDisplay: true,
    },
  ];

  let dataHistory = currentDevice ? currentDevice.histories : [];
  dataHistory = dataHistory.map((item) => ({
    ...item,
    year: moment(item.updateTime).year(),
  }));

  const timeline_maintenance_history = groupBy(dataHistory, "year");

  // Render list filed
  const renderListField = listField.map((item, index) => {
    return (
      <Descriptions.Item
        span={item.blockDisplay ? 2 : 1}
        key={index}
        label={item.title}
        labelStyle={{
          width: "15%",
          fontWeight: "bold",
          padding: "8px 8px",
        }}
        contentStyle={{
          padding: "8px 8px",
          whiteSpace: "pre-wrap",
        }}
      >
        {item.value || "-"}
      </Descriptions.Item>
    );
  });
  function shortText(text) {
    if (text.length <= 6) {
      return text;
    } else {
      const firstThree = text.substring(0, 3);
      const lastThree = text.substring(text.length - 3);
      const shortenedText = firstThree + "..." + lastThree;
      return shortenedText;
    }
  }
  // Columns
  const columns = [
    {
      title: "管理No",
      width: "70px",
      render: (_, _2, index) => {
        return <div className="center">{++index}</div>;
      },
    },
    {
      title: <span>{lang.CALIBRATION_RECORD_ITEMS}</span>,
      dataIndex: "checkItemName",
      width: "20%",
      align: "center",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "100px" }}
          >
            <Col span={24}>
              <span className="white-space display-2-line">
                <Tooltip title={text}>{text}</Tooltip>
              </span>
            </Col>
          </Row>
        );
      },
    },
    {
      title: <span>{lang.VALUE}</span>,
      dataIndex: "value",
      width: "10%",
      align: "center",

      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "100px" }}
          >
            <Col span={24}>
              <span className="white-space display-2-line">
                <Tooltip title={text}>{text}</Tooltip>
              </span>
            </Col>
          </Row>
        );
      },
    },
    {
      title: <span>{lang.STANDARD_VALUE}</span>,
      dataIndex: "standardValue",
      width: "10%",
      align: "center",

      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "100px" }}
          >
            <Col span={24}>
              <span className="white-space display-2-line">
                <Tooltip title={text}>{text}</Tooltip>
              </span>
            </Col>
          </Row>
        );
      },
    },
    {
      title: <span>{lang.NEXT_CALIBRATION_DATE}</span>,
      dataIndex: "nextCalibrationDate",
      width: "15%",
      align: "center",

      render: (text, row) => {
        return (
          <Row style={{ minWidth: 100 }} justify="space-between" align="middle">
            <Col span={24}>{text}</Col>
          </Row>
        );
      },
    },
    {
      title: <span>{lang.REMARK}</span>,
      dataIndex: "remarks",
      width: "20%",
      align: "center",

      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "100px" }}
          >
            <Col span={24}>
              <span className="white-space display-5-line">
                <Tooltip title={text}>{text}</Tooltip>
              </span>
            </Col>
          </Row>
        );
      },
    },
    {
      title: <span>{lang.ATTACH}</span>,
      dataIndex: "attachments",
      width: "15%",
      align: "start",

      render: (text, row) => {
        return (
          <Row
            className="mb0"
            style={{ listStyle: "none", paddingLeft: "10px", maxWidth: 150 }}
          >
            {row.attachments.length
              ? row.attachments.map((item) => (
                  <Col span={24}>{renderListAttachment(item)}</Col>
                ))
              : ""}
          </Row>
        );
      },
    },
    {
      title: lang.RECORD_TIMELINE,
      dataIndex: "history",
      width: "10%",
      align: "center",
      render: (_, row, index) => {
        return (
          <Button
            size="middle"
            type="primary"
            className="buttonPC button--info wAuto"
            onClick={() => onSetcurrentDevice(index)}
          >
            <HistoryOutlined style={{ marginTop: 4 }} />
          </Button>
        );
      },
    },
  ];

  // Column Detail TimeLine
  const column_maintaince_history = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "50px",
      render: (item, row, index) => index + 1,
    },
    {
      title: lang.MAINTAINCE_DATE,
      dataIndex: "updateTime",
      key: "updateTime",
      width: "120px",
    },
    {
      title: lang.MANAGER,
      dataIndex: "updater",
      key: "updater",
      width: "120px",
    },
    {
      title: lang.VALUE,
      key: "value",
      width: "120px",
      dataIndex: "value",
    },
    {
      title: lang.STANDARD_VALUE,
      key: "standardValue",
      width: "120px",
      dataIndex: "standardValue",
    },

    {
      title: lang.NEXT_CALIBRATION_DATE,
      key: "nextCalibrationDate",
      width: "120px",
      dataIndex: "nextCalibrationDate",
    },

    {
      title: lang.REMARK,
      key: "remarks",
      dataIndex: "remarks",
      width: "20%",
      align: "left",
    },
    {
      title: lang.ATTACH,
      key: "attachments",
      width: "120px",
      dataIndex: "attachments",
    },
  ];

  // Khi nhấn vào button history
  const onSetcurrentDevice = (index) => {
    setCurrentDevice(details[index]);
    setVisible(true);
  };

  // Close Drawer
  const onCloseDrawerHistory = () => {
    setVisible(false);
  };

  // Get Detail Data
  const getDetailsData = async () => {
    try {
      setShowLoading(true);
      const resDetail = await ApiCalibration.getDetailCalibraion(id);
      const { id: idMachine, details, ...others } = resDetail.data;
      setData(others);
      setDetails(details);
    } catch (error) {
      showMessage(
        KEY.ERROR,
        MSG_CATCH(),
        history.push("/CalibrationManagement")
      );
    } finally {
      setShowLoading(false);
    }
  };

  // Seletc icon file
  const getIconByFileType = (name, type) => {
    if (isImage(name)) return <FileImageOutlined className="first-icon" />;
    const splitArray = name.split(".");
    const fileType = splitArray[splitArray.length - 1];

    switch (fileType) {
      case "zip":
      case "rar":
        return <FileZipOutlined className="first-icon" />;
      case "xlsx":
      case "xlsm":
      case "xls":
      case "xml":
        return <FileExcelOutlined className="first-icon" />;
      case "txt":
      case "csv":
        return <FileTextOutlined className="first-icon" />;
      case "pdf":
        return <FilePdfOutlined className="first-icon" />;
      case "docx":
      case "doc":
        return <FileWordOutlined className="first-icon" />;
      default:
        return <FileOutlined className="first-icon" />;
    }
  };

  const renderListAttachment = (item, type = 1) => {
    const name = item.attachFileName.split(".");
    const fileType = name.pop();
    return (
      <a
        href={item.attachFileUrl}
        title={item.attachFileName}
        target="_blank"
        style={{ color: "#0080CB" }}
      >
        <Typography
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            color: "#0080cb",
            fontSize: 14,
          }}
          className="mb5"
          ellipsis={{
            rows: 1,
          }}
        >
          {getIconByFileType(item.attachFileName, type)} {name}.{fileType}
        </Typography>
      </a>
    );
  };

  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      getDetailsData();
    }
  }, [id, allowRoute]);
  return (
    <div className="des-content">
      <TitlePage name={lang.VIEW_DEVICE} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          {lang.VIEW_DEVICE}
        </h1>
        <Row>
          <Col span={24}>
            <BoxContent style={{ padding: "8px", margin: "8px" }}>
              <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                <Panel
                  header={
                    <>
                      <InfoCircleFilled className="first-icon" /> 計測機器情報
                    </>
                  }
                  key="1"
                >
                  <Descriptions
                    size="small"
                    column={2}
                    bordered
                    style={{ maxHeight: "720px", overflow: "auto" }}
                  >
                    {renderListField}
                  </Descriptions>
                </Panel>
              </Collapse>
            </BoxContent>
            <BoxContent style={{ padding: "8px", margin: "8px" }}>
              <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                <Panel
                  header={
                    <>
                      <ArchiveTick className="first-icon" variant="Bold" />
                      {lang.CALIBRATION_RECORD_ITEM_LIST}
                    </>
                  }
                  key="1"
                >
                  <Table
                    locale={{
                      emptyText: "",
                    }}
                    pagination={false}
                    dataSource={details}
                    className="gridSearch calibrationViewTable"
                    columns={columns}
                    bordered
                    scroll={{ x: "auto" }}
                  />
                </Panel>
              </Collapse>
            </BoxContent>
          </Col>
        </Row>
      </Content>

      <FooterPage>
        <Row justify="space-between">
          <Col></Col>
          <Col>
            <Button
              onClick={() => history.push(`/CalibrationManagement/Edit/${id}`)}
              className="buttonPC button--info wAuto ml15"
            >
              <EditOutlined />
              {lang.EDIT}
            </Button>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15"
              onClick={() => history.goBack()}
            >
              <RollbackOutlined />
              {lang.BACK}
            </Button>
          </Col>
        </Row>
      </FooterPage>

      <Drawer
        title={lang.CALIBRATION_HISTORY}
        width={350}
        placement="right"
        visible={visible}
        onClose={onCloseDrawerHistory}
      >
        <Timeline mode={"left"}>
          {Object.keys(timeline_maintenance_history).map((year, indexYear) => {
            const dot = <CheckCircleOutlined />;
            timeline_maintenance_history[year].unshift({
              updateTime: year,
            });
            return timeline_maintenance_history[year].map((item, index) => {
              if (index === 0) {
                return (
                  <Timeline.Item
                    key={index}
                    label={
                      <Typography.Title level={5}>{year}年</Typography.Title>
                    }
                  >
                    <Typography.Text>&nbsp;</Typography.Text>
                  </Timeline.Item>
                );
              }
              const label = (
                <Typography.Text>{item.updateTime}</Typography.Text>
              );
              return (
                <Timeline.Item
                  key={index}
                  label={label || ""}
                  dot={
                    <CheckCircleOutlined
                      style={
                        indexYear === 0 && index === 1
                          ? { color: "#1890ff" }
                          : { color: "rgb(255, 85, 0)" }
                      }
                    />
                  }
                  onClick={() => {
                    setTimeline_detail(item);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text strong>
                    {item.value || "(無し)"}
                  </Typography.Text>
                </Timeline.Item>
              );
            });
          })}
        </Timeline>
        <Drawer
          title={
            timeline_detail &&
            timeline_detail.dateImplementation &&
            moment(timeline_detail.dateImplementation * 1000).format(
              "YYYY/MM/DD"
            )
          }
          width={400}
          onClose={() => setTimeline_detail(null)}
          open={timeline_detail}
          visible={timeline_detail}
          bodyStyle={{ padding: 0 }}
        >
          {timeline_detail && (
            <Descriptions size="small" column={1} bordered>
              {column_maintaince_history.map((item, index) => {
                if (item.dataIndex === "no") {
                  return <></>;
                }

                let content = timeline_detail[item.dataIndex] || "-";
                if (item.dataIndex === "updateTime") {
                  content = timeline_detail.updateTime;
                }
                if (item.dataIndex === "attachments") {
                  return (
                    <Descriptions.Item
                      key={index}
                      label={item.title}
                      labelStyle={{ width: 120, fontWeight: "bold" }}
                    >
                      <Row
                        className="mb0"
                        style={{
                          listStyle: "none",

                          maxWidth: 180,
                        }}
                      >
                        {content.length
                          ? content.map((item) => (
                              <Col span={24}>
                                {renderListAttachment(item, 0)}
                              </Col>
                            ))
                          : "-"}
                      </Row>
                    </Descriptions.Item>
                  );
                }
                return (
                  <>
                    <Descriptions.Item
                      key={index}
                      label={item.title}
                      labelStyle={{ width: 120, fontWeight: "bold" }}
                    >
                      {content}
                    </Descriptions.Item>
                  </>
                );
              })}
            </Descriptions>
          )}
        </Drawer>
      </Drawer>
      <Loading show={showLoading} />
    </div>
  );
}

export default CalibrationView;
