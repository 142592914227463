/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Layout,
  Col,
  Row,
  Select,
  Input,
  Divider,
  Button,
  Tooltip,
  Form,
  Table,
  Space,
} from "antd";
import { useMediaQuery } from "react-responsive";
import KBN from "../../api/backend/kbn";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import { Link, useLocation } from "react-router-dom";
import API from "../../api/backend/officeDetail";
import Loading from "../../component/Desktop/loading/loading";
import { CONFIG, KEY, lang } from "../../constants/common.const";
import {
  MSG_CATCH,
  enterSearch,
  showMessage,
  renderOption,
  statusRes,
  getQuerySearch,
  pushObject2Query,
} from "../../constants/utils";
import { useHistory } from "react-router-dom";
import {
  SearchOutlined,
  EyeFilled,
  EditFilled,
  ClearOutlined,
} from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import TitlePage from "../../component/Desktop/titlePage";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
const { Content } = Layout;

function CheckListManagement() {
  const objSearch = getQuerySearch();
  const history = useHistory();
  const [formSearch] = Form.useForm();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  // Column GRID
  const initColumns = [
    {
      title: "事業所コード",
      dataIndex: "siteOfficeMasterCode",
      width: "15%",
      align: "center",
    },
    {
      title: "事業所名",
      dataIndex: "siteOfficeMasterName",
      align: "left",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              <Link
                to={"/DetailOffice/" + row.siteOfficeMasterCode}
                className="white-space"
              >
                {text}
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: lang.STATUS,
      dataIndex: "statusValue",
      align: "center",
      width: "15%",
    },
    {
      title: "本社担当",
      dataIndex: "headquartersChargeName",
      align: "center",
      width: "20%",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 180,
      render: (row) => {
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button className="mr5 btnEdit">
                <Link
                  to={"/UpdateOffice/" + row.siteOfficeMasterCode}
                  className="w100"
                >
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* View */}
            <Tooltip title={lang.VIEW}>
              <Button className="mr5 btnView">
                <Link
                  to={"/DetailOffice/" + row.siteOfficeMasterCode}
                  className="white-space"
                >
                  <EyeFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            {/* <Tooltip title={lang.DELETE}>
              <Button className="mr5 btnDelete" onClick={onDeleteRow}>
                <DeleteOutlined />
              </Button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  // const onDeleteRow = (e) => {
  //   showMessageChange(() => {
  //     // Delete
  //     openLoadingSpinner();

  //     // call api

  //     closeLoadingSpinner();
  //   });
  // };

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Input
  const [txtName, setTxtName] = useState(objSearch.siteOfficeMasterName || "");
  const [ddlStatus, setDdlStatus] = useState(
    new classDdl(objSearch.status || null)
  );
  // Table
  const [grid, setGrid] = useState([]);
  const paramsSearch = {
    siteOfficeMasterName: txtName ? txtName.trim() : null,
    status: ddlStatus.value,
  };
  // Search
  const search_onClick = (onlySearch = true) => {
    // Loading
    onlySearch && openLoadingSpinner();

    return API.searchOffice(paramsSearch)
      .then((res) => {
        // Load init
        if (statusRes(res)) {
          setGrid(res.data.data);
          pushObject2Query(paramsSearch);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch(() => {
        onlySearch && showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close loading
        onlySearch && closeLoadingSpinner();
      });
  };
  // Init
  useEffect(() => {
    openLoadingSpinner();

    Promise.all([search_onClick(false), KBN.getSiteOfficeMasterStatus()])
      .then((res) => {
        let statusOption = res[1] ? res[1].data : null;

        setDdlStatus({
          ...ddlStatus,
          options: statusOption ? statusOption.data : [],
        });
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  }, []);

  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick();
  }, [txtName]);
  // Alway device
  useEffect(() => {
    checkRoleScreen(location.pathname, allowRoute) && search_onClick();
  }, [ddlStatus.value, allowRoute]);

  // clearInputSearch
  const clearInputSearch = () => {
    setTxtName(null);
    setDdlStatus({ ...ddlStatus, value: null });
    formSearch.resetFields();
    formSearch.submit();
  };

  ///// RENDER
  return (
    <div className="des-content">
      <TitlePage name={lang.OFFICE_MANAGEMENT} />
      <Content
        // className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          onFinish={search_onClick}
        >
          <Row gutter={[8, isPC ? 0 : 16]}>
            <Col span={isPC ? 5 : 24}>
              <Form.Item name="txtName" className="rowNotMargin">
                <Input
                  defaultValue={objSearch.siteOfficeMasterName || ""}
                  allowClear
                  value={txtName}
                  maxLength={255}
                  placeholder="事業所名"
                  onChange={(e) => {
                    setTxtName(e.target.value);
                  }}
                  {...enterSearch(search_onClick)}
                />
              </Form.Item>
            </Col>
            {isPC && (
              <Col span="8">
                <Button
                  className="buttonPC button--info wAuto"
                  htmlType="submit"
                >
                  <SearchOutlined />
                  {lang.SEARCH}
                </Button>
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml10"
                  onClick={clearInputSearch}
                >
                  <ClearOutlined />
                  {lang.CLEAR}
                </Button>
              </Col>
            )}
          </Row>
          {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
          <Row gutter={[8, isPC ? 0 : 8]} className="align-center">
            {isPC && (
              <Col className="bold mr10">
                {lang.FILTER}
                {lang.COLON}
              </Col>
            )}
            <Col span={isPC ? 5 : 12}>
              <Form.Item name="ddlStatus" className="rowNotMargin">
                <Select
                  defaultValue={objSearch.status || null}
                  value={ddlStatus.value}
                  placeholder={lang.STATUS}
                  onChange={(v) => {
                    setDdlStatus({ ...ddlStatus, value: v });
                  }}
                >
                  {renderOption(ddlStatus.options)}
                  {/* <Option value=""></Option>
                  <Option value="1">{lang.OFFICIAL}</Option>
                  <Option value="0">{lang.DRAFT}</Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
      {/* New */}
      <Content
        className={isPC && "site-layout-background"}
        style={{
          margin: "24px 16px",
          padding: isPC ? 24 : 0,
        }}
      >
        <Row justify="space-between ">
          <Col span={12}>
            <Button
              type="link"
              onClick={() => {
                history.push("/CreateOffice");
              }}
              className="btnAddRecord mb15"
            >
              <AddIcon />
              {lang.ADD}
            </Button>
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              該当件数 <span>{grid.length}件</span>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey={(record) => record.siteOfficeMasterCode}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid}
          pagination={CONFIG.paging}
          scroll={{ x: "auto" }}
        />
      </Content>

      {/* Loader */}
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default CheckListManagement;
