import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Apifacility } from "../../../api/backend/facilityManagement";
import BreakLine from "../../../component/Desktop/breakLine";
import GroupItem from "../../../component/Desktop/groupItem";
import Loading from "../../../component/Desktop/loading/loading";
import RowInput from "../../../component/Desktop/rowInput";
import TitlePage from "../../../component/Desktop/titlePage";
import FormInput from "../../../component/FormInput";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import classDdl from "../../../models/control/dropdownlist";
import classText from "../../../models/control/text";
import { checkRoleScreen } from "../../../utils/roles";
import KbnApi from "./../../../api/backend/kbn";
import "./facility.scss";
import LocationTree from "./locationAddTree";
function Addfacility() {
  const { id } = useParams();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [dataInput, setDataInput] = useState({
    facilityName: new classText(),
    completionDate: new classText(),
    note: new classText(),
    plantName: new classDdl(),
    address: new classText(),
    startDate: new classText(),
    endDate: new classText(),
    facilityManager: new classDdl(),
    // postalCode: new classText(),
    zipCode1: new classText(),
    zipCode2: new classText(),
    prefectureName: new classDdl(),
    processingMethod: new classText(),
    processingAbility: new classText(),
    status: new classText(),
  });
  const [listBuilding, setListBuiding] = useState([]);
  const [listFloor, setListFloor] = useState([]);
  const [listRoom, setListRoom] = useState([]);
  const facilityRef = useRef();
  const prefectRef = useRef();
  const zipCode1Ref = useRef();
  const zipCode2Ref = useRef();
  const plantNameRef = useRef();
  const currentDataDetail = useRef();
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const handleChangeInput =
    (key, type = null) =>
    (eV) => {
      const newValue = type === "input" ? eV.target.value : eV;
      setDataInput({
        ...dataInput,
        [key]: {
          ...dataInput[key],
          value: newValue,
        },
      });
    };
  const listDataInput = [
    {
      title: lang.NAME_FACILITY,
      valueObject: dataInput.facilityName,
      onChange: handleChangeInput("facilityName", "input"),
      require: true,
      type: "input",
      refer: facilityRef,
    },
    {
      title: lang.COMPLETION_DATE,
      valueObject: dataInput.completionDate,
      onChange: handleChangeInput("completionDate"),
      require: false,
      type: "date",
    },
    {
      title: lang.REMARK,
      valueObject: dataInput.note,
      onChange: handleChangeInput("note", "input"),
      require: false,
      type: "input",
    },
    {
      title: lang.PLANT_NAME,
      valueObject: dataInput.plantName,
      onChange: handleChangeInput("plantName"),
      require: true,
      type: "select",
      refer: plantNameRef,
    },
    {
      title: lang.APPLICABLE_START_DATE,
      valueObject: dataInput.startDate,
      onChange: handleChangeInput("startDate"),
      require: false,
      type: "date",
      afterDate: dataInput.endDate.value
        ? moment(dataInput.endDate.value).format(KEY.DATE_DEFAULT)
        : null,
    },
    {
      title: lang.APPLICABLE_END_DATE,
      valueObject: dataInput.endDate,
      onChange: handleChangeInput("endDate"),
      require: false,
      type: "date",
      beforeDate: dataInput.startDate.value
        ? moment(dataInput.startDate.value).format(KEY.DATE_DEFAULT)
        : null,
    },
    {
      title: lang.FACILITY_MANAGER,
      valueObject: dataInput.facilityManager,
      onChange: handleChangeInput("facilityManager"),
      require: false,
      type: "select",
    },
    {
      title: lang.NAME_OF_PREFECTURE,
      valueObject: dataInput.prefectureName,
      onChange: handleChangeInput("prefectureName"),
      require: true,
      type: "select",
      refer: prefectRef,
    },
    {
      title: lang.ADDRESS,
      valueObject: dataInput.address,
      onChange: handleChangeInput("address", "input"),
      require: false,
      type: "input",
    },
    {
      title: lang.PROCESSING_METHOD,
      valueObject: dataInput.processingMethod,
      onChange: handleChangeInput("processingMethod", "input"),
      require: false,
      type: "input",
    },
    {
      title: lang.PROCESSING_POWER,
      valueObject: dataInput.processingAbility,
      onChange: handleChangeInput("processingAbility", "input"),
      require: false,
      type: "input",
    },
  ];
  const renderListInput = (listData) =>
    listData.map((item, index) => (
      <div key={index}>
        <BreakLine />
        <RowInput required={item.require} name={item.title} box>
          <FormInput {...item} />
        </RowInput>
        <BreakLine />
      </div>
    ));
  const handleAddBuilding = () => {
    const newBuilding = {
      buildingName: "",
      buildingCode: Date.now().toString(),
      isNew: true,
    };
    setListBuiding([...listBuilding, newBuilding]);
  };
  const handleChangeBuildingName = (buildingItem, newvalue) => {
    const index = listBuilding.findIndex(
      (item) => buildingItem.buildingCode === item.buildingCode
    );
    if (index >= 0) {
      const newListBuilding = [...listBuilding];
      newListBuilding[index].buildingName = newvalue;
      newListBuilding[index].error = newvalue.trim() ? false : true;
      setListBuiding(newListBuilding);
    }
  };
  const handleAddFloor = (buildingCode) => {
    const floorCode = Date.now().toString();
    const newFloor = {
      floorName: "",
      floorCode,
      buildingCode,
      isNew: true,
    };
    const newListFloor = [...listFloor];
    newListFloor.push(newFloor);
    setListFloor(newListFloor);
  };
  const handleChangeFloorName = (buildingCode, floorCode, newValue) => {
    const index = listFloor.findIndex(
      (item) =>
        item.buildingCode === buildingCode && item.floorCode === floorCode
    );
    if (index >= 0) {
      const newListfloor = [...listFloor];
      newListfloor[index].floorName = newValue;
      newListfloor[index].error = newValue.trim() ? false : true;
      setListFloor(newListfloor);
    }
  };
  const handleAddRoom = (floorCode, buildingCode) => {
    const newRoom = {
      roomName: "",
      roomCode: Date.now().toString(),
      floorCode,
      buildingCode,
      isNew: true,
    };
    setListRoom([...listRoom, newRoom]);
  };
  const handleChangeRoomName = (floorCode, roomCode, newValue) => {
    const index = listRoom.findIndex(
      (item) => item.floorCode === floorCode && item.roomCode === roomCode
    );
    if (index >= 0) {
      const newListRoom = [...listRoom];
      newListRoom[index].roomName = newValue;
      newListRoom[index].error = newValue.trim() ? false : true;
      setListRoom(newListRoom);
    }
  };

  const handleDeleteBuilding = (buildingCode) => {
    setListBuiding(
      listBuilding.filter((item) => item.buildingCode !== buildingCode)
    );
    const newListFloor = listFloor.filter(
      (item) => item.buildingCode !== buildingCode
    );
    const newListRoom = listRoom.filter(
      (item) =>
        newListFloor.findIndex(
          (itemFloor) => itemFloor.floorCode === item.floorCode
        ) >= 0
    );
    setListFloor(newListFloor);
    setListRoom(newListRoom);
  };
  const handleDeleteFloor = (buildingCode, floorCode) => {
    const index = listFloor.findIndex(
      (item) =>
        item.buildingCode === buildingCode && item.floorCode === floorCode
    );
    if (index >= 0) {
      const newListFloor = [...listFloor];
      newListFloor.splice(index, 1);
      setListFloor(newListFloor);
      setListRoom(listRoom.filter((item) => item.floorCode !== floorCode));
    }
  };
  const handleDeleteRoom = (floorCode, roomCode) => {
    const index = listRoom.findIndex(
      (item) => item.floorCode === floorCode && item.roomCode === roomCode
    );
    if (index >= 0) {
      const newListRoom = [...listRoom];
      newListRoom.splice(index, 1);
      setListRoom(newListRoom);
    }
  };
  const onCancel = () => {
    history.goBack();
  };

  const isArrayNoHasTwoSameValue = (arr) => {
    const setArr = new Set(arr);
    return setArr.size === arr.length;
  };
  const checkNameSakeBuilding = () => {
    const buildings = getBuidingsData();
    const listNameBuildings = buildings.map((item) => item.buildingName);
    if (!isArrayNoHasTwoSameValue(listNameBuildings)) {
      showMessage(KEY.ERROR, lang.MESS_CONFIRM_SAME_BUILDING_NAME);
      return false;
    }
    for (let i = 0; i < buildings.length; i++) {
      const listFloor = buildings[i].floors;
      const listFloorName = listFloor.map((item) => item.floorName);
      if (!isArrayNoHasTwoSameValue(listFloorName)) {
        showMessage(KEY.ERROR, lang.MESS_CONFIRM_SAME_FLOOR_NAME);
        return false;
      }
      for (let k = 0; k < listFloor.length; k++) {
        const listRoomName = listFloor[k].rooms.map((item) => item.roomName);
        if (!isArrayNoHasTwoSameValue(listRoomName)) {
          showMessage(KEY.ERROR, lang.MESS_CONFIRM_SAME_ROOM_NAME);
          return false;
        }
      }
    }

    return true;
  };
  const validatedData = () => {
    if (dataInput.zipCode1.value && !dataInput.zipCode2.value) {
      setDataInput({
        ...dataInput,
        zipCode2: { ...dataInput.zipCode2, error: true },
      });
      zipCode2Ref.current.focus();
      return false;
    }
    if (dataInput.zipCode2.value && !dataInput.zipCode1.value) {
      setDataInput({
        ...dataInput,
        zipCode1: { ...dataInput.zipCode1, error: true },
      });
      zipCode1Ref.current.focus();
      return false;
    }
    if (!dataInput.facilityName.value || !dataInput.facilityName.value.trim()) {
      setDataInput({
        ...dataInput,
        facilityName: { ...dataInput.facilityName, error: true },
      });
      facilityRef.current.focus();
      return false;
    }
    if (!dataInput.plantName.value) {
      setDataInput({
        ...dataInput,
        plantName: { ...dataInput.plantName, error: true },
      });
      plantNameRef.current.focus();
      return false;
    }
    if (!dataInput.prefectureName.value) {
      setDataInput({
        ...dataInput,
        prefectureName: { ...dataInput.prefectureName, error: true },
      });
      prefectRef.current.focus();
      return false;
    }
    let flagCheckNull = false;
    const listBuildingCheck = listBuilding.map((buildingItem) => {
      if (!buildingItem.buildingName.trim()) {
        if (!flagCheckNull) flagCheckNull = true;
        buildingItem.error = true;
      } else {
        if (buildingItem.error) {
          buildingItem.error = false;
        }
      }
      return buildingItem;
    });
    const listFlooCheck = listFloor.map((floorItem) => {
      if (!floorItem.floorName.trim()) {
        if (!flagCheckNull) flagCheckNull = true;
        floorItem.error = true;
      } else {
        if (floorItem.error) {
          floorItem.error = false;
        }
      }
      return floorItem;
    });
    const listRoomCheck = listRoom.map((roomItem) => {
      if (!roomItem.roomName.trim()) {
        if (!flagCheckNull) flagCheckNull = true;
        roomItem.error = true;
      } else {
        if (roomItem.error) {
          roomItem.error = false;
        }
      }
      return roomItem;
    });
    setListBuiding(listBuildingCheck);
    setListFloor(listFlooCheck);
    setListRoom(listRoomCheck);
    if (flagCheckNull) return false;
    if (!checkNameSakeBuilding()) return false;
    return true;
  };
  const handleChangeZipCode = (key) => (e) => {
    const newValue = e.target.value;
    if (newValue.split("").every((char) => char >= "0" && char <= "9")) {
      setDataInput({
        ...dataInput,
        [key]: {
          ...dataInput[key],
          value: e.target.value,
        },
      });
    }
  };
  const getBuidingsData = () => {
    let listBuildingClone = JSON.parse(JSON.stringify(listBuilding));
    const buildingsData = listBuildingClone.map((buildingItem) => {
      const listFloorClone = JSON.parse(JSON.stringify(listFloor));
      let listFloorsOfBuilding = listFloorClone.filter(
        (item) => item.buildingCode === buildingItem.buildingCode
      );
      listFloorsOfBuilding = listFloorsOfBuilding.map((floorItem) => {
        const listRoomClone = JSON.parse(JSON.stringify(listRoom));
        floorItem.rooms = listRoomClone
          .filter((item) => item.floorCode === floorItem.floorCode)
          .map((roomItem) => {
            if (roomItem.hasOwnProperty("isNew")) {
              roomItem.roomCode = null;
              delete roomItem.isNew;
            }
            return roomItem;
          });
        if (floorItem.hasOwnProperty("isNew")) {
          floorItem.floorCode = null;
          delete floorItem.isNew;
        }
        return floorItem;
      });
      buildingItem.facilityCode = "";
      buildingItem.floors = listFloorsOfBuilding;
      if (buildingItem.hasOwnProperty("isNew")) {
        buildingItem.buildingCode = null;
        delete buildingItem.isNew;
      }
      return buildingItem;
    });
    return buildingsData;
  };
  const onSaveData = async () => {
    if (!validatedData()) return;
    const buildingsData = getBuidingsData();
    const body = {
      FacilityCode: "",
      FacilityName: dataInput.facilityName.value,
      CompletionDate: dataInput.completionDate.value
        ? moment(dataInput.completionDate.value).format(KEY.DATE_DEFAULT)
        : null,
      Note: dataInput.note.value,
      Address: dataInput.address.value,
      StartDate: dataInput.startDate.value
        ? moment(dataInput.startDate.value).format(KEY.DATE_DEFAULT)
        : null,
      EndDate: dataInput.endDate.value
        ? moment(dataInput.endDate.value).format(KEY.DATE_DEFAULT)
        : null,
      FacilityManager: dataInput.facilityManager.value,
      ZipCode1: dataInput.zipCode1.value,
      ZipCode2: dataInput.zipCode2.value,
      PrefectureName: "",
      ProcessingMethod: dataInput.processingMethod.value,
      ProcessingAbility: dataInput.processingAbility.value,
      PrefectureCode: dataInput.prefectureName.value,
      SiteOfficeMasterCode: "",
      ErrorMessage: "",
      Buildings: buildingsData,
      Status: dataInput.status.value,
      PlantCode: dataInput.plantName.value,
      PlantName: "",
    };
    if (id) {
      body.FacilityCode = id;
    }
    const postFacility = async () => {
      setShowLoading(true);
      const formData = new FormData();
      formData.append("data", JSON.stringify(body));

      try {
        setShowLoading(true);
        !id
          ? await Apifacility.createFacility(formData)
          : await Apifacility.updateFacility(formData);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => history.goBack());
      } catch (error) {
        let msg;
        if (error.response) {
          if (error.response.status === 400) {
            console.log(error.response);
            msg = error.response.data.msg;
          }
        }
        showMessage(
          KEY.ERROR,
          msg || MSG_CATCH(),
          msg && id && renderTreeDetails(currentDataDetail.current)
        );
      } finally {
        setShowLoading(false);
      }
    };
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: postFacility,
    });
  };
  const renderTreeDetails = (data) => {
    let listBuildingData = [];
    let listFloorData = [];
    let listRoomData = [];
    listBuildingData = data.buildings.map(({ buildingCode, buildingName }) => ({
      buildingCode,
      buildingName,
    }));
    data.buildings.forEach((buildingItem) => {
      const floors = buildingItem.floors.map(
        ({ floorCode, floorName, buildingCode }) => ({
          floorCode,
          floorName,
          buildingCode,
        })
      );
      listFloorData = listFloorData.concat(floors);
      buildingItem.floors.forEach((floorItem) => {
        const rooms = floorItem.rooms.map(
          ({ roomCode, roomName, floorCode }) => ({
            roomCode,
            roomName,
            floorCode,
          })
        );
        listRoomData = listRoomData.concat(rooms);
      });
    });
    setListBuiding(listBuildingData);
    setListFloor(listFloorData);
    setListRoom(listRoomData);
  };
  const fetchDetail = async () => {
    id && setShowLoading(true);
    try {
      const [
        detailfacilityRes,
        prefectureData,
        facilityManagerData,
        plantNameData,
      ] = await Promise.all([
        Apifacility.getDetail({
          facilityCode: id,
        }),
        KbnApi.getPrefectures(),
        Apifacility.getListUserForManangerFacility(),
        Apifacility.getListPlantName(),
      ]);
      if (!detailfacilityRes.data && id) throw { status: 404 };
      if (id) {
        const { data } = detailfacilityRes;
        currentDataDetail.current = data;
        setDataInput({
          ...dataInput,
          facilityName: {
            ...dataInput.facilityName,
            value: data.facilityName,
          },
          zipCode1: {
            ...dataInput.zipCode1,
            value: data.zipCode1,
          },
          zipCode2: {
            ...dataInput.zipCode2,
            value: data.zipCode2,
          },
          address: { ...dataInput.address, value: data.address },
          completionDate: {
            ...dataInput.completionDate,
            value: data.completionDate ? moment(data.completionDate) : null,
          },
          note: { ...dataInput.note, value: data.note },
          startDate: {
            ...dataInput.startDate,
            value: data.startDate ? moment(data.startDate) : null,
          },
          endDate: {
            ...dataInput.endDate,
            value: data.endDate ? moment(data.endDate) : null,
          },
          processingMethod: {
            ...dataInput.processingMethod,
            value: data.processingMethod,
          },

          // postalCode: { ...dataInput.postalCode, value: data.postalCode },
          prefectureName: {
            ...dataInput.prefectureName,
            value: data.prefectureCode,
            options: prefectureData.data.data,
          },
          facilityManager: {
            ...dataInput.facilityManager,
            value: data.facilityManager,
            options: facilityManagerData.data.map((item) => ({
              key: item.userName,
              value: item.displayName,
            })),
          },
          plantName: {
            ...dataInput.plantName,
            value: data.plantCode,
            options: plantNameData.data.map((item) => ({
              key: item.plantCode,
              value: item.plantName,
            })),
          },
          processingAbility: {
            ...dataInput.processingAbility,
            value: data.processingAbility,
          },
          status: {
            ...dataInput.status,
            value: data.status,
          },
        });
        renderTreeDetails(data);
      } else {
        setDataInput({
          ...dataInput,
          prefectureName: {
            ...dataInput.prefectureName,
            options: prefectureData.data.data,
          },
          facilityManager: {
            ...dataInput.facilityManager,
            options: facilityManagerData.data.map((item) => ({
              key: item.userName,
              value: item.displayName,
            })),
          },
          plantName: {
            ...dataInput.plantName,
            options: plantNameData.data.map((item) => ({
              key: item.plantCode,
              value: item.plantName,
            })),
          },
        });
      }
    } catch (error) {
      if (error.status === 404) {
        history.goBack();
      } else showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) fetchDetail();
  }, [id, allowRoute]);
  return (
    <div className="des-content">
      <TitlePage
        name={
          id
            ? lang.FACILITY_MANEGEMENT_INFO_UPDATE
            : lang.FACILITY_MANEGEMENT_INFO_ADD
        }
      />

      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">
            {id
              ? lang.FACILITY_MANEGEMENT_INFO_UPDATE
              : lang.FACILITY_MANEGEMENT_INFO_ADD}
          </h1>
        </div>
        <div className="mt15"></div>
        {renderListInput(listDataInput.slice(0, 8))}
        <BreakLine />
        <RowInput name={lang.POST_CODE} box>
          <Row>
            <Col span="3">
              <Input
                className={`${
                  dataInput.zipCode1.error ? STYLE.BORDER_RED : ""
                } w100`}
                ref={zipCode1Ref}
                type="text"
                value={dataInput.zipCode1.value}
                maxLength={3}
                onChange={handleChangeZipCode("zipCode1")}
              />
            </Col>
            <Col span="1" className="ml20 mr-5 mt5">
              {"-"}
            </Col>
            <Col span="3">
              <Input
                className={`${
                  dataInput.zipCode2.error ? STYLE.BORDER_RED : ""
                } w100`}
                ref={zipCode2Ref}
                type="text"
                value={dataInput.zipCode2.value}
                maxLength={4}
                onChange={handleChangeZipCode("zipCode2")}
              />
            </Col>
          </Row>
        </RowInput>
        <BreakLine />
        {renderListInput(listDataInput.slice(8))}
        <RowInput name={lang.SITE_MANAGEMENT} box>
          <Row>
            <Col span={24}>
              <Row gutter={[4, 0]}>
                <Col span={7}>
                  <div className="titleLocation">{lang.BUILDING}</div>
                </Col>
                <Col span={7}>
                  <div className="titleLocation">{lang.FLOOR}</div>
                </Col>
                <Col span={7}>
                  <div className="titleLocation">{lang.ROOM}</div>
                </Col>
              </Row>
            </Col>
            <Col span="24">
              <LocationTree
                listBuilding={listBuilding}
                listFloor={listFloor}
                listRoom={listRoom}
                handleAddBuilding={handleAddBuilding}
                handleAddFloor={handleAddFloor}
                handleAddRoom={handleAddRoom}
                handleChangeBuildingName={handleChangeBuildingName}
                handleChangeFloorName={handleChangeFloorName}
                handleChangeRoomName={handleChangeRoomName}
                handleDeleteBuilding={handleDeleteBuilding}
                handleDeleteFloor={handleDeleteFloor}
                handleDeleteRoom={handleDeleteRoom}
                id={id}
              />
            </Col>
          </Row>
        </RowInput>
        <BreakLine />
        <Row className="mt20" justify="end" gutter={[8, 8]}>
          <Button
            onClick={() => onSaveData()}
            className="buttonPC button--info wAuto ml15"
          >
            <SaveOutlined />
            {lang.SAVE}
          </Button>

          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={onCancel}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
      <Loading show={showLoading} />
    </div>
  );
}

export default Addfacility;
