import { AuthenticationContext, adalGetToken } from "react-adal";
import { KEY } from "@src/constants/common.const";
export const config = {
  clientEndpoint: "https://utks.shi-ev.com",
  defaultEndpoint: "https://utksapi.shi-ev.com/api/office/",
  backendEndpoint: "https://utksapi.shi-ev.com/api/office/",
  clientId: "837e977d-7d1c-4a84-9969-5797d09c0d27",
  timeoutDefault: 600000,
};
export const adalConfig = {
  tenant: "adfs",
  clientId: config.clientId,
  redirectUri: config.clientEndpoint,
  postLogoutRedirectUri: config.clientEndpoint,
  instance: "https://evgfs.shi.co.jp/",
  endpoints: {
    api: config.defaultEndpoint,
  },
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

async function getNewToken() {
  let token = authContext.getCachedToken(adalConfig.clientId);
  if (token === null) {
    token = await adalGetToken(authContext, adalConfig.clientId);
  }
  return token;
}

export const getToken = getNewToken;

export const getUser = () => authContext.getCachedUser();

export const logOut = () => {
  const idToken = localStorage.getItem("adal.idtoken");
  if (idToken !== null && idToken !== "") {
    localStorage.removeItem("siteOffice");
    localStorage.removeItem("displayName");
    localStorage.removeItem(KEY.USER_ROLE);
    authContext.logOut();
  } else {
    localStorage.clear();
    window.location.replace(config.clientEndpoint);
  }
};

export const toyotaKamoId = 23001;
export const arrayClInputDate = [636,639,642];
export const yuniId = 1002;
export const workingSaveStatus = [2,3];
