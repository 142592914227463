/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import UpdateMaster from "./update";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import API from "../../api/backend/inventory";
import KBN from "../../api/backend/kbn";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  isEmpty,
  MSG_CATCH,
  showMessage,
  showMessageChange,
  statusRes,
} from "../../constants/utils";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
import { isString, isObject } from "lodash";

export default function WarehousesUpdate() {
  const { id, id: modeUpdate } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [warehouses, setWarehouses] = useState({
    ...new classText(),
    ref: useRef(null),
  });
  const [warehousesCode, setWarehousesCode] = useState(new classText());

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const [facility, setFacility] = useState({
    ...new classDdl(),
    disabled: false,
    ref: useRef(null),
  });
  const [building, setBuilding] = useState({
    ...new classDdl(),
    ref: useRef(null),
  });
  const [floor, setFloor] = useState({
    ...new classDdl(),
    ref: useRef(null),
  });
  const [room, setRoom] = useState({
    ...new classDdl(),
    ref: useRef(null),
  });
  const [imageUrl, setImageUrl] = useState('');
  const [note, setNote] = useState(new classText());
  const [creator, setCreator] = useState(new classText());
  const [createDate, setCreateDate] = useState(new classText());
  const [updater, setUpdater] = useState(new classText());
  const [updateDate, setUpdateDate] = useState(new classText());
  const [flagChange, setFlagChange] = useState(false);

  const uploadFile = async (file) => {
    if (!file) {
      setImageUrl('');
      return;
    };

    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await API.uploadFile(formData);
      if (res.data && res.data.url) {
        setImageUrl(res.data.url);
      }
    } catch (err) {
      let msg = MSG_CATCH();
      if (!isEmpty(err.response) && isString(err.response.data) && !isEmpty(err.response.data)) {
          msg = err.response.data;
      }
      if (!isEmpty(err.response) && isObject(err.response.data) && isString(err.response.data.msg)) {
          msg = err.response.data.msg;
      }
      showMessage(KEY.ERROR, msg);
    }
  };

  const items = [
    {
      name: lang.WAREHOUSES_NAME,
      get: warehouses,
      set: setWarehouses,
      type: KEY.TEXT,
      required: true,
    },
    ...(modeUpdate
      ? [
          {
            name: lang.WAREHOUSES_CODE,
            get: warehousesCode,
          },
        ]
      : []),
    {
      name: lang.FACILITY,
      get: facility,
      set: setFacility,
      type: KEY.DDL,
      required: true,
      onChange: (v) => {
        setFacility({ ...facility, value: v });
        setFlagChange(true);
        onChangeFacility(v);
      },
    },
    {
      name: lang.BUILDING,
      get: building,
      set: setBuilding,
      type: KEY.DDL,
      onChange: (v) => {
        setBuilding({ ...building, value: v });
        onChangeBuilding(v);
      },
    },
    {
      name: lang.FLOOR,
      get: floor,
      set: setFloor,
      type: KEY.DDL,
      onChange: (v) => {
        setFloor({ ...floor, value: v });
        onChangeFloor(v);
      },
    },
    {
      name: lang.ROOM,
      get: room,
      set: setRoom,
      type: KEY.DDL,
    },
    {
      name: lang.NOTE,
      get: note,
      set: setNote,
      type: KEY.TEXTAREA,
    },
    ...(modeUpdate
      ? [
          {
            name: lang.CREATOR,
            get: creator,
          },
          {
            name: lang.CREATE_DATE,
            get: createDate,
          },
          {
            name: lang.UPDATER,
            get: updater,
          },
          {
            name: lang.UPDATE_DATE,
            get: updateDate,
          },
        ]
      : []),
      {
        name: lang.PHOTO,
        get: imageUrl,
        set: uploadFile,
        type: KEY.IMAGE,
      },
  ];

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setLoading(true);

      (modeUpdate ? getDetail() : getFacility())
        .then((res) => {
          if (modeUpdate) {
            getFacility();
            getBuilding(res.data.facilityCode);
            getFloor(res.data.buildingCode);
            getRoom(res.data.floorCode);
          }
        })
        .catch((err) => {
          history.push("/WarehouseMaster");
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [allowRoute]);

  const funcReset = (preV) => ({ ...preV, disabled: true, value: null });
  const getDetail = () => {
    const api = new Promise((resolve, reject) => {
      API.detailWarehouses(id)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
    api.then((res) => {
      if (statusRes(res)) {
        const data = res.data;
        setImageUrl(data.imageUrl || '');
        setWarehousesCode((preV) => ({
          ...preV,
          value: data.inventoryWarehouseCode,
        }));
        setWarehouses((preV) => ({
          ...preV,
          value: data.inventoryWarehouseName,
        }));
        setFacility((preV) => ({
          ...preV,
          value: data.facilityCode,
          disabled: data.haveItems,
        }));
        setBuilding((preV) => ({ ...preV, value: data.buildingCode }));
        setFloor((preV) => ({ ...preV, value: data.floorCode }));
        setRoom((preV) => ({ ...preV, value: data.roomCode }));
        setNote((preV) => ({ ...preV, value: data.note }));
        //
        setCreator((preV) => ({ ...preV, value: data.creater }));
        setCreateDate((preV) => ({ ...preV, value: data.createDate }));
        setUpdater((preV) => ({ ...preV, value: data.updater }));
        setUpdateDate((preV) => ({ ...preV, value: data.updateDate }));
      } else {
        throw new Error();
      }
    });
    return api;
  };
  // Location
  const getFacility = async () => {
    return await KBN.getFacility().then((res) => {
      if (statusRes(res)) {
        setFacility((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.facilityCode;
            v["value"] = v.facilityName;
            return v;
          }),
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getBuilding = async (idFacility) => {
    return await KBN.getBuilding(idFacility).then((res) => {
      if (statusRes(res)) {
        setBuilding((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.buildingCode;
            v["value"] = v.buildingName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getFloor = async (idBuilding) => {
    return await KBN.getFloor(idBuilding).then((res) => {
      if (statusRes(res)) {
        setFloor((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.floorCode;
            v["value"] = v.floorName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getRoom = async (idFloor) => {
    return await KBN.getRoom(idFloor).then((res) => {
      if (statusRes(res)) {
        setRoom((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.roomCode;
            v["value"] = v.roomName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  // Facility
  const onChangeFacility = (v) => {
    setBuilding(funcReset);
    setFloor(funcReset);
    setRoom(funcReset);
    if (!isEmpty(v)) {
      getBuilding(v);
    }
  };
  // Building
  const onChangeBuilding = (v) => {
    setFloor(funcReset);
    setRoom(funcReset);
    if (!isEmpty(v)) {
      getFloor(v);
    }
  };
  // Floor
  const onChangeFloor = (v) => {
    setRoom(funcReset);
    if (!isEmpty(v)) {
      getRoom(v);
    }
  };

  const onSave = () => {
    setLoading(true);

    (modeUpdate ? API.updateWarehouses : API.createWarehouses)({
      inventoryWarehouseCode: warehousesCode.value,
      inventoryWarehouseName: warehouses.value,
      facilityCode: facility.value,
      buildingCode: building.value,
      floorCode: floor.value,
      roomCode: room.value,
      note: note.value,
      imageUrl: imageUrl,
      imageName: ''
    })
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
            history.goBack();
          });
        } else {
          showMessage(KEY.ERROR, (res && res.data.msg) || MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(
          KEY.ERROR,
          (err.response && err.response.data.msg) || MSG_CATCH()
        );
      })
      .then(() => {
        setLoading(false);
      });
  };
  const onBack = () => {
    if (flagChange) {
      showMessageChange(history.goBack, COMMON_CONSTANT.BOM_C018);
    } else {
      history.goBack();
    }
  };

  return (
    <UpdateMaster
      flagChange={[flagChange, setFlagChange]}
      name={lang.WAREHOUSES_MASTER}
      items={items}
      onSave={onSave}
      onBack={onBack}
      loading={loading}
    />
  );
}
