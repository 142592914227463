class classBuhin {
    constructor(inventoryItemName, inventoryItemCode, stockQuantity, BuhinName, BuhinID, Quantity, machineForSiteOfficeCode) {
        this.inventoryItemCode = inventoryItemCode;
        this.inventoryItemName = inventoryItemName;
        this.stockQuantity = stockQuantity;
        this.BuhinID = BuhinID;
        this.BuhinName = BuhinName;
        this.Quantity = Quantity;
        this.machineForSiteOfficeCode = machineForSiteOfficeCode;
    }
};

export default classBuhin;