import { createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/reportManagement";
import { loadingDesktop } from "./loading";
import React from "react";
import { Modal } from "antd";
import { COMMON_CONSTANT, lang, KEY } from "@src/constants/common.const";
import { showMessage, MSG_CATCH } from '@src/constants/utils';
import { isEmpty, isObject, isString } from 'lodash';

const initialState = {
  templateChecklist: [],
  numberOfTemp: 0,
  reportType: [],
  changeData: false,
  loadingTable: true,
  currentTemplate: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateTemplateChecklist: (state, { payload }) => {
      state.templateChecklist = payload.data;
      state.numberOfTemp = payload.count;
    },
    updateReportType: (state, { payload }) => {
      state.reportType = payload.data;
    },
    showLoadingTable: (state, { payload }) => {
      state.loadingTable = payload;
    },
    updateCurrentTemplate: (state, { payload }) => {
      state.currentTemplate = payload;
    },
  },
});

// the action
export const {
  updateTemplateChecklist,
  updateReportType,
  showLoadingTable,
  updateCurrentTemplate,
} = reportSlice.actions;

//the selector
export const reportSelector = (state) => state.report;

// the reducer
export default reportSlice.reducer;

// get report type
export const getReportType = () => (dispatch) => {
  API.getReportType()
    .then((res) => {
      if (res && res.status === 200) {
        dispatch(updateReportType(res.data));
      }
    })
    .catch((error) => console.log(error));
};
export const addTemplateChecklist = (data, fileData, history) => (dispatch) => {
  dispatch(loadingDesktop(true));
  API.addChecklistTemplate(data, fileData)
    .then((res) => {
      dispatch(loadingDesktop(false));
      if (res && res.status === 200) {
        Modal.success({
          centered: true,
          title: <span style={{ fontWeight: "bold" }}>{lang.SUCCESS}</span>,
          content: COMMON_CONSTANT.BOM_C002,
          okText: lang.OK,
        });
        history.push("/TemplateManagement");
      } else {
        let arrMessage = res.data;
        if (arrMessage.msg) {
          Modal.error({
            centered: true,
            title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
            content: arrMessage.msg,
            okText: lang.OK,
          });
        } else {
          Modal.error({
            centered: true,
            title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
            content: COMMON_CONSTANT.BOM_E029,
            okText: lang.OK,
          });
        }
      }
    })
    .catch((err) => {
      dispatch(loadingDesktop(false));
      let msg = MSG_CATCH();
      if (!isEmpty(err.response) && isString(err.response.data) && !isEmpty(err.response.data)) {
        msg = err.response.data;
      }
      if (!isEmpty(err.response) && isObject(err.response.data) && isString(err.response.data.msg)) {
        msg = err.response.data.msg;
      }
      showMessage(KEY.ERROR, msg);
    });
};
export const editTemplateChecklist = (data, fileData, history) => (
  dispatch
) => {
  dispatch(loadingDesktop(true));
  API.editChecklistTemplate(data, fileData)
    .then((res) => {
      dispatch(loadingDesktop(false));
      if (res && res.status === 200) {
        Modal.success({
          centered: true,
          title: <span style={{ fontWeight: "bold" }}>{lang.SUCCESS}</span>,
          content: COMMON_CONSTANT.BOM_C003,
          okText: lang.OK,
        });
        history.push("/TemplateManagement");
      }else {
        let arrMessage = res.data;
        if (arrMessage.msg) {
          Modal.error({
            centered: true,
            title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
            content: arrMessage.msg,
            okText: lang.OK,
          });
        } else {
          Modal.error({
            centered: true,
            title: <span style={{ fontWeight: "bold" }}>{lang.ERROR}</span>,
            content: COMMON_CONSTANT.BOM_E029,
            okText: lang.OK,
          });
        }
      }
    })
    .catch((err) => {
      dispatch(loadingDesktop(false));
      let msg = MSG_CATCH();
      if (!isEmpty(err.response) && isString(err.response.data) && !isEmpty(err.response.data)) {
        msg = err.response.data;
      }
      if (!isEmpty(err.response) && isObject(err.response.data) && isString(err.response.data.msg)) {
        msg = err.response.data.msg;
      }
      showMessage(KEY.ERROR, msg);
    });
};
//delete template checklist
export const deleteTemplateChecklist = (id) => (dispatch) => {
  dispatch(loadingDesktop(true));
  API.deleteTemplate(id)
    .then((res) => {
      dispatch(loadingDesktop(false));
      if (res && res.status === 200) {
        Modal.success({
          centered: true,
          title: <span style={{ fontWeight: "bold" }}>{lang.SUCCESS}</span>,
          content: COMMON_CONSTANT.BOM_C004,
          okText: lang.OK,
        });
        dispatch(getTemplateChecklist());
      }
    })
    .catch((err) => {
      dispatch(loadingDesktop(false));
      let msg = MSG_CATCH();
      if (!isEmpty(err.response) && isString(err.response.data) && !isEmpty(err.response.data)) {
        msg = err.response.data;
      }
      if (!isEmpty(err.response) && isObject(err.response.data) && isString(err.response.data.msg)) {
        msg = err.response.data.msg;
      }
      showMessage(KEY.ERROR, msg);
    });
};
// get template checklist all or filter
export const getTemplateChecklist = (name, type) => (dispatch) => {
  dispatch(showLoadingTable(true));
  API.searchChecklistTemplate(name, type)
    .then((res) => {
      if (res && res.status === 200) {
        dispatch(updateTemplateChecklist(res.data));
        dispatch(showLoadingTable(false));
      }
    })
    .catch((error) => console.log(error));
};
