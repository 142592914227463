/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Checkbox,
  Dropdown,
  Typography,
  Menu,
  Modal,
} from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "@src/styles/icon/clone.svg";
import { COMMON_CONSTANT, lang, KEY } from "../../constants/common.const";
import BoxContent from "../../component/Desktop/content";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import { ReactComponent as EditIcon } from "@src/styles/icon/edit.svg";
import { ReactComponent as CloneIcon } from "@src/styles/icon/clone.svg";
import { ReactComponent as DownloadIcon } from "@src/styles/icon/download.svg";
import FooterPage from "../../component/footer/footerPage";
import { ReactComponent as ViewHistoryIcon } from "@src/styles/icon/view_history.svg";
import StatusConst from "../../constants/status.const";
import {
  MSG_CATCH,
  formatCheckItemCode,
  showMessage,
  statusRes,
} from "../../constants/utils";
import API from "../../api/backend/managementCL";
import KBN from "../../api/backend/kbn";
import { useHistory } from "react-router-dom";
import GridCheckList from "../../component/Desktop/gridView"; // Grid
import GridCheckListPreCheck from "../../component/Desktop/gridViewPreCheck"; // Grid
import queryString from "query-string";
import { useParams } from "react-router-dom";

import { RollbackOutlined } from "@ant-design/icons";
import TabCheckList from "../../component/Desktop/tabHistory"; // Tab
import TabCheckListPreCheck from "../../component/Desktop/tabHistoryPreCheck"; // Tab

import classDdl from "../../models/control/dropdownlist";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";
import classGroupPreCheck from "../../models/groupPreCheck";

let PARAMS;
let verSelect;
const { Paragraph } = Typography;

function ViewCL(props) {
  const history = useHistory();
  let { id } = useParams();
  const [initPage, setInitPage] = useState(true);
  const params = useParams();
  const [dataDetail, setDataDetail] = useState(null);
  const [lblChecklistName, setLblChecklistName] = useState("");
  const [lblIsPreUseChecklist, setLblIsPreUseChecklist] = useState("");
  // const [ddlVersion, setDdlVersion] = useState([]);
  const [ddlTemplate, setDdlTemplate] = useState("");
  const [ddlInspectionTiming, setDdlInspectionTiming] = useState("");
  const [remarkType, setRemarkType] = useState("");
  const [txtType, settxtType] = useState("");
  const [MULTIPLE_TIME, setMULTIPLE_TIME] = useState(false);
  const [LIST_INPUTCONTENTOPTION, setLIST_INPUTCONTENTOPTION] = useState(
    new classDdl()
  );
  const [listTimeCheck, setlistTimeCheck] = useState([]);

  // Group Tab
  const [GROUP_LIST, setGROUP_LIST] = useState([]);
  const [CURRENT_GROUP, setCURRENT_GROUP] = useState(null);
  const [showCheckList, setShowCheckList] = useState(false);

  // COLUMNS: START
  const columnFirst = [
    { title: "順", dataIndex: "itemOrder", width: 30 },
    { title: "点検項目", dataIndex: "checkItemName", width: 100 },
    { title: "初期タイプ", dataIndex: "defaultTypeValue", width: 100 },
    { title: "初期値", dataIndex: "defaultValue", width: 100 },
    {
      title: "累計計算項目",
      dataIndex: "accumulatedGroupItemCodes",
      width: 100,
      render: (txt) => {
        return (
          <div className="ant-table-selection">
            {txt &&
              txt.split(",").map((itemCode) => {
                return <Paragraph>{itemCode}</Paragraph>;
              })}
          </div>
        );
      },
    },
    { title: "入力値の分類", dataIndex: "accumulatedTypeValue", width: 100 },
    {
      title: "乗数",
      dataIndex: "multiplier",
      width: 50,
    },
    {
      title: "判断",
      width: 100,
      dataIndex: "itemJudgement",
      render: (txt, row) => {
        return <div className="content-center">{row.itemJudgementValue}</div>;
      },
    },
  ];
  const columnLast = [
    { title: "入力形式", dataIndex: "mode", key: "mode", width: 70 },
    { title: "運転めやす", key: "minMax", width: 70 },
    { title: "単位", dataIndex: "unit", key: "unit", width: 50 },
  ];
  const renderNodeCheckTime = listTimeCheck.map((v, i) => {
    return {
      title: <span className="fs12">{v}</span>,
      align: "center",
      width: 50,
      render: (text, row) => {
        const obj = {
          props: {},
        };
        if (Number(row.typeValue) === 2 && row.times) {
          // Multiple time
          const rowN = { ...row };
          obj.children = (
            <div className="content-center">
              <Checkbox
                disabled
                checked={rowN.times.split(",").indexOf(i + 1 + "") >= 0}
              ></Checkbox>
              {nodeBtnCopy(rowN, i + 1)}
            </div>
          );
        } else {
          // Only time
          if (i === 0) {
            // Check all
            obj.props.colSpan = listTimeCheck.length;
            obj.children = (
              <div className="d-inline-block">{nodeBtnCopy(row)}</div>
            );
          } else {
            // Other
            obj.props.colSpan = 0;
          }
        }
        return obj;
      },
    };
  });
  const renderColumn = (multiple = false) => {
    return multiple && renderNodeCheckTime.length > 0
      ? [
          ...columnFirst,
          // { title: '', dataIndex: 'type' }, // 点検区分
          {
            title: <>複数回の点 ({listTimeCheck.length})</>,
            children: renderNodeCheckTime,
          },
          ...columnLast,
        ]
      : [
          ...columnFirst,
          {
            title: "コピー",
            key: "copy",
            width: "5%",
            render: (txt, row) => {
              return <div className="content-center">{nodeBtnCopy(row)}</div>;
            },
          },
          ...columnLast,
        ];
  };

  // PRE_CHECK COLUMN
  const columnPreCheck = [
    { title: "順", dataIndex: "itemOrder", width: 30 },
    { title: "点検項目名", dataIndex: "checkItemName", width: 110 },
    {
      title: (
        <span>
          運転めやす <br />
          (下限位)
        </span>
      ),
      width: 100,
      dataIndex: "normalLowerControlValue",
    },
    {
      title: (
        <span>
          運転めやす <br />
          (上限値)
        </span>
      ),
      width: 100,
      dataIndex: "normalUpperControlValue",
    },
    {
      title: "入力形式",
      width: 100,
      dataIndex: "modeValue",
    },

    {
      title: "单位",
      width: 50,
      dataIndex: "unit",
    },

    {
      title: "選択",
      width: 50,
      dataIndex: "choices",
    },
    {
      title: "点検頻度",
      width: 100,
      dataIndex: "inspectionTimingValue",
    },
    {
      title: "判断",
      width: 100,
      dataIndex: "itemJudgementValue",
    },

    { title: "初期タイプ", dataIndex: "defaultTypeValue", width: 100 },
    { title: "初期値", dataIndex: "defaultValue", width: 100 },
    // {
    //   title: "累計計算項目",
    //   dataIndex: "accumulatedGroupItemCodes",
    //   width: 110,
    //   render: (txt) => {
    //     return (
    //       <div className="ant-table-selection">
    //         {txt &&
    //           txt.split(",").map((itemCode) => {
    //             return <Paragraph>{itemCode}</Paragraph>;
    //           })}
    //       </div>
    //     );
    //   },
    // },
    // { title: "入力値の分類", dataIndex: "accumulatedTypeValue", width: 100 },
    // {
    //   title: "乗数",
    //   dataIndex: "multiplier",
    //   width: 50,
    // },
  ];

  const renderColumnPreCheck = (multiple = false) => {
    return multiple && renderNodeCheckTime.length > 0
      ? [
          ...columnPreCheck,
          // { title: '', dataIndex: 'type' }, // 点検区分
          {
            title: <>複数回の点 ({listTimeCheck.length})</>,
            children: renderNodeCheckTime,
          },
        ]
      : [
          ...columnPreCheck,
          {
            title: "コピー",
            key: "copy",
            width: "5%",
            render: (txt, row) => {
              return <div className="content-center">{nodeBtnCopy(row)}</div>;
            },
          },
        ];
  };

  // COLUMNS: END

  const nodeBtnCopy = (row, order) => (
    <CopyToClipboard
      text={formatCheckItemCode(row.CheckItemCode || row.checkItemCode, order)}
    >
      <Button type="link" className="btnLink btnLink-active pr2 pl2 mb2">
        <CopyIcon className="w18px" />
      </Button>
    </CopyToClipboard>
  );

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  };

  // Init
  const init = (ver) => {
    openLoadingSpinner();

    // Load pulldown
    Promise.all([
      API.getCheckList({
        id: id,
        version: ver ? ver : null,
        // status: 0,
      }),
      KBN.getInputContentOptions(),
    ])
      .then((res) => {
        let ipContent = res[1] ? res[1].data.data : [];
        setLIST_INPUTCONTENTOPTION({
          ...LIST_INPUTCONTENTOPTION,
          options: ipContent,
        });

        // Detail
        let data = res[0] ? res[0].data.data : {};
        setDataDetail(data);
        setLblChecklistName(data.checklistName);
        setLblIsPreUseChecklist(
          data.isPreUseChecklist
            ? data.isPreUseChecklist
            : KEY.TYPE_DEFAULT_CHECKLIST
        );
        setDdlTemplate(data.templateTitle);
        settxtType(data.type);
        setRemarkType(
          Number(data.remarkType) === 1 ? lang.MACHINE_ONLY : lang.REMARK_ITEM
        );
        setDdlInspectionTiming(data.checkTiming);

        verSelect = data.version; // Select option now
        setMULTIPLE_TIME(Number(data.typeValue) === 2);
        data.times && setlistTimeCheck(data.times.split(","));

        if (data.isPreUseChecklist == KEY.TYPE_PRE_USE_CHECKLIST) {
          // Group Mapping
          data.groups = data.groups.map((grp) => {
            let group = new classGroupPreCheck();
            group.ID = grp["id"];
            group.Name = grp["groupName"];
            group.Items = grp["itemPreChecks"];

            return group;
          });
        } else {
          // Group Mapping
          data.groups = data.groups.map((grp) => {
            let group = new classGroup();
            group.ID = grp["id"];
            group.Name = grp["groupName"];

            // Machine
            group.Machines = grp["machines"].map((mc) => {
              let machine = new classMachine();
              machine = mc;
              machine.ID = mc["id"];
              machine.Name = mc["machineforSiteOfficeName"];

              machine.Items = [];
              mc["items"]
                .filter((v) => Number(v.isChecked) === 1)
                .map((v, i) => {
                  v.itemOrder = i + 1;
                  return v;
                })
                .forEach((v) => {
                  if (
                    machine.Items.some(
                      (x) => x.checkItemCode == v.checkItemCode
                    )
                  )
                    return;
                  machine.Items.push(v);
                });

              return machine;
            });

            return group;
          });
        }

        setGROUP_LIST(data.groups);
        setCURRENT_GROUP(data.groups[0].ID + "0");
        setInitPage(false);
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH(), toSearch);
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    PARAMS = queryString.parse(props.location.search);
    init(PARAMS.version);
  }, [params]);

  const toParent = () => {
    history.goBack();
  };

  const toSearch = () => {
    history.push("/ChecklistManagementv2");
  };

  const actionForVer = (view = true) => {
    // List version
    const listVer = [];
    const toPage = view ? "/ViewChecklistv2" : "/EditChecklistv2";
    for (let i = dataDetail.versionMax; i > 0; i--) {
      listVer.push(
        <Menu.Item
          key={i}
          disabled={dataDetail.versions.indexOf(i) === -1}
          onClick={() => {
            history.push(`${toPage}/${id}?version=${i}`);
          }}
        >
          {lang.VERSION}
          {i}
        </Menu.Item>
      );
    }
    return listVer;
  };

  const onDeleteHistory = () => {
    // OK - Delete CL
    const onOK = async () => {
      openLoadingSpinner();
      // API Del
      try {
        const res = await API.delete(id, dataDetail.version);
        closeLoadingSpinner();

        if (statusRes(res)) {
          // Open success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004, toSearch);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      } catch (err) {
        closeLoadingSpinner();
        showMessage(
          KEY.ERROR,
          (err.response && err.response.data.msg) || MSG_CATCH()
        );
      }
    };

    // Show popup
    Modal.confirm({
      centered: true,
      title: lang.WARMING,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.DELETE,
      cancelText: lang.CANCEL,
      onOk: onOK,
    });
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      {!initPage && (
        <>
          <TitlePage name={lang.INFO_CHECKLIST} />
          {/* Group 1 */}
          <BoxContent>
            <h1 className="white-space">
              {lblChecklistName} {lang.VERSION}
              {verSelect}
            </h1>

            <Row className="" gutter={[16, 16]}>
              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">{lang.NEWEST_VERSION}</span>
                    {lang.COLON}
                  </Col>
                  <Col>
                    {lang.VERSION}
                    {dataDetail.versionMax}
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">{lang.STATUS}</span>
                    {lang.COLON}
                  </Col>
                  <Col>
                    <Paragraph
                      ellipsis={{ rows: 1 }}
                      className="textStatus"
                      style={{
                        color:
                          StatusConst.STATUS_CL_MANAGEMENT[
                            dataDetail.statusValue
                          ],
                        background:
                          StatusConst.STATUS_CL_MANAGEMENT[
                            dataDetail.statusValue
                          ] + "1A",
                      }}
                    >
                      <b>{dataDetail.status}</b>
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">出力テンプレート</span>
                    {lang.COLON}
                  </Col>
                  <Col>{ddlTemplate}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">点検頻度</span>
                    {lang.COLON}
                  </Col>
                  <Col>{ddlInspectionTiming}</Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">{lang.CHECKLIST_TYPE}</span>
                    {lang.COLON}
                  </Col>
                  <Col>
                    {StatusConst.TYPE_CHECKLIST_STATUS[lblIsPreUseChecklist]}
                  </Col>
                </Row>
              </Col>

              {lblIsPreUseChecklist !== KEY.TYPE_PRE_USE_CHECKLIST && (
                <>
                  <Col span={24}>
                    <Row>
                      <Col lg={4} xs={10}>
                        <span className="bold">{lang.REMARK_TYPE}</span>
                        {lang.COLON}
                      </Col>
                      <Col>{remarkType}</Col>
                    </Row>
                  </Col>
                </>
              )}

              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">点検区分</span>
                    {lang.COLON}
                  </Col>
                  <Col>{txtType}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col lg={4} xs={10}>
                    <span className="bold">画面</span>
                    {lang.COLON}
                  </Col>
                  <Col>{dataDetail.isDisplay ? lang.YES : lang.NO}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Button
                  type="link"
                  className="btnLink btnLink-active"
                  onClick={() => {
                    window.location.href = dataDetail.templateUrl;
                  }}
                >
                  <DownloadIcon className="w18px svg-default mr5" />
                  {dataDetail.templateFileName}
                </Button>
              </Col>
            </Row>
            <Row justify="space-between" className="mb15" gutter={[16, 16]}>
              <Col
                style={{
                  display: "inline-flex",
                }}
              >
                <Dropdown
                  overlay={<Menu>{actionForVer()}</Menu>}
                  placement="bottomLeft"
                  arrow
                >
                  <Button
                    className="buttonPC button--outline --todo-- wAuto content-center"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <ViewHistoryIcon className="w18px svg-default mr5" />
                    {lang.VIEW}
                  </Button>
                </Dropdown>

                <Button
                  className="buttonPC button--outline --todo-- wAuto ml15 content-center"
                  onClick={(e) => {
                    history.push(
                      `/EditChecklistv2/${id}?version=${dataDetail.version}`
                    );
                    e.preventDefault();
                  }}
                >
                  <EditIcon className="w18px svg-default mr5" />
                  {dataDetail.isDraft ? lang.EDIT : lang.VERSION_UP}{" "}
                  {lang.VERSION}
                  {dataDetail.version}
                </Button>
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml15 content-center"
                  onClick={() => {
                    history.push(
                      `/CloneChecklistv2/${id}?version=${verSelect}`
                    );
                  }}
                >
                  <CloneIcon className="w18px svg-default mr5" />
                  {lang.CLONE} {lang.VERSION}
                  {dataDetail.version}
                </Button>
              </Col>
              <Col>
                <Button
                  type="link"
                  className="buttonPC button--error--outline"
                  onClick={(e) => {
                    if (dataDetail.haveCheckResult) {
                      // Exist input CL
                      showMessage(KEY.ERROR, COMMON_CONSTANT.BOM_C035);
                    } else {
                      onDeleteHistory();
                    }
                    e.preventDefault();
                  }}
                >
                  <DeleteIcon className="w18px svg-danger mr5" />
                  {lang.DELETE} {lang.VERSION}
                  {dataDetail.version}
                </Button>
              </Col>
            </Row>

            {lblIsPreUseChecklist === KEY.TYPE_PRE_USE_CHECKLIST ? (
              <>
                <TabCheckListPreCheck
                  panes={GROUP_LIST}
                  activeKey={CURRENT_GROUP}
                  setActiveKey={setCURRENT_GROUP}
                  view={true}
                  body={(group = new classGroupPreCheck()) => {
                    /// Body TAB
                    return (
                      <div key={group.ID}>
                        {/* Table Item Machine */}
                        <GridCheckListPreCheck
                          columns={
                            MULTIPLE_TIME
                              ? renderColumnPreCheck(true)
                              : renderColumnPreCheck(false)
                          }
                          ddlInputContentOption={LIST_INPUTCONTENTOPTION}
                          rowKey="id"
                          dataSource={group.Items}
                          scroll={{ x: "auto", y: "auto" }}
                        />
                      </div>
                    );
                  }}
                ></TabCheckListPreCheck>
              </>
            ) : (
              <>
                <TabCheckList
                  panes={GROUP_LIST}
                  activeKey={CURRENT_GROUP}
                  setActiveKey={setCURRENT_GROUP}
                  view={true}
                  body={(machine = new classMachine()) => {
                    /// Body TAB
                    return (
                      <div key={machine.ID}>
                        {/* Location Info */}
                        <Row className="mt15 mb15 ml15 mr15 checkitem-header">
                          <Col
                            span={24}
                            className="checkitem-header-title fs16 bold"
                          >
                            {machine.Name}
                          </Col>
                          <Col span={24} className="mt5 info-more">
                            <span className="mr25">
                              <span className="--bold">{lang.FACILITY}</span>
                              {lang.COLON}
                              {machine.facilityName}
                            </span>
                            <span className="mr25">
                              <span className="--bold">{lang.BUILDING}</span>
                              {lang.COLON}
                              {machine.buildingName}
                            </span>
                            <span className="mr25">
                              <span className="--bold">{lang.FLOOR}</span>
                              {lang.COLON}
                              {machine.floorName}
                            </span>
                            <span className="mr25">
                              <span className="--bold">{lang.ROOM}</span>
                              {lang.COLON}
                              {machine.roomName}
                            </span>
                            <span className="mr25">
                              <span className="--bold">{lang.PROCESS}</span>
                              {lang.COLON}
                              {machine.processName}
                            </span>
                          </Col>
                          {/* showLocation(e) */}
                        </Row>
                        {/* Table Item Machine */}
                        <GridCheckList
                          columns={
                            MULTIPLE_TIME
                              ? renderColumn(true)
                              : renderColumn(false)
                          }
                          ddlInputContentOption={LIST_INPUTCONTENTOPTION}
                          rowKey="id"
                          dataSource={machine.Items}
                        />
                      </div>
                    );
                  }}
                ></TabCheckList>
              </>
            )}
          </BoxContent>
        </>
      )}

      <FooterPage>
        <Row justify="end">
          <Col>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15"
              onClick={toParent}
            >
              <RollbackOutlined />
              {COMMON_CONSTANT.BACK}
            </Button>
          </Col>
        </Row>
      </FooterPage>
    </div>
  );
}

export default ViewCL;
