import { Image, Tooltip, Button } from "antd";
import React from "react";
import { useState } from "react";
import "./user.scss";
import { UploadOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { lang, SIZE_IMAGE } from "../../constants/common.const";
function ShowImage({ imageUrl, uploadFileImage, deleteImage }) {
  const [previewImage, setPreviewImage] = useState(false);
  return (
    <div className="wrap-image">
      <Image
        preview={{
          visible: previewImage,
          onVisibleChange: (visible) => setPreviewImage(visible),
        }}
        width={SIZE_IMAGE}
        height={SIZE_IMAGE}
        src={imageUrl}
        style={{ borderRadius: 5, height: SIZE_IMAGE }}
      />

      <div className="wrap-action">
        <Tooltip title="機器写真">
          <Button
            style={{ marginTop: 0, width: 30 }}
            className="button-action"
            type="primary"
            onClick={() => uploadFileImage()}
          >
            <UploadOutlined />
          </Button>
        </Tooltip>
        <Tooltip title={lang.PREVIEW}>
          <Button
            onClick={() => setPreviewImage(!previewImage)}
            className="button-action"
            type="default"
            placeholder=""
            style={{ background: "gray" }}
          >
            <EyeOutlined className="icon-action" />
          </Button>
        </Tooltip>
        <Tooltip title={lang.DELETE}>
          <Button
            onClick={() => deleteImage()}
            className="button-action"
            type="danger"
          >
            <DeleteOutlined className="icon-action" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

export default ShowImage;
