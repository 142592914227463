import Api from "@src/api";

export default {
  //search machine
  searchMachine(params) {
    return Api().get("MachineForSiteOffice/SearchMachine", { params });
  },
  deleteMachine(params) {
    return Api().delete("MachineForSiteOffice/Delete", { params })
  },
  save(formData) {
    return Api().post(`MachineForSiteOffice/CreateMachine`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  saveMulti(formData) {
    return Api().post(`MachineForSiteOffice/CreateMultiMachine`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  update(formData) {
    return Api().put(`MachineForSiteOffice/Update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  uploadFile(formData) {
    return Api().post(`MachineForSiteOffice/UploadFileAttachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  downloadTemplateMachineMaintenance() {
    return Api().get(`MachineForSiteOffice/DownloadTemplateMaintenanceHistory`, {});
  },
  downloadTemplateImportMachine() {
    return Api().get(`MachineForSiteOffice/DownloadTemplateImportMachine`, {});
  },
  getMachine(code, version = null) {
    if (version) {
      return Api().get(`MachineForSiteOffice/GetMachineByVersion`, {
        params: {
          MachineForSiteOfficeCode: code,
          RecordVersion: version
        }
      });
    } else {
      return Api().get(`MachineForSiteOffice/GetMachineByCode?code=${code}`);
    }
  },
  getFacility(params) {
    return Api().get("/Facility", { params });
  },
  getBuilding(params) {
    return Api().get("/Building", { params });
  },
  getFloor(params) {
    return Api().get("/Floor", { params });
  },
  getRooms(params) {
    return Api().get("/Room", { params });
  },
  getProcess(params) {
    return Api().get("/Process", { params });
  },
  maintainanceHistory(params) {
    return Api().get("/MachineForSiteOffice/MaintainanceHistory", { params });
  },
  addMaintainanceHistory(params) {
    return Api().put(`/MachineForSiteOffice/MaintainanceHistory/${params.machineCode}`);
  },
  deleteMaintainanceHistory(params) {
    return Api().delete(`/MachineForSiteOffice/MaintainanceHistory/${params.machineCode}`);
  },
  getClassification(params) {
    return Api().get("/MachineForSiteOffice/MaintainanceClassification", { params });
  },
  updateClassification(params) {
    return Api().post(`/MachineForSiteOffice/UpdateMachineMaintenanceClassification`, params);
  },
};
