/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Form,
  DatePicker,
  Select,
  Layout,
  Modal,
  Typography,
  Spin,
  Tooltip,
  Checkbox,
  Empty,
  Input,
  Tabs,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import LoadingInline from "../../component/Popup/loading-inline";

import { lang, COMMON_CONSTANT, KEY } from "../../constants/common.const";
import StatusConst from "../../constants/status.const";
import { useMediaQuery } from "react-responsive";
import TitlePage from "../../component/Desktop/titlePage";
import AutoMail from "../../component/Desktop/autoMail";
// import { ReactComponent as ConfirmIcon } from "@src/styles/icon/comfirm.svg";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import { Link } from "react-router-dom";
import API from "../../api/backend/historyCL";
import {
  MSG_CATCH,
  statusRes,
  INPUT_DATE,
  renderOption,
  enterSearch,
  showMessage,
  getQuerySearch,
  int2DateString,
  getUserValue,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  pushObject2QueryReplace,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { objectEach } from "highcharts";
import rolesConst from "../../routers/roles/roles.const";

const { Content } = Layout;
const objColorStatus = {};
const { Paragraph } = Typography;
const objClear = { value: null, disabled: true };
let F5 = true;
let objSearchOrigin = {
  checklistName: null,
  inspectors: null,
  status: null,
  startDate: null,
  endDate: null,
};
let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function HistoryCL_List({
  screen = true,
  setDataSelected,
  idSelect,
  setIdSelect,
  history: historyPage,
  isEndUser,
  ...other
}) {
  const { selectedFactory } = useSelector((state) => state.auth);
  // Step1: Update param
  if ((historyPage && historyPage.action === "POP") || isEndUser) {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [stateData, setStateData] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const [stateDataCLDefault, setStateDataCLDefault] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const [stateDataCLPreCheck, setStateDataCLPreCheck] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  // Loading
  const [showLoading, setShowLoading] = useState(true);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const location = useLocation();
  const { roles } = useSelector((state) => state.auth);
  const isLead =
    roles === rolesConst.HEAD_OFFICE_ADMIN || roles === rolesConst.OFFICE_ADMIN;

  // useEffect(() => {
  // });
  // Create object status color
  StatusConst.STATUS_LIST.forEach((v) => {
    objColorStatus[v.key] = v.color;
    objColorStatus[v.value] = v.color;
  });

  const [formSearch] = Form.useForm();
  //  Grid
  const [initPage, setInitPage] = useState(true);
  const [grid, setGrid] = useState(new classGrid());
  const [gridCLDefault, setGridCLDefault] = useState(new classGrid());
  const [gridCLPreCheck, setGridCLPreCheck] = useState(new classGrid());

  // Input search
  const [ddlChecklistName, setDdlChecklistName] = useState(new classDdl()); // Hidden
  const [txtChecklistName, setTxtChecklistName] = useState({
    ...new classText(),
    value: getTxtValue(objSearch["checklistName"]),
  });
  const [ddlStatus, setDdlStatus] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch["status"]),
  });
  const [txtInspector, setTxtInspector] = useState({
    ...new classText(),
    users: getUserValue(objSearch["inspectors"]),
  });
  const [txtCheckStartDate, setTxtCheckStartDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch["startDate"]),
  });
  const [txtCheckEndDate, setTxtCheckEndDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch["endDate"]),
  });

  const queryEndUser = isEndUser ? "?isEndUser=true" : "";
  // Step2: Sync input <-> urls
  const syncInput = () => {
    objSearch.checklistName = txtChecklistName.value
      ? txtChecklistName.value.trim()
      : null;
    objSearch.status = ddlStatus.value;
    objSearch.inspectors = txtInspector.users
      .filter((v) => {
        let flg = true;
        txtInspector.list.map((o) => {
          if (o.value === v) {
            flg = !o.error;
          }
          return o;
        });
        return flg;
      })
      .toString();
    objSearch.startDate = txtCheckStartDate.value;
    objSearch.endDate = txtCheckEndDate.value;

    // Push to url
    pushObject2QueryReplace(objSearch);
  };

  // Search
  const search_onClick = (init, offset = 0) => {
    // Loading
    if (offset === 0) {
      openLoadingSpinner(); //Only init
    }
    setStateData({ ...stateData, loading: true });
    setStateDataCLDefault({ ...stateDataCLDefault, loading: true });
    setStateDataCLPreCheck({ ...stateDataCLPreCheck, loading: true });

    // input to objectSearch; push url
    syncInput();

    // Update format
    if (!isEndUser) objSearch = getQuerySearch();
    objSearch.startDate = int2DateString(Number(objSearch.startDate));
    objSearch.endDate = int2DateString(Number(objSearch.endDate));
    if (location.pathname === "/EndUser" && isLead) {
      objSearch.endUserMode = true;
    }

    Promise.all([
      API.searchHistory({
        params: {
          ...objSearch,
          isPreUseChecklist: "",
          limit: 20,
          offset: offset,
        },
      }),
      API.searchHistory({
        params: {
          ...objSearch,
          isPreUseChecklist: "0",
          limit: 20,
          offset: offset,
        },
      }),
      API.searchHistory({
        params: {
          ...objSearch,
          isPreUseChecklist: "1",
          limit: 20,
          offset: offset,
        },
      }),
    ])
      .then((res) => {
        let resAll = res[0];
        let resCLDefault = res[1];
        let resCLPreCheck = res[2];

        if (offset !== 0) {
          // Load more
          setGrid({
            ...grid,
            data: [...grid.data, ...resAll.data.data],
          });
          setGridCLDefault({
            ...gridCLDefault,
            data: [...gridCLDefault.data, ...resCLDefault.data.data],
          });
          setGridCLPreCheck({
            ...gridCLPreCheck,
            data: [...gridCLPreCheck.data, ...resCLPreCheck.data.data],
          });
        } else {
          // Load init
          setGrid(resAll.data);
          setGridCLDefault(resCLDefault.data);
          setGridCLPreCheck(resCLPreCheck.data);
        }
      })
      .catch((error) => {
        init !== true && showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        setTimeout(closeLoadingSpinner, 200);
      });
  };

  // clearInputSearch
  const clearInputSearch = () => {
    setDdlChecklistName({ ...ddlChecklistName, ...objClear });
    setTxtChecklistName({ ...txtChecklistName, ...objClear });
    setTxtInspector(new classText());
    setDdlStatus({ ...ddlStatus, ...objClear });
    setTxtCheckStartDate(objClear);
    setTxtCheckEndDate(objClear);
    pushObject2QueryReplace(objSearchOrigin);
  };

  const onDelete = (rowData) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        await API.deleteHistory(rowData.id);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    // Show popup
    Modal.confirm({
      centered: true,
      title: lang.WARMING,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.DELETE,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  const onSelect = (checked, index) => {
    setIdSelect(checked ? grid.data[index].id : null);
    setDataSelected(checked ? grid.data[index] : null);
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Init
  useEffect(() => {
    // Open loading
    screen && openLoadingSpinner();

    let checklist,
      status = [];
    // Load pulldown
    Promise.all(
      [API.getDdlChecklist(), API.getDdlStatus()],
      search_onClick(true)
    )
      .then((res) => {
        checklist = res[0] ? res[0].data.data : [];
        status = res[1] ? res[1].data.data : [];
        if (isEndUser) {
          status = status.filter(
            (item) => item.key === "0" || item.key === "6"
          );
        }
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Set pulldown
        setDdlChecklistName({ ...ddlChecklistName, options: checklist });
        setDdlStatus({ ...ddlStatus, options: status });
        // Close loading
        screen && closeLoadingSpinner();
      });

    F5 = false;
  }, [selectedFactory]);

  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick();
  }, [txtChecklistName.value]);

  // Alway device
  useEffect(() => {
    !initPage && search_onClick();
  }, [
    txtCheckEndDate.value,
    txtCheckStartDate.value,
    ddlStatus.value,
    txtInspector.list,
  ]);

  // Update offset
  useEffect(() => {
    setStateData({
      ...stateData,
      offset: grid.data.length,
      hasMore: grid.data.length !== grid.count,
      loading: false,
    });
  }, [grid.data]);
  useEffect(() => {
    setStateDataCLDefault({
      ...stateDataCLDefault,
      offset: gridCLDefault.data.length,
      hasMore: gridCLDefault.data.length !== gridCLDefault.count,
      loading: false,
    });
  }, [gridCLDefault.data]);
  useEffect(() => {
    setStateDataCLPreCheck({
      ...stateDataCLPreCheck,
      offset: gridCLPreCheck.data.length,
      hasMore: gridCLPreCheck.data.length !== gridCLPreCheck.count,
      loading: false,
    });
  }, [gridCLPreCheck.data]);

  // Render
  function renderCheckListItem(gridData) {
    return (
      <>
        <Row gutter={[20, 20]} style={{ minHeight: "200px" }}>
          {gridData.data.length > 0 ? (
            <>
              {gridData.data.map((v, key) => {
                v.isPreUseChecklist = v.isPreUseChecklist
                  ? v.isPreUseChecklist
                  : KEY.TYPE_DEFAULT_CHECKLIST;
                return (
                  <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={key}>
                    <Link
                      to={
                        screen ? "/history-detail/" + v.id + queryEndUser : "#"
                      }
                    >
                      <div
                        className="itemList-2 custom-height-clType"
                        onClick={() => {
                          !screen && onSelect(!v.checked, key);
                        }}
                      >
                        <Row justify="space-between" className="align-center">
                          <Col className="id">ID: {v.id}</Col>
                          {screen && !isEndUser ? (
                            <Col className="icon">
                              {v.status !== "承認済" && (
                                <Button
                                  type="link"
                                  className="iconAction-delete btnLink"
                                  onClick={(e) => {
                                    onDelete(v);
                                    e.preventDefault();
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              )}
                            </Col>
                          ) : !isEndUser ? (
                            <Col className="created">
                              <Checkbox
                                checked={v.id === idSelect}
                                onChange={(e) => {
                                  onSelect(e.target.checked, key);
                                }}
                              ></Checkbox>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                        <div className="title">
                          <Paragraph ellipsis={{ rows: 2 }}>
                            <Tooltip title={v.checklistName}>
                              {v.checklistName}
                            </Tooltip>
                          </Paragraph>
                        </div>

                        <Row className="rowNotMargin">
                          <Col>
                            <div
                              style={{
                                color:
                                  StatusConst.TYPE_CHECKLIST_COLOR[
                                    v.isPreUseChecklist
                                  ],
                              }}
                            >
                              {
                                StatusConst.TYPE_CHECKLIST_STATUS[
                                  v.isPreUseChecklist
                                ]
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row className="rowNotMargin">
                          <Col className="account">{v.inspectorName}</Col>
                          <Col flex="auto"></Col>
                          {v.approverName && (
                            <Col className="account">
                              {lang.APPROVER} : {v.approverName}
                            </Col>
                          )}
                        </Row>
                        <Row justify="space-between" className="more">
                          <Col>{v.checkDate}</Col>
                          <Col>
                            {/* {v.status} */}

                            <Paragraph
                              ellipsis={{ rows: 1 }}
                              className="textStatus"
                              style={{
                                color: objColorStatus[v.status],
                                background: objColorStatus[v.status] + "1A",
                              }}
                            >
                              {v.status}
                            </Paragraph>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                  </Col>
                );
              })}
              <Col span={24} className="content-center">
                <LoadingInline isLoadingInline={stateData.loading} />
              </Col>
            </>
          ) : (
            !initPage && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w100" />
            )
          )}
        </Row>
      </>
    );
  }
  return (
    <div className={screen ? "des-content" : ""}>
      {screen && <TitlePage name={lang.HISTORY_CHECKLIST} />}
      {/* Group input */}
      <Content
        style={
          screen
            ? {
                margin: "24px 16px",
                padding: 0,
              }
            : {
                margin: "16px",
                padding: 0,
              }
        }
      >
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
        >
          <Row gutter={[8, isPC ? 0 : 16]}>
            <Col span={isPC ? 5 : 24}>
              <Form.Item
                name="ddlChecklistName"
                hidden={true}
                label=""
                className="mb0"
              >
                <Select
                  value={ddlChecklistName.value}
                  placeholder="点検表名"
                  onChange={(v) => {
                    setDdlChecklistName({
                      ...ddlChecklistName,
                      value: v,
                    });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlChecklistName.options)}
                </Select>
              </Form.Item>

              <Input
                allowClear
                value={txtChecklistName.value}
                placeholder="点検表名"
                maxLength={255}
                onChange={(e) => {
                  setTxtChecklistName({
                    ...txtChecklistName,
                    value: e.target.value,
                  });
                }}
                {...enterSearch(search_onClick)}
              />
            </Col>
            {isPC && (
              <Col span="8">
                <Button
                  className="buttonPC button--info wAuto"
                  onClick={search_onClick}
                >
                  <SearchOutlined className="" />
                  {lang.SEARCH}
                </Button>
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml10"
                  onClick={clearInputSearch}
                >
                  <ClearOutlined />
                  {lang.CLEAR}
                </Button>
              </Col>
            )}
          </Row>
          {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
          <Row gutter={[8, isPC ? 0 : 8]} className="align-center">
            {isPC && (
              <Col className="bold mr10">
                {lang.FILTER}
                {lang.COLON}
              </Col>
            )}
            <Col span={isPC ? 5 : 12}>
              <Select
                className="w100"
                placeholder={lang.STATUS}
                value={ddlStatus.value}
                onChange={(v) => {
                  setDdlStatus({ ...ddlStatus, value: v });
                }}
                {...enterSearch(search_onClick)}
              >
                {renderOption(ddlStatus.options)}
              </Select>
            </Col>
            <Col span={isPC ? 5 : 12}>
              {/* 2 auto picker */}

              <AutoMail
                get={txtInspector}
                set={setTxtInspector}
                placeholder="点検者"
              ></AutoMail>
            </Col>
            <Col span={isPC ? 5 : 12}>
              {/* 4 */}
              <DatePicker
                format={KEY.DATE_DEFAULT}
                value={txtCheckStartDate.value}
                placeholder={"点検日付から"}
                className="w100"
                disabledDate={(currentDate) => {
                  return txtCheckEndDate.value && (txtCheckEndDate.value.valueOf() < currentDate.valueOf());
                }}
                onChange={(v, dateString) => {
                  setTxtCheckStartDate({
                    ...txtCheckStartDate,
                    value: v,
                  });
                }}
                {...INPUT_DATE}
              />
            </Col>
            <Col span={isPC ? 5 : 12}>
              {/* 4 */}
              <DatePicker
                format={KEY.DATE_DEFAULT}
                value={txtCheckEndDate.value}
                placeholder={"点検日付に"}
                disabledDate={(currentDate) => {
                  return txtCheckStartDate.value && (txtCheckStartDate.value.valueOf() > currentDate.valueOf());
                }}
                className="w100"
                onChange={(v, dateString) => {
                  setTxtCheckEndDate({ ...txtCheckEndDate, value: v });
                }}
                {...INPUT_DATE}
              />
            </Col>
          </Row>
        </Form>
      </Content>
      {/* Table data */}
      <Content
        className={isPC ? "site-layout-background" : ""}
        style={
          screen
            ? {
                margin: "24px 16px",
                padding: isPC ? 24 : 0,
              }
            : { margin: "0 16px" }
        }
      >
        {/* <div className="titleText2">
          <ConfirmIcon className="svg-default" /> {lang.HISTORY_CHECKLIST}
        </div> */}

        <div className={"tabsResponsive " + (!isPC && "tabsResponsive_mobile")}>
          <Tabs type={"card"} defaultActiveKey={0}>
            <Tabs.TabPane tab={lang.ALL_CHECKLIST} key={0} closable={false}>
              <Spin spinning={showLoading}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => {
                    search_onClick("", stateData.offset);
                  }}
                  hasMore={!stateData.loading && stateData.hasMore}
                  useWindow={true}
                >
                  {renderCheckListItem(grid)}
                </InfiniteScroll>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={lang.PATROL_INSPECTION_CHECKLIST}
              key={1}
              closable={false}
            >
              <Spin spinning={showLoading}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => {
                    search_onClick(gridCLDefault.offset, "0");
                  }}
                  hasMore={!gridCLDefault.loading && gridCLDefault.hasMore}
                  useWindow={true}
                >
                  {renderCheckListItem(gridCLDefault)}
                </InfiniteScroll>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane tab={lang.PRE_USE_CHECKLIST} key={2} closable={false}>
              <Spin spinning={showLoading}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => {
                    search_onClick(stateDataCLPreCheck.offset, "1");
                  }}
                  hasMore={
                    !stateDataCLPreCheck.loading && stateDataCLPreCheck.hasMore
                  }
                  useWindow={true}
                >
                  {renderCheckListItem(gridCLPreCheck)}
                </InfiniteScroll>
              </Spin>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  );
}
export default HistoryCL_List;
