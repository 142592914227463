import Api from "@src/api";

export default {
  // SCREEN: SEARCH
  getData(data) {
    return Api().get("Checklists?isDisplay=1", data);
  },
  updateVisible(data) {
    return Api().post("Checklists/UpdateVisible", data);
  },
  delete(id, verison) {
    return Api().delete(
      verison ? `Checklists/${id}/${verison}` : `Checklists/${id}`
    );
  },
  // DDL
  getTiming(data) {
    return Api().get(`kbn/inspectionTiming`);
  },
  getChecklistTemplate(data) {
    return Api().get(`kbn/checklistTemplateList`);
  },

  // ------SCREEN: ADD
  // no.1
  getFacility(data) {
    return Api().get(`Facility`);
  },
  // no.2
  getBuilding(facilityId) {
    return Api().get(`Building?facilityCode=${facilityId}`);
  },
  // no.3
  getFloor(BuildingId) {
    return Api().get(`Floor?buildingCode=${BuildingId}`);
  },
  // no.4
  getRoom(FloorId) {
    return Api().get(`Room?floorCode=${FloorId}`);
  },
  // no.5
  getProcess(facilityId) {
    return Api().get(`Process?facilityCode=${facilityId}`);
  },
  // Search machine
  getMachine(data) {
    return Api().get(`MachineForSiteOffice`, { params: data });
  },

  // ------SCREEN: VIEW
  // Get checklist
  getCheckList(data) {
    return Api().get(`Checklists/detail/${data.id}`, { params: data });
  },
  // Save
  save(formData, id, clone) {
    //
    if (id && !clone) {
      return Api().put(`Checklists/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }

    return Api().post(`Checklists`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
