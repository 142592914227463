import Api from "@src/api";
export default {
  exportMachineData(data) {
    let endpoints = "ImportExport/Export";
    return Api().post(endpoints, data, { responseType: "blob" });
  },
  importData(data) {
    let endpoints = "ImportExport/ImportMachineAsync/start";
    return Api().post(endpoints, data);
  },
  importChecklist(data) {
    let endpoints = "ImportExport/ImportChecklistAsync/start";
    return Api().post(endpoints, data);
  },
  exportChecklist(data) {
    let endpoints = "ImportExport/ExportChecklist";
    return Api().post(endpoints, data, { responseType: "blob" });
  },
  importInventory(data) {
    let endpoints = "Inventory/ImportMaster/start";
    return Api().post(endpoints, data);
  },
  exportInventory() {
    let endpoints = "Inventory/DownloadTemplateImportMaster";
    return Api().get(endpoints);
  },
  checkStatus(id, isFirstCall) {
    let endpoints = "ImportExport/ImportAsync/status/" + id;
	if (isFirstCall) {
		Api(1000).get(endpoints);
	} else {
		return Api().get(endpoints);
	}
  },
};
