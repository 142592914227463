import { Col, Tabs, Row, Empty, Select } from "antd";
import React, { useState } from "react";
import GridCheckList2 from "./gridAdd"; // Grid
import { lang } from "../../constants/common.const";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import { ReactComponent as EditIcon } from "@src/styles/icon/edit.svg";
import TabsCustom from "@src/component/TabsComponent/TabsCustom";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";
import classItem from "../../models/itemMachine";

const { TabPane } = Tabs;
const position = {
  TOP: "TOP",
  LEFT: "LEFT",
  ITEM: "ITEM",
};
const { Option } = Select;

function TabCheckList({
  panes,
  setPanes,
  activeKey,
  setActiveKey,
  onAddTab,
  onUpdateTab,
  onRemoveLeft,
  showLocation,
  MULTIPLE_TIME,
  listTimeCheck = [],
  onAddListTimeCheck,
  onRemoveListTimeCheck,
  onUpdateTimeCheck,
  accumulatedTypeList,
  defaultTypeList,
  defaultJudgement,
  ddlTypeChecklist,
  inventoryData,
  ...other
}) {
  let [tabIndex, setTabIndex] = useState(panes[0].ID || 1);

  const onChangeAccumulatedType = (
    value,
    rowData,
    indexTabTop,
    indexTabLeft
  ) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.accumulatedType = value;
          if (Number(value) === 4) {
            if (!item.multiplier || item.multiplier === "") { item.multiplier = 1; }
          } else {
            item.multiplier = "";
          }
        }
      }
    );
    setPanes(d);
  };
  const onChangeDefaultType = (value, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.defaultType = value;
          if (Number(value) === 1 || Number(value) === 2) {
            item.defaultValue = "";
          }
        }
      }
    );
    setPanes(d);
  };
  const onChangeItemJudgement = (value, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.itemJudgement = value;
        }
      }
    );
    setPanes(d);
  };

  //  change inventory
  const onChangeInventoryItem = (value, rowData, indexTabTop, indexTabLeft) => {
    const d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.inventoryItemCode.value = value;
          const indexWarehouse = inventoryData.findIndex(
            (item) => item.inventoryItemCode === value
          );
          let optionWareHouse = [];
          if (indexWarehouse >= 0) {
            optionWareHouse = inventoryData[indexWarehouse].warehouseItems.map(
              (item) => ({
                key: item.inventoryWarehouseItemCode,
                value: item.inventoryWarehouseName,
              })
            );
          }
          item.inventoryWarehouseItemCode.value = null;
          item.inventoryWarehouseItemCode.options = optionWareHouse;
        }
      }
    );
    setPanes(d);
  };

  // Warehouse code
  const onChangeInventoryWareHouseCode = (
    value,
    rowData,
    indexTabTop,
    indexTabLeft
  ) => {
    const d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.inventoryWarehouseItemCode.value = value;
        }
      }
    );
    setPanes(d);
  };

  const onChangeAutoCalculate = (value, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.autoCalculate = value;
        }
      }
    );
    setPanes(d);
  };
  const onChangeItemCodeRefer = (value, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.itemCodeRefer = value;
        }
      }
    );
    setPanes(d);
  };
  const onChangeDefaultValue = (value, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.defaultValue = value;
        }
      }
    );
    setPanes(d);
  };
  const onChangeAccumulatedGroupItemCodes = (
    value,
    rowData,
    indexTabTop,
    indexTabLeft
  ) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.accumulatedGroupItemCodes = value;
        }
      }
    );
    setPanes(d);
  };
  const onChangeMultiplier = (value, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.multiplier = value;
        }
      }
    );
    setPanes(d);
  };

  const onSwitchTimeCheck = (checked, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach((v) => {
      if (v === rowData) {
        v.multipleTime = checked;
        v.accumulatedType = "1";
        v.multiplier = "";
      }
    });
    setPanes(d);
  };

  // Check time v2
  const onCheckTime = (
    checked,
    indexCheck,
    rowData,
    indexTabTop,
    indexTabLeft
  ) => {
    let d = [...panes];
    let count = 0;
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          item.timeArr[indexCheck] = checked;

          item.timeArr.forEach((v) => {
            v && count++;
          });
        }
      }
    );

    // Uncheck all time OR first check time
    if (count === 0 || (checked && count === 1)) {
      d = cbGridBase(
        checked,
        rowData,
        d[indexTabTop].Machines[indexTabLeft],
        indexCheck,
        indexTabLeft,
        indexTabTop,
        true
      );
    }

    setPanes(d);
  };

  const onCheckAllTime = (checked, rowData, indexTabTop, indexTabLeft) => {
    let d = [...panes];
    d[indexTabTop].Machines[indexTabLeft].Items.forEach(
      (item = new classItem()) => {
        if (item.ID === rowData.ID) {
          listTimeCheck.forEach((v, i) => {
            item.timeArr[i] = checked;
          });
        }
      }
    );
    return d;
  };

  // change
  const changeOrder = (value, rowData, list, where, indexTab, indexTabLeft) => {
    let pageY = window.pageYOffset; // Get Y
    // let pageX = window.pageXOffset; // Get X

    const listNew = [...list];
    const indexNew = value - 1;
    let indexOld = rowData.Order - 1;

    // Remove
    listNew.splice(indexOld, 1);
    // Add
    listNew.splice(indexNew, 0, rowData);
    // Re-order
    listNew.forEach((el, i) => {
      el.Order = i + 1;
    });

    // TOP
    if (where === position.TOP) {
      setPanes(listNew);
      // setActiveKey(rowData.ID);
    }
    if (where === position.LEFT) {
      // LEFT
      panes[indexTab].Machines = listNew;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    if (where === position.ITEM) {
      // ITEM
      panes[indexTab].Machines[indexTabLeft].Items = listNew;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    // Re - Scroll
    setTimeout(() => {
      window.scrollTo(0, pageY);
    }, 0);

    return false;
  };

  const changeOrderItem = (
    value,
    rowData,
    list,
    where,
    indexTab,
    indexTabLeft
  ) => {
    let pageY = window.pageYOffset; // Get Y
    // let pageX = window.pageXOffset; // Get X
    const checkedList = list.filter((x) => x.checked);
    const uncheckedList = list.filter((x) => !x.checked);
    const indexNew = value - 1;
    const indexOld = checkedList.findIndex(
      (x) => x.CheckItemCode == rowData.CheckItemCode
    );
    // Remove
    checkedList.splice(indexOld, 1);
    // Add
    checkedList.splice(indexNew, 0, rowData);
    // Re-order
    checkedList.forEach((el, i) => {
      el.Order = i + 1;
    });

    uncheckedList.forEach((el) => (el.Order = null));

    // TOP
    if (where === position.ITEM) {
      // ITEM
      panes[indexTab].Machines[indexTabLeft].Items =
        checkedList.concat(uncheckedList);
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    // Re - Scroll
    setTimeout(() => {
      window.scrollTo(0, pageY);
    }, 0);

    return false;
  };

  const onCheckAllItem = (
    e,
    dTable = [],
    indexTabLeft,
    indexTabTop,
    funcUncheckTabOther
  ) => {
    let checked = e.target.checked;
    // Check/Uncheck all
    const dItem = funcUncheckTabOther(
      panes[indexTabTop].Machines[indexTabLeft].ID,
      indexTabTop
    );
    dItem[indexTabTop].Machines[indexTabLeft].Items = dTable.map(
      (i = new classItem()) => {
        i.checked = checked;

        i.timeArr &&
          i.timeArr.forEach((v, index) => {
            i.timeArr[index] = checked;
          });
        return i;
      }
    );

    setPanes(dItem);
  };

  // RENDER - Tab button
  const renderTabTitle = (
    dataRow = new classGroup(),
    isLeft,
    list,
    where,
    indexTab
  ) => {
    // Show title when
    let title = () => {
      // Tab LEFT
      if (isLeft) {
        return (
          <span
            className="textOverflow"
          // style={{
          //   maxWidth: "142px",
          // }}
          >
            {dataRow.Name}
          </span>
        );
      }
      // Tab TOP
      // Case active & !tabInit
      if (Number(activeKey) === Number(dataRow.ID)) {
        // REMOVE
        let funcDel = () => {
          // Remove tab group
          let obj = remove(dataRow.ID, list);
          setPanes(obj.data);
          setActiveKey(obj.data[obj.index].ID);
          return dataRow; // Return group del
        };
        // RE-NAME
        let funcUpdateName = (newNameGroup) => {
          let dGroup = panes;
          dGroup[indexTab].Name = newNameGroup;
          setPanes(dGroup);
        };
        return (
          <div
            className="d-inline-block btnLink-active pointer content-center"
            onClick={(eUpdate) => onUpdateTab(dataRow, funcDel, funcUpdateName)}
          >
            <span
              className="textOverflow d-inline-block"
              style={{
                maxWidth: "137px",
              }}
            >
              {dataRow.Name}
            </span>
            <EditIcon className="ml5" />
          </div>
        );
      } else {
        // in-active
        return (
          <span
            className="textOverflow"
            style={{
              maxWidth: "142px",
            }}
          >
            {dataRow.Name}
          </span>
        );
      }
    };

    // Render tab
    return (
      <Row
        // justify="space-between"
        className="left"
      >
        <Col
          span={24}
          // style={isLeft && { width: "189px" }}
          className="align-center"
        >
          <Select
            className="d-inline-block mr5 selectOrder"
            size="small"
            onChange={(v) => {
              changeOrder(v, dataRow, list, where, indexTab);
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              return false;
            }}
            value={dataRow.Order}
          >
            {list.map((el, i) => {
              // if (++i === e.Order)
              // return (<option key={el.Order} value={el.Order} selected>{el.Order}</option>);
              return (
                <Option key={el.Order} value={el.Order}>
                  {el.Order}
                </Option>
              );
            })}
          </Select>
          {title()}
        </Col>
      </Row>
    );
  };

  // REMOVE
  const remove = (key, data) => {
    let lastIndex;

    // 1. Get tab active
    data.forEach((e, i) => {
      if (e.ID === key) {
        // Pre-tab
        lastIndex = i - 1;
      }
    });
    // 2. Delete tab
    data = data.filter((e) => e.ID !== key);
    // 3. Re-Order tab
    data.forEach((e, i) => {
      e.Order = i + 1;
    });

    return {
      index: lastIndex < 0 ? 0 : lastIndex,
      data: data,
    };
  };

  // TOP ------------ CRUD
  // Active tab
  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  // Update tab
  const onEdit = (key, action) => {
    if (action === "add") {
      add();
    }
    // else {
    //     // REMOVE
    //     debugger;
    //     let obj = remove(key, panes);
    //     setPanes(obj.data);
    //     setActiveKey(obj.data[obj.index].ID);
    // }
  };
  // ADD
  const add = () => {
    tabIndex = ++tabIndex;
    setTabIndex(tabIndex);
    onAddTab(tabIndex); // Add to Group tab
  };

  // LEFT ------------- CRUD

  const delTabLeft = (idRow, idParent, leftData) => {
    // REMOVE
    let obj = remove(idRow, leftData);

    const newP = panes.map((e) => {
      if (e.ID === idParent) {
        e.Machines = obj.data;
      }
      return e;
    });
    setPanes(newP);
  };
  // Update tab
  const onEditLeft = (key, action) => {
    // Get tab
    let idParent;
    let rowData;
    let leftData;
    panes.forEach((e) => {
      e.Machines &&
        e.Machines.length > 0 &&
        e.Machines.forEach((e2) => {
          if (e.ID + "" + e2.ID === String(key)) {
            rowData = e2;
            idParent = e.ID;
            leftData = e.Machines;
          }
        });
    });

    if (action === "add") {
    } else {
      onRemoveLeft(delTabLeft, rowData, idParent, leftData);
    }
  };

  // Tab-left: Update checkbox
  // Uncheck all tab other
  // Check item click
  const cbGrid = (checked, row, machine, iRow, iTabLeft, iTabTop) => {
    setPanes(cbGridBase(checked, row, machine, iRow, iTabLeft, iTabTop));
  };
  const cbGridBase = (
    checked,
    row,
    machine,
    iRow,
    iTabLeft,
    iTabTop,
    onlyCheckRow
  ) => {
    const data = onlyCheckRow
      ? [...panes]
      : onCheckAllTime(checked, row, iTabTop, iTabLeft);
    const idRow = row.ID;
    // Uncheck group other
    data.forEach((g = new classGroup(), i1) => {
      if (String(i1) !== String(iTabTop)) {
        // Group other
        g.Machines.forEach((m = new classMachine(), i2) => {
          if (m.ID === machine.ID) {
            // Is Machine
            m.Items.forEach((i = new classItem()) => {
              if (idRow === i.ID) {
                i.checked = false;
              }
            });
          }
        });
      }
    });
    // Checked row
    data[iTabTop].Machines[iTabLeft].Items.forEach((i = new classItem()) => {
      if (idRow === i.ID) {
        i.checked = checked;
      }
    });

    return data;
  };
  // Uncheck all tab other - For checkallF
  const funcUncheckTabOther = (machineID, iTabTop) => {
    const data = [...panes];
    data.forEach((g = new classGroup(), i1) => {
      if (String(i1) !== String(iTabTop)) {
        // Group other
        g.Machines.forEach((m = new classMachine(), i2) => {
          if (m.ID === machineID) {
            // Is Machine
            m.Items.forEach((i = new classItem()) => {
              i.checked = false;
            });
          }
        });
      }
    });
    return data;
  };

  // Render------ Tab LEFT
  const renderTabLeft = (subData, indexTabTop, idTabTop) => {
    return (
      <Tabs
        style={{ height: "464px" }}
        type="editable-card"
        tabPosition="left"
        className="tbl-left-createCL"
        // defaultActiveKey={activeKeyLeft}
        hideAdd={true}
        // onChange={onChangeLeft}
        onEdit={onEditLeft}
        tabBarExtraContent={{
          left: <div className="titleTabLeft">{lang.MACHINES}</div>,
        }}
      >
        {subData.map((machine = new classMachine(), indexTabLeft) => {
          return (
            <TabPane
              tab={renderTabTitle(
                machine,
                true,
                subData,
                position.LEFT,
                indexTabTop
              )}
              key={idTabTop + "" + machine.ID}
              closeIcon={<DeleteIcon className="iconAction-delete" />}
            >
              {machine.Items ? (
                <div>
                  <Row className="mt15 mb15 ml15 mr15 checkitem-header">
                    <Col span={24} className="checkitem-header-title fs16 bold">
                      {machine.Name}
                    </Col>
                    <Col span={24} className="mt5 info-more">
                      <span className="mr25">
                        <span className="--bold">{lang.FACILITY}</span>
                        {lang.COLON}
                        {machine.facilityName}
                      </span>
                      <span className="mr25">
                        <span className="--bold">{lang.BUILDING}</span>
                        {lang.COLON}
                        {machine.buildingName}
                      </span>
                      <span className="mr25">
                        <span className="--bold">{lang.FLOOR}</span>
                        {lang.COLON}
                        {machine.floorName}
                      </span>
                      <span className="mr25">
                        <span className="--bold">{lang.ROOM}</span>
                        {lang.COLON}
                        {machine.roomName}
                      </span>
                      <span className="mr25">
                        <span className="--bold">{lang.PROCESS}</span>
                        {lang.COLON}
                        {machine.processName}
                      </span>
                    </Col>
                    {/* showLocation(e) */}
                  </Row>
                  <GridCheckList2
                    ddlTypeChecklist={ddlTypeChecklist}
                    rowKey="ID"
                    dataSource={machine.Items}
                    dataMachine={machine}
                    //
                    MULTIPLE_TIME={MULTIPLE_TIME}
                    listTimeCheck={listTimeCheck}
                    onAddListTimeCheck={onAddListTimeCheck}
                    onRemoveListTimeCheck={onRemoveListTimeCheck}
                    onUpdateTimeCheck={onUpdateTimeCheck}
                    accumulatedTypeList={accumulatedTypeList}
                    defaultTypeList={defaultTypeList}
                    defaultJudgement={defaultJudgement}
                    onChangeAccumulatedType={(v, rowData) => {
                      onChangeAccumulatedType(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeDefaultType={(v, rowData) => {
                      onChangeDefaultType(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeItemJudgement={(v, rowData) => {
                      onChangeItemJudgement(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeInventoryItem={(v, rowData) => {
                      onChangeInventoryItem(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeInventoryWareHouseCode={(v, rowData) => {
                      onChangeInventoryWareHouseCode(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeAutoCalculate={(v, rowData) => {
                      onChangeAutoCalculate(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeItemCodeRefer={(v, rowData) => {
                      onChangeItemCodeRefer(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeDefaultValue={(v, rowData) => {
                      onChangeDefaultValue(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeAccumulatedGroupItemCodes={(v, rowData) => {
                      onChangeAccumulatedGroupItemCodes(
                        v,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onChangeMultiplier={(v, rowData) => {
                      onChangeMultiplier(v, rowData, indexTabTop, indexTabLeft);
                    }}
                    // switch Time
                    onSwitchTimeCheck={(checked, rowData) => {
                      onSwitchTimeCheck(
                        checked,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onCheckAllTime={(checked, rowData) => {
                      onCheckAllTime(
                        checked,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    onCheckTime={(checked, indexCheck, rowData) => {
                      onCheckTime(
                        checked,
                        indexCheck,
                        rowData,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    // Order item
                    onChangeOrderRow={(v, rowData, listItem) => {
                      changeOrderItem(
                        v,
                        rowData,
                        listItem,
                        position.ITEM,
                        indexTabTop,
                        indexTabLeft
                      );
                    }}
                    // Check Item
                    onCheckboxItem={(e, row, machine, indexRow) => {
                      cbGrid(
                        e.target.checked,
                        row,
                        machine,
                        indexRow,
                        indexTabLeft,
                        indexTabTop
                      );
                    }}
                    // scroll={{ x: 100, y: 368 }}
                    onCheckAllItem={(ev) => {
                      onCheckAllItem(
                        ev,
                        machine.Items,
                        indexTabLeft,
                        indexTabTop,
                        funcUncheckTabOther
                      );
                    }}
                  />
                </div>
              ) : (
                <div className="center mt20">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </TabPane>
          );
        })}
      </Tabs>
    );
  };

  // Render Tab TOP
  return (
    <TabsCustom
      style={{ minHeight: "500px" }}
      className="tbl-createCL"
      type="editable-card"
      onChange={onChange}
      activeKey={activeKey + ""}
      onEdit={onEdit}
      // Button add
      // addIcon={
      //   <span className="">
      //     <PlusOutlined className="orange" /> グループを追加
      //   </span>
      // }
      onTabClick={(key, event) => {
        //Empty
      }}
      {...other}
    >
      {panes.map((pane, indexTab) => {
        return (
          <TabPane
            tab={renderTabTitle(pane, false, panes, position.TOP, indexTab)}
            key={pane.ID}
            closable={false}
          >
            {pane.Machines && pane.Machines.length > 0 ? (
              renderTabLeft(pane.Machines, indexTab, pane.ID)
            ) : (
              <div className="center mt20">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </TabPane>
        );
      })}
    </TabsCustom>
  );
}

export default TabCheckList;
