import {
  CheckSquareOutlined,
  CarryOutFilled,
  ClusterOutlined,
  CodeSandboxOutlined,
  ContainerOutlined,
  ControlOutlined,
  AppstoreAddOutlined,
  EnvironmentOutlined,
  ExportOutlined,
  FileSyncOutlined,
  FileTextFilled,
  FormOutlined,
  GlobalOutlined,
  HddOutlined,
  HistoryOutlined,
  HomeFilled,
  UserOutlined,
  ImportOutlined,
  InfoCircleFilled,
  LayoutFilled,
  LayoutOutlined,
  ProfileOutlined,
  ReconciliationFilled,
  ScheduleOutlined,
  SettingFilled,
  SnippetsOutlined,
  ToolFilled,
  UnorderedListOutlined,
  NotificationFilled,
  EditOutlined,
  CalendarFilled,
  LineChartOutlined,
  TeamOutlined
} from "@ant-design/icons";
import ROUTER_PAGE from "@src/constants/router.const";
import { lang } from "@src/constants/common.const";
import rolesConst from "../routers/roles/roles.const";

export const createListMenu = (role) => {
  const listAllMenu = [
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M010",
      menuName: "ホームページ",
      displayOrder: 1,
      key: "DASHBOARD",
      icon: HomeFilled,
      path: ROUTER_PAGE.ROUTER_PAGE.DASHBOARD,
    },
    {
      haveScreen: 0,
      key: "checklistManagement",
      icon: ScheduleOutlined,
      subMenus: [
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M021",
          menuName: "点検実施",
          displayOrder: 1,
          key: "CL_INPUT",
          icon: FormOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CL_INPUT,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M022",
          menuName: "点検表登録",
          displayOrder: 2,
          key: "CL_MANAGEMENT",
          icon: UnorderedListOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CL_MANAGEMENT[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M023",
          menuName: "総累計リセット",
          displayOrder: 3,
          key: "INPUT_REST_LAST_VALUE",
          icon: FileSyncOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.INPUT_REST_LAST_VALUE[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M024",
          menuName: "点検履歴",
          displayOrder: 4,
          key: "CL_HISTORY",
          icon: HistoryOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CL_HISTORY,
        },
      ],
      menuCode: "M020",
      menuName: "点検管理",
      displayOrder: 2,
    },
    
    {
      haveScreen: 0,
      key: "LOCATION_MANAGEMENT",
      icon: ToolFilled,
      subMenus: [
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M031",
          menuName: "施設管理",
          displayOrder: 1,
          key: "FACILITY_MANAGEMENT",
          icon: EnvironmentOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.FACILITY_MANAGEMENT[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M032",
          menuName: "プロセス管理",
          displayOrder: 2,
          key: "PROCESS_MANAGEMENT",
          icon: ProfileOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.PROCESS_MANAGEMENT[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M033",
          menuName: "機器台帳管理",
          displayOrder: 3,
          key: "MACHINE_MANAGEMENT",
          icon: ControlOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.MACHINE_MANAGEMENT[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M034",
          menuName: lang.MAINTAINCE_HISTORY,
          displayOrder: 4,
          key: "MAINTAIN_HISTORY",
          icon: AppstoreAddOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.MAINTAIN_HISTORY[0],
        },
        {
          haveScreen: 1,
          subMenus: [],
          menuCode: "M035",
          menuName: lang.CALIBRATION_MANAGEMENT,
          displayOrder: 5,
          key: "CALIBRATION",
          icon: CheckSquareOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CALIBRATION[0],
        },
      ],
      menuCode: "M030",
      menuName: "場所・機器管理",
      displayOrder: 3,
    },
    {
      haveScreen: 0,
      key: "reportManagement",
      icon: FileTextFilled,
      subMenus: [
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M041",
          menuName: "報告書出力",
          displayOrder: 1,
          key: "CUSTOMER_REPORT",
          icon: SnippetsOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CUSTOMER_REPORT,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M042",
          menuName: "CSVファイル入力",
          displayOrder: 2,
          key: "SHEET_IMPORT_REPORT",
          icon: ImportOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.SHEET_IMPORT_REPORT,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M043",
          menuName: "報告テンプレート管理",
          displayOrder: 3,
          key: "TEMPLATE_MANAGEMENT",
          icon: LayoutFilled,
          path: ROUTER_PAGE.ROUTER_PAGE.TEMPLATE_MANAGEMENT,
        },
      ],
      menuCode: "M040",
      menuName: "報告管理",
      displayOrder: 4,
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M050",
      menuName: "作業報告管理",
      displayOrder: 5,
      key: "WORK_REPORT",
      icon: CarryOutFilled,
      path: ROUTER_PAGE.ROUTER_PAGE.WORK_REPORT[0],
    },
    {
      
      haveScreen: 0,
      subMenus: [
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M061",
          menuName: "権限設定",
          displayOrder: 1,
          key: "ROLE_SETTING",
          icon: LayoutOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M062",
          menuName: "ユーザ情報",
          displayOrder: 1,
          key: "USER_INFO",
          icon: UserOutlined ,
          path: "/ViewUser",
        }
      ],
      menuCode: "M060",
      menuName: "ユーザ設定",
      displayOrder: 6,
      key: "USER_SETTING",
      icon: SettingFilled,
      path: ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING,
    },
    {
      haveScreen: 0,
      key: "importExport",
      icon: ReconciliationFilled,
      subMenus: [
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M071",
          menuName: "点検表登録",
          displayOrder: 1,
          key: "CL_REGISTRATION",
          icon: LayoutOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CL_REGISTRATION,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M072",
          menuName: "機器台帳登録",
          displayOrder: 2,
          key: "MC_REGISTRATION",
          icon: ClusterOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.MC_REGISTRATION,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M073",
          menuName: "倉庫マスターデータ登録",
          displayOrder: 3,
          key: "INVENTORY_REGISTRATION",
          icon: ClusterOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.INVENTORY_REGISTRATION,
        },
      ],
      menuCode: "M070",
      menuName: "初期データ登録",
      displayOrder: 7,
    },
    {
      haveScreen: 0,
      key: "INVENTORY",
      icon: CodeSandboxOutlined,
      subMenus: [
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M081",
          menuName: "入庫レコード",
          displayOrder: 1,
          key: "IMPORT_INVENTORY",
          icon: ImportOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.IMPORT_INVENTORY[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M082",
          menuName: "出庫レコード",
          displayOrder: 2,
          key: "EXPORT_INVENTORY",
          icon: ExportOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.EXPORT_INVENTORY[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M083",
          menuName: "在庫数量",
          displayOrder: 3,
          key: "INVENTORY_DATA",
          icon: ContainerOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.INVENTORY_DATA,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M084",
          menuName: "在庫履歴",
          displayOrder: 4,
          key: "STOCK",
          icon: ContainerOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.STOCK,
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M085",
          menuName: "倉庫",
          displayOrder: 5,
          key: "WAREHOUSES_MASTER",
          icon: HddOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.WAREHOUSES_MASTER[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M086",
          menuName: "カテゴリー",
          displayOrder: 6,
          key: "CATEGORY_MASTER",
          icon: HddOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.CATEGORY_MASTER[0],
        },
        {
          haveScreen: 1,
          subMenus: null,
          menuCode: "M087",
          menuName: "項目",
          displayOrder: 7,
          key: "ITEM_MASTER",
          icon: HddOutlined,
          path: ROUTER_PAGE.ROUTER_PAGE.ITEM_MASTER[0],
        },
      ],
      menuCode: "M080",
      menuName: "在庫",
      displayOrder: 8,
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M090",
      menuName: "事業所情報管理",
      displayOrder: 9,
      key: "OFFICE_MANAGEMENT",
      icon: GlobalOutlined,
      path: ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT[0],
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M100",
      menuName: "事業所情報",
      displayOrder: 10,
      key: "OFFICE_INFO",
      icon: InfoCircleFilled,
      path: ROUTER_PAGE.ROUTER_PAGE.OFFICE_INFO,
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M110",
      menuName: "機能カスタマイズ設定",
      displayOrder: 11,
      key: "FUNTION_MACHINE_MANAGEMENT",
      icon: EditOutlined,
      path: ROUTER_PAGE.ROUTER_PAGE.FUNTION_MACHINE_MANAGEMENT,
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M120",
      menuName: "お知らせ",
      displayOrder: 12,
      key: "HOME_BANNER",
      icon: NotificationFilled,
      path: ROUTER_PAGE.ROUTER_PAGE.HOME_BANNER[0],
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M130",
      menuName: '動的レポート管理',
      displayOrder: 13,
      key: "DYNAMIC_REPORT_MANAGEMENT",
      icon: LineChartOutlined,
      path: ROUTER_PAGE.ROUTER_PAGE.DYNAMIC_REPORT_MANAGEMENT[0],
    },
    {
      haveScreen: 1,
      subMenus: [],
      menuCode: "M150",
      menuName: lang.CALENDAR,
      displayOrder: 15,
      key: "CALENDAR",
      icon: CalendarFilled,
      path: ROUTER_PAGE.ROUTER_PAGE.CALENDAR,
    },
  ];
  
   if([rolesConst.OFFICE_ADMIN , rolesConst.HEAD_OFFICE_ADMIN].includes(role)){
    listAllMenu.push({
      haveScreen: 1,
      subMenus: [],
      path: ROUTER_PAGE.ROUTER_PAGE.END_USER,
      key: "END_USER",
      menuCode: "M160",
       menuName:lang.END_USER,
      icon: TeamOutlined,
  
    })
   }
    return listAllMenu;
} 
  
 

