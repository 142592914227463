import Api from "@src/api";

export const UserMangementApi  = {
    searchUserByKeyWord(params){
        return Api().get("/UserInfo/SearchUserByKeyWord",{params})
    },
    getInforUserByUserName(params){
        return Api().get("/UserInfo/GetUserByUserName",{params})
    },
    getOwnInformation(){
    return Api().get("/UserInfo")
    },
    uploadAvatar (formData) {
        return Api().post("FileUpload/Image",formData,{
            headers: { 'Content-Type': 'multipart/form-data'}
        })
    },
    uploadSignature (formData) {
        return Api().post("FileUpload/Signature",formData,{
            headers: { 'Content-Type': 'multipart/form-data'}
        })
    },
    updateUser (data) {
        return Api().put("UserInfo/UpdateUser",data);
    }
}