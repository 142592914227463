import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { isEmpty } from 'lodash';

const App = ({ imageUrl, handleUpload }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = () => {
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (isEmpty(newFileList)) {
      handleUpload(null);
    }
  };

  useEffect(() => {
    if (!imageUrl) {
      setFileList([]);
      return;
    };
    setFileList([{
        status: 'done',
        url: imageUrl,
      },
    ])
  }, [imageUrl]);

  return (
    <div className="wrap-unsetw-btn">
      <Upload
        accept="image/*"
        action={handleUpload}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        className="my-upload"
      >
        {
          fileList.length === 0 && <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        }
      </Upload>
      <Modal open={previewOpen} visible={previewOpen} title={"写真"} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};
export default App;