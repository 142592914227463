/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import PopupAllUser from "./popupAllUser";
// import { ReactComponent as ListIcon } from "@src/styles/icon/list.svg";
import { useMediaQuery } from "react-responsive";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/managementRole";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  KEY,
  LIMIT,
  ROLE_KEY,
  lang,
  STYLE
} from "../../constants/common.const";
import {
  MSG_CATCH,
  enterSearch,
  getQuerySearch,
  isAdminOffice,
  isHeadOffice,
  pushObject2Query,
  renderOption,
  showMessage,
  statusRes,
} from "../../constants/utils";

import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import {
  CloseOutlined,
  DeleteOutlined,
  EditFilled,
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { Avatar, List, Typography } from "antd";
import { Building, SecurityUser, UserTag } from "iconsax-react";
import { isEmpty, isEqual, isObject, isString } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import classPopup from "../../models/control/popup";
import { checkRoleScreen } from "../../utils/roles";
import rolesConst from "./../../routers/roles/roles.const";
import "./list.scss";

const { Panel } = Collapse;
const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;
const objClear = {
  value: null,
};
const objectClone = (obj = {}) => JSON.parse(JSON.stringify(obj));

let listRole = [],
  listSite = [],
  listRoleRender;

function Role_list() {
  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [initPage, setInitPage] = useState(true);
  const [grid, setGrid] = useState(new classGrid());
  const objSearch = getQuerySearch();
  // Input search
  const [txtUser, settxtUser] = useState(new classText(objSearch.user || null));
  const [ddlRole, setddlRole] = useState(new classDdl(objSearch.role || null));
  const [ddlSiteOffice, setddlSiteOffice] = useState(
    new classDdl(objSearch.siteOfficeMasterCode || null)
  );
  // POPUP
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [popupChecklist, setPopupChecklist] = useState(new classPopup());
  const [form] = Form.useForm();
  const [gridPopup, setGridPopup] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const [_listChecklist, set_listChecklist] = useState([]);
  const [_nameChecklist, set_nameChecklist] = useState(new classText());
  const [loadSpin, setLoadSpin] = useState(true);
  const location = useLocation();
  const { allowRoute, roles, roleKey } = useSelector((state) => state.auth);
  const isAdmin = roleKey == ROLE_KEY.ADMIN;
  const isChief = roleKey == KEY.MENU_ADMIN_OFFICE;
  const searchParams = {
    user: txtUser.value ? txtUser.value.trim() : null,
    role: ddlRole.value,
    siteOfficeMasterCode: ddlSiteOffice.value,
  };
  // Search
  const search_onClick = async (init = false, emptyParam = false) => {
    // Loading
    openLoadingSpinner();
    // Call
    return await API.searchUserRole(!emptyParam ? searchParams : null)
      .then((res) => {
        if (statusRes(res)) {
          setGrid(res.data);
          !init &&
            pushObject2Query(
              !emptyParam
                ? searchParams
                : {
                    user: null,
                    role: null,
                    siteOfficeMasterCode: null,
                  }
            );
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        !init && showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        closeLoadingSpinner();
      });
  };

  const showMessageDeleteConfirm = (userName) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();
      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        // Del
        await API.deleteUser(userName);
        // API Search
        await search_onClick(false);
        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.DELETED_USER);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.DELETE_USER,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };
  // POPUP
  const popup = {
    show: () => {
      setPopupChecklist({ ...popupChecklist, show: true });
    },
    hide: () => {
      setPopupChecklist({ ...popupChecklist, show: false });
    },
    showPopup: () => {
      // Clear
      form.resetFields();
      // Re-search
      popup.search();
      popup.show();
    },
    closePopup: () => {
      // Clear
      form.resetFields();
      set_listChecklist([]);
      set_nameChecklist(new classText());
      //popup.hide();
      popup.hide();
    },
    // Search list Checklist
    search: async (reSearch) => {
      // Loading
      setLoadSpin(true);
      return API.searchUserAD({
        keyword: getKeyword(),
        offset: reSearch ? 0 : gridPopup.offset,
        limit: LIMIT,
      })
        .then((res) => {
          const data = res.data;
          set_listChecklist(popup.verifyData(data.data));
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          setLoadSpin(false);
        });
    },

    verifyData: (dataNew) => {
      return dataNew.map((v) => {
        let checked = false;
        // Checkid
        grid.data.forEach((vOld) => {
          if (vOld.userName === v.userPrincipalName) {
            checked = true;
          }
        });
        v["disabled"] = checked;
        v["checked"] = v["checked"] || checked; // Keep checked - user
        return v;
      });
    },
    addToGroup: () => {
      openLoadingSpinner();

      // List add
      const listAdd = _listChecklist.filter((v) => v.checked && !v.disabled);

      API.addUserAD(listAdd)
        .then((res) => {
          //  show message
          showMessage(
            KEY.INFO,
            <>
              {listAdd.map((v) => {
                return (
                  <>
                    {v.userPrincipalName} <br />
                  </>
                );
              })}
              {COMMON_CONSTANT.ADD_USER}
            </>,
            search_onClick // Re-search home
          );
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          closeLoadingSpinner();
          popup.closePopup();
        });
    },
  };

  // Update offset
  useEffect(() => {
    setGridPopup({ ...gridPopup, offset: _listChecklist.length });
  }, [_listChecklist]);
  // Search for Mobile
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      !isPC && search_onClick(true);
    }
  }, [txtUser.value, ddlSiteOffice.value, ddlRole.value, allowRoute]);

  const getKeyword = () => {
    return _nameChecklist.value && _nameChecklist.value.length > 0
      ? _nameChecklist.value.trim()
      : null;
  };

  const gridLoadMore = () => {
    API.searchUserAD({
      keyword: getKeyword(),
      offset: gridPopup.offset,
      limit: LIMIT,
    })
      .then((res) => {
        const data = res.data;
        // push

        set_listChecklist(popup.verifyData([..._listChecklist, ...data.data]));
      })
      .catch((error) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const grid_checkbox = (id, index, checked) => {
    const list = JSON.parse(JSON.stringify(_listChecklist));
    list[index]["checked"] = checked;
    set_listChecklist(list);
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      // Open loading
      openLoadingSpinner();
      // settxtUser({ ...txtUser, value: "" });
      setPopupChecklist({
        ...popupChecklist,
        show: false,
        title: lang.SEE_ALL,
      });

      // Load pulldown
      Promise.all([KBN.getRole(), KBN.getSiteOffice(), search_onClick(true)])
        .then((res) => {
          listRole = res[0] ? res[0].data.data : [];
          if (isChief) {
            listRole = listRole.filter(
              (role) => ![ROLE_KEY.ADMIN, ROLE_KEY.OPERATOR].includes(role.key)
            );
          }
          listSite = res[1] ? res[1].data.data : [];
          listRoleRender = renderOption(listRole, false);
        })
        .catch((err) => {
          showMessageError(MSG_CATCH());
        })
        .then(() => {
          // Set pulldown
          setddlRole({
            ...ddlRole,
            options: [{ key: "0", value: "権限無し" }, ...listRole],
          });
          setddlSiteOffice({ ...ddlSiteOffice, options: listSite });
          // Close loading
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);

  const clearInputSearch = () => {
    settxtUser({ ...txtUser, value: "" });
    setddlRole({ ...ddlRole, value: null });
    setddlSiteOffice({ ...ddlSiteOffice, value: null });
    formSearch.setFieldsValue({
      user: null,
      ddlRole: null,
      ddlSiteOffice: null,
    });
    search_onClick(false, true);
  };

  // Render
  return (
    <div className="des-content role-setting">
      <TitlePage name={lang.ROLE_SETTING} />
      <Content
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        {/* Group input */}
      </Content>

      {!initPage && (
        <Content
          className="site-layout-background"
          style={{
            margin: "24px auto",
            padding: 24,
            maxWidth: 1080,
          }}
        >
          {/* <div className="titleText2 ">
            <ListIcon className="w18px svg-default mr5" /> {lang.ROLE_SETTING}
          </div> */}
          <Form
            name="searchRole"
            layout={"vertical"}
            form={formSearch}
            // onFinish={search_onClick}
            className="formStyle"
            style={{ display: "inline" }}
          >
            <Row gutter={[8, isPC ? 0 : 16]}>
              <Col span={24}>
                <Row gutter={[8, isPC ? 0 : 16]}>
                  <Col span={isPC ? 8 : 24}>
                    <Form.Item name="user" className="rowNotMargin">
                      <Input
                        allowClear
                        defaultValue={objSearch.user || null}
                        placeholder="ユーザ"
                        value={txtUser.value}
                        onChange={(v) => {
                          settxtUser({ ...txtUser, value: v.target.value });
                        }}
                        maxLength={255}
                        // {...enterSearch(search_onClick)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={isPC ? 8 : 12}>
                    <Form.Item name="ddlRole" className="rowNotMargin">
                      <Select
                        defaultValue={objSearch.role || null}
                        placeholder="権限"
                        value={ddlRole.value}
                        // className="w100 ant-input selectHtml "
                        onChange={(v) => {
                          setddlRole({ ...ddlRole, value: v });
                        }}
                        // {...enterSearch(search_onClick)}
                      >
                        {renderOption(ddlRole.options)}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={isPC ? 8 : 12}>
                    {/* 2 auto picker */}
                    <Form.Item name="ddlSiteOffice" className="rowNotMargin">
                      <Select
                        defaultValue={objSearch.siteOfficeMasterCode || null}
                        placeholder="事業所サイト"
                        value={ddlSiteOffice.value}
                        // className="w100 ant-input selectHtml "
                        onChange={(v) => {
                          setddlSiteOffice({
                            ...ddlSiteOffice,
                            value: v,
                          });
                        }}
                        // {...enterSearch(search_onClick)}
                      >
                        {renderOption(ddlSiteOffice.options)}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {isPC && (
                <Col span="24" className="right mt16">
                  <Button
                    className="buttonPC button--info"
                    onClick={() => search_onClick(false)}
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>

                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml8"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
          {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
          <Row justify="space-between mb15">
            <Col span={12}>
              {/* Add */}
              {(isHeadOffice() || isAdminOffice()) && (
                <Button
                  type="link"
                  className="pl0 pr0 m-wAuto btnLink"
                  onClick={() => {
                    popup.showPopup();
                  }}
                >
                  <AddIcon className="svg-default mr5" />
                  <span style={{ fontWeight: 600 }}>{COMMON_CONSTANT.ADD}</span>
                </Button>
              )}
            </Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                該当件数 <span>{grid.count}件</span>
              </Space>
            </Col>
          </Row>

          {/* Grid  */}
          {isEmpty(grid.data) && (
            <div className="text-center">
              <Empty />
            </div>
          )}
          {(ddlRole.options || [])
            // .filter(r => !ddlRole.value || r.key == ddlRole.value)
            .map((role, index) => {
              const dataSource = grid.data.filter((x) => {
                if (role.key == "0") {
                  return isEmpty(x.roles);
                }
                return x.roles.some((r) => r.roleValue === role.key);
              });
              const title = (
                <Title level={4} className="mb0" style={{ fontSize: 18 }}>
                  {role.value}{" "}
                  <span style={{ color: "#65676B" }}>
                    · {dataSource.length}件
                  </span>
                </Title>
              );

              if (isEmpty(dataSource)) return <></>;
              return (
                <div key={index}>
                  <Collapse
                    defaultActiveKey={dataSource.length ? ["1"] : ["0"]}
                    ghost
                  >
                    <Panel showArrow={false} header={title} key="1">
                      {/* {title} */}
                      <List
                        itemLayout="horizontal"
                        dataSource={dataSource}
                        renderItem={(item, index) => {
                          const title = (
                            <div className="cursor-pointer">
                              {[
                                rolesConst.HEAD_OFFICE_ADMIN,
                                rolesConst.OFFICE_ADMIN,
                              ].includes(roles) ? (
                                <span
                                  onClick={() =>
                                    history.push(`/ViewUser/${item.userName}`)
                                  }
                                  className="link-name"
                                >
                                  {item.displayName} ({item.userName})
                                </span>
                              ) : (
                                <span>
                                  {item.displayName} ({item.userName})
                                </span>
                              )}

                              {item.isApprover && (
                                <Tooltip title={lang.APPROVER}>
                                  <SecurityUser
                                    color="#2596be"
                                    variant="Bold"
                                    className="ml4 mb-2"
                                  />
                                </Tooltip>
                              )}
                              {item.isRequestingRole && (
                                <Tag color={"orange"} className="tag-label ml5">
                                  <span
                                    style={{
                                      color: "orange",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    所長権限対応中
                                  </span>
                                </Tag>
                              )}
                            </div>
                          );
                          return (
                            <List.Item className="pb0" key={index}>
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={item.avatarUrl}
                                    size={48}
                                    style={{
                                      backgroundColor: "#fde3cf",
                                      color: "#f56a00",
                                    }}
                                  >
                                    {!item.avatarUrl &&
                                      (item.displayName ||
                                        item.userName)[0].toUpperCase()}
                                  </Avatar>
                                }
                                title={title}
                                description={<AccountDescription item={item} />}
                                className="mb0"
                              />
                              <div>
                                <AccountUpdateButtonModal
                                  item={item}
                                  openLoadingSpinner={openLoadingSpinner}
                                  closeLoadingSpinner={closeLoadingSpinner}
                                  showMessageError={showMessageError}
                                  search_onClick={search_onClick}
                                  setInitPage={setInitPage}
                                />
                                {isAdmin && (
                                  <Tooltip title={lang.DELETE}>
                                    {/* Delete */}
                                    <Button
                                      disabled={!item.canDelete}
                                      className="mr5 btnDelete btn-action"
                                      onClick={() => {
                                        showMessageDeleteConfirm(item.userName);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Tooltip>
                                )}
                              </div>
                            </List.Item>
                          );
                        }}
                      />
                    </Panel>
                  </Collapse>
                  <Divider className="mb20 mt10 ml0 mr0" />
                </div>
              );
            })}
        </Content>
      )}

      {/* Footer */}
      {/* {grid.data.length > 0 && (
        <FooterPage>
          <Row justify="">
            <Col>
              <Button
                className="buttonPC button--info wAuto "
                onClick={onSaveRole}
              >
                <SaveOutlined />
                {lang.SAVE}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={onCancelRole}
              >
                <UndoOutlined />
                {lang.RESET}
              </Button>
            </Col>
          </Row>
        </FooterPage>
      )} */}

      <Modal
        className={"popupP2"}
        centered
        title={popupChecklist.title}
        visible={popupChecklist.show}
        // onOk={handleOk}
        onCancel={popup.closePopup}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={popup.addToGroup} disabled={!_listChecklist.some((v) => v.checked && !v.disabled)}>
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
          <Button
            className="buttonPC button--outline --todo-- wAuto"
            key="back"
            onClick={popup.closePopup}
          >
            <CloseOutlined />
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <PopupAllUser
          isPC={isPC}
          loadSpin={loadSpin}
          popup={popup}
          _nameChecklist={_nameChecklist}
          set_nameChecklist={set_nameChecklist}
          gridPopup={gridPopup}
          _listChecklist={_listChecklist}
          form={form}
          gridLoadMore={gridLoadMore}
          grid_checkbox={grid_checkbox}
        />
      </Modal>

      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default Role_list;

export function AccountDescription({ item }) {
  return (
    <>
      <div className="mb4">
        {item.roles.map((r, i) => {
          const siteOffices = r.siteOffices.map((site, i) => {
            return (
              <div className="mb4" key={i}>
                <Building size={24} className="first-icon" />
                <span style={{ verticalAlign: "text-top" }}>
                  {site.siteOfficeMasterName}
                </span>
              </div>
            );
          });

          const roleName = (
            <span
              className="mr5"
              style={{
                color: "#1876F2",
                backgroundColor: "#E7F3FF",
                borderRadius: "4px",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              {r.role}
              {r.siteOffices.length
                ? ` (${
                    r.siteOffices.length > 1
                      ? r.siteOffices.length
                      : r.siteOffices[0].siteOfficeMasterName
                  })`
                : ""}
            </span>
          );

          return (
            <Popover
              title={
                <b style={{ fontSize: 16 }}>
                  <UserTag size={24} className="first-icon color-danger" />
                  {r.role}
                </b>
              }
              content={siteOffices}
              trigger={r.siteOffices.length ? "click" : ""}
              overlayClassName="role-setting-popover"
              placement="bottomLeft"
              key={i}
            >
              {roleName}
            </Popover>
          );
        })}
      </div>
      <p>The last update at {item.updateDateTime}</p>
    </>
  );
}

export function AccountAction({ item }) {
  return (
    <>
      <div className="mb4">
        {item.roles.map((r, i) => {
          const siteOffices = r.siteOffices.map((site, i) => {
            return (
              <div className="mb4" key={i}>
                <Building size={24} className="first-icon" />
                <span style={{ verticalAlign: "text-top" }}>
                  {site.siteOfficeMasterName}
                </span>
              </div>
            );
          });

          const roleName = (
            <span
              className="mr5"
              style={{
                color: "#1876F2",
                backgroundColor: "#E7F3FF",
                borderRadius: "4px",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              {r.role}
              {r.siteOffices.length ? `(${r.siteOffices.length})` : ""}
            </span>
          );

          return (
            <Popover
              title={
                <b style={{ fontSize: 16 }}>
                  <UserTag size={24} className="first-icon color-danger" />
                  {r.role}
                </b>
              }
              content={siteOffices}
              trigger={r.siteOffices.length ? "click" : ""}
              overlayClassName="role-setting-popover"
              placement="bottomLeft"
              key={i}
            >
              {roleName}
            </Popover>
          );
        })}
      </div>
      <p>The last update at {item.updateDateTime}</p>
    </>
  );
}

export function AccountUpdateButtonModal({
  item,
  openLoadingSpinner,
  closeLoadingSpinner,
  showMessageError,
  search_onClick,
  setInitPage,
}) {
  const { roleKey, selectedFactory, roles } = useSelector(
    (state) => state.auth
  );
  const [accountUpdate, setAccountUpdate] = useState(null);
  const [popupUpdate, setPopupUpdate] = useState(new classPopup());
  const popupUpdateFunc = {
    show: () => {
      setPopupUpdate({ ...popupUpdate, show: true });
    },
    hide: () => {
      setPopupUpdate({ ...popupUpdate, show: false });
    },
  };
  const isChief = roleKey == KEY.MENU_ADMIN_OFFICE;
  const isNotChanged =
    accountUpdate != null &&
    isEqual(
      {
        ...accountUpdate,
        roles: (accountUpdate.roles || []).filter((x) => x.roleValue),
      },
      item
    );
  const isRequest =
    isChief &&
    accountUpdate != null &&
    (accountUpdate.roles || []).some(
      (x) => x.roleValue == KEY.MENU_ADMIN_OFFICE
    );

  //Add Role
  const onAddRole = () => {
    let g = objectClone(accountUpdate);
    g.roles.push({
      roleValue: null,
      siteOffices: [],
    });
    setAccountUpdate(g);
  };
  //Delete Role
  const onDeleteRole = (indexRole) => {
    let g = objectClone(accountUpdate);
    g.roles.splice(indexRole, 1);
    setAccountUpdate(g);
  };
  // Change Role in 権限設定
  const onChangeRole = (v, indexRole) => {
    let g = objectClone(accountUpdate);
    if (Number(v) === 1 || Number(v) === 11) {
      // 本社スタフ
      g.roles = [
        {
          roleValue: v,
          siteOffices: [],
        },
      ];
    } else {
      // Other
      g.roles[indexRole].roleValue = v;
    }
    setAccountUpdate(g);
  };
  // Change Site
  const onChangeSite = (v = [], indexRole) => {
    let g = objectClone(accountUpdate);
    let listSizeSelect = [];
    v.forEach((v) => {
      listSizeSelect.push({
        siteOfficeMasterCode: v,
      });
    });

    g.roles[indexRole].siteOffices = listSizeSelect;
    setAccountUpdate(g);
  };
  const isInvalid = accountUpdate && (accountUpdate.roles || []).some(role => {
    const notAdmin = role.roleValue &&
    role.roleValue != ROLE_KEY.ADMIN &&
    role.roleValue != ROLE_KEY.OPERATOR;

    const emptySite = isEmpty(role.siteOffices);
    return notAdmin && emptySite;
  });

  const renderRoleSelection = () => {
    // List Record Role
    let listRowSelectRole = accountUpdate.roles.map((role, indexRole) => {
      const selectedSite = role.siteOffices.map((v) => {
        return v.siteOfficeMasterCode;
      });

      const isSitesDisabled =
        !role.roleValue ||
        role.roleValue == ROLE_KEY.ADMIN ||
        role.roleValue == ROLE_KEY.OPERATOR;

      return (
        <div key={indexRole}>
          <Row gutter={[5, 10]}>
            <Col span={9}>
              <Select
                value={role.roleValue}
                className="w100"
                placeholder="権限"
                onChange={(e) => {
                  onChangeRole(e, indexRole);
                }}
              >
                {listRoleRender}
              </Select>
            </Col>
            <Col span={14}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                placeholder="事業所サイト"
                value={selectedSite}
                onChange={(e) => {
                  onChangeSite(e, indexRole);
                }}
                disabled={isSitesDisabled}
                filterOption={(input, opt) => {
                  return (opt.label || "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                options={listSite
                  .filter((x) => !isChief || x.key === selectedFactory)
                  .map((v, i) => {
                    return { value: v.key, label: v.value };
                  })}
                className={(!isSitesDisabled && isEmpty(selectedSite)) ? STYLE.BORDER_RED : ""}
              />
            </Col>
            <Col span={1}>
              <MinusCircleOutlined
                style={{ lineHeight: "40px", fontSize: 18 }}
                onClick={() => {
                  onDeleteRole(indexRole);
                }}
              />
            </Col>
          </Row>
        </div>
      );
    });
    // Btn Add
    let btnAdd = () => {
      let domAdd = (
        <Row gutter={[5, 10]}>
          <Col span={isEmpty(accountUpdate.roles) ? 24 : 23}>
            <Button
              className="buttonPC w100 btn-add-field"
              onClick={() => {
                onAddRole();
              }}
            >
              <PlusOutlined /> 権限追加
            </Button>
          </Col>
        </Row>
      );
      if (
        (accountUpdate.roles.length < 3 && // Max 3 role
          (accountUpdate.roles.length > 0 &&
            accountUpdate.roles[0].roleValue) !== 1) || // Not Boss
        accountUpdate.roles.length === 0 // Empty role
      ) {
        return domAdd;
      }
    };
    // List Row + btnAdd
    return (
      <>
        {listRowSelectRole}
        {btnAdd()}
        {isChief &&
          isRequest &&
          !isNotChanged &&
          (accountUpdate.roles || []).some(
            (x) => x.roleValue == KEY.MENU_ADMIN_OFFICE
          ) && (
            <p class="mb0 textError">
              {COMMON_CONSTANT.WARNING_SETTING_CHIEF_ROLE}
            </p>
          )}
      </>
    );
  };
  const onSaveRole = () => {
    // Loading
    openLoadingSpinner();
    if (isNotChanged) {
      showMessage(
        KEY.WARMING,
        "権限情報が変わりません。もう一度確認してください。"
      );
      closeLoadingSpinner();
      return;
    }
    const api = isRequest ? API.requestRole : API.updateRole;
    const body = isRequest ? accountUpdate : [accountUpdate];

    let textSuccess = "";
    let flagText = false;

    if (Array.isArray(body)) {
      flagText = body.some((item) =>
        item.roles.find((i) => i.roleValue === "2")
      );
    } else {
      flagText = body.roles.find((item) => item.roleValue === "2");
    }
    if (roles === rolesConst.OFFICE_ADMIN && flagText) {
      textSuccess = "所長権限追加の件、情報システムにメールを送りました。";
    }
    // Call
    return api(body)
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, textSuccess || COMMON_CONSTANT.UPDATED_USER);
          search_onClick();
          popupUpdateFunc.hide();
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((err) => {
        let msg = MSG_CATCH();
        if (
          !isEmpty(err.response) &&
          isString(err.response.data) &&
          !isEmpty(err.response.data)
        ) {
          msg = err.response.data;
        }
        if (
          !isEmpty(err.response) &&
          isObject(err.response.data) &&
          isString(err.response.data.msg)
        ) {
          msg = err.response.data.msg;
        }
        showMessageError(KEY.ERROR, msg);
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        closeLoadingSpinner();
      });
  };
  useEffect(() => {
    if (!popupUpdate.show) setAccountUpdate(null);
  }, [popupUpdate.show]);
  return (
    <>
      <Tooltip title={lang.EDIT}>
        {/* Update */}
        <Button
          className="mr5 btn-action"
          onClick={() => {
            setAccountUpdate({ ...item });
            popupUpdateFunc.show();
          }}
        >
          <EditFilled />
        </Button>
      </Tooltip>
      {accountUpdate && (
        <Modal
          className={""}
          centered
          title={accountUpdate.userName}
          visible={popupUpdate.show}
          // onOk={handleOk}
          onCancel={popupUpdateFunc.hide}
          width={768}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={onSaveRole}
              disabled={isNotChanged || isInvalid}
            >
              <PlusOutlined />
              {COMMON_CONSTANT.ADD}
            </Button>,
            <Button key="back" onClick={popupUpdateFunc.hide}>
              <CloseOutlined />
              {lang.CANCEL}
            </Button>,
          ]}
        >
          <Form
            layout={"horizonal"}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            className="form-role-setting"
          >
            <Form.Item label="ユーザ名">
              <Text style={{ fontSize: 16 }}>{accountUpdate.userName}</Text>
            </Form.Item>
            <Form.Item label="氏名">
              <Text style={{ fontSize: 16 }}>{accountUpdate.displayName}</Text>
            </Form.Item>
            <Form.Item label="権限設定">{renderRoleSelection()}</Form.Item>
            <Form.Item label={lang.APPROVER}>
              <Checkbox
                checked={accountUpdate.isApprover}
                onClick={() => {
                  setAccountUpdate({
                    ...accountUpdate,
                    isApprover: !accountUpdate.isApprover,
                  });
                }}
              ></Checkbox>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}
