/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Tooltip, Modal, Tag } from "antd";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/inventory";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import {
  getQuerySearch,
  isEmpty,
  MSG_CATCH,
  pushObject2Query,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import List from "./list";
import classGrid from "../../models/control/grid";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";

export default function Warehouses_list() {
  const TYPE_GET = {
    CHANGE_OFFICE: "1",
    DEFAULT: "0",
  };
  const [initRender, setInitRender] = useState(true);
  const objSearch = getQuerySearch();
  const [grid, setGrid] = useState(new classGrid());
  const [loading, setLoading] = useState(true);
  const [warehouses, setWarehouses] = useState(
    new classText(objSearch.inventoryWarehouse || null)
  );
  const [facility, setFacility] = useState(
    new classDdl(objSearch.facilityCode || null)
  );

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const columns = [
    {
      title: lang.WAREHOUSES_CODE,
      dataIndex: "inventoryWarehouseCode",
      width: "20%",
      align: "center",
    },
    {
      title: lang.WAREHOUSES_NAME,
      dataIndex: "inventoryWarehouseName",
      render: (txt, row) => {
        return (
          <Link to={"/WarehouseUpdate/" + row.inventoryWarehouseCode}>
            {txt}
            {row.isNew && (
              <Tag color="magenta" className="ml10">
                {lang.NEW}
              </Tag>
            )}
          </Link>
        );
      },
    },
    {
      title: lang.FACILITY,
      dataIndex: "facilityName",
      // align: "center",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 180,
      render: (row) => {
        let disabledEdit = false;
        let disabledDelete = false;
        if (row.haveItems) {
          disabledDelete = true;
        }
        //
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={disabledEdit} className="mr5 btnEdit">
                <Link
                  to={"/WarehouseUpdate/" + row.inventoryWarehouseCode}
                  className="w100"
                >
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={disabledDelete}
                className="mr5 btnDelete"
                onClick={() => {
                  showMessageDeleteConfirm(row.inventoryWarehouseCode);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const paramsSearch = {
    inventoryWarehouse: isEmpty(warehouses.value)
      ? null
      : warehouses.value.trim(),
    facilityCode: facility.value,
  };

  const onSearch = (offset = 0) => {
    setGrid((preV) => ({ ...preV, loading: true }));

    // Call
    return KBN.getWarehouses({
      ...paramsSearch,
      offset: offset,
      limit: 20,
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            // Init, Click
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            // Load more
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
          pushObject2Query(paramsSearch);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setGrid((preV) => ({ ...preV, loading: false }));
      });
  };
  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid((preV) => ({ ...preV, loading: false, hasMore: false }));
    } else {
      // load more
      onSearch(data.length);
    }
  };
  const clearInput = () => {
    setWarehouses({ ...warehouses, value: null });
    setFacility({ ...facility, value: null });
  };
  const getFacility = (type = TYPE_GET.DEFAULT) => {
    return KBN.getFacility({
      inventoryWarehouseName: warehouses.value,
      facilityCode: facility.value,
    })
      .then((res) => {
        setFacility({
          ...facility,
          value: type === TYPE_GET.CHANGE_OFFICE ? null : facility.value,
          options: res.data.map((v) => {
            v.key = v.facilityCode;
            v.value = v.facilityName;
            return v;
          }),
        });
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (initRender) {
        setInitRender(false);
      } else {
        setFacility({ ...facility, value: null });
        paramsSearch.facilityCode = null;
      }

      setLoading(true);
      Promise.all([
        onSearch(),
        getFacility(!initRender ? TYPE_GET.CHANGE_OFFICE : TYPE_GET.DEFAULT),
      ])
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
          setLoading(false);
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [allowRoute]);

  const showMessageDeleteConfirm = (id) => {
    // Action del
    const funcDel = async () => {
      setLoading(true);
      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        // Del
        await API.deleteWarehouses(id);
        // API Search
        await onSearch();
        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      setLoading(false);
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  return (
    <List
      inputs={[
        {
          name: "倉庫",
          get: warehouses,
          type: KEY.TEXT,
          onChange: (e) => {
            setWarehouses({ ...warehouses, value: e.target.value });
          },
        },
        {
          name: "施設",
          get: facility,
          type: KEY.DDL,
          onChange: (v) => {
            setFacility({ ...facility, value: v });
          },
        },
      ]}
      columns={columns}
      grid={grid}
      rowKey={"inventoryWarehouseCode"}
      actionSearch={() => {
        onSearch();
      }}
      loadMore={onLoadMore}
      clearInput={clearInput}
      linkAdd="/WarehouseUpdate"
      loading={loading}
    />
  );
}
