import React from "react";
import { Input } from "antd";

export default function InputPositiveNumber({
  onChange: _onChange,
  className,
  ...other
}) {
  return (
    <Input
      onChange={(e) => {
        const v = e.target.value;
        if ((v !== null && /^[0-9.]+$/s.exec(v)) || v === "") {
          // Update
          _onChange(e);
        } else {
          // Keep value
          e.target.value = e.target.defaultValue;
          _onChange(e);
        }
      }}
      className={"right " + className}
      {...other}
    />
  );
}
