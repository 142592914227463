import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Select, Table, Space, DatePicker, Button } from "antd";
import { CONFIG, lang, KEY, STYLE } from "../../constants/common.const";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/customerReport";
import KBN from "../../api/backend/kbn";
import Notification from "@src/component/Popup/notification";
import moment from "moment";
import {
  MSG_CATCH,
  enterSearch,
  renderOption,
  statusRes,
  INPUT_DATE,
  INPUT_MONTH,
  INPUT_YEAR,
  showMessage,
} from "../../constants/utils";
// Class
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
// Icon
import {
  SearchOutlined,
  CloudUploadOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function SheetImportReport() {
  // Loading
  let [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  // Input search
  let [ddlReportType, setDdlReportType] = useState(new classDdl());
  let ddlReportTypeEl = useRef(null);
  // Date
  let [lblCheck, setLblCheck] = useState(" ");
  let [txtCheckStartDate, setTxtCheckStartDate] = useState(new classText());
  let txtCheckStartDateEl = useRef(null);
  let [txtCheckEndDate, setTxtCheckEndDate] = useState(new classText());
  let txtCheckEndDateEl = useRef(null);
  // End date
  let [ddlTemplate, setDdlTemplate] = useState(new classDdl());
  let ddlTemplateEl = useRef(null);
  //  Grid
  let [grid, setGrid] = useState(new classGrid());
  let [dataDropDown, updateDataDropdown] = useState([]);
  let hiddenFileInput = useRef(null);
  const [rowClickUpload, updateRowClicked] = useState(null);
  // Column GRID
  const initColumns = [
    {
      title: lang.ID,
      dataIndex: "id",
      width: "5%",
      align: "center",
      render: (txt, row, index) => {
        return grid.data.length - index;
      },
    },
    {
      title: lang.INSPECTION_DATE,
      dataIndex: "checkDate",
      width: "15%",
    },
    {
      title: lang.TEMPLATE_NAME,
      width: "20%",
      dataIndex: "templateTitle",
      className: "white-space",
      align: "center",
    },
    {
      title: lang.SHEET,
      width: "20%",
      dataIndex: "typeCSV",
      align: "center",
      render: (text, row) => {
        return (
          <Select
            style={{ minWidth: "200px" }}
            size={"small"}
            value={row.something}
            onChange={(value) => {
              onChangeSheet(value, row);
            }}
          >
            {renderSheet()}
          </Select>
        );
      },
    },
    {
      title: lang.UPLOAD,
      align: "center",
      width: "20%",
      render: (_, row) => {
        return (
          <>
            <Button
              disabled={!row.typeCSV}
              onClick={() => {
                updateRowClicked(row);
                hiddenFileInput.current.click();
              }}
              className="btnClone"
            >
              <CloudUploadOutlined />
            </Button>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              ref={hiddenFileInput}
              onChange={(e) => onUpload(e)}
              style={{ display: "none" }}
            />
          </>
        );
      },
    },
  ];
  const onChangeSheet = (value, row) => {
    const dataGrid = Object.assign({}, grid);
    dataGrid.data.forEach((rowData) => {
      if (rowData.rowKey === row.rowKey) {
        rowData.typeCSV = value;
      }
    });
    setGrid(dataGrid);
  };
  const onUpload = async (event) => {
    openLoadingSpinner();
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    API.uploadFile(
      ddlTemplate.value,
      rowClickUpload.typeCSV,
      rowClickUpload.startDate,
      formData
    ).then(
      (res) => {
        closeLoadingSpinner();
        if (res.status !== 200) {
          Notification({
            type: "error",
            text: "アップロードに失敗しました",
          });
        } else {
          Notification({
            type: "success",
            text: "アップロードに成功しました",
          });
        }
      },
      (errors) => {
        Notification({
          type: "error",
          text: "アップロードに失敗しました",
        });
      }
    );
  };
  const renderSheet = () => {
    return dataDropDown.map((e, index) => (
      <Select.Option value={e} key={index}>
        {e}
      </Select.Option>
    ));
  };
  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Moment to week
  const getWeek = (value) => {
    if (value) {
      let last = moment(value.format(KEY.DATE_DEFAULT), KEY.DATE_DEFAULT)
        .day(7)
        .format(KEY.DATE_DEFAULT);
      return value.day(1).format(KEY.DATE_DEFAULT) + " - " + last;
    } else {
      return "";
    }
  };

  const onChangeReportType = (v, loadInit = false) => {
    !loadInit && openLoadingSpinner();
    // Reload ddl
    !loadInit && loadDDLChecklistTemplate(v);

    // Update datepicker
    !loadInit && setDdlReportType({ ...ddlReportType, value: v });
    let obj = {};

    switch (v) {
      case "1": // date
        setLblCheck(lang.TARGET_DATE);
        obj = { picker: "date", format: KEY.DATE_DEFAULT, input: INPUT_DATE };
        break;
      case "2": // week
        setLblCheck(lang.TARGET_WEEK);
        obj = {
          picker: "week",
          format: (value) => getWeek(value),
          input: null,
        };
        break;
      case "3": // month
        setLblCheck(lang.TARGET_MONTH);
        obj = { picker: "month", format: KEY.MONTH, input: INPUT_MONTH };
        break;
      case "4": // year
        setLblCheck(lang.TARGET_YEAR);
        obj = { picker: "year", format: KEY.YEAR, input: INPUT_YEAR };
        break;
      case "5": // finance year
        setLblCheck(lang.TARGET_YEAR);
        obj = { picker: "year", format: KEY.YEAR, input: INPUT_YEAR };
        break;
      case "6": // other
        setLblCheck(lang.BEGIN_DATE_TO_END_DATE);
        obj = { picker: "date", format: KEY.DATE_DEFAULT, input: INPUT_DATE };
        break;
      default:
        break;
    }

    setTxtCheckStartDate({ ...txtCheckStartDate, value: moment(new Date()) }); // Clear date pick
    setTxtCheckEndDate({ ...txtCheckEndDate, value: moment(new Date()) }); // Clear date pick
    setTxtCheckStartDate({
      ...txtCheckStartDate,
      ...obj,
      value: "",
      error: false,
    });
    setTxtCheckEndDate({ ...txtCheckEndDate, ...obj, value: "", error: false });
    !loadInit && closeLoadingSpinner();
  };

  const getStartDateString = () => {
    return txtCheckStartDate.value
      ? txtCheckStartDate.value.format(txtCheckStartDate.format)
      : null;
  };
  const getEndDateString = () => {
    return txtCheckEndDate.value
      ? txtCheckEndDate.value.format(txtCheckEndDate.format)
      : null;
  };

  // Search
  const search_onClick = () => {
    const gridObj = Object.assign({}, grid);
    gridObj.data = [];
    setGrid(gridObj);
    if (!validate()) {
      return;
    }
    // Loading
    openLoadingSpinner();

    let dateStart, dateEnd;
    if (String(ddlReportType.value) === "2") {
      // Week
      dateStart = getWeek(txtCheckStartDate.value);
      dateEnd = txtCheckEndDate.value ? getWeek(txtCheckEndDate.value) : null;
    } else {
      dateStart = getStartDateString();
      dateEnd = getEndDateString();
    }
    API.getDropdown(ddlTemplate.value).then((res) => {
      updateDataDropdown(res.data.data);
    });
    // Call
    return API.search({
      reportType: ddlReportType.value,
      startTime: dateStart,
      endTime: dateEnd,
      templateId: ddlTemplate.value,
    })
      .then((res) => {
        if (statusRes(res)) {
          // Update key
          let grid = res.data;
          grid.data = grid.data.map((v, i) => {
            v["rowKey"] = i;
            v["typeCSV"] = null;
            return v;
          });
          setGrid(grid);
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };

  useEffect(() => {
    if (ddlTemplate.value) {
      formSearch.setFieldsValue({ ddlTemplateEl: null });
      setDdlTemplate({ ...ddlTemplate, value: null });
    }
  }, [allowRoute]);
  // Validate search
  const validate = () => {
    ddlReportType = { ...ddlReportType, error: false };
    txtCheckStartDate = { ...txtCheckStartDate, error: false };
    txtCheckEndDate = { ...txtCheckEndDate, error: false };
    ddlTemplate = { ...ddlTemplate, error: false };

    let flagErr = false;

    if (ddlTemplate.value === "") {
      flagErr = true;
      // Border red
      ddlTemplate = { ...ddlTemplate, error: true };
      ddlTemplateEl.current.focus();
    }
    if (txtCheckStartDate.value === null || txtCheckStartDate.value === "") {
      flagErr = true;
      // Border red
      txtCheckStartDate = { ...txtCheckStartDate, error: true };
      txtCheckStartDateEl.current.focus();
    }
    if (ddlReportType.value === "") {
      flagErr = true;
      // Border red
      ddlReportType = { ...ddlReportType, error: true };
      ddlReportTypeEl.current.focus();
    }

    // Update state
    setDdlTemplate(ddlTemplate);
    setTxtCheckEndDate(txtCheckEndDate);
    setTxtCheckStartDate(txtCheckStartDate);
    setDdlReportType(ddlReportType);

    return !flagErr;
  };

  // clearInputSearch
  const clearInputSearch = async () => {
    onChangeReportType(ddlReportType.options[0].key);
    setGrid({ ...grid, data: [], total: 0 });
    formSearch.resetFields();
  };

  const loadDDLChecklistTemplate = (reportTypeValue = 1) => {
    return KBN.getChecklistTemplate({ reportType: reportTypeValue }).then(
      (res) => {
        let listTemplateCheckList = res ? res.data.data : [];
        setDdlTemplate({
          ...ddlTemplate,
          value: "",
          options: listTemplateCheckList,
          error: false,
        });
      }
    );
  };

  // Init
  useEffect(() => {
    // Open loading
    openLoadingSpinner();
    setGrid({ ...grid, data: [] });
    // Load pulldown
    Promise.all([KBN.getTemplateReportType(), loadDDLChecklistTemplate()])
      .then((res) => {
        const listTemplateReportType = res[0] ? res[0].data.data : [];
        // Set pulldown
        setDdlReportType({
          ...ddlReportType,
          options: listTemplateReportType,
          value: listTemplateReportType[0].key,
        });
        onChangeReportType(listTemplateReportType[0].key, true);
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowRoute]);

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.IMPORT_CSV} />
      {/* Group input */}
      <GroupItem>
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          initialValues={{ ddlReportType: "1" }}
        >
          <Row gutter={[16, 8]}>
            <Col span="8">
              {/* 1 */}
              <Form.Item
                name="ddlReportType"
                className="rowNotMargin"
                label={lang.CATEGORY}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={lang.CATEGORY}
                  value={ddlReportType.value}
                  ref={ddlReportTypeEl}
                  className={ddlReportType.error ? STYLE.BORDER_RED : ""}
                  onChange={(v) => {
                    onChangeReportType(v);
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlReportType.options, false)}
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              {/* 2 - date */}
              <Form.Item
                name="txtCheckStartDate"
                className="rowNotMargin"
                label={lblCheck}
                rules={[{ required: true }]}
              >
                <Row>
                  <Col span={11}>
                    <DatePicker
                      format={txtCheckStartDate.format || KEY.DATE_DEFAULT}
                      ref={txtCheckStartDateEl}
                      value={txtCheckStartDate.value}
                      picker={txtCheckStartDate.picker}
                      disabledDate={(d) =>
                        !d || d.isAfter(txtCheckEndDate.value)
                      }
                      className={
                        "w100 " +
                        (txtCheckStartDate.error ? STYLE.BORDER_RED : "")
                      }
                      onChange={(v, dateString) => {
                        setTxtCheckStartDate({
                          ...txtCheckStartDate,
                          value: v,
                        });
                      }}
                      {...txtCheckStartDate.input}
                    />
                  </Col>
                  <Col span={2} className="center" style={{ padding: "7px" }}>
                    ～
                  </Col>
                  <Col span={11}>
                    <DatePicker
                      format={txtCheckEndDate.format || KEY.DATE_DEFAULT}
                      ref={txtCheckEndDateEl}
                      value={txtCheckEndDate.value}
                      picker={txtCheckEndDate.picker}
                      disabledDate={(d) =>
                        !d || d.isBefore(txtCheckStartDate.value)
                      }
                      className={
                        "w100 " +
                        (txtCheckEndDate.error ? STYLE.BORDER_RED : "")
                      }
                      onChange={(v, dateString) => {
                        setTxtCheckEndDate({ ...txtCheckEndDate, value: v });
                      }}
                      {...txtCheckEndDate.input}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span="8">
              {/* 3 site */}
              <Form.Item
                name="ddlTemplateEl"
                className="rowNotMargin"
                label={lang.TEMPLATE_NAME}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={lang.TEMPLATE_NAME}
                  ref={ddlTemplateEl}
                  value={ddlTemplate.value}
                  className={ddlTemplate.error ? STYLE.BORDER_RED : ""}
                  onChange={(v) => {
                    setDdlTemplate({ ...ddlTemplate, value: v });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlTemplate.options)}
                </Select>
              </Form.Item>
            </Col>
            {/* Button Search/Clear */}
            <Col span={24} className="right">
              <Button
                className="buttonPC button--info wAuto"
                onClick={search_onClick}
              >
                <SearchOutlined className="" />
                {lang.SEARCH}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={clearInputSearch}
              >
                <ClearOutlined />
                {lang.CLEAR}
              </Button>
            </Col>
          </Row>
        </Form>
      </GroupItem>

      <GroupItem>
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}{" "}
              <span>
                {grid.total || 0}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey="rowKey"
          className="gridSearch tblRole mt10"
          columns={initColumns}
          dataSource={grid.data}
          pagination={CONFIG.paging}
        />
      </GroupItem>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default SheetImportReport;
