export const chartLine = {
  chart: {
    type: "spline",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
    labels: { overflow: "allow" },
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },

  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: true,
        radius: 2.5,
      },
      connectNulls: true,
    },
  },
  series: [
    {
      name: "",
      data: [],
    },
  ],
};
