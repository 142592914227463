import {
  CloseCircleOutlined,
  EditOutlined,
  ScheduleOutlined,
  RollbackOutlined,
  HistoryOutlined,
  SaveOutlined,
  InfoCircleOutlined,
  CloudDownloadOutlined,
  InfoCircleFilled,
  FileImageOutlined,
  FileExcelOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileOutlined,
  CopyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  VideoTime,
  ArchiveTick,
  ArchiveBook,
  FolderAdd,
  Convertshape,
} from "iconsax-react";
import TextArea from "antd/lib/input/TextArea";
import {
  Empty,
  Button,
  Col,
  Modal,
  Row,
  Table,
  Tooltip,
  Descriptions,
  Collapse,
  Image,
  Timeline,
  Typography,
  Drawer,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import classPopup from "../../models/control/popup";
import classText from "../../models/control/text";
import Loading from "../../component/Desktop/loading/loading";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import {
  isImage,
  MSG_CATCH,
  showMessage,
  showMessageChange,
} from "../../constants/utils";
import API from "../../api/backend/machineManagement";
import moment from "moment";
import BoxContent from "@src/component/Desktop/content";
import FooterPage from "../../component/footer/footerPage";
import "./machine.scss";
import { isEmpty, groupBy } from "lodash";
import { FormInput } from "./add1";
import { checkRoleScreen } from "../../utils/roles";
import { useSelector } from "react-redux";
import exportPdfInfoMachine from "./exportPdfInfoMachine";
import rolesConst from "../../routers/roles/roles.const";

const { Panel } = Collapse;

const getIconByFileType = (name) => {
  if (isImage(name)) return <FileImageOutlined className="first-icon" />;
  const splitArray = name.split(".");
  const fileType = splitArray[splitArray.length - 1];

  switch (fileType) {
    case "zip":
    case "rar":
      return <FileZipOutlined className="first-icon" />;
    case "xlsx":
    case "xlsm":
    case "xls":
    case "xml":
      return <FileExcelOutlined className="first-icon" />;
    case "txt":
    case "csv":
      return <FileTextOutlined className="first-icon" />;
    case "pdf":
      return <FilePdfOutlined className="first-icon" />;
    case "docx":
    case "doc":
      return <FileWordOutlined className="first-icon" />;
    default:
      return <FileOutlined className="first-icon" />;
  }
};

export function FileAttachment({ item, index, noIcon = false }) {
  const name = item.attachFileName.split(".");
  const fileType = name.pop();
  return (
    <li key={index} className="machine-file">
      {!noIcon && getIconByFileType(item.attachFileName)}
      <a
        href={item.attachFileUrl}
        title={item.attachFileName}
        style={{ display: "flex" }}
      >
        <p className="text-1line mb0">{name}</p>
        <span>.{fileType}</span>
      </a>
    </li>
  );
}

function ViewMachine({ isEndUser }) {
  const history = useHistory();
  const { id, version } = useParams();
  const [popupAttach, setPopupAttach] = useState(new classPopup());
  const [open, setOpen] = useState(false);
  const [timeline_detail, setTimeline_detail] = useState(null);
  const showDrawer = () => setOpen(true);
  const showDrawerMaintenance = (id) => setOpen(id);
  const onClose = () => setOpen(false);
  const location = useLocation();
  const { allowRoute, isLeader } = useSelector((state) => state.auth);
  const repairFrequencyOptions = [
    { value: null, label: "セットなし" },
    { value: 3, label: "3か月" },
    { value: 6, label: "6か月" },
    { value: 12, label: "1年" },
  ];

  const initColumnsProject = [
    {
      title: lang.INSPECTION_TIME_NAME,
      dataIndex: "inspectionTime",
      key: "inspectionTime",
      align: "left",
      width: "20%",
    },
    {
      title: (
        <span>
          管理値
          <br />
          (下限値)
        </span>
      ),
      dataIndex: "controlValueLow",
      key: "controlValueLow",
      align: "center",
      width: "9%",
    },
    {
      title: (
        <span>
          管理値
          <br />
          (上限値)
        </span>
      ),
      dataIndex: "controlValueUpper",
      key: "controlValueUpper",
      align: "center",
      width: "9%",
    },
    {
      title: (
        <span>
          運転めやす
          <br />
          (下限値)
        </span>
      ),
      dataIndex: "easyDriveLow",
      key: "easyDriveLow",
      align: "center",
      width: "9%",
    },
    {
      title: (
        <span>
          運転めやす
          <br />
          (上限値)
        </span>
      ),
      dataIndex: "easyDriveUpper",
      key: "easyDriveUpper",
      align: "center",
      width: "9%",
    },
    {
      title: lang.INPUT_FORMAT,
      dataIndex: "inputFormat",
      key: "inputFormat",
      align: "center",
      width: "10%",
    },
    {
      title: lang.UNIT,
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: "10%",
    },
    {
      title: lang.SELECTION,
      dataIndex: "selection",
      key: "selection",
      align: "left",
      width: "20%",
      render: (_) => {
        if (!_) return <></>;
        return (
          <ul className="mb0" style={{ paddingLeft: "24px" }}>
            {_.split(";").map((txt, index) => {
              return <li key={index}>{txt}</li>;
            })}
          </ul>
        );
      },
    },
    {
      title: (
        <span>
          点検
          <br />
          頻度
        </span>
      ),
      dataIndex: "InspectionFryquency",
      key: "InspectionFryquency",
      align: "center",
      width: "5%",
    },
  ];
  const initColumnsTableInventoryLinkage = [
    {
      title: lang.INVENTORY_ITEM,
      dataIndex: "inventoryItem",
      key: "inventoryItem",
      align: "left",
    },
    {
      title: lang.WAREHOUSES_NAME,
      dataIndex: "warehouseName",
      key: "warehouseName",
      align: "left",
    },
    {
      title: lang.TYPE_MACHINE,
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: lang.QUANTITY_LEFT_IN_WAREHOUSE,
      dataIndex: "quantityLeftInWarehouse",
      key: "quantityLeftInWarehouse",
      align: "center",
    },
    {
      title: lang.USED_QUANTITY,
      dataIndex: "usedQuantity",
      key: "usedQuantity",
      align: "center",
    },
    {
      title: "",
      dataIndex: "delete",
      width: "120px",
      render: (_, row) => {
        return (
          <Button
            disabled={isEndUser}
            type="primary"
            onClick={() =>
              history.push(`/InventoryData?inventory=${row.inventoryItem}`)
            }
          >
            {lang.QUANTITY_MACHINE_STOCK}
          </Button>
        );
      },
    },
  ];
  const column_maintaince_history = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "50px",
      render: (item, row, index) => index + 1,
    },
    {
      title: lang.MAINTAINCE_DATE,
      dataIndex: "dateImplementation",
      key: "dateImplementation",
      width: "120px",
      render: (item) => moment(item * 1000).format("YYYY-MM-DD"),
    },
    {
      title: lang.MAINTAINCE_DIVISION,
      dataIndex: "division",
      key: "division",
      width: "120px",
    },
    {
      title: lang.MAINTAINCE_PIC,
      key: "contractorValue",
      width: "120px",
      dataIndex: "contractorValue",
    },
    {
      title: lang.MAINTAINCE_OVERVIEW,
      key: "overview",
      dataIndex: "overview",
      width: "20%",
      align: "left",
    },
    {
      title: lang.MAINTAINCE_WORK_CONTENT,
      key: "workContent",
      dataIndex: "workContent",
      align: "left",
    },
    {
      title: lang.ATTACH,
      dataIndex: "listAttachment",
      key: "listAttachment",
      width: "20%",
      align: "left",
      render: (listAttachment, row) => (
        <ul className="mb0" style={{ listStyle: "none", paddingLeft: "40px" }}>
          {listAttachment.map((item, index) => {
            return <FileAttachment item={item} index={index} key={index} />;
          })}
        </ul>
      ),
    },
  ];
  const column_plan_maintaince_history = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "50px",
      render: (item, row, index) => index + 1,
    },
    {
      title: lang.MAINTAINCE_DATE,
      dataIndex: "dateImplementation",
      key: "dateImplementation",
      width: "120px",
      render: (item) => moment(item * 1000).format("YYYY-MM-DD"),
    },
    {
      title: lang.MAINTAINCE_DIVISION,
      dataIndex: "division",
      key: "division",
      width: "120px",
    },
    {
      title: lang.MAINTAINCE_PIC,
      key: "contractorValue",
      width: "120px",
      dataIndex: "contractorValue",
    },
    {
      title: lang.MAINTAINCE_OVERVIEW,
      key: "overview",
      dataIndex: "overview",
      width: "20%",
      align: "left",
    },
    {
      title: lang.MAINTAINCE_WORK_CONTENT,
      key: "workContent",
      dataIndex: "workContent",
      align: "left",
    },
    {
      title: "修理頻度",
      key: "repairFrequency",
      dataIndex: "repairFrequency",
      width: "20%",
      render: (item) => {
        const selectedItem = repairFrequencyOptions.find(
          (x) => x.value === item
        );
        const label = selectedItem ? selectedItem.label : "セットなし";
        return label;
      },
    },
  ];

  const [showLoading, setShowLoading] = useState(false);
  const [machineData, setMachineData] = useState({});

  const getDetail = async () => {
    try {
      const res = await API.getMachine(id, version);
      const data = res.data;
      if (!res.data) {
        history.push("/machineManagement");
        return;
      }
      setMachineData(data);
    } catch (err) {
      history.push("/machineManagement");
      throw err;
    }
  };

  //Fetch machine for update
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (!id) return;
      setShowLoading(true);
      try {
        Promise.all([getDetail()]);
      } catch (e) {
        showMessage(KEY.ERROR, MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    }
  }, [allowRoute]);

  const data = {
    ...machineData,
    nameMachine: machineData.machineForSiteOfficeName,
    nameRoom: machineData.roomName,
    nameFacility: machineData.facilityName,
    nameFloor: machineData.floorName,
    nameBuilding: machineData.buildingName,
    nameProcess: machineData.processName,
    model: machineData.modelNumber,
    seriNumber: machineData.sn,
    machineStatus: machineData.status,
    setDate: machineData.applyDate
      ? moment(machineData.applyDate).format("YYYY/MM/DD")
      : null,
    dateRegisterMachine: machineData.createDatetime
      ? moment(machineData.createDatetime * 1000).format("YYYY/MM/DD")
      : null,
    specification: machineData.specification,
    serviceLife: machineData.serviceLife,
    drawingNumber: machineData.drawingLineItemNumber,
    made: machineData.saiban,
    serviceLifeInHouse: machineData.enduranceYear,
    yearOfManufacture: machineData.yearOfManufacture,
    durability: machineData.serviceTime,
    replacementDisposalYear: machineData.replacementDate
      ? moment(machineData.replacementDate).format("YYYY/MM/DD")
      : null,
    condition: machineData.condition,
    remark: machineData.note,
    file: machineData.listAttachment,
    use: machineData.use,
    imageUrl: machineData.imageUrl,
  };

  const dataTableProject = (machineData.checkItems || [])
    .filter((checkItem) => Object.values(checkItem).some((x) => !!x))
    .map((item) => ({
      ...item,
      key: item.checkItemCode,
      inspectionTime: item.checkItemName,
      controlValueLow: item.lowerControlValue,
      controlValueUpper: item.upperControlValue,
      easyDriveLow: item.normalLowerControlValue,
      easyDriveUpper: item.normalUpperControlValue,
      inputFormat: item.mode,
      unit: item.unit,
      selection: item.choices,
      InspectionFryquency: item.inspectionTiming,
    }));

  const dataTableInventoryLinkage = (machineData.listInventory || []).map(
    (inventory, index) => {
      return {
        key: index,
        inventoryItem: inventory.inventoryItemName,
        warehouseName: inventory.inventoryWarehouseName,
        type: inventory.inventoryType,
        quantityLeftInWarehouse: inventory.stockQuantity,
        usedQuantity: inventory.usedQuantity,
      };
    }
  );

  const dataTableChangeLog = (machineData.listHistory || []).map(
    (history, index) => {
      return {
        ...history,
        key: index,
        changeDate: moment(history.updateDatetime * 1000).format(
          "YYYY/MM/DD HH:mm"
        ),
        updateContent: history.updateDetail,
        updater: history.userUpdate,
        listAttachment: history.listAttachment
          .filter((x) => x.type == 2)
          .map((item, index) => {
            return {
              ...item,
              ItemOrder: 1,
              FileName: item.attachFileName,
              DotName: "PNG",
              Description: item.description,
              attachmentUrl: item.attachFileUrl,
            };
          }),
      };
    }
  );
  // .sort((a, b) => moment(b.updateDatetime * 1000).diff(moment(a.updateDatetime * 1000)));
  const data_maintenance_history = (data.listMaintenance || [])
    .sort((a, b) => a.dateImplementation > b.dateImplementation ? 1 : -1)
    .map((x) => ({
      ...x,
      year: moment(x.dateImplementation * 1000).year(),
    }));
  const timeline_maintenance_history = groupBy(
    data_maintenance_history,
    "year"
  );
  const machine_information_display = [
    {
      title: lang.DATE_REGISTER_MACHINE,
      content: data.dateRegisterMachine,
    },
    {
      title: lang.MACHINE_NAME,
      content: data.nameMachine,
    },
    {
      title: lang.NAME_FACILITY,
      content: data.nameFacility,
      inlineDisplay: true,
    },
    {
      title: lang.NAME_BUILDING,
      content: data.nameBuilding,
      inlineDisplay: true,
    },
    {
      title: lang.NAME_FLOOR,
      content: data.nameFloor,
      inlineDisplay: true,
    },
    {
      title: lang.NAME_ROOM,
      content: data.nameRoom,
      inlineDisplay: true,
    },
    {
      title: lang.NAME_PROCESS,
      content: data.nameProcess,
    },
    {
      title: lang.MACHINE_MANUFACTURE_NAME,
      content: data.manufactureName,
      inlineDisplay: true,
    },
    {
      title: lang.MODEL,
      content: data.model,
      inlineDisplay: true,
    },
    {
      title: lang.SERI_NUMBER,
      content: data.seriNumber,
      inlineDisplay: true,
    },
    {
      title: lang.STATUS_MACHINE,
      content: data.machineStatus,
      inlineDisplay: true,
    },
    {
      title: lang.SET_TIME,
      content: data.setDate,
      inlineDisplay: true,
    },
    {
      title: lang.REPLACEMENT_DISPOSAL_YEAR,
      content: data.replacementDisposalYear,
      inlineDisplay: true,
    },
    {
      title: lang.SPECIFICATIONS,
      content: data.specification,
    },
    {
      title: lang.SERVICE_LIFE,
      content: data.serviceLife,
      inlineDisplay: true,
    },
    {
      title: lang.YEAR_OF_MANUFACTURE,
      content: data.yearOfManufacture,
      inlineDisplay: true,
    },
    {
      title: lang.SERVICE_LIFE_IN_HOUSE,
      content: data.serviceLifeInHouse,
      inlineDisplay: true,
    },
    {
      title: lang.DUABILITY,
      content: data.durability,
      inlineDisplay: true,
    },
    {
      title: lang.DRAWING_NUMBER,
      content: data.drawingNumber,
      inlineDisplay: true,
    },
    {
      title: lang.MADE,
      content: data.made,
      inlineDisplay: true,
    },
    {
      title: lang.USE,
      content: data.use,
      inlineDisplay: true,
    },
    {
      title: lang.CONDITION,
      content: data.condition,
      inlineDisplay: true,
    },
    {
      title: lang.REMARK,
      content: data.remark,
    },
  ];

  const addition_table_display = [
    {
      title: (
        <>
          <ArchiveTick variant="Bold" className="first-icon" />
          {lang.INSPECTION_ITEM}
        </>
      ),
      titleEmpty: (
        <>
          <ArchiveTick variant="Bold" className="first-icon" />
          {lang.INSPECTION_ITEM} : (無し)
        </>
      ),
      dataSource: dataTableProject,
      column: initColumnsProject,
      isDisplayInHistory: true,
    },
    {
      title: (
        <>
          <ArchiveBook variant="Bold" className="first-icon" />
          {lang.INVENTORY_LINKAGE}
        </>
      ),
      titleEmpty: (
        <>
          <ArchiveBook variant="Bold" className="first-icon" />
          {lang.INVENTORY_LINKAGE} : (無し)
        </>
      ),
      dataSource: dataTableInventoryLinkage,
      column: initColumnsTableInventoryLinkage,
    },
  ];
  const [updateContent, setUpdateContent] = useState(new classText());
  const [popupUpdate, setPopupUpdate] = useState(new classPopup());

  function showPopupUpdate() {
    // Show popup
    setPopupUpdate({ ...popupUpdate, show: true });
  }
  function hidePopupUpdate() {
    // Show popup
    setPopupUpdate({ ...popupUpdate, show: false });
  }

  const genExtra = () => {
    return (
      <button
        style={{
          backgroundColor: "rgb(241, 105, 0)",
          color: "white",
          border: "none",
          height: "unset",
          padding: "0 auto",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          showDrawerMaintenance(1);
        }}
      >
        タイムライン
      </button>
    );
  };

  const validate = () => {
    let flagErr = false;

    if (popupUpdate.show) {
      if (updateContent.value === null || updateContent.value === "") {
        flagErr = true;
        setUpdateContent({ ...updateContent, error: true });
      }
    }

    return !flagErr;
  };
  let dataTableMaintenanceHistory = data_maintenance_history.map(
    (item, index) =>
      Object.values({
        no: index + 1,
        dateImplementation: item.dateImplementation
          ? moment(item.dateImplementation * 1000).format("YYYY-MM-DD")
          : "",
        division: item.division,
        contractorValue: item.contractorValue,
        overview: item.overview,
        workContent: item.workContent,
        listAttachment: item.listAttachment,
      }).map((item) => (!item ? "" : item))
  );
  dataTableMaintenanceHistory.unshift(
    Object.values(column_maintaince_history.map((item) => item.title))
  );
  const getDataTableAdditionDisplay = (columns, dataSource) => {
    const listDataIndex = columns.map((item) => item.dataIndex);
    const dataColumn = dataSource.map((dataItem) => {
      const newObject = {};
      listDataIndex.forEach((key) => {
        newObject[key] = dataItem[key];
      });
      return newObject;
    });
    const dataColumnContent = dataColumn.map((columnItem) =>
      Object.values(columnItem).map((item) => (!item ? "" : item))
    );
    return dataColumnContent;
  };

  //  Data Body tabe project / print pdf
  const dataBodyTableProject = [
    [
      "点検項目名",
      "管理値(下限値)",
      "管理値(上限値)",
      "運転めやす(下限値)",
      "運転めやす(上限値)",
      "入力形式",
      "単位",
      "選択",
      "点検頻度",
    ],
    ...getDataTableAdditionDisplay(initColumnsProject, dataTableProject),
  ];

  //  Data Body tabe inventory / print pdf
  const dataBodyTableInventoryLinkage = [
    initColumnsTableInventoryLinkage
      .slice(0, initColumnsTableInventoryLinkage.length - 1)
      .map((item) => item.title),
    ...getDataTableAdditionDisplay(
      initColumnsTableInventoryLinkage.slice(
        0,
        initColumnsTableInventoryLinkage.length - 1
      ),
      dataTableInventoryLinkage
    ),
  ];

  // Data Table Plan Repair // print pdf
  const dataBodyTableRepairPlan = [
    column_plan_maintaince_history.map((item) => item.title),
    ...getDataTableAdditionDisplay(
      column_plan_maintaince_history,
      data.listPlanMaintenance
        ? data.listPlanMaintenance.map((item, index) => {
            return {
              ...item,
              no: index + 1,
              repairFrequency:
                item.repairFrequency === null
                  ? "セットなし"
                  : repairFrequencyOptions.find(
                      (i) => i.value === item.repairFrequency
                    ).label,
              dateImplementation: item.dateImplementation
                ? moment(item.dateImplementation * 1000).format("YYYY-MM-DD")
                : "",
            };
          })
        : []
    ),
  ];

  return (
    <div className="des-content machine-content">
      <TitlePage name={lang.MACHINE_LEDGER_INFOMATION} />
      <Loading show={showLoading}></Loading>

      <Row
        gutter={0}
        style={{ margin: "0px auto", padding: "0px 8px", maxWidth: "1400px" }}
      >
        <Col lg={8} md={24} xs={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <div className="avatar">
              <Image width={"100%"} src={data.imageUrl || "/img/default.png"} />
            </div>
          </BoxContent>

          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            {data.file && data.file.length > 0 && (
              <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
                <Panel
                  header={
                    <>
                      <FolderAdd variant="Bold" className="first-icon" />
                      {lang.ATTACH_FILE}
                    </>
                  }
                  key="1"
                >
                  <ul
                    style={{
                      listStyle: "none",
                      paddingLeft: "40px",
                      height: "205px",
                      overflowY: "auto",
                    }}
                  >
                    {data.file.map((item, index) => (
                      <FileAttachment key={index} item={item} index={index} />
                    ))}
                  </ul>
                </Panel>
              </Collapse>
            )}
            {!(data.file && data.file.length > 0) && (
              <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
                {/* <Panel header={<><FolderAdd variant="Bold" className="first-icon" />{lang.ATTACH_FILE + ' : (無し)'}</>} showArrow={false} /> */}
                <Panel
                  header={
                    <>
                      <FolderAdd variant="Bold" className="first-icon" />
                      {lang.ATTACH_FILE + " : (無し)"}
                    </>
                  }
                  key="1"
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ height: 156, paddingTop: 56 }}
                  />
                </Panel>
              </Collapse>
            )}
          </BoxContent>
        </Col>
        <Col lg={16} md={24} xs={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <InfoCircleFilled className="first-icon" /> 機器情報
                  </>
                }
                key="1"
              >
                <Descriptions
                  size="small"
                  column={2}
                  bordered
                  style={{ overflow: "auto", height: "550px" }}
                >
                  {machine_information_display.map((info, index) => {
                    return (
                      <Descriptions.Item
                        span={info.inlineDisplay ? 1 : 2}
                        key={index}
                        label={info.title}
                        labelStyle={{
                          width: "20%",
                          fontWeight: "bold",
                          padding: "8px 8px",
                        }}
                        contentStyle={{
                          padding: "8px 8px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {info.content || "-"}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
        <Col span={24}>
          {addition_table_display.map((item, index) => {
            if (!item.isDisplayInHistory && version) return <></>;
            return (
              <BoxContent key={index} style={{ padding: "8px", margin: "8px" }}>
                {!isEmpty(item.dataSource) && (
                  <Collapse
                    expandIconPosition="right"
                    key={index}
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={item.title} key="1">
                      <Table
                        scroll={{ y: "300px", x: "768px" }}
                        pagination={false}
                        dataSource={item.dataSource}
                        className="tableProject 111"
                        columns={item.column}
                        style={{ background: "transparent !important" }}
                        bordered
                        size="small"
                      />
                    </Panel>
                  </Collapse>
                )}
                {isEmpty(item.dataSource) && (
                  <Collapse collapsible="disabled">
                    <Panel header={item.titleEmpty} showArrow={false} />
                  </Collapse>
                )}
              </BoxContent>
            );
          })}
        </Col>
        <Col span={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            {!isEmpty(data_maintenance_history) && (
              <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
                <Panel
                  header={
                    <>
                      <VideoTime className="first-icon" variant="Bold" />
                      {lang.MAINTAINCE_HISTORY}
                    </>
                  }
                  key="1"
                  // extra={genExtra()}
                >
                  <Table
                    scroll={{ y: "300px", x: "768px" }}
                    pagination={false}
                    dataSource={data_maintenance_history}
                    className="tableProject"
                    columns={column_maintaince_history}
                    style={{
                      background: "transparent !important",
                      whiteSpace: "pre-wrap",
                    }}
                    bordered
                    size="small"
                  />
                </Panel>
              </Collapse>
            )}
            {isEmpty(data_maintenance_history) && (
              <Collapse collapsible="disabled">
                <Panel
                  header={
                    <>
                      <VideoTime className="first-icon" variant="Bold" />
                      {lang.MAINTAINCE_HISTORY + " : (無し)"}
                    </>
                  }
                  showArrow={false}
                />
              </Collapse>
            )}
          </BoxContent>
        </Col>
        <Col span={24}>
          <BoxContent style={{ padding: "8px", margin: "8px" }}>
            {!isEmpty(data.listPlanMaintenance) && (
              <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
                <Panel
                  header={
                    <>
                      <Convertshape className="first-icon" variant="Bold" />
                      {lang.REPAIR_PLAN}
                    </>
                  }
                  key="1"
                  // extra={genExtra()}
                >
                  <Table
                    scroll={{ y: "300px", x: "768px" }}
                    pagination={false}
                    dataSource={data.listPlanMaintenance}
                    className="tableProject"
                    columns={column_plan_maintaince_history}
                    style={{
                      background: "transparent !important",
                      whiteSpace: "pre-wrap",
                    }}
                    bordered
                    size="small"
                  />
                </Panel>
              </Collapse>
            )}
            {isEmpty(data.listPlanMaintenance) && (
              <Collapse collapsible="disabled">
                <Panel
                  header={
                    <>
                      <Convertshape className="first-icon" variant="Bold" />
                      {lang.REPAIR_PLAN + " : (無し)"}
                    </>
                  }
                  showArrow={false}
                />
              </Collapse>
            )}
          </BoxContent>
        </Col>
      </Row>

      {!version && (
        <FooterPage>
          <Row justify="space-between">
            <Col></Col>
            <Col>
              {isEndUser && (
                <Button
                  onClick={() =>
                    exportPdfInfoMachine(
                      dataTableMaintenanceHistory,
                      machine_information_display,
                      dataBodyTableProject,
                      dataBodyTableInventoryLinkage,
                      dataBodyTableRepairPlan,
                      data.imageUrl || "/img/default.png",
                      data.file,
                      `機器台帳情報_${machineData.machineForSiteOfficeCode}`
                    )
                  }
                  className="buttonPC button--outline --todo-- wAuto mr10"
                >
                  <CloudDownloadOutlined
                    style={{
                      color: "#f50",
                    }}
                  />
                  {lang.DOWNLOAD_PDF}
                </Button>
              )}

              <Button
                type="primary"
                className="buttonPC button--info wAuto mr10"
                onClick={showDrawer}
              >
                <HistoryOutlined />
                {lang.CHANGE_LOG}
              </Button>
              {!isEndUser && isLeader && (
                <Button
                  type="primary"
                  className="buttonPC button--info wAuto mr10"
                  onClick={() => history.push(`/AddMachine?clone_id=${id}`)}
                >
                  <CopyOutlined />
                  コピー
                </Button>
              )}
              {!isEndUser && isLeader && (
                <Button
                  className="buttonPC button--info wAuto mr10"
                  onClick={() => history.push(`/EditMachine/${id}`)}
                >
                  <EditOutlined />
                  {lang.EDIT}
                </Button>
              )}
              <Button
                className="buttonPC button--outline --todo-- wAuto"
                onClick={() => history.goBack()}
              >
                <RollbackOutlined />
                {lang.BACK}
              </Button>
            </Col>
          </Row>
        </FooterPage>
      )}

      <Drawer
        title={lang.CHANGE_LOG}
        width={335}
        placement="right"
        onClose={onClose}
        open={open === true}
        getContainer={false}
        visible={open === true}
      >
        <Timeline mode={"left"}>
          {dataTableChangeLog.map((item, index) => {
            const label = !isEndUser ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/HistoryUpdateMachine/${id}/${item.recordVersion}`}
              >
                <Typography.Text>{item.changeDate}</Typography.Text>
                <br />
              </a>
            ) : (
              <>
                <Typography.Text>{item.changeDate}</Typography.Text>
                <br />
              </>
            );
            const dot = !isEndUser ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/HistoryUpdateMachine/${id}/${item.recordVersion}`}
              >
                <HistoryOutlined />
              </a>
            ) : (
              <HistoryOutlined />
            );
            return (
              <Timeline.Item
                key={index}
                label={label}
                dot={dot}
                style={{ cursor: "pointer" }}
              >
                {!isEndUser ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/HistoryUpdateMachine/${id}/${item.recordVersion}`}
                  >
                    <Typography.Title level={5}>
                      {item.updater}
                    </Typography.Title>
                    <Typography.Text>{item.updateContent}</Typography.Text>
                  </a>
                ) : (
                  <>
                    <Typography.Title level={5}>
                      {item.updater}
                    </Typography.Title>
                    <Typography.Text>{item.updateContent}</Typography.Text>
                  </>
                )}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Drawer>
      <Drawer
        title={lang.MAINTAINCE_HISTORY}
        width={350}
        placement="right"
        onClose={onClose}
        open={open === 1}
        getContainer={false}
        visible={open === 1}
      >
        <Timeline mode={"left"}>
          {Object.keys(timeline_maintenance_history).map((year, indexYear) => {
            const dot = <CheckCircleOutlined />;
            timeline_maintenance_history[year].unshift({
              dateImplementation: year,
            });
            return timeline_maintenance_history[year].map((item, index) => {
              if (index == 0) {
                return (
                  <Timeline.Item
                    key={index}
                    label={
                      <Typography.Title level={5}>{year}年</Typography.Title>
                    }
                  >
                    <Typography.Text>&nbsp;</Typography.Text>
                  </Timeline.Item>
                );
              }
              const label = (
                <Typography.Text>
                  {moment(item.dateImplementation * 1000).format("YYYY/MM/DD")}
                </Typography.Text>
              );
              return (
                <Timeline.Item
                  key={index}
                  label={label || ""}
                  dot={
                    <CheckCircleOutlined
                      style={
                        indexYear == 0 && index == 1
                          ? { color: "#1890ff" }
                          : { color: "rgb(255, 85, 0)" }
                      }
                    />
                  }
                  onClick={() => {
                    setTimeline_detail(item);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text>
                    {item.contractorValue || "(無し)"}
                  </Typography.Text>
                </Timeline.Item>
              );
            });
          })}
        </Timeline>
        <Drawer
          title={
            timeline_detail &&
            timeline_detail.dateImplementation &&
            moment(timeline_detail.dateImplementation * 1000).format(
              "YYYY/MM/DD"
            )
          }
          width={335}
          onClose={() => setTimeline_detail(null)}
          open={timeline_detail}
          visible={timeline_detail}
          bodyStyle={{ padding: 0 }}
        >
          {timeline_detail && (
            <Descriptions size="small" column={1} bordered>
              {column_maintaince_history.map((item, index) => {
                if (item.dataIndex === "no") {
                  return <></>;
                }
                let content = timeline_detail[item.dataIndex] || "-";
                if (item.dataIndex === "dateImplementation") {
                  content = moment(
                    timeline_detail.dateImplementation * 1000
                  ).format("YYYY/MM/DD");
                }
                if (item.dataIndex === "listAttachment") {
                  content = (
                    <ul
                      className="mb0"
                      style={{ listStyle: "none", paddingLeft: "0px" }}
                    >
                      {timeline_detail.listAttachment.map((item, index) => {
                        return (
                          <FileAttachment
                            item={item}
                            index={index}
                            key={index}
                            noIcon={true}
                          />
                        );
                      })}
                    </ul>
                  );
                }
                return (
                  <>
                    <Descriptions.Item
                      key={index}
                      label={item.title}
                      labelStyle={{ width: 120, fontWeight: "bold" }}
                    >
                      {content}
                    </Descriptions.Item>
                  </>
                );
              })}
            </Descriptions>
          )}
        </Drawer>
      </Drawer>
    </div>
  );
}

export default ViewMachine;
