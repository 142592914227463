import { lang } from "@src/constants/common.const";

export const ROUTER_PAGE = {
  DASHBOARD: "/OfficeDetail",
  END_USER:"/EndUser",
  HOME_BANNER: [
    "/HomeBannerManagement",
    "/CreateHomeBanner",
    "/ViewHomeBanner",
    "/EditHomeBanner",
  ],
  CL_MANAGEMENT: [
    "/ChecklistManagementv2",
    "/EditChecklistv2",
    "/CreateChecklistv2",
    "/ViewChecklistv2",
    "/CloneChecklistv2",
  ],
  CL_HISTORY: "/history2",
  CL_INPUT: "/checklist",
  INPUT_REST_LAST_VALUE: ["/ResetLastValue", "/EditResetLastValue"],
  CUSTOMER_REPORT: "/CustomerReport",
  SHEET_IMPORT_REPORT: "/SheetImportReport",
  TEMPLATE_MANAGEMENT: "/TemplateManagement",
  WORK_REPORT: [
    "/WorkReportManagement",
    "/CreateReport",
    "/ViewReport",
    "/EditReport",
  ],
  CL_REGISTRATION: "/ChecklistRegistration",
  MC_REGISTRATION: "/MachineRegistration",
  INVENTORY_REGISTRATION: "/InventoryRegistration",
  OFFICE_INFO: "/ViewOffice",
  OFFICE_MANAGEMENT: [
    "/OfficeManagement",
    "/CreateOffice",
    "/DetailOffice",
    "/UpdateOffice",
  ],
  ROLE_SETTING: "/RoleSetting",
  IMPORT_INVENTORY: ["/ImportInventory", "/ImportInventoryUpdate"],
  EXPORT_INVENTORY: ["/ExportInventory", "/ExportInventoryUpdate"],
  WAREHOUSES_MASTER: ["/WarehouseMaster", "/WarehouseUpdate"],
  CATEGORY_MASTER: ["/CategoryMaster", "/CategoryUpdate"],
  ITEM_MASTER: ["/ItemMaster", "/ItemUpdate"],
  INVENTORY_DATA: "/InventoryData",
  STOCK: "/Stock",
  FUNTION_MACHINE_MANAGEMENT: "/FunctionMachineManagement",
  MACHINE_MANAGEMENT: [
    "/machineManagement",
    "/AddMachine",
    "/EditMachine",
    "/HistoryUpdateMachine",
    "ViewMachine",
  ],
  MAINTAIN_HISTORY: ["/MaintainHistory"],
  FACILITY_MANAGEMENT: [
    "/FacilityManagement",
    "/AddFacility",
    "/EditFacility",
    "/ViewFacility",
  ],
  PROCESS_MANAGEMENT: [
    "/ProcessManagement",
    "/EditProcess",
    "/ViewProcess",
    "/AddProcess",
  ],
  CALENDAR: "/calendar",
  USER_INFO:"/ViewUser",
  CALIBRATION: [
    "/CalibrationManagement",
    "/CalibrationManagement/Add",
    "/CalibrationManagement/View",
    "/CalibrationManagement/Edit",
  ],
  DYNAMIC_REPORT_MANAGEMENT: ["/DynamicReportManagement", "/DynamicReportManagement/Add", "/DynamicReportManagement/Edit"],
};
export const NAME_PAGE = {
  DASHBOARD: lang.HOMEPAGE,
  HOME_BANNER: lang.HOME_BANNER,
  CL_MANAGEMENT: lang.LIST_CHECKLIST,
  CL_HISTORY: lang.HISTORY_CHECKLIST,
  CL_INPUT: lang.TODO_CHECKLIST,
  INPUT_REST_LAST_VALUE: lang.SC_REST_LAST_VALUE,
  CUSTOMER_REPORT: lang.EXPORT_REPORT,
  TEMPLATE_MANAGEMENT: lang.SAMPLE_TEMP_MANAGEMENT,
  WORK_REPORT: lang.WORK_REPORT_MANAGEMENT,
  CL_REGISTRATION: lang.LIST_CHECKLIST,
  MC_REGISTRATION: lang.REGISTRY_MACHINE,
  INVENTORY_REGISTRATION: lang.REGISTRY_INVENTORY,
  OFFICE_INFO: lang.OFFICE_DETAIL,
  OFFICE_MANAGEMENT: lang.OFFICE_MANAGEMENT,
  ROLE_SETTING: lang.ROLE_SETTING,
  WAREHOUSES_MASTER: lang.WAREHOUSES_MASTER,
  CATEGORY_MASTER: lang.CATEGORY_MASTER,
  ITEM_MASTER: lang.ITEM_MASTER,
  INVENTORY_DATA: lang.INVENTORY_DATA,
  STOCK: lang.STOCK,
  MACHINE_MANAGEMENT: lang.MACHINE_MANAGEMENT,
  CALENDAR: lang.CALENDAR,
  DYNAMIC_REPORT_MANAGEMENT: lang.DYNAMIC_REPORT_MANAGEMENT,
};
export default {
  ROUTER_PAGE,
  NAME_PAGE,
};
