import React, { useEffect, useState } from "react";
import GroupChecklist from "./GroupChecklist/groupChecklist";
import "./inspection.scss";
import ChecklistOverall from "./ChecklistOverall/checklistOverall";
import { useDispatch, useSelector } from "react-redux";
import TitlePage from "@src/component/Desktop/titlePage";
import {
  getChecklistsBookMark,
  getChecklistsSaved,
  getGroups,
  getChecklistsFiltered,
  getDataJudgment,
  getWorkingStatus,
  resetStateIsUpdated,
  resetStateWhenOffline,
  checklistApplyForToyota,
  createTempChecklist,
  updateEachChecklist,
} from "@src/redux/checkList";
import { Row, Col, Modal, Button, Checkbox } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { lang } from "@src/constants/common.const";
import { KEY } from "../../constants/common.const";
import { openConfirm } from "@src/redux/confirm";
import queryString from "query-string";
import API from "@src/api/backend/checkList";
import { isEmpty } from "lodash";
import { FullCalendarList } from "@src/page/Office/detail.jsx";
import moment from "moment";
import { getCookie, setCookie } from "@src/utils/cookie";

function ChecklistOverview() {
  const dispatch = useDispatch();
  const {
    dataSaved,
    dataTemplate,
    isLoadingTemplate,
    isLoadingSaved,
    dataTemplateFiltered,
    dataGroups,
    isUpdated,
  } = useSelector((state) => state.checklist);
  const { statusNetwork, selectedFactory, isToyota, userName } = useSelector(
    (state) => state.auth
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // check data in detail checklist exist or not
    // if it have so show popup confirm,after that recall api get list check result
    // if it don't, clear all data
    if (isUpdated) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: "/checklist",
        })
      );
    } else {
      if (navigator.onLine) {
        dispatch(resetStateIsUpdated());
        dispatch(getChecklistsBookMark("first", selectedFactory, 10));
        dispatch(getChecklistsSaved("first", selectedFactory));
        dispatch(getGroups({ type: "all", factoryCode: selectedFactory }));
        // dispatch(getChecklistsFiltered({ type: "first" }, selectedFactory));
        dispatch(getDataJudgment());
        dispatch(getWorkingStatus());
        if (isToyota) dispatch(checklistApplyForToyota());
      } else {
        dispatch(resetStateWhenOffline());
      }
    }
  }, [dispatch, selectedFactory, isToyota]);
  const createTempCL = (obj) => {
    history.push(`/checklist/${obj.id}`);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const queryParams = queryString.parse(history.location.search);
  useEffect(() => {
    const fetchCheckListDetail = async () => {
      try {
        if (
          !queryParams.checklistId ||
          !queryParams.calendarRemindId ||
          isEmpty(dataGroups)
        )
          throw null;
        const res = await API.getCheckListCalendar(queryParams);
        const currentChecklist = updateEachChecklist(res.data, "dataTemplate");
        await dispatch(createTempChecklist(currentChecklist, null));
        history.push("/list/new-checklist");
      } catch (e) {
        history.push("/checklist");
      }
    };
    fetchCheckListDetail();
  }, [location.search, history, dataGroups]);

  const [isShowModal, setIsShowModal] = useState(
    !getCookie(KEY.NOT_DISPLAY_CALENDAR_POPUP + userName)
  );
  const [isSetCookie, setIsSetCookie] = useState(false);
  const onHideModal = () => {
    setIsShowModal(false);
    if (isSetCookie) {
      setCookie(KEY.NOT_DISPLAY_CALENDAR_POPUP + userName, true, 1);
    }
  };
  const onChangeHideModal = (e) => {
    setIsSetCookie(e.target.checked);
  };

  return (
    <div
      className={
        isDesktopOrLaptop ? "des-content" : "des-content des-content__mobile"
      }
      style={!statusNetwork ? { marginTop: "110px" } : {}}
    >
      {isDesktopOrLaptop && <TitlePage name={lang.TODO_CHECKLIST} />}
      <div
        id="animation"
        className={
          isDesktopOrLaptop ? "checkList" : "checkList checkList__mobile"
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={isDesktopOrLaptop ? 10 : 24}>
            <ChecklistOverall
              type="bookMark"
              title={lang.BOOKMARK}
              data={dataTemplate}
              isLoading={isLoadingTemplate}
              clickCLBookMark={(obj) => createTempCL(obj)}
            />
          </Col>
          <Col span={isDesktopOrLaptop ? 14 : 24}>
            <ChecklistOverall
              type="notBookMark"
              title={lang.CHECK_RESULT}
              data={dataSaved}
              isLoading={isLoadingSaved}
              clickCLBookMark={(obj) => createTempCL(obj)}
            />
          </Col>
        </Row>
        {!isDesktopOrLaptop && (
          <Modal
            className="calendar-content modal-calendar"
            visible={isShowModal}
            title={
              moment().startOf("week").format("YYYY年MM月DD日") +
              " ~ " +
              moment().endOf("week").format("YYYY年MM月DD日")
            }
            bodyStyle={{ padding: "0px" }}
            onCancel={onHideModal}
            footer={null}
          >
            <FullCalendarList height="auto" maxHeight="80vh" />
            <Row>
              <Col span={18} style={{ textAlign: "left", padding: "14px" }}>
                <Checkbox onChange={onChangeHideModal} className="mt6">
                  1日間ポップアップ表示なし
                </Checkbox>
              </Col>
              <Col span={6} style={{ textAlign: "right", padding: "14px" }}>
                <Button key="submit" type="primary" onClick={onHideModal}>
                  OK
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
        <div className={isDesktopOrLaptop ? "checklistFilter" : ""}>
          <GroupChecklist
            isDesktopOrLaptop={isDesktopOrLaptop}
            dataCheckList={dataTemplateFiltered}
            dataGroups={dataGroups}
          />
        </div>
      </div>
    </div>
  );
}

export default ChecklistOverview;
