import { createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/officeDetail";

const initialState = {
  dataReport: [],
  dataChecklistManagement: [],
  dataOverview: null,
  dataChartReport: null,
  dataChartChecklist: null,
};

const officeDetail = createSlice({
  name: "office-detail",
  initialState,
  reducers: {
    setDataOverview: (state, { payload }) => {
      state.dataOverview = payload;
    },
    setDataChartReport: (state, { payload }) => {
      state.dataChartReport = payload;
    },
    setDataChartChecklist: (state, { payload }) => {
      state.dataChartChecklist = payload;
    },
    resetData: (state) => {
      state.dataReport = [];
      state.dataChecklistManagement = [];
      state.dataOverview = null;
      state.dataChartReport = null;
      state.dataChartChecklist = null;
    },
  },
});

// the action
export const {
  setDataOverview,
  setDataChartReport,
  setDataChartChecklist,
  resetData,
} = officeDetail.actions;

//the selector
export const loadingSelector = (state) => state.loading;

export const getSummary = () => (dispatch) => {
  API.getSummaryOfficeDetail()
    .then((res) => {
      if (res && res.status === 200) {
        const {
          approvedTroubleReportCount,
          checklistCount,
          historyCount,
          troubleReportCount,
          workingCheckResultCount,
          solvedTroubleReportCount,
        } = res.data;
        dispatch(
          setDataOverview({
            checklist: [checklistCount, "#0080cb"],
            history: [historyCount, "#2fad5a"],
            workingCheckResult: [workingCheckResultCount, "#f16900"],
            troubleReport: [troubleReportCount, "#c9297f"],
          })
        );
        dispatch(
          setDataChartReport({
            total: troubleReportCount,
            approved: approvedTroubleReportCount,
            resolved: solvedTroubleReportCount,
            unapproved:
              troubleReportCount -
              approvedTroubleReportCount -
              solvedTroubleReportCount,
          })
        );
      }
    })
    .catch((error) => console.log(error));
};
export const getChecklistSummary = (type) => (dispatch) => {
  API.getStatisticChecklist(type)
    .then((res) => {
      if (res && res.status === 200) {
        const labels = [];
        const approved = [];
        const unapproved = [];
        const reject = [];
        const working = [];
        const complete = [];
        const completeAbnormal = [];
        if (res.data.length) {
          res.data.forEach((item) => {
            labels.push(item.title);
            approved.push(item.statusCount[0]);
            unapproved.push(item.statusCount[1]);
            reject.push(item.statusCount[2]);
            working.push(item.statusCount[3]);
            complete.push(item.statusCount[4]);
            completeAbnormal.push(item.statusCount[5]);
          });
        }
        dispatch(
          setDataChartChecklist({
            labels: labels,
            approved: approved,
            unapproved: unapproved,
            reject: reject,
            working: working,
            complete: complete,
            completeAbnormal: completeAbnormal,
          })
        );
      }
    })
    .catch((error) => console.log(error));
};
// the reducer
export default officeDetail.reducer;
