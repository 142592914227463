import Api from "@src/api";

export default {
  // ------Machine
  // no.1
  getFacility(data) {
    return Api().get(`Facility`);
  },
  getDetailFacility(params) {
    return Api().get("/Facility/GetDetailFacility", { params });
  },
  // no.2
  getBuilding(facilityId) {
    return Api().get(`Building?facilityCode=${facilityId}`);
  },
  // no.3
  getFloor(BuildingId) {
    return Api().get(`Floor?buildingCode=${BuildingId}`);
  },
  // no.4
  getRoom(FloorId) {
    return Api().get(`Room?floorCode=${FloorId}`);
  },
  // no.5
  getProcess(facilityId) {
    return Api().get(`Process?facilityCode=${facilityId}`);
  },
  searchProcess(params) {
    return Api().get("Process/SearchProcess", { params });
  },
  // Search machine
  getMachine(data) {
    return Api().get(`MachineForSiteOffice`, { params: data });
  },

  getTiming() {
    return Api().get(`kbn/inspectionTiming`);
  },
  getChecklistTemplate(data) {
    return Api().get(`kbn/checklistTemplateList`, { params: data });
  },
  getWorkReportStatus(data) {
    return Api().get(`kbn/GetWorkReportStatus`);
  },
  getPriority(data) {
    return Api().get(`kbn/GetPriority`);
  },
  getWorkReportType(data) {
    return Api().get(`kbn/GetWorkReportType`);
  },

  // Role
  getRole(data) {
    return Api().get(`kbn/newRoles`);
  },
  getSiteOffice(data) {
    return Api().get(`kbn/siteOffice`);
  },

  // Cutomer Report
  getTemplateReportType(data) {
    return Api().get(`kbn/TemplateReportType`);
  },
  getCheckListType() {
    return Api().get(`kbn/checkListType`);
  },
  getInputContentOptions() {
    return Api().get(`kbn/inputContentOptions`);
  },
  getOptionsAccummulateType() {
    return Api().get("/Kbn/AccumulatedType");
  },
  getWorkingStatus() {
    return Api().get("/Kbn/machineWorkingStatus");
  },
  getPrefectures() {
    return Api().get("/Kbn/prefectures");
  },
  getSiteOfficeMasterStatus() {
    return Api().get("/Kbn/SiteOfficeMasterStatus");
  },
  getChecklistStatus() {
    return Api().get("/Kbn/ChecklistStatus");
  },
  // Inventory
  getStorage() {
    return Api().get("/Kbn/InventoryWarehouses");
  },
  getCategoryMachine(data) {
    return Api().get("/Kbn/MachineCategories", {
      params: data,
    });
  },
  getWorkReportForExport(data) {
    return Api().get("/Inventory/WorkReports", {
      params: data,
    });
  },
  // Warehouses
  getWarehouses(data) {
    return Api().get("/Inventory/Warehouses", {
      params: data,
    });
  },
  // Category
  getCategories(data) {
    return Api().get("/Inventory/Categories", {
      params: data,
    });
  },
  // InventoryType
  getInventoryType(data) {
    return Api().get("/Kbn/InventoryType", {
      params: data,
    });
  },
  getRemarkType() {
    return Api().get("/Kbn/RemarkType");
  },
  getDefaultType() {
    return Api().get("/Kbn/CheckItemDefaultType");
  },
  getItemJudgement() {
    return Api().get("/Kbn/ItemJudgment");
  },

  getInputMode() {
    return Api().get("/Kbn/GetInputMode");
  },

  // HomeBanner
  GetHomeBannerType(data) {
    return Api().get(`kbn/GetHomeBannerType`);
  },
  getHomeBannerSender() {
    return Api().get('/Kbn/GetHomeBannerSender')
  },
  getCalendarFixedType() {
    return Api().get('/Kbn/GetCalendarFixedType')
  },
};
