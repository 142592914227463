/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import TitlePage from "@src/component/Desktop/titlePage";
import CustomSelectCL from "./popupCustomSelect";
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Form,
  Modal,
  Drawer,
  Tooltip,
  Empty,
  Radio,
} from "antd";
import Loading from "../../../component/Desktop/loading/loading";
import "../inspection.scss";
import AddIcon from "@src/styles/icon/add.svg";
import { useParams } from "react-router-dom";
import {
  PlusOutlined,
  CloseCircleOutlined,
  UserOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { fromUnixTime } from "date-fns";
import { useMediaQuery } from "react-responsive";
import format from "@src/utils/format";
import FooterPage from "../../../component/footer/footerPage";
import PopupSearchCL from "./popupSearchCL"; // Popup
import API from "../../../api/backend/setting";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../../constants/common.const";
import classPopup from "../../../models/control/popup";
import classText from "../../../models/control/text";
import { ReactComponent as ConfirmIcon } from "@src/styles/icon/comfirm.svg";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import { ReactComponent as CustomIcon } from "@src/styles/icon/setting.svg";

import {
  showMessage,
  showMessageChange,
  statusRes,
  MSG_CATCH,
  checkSpecialCharacterSingle,
  isHeadOffice,
  isAdminOffice,
  isGroupManager,
} from "../../../constants/utils";
const { Paragraph } = Typography;
let flagChangeData = false;
let initPage = false;

function SettingGroup() {
  const history = useHistory();
  let [txtGroupName, setTxtGroupName] = useState(new classText());
  let [typeGroup, setTypeGroup] = useState(null);
  let [listGroup, setListGroup] = useState([]);
  let [showLoading, setShowLoading] = useState(false);
  const [visibleCustomCL, updateVisibleCustomCL] = useState(false);
  const [clSelected, updateCLSelected] = useState({ id: null });
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  // POPUP
  let [popupChecklist, setPopupChecklist] = useState(new classPopup());
  const [form] = Form.useForm();
  let [gridPopup, setGridPopup] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  let [_listChecklist, set_listChecklist] = useState([]);
  let [_nameChecklist, set_nameChecklist] = useState(new classText());
  let [loadSpin, setLoadSpin] = useState(true);

  const { idChecklist } = useParams();

  // Init
  useEffect(() => {
    flagChangeData = false;
    initPage = false;

    openLoadingSpinner();
    setPopupChecklist({
      ...popupChecklist,
      show: false,
      title: lang.ALL_CHECKLIST,
    });
    API.getDataGroup(idChecklist)
      .then((res) => {
        let data = res.data;

        setTxtGroupName({ ...txtGroupName, value: data.name });
        setTypeGroup(data.type);
        setListGroup(data.data);
      })
      .catch((error) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
        initPage = true;
      });
  }, []);
  // Update offset
  useEffect(() => {
    setGridPopup({ ...gridPopup, offset: _listChecklist.length });
  }, [_listChecklist]);
  const updateCustomCLSelected = (data, visible) => {
    updateCLSelected(data);
    updateVisibleCustomCL(visible);
  };
  const onCustomChecklist = (checklist) => {
    let arrCL = [...listGroup];
    arrCL.forEach((cl) => {
      if (cl.id === checklist.id) {
        cl = Object.assign({}, checklist);
      }
    });
    setListGroup(arrCL);
    updateVisibleCustomCL(false);
  };
  // POPUP
  const popup = {
    show: () => {
      setPopupChecklist({ ...popupChecklist, show: true });
    },
    hide: () => {
      setPopupChecklist({ ...popupChecklist, show: false });
    },
    showPopup: () => {
      // Clear
      form.resetFields();
      // Re-search
      popup.search();
      popup.show();
    },
    closePopup: () => {
      // Clear
      form.resetFields();
      set_listChecklist([]);
      //popup.hide();
      popup.hide();
    },
    // Search list Checklist
    search: async (reSearch) => {
      // Loading
      setLoadSpin(true);
      return API.searchChecklist(
        11001,
        reSearch ? 0 : gridPopup.offset,
        _nameChecklist.value ? _nameChecklist.value.trim() : ""
      )
        .then((res) => {
          let data = res.data;
          set_listChecklist(popup.verifyData(data.data));
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          setLoadSpin(false);
        });
    },
    grid_checkbox: (id, index, checked) => {
      let list = [..._listChecklist];
      list[index]["checked"] = checked;

      set_listChecklist(list);
    },
    gridLoadMore: () => {
      API.searchChecklist(11001, gridPopup.offset, _nameChecklist.value)
        .then((res) => {
          let data = res.data;
          // push
          set_listChecklist(
            popup.verifyData([..._listChecklist, ...data.data])
          );
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    },
    verifyData: (dataNew) => {
      return dataNew.map((v) => {
        let checked = false;
        // Checkid
        listGroup.forEach((vOld) => {
          if (vOld.id === v.id) {
            checked = true;
          }
        });
        v["disabled"] = checked;
        v["checked"] = v["checked"] || checked;
        return v;
      });
    },
    addToGroup: () => {
      let countNewItem = 0;
      let listAdd = [];
      _listChecklist.forEach((v, i) => {
        if (v["checked"] && v.checked === true && v.disabled !== true) {
          listAdd.push(v);
          countNewItem++;
        }
      });

      if (countNewItem > 0) {
        setListGroup([...listGroup, ...listAdd]);
        flagChangeData = true;
      }
      popup.closePopup();
    },
  };

  useEffect(() => {
    if (initPage) {
      flagChangeData = true;
    }
  }, [txtGroupName.value, typeGroup]);

  // onDeleteCL
  const onDeleteCL = (id, index) => {
    listGroup.splice(index, 1);
    setListGroup([...listGroup]);
    flagChangeData = true;
  };

  const validate = () => {
    let err = false;
    const vInput = txtGroupName.value;
    if (
      vInput === null ||
      vInput === "" ||
      vInput.trim() === "" ||
      checkSpecialCharacterSingle(txtGroupName) === false
    ) {
      err = true;
    }
    return err;
  };

  // Action Online
  const onSave = () => {
    // Set/Reset border
    setTxtGroupName({ ...txtGroupName, error: validate() });

    // Check format
    if (validate()) {
      return;
    }
    let machineIds = [];
    listGroup.forEach((checklist) => {
      checklist.checklistGroups.forEach((tab) => {
        if (tab.machineIds) {
          machineIds = machineIds.concat(tab.machineIds);
        }
      });
    });
    // Save
    const data = {
      GroupName: txtGroupName.value.trim(),
      type: typeGroup,
      machineIds: machineIds,
      checklistIds: [
        ...new Set( // Clear duplicate
          listGroup.map((v) => {
            return v.id; // Sent only id
          })
        ),
      ],
    };
    API.saveGroup(idChecklist, data)
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, toParent);
        } else {
          // Fail
          showMessage(KEY.ERROR, (res && res.data.msg) || MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  const onCancel = () => {
    if (flagChangeData) {
      showMessageChange(toParent, COMMON_CONSTANT.BOM_C018);
    } else {
      toParent();
    }
  };

  const toParent = () => {
    history.push("/checklist");
  };

  const onDelete = () => {
    const del = () => {
      API.deleteGroupChecklist(idChecklist)
        .then((res) => {
          if (statusRes(res)) {
            // Success
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004, toParent);
          } else {
            throw res;
          }
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    };
    showMessageChange(del);
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      {isDesktopOrLaptop && <TitlePage name={lang.EDIT_GROUP} />}
      <div className="setting-group">
        {!isDesktopOrLaptop && (
          <Row justify="space-between" className="page-mobile pb25 pt20">
            <Col span={12} className="page-mobile-back" onClick={onCancel}>
              {lang.EDIT_GROUP}
            </Col>
            <Col span={12} className="right">
              <Button
                type="link"
                onClick={onDelete}
                className="color-danger pr0 button-center"
              >
                <DeleteIcon className="svg-danger mr5" /> {lang.DELETE_GROUP}
              </Button>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 0]}>
          <Col>
            <Row className="mb5">{COMMON_CONSTANT.GROUP_NAME}</Row>
            <Row>
              <Input
                allowClear
                value={txtGroupName.value}
                style={{ minWidth: "250px", width: "auto" }}
                className={txtGroupName.error ? STYLE.BORDER_RED : ""}
                onChange={(v) => {
                  setTxtGroupName({ ...txtGroupName, value: v.target.value });
                }}
              />
            </Row>
          </Col>
          <Col>
            <Row className="mb5">{COMMON_CONSTANT.GROUP_TYPE}</Row>
            <Row>
              <Radio.Group
                className="bold"
                value={typeGroup}
                onChange={(e) => {
                  setTypeGroup(e.target.value);
                }}
              >
                <Radio.Button
                  value={KEY.GROUP_TYPE_COMMON}
                  disabled={
                    !(isHeadOffice() || isAdminOffice() || isGroupManager())
                  }
                >
                  <GlobalOutlined /> {lang.GROUP_COMMON}
                </Radio.Button>
                <Radio.Button value={KEY.GROUP_TYPE_USER}>
                  <UserOutlined /> {lang.GROUP_USER}
                </Radio.Button>
              </Radio.Group>
            </Row>
          </Col>
        </Row>
        <div className={isDesktopOrLaptop ? "content" : "content-mobile"}>
          <Row className="mb20">
            <Col>
              <a onClick={popup.showPopup} className="pl0 pr0 m-wAuto">
                <img src={AddIcon} alt="" style={{ paddingRight: "8px" }} />
                <span style={{ fontWeight: 600 }}>{lang.ADD_CHECKLIST}</span>
              </a>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            {listGroup.length > 0 ? (
              listGroup.map((e, index) => {
                return (
                  <Col xs={24} sm={24} md={8} lg={6} xxl={4} key={e.id}>
                    <Row
                      align="middle"
                      style={{ padding: "13px 16px" }}
                      className="itemList itemList-row nonSelect"
                    >
                      {/* Title */}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col className="id">
                            {lang.ID}: {e.id}
                          </Col>
                          <Col className="created">
                            {e.isPreUseChecklist !=
                              KEY.TYPE_PRE_USE_CHECKLIST && (
                              <>
                                <CustomIcon
                                  style={{ marginRight: "20px" }}
                                  className="iconAction-delete"
                                  onClick={() => {
                                    updateCustomCLSelected(e, true);
                                  }}
                                />
                              </>
                            )}

                            <DeleteIcon
                              className="iconAction-delete"
                              onClick={() => {
                                onDeleteCL(e.id, index);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {/* Body */}
                      <Col className="title" span={24}>
                        <Paragraph
                          ellipsis={{ rows: 2 }}
                          className="text-weight-700"
                        >
                          <Tooltip title={e.checklistName}>
                            {e.checklistName}
                          </Tooltip>
                        </Paragraph>
                      </Col>
                      {/* Footer */}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col className="created">{e.createName}</Col>
                          <Col className="created">
                            {format(
                              fromUnixTime(Number(e.createDatetime)),
                              "yyyy/MM/dd"
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <div className="center mt20 w100">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Row>
        </div>
      </div>

      <CustomSelectCL
        data={clSelected}
        visible={visibleCustomCL}
        onCreate={onCustomChecklist}
        onCancel={() => {
          updateVisibleCustomCL(false);
        }}
      />

      {/* MODAL ADD CHECKLIST */}
      {isDesktopOrLaptop ? (
        <Modal
          className={"popupP2"}
          centered
          title={popupChecklist.title}
          visible={popupChecklist.show}
          // onOk={handleOk}
          onCancel={popup.closePopup}
          width={1000}
          footer={[
            <Button key="submit" type="primary" onClick={popup.addToGroup}>
              <PlusOutlined />
              {COMMON_CONSTANT.ADD}
            </Button>,
            <Button
              key="back"
              className="buttonPC button--outline wAuto "
              onClick={popup.closePopup}
            >
              <CloseCircleOutlined />
              {lang.CANCEL}
            </Button>,
          ]}
        >
          <PopupSearchCL
            isDesktopOrLaptop={isDesktopOrLaptop}
            listGroup={listGroup}
            loadSpin={loadSpin}
            popup={popup}
            _nameChecklist={_nameChecklist}
            set_nameChecklist={set_nameChecklist}
            gridPopup={gridPopup}
            _listChecklist={_listChecklist}
            form={form}
          />
        </Modal>
      ) : (
        <Drawer
          className="menuBottom"
          title={popupChecklist.title}
          placement="bottom"
          onClose={popup.closePopup}
          visible={popupChecklist.show}
          // visible={true}
        >
          <PopupSearchCL
            listGroup={listGroup}
            loadSpin={loadSpin}
            popup={popup}
            _nameChecklist={_nameChecklist}
            set_nameChecklist={set_nameChecklist}
            gridPopup={gridPopup}
            _listChecklist={_listChecklist}
            form={form}
          />
          <Row className="menuBottom-footer">
            <Col span={12}>
              <div
                className="menuBottom-footer-btnCancel content-center"
                onClick={popup.closePopup}
              >
                {lang.CANCEL}
              </div>
            </Col>
            <Col span={12}>
              <div
                className="menuBottom-footer-btnConfirm content-center "
                onClick={popup.addToGroup}
              >
                {COMMON_CONSTANT.ADD}
              </div>
            </Col>
          </Row>
        </Drawer>
      )}

      {/* ACTION */}

      <FooterPage>
        {isDesktopOrLaptop ? (
          <Row gutter={[8, 0]} justify="space-between">
            <Col>
              <Row>
                <Col>
                  <Button
                    type="primary"
                    onClick={onSave}
                    className="content-center"
                  >
                    <ConfirmIcon className="mr5" />
                    {lang.CONFIRM}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline wAuto ml15"
                    onClick={onCancel}
                  >
                    <CloseCircleOutlined /> {lang.CANCEL}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className=" content-center">
              <Button
                className="buttonPC button--error--outline"
                onClick={onDelete}
              >
                <DeleteIcon className="svg-danger mr5" /> {lang.DELETE_GROUP}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Row className="menuBottom-footer">
              <Col span={12}>
                <div
                  className="menuBottom-footer-btnCancel content-center"
                  onClick={onCancel}
                >
                  {lang.CANCEL}
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="menuBottom-footer-btnConfirm content-center "
                  onClick={onSave}
                >
                  {lang.CONFIRM}
                </div>
              </Col>
            </Row>
          </Row>
        )}
      </FooterPage>
    </div>
  );
}

export default SettingGroup;
