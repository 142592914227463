import Api from "@src/api";
export const Apifacility = {
  searchFacility(params) {
    return Api().get("Facility/SearchFacility", { params });
  },
  deleteFacility(params) {
    return Api().delete("Facility/DeleteFacility", { params });
  },
  getDetail(params) {
    return Api().get("Facility/GetDetailFacility", { params });
  },
  createFacility(data) {
    return Api().post("Facility/CreateFacility", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateFacility(data) {
    return Api().put("Facility/UpdateFacility", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getListUserForManangerFacility() {
    return Api().get("UserInfo/GetUserBySiteOffice");
  },
  getListPlantName() {
    return Api().get("Facility/GetPlant");
  },
};
