/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Button, Modal, Table, Checkbox } from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import API from "../../api/backend/workReport";
import BreakLine from "../../component/Desktop/breakLine";

import {
  MSG_CATCH,
  statusRes,
  getNameFile,
  getDotFile,
  isImage,
  showMessage,
} from "../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { config } from "@src/api/config";
import { updateCurrentRouter } from "@src/redux/checkList";
import {
  CloseCircleOutlined,
  PaperClipOutlined,
  CheckSquareOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import classBtn from "../../models/control/button";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import TextArea from "antd/lib/input/TextArea";
import classBuhin from "../../models/machineBuhin";
import classPopup from "../../models/control/popup";
import classMachine from "../../models/machineDes";
import classCheckbox from "../../models/control/checkbox";
import moment from "moment";
import { checkRoleScreen } from "../../utils/roles";

//Declare global variables
export const REPORT_TYPE = [
  { id: "1", name: "作業報告" },
  { id: "2", name: "トラブル報告" },
];

function ViewWorkReport() {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  let [hdnChecklistID, sethdnChecklistID] = useState(new classText());
  let [txtName, settxtName] = useState(new classText());
  let [reportDate, setReportDate] = useState(new classText());
  let [ddlReportType, setddlReportType] = useState(new classDdl());
  const [ddlReportTypeCode, setddlReportTypeCode] = useState(new classDdl());
  let [ddlPriority, setddlPriority] = useState(new classDdl());
  let [txtStatus, settxtStatus] = useState(new classText());

  let [peConfirm, setpeConfirm] = useState(new classText());
  let [peApprove, setpeApprove] = useState(new classText());

  // Group 2
  let [dataMachine, setdataMachine] = useState([]);
  let [rowShow, setrowShow] = useState([]);

  let [txtTroubleInformation, settxtTroubleInformation] = useState(
    new classText()
  );
  let [txtRenovationMethod, settxtRenovationMethod] = useState(new classText());
  let [txtReason, settxtReason] = useState(new classText());
  const [chkAutoExport, setChkAutoExport] = useState(new classCheckbox());

  // Btn
  let [btnConfirm, setbtnConfirm] = useState(new classBtn());
  let [btnResolve, setbtnResolve] = useState(new classBtn());
  let [btnApprove, setbtnApprove] = useState(new classBtn());
  let [btnReject, setbtnReject] = useState(new classBtn());

  // Popup
  let [popupAttach, setPopupAttach] = useState(new classPopup());
  let [popupConfirm, setPopupConfirm] = useState(new classPopup());
  // In-Popup
  let [tblAttach, setTblAttach] = useState([]);
  let [txtReasonInput, settxtReasonInput] = useState(new classText());

  const columnsMachine = [
    { title: "機器名", dataIndex: "Name", width: "20%" },
    { title: "施設", dataIndex: "facilityName", align: "center", width: "16%" },
    { title: "棟", dataIndex: "buildingName", align: "center", width: "14%" },
    { title: "階", dataIndex: "floorName", align: "center", width: "10%" },
    { title: "部屋", dataIndex: "roomName", align: "center", width: "12%" },
    {
      title: "プロセス",
      dataIndex: "processName",
      align: "center",
      width: "9%",
    },
    {
      title: "交換", // Select
      width: "9%",
      align: "center",
      dataIndex: "checked",
      render: (txt, row = classMachine(), id) => {
        return <Checkbox checked={row.LstBuhin.length > 0} disabled></Checkbox>;
      },
    },
  ];

  const columnsAttach = [
    {
      title: "No",
      dataIndex: "ItemOrder",
    },
    {
      title: "プレビュー",
      dataIndex: "FileName",
      width: "15%",
      render: (text, row) => {
        let urlFile = row.attachmentUrl;
        if (isImage(row.DotName)) {
          return (
            <div className="center">
              <a target="_blank" rel="noopener noreferrer" href={urlFile}>
                <img
                  width="48"
                  height="48"
                  border="0"
                  alt="プレビュー"
                  title={row.FileName}
                  dragid="0"
                  draggable="true"
                  src={urlFile}
                />
              </a>
            </div>
          );
        } else {
          return (
            <div className="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFile}
                title={row.FileName}
              >
                <PaperClipOutlined className="blue iconL" />
              </a>
            </div>
          );
        }
      },
    },
    {
      title: "ファイル名",
      dataIndex: "FileName",
      render: (text, row) => {
        return text;
      },
    },
    {
      title: "備考とメヂイアの挿入と操作",
      dataIndex: "Description",
      width: "35%",
      render: (text, row) => {
        return text;
      },
    },
  ];

  const init = () => {
    setInitPage(true);
    // Open loading
    openLoadingSpinner();

    Promise.all([API.getDetail(id)])
      .then((res) => {
        let data = res[0] ? res[0].data : null;
        //Group1
        if (!data) {
          history.push("/WorkReportManagement");
          return;
        }
        // Normal
        sethdnChecklistID({
          ...hdnChecklistID,
          value: data.checkResultName,
          id: data.checkResultId,
        });
        let reportTime = data.reportDate
          ? moment.unix(Number(data.reportDate)).format(KEY.DATE_DEFAULT)
          : moment(new Date()).format(KEY.DATE_DEFAULT);
        setReportDate({ ...reportDate, value: reportTime });
        settxtName({ ...txtName, value: data.name });
        setddlReportType({ ...ddlReportType, value: data.reportTypeValue });
        setddlReportTypeCode({ ...ddlReportTypeCode, value: data.reportType });
        ddlPriority.value = data.priorityValue;
        setddlPriority({ ...ddlPriority, value: data.priorityValue });

        settxtStatus({ ...txtStatus, value: data.statusValue });
        peConfirm.value = data.confirmerDisplay;
        setpeConfirm({ ...peConfirm, value: data.confirmerDisplay });

        peApprove.value = data.approverDisplay;
        setpeApprove({ ...peApprove, value: data.approverDisplay });
        //Group2
        settxtRenovationMethod({
          ...txtRenovationMethod,
          value: data.solution,
        });
        txtTroubleInformation.value = data.description;
        settxtTroubleInformation({
          ...txtTroubleInformation,
          value: data.description,
        });
        txtReason.value = data.rejectReason;
        settxtReason({ ...txtReason, value: data.rejectReason });
        setChkAutoExport({ ...chkAutoExport, checked: data.createExport });
        // Table machine
        let show = [];
        data.listWorkReportDetail = data.listWorkReportDetail.map(
          (v = new classMachine()) => {
            let row = new classMachine();
            row = v;
            row.ID = v.machineForSiteOfficeCode; // Key
            row.Name = v.machineForSiteOfficeName;
            row.LstBuhin = v.buhins.map((b = new classBuhin()) => {
              return {
                inventoryWarehouseName: b.inventoryWarehouseName,
                inventoryWarehouseCode: b.inventoryWarehouseCode,
                inventoryWarehouseItemCode: b.inventoryWarehouseItemCode,
                inventoryItemName: b.inventoryItemName,
                Quantity: b.inventoryQuantity,
                machineForSiteOfficeCode: b.machineForSiteOfficeCode,
              };
            });
            // Show sub
            if (row.LstBuhin.length > 0) {
              row.checked = true;
              // Show list buhin
              show.push(row.ID);
            }
            return row;
          }
        );
        setrowShow(show);
        setdataMachine(data.listWorkReportDetail);
        // Popup attach
        data.listWorkReportAttachment = data.listWorkReportAttachment.map(
          (v) => {
            let row = {};
            row["ItemOrder"] = v.itemOrder; // Key
            row["FileName"] = getNameFile(v.fileName);
            row["DotName"] = getDotFile(v.fileName);
            row["Description"] = v.description;
            row["attachmentUrl"] = v.attachmentUrl;
            return row;
          }
        );
        setTblAttach(data.listWorkReportAttachment);
        // update screen
        checkStatusWorkReport(data.statusValue);
        changeReportType(data.reportType);

        // Pass init
        setInitPage(false);
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  // Init
  useEffect(checkRoleScreen(location.pathname, allowRoute) && init, [
    allowRoute,
  ]);

  // Config screen
  const changeReportType = (v) => {
    v = Number(v);
    let trouble = { ...txtTroubleInformation },
      confrim = { ...peConfirm },
      approve = { ...peApprove },
      priority = { ...ddlPriority };

    if (v === 1) {
      // 作業報告
      trouble.show = false;

      // Hide 交換 column
      // Clear checked + off show

      // Hide email confirm/approve
      confrim.show = false;
      approve.show = false;
      // Hide all button
      setbtnConfirm({ ...btnConfirm, show: false });
      setbtnResolve({ ...btnResolve, show: false });
      setbtnApprove({ ...btnApprove, show: false });
      setbtnReject({ ...btnReject, show: false });

      // Disable 優先度
      priority.value = "";
      priority.disabled = true;
    } else if (v === 2) {
      /// トラブル報告
      trouble.show = true;
      // Show 交換 column

      // Show email confirm/approve
      confrim.show = true;
      approve.show = true;

      //
      priority.disabled = false;
    } else {
      // other
    }
    //check permission from other factory
    // TODO

    // Update state
    settxtTroubleInformation(trouble);
    setpeConfirm(confrim);
    setpeApprove(approve);
    setddlPriority(priority);
  };

  // Show/hide button
  function checkStatusWorkReport(status) {
    let reject = { ...btnReject },
      confirm = { ...btnConfirm },
      approve = { ...btnApprove },
      resolve = { ...btnResolve };
    const role = Number(localStorage.getItem(KEY.USER_ROLE));

    reject.show = true;
    confirm.show = true;
    approve.show = true;
    resolve.show = true;

    // Check permission
    if (role === 1 || role === 2) {
      // Head office ; Admin Group
      // Show all
    } else if (role === 3) {
      // Office user
      // Show only resolve
      reject.show = false;
      confirm.show = false;
      approve.show = false;
    } else {
      // Customer
      // Hide all
      reject.show = false;
      confirm.show = false;
      approve.show = false;
      resolve.show = false;
    }

    // Check status
    if (
      status === COMMON_CONSTANT.WORK_REPORT_STATUS_REJECTED ||
      status === COMMON_CONSTANT.WORK_REPORT_STATUS_RESOLVED
    ) {
      // Reject and Resolve
      reject.show = false;
      confirm.show = false;
      approve.show = false;
      resolve.show = false;

      // display 拒否の理由
      if (status === COMMON_CONSTANT.WORK_REPORT_STATUS_REJECTED) {
        settxtReason({ ...txtReason, show: true });
      } else {
        settxtReason({ ...txtReason, show: false });
      }
    } else if (status === COMMON_CONSTANT.WORK_REPORT_STATUS_CONFIRMED) {
      // Confirm mode
      confirm.show = false;
      resolve.show = false;
    } else if (status === COMMON_CONSTANT.WORK_REPORT_STATUS_APPROVED) {
      // Approve mode
      reject.show = false;
      confirm.show = false;
      approve.show = false;
    } else {
      // Status Open and ReOpen
      resolve.show = false;
    }

    setbtnConfirm({ ...confirm });
    setbtnResolve({ ...resolve });
    setbtnApprove({ ...approve });
    setbtnReject({ ...reject });
  }

  function showAttachFileLst() {
    // Show popup
    setPopupAttach({ ...popupAttach, show: true });
  }

  function backToSearch() {
    history.goBack();
  }

  function showPopupConfirm(mode) {
    mode = String(mode);
    let customTitle = false;
    let title;
    let type;

    if (mode === COMMON_CONSTANT.WORK_REPORT_STATUS_REJECTED) {
      // Reject mode
      settxtReasonInput({ ...txtReasonInput, value: "", error: false });
      customTitle = true;
      title = "この作業報告を拒否してもよろしいですか？";
      type = COMMON_CONSTANT.REJECTED;

      // Show popup
      setPopupConfirm({
        ...popupConfirm,
        show: true,
        title: title,
        customTitle: customTitle,
        type: type,
      });
      return false;
    } else if (mode === COMMON_CONSTANT.WORK_REPORT_STATUS_CONFIRMED) {
      // Confirm mode
      title = "この作業報告を確認してもよろしいですか？";
      type = COMMON_CONSTANT.CONFIRMED;
    } else if (mode === COMMON_CONSTANT.WORK_REPORT_STATUS_APPROVED) {
      // Approve mode
      title = "この作業報告を承認してもよろしいですか？";
      type = COMMON_CONSTANT.APPROVED;
    } else if (mode === COMMON_CONSTANT.WORK_REPORT_STATUS_RESOLVED) {
      // Resolve mode
      title = "この作業報告を完了してもよろしいですか？";
      type = COMMON_CONSTANT.RESOLVED;
    } else {
      // other
    }

    // Show popup
    Modal.confirm({
      title: lang.WARMING,
      content: title,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        changeStatus({
          title: title,
          customTitle: customTitle,
          type: type,
        });
      },
    });
  }

  function changeStatus(data) {
    popupConfirm = data || popupConfirm;

    if (String(popupConfirm.type) === COMMON_CONSTANT.REJECTED) {
      // Validate required
      if (txtReasonInput.value && txtReasonInput.value.trim() !== "") {
        // normal
      } else {
        // error
        settxtReasonInput({ ...txtReasonInput, error: true });
        return false;
      }
    }

    openLoadingSpinner();
    setPopupConfirm({
      ...popupConfirm,
      show: false,
    });

    // Call
    API.changeStatus({
      id: id,
      Status: popupConfirm.type,
      RejectResson: txtReasonInput.value,
    })
      .then((res) => {
        if (statusRes(res)) {
          init();
          // If reject and resolve => change status of office
          if (String(popupConfirm.type) === COMMON_CONSTANT.RESOLVED) {
            // init();
          } else {
            showNotifiPopup(popupConfirm.type);
          }
        } else {
          showMessage(KEY.ERROR, res.data.msg);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close
        closeLoadingSpinner();
      });
  }

  function showNotifiPopup(mode) {
    mode = String(mode);
    let title;

    if (mode === COMMON_CONSTANT.REJECTED) {
      // Reject mode
      title = lang.REJECT_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.CONFIRMED) {
      // Confirm mode
      title = lang.CONFIRM_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.APPROVED) {
      // Approve mode
      title = lang.APPROVE_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.RESOLVED) {
      // Resolve mode
      title = lang.SOLVED_NORMALLY;
    } else {
      // Empty
    }
    showMessage(KEY.INFO, title);
  }
  const openNewHistoryDetail = (router) => {
    window.open(config.clientEndpoint + router);
  };
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.WORK_REPORT_INFORMATION} />
      {/* Group 1 */}
      <GroupItem name={lang.REPORT_INFO} center>
        <div className="mt15"></div>
        <BreakLine />
        <RowInput name={lang.CHECK_RESULT_DESTINATION} box>
          <a
            onClick={() => {
              dispatch(
                updateCurrentRouter("/history-detail/" + hdnChecklistID.id)
              );
              openNewHistoryDetail("/history-detail/" + hdnChecklistID.id);
            }}
            className="white-space"
          >
            {hdnChecklistID.value}
          </a>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REPORT_NAME} box>
          <div className="white-space">{txtName.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REPORT_DATE} box>
          <div className="white-space">{reportDate.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REPORT_LEVEL} box>
          {ddlReportType.value}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.PRIORITY} box>
          {ddlPriority.value}
        </RowInput>
        <BreakLine />
        {peConfirm.show && (
          <>
            <RowInput name={lang.CONFIRMER_MAIL_ADDRESS} box>
              <div className="white-space">{peConfirm.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}
        {peApprove.show && (
          <>
            <RowInput name={lang.APPROVED_MAIL_ADDRESS} box>
              <div className="white-space">{peApprove.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}
        <RowInput name={lang.STATUS} box>
          {txtStatus.value}
        </RowInput>
        <BreakLine />

        {/* Comment */}
        {dataMachine.length > 0 && (
          <Table
            rowKey={lang.ID}
            className="tbl-original mt15 "
            scroll={{ x: 100, y: 500 }}
            pagination={false}
            columns={columnsMachine}
            expandable={
              rowShow.length > 0
                ? {
                    expandedRowKeys: rowShow,
                    rowExpandable: (row) => row.checked,
                    expandedRowRender: (row = new classMachine()) => {
                      let sub = row.LstBuhin || [];
                      return (
                        <table className="sub-table">
                          <thead>
                            <tr>
                              <th>{lang.PART_NAME}</th>
                              <th>{lang.WAREHOUSES_NAME}</th>
                              <th>{lang.QUANTITY}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sub.length > 0 ? (
                              sub.map((b = new classBuhin(), i) => {
                                return (
                                  <tr key={i}>
                                    <td>{b.inventoryItemName}</td>
                                    <td>{b.inventoryWarehouseName}</td>
                                    <td>{b.Quantity}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={2}>
                                  {COMMON_CONSTANT.EMPTY_DATA}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      );
                    },
                  }
                : {}
            }
            dataSource={dataMachine}
          />
        )}

        <div className="mt15"></div>
        {txtTroubleInformation.show && (
          <>
            <BreakLine />
            <RowInput name={lang.PRECAUTION_TROUBLE_DETAIL} box>
              <div className="white-space">{txtTroubleInformation.value}</div>
            </RowInput>
          </>
        )}
        <BreakLine />
        <RowInput name={lang.SAVE_REPORT + "・" + lang.REPAIR_METHOD} box>
          <div className="white-space">{txtRenovationMethod.value}</div>
        </RowInput>
        <BreakLine />
        {txtReason.show && (
          <>
            <RowInput name={lang.REASON_FOR_REJECT} box>
              <div className="white-space">{txtReason.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}
        {Number(ddlReportTypeCode.value) === 1 && (
          <>
            <RowInput name={lang.EXPORT_INVENTORY} box>
              <Checkbox
                className=" nonSelect"
                checked={chkAutoExport.checked}
                disabled={true}
              >
                {lang.AUTO_EXPORT_INVENTORY}
              </Checkbox>
            </RowInput>
            <BreakLine />
          </>
        )}
        {/* Button footer */}
        <Row justify="end" className="mt10 mb10">
          {!initPage && (
            <>
              {/* Attach */}
              <Button
                className="buttonPC button--outline --todo-- wAuto"
                onClick={() => {
                  showAttachFileLst();
                }}
              >
                <PaperClipOutlined className="orange " /> {lang.ATTACH}
              </Button>

              <>
                {/* btnReject */}
                {btnReject.show && (
                  <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={() => {
                      showPopupConfirm(
                        COMMON_CONSTANT.WORK_REPORT_STATUS_REJECTED
                      );
                    }}
                  >
                    <MinusCircleOutlined />
                    {lang.REJECT}
                  </Button>
                )}
                {/* btnConfirm */}
                {btnConfirm.show && (
                  <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={() => {
                      showPopupConfirm(
                        COMMON_CONSTANT.WORK_REPORT_STATUS_CONFIRMED
                      );
                    }}
                  >
                    <CheckSquareOutlined />
                    {lang.CONFIRM}
                  </Button>
                )}
                {/* btnApprove */}
                {btnApprove.show && (
                  <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={() => {
                      showPopupConfirm(
                        COMMON_CONSTANT.WORK_REPORT_STATUS_APPROVED
                      );
                    }}
                  >
                    <CheckCircleOutlined />
                    {lang.APPROVE}
                  </Button>
                )}
              </>
              {/* btnResolve */}
              {btnResolve.show && (
                <Button
                  className="buttonPC button--info wAuto ml15"
                  onClick={() => {
                    showPopupConfirm(
                      COMMON_CONSTANT.WORK_REPORT_STATUS_RESOLVED
                    );
                  }}
                >
                  <FileDoneOutlined />
                  {lang.RESOLVE}
                </Button>
              )}
              {/* Back */}
            </>
          )}
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={backToSearch}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>
        </Row>
      </GroupItem>

      {/* attachFileModal */}
      <Modal
        centered
        width="50%"
        bodyStyle={{ height: "400px", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.ATTACH}</div>}
        visible={popupAttach.show}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              setPopupAttach({ ...popupAttach, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <Table
          className="tbl-original"
          rowKey="ItemOrder"
          dataSource={tblAttach}
          columns={columnsAttach}
          pagination={false}
        />
      </Modal>

      {/* Popup confirm */}
      <Modal
        centered
        closeIcon={<></>}
        className="modal-msg modal-warning2"
        title={
          <>
            <ExclamationCircleOutlined />
            <div className="modal-custom-content">
              {popupConfirm.title}
              {popupConfirm.customTitle && (
                <>
                  <Row>
                    <span className="fs12 pt5 mb5">
                      {lang.REASON_FOR_REJECT}
                      <label className="blod ml5 mark-required">*</label>
                    </span>
                  </Row>
                  <Row>
                    <TextArea
                      rows={3}
                      className={txtReasonInput.error ? STYLE.BORDER_RED : ""}
                      maxLength={255}
                      value={txtReasonInput.value}
                      onChange={(e) => {
                        settxtReasonInput({
                          ...txtReasonInput,
                          value: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </Row>
                </>
              )}
            </div>
          </>
        }
        visible={popupConfirm.show}
        footer={[
          <Button
            key={2}
            onClick={() => {
              setPopupConfirm({ ...popupConfirm, show: false });
            }}
          >
            {lang.CANCEL}
          </Button>,
          <Button
            key={1}
            className="buttonPC button--warning2 wAuto"
            onClick={() => {
              changeStatus();
            }}
          >
            {lang.OK}
          </Button>,
        ]}
      ></Modal>
    </div>
  );
}

export default ViewWorkReport;
