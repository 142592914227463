import Api from "@src/api";

export default {
  // 1: Search-----
  // Grid
  search(data) {
    return Api().get("CustomerReport", { params: data });
  },
  download(data) {
    return Api().get("CustomerReport/download", {
      params: data,
      responseType: "blob",
    });
  },
  dowloadMultipeFile(data,params){
    return Api().post("CustomerReport/download", data,{
      responseType: "blob",
      headers: { "Content-Type": "application/json" },
      params
    });
  },
  
  getDropdown(templateId) {
    return Api().get(`CustomerReport/${templateId}/csvSheets`);
  },
  uploadFile(templateId, typeCSV, startDate, data) {
    return Api().post(
      `CustomerReport/${templateId}/uploadCsv?sheetName=${typeCSV}&startDate=${startDate}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
};
