import { UploadOutlined } from "@ant-design/icons";
import BoxContent from "@src/component/Desktop/content";
import { Button, Slider, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { UserMangementApi } from "../../api/backend/userManagement";
import { KEY, SIZE_IMAGE, lang } from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import ShowImage from "./showImage";
import { UserEdit } from "iconsax-react";
import { useMediaQuery } from "react-responsive";

const TO_RADIANS = Math.PI / 180;

export default function FileImageInput({ imageUrl, setImageUrl, title }) {
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef(null);
  const [visisbleModal, setVisiableModal] = useState(false);
  const [urlPreview, setUrlPreview] = useState("");

  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [fileName, setFileName] = useState("");
  const fileRef = useRef(null);

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const scaleMark = {};
  const rorateMark = {};
  Array.from({ length: 5 }, (_, index) => (index + 1) * 2).forEach(
    (valueScale) => (scaleMark[valueScale] = "x" + valueScale)
  );
  Array.from({ length: 4 }, (_, index) => (index + 1) * 90).forEach(
    (rorateValue) => (rorateMark[rorateValue] = rorateValue + "°")
  );
  // Change file image
  function onSelectFile(e) {
    // setScale(1);
    // setRotate(0);
    // setUrlPreview("");
    // setCrop(null);
    if (e.target.files && e.target.files.length > 0) {
      // setCrop(undefined);
      setFileName(e.target.files[0].name);
      const reader = new FileReader();

      reader.onload = (e) => {
        setImgSrc(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    setVisiableModal(true);
  }

  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const initPercentWidth = height < width ? (height / width) * 100 : 90;
    const cropInPercent = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: initPercentWidth,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
    const cropInPx = {
      unit: "px",
      width: (cropInPercent.width * width) / 100,
      height: (cropInPercent.height * height) / 100,
      x: (cropInPercent.x * width) / 100,
      y: (cropInPercent.y * height) / 100,
    };
    setCrop(cropInPercent);
    setCompletedCrop(cropInPx);
  }

  // Dùng canvas để vẽ hình ảnh
  async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
  }

  // To blob
  function toBlob(canvas) {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  }

  // Privew Image
  async function imgPreview(image, crop, scale = 1, rotate = 0) {
    const canvas = document.createElement("canvas");
    canvasPreview(image, canvas, crop, scale, rotate);

    const blob = await toBlob(canvas);

    if (!blob) {
      return "";
    }
    return blob;
  }

  // Xử lí khi cắt xong và nhấn button ok
  const completeCrop = async () => {
    const cropped = await imgPreview(
      imageRef.current,
      completedCrop,
      scale,
      rotate
    );
    setCroppedImage(cropped);
    resetAffterCrop();
  };

  const resetAffterCrop = (visible = false) => {
    setVisiableModal(false);
    fileRef.current.value = null;
    setScale(1);
    setRotate(0);
    setUrlPreview("");
    setCrop(null);
    setCompletedCrop(null);
  };

  // Upload file lên cloud và lấy url về
  const uploadFileImage = async (file) => {
    const formData = new FormData();
    const body = { attachFileName: file.name, type: 3 };
    formData.append("data", JSON.stringify(body));
    formData.append("file", file);
    try {
      setLoading(true);
      const res =
        title === lang.AVATAR_USER
          ? await UserMangementApi.uploadAvatar(formData)
          : await UserMangementApi.uploadSignature(formData);
      setImageUrl(res.data.url, fileName);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  // Khi click vào button delete
  const deleteAvatar = () => {
    setImageUrl("", "");
  };

  // Hủy cắt
  const onCancelModal = () => {
    setVisiableModal(false);
    resetAffterCrop();
  };

  const handleCompleteCrop = (c) => {
    setCompletedCrop(c);
  };

  // Mỗi khi cắt file mới thì gọi api upload
  useEffect(() => {
    if (croppedImage) {
      uploadFileImage(croppedImage);
    }
  }, [croppedImage]);

  useEffect(() => {
  }, [completedCrop]);

  return (
    <div>
      <BoxContent style={{ padding: "8px", margin: "8px" }}>
        {(!imageUrl || loading) && (
          <Button
            onClick={() => {
              fileRef.current.click();
            }}
            style={{ width: 170, height: 170 }}
            className="buttonWrapUpload"
          >
            {!loading ? (
              <b>
                <div className="uploadWrap">
                  <UploadOutlined className="uploadIcon" />
                  <p className="uploadText">機器写真</p>
                </div>
              </b>
            ) : (
              <Spin />
            )}
          </Button>
        )}
        {imageUrl && !loading && (
          <>
            <div className="avatar">
              <ShowImage
                uploadFileImage={() => fileRef.current.click()}
                deleteImage={deleteAvatar}
                imageUrl={imageUrl}
              />
            </div>
          </>
        )}
      </BoxContent>

      <div>
        <input
          type="file"
          className="d-none"
          ref={fileRef}
          accept="image/*"
          onChange={onSelectFile}
        />

        {visisbleModal && imgSrc && (
          <div className="wrap-modal-crop">
            <div className="wrap-modal-title">
              <h2 className="ml10" style={{ fontWeight: "bold" }}>
                {lang.CROP_IMAGE}
              </h2>
            </div>

            <div className="modal-crop">
              <ReactCrop
                keepSelection
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => handleCompleteCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imageRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    borderRadius: 5,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
            <div className="edit">
              <div className="edit-scale">
                <strong className="edit-title">Scale</strong>
                <div>
                  <Slider
                    marks={scaleMark}
                    onChange={(v) => setScale(v)}
                    min={1}
                    max={10}
                    step={0.1}
                    style={{ width: 300 }}
                    value={scale}
                  />
                </div>
              </div>
              <div className="edit-scale">
                <strong className="edit-title">Rorate</strong>
                <Slider
                  onChange={(v) => setRotate(v)}
                  min={0}
                  max={360}
                  step={10}
                  style={{ width: 300 }}
                  value={rotate}
                  marks={rorateMark}
                />
              </div>
            </div>
            <div
              className={
                isPC
                  ? "wrap-modal-button mt20"
                  : "wrap-modal-button-mobile mt20"
              }
            >
              <Button className="mr10" onClick={() => onCancelModal()}>
                {lang.CANCEL}
              </Button>
              <Button type="primary" onClick={() => completeCrop()}>
                {lang.OK}
              </Button>
            </div>
          </div>
        )}
      </div>
      {visisbleModal && <div className="overlay-crop"></div>}
    </div>
  );
}
