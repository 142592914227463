import Api from "@src/api";
export const ApiProcessMangement = {
  getProcessMasterStatus(){
    return Api().get("Kbn/GetProcessMasterStatus")
  },
  searchProcess(params) {
    return Api().get("Process/SearchProcess", { params });
  },
  fetchFacility(params) {
    return Api().get("/Facility", { params });
  },
  getDetailtProcess(params) {
    return Api().get("Process/GetDetailProcess", { params });
  },
  fetchProcessMaster(params) {
    return Api().get("Process/GetProcessMaster", { params });
  },
  createProcess(data) {
    return Api().post("Process/CreateProcess", data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });
  },
  updateProcess(data) {
    return Api().put("Process/UpdateProcess", data,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  deleteProcess(params) {
    return Api().delete("Process/DeleteProcess", {params});
  },
};
