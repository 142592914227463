import React, { useEffect } from "react";
import { Row, Col, Typography, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingInline from "@src/component/Popup/loading-inline";
import TitlePage from "@src/component/Desktop/titlePage";
import { LeftOutlined } from "@ant-design/icons";
import {
  getChecklistsBookMark,
  createTempChecklist,
} from "@src/redux/checkList";
import InfiniteScroll from "react-infinite-scroller";
import { useMediaQuery } from "react-responsive";
import { lang } from "@src/constants/common.const";
import { KEY } from "../../../constants/common.const";
const { Paragraph } = Typography;
function ShowAllChecklistBK() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    dataTemplate,
    isLoadingTemplate,
    offsetTemplateChecklist,
    isLoadMore,
  } = useSelector((state) => state.checklist);
  const { statusNetwork } = useSelector((state) => state.auth);
  useEffect(() => {
    if (navigator.onLine) {
      dispatch(getChecklistsBookMark("first", 11001, 20));
    }
  }, [dispatch]);
  const goToDetail = (obj) => {
    dispatch(createTempChecklist(obj, history));
  };
  const loadMore = () => {
    dispatch(getChecklistsBookMark(offsetTemplateChecklist, 11001, 20));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  return (
    <div
      className={
        isDesktopOrLaptop ? "des-content" : "des-content des-content__mobile"
      }
      style={!statusNetwork ? { marginTop: "110px" } : {}}
    >
      <Row
        className="rowNotMargin button-back"
        align="middle"
        gutter={[8, 8]}
        onClick={() => history.goBack()}
      >
        <Col className="icon-back">
          <LeftOutlined />
        </Col>
        <Col className="text">{lang.BACK}</Col>
      </Row>
      <TitlePage name={lang.LIST_BOOKMARK} />
      <div
        id="animation"
        className={isDesktopOrLaptop ? "showAll" : "showAll showAll__mobile"}
      >
        <div className={isDesktopOrLaptop ? "card-detail" : ""}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadMore}
            hasMore={isLoadMore && !isLoadingTemplate}
            useWindow={true}
          >
            <Row gutter={[16, 16]} justify="center" className="rowNotMargin">
              {dataTemplate.map((item, index) => (
                <Col id="animation" xs={24} md={12} lg={6} key={index}>
                  <div
                    id="animation"
                    className="itemList itemList__bookMark itemList__grid__bookMark"
                    onClick={() => goToDetail(item)}
                  >
                    <div className="id">
                      {lang.ID}: {item.id}
                    </div>
                    <Paragraph ellipsis={{ rows: 2 }} className="title">
                      <Tooltip title={item.checklistName}>
                        {item.checklistName}
                      </Tooltip>
                    </Paragraph>
                  </div>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
          <Col span={24} className="text-center">
            <LoadingInline isLoadingInline={isLoadingTemplate} />
          </Col>
        </div>
      </div>
    </div>
  );
}

export default ShowAllChecklistBK;
