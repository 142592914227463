import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
// import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import API from "../../api/backend/customerReport";
import KBN from "../../api/backend/kbn";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { CONFIG, KEY, lang, STYLE } from "../../constants/common.const";
import {
  enterSearch,
  INPUT_DATE,
  INPUT_MONTH,
  INPUT_YEAR,
  MSG_CATCH,
  renderOption,
  saveFile,
  showMessage,
  statusRes,
} from "../../constants/utils";
// Class
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
// Icon
import {
  ClearOutlined,
  CloudDownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { checkRoleScreen } from "../../utils/roles";
import moment from "moment-timezone";

function CustomerReport() {
  // Loading
  let [showLoading, setShowLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [formSearch] = Form.useForm();
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  // Input search
  let [ddlReportType, setDdlReportType] = useState(new classDdl());
  let ddlReportTypeEl = useRef(null);
  // Date
  let [lblCheck, setLblCheck] = useState(" ");
  let [txtCheckStartDate, setTxtCheckStartDate] = useState(new classText());
  let txtCheckStartDateEl = useRef(null);
  let [txtCheckEndDate, setTxtCheckEndDate] = useState(new classText());
  let txtCheckEndDateEl = useRef(null);
  // End date
  let [ddlTemplate, setDdlTemplate] = useState(new classDdl());
  let ddlTemplateEl = useRef(null);
  //  Grid
  let [grid, setGrid] = useState(new classGrid());
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const disabledButtonDownload =
    grid.data.filter((item) => item.checked).length < 2;
  const classBtn = isPC
    ? `${!disabledButtonDownload ? "buttonPC" : ""} button--outline wAuto`
    : `${!disabledButtonDownload ? "buttonPC" : ""} button--outline w100`;

  // Column GRID
  const initColumns = [
    {
      title: (
        <Checkbox
          disabled={!grid.data.length}
          checked={checkAll}
          onChange={(e) => setCheckAll(e.target.checked)}
        ></Checkbox>
      ),
      align: "center",
      dataIndex: "checked",
      render: (text, row) => (
        <Checkbox
          checked={row.checked}
          onChange={hancleChangeChecked(row.rowKey)}
        />
      ),
      width: "5%",
    },
    {
      title: lang.ID,
      dataIndex: "id",
      width: "5%",
      align: "center",
      render: (txt, row, index) => {
        return grid.data.length - index;
      },
    },

    {
      title: lang.INSPECTION_DATE,
      dataIndex: "checkDate",
      width: "20%",
    },
    {
      title: lang.TEMPLATE_NAME,
      width: "30%",
      dataIndex: "templateTitle",
      className: "white-space",
      align: "center",
    },
    {
      title: lang.APPROVER,
      width: "15%",
      dataIndex: "approver",
      align: "center",
    },
    {
      title: lang.INSPECTION_PERSON,
      width: "15%",
      dataIndex: "inspector",
      align: "center",
    },
    {
      title: lang.DOWNLOAD,
      align: "center",
      width: "10%",
      render: (text, row) => {
        return (
          <Tooltip title={lang.EXPORT}>
            <Button
              onClick={(e) => {
                onDownloadSingleFile(row);
              }}
              className="btnClone"
            >
              <CloudDownloadOutlined />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  //checked
  const hancleChangeChecked = (rowKey) => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    const index = newGrid.data.findIndex((item) => item.rowKey === rowKey);
    if (index >= 0) {
      newGrid.data[index].checked = e.target.checked;
      setGrid(newGrid);
    }
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Moment to week
  const getWeek = (value) => {
    if (value) {
      let last = moment(value.format(KEY.DATE_DEFAULT), KEY.DATE_DEFAULT)
        .day(7)
        .format(KEY.DATE_DEFAULT);
      return value.day(1).format(KEY.DATE_DEFAULT) + " - " + last;
    } else {
      return "";
    }
  };

  const onChangeReportType = (v, loadInit = false) => {
    !loadInit && openLoadingSpinner();
    // Reload ddl
    !loadInit && loadDDLChecklistTemplate(v);

    // Update datepicker
    !loadInit && setDdlReportType({ ...ddlReportType, value: v });
    let obj = {};

    if(v == '2') {
      moment.updateLocale("ja", {
        week: {
          dow: 1,
        },
      });
    } else {
      moment.updateLocale("ja", {
        week: {
          dow: 0,
        },
      });
    }

    switch (v) {
      case "1": // date
        setLblCheck(lang.TARGET_DATE);
        obj = { picker: "date", format: KEY.DATE_DEFAULT, input: INPUT_DATE };
        break;
      case "2": // week
        setLblCheck(lang.TARGET_WEEK);
        obj = {
          picker: "week",
          format: (value) => getWeek(value),
          input: null,
        };
        break;
      case "3": // month
        setLblCheck(lang.TARGET_MONTH);
        obj = { picker: "month", format: KEY.MONTH, input: INPUT_MONTH };
        break;
      case "4": // year
        setLblCheck(lang.TARGET_YEAR);
        obj = { picker: "year", format: KEY.YEAR, input: INPUT_YEAR };
        break;
      case "5": // finance year
        setLblCheck(lang.TARGET_YEAR);
        obj = { picker: "year", format: KEY.YEAR, input: INPUT_YEAR };
        break;
      case "6": // other
        setLblCheck(lang.BEGIN_DATE_TO_END_DATE);
        obj = { picker: "date", format: KEY.DATE_DEFAULT, input: INPUT_DATE };
        break;
      default:
        break;
    }

    setTxtCheckStartDate({ ...txtCheckStartDate, value: moment(new Date()) }); // Clear date pick
    setTxtCheckEndDate({ ...txtCheckEndDate, value: moment(new Date()) }); // Clear date pick
    setTxtCheckStartDate({
      ...txtCheckStartDate,
      ...obj,
      value: "",
      error: false,
    });
    setTxtCheckEndDate({ ...txtCheckEndDate, ...obj, value: "", error: false });
    !loadInit && closeLoadingSpinner();
  };

  const getStartDateString = () => {
    return txtCheckStartDate.value
      ? txtCheckStartDate.value.format(txtCheckStartDate.format)
      : null;
  };
  const getEndDateString = () => {
    return txtCheckEndDate.value
      ? txtCheckEndDate.value.format(txtCheckEndDate.format)
      : null;
  };
  const paramsSearch = () => {
    let dateStart, dateEnd;
    if (String(ddlReportType.value) === "2") {
      // Week
      dateStart = getWeek(txtCheckStartDate.value);
      dateEnd = txtCheckEndDate.value ? getWeek(txtCheckEndDate.value) : null;
    } else {
      dateStart = getStartDateString();
      dateEnd = getEndDateString();
    }
    return {
      reportType: ddlReportType.value,
      startTime: dateStart,
      endTime: dateEnd,
      templateId: ddlTemplate.value,
    };
  };
  // Search
  const search_onClick = () => {
    if (!validate()) {
      return;
    }
    // Loading
    openLoadingSpinner();
    // Call
    return API.search(paramsSearch())
      .then((res) => {
        if (statusRes(res)) {
          // Update key
          let grid = res.data;
          grid.data = grid.data.map((v, i) => {
            v["rowKey"] = i;
            v.checked = false;
            return v;
          });
          setGrid(grid);
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };

  // Validate search
  const validate = () => {
    ddlReportType = { ...ddlReportType, error: false };
    txtCheckStartDate = { ...txtCheckStartDate, error: false };
    txtCheckEndDate = { ...txtCheckEndDate, error: false };
    ddlTemplate = { ...ddlTemplate, error: false };

    let flagErr = false;

    if (ddlTemplate.value === "") {
      flagErr = true;
      // Border red
      ddlTemplate = { ...ddlTemplate, error: true };
      ddlTemplateEl.current.focus();
    }
    if (txtCheckStartDate.value === null || txtCheckStartDate.value === "") {
      flagErr = true;
      // Border red
      txtCheckStartDate = { ...txtCheckStartDate, error: true };
      txtCheckStartDateEl.current.focus();
    }
    if (ddlReportType.value === "") {
      flagErr = true;
      // Border red
      ddlReportType = { ...ddlReportType, error: true };
      ddlReportTypeEl.current.focus();
    }

    // Update state
    setDdlTemplate(ddlTemplate);
    setTxtCheckEndDate(txtCheckEndDate);
    setTxtCheckStartDate(txtCheckStartDate);
    setDdlReportType(ddlReportType);

    return !flagErr;
  };

  // clearInputSearch
  const clearInputSearch = async () => {
    onChangeReportType(ddlReportType.options[0].key);
    setGrid({ ...grid, data: [], total: 0 });
    formSearch.resetFields();
  };

  const customDataItemPost = (rowData) => {
    let param = {
      reportType: ddlReportType.value,
      templateId: ddlTemplate.value,
    };
    if (Number(rowData.id) === 0) {
      param["startTime"] = rowData.startDate;
      param["endTime"] = rowData.endDate;
    } else {
      // ID row exist
      param["id"] = rowData.id;
      if (String(ddlReportType.value) === "2") {
        // Week
        param["startTime"] = getWeek(txtCheckStartDate.value);
        param["endTime"] = getWeek(txtCheckEndDate.value);
      } else {
        // Other
        param["startTime"] = getStartDateString();
        param["endTime"] = getEndDateString();
      }
    }
    return param;
  };

  //Download 1 file
  const onDownloadSingleFile = (rowData) => {
    // Loading
    openLoadingSpinner();
    const param = customDataItemPost(rowData);
    // Call
    return API.download(param)
      .then((res) => {
        if (statusRes(res)) {
          saveFile(res);
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessageError(MSG_CATCH(lang.CAN_NOT_DOWNLOAD_DATA_NOT_EXISTED));
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  // download many files
  const onDownloadManyFile = (listFile) => {
    openLoadingSpinner();

    const dataDownLoad = listFile
      .filter((item) => item.checked)
      .map((item) => customDataItemPost(item));
    // Call
    return API.dowloadMultipeFile(JSON.stringify(dataDownLoad), {
      ...paramsSearch(),
      downloadAll: checkAll,
    }).then((res) => {
      saveFile(res);
      closeLoadingSpinner();
    });
  };

  const loadDDLChecklistTemplate = (reportTypeValue = 1) => {
    return KBN.getChecklistTemplate({ reportType: reportTypeValue }).then(
      (res) => {
        let listTemplateCheckList = res ? res.data.data : [];
        setDdlTemplate({
          ...ddlTemplate,
          value: "",
          options: listTemplateCheckList,
          error: false,
        });
      }
    );
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setGrid({ ...grid, data: [] });
      // Open loading
      openLoadingSpinner();

      // Load pulldown
      Promise.all([KBN.getTemplateReportType(), loadDDLChecklistTemplate()])
        .then((res) => {
          const listTemplateReportType = res[0] ? res[0].data.data : [];
          // Set pulldown
          setDdlReportType({
            ...ddlReportType,
            options: listTemplateReportType,
            value: listTemplateReportType[0].key,
          });
          onChangeReportType(listTemplateReportType[0].key, true);
          formSearch.setFieldsValue({ ddlTemplateEl: null });
        })

        .catch((err) => {
          showMessageError(MSG_CATCH());
        })
        .then(() => {
          // Close loading
          closeLoadingSpinner();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowRoute]);

  //checkAll

  useEffect(() => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    if (checkAll) {
      newGrid.data = newGrid.data.map((item) => {
        item.checked = true;
        return item;
      });
      setGrid(newGrid);
    } else {
      if (
        grid.data.length === grid.data.filter((item) => item.checked).length
      ) {
        newGrid.data = newGrid.data.map((item) => {
          item.checked = false;
          return item;
        });
        setGrid(newGrid);
      }
    }
  }, [checkAll]);

  useEffect(() => {
    if (grid.data.length) {
      if (
        grid.data.length === grid.data.filter((item) => item.checked).length
      ) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
    }
  }, [grid.data]);
  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.EXPORT_REPORT} />
      {/* Group input */}
      <GroupItem>
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          initialValues={{ ddlReportType: "1" }}
        >
          <Row gutter={[16, 8]}>
            <Col span="8">
              {/* 1 */}
              <Form.Item
                name="ddlReportType"
                className="rowNotMargin"
                label={lang.CATEGORY}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={lang.CATEGORY}
                  value={ddlReportType.value}
                  ref={ddlReportTypeEl}
                  className={ddlReportType.error ? STYLE.BORDER_RED : ""}
                  onChange={(v) => {
                    onChangeReportType(v);
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlReportType.options, false)}
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              {/* 2 - date */}
              <Form.Item
                name="txtCheckStartDate"
                className="rowNotMargin"
                label={lblCheck}
                rules={[{ required: true }]}
              >
                <Row>
                  <Col span={11}>
                    <DatePicker
                      format={txtCheckStartDate.format || KEY.DATE_DEFAULT}
                      ref={txtCheckStartDateEl}
                      value={txtCheckStartDate.value}
                      picker={txtCheckStartDate.picker}
                      disabledDate={(d) =>
                        !d || d.isAfter(txtCheckEndDate.value)
                      }
                      className={
                        "w100 " +
                        (txtCheckStartDate.error ? STYLE.BORDER_RED : "")
                      }
                      onChange={(v, dateString) => {
                        setTxtCheckStartDate({
                          ...txtCheckStartDate,
                          value: v,
                        });
                      }}
                      {...txtCheckStartDate.input}
                    />
                  </Col>
                  <Col span={2} className="center" style={{ padding: "7px" }}>
                    ～
                  </Col>
                  <Col span={11}>
                    <DatePicker
                      format={txtCheckEndDate.format || KEY.DATE_DEFAULT}
                      ref={txtCheckEndDateEl}
                      value={txtCheckEndDate.value}
                      picker={txtCheckEndDate.picker}
                      disabledDate={(d) =>
                        !d || d.isBefore(txtCheckStartDate.value)
                      }
                      className={
                        "w100 " +
                        (txtCheckEndDate.error ? STYLE.BORDER_RED : "")
                      }
                      onChange={(v, dateString) => {
                        setTxtCheckEndDate({ ...txtCheckEndDate, value: v });
                      }}
                      {...txtCheckEndDate.input}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span="8">
              {/* 3 site */}
              <Form.Item
                name="ddlTemplateEl"
                className="rowNotMargin"
                label={lang.TEMPLATE_NAME}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={lang.TEMPLATE_NAME}
                  ref={ddlTemplateEl}
                  value={ddlTemplate.value}
                  className={ddlTemplate.error ? STYLE.BORDER_RED : ""}
                  onChange={(v) => {
                    setDdlTemplate({ ...ddlTemplate, value: v });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlTemplate.options)}
                </Select>
              </Form.Item>
            </Col>
            {/* Button Search/Clear */}
            <Col span={24} className="right">
              <Button
                className="buttonPC button--info wAuto"
                onClick={search_onClick}
              >
                <SearchOutlined className="" />
                {lang.SEARCH}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={clearInputSearch}
              >
                <ClearOutlined />
                {lang.CLEAR}
              </Button>
            </Col>
          </Row>
        </Form>
      </GroupItem>

      <GroupItem>
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}{" "}
              <span>
                {grid.total || 0}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>

        {/* Grid  */}
        <Table
          rowKey="rowKey"
          className="gridSearch tblRole mt10"
          columns={initColumns}
          dataSource={grid.data}
          pagination={CONFIG.paging}
        />
      </GroupItem>
      <Loading show={showLoading}></Loading>
      <div className="wrapActionDownload site-layout-background">
        <Row className="action" justify="end" align="middle">
          <Space>
            <Tooltip title={lang.MANY_ATTACH}>
              <Button
                disabled={disabledButtonDownload}
                className={classBtn}
                style={{ border: "none !important" }}
                onClick={(e) => {
                  onDownloadManyFile(grid.data);
                }}
              >
                <CloudDownloadOutlined className="orange" /> {lang.MANY_ATTACH}
              </Button>
            </Tooltip>
          </Space>
        </Row>
      </div>
    </div>
  );
}
export default CustomerReport;
