import React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  DatePicker,
  Select,
  TimePicker,
  Row,
  Col,
  Table,
  Divider,
} from "antd";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { Content } from "antd/lib/layout/layout";
import TitlePage from "@src/component/Desktop/titlePage";
import "./calendar.scss";
import {
  lang,
  KEY,
  COMMON_CONSTANT,
  ROLE_KEY,
  STYLE,
} from "@src/constants/common.const";
import {
  CloseCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
  LoadingOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { checkRoleScreen } from "../../utils/roles";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../api/backend/calendar";
import API_CL from "../../api/backend/managementCL";
import API_USER from "../../api/backend/managementRole";
import API_KBN from "../../api/backend/kbn";
import {
  MSG_CATCH,
  statusRes,
  showMessage,
  handleCatchError,
  showMessageChange,
  enterSearch,
  renderOption,
  INPUT_DATE,
} from "../../constants/utils";
import classPopup from "../../models/control/popup";
import { debounce } from "lodash";
import { isEmpty, isString, isObject } from "lodash";
import Loading from "../../component/Desktop/loading/loading";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import allLocales from "@fullcalendar/core/locales-all";
import { useMediaQuery } from "react-responsive";
import { ColorPicker } from "@src/page/DynamicChartManagement/add";
import rolesConst from "@src/routers/roles/roles.const";

// Popup
const showMessageError = (msg) => {
  showMessage(KEY.ERROR, msg);
};

const showMessageWarning = (msg) => {
  showMessage(KEY.WARMING, msg);
};

export function dayCellClassNames({ date, view }) {
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  const className = isWeekend ? "weekend-day" : "";
  return className ? className : null;
}

export default function DemoApp() {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1104px)" });
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: isSmallScreen ? 24 : 19 },
  };
  const location = useLocation();
  const { allowRoute, roleKey, displayName, userName, selectedFactory } =
    useSelector((state) => state.auth);
  const isMember = roleKey == ROLE_KEY.MEMBER;
  //Modal
  const [modal, setModal] = useState(new classPopup());
  const showModal = () => setModal({ ...modal, show: true });
  const hideModal = () => {
    setModal({ ...modal, show: false });
    setDataUpdate({});
    setDetails([]);
  };

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => setShowLoading(true);
  const closeLoadingSpinner = () => setShowLoading(false);

  //State
  const [calendarMinDate, setCalendarMinDate] = useState(
    moment().startOf("month").subtract(7, "d").format("YYYY/MM/DD")
  );
  const [calendarMaxDate, setCalendarMaxDate] = useState(
    moment().endOf("month").add(7, "d").format("YYYY/MM/DD")
  );
  const [events, setEvents] = useState([]);
  const [dataUpdate, setDataUpdate] = useState({});
  const [form] = Form.useForm();
  const [initPage, setInitPage] = useState(true);
  const [details, setDetails] = useState([]);
  //Input search
  const [type, setType] = useState(new classDdl());

  // // Init
  const handleDatesSet = async ({ start, end }) => {
    const startTime = moment(start).format("YYYY/MM/DD");
    const endTime = moment(end).format("YYYY/MM/DD");
    setCalendarMinDate(startTime);
    setCalendarMaxDate(endTime);
    await search_onClick(false, startTime, endTime);
  };

  const handleDateClick = (date) => {
    setDataUpdate({
      startTime: moment(date.date).startOf("day").unix(),
      endTime: moment(date.date).endOf("day").unix(),
      endDateS: moment(date.date).endOf("day").unix(),
      allDayEvent: true,
      repeat: null,
      remindBefore: null,
      fixedType: "1",
    });
    showModal();
  };

  const handleEventClick = (eventInfo) => {
    const eventOrig = events.find((x) => x.id == eventInfo.event.id);
    showModal();
    setDataUpdate(eventOrig);
    setDetails([...eventOrig.details].splice(1, eventOrig.details.length - 1));
  };

  // Viết hàm validate các trường bắt buộc nhập
  const validateField = () => {
    let result = true;
    let error = {};
    if (!dataUpdate.taskName || !dataUpdate.taskName.trim()) {
      error.taskName = true;
      result = false;
      dataUpdate.taskName = null;
    }
    setDataUpdate({ ...dataUpdate, error: error });
    if (!result) {
      showMessageWarning(COMMON_CONSTANT.BOM_E031);
    }
    return result;
  };

  const setEvent = async (eventInfo) => {
    if (!validateField()) {
      return;
    }
    try {
      let finalStartTime = +dataUpdate.startTime;
      let finalEndTime = +dataUpdate.endTime;

      if (+dataUpdate.startTime == +dataUpdate.endTime) {
        finalEndTime = +dataUpdate.endTime + 1;
      } else {
        if (+dataUpdate.startTime > +dataUpdate.endTime) {
          finalStartTime = +dataUpdate.endTime;
          finalEndTime = +dataUpdate.startTime;
        }
      }
      const sourceDateTime = dataUpdate.repeat
        ? {
          startTime: +finalStartTime,
          endTime: +finalEndTime,
          allDayEvent: dataUpdate.allDayEvent,
        }
        : {
          startTime: +finalStartTime,
          endTime: +finalEndTime,
          allDayEvent: dataUpdate.allDayEvent,
          details: [
            {
              startTime: +finalStartTime,
              endTime: +finalEndTime,
              allDayEvent: dataUpdate.allDayEvent,
            },
          ].concat(details),
        };

      const body = {
        checklistId: dataUpdate.checklistId,
        taskName: dataUpdate.taskName,
        username: isMember ? userName : dataUpdate.username,
        repeat: dataUpdate.repeat,
        remindBefore: dataUpdate.remindBefore,
        fixedType: dataUpdate.fixedType || 1,
        endDateS: (dataUpdate.repeat && endRepeat) ? (dataUpdate.endDateS || dataUpdate.startTime) : null,
        ...sourceDateTime,
      };

      const isUpdate = !!dataUpdate.id;

      const res = await (!isUpdate
        ? API.setEvent(body)
        : API.updateEvent(body, dataUpdate.id));
      if (statusRes(res)) {
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
        search_onClick();
        hideModal();
      } else {
        showMessageError(MSG_CATCH());
      }
    } catch (err) {
      handleCatchError(err);
    }
  };

  const deleteEvent = async (id) => {
    try {
      const res = await API.deleteEvent(id);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      search_onClick();
      hideModal();
    } catch (e) {
      handleCatchError(e);
    }
  };

  // Search
  const search_onClick = async (init, startTime, endTime) => {
    startTime = startTime || calendarMinDate;
    endTime = endTime || calendarMaxDate;
    // Loading
    openLoadingSpinner();
    // Call
    try {
      const res = await API.getEvents(type.value || "", startTime, endTime);
      const resColor = await API.getCalendarConfig();
      const newToOldFormat = [];
      res.data.forEach((x) => {
        x.details.forEach((y) => {
          newToOldFormat.push({
            ...x,
            startTime: y.startTime,
            endTime: y.endTime,
            allDayEvent: y.allDayEvent,
          });
        });
      });
      const eventsData = newToOldFormat.map((x) => {
        const title =
          (x.taskName || x.checklistName) +
          (x.displayName ? `: ${x.displayName}` : "");
        const momentStartTime = moment.unix(x.startTime);
        const momentEndTime = moment.unix(+x.endTime + 1);
        const thisType = resColor.data.find((y) => y.type == x.fixedType);
        return {
          ...x,
          title: title,
          start: momentStartTime.format(
            x.allDayEvent ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
          ),
          end: momentEndTime.format(
            x.allDayEvent ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
          ),
          backgroundColor: thisType.color || "#0080cb",
          borderColor: thisType.color || "#0080cb",
        };
      });
      setEvents(eventsData);
    } catch (error) {
      !init && showMessageError(MSG_CATCH());
    } finally {
      setInitPage(false);
      // Close loading
      closeLoadingSpinner();
    }
  };

  const fetchCheckList = async (value) => {
    try {
      const res = await API_CL.getData({
        params: { checklistName: value, status: 1 },
      });
      if (res && !isEmpty(res.data)) {
        return res.data.data.map((item) => ({
          label: item.checklistName,
          value: item.id,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUser = async (value) => {
    try {
      const res = await API_USER.searchUserRole({
        user: value,
        siteOfficeMasterCode: selectedFactory,
        searchForCalendar: true,
      });
      if (res && !isEmpty(res.data)) {
        return res.data.data.map((item) => ({
          label: `${item.displayName} (${item.userName})`,
          value: item.userName,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (dataUpdate.repeat == 1) {
        setDataUpdate({
          ...dataUpdate,
          endTime: getTimeTodayByEndTime(
            dataUpdate.startTime,
            dataUpdate.endTime
          ).unix(),
        });
      }
    }
  }, [dataUpdate.repeat, dataUpdate.startTime, allowRoute]);

  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      search_onClick(null, null, null);
    }
  }, [allowRoute]);

  const disabledEndDate = (endDate) => {
    const startDate = moment.unix(dataUpdate.startTime);
    if (dataUpdate.repeat == 2) {
      return (
        startDate &&
        (endDate.valueOf() < startDate.valueOf() ||
          moment(endDate).diff(startDate, "week") !== 0)
      );
    }

    if (dataUpdate.repeat == 3) {
      return (
        startDate &&
        (endDate.valueOf() < startDate.valueOf() ||
          moment(endDate).diff(startDate, "month") !== 0)
      );
    }

    return startDate && endDate.valueOf() < startDate.valueOf();
  };

  const [formSearch] = Form.useForm();

  const [fixedType, setFixedType] = useState([]);
  const fetchFixedType = async () => {
    try {
      const res = await API_KBN.getCalendarFixedType();
      const resColor = await API.getCalendarConfig();
      if (res && !isEmpty(res.data)) {
        setFixedType(
          res.data.data.map((item) => {
            const thisType = resColor.data.find((x) => x.type == item.key);
            return {
              label: item.value,
              value: item.key,
              color: thisType.color,
              disabled: item.key != 1 && item.key != 2,
            };
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchFixedType();
  }, []);

  const onAddSetDate = () => {
    setDetails([
      ...details,
      {
        startTime: moment().startOf("day").unix(),
        endTime: moment().endOf("day").unix(),
        allDayEvent: true,
      },
    ]);
  };

  const onDeleteSetDate = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setDetails(newDetails);
  };

  const onChangeDateDetails = (index, value, type) => {
    const newDetails = [...details];
    newDetails[index][type] = value;
    setDetails(newDetails);
  };

  const onChangeAllDayEventDetails = (index, value) => {
    const newDetails = [...details];
    newDetails[index].allDayEvent = value;
    setDetails(newDetails);
  };

  const canNotEdit =
    (isMember && dataUpdate.type == 2) ||
    dataUpdate.fixedType == 3 ||
    dataUpdate.fixedType == 4;

  const [endRepeat, setEndRepeat] = useState(true);
  useEffect(() => {
    if (dataUpdate.startTime > dataUpdate.endDateS) {
      setDataUpdate({ ...dataUpdate, endDateS: moment.unix(dataUpdate.startTime).endOf("day").unix()})
    }
  }, [dataUpdate.startTime]);
  console.log(dataUpdate.endDateS);
  return (
    <>
      <div className="des-content calendar-content">
        <TitlePage name={lang.CALENDAR} />
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          {/* Group input */}
          <div>
            <Form
              name="searchRole"
              layout={"vertical"}
              form={formSearch}
              className="formStyle"
            >
              <Row align="stretch" gutter={[8, 8]}>
                <Col span={24} className="mr10">
                  <Row gutter={[8, 8]}>
                    <Col span="8">
                      <Form.Item
                        name="CATEGORY"
                        label="タイプ"
                        className="rowNotMargin"
                      >
                        <Select
                          placeholder="既定"
                          value={type.value}
                          className={type.error ? STYLE.BORDER_RED : ""}
                          onChange={(v) => {
                            setType({ ...type, value: v });
                          }}
                        >
                          {renderOption(
                            [
                              { key: null, value: "既定" },
                              { key: 1, value: COMMON_CONSTANT.ASSIGN_BY_ME },
                              {
                                key: 2,
                                value: COMMON_CONSTANT.ASSIGN_BY_CHIEF,
                              },
                            ],
                            false
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item className="rowNotMargin" label=" ">
                        <Button
                          className="buttonPC button--info wAuto mr8"
                          onClick={() => {
                            search_onClick(null, null, null);
                          }}
                        >
                          <SearchOutlined className="" />
                          {lang.SEARCH}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          <div>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
                listPlugin,
              ]}
              initialView="timeGridWeek"
              initialDate={new Date()}
              datesSet={handleDatesSet}
              events={events}
              dateClick={handleDateClick}
              eventClick={handleEventClick}
              eventTimeFormat={eventTimeFormat}
              dayCellClassNames={dayCellClassNames}
              dayMaxEvents={3}
              eventMaxStack={3}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
              }}
              locale="ja"
              locales={allLocales}
              buttonText={{
                today: "今日",
                month: "月",
                week: "週",
                day: "日",
                list: "ﾘｽﾄ",
              }}
            />
          </div>
        </Content>
      </div>
      {!isEmpty(dataUpdate) && (
        <Modal
          centered
          width={isPC ? "55%" : "95%"}
          bodyStyle={{}}
          closeIcon={<></>}
          className="modal-msg modal-padding"
          title={
            <div className="center">
              {dataUpdate.id
                ? dataUpdate.taskName
                  ? `${lang.TASK_NAME}：${dataUpdate.taskName}`
                  : `点検表：${dataUpdate.checklistName}`
                : `点検カレンダー作成`}
            </div>
          }
          visible={modal.show}
          footer={[
            ...(canNotEdit
              ? []
              : [
                <Button
                  key={1}
                  type="submit"
                  className="buttonPC button--info -- wAuto"
                  onClick={() => {
                    setEvent(null);
                  }}
                >
                  <SaveOutlined />
                  {lang.OK}
                </Button>,
                <Button
                  key={2}
                  className="buttonPC button--error -- wAuto"
                  onClick={() => {
                    showMessageChange(() => deleteEvent(dataUpdate.id));
                  }}
                  hidden={!dataUpdate.id}
                >
                  <DeleteOutlined />
                  {lang.DELETE}
                </Button>,
              ]),
            ...[
              <Button
                key={3}
                className="buttonPC button--outline --todo-- wAuto"
                onClick={hideModal}
              >
                <CloseCircleOutlined />
                {lang.CLOSE}
              </Button>,
            ],
          ]}
        >
          <Form
            {...layout}
            layout={isSmallScreen ? "vertical" : "horizonal"}
            form={form}
          >
            <Form.Item label={lang.TASK_NAME}>
              <Input
                className={
                  dataUpdate.error && dataUpdate.error.taskName
                    ? STYLE.BORDER_RED
                    : ""
                }
                value={dataUpdate.taskName}
                placeholder={lang.TASK_NAME}
                onChange={(e) => {
                  setDataUpdate({
                    ...dataUpdate,
                    taskName: e.target.value,
                    error: {
                      ...dataUpdate.error,
                      checklistId: false,
                      taskName: false,
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="点検表">
              <DebounceSelect
                className={
                  dataUpdate.error && dataUpdate.error.checklistId
                    ? STYLE.BORDER_RED
                    : ""
                }
                value={
                  dataUpdate.checklistId
                    ? {
                      value: dataUpdate.checklistId,
                      label: dataUpdate.checklistName,
                    }
                    : { value: null }
                }
                placeholder="点検表"
                fetchOptions={fetchCheckList}
                onChange={(newValue) => {
                  setDataUpdate({
                    ...dataUpdate,
                    checklistId: newValue ? newValue.value : null,
                    checklistName: newValue ? newValue.label : null,
                    error: {
                      ...dataUpdate.error,
                      checklistId: false,
                      taskName: false,
                    },
                  });
                }}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item label="担当者">
              {!isMember && (
                <DebounceSelect
                  allowClear
                  className={
                    dataUpdate.error && dataUpdate.error.username
                      ? STYLE.BORDER_RED
                      : ""
                  }
                  value={
                    dataUpdate.username
                      ? {
                        value: dataUpdate.username,
                        label: `${dataUpdate.displayName} (${dataUpdate.username})`,
                      }
                      : { value: null }
                  }
                  placeholder="担当者"
                  fetchOptions={fetchUser}
                  onChange={(newValue) => {
                    if (!newValue) {
                      setDataUpdate({
                        ...dataUpdate,
                        username: null,
                        displayName: null,
                        error: { ...dataUpdate.error, username: false },
                      });
                      return;
                    }
                    setDataUpdate({
                      ...dataUpdate,
                      username: newValue.value,
                      displayName: newValue.label.substring(
                        0,
                        newValue.label.indexOf(" (")
                      ),
                      error: { ...dataUpdate.error, username: false },
                    });
                  }}
                />
              )}
              {isMember && (
                <Input defaultValue={`${displayName} (${userName})`} disabled />
              )}
            </Form.Item>
            <Form.Item label={"タイプ"}>
              <Row>
                <Col md={22} xs={20}>
                  <Select
                    value={
                      // fixedType.find((x) => x.value == dataUpdate.fixedType) ? fixedType.find((x) => x.value == dataUpdate.fixedType).label : null
                      dataUpdate.fixedType
                    }
                    onChange={(e) =>
                      setDataUpdate({ ...dataUpdate, fixedType: e })
                    }
                  >
                    {fixedType.map((item) => {
                      return (
                        <Select.Option
                          key={item.key}
                          value={item.value}
                          style={{}}
                          disabled={item.disabled}
                        >
                          <div
                            className="mr8"
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: "50%",
                              backgroundColor: item.color,
                              display: "inline-block",
                            }}
                          ></div>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={2}>
                  <ButtonModalType
                    callback={() => {
                      fetchFixedType();
                      search_onClick();
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="繰返">
              <Select
                placeholder="頻度"
                disabled={details.length > 0}
                value={repeat.find((x) => x.value == dataUpdate.repeat).label}
                options={repeat}
                onChange={(e) => setDataUpdate({ ...dataUpdate, repeat: e })}
              />
            </Form.Item>
            {
              dataUpdate.repeat && <Form.Item label="反復の終了日">
                <div>
                  <DatePicker
                    disabledDate={(currentDate) => {
                      return dataUpdate.startTime && (dataUpdate.startTime * 1000 >= currentDate.valueOf());
                    }}
                    className="mr4"
                    allowClear={false}
                    format="YYYY/MM/DD"
                    value={moment.unix(dataUpdate.endDateS)}
                    onChange={(e) =>
                      setDataUpdate({
                        ...dataUpdate,
                        endDateS: moment(e).endOf("day").unix()
                      })
                    }
                    disabled={!endRepeat}
                  />
                </div>
                <div>
                  <Checkbox
                    onChange={() => setEndRepeat(!endRepeat)}
                    checked={!endRepeat}
                  >
                    終了日なし
                  </Checkbox>
                </div>
              </Form.Item>
            }
            <Form.Item label="日時">
              <div>
                <DatePicker
                  disabledDate={(currentDate) => {
                    return dataUpdate.endTime && (dataUpdate.endTime * 1000 <= currentDate.valueOf());
                  }}
                  className="mr4"
                  showTime={!dataUpdate.allDayEvent}
                  allowClear={false}
                  format={
                    dataUpdate.allDayEvent ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm"
                  }
                  value={moment.unix(dataUpdate.startTime)}
                  onChange={(e) =>
                    setDataUpdate({
                      ...dataUpdate,
                      startTime: dataUpdate.allDayEvent
                        ? moment(e).startOf("day").unix()
                        : moment(e).unix(),
                    })
                  }
                />
                ~
                <DatePicker
                  disabledDate={(currentDate) => {
                    return dataUpdate.startTime && (dataUpdate.startTime * 1000 >= currentDate.valueOf());
                  }}
                  className="ml4"
                  showTime={!dataUpdate.allDayEvent}
                  allowClear={false}
                  format={
                    dataUpdate.allDayEvent ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm"
                  }
                  value={moment.unix(dataUpdate.endTime)}
                  onChange={(e) =>
                    setDataUpdate({
                      ...dataUpdate,
                      endTime: dataUpdate.allDayEvent
                        ? moment(e).endOf("day").unix()
                        : moment(e).unix(),
                    })
                  }
                />
              </div>
              <div>
                <Checkbox
                  onChange={() =>
                    setDataUpdate({
                      ...dataUpdate,
                      allDayEvent: !dataUpdate.allDayEvent,
                    })
                  }
                  checked={dataUpdate.allDayEvent}
                >
                  終日イベント
                </Checkbox>
              </div>
              {details.map((x, index) => {
                return (
                  <>
                    <div key={index} className="mt4">
                      <DatePicker
                        disabledDate={(currentDate) => {
                          return x.endTime && (x.endTime * 1000 <= currentDate.valueOf());
                        }}
                        className="mr4"
                        showTime={!x.allDayEvent}
                        allowClear={false}
                        format={
                          x.allDayEvent ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm"
                        }
                        value={
                          x.startTime ? moment.unix(x.startTime) : moment.unix()
                        }
                        onChange={(e) =>
                          onChangeDateDetails(
                            index,
                            x.allDayEvent
                              ? moment(e).startOf("day").unix()
                              : moment(e).unix(),
                            "startTime"
                          )
                        }
                      />
                      ~
                      <DatePicker
                        disabledDate={(currentDate) => {
                          return x.startTime && (x.startTime * 1000 >= currentDate.valueOf());
                        }}
                        className="ml4"
                        showTime={!x.allDayEvent}
                        allowClear={false}
                        format={
                          x.allDayEvent ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm"
                        }
                        value={moment.unix(x.endTime)}
                        onChange={(e) =>
                          onChangeDateDetails(
                            index,
                            x.allDayEvent
                              ? moment(e).endOf("day").unix()
                              : moment(e).unix(),
                            "endTime"
                          )
                        }
                      />
                      <span
                        className="ml8"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(index) => onDeleteSetDate(index)}
                      >
                        x
                      </span>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(e) =>
                          onChangeAllDayEventDetails(index, e.target.checked)
                        }
                        checked={x.allDayEvent}
                      >
                        終日イベント
                      </Checkbox>
                    </div>
                  </>
                );
              })}
              <Button
                className="mt8"
                onClick={onAddSetDate}
                disabled={dataUpdate.repeat}
              >
                + 追加
              </Button>
            </Form.Item>
            <Form.Item label={lang.REMIND}>
              <Select
                value={
                  dataUpdate.remindBefore
                    ? remindBefore.find(
                      (x) => x.value == dataUpdate.remindBefore
                    ).label
                    : null
                }
                options={remindBefore}
                onChange={(e) =>
                  setDataUpdate({ ...dataUpdate, remindBefore: e })
                }
              />
            </Form.Item>
          </Form>
          <Row>
            <Col span={20} offset={4}>
              {(isMember && dataUpdate.type == 2) && (<p className="red mb0">*他の人が作成したスケジュールを更新することはできません</p>)}
              {(dataUpdate.fixedType == 3 || dataUpdate.fixedType == 4) && (<p className="red mb0">*このイベントは編集できません</p>)}
            </Col>
          </Row>
        </Modal>
      )}
      <Loading show={showLoading}></Loading>
    </>
  );
}
const eventTimeFormat = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  meridiem: false,
};

const repeat = [
  { key: null, value: null, label: "繰り返し無し" },
  { key: 1, value: 1, label: "毎日" },
  { key: 2, value: 2, label: "毎週" },
  { key: 3, value: 3, label: "毎月" },
  { key: 6, value: 6, label: "3ヶ月" },
  { key: 7, value: 7, label: "6ヶ月" },
  { key: 4, value: 4, label: "年間" },
];

const remindBefore = [
  { key: null, value: null, label: "通知しない" },
  { key: 1, value: 5, label: "5分前に" },
  { key: 2, value: 15, label: "15分前に" },
  { key: 3, value: 30, label: "30分前に" },
  { key: 4, value: 60, label: "1時間前に" },
  { key: 5, value: 120, label: "2時間前に" },
  { key: 6, value: 720, label: "12時間前に" },
  { key: 7, value: 1440, label: "1日前までに" },
  { key: 8, value: 10080, label: "1週間前までに" },
];

export const CLASS_NAME_BY_FIXED_TYPE = {
  1: "event--confirmed",
  2: "event--plan",
  3: "event--repair",
  4: "event--calibration",
};

export function DebounceSelect({
  fetchOptions,
  debounceTimeout = 400,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      setIsFirstTime(false);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onClick={() => isFirstTime && debounceFetcher("")}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <LoadingOutlined size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

const getTimeTodayByEndTime = (startTime, endTime) => {
  const startDayTimeStamp = moment.unix(startTime).startOf("day").unix();
  const diffTime = endTime - moment.unix(endTime).startOf("day").unix();
  return moment.unix(startDayTimeStamp + diffTime);
};

export const ButtonModalType = ({ callback }) => {
  const { roles } = useSelector((state) => state.auth);
  const isAdmin = [
    rolesConst.OFFICE_ADMIN,
    rolesConst.HEAD_OFFICE_ADMIN,
  ].includes(roles);

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [visible, setVisible] = useState(false);
  const [classification, setClassification] = useState([]);

  const getClassification = async () => {
    try {
      const res = await API_KBN.getCalendarFixedType();
      const resColor = await API.getCalendarConfig();
      if (res && !isEmpty(res.data)) {
        setClassification(
          res.data.data.map((item) => {
            const thisType = resColor.data.find((x) => x.type == item.key);
            return {
              label: item.value,
              value: item.key,
              color: thisType.color,
              disabled: item.key != 1 && item.key != 2,
            };
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateClassification = async () => {
    try {
      await API.setCalendarConfig(
        classification.map((item) => {
          return {
            type: item.value,
            color: item.color,
          };
        })
      );
      Modal.success({
        centered: true,
        title: <span style={{ fontWeight: "bold" }}>{lang.SUCCESS}</span>,
        content: COMMON_CONSTANT.BOM_C002,
        okText: lang.OK,
        onOk: callback,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const setModalOn = () => {
    getClassification();
    setVisible(true);
  };

  const setModalOff = () => {
    setVisible(false);
  };

  const handleOK = async () => {
    await updateClassification();
    setVisible(false);
  };

  const column = [
    {
      title: "色",
      dataIndex: "color",
      key: "color",
      width: "10%",
      render: (color, record, index) => {
        return (
          <ColorPicker
            color={color}
            onChange={(color) => {
              const newClassification = [...classification];
              newClassification[index].color = color;
              setClassification(newClassification);
            }}
          />
        );
      },
    },
    {
      title: "タイプ",
      dataIndex: "label",
      key: "label",
      render: (text, record, index) => {
        return text;
      },
    },
  ];

  return (
    <>
      <Button
        disabled={!isAdmin}
        className="buttonPC button--info wAuto ml12"
        onClick={setModalOn}
        style={{ minWidth: "unset" }}
      >
        <FormOutlined />
      </Button>
      <Modal
        centered
        width={isPC ? "40%" : "100%"}
        closeIcon={<></>}
        className=""
        title={null}
        visible={visible}
        onCancel={setModalOff}
        footer={
          <Row>
            <Col span={4} style={{ textAlign: "left" }}></Col>
            <Col span={20} style={{ textAlign: "right" }}>
              <Button
                key={1}
                className="buttonPC button--info wAuto"
                onClick={() => {
                  handleOK();
                }}
              >
                {lang.OK}
              </Button>
              <Button
                onClick={setModalOff}
                className="buttonPC --todo-- wAuto  button--outline"
              >
                {lang.CANCEL}
              </Button>
            </Col>
          </Row>
        }
      >
        <Table
          className="tableAddMachine"
          scroll={{ y: 300 }}
          rowKey="ItemOrder"
          dataSource={classification}
          columns={column}
          pagination={false}
          bordered
        />
      </Modal>
    </>
  );
};
