import {
  ClearOutlined,
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import Loading from "../../../component/Desktop/loading/loading";
import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  lang,
} from "../../../constants/common.const";
import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  pushObject2QueryReplace,
  renderOption,
  showMessage,
  statusRes,
} from "../../../constants/utils";
import classDdl from "../../../models/control/dropdownlist";
import classText from "../../../models/control/text";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ApiProcessMangement } from "../../../api/backend/prcessManagement";
import TitlePage from "../../../component/Desktop/titlePage";
import classGrid from "../../../models/control/grid";
import { checkRoleScreen } from "../../../utils/roles";

function ListProcess() {
  const objSearch = getQuerySearch();
  const { selectedFactory } = useSelector((state) => state.auth);
  const [facilityName, setFacilityName] = useState(
    new classDdl(objSearch.facilityName || "")
  );
  const [processName, setProcessName] = useState(
    new classText(objSearch.processName || "")
  );
  const [formSearch] = Form.useForm();
  const [grid, setGrid] = useState(new classGrid());
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(objSearch.page || 1);
  const { allowRoute, isLeader } = useSelector((state) => state.auth);
  const location = useLocation();
  const clearObj = {
    value: null,
  };
  const clearInputSearch = () => {
    setProcessName({ ...processName, ...clearObj });
    setFacilityName({ ...facilityName, ...clearObj });
    formSearch.resetFields();
    formSearch.submit();
    history.push(location.pathname);
  };

  const initColumns = [
    {
      title: lang.NAME_PROCESS,
      dataIndex: "processName",
      align: "center",
      width: "20%",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              <Link to={"/ViewProcess/" + row.id} className="white-space">
                {text}
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: lang.NAME_FACILITY,
      dataIndex: "facilityName",
      width: "20%",
      align: "center",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>{text}</Col>
          </Row>
        );
      },
    },
    {
      title: lang.PROCESS_MASTER_NAME,
      dataIndex: "processMasterName",
      align: "center",
      width: "20%",
    },

    {
      title: lang.STATUS,
      dataIndex: "status",
      align: "center",
      width: "20%",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: "20%",
      render: (text, row) => {
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={!isLeader} className="mr5 btnEdit">
                <Link to={"/EditProcess/" + row.id} className="w100">
                  <EditFilled />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={!isLeader}
                onClick={() => handleDelete(row.id)}
                className="mr5 btnDelete"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const handleDelete = async (id) => {
    const deleteProcess = async () => {
      setShowLoading(true);
      try {
        Modal.destroyAll();
        await ApiProcessMangement.deleteProcess({
          processId: id,
        });
        await onSearch();
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        let msg;
        if (ex.response) {
          if (ex.response.status === 400) {
            msg = ex.response.data;
          }
        }
        showMessage(KEY.ERROR, msg || MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    };

    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: deleteProcess,
      centered: true,
    });
  };
  const onSearch = async (init = true) => {
    try {
      setShowLoading(true);
      const response = await ApiProcessMangement.searchProcess({
        facilityCode: facilityName.value,
        processName: processName.value ? processName.value.trim() : null,
      });
      setGrid({ ...grid, data: response.data, count: response.data.length });
      if (!init) {
        setCurrentPage(1);
        pushObject2Query({
          facilityName: facilityName.value,
          processName: processName.value,
          page: 1,
        });
      } else {
        pushObject2Query({
          facilityName: facilityName.value,
          processName: processName.value,
          page: currentPage,
        });
      }
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  //api fill facility options
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const responseFacility = await ApiProcessMangement.fetchFacility({
          siteOfficeMasterCode: selectedFactory,
        });
        if (statusRes(responseFacility)) {
          const { data } = responseFacility;
          const listOptionfacility = data.map((item) => ({
            key: item.facilityCode,
            value: item.facilityName,
          }));
          setFacilityName({ ...facilityName, options: listOptionfacility });
        }
      } catch (error) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchFacility();
  }, [selectedFactory, allowRoute]);
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) onSearch();
  }, [allowRoute]);
  return (
    <div className="des-content">
      <TitlePage name={lang.PROCESS_MANAGEMENT} />
      <Content
        className="site-layout-background m24"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Form form={formSearch} onFinish={() => onSearch(false)}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item className="rowNotMargin" name="facilityName">
                <Select
                  defaultValue={objSearch.facilityName || null}
                  placeholder={lang.NAME_FACILITY}
                  value={facilityName.value}
                  className="w100"
                  onChange={(v) => {
                    setFacilityName({
                      ...facilityName,
                      value: v,
                    });
                  }}
                >
                  {renderOption(facilityName.options)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="rowNotMargin" name="processName">
                <Input
                  defaultValue={objSearch.processName || ""}
                  placeholder={lang.NAME_PROCESS}
                  value={processName.value}
                  className="w100"
                  onChange={(e) => {
                    setProcessName({
                      ...processName,
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="right">
              <Button htmlType="submit" className="buttonPC button--info wAuto">
                <SearchOutlined />
                {lang.SEARCH}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={clearInputSearch}
              >
                <ClearOutlined />
                {lang.CLEAR}
              </Button>
            </Col>
          </Row>
        </Form>
      </Content>
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Row justify="space-between ">
          <Col span={12}>
            {isLeader && (
              <Button
                type="link"
                onClick={() => {
                  history.push("/AddProcess");
                }}
                className="btnAddRecord mb15"
              >
                <AddIcon />
                {lang.ADD}
              </Button>
            )}
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              該当件数 <span>{grid.count}件</span>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey={(record) => record.id}
          pagination={{
            ...CONFIG.paging,

            current: Number(currentPage) || 1,
            onChange: (curr) => {
              setCurrentPage(curr);
              pushObject2QueryReplace({ page: curr });
            },
          }}
          dataSource={grid.data}
          columns={initColumns}
          className="gridSearch"
        />
      </Content>
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default ListProcess;
