/* eslint-disable react-hooks/exhaustive-deps */
import {
  Layout,
  Form,
  Input,
  Space,
  Row,
  Col,
  Select,
  Button,
  Table,
  Checkbox,
  Modal,
  Switch,
  Tag,
  DatePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput2";
import TitlePage from "../../component/Desktop/titlePage";
import FooterPage from "../../component/footer/footerPage";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import classText from "../../models/control/text";
import classGrid from "../../models/control/grid";
import classDdl from "../../models/control/dropdownlist";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/inventory";
import {
  ExportOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileTextFilled,
  RollbackOutlined,
  SettingFilled,
  SaveOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  getSortColumn_local,
  inputNumberRequired,
  isEmpty,
  MSG_CATCH,
  onSortColumn,
  removeItemArr,
  renderDefaultColumn,
  renderOption,
  renderSortColumn,
  saveSortColumn_local,
  showMessage,
  showMessageChange,
  statusRes,
} from "../../constants/utils";
import classPopup from "../../models/control/popup";
import BoxSearchMachine from "../../component/Desktop/BoxSearchMachine";
import InputPositiveNumber from "../../component/Desktop/inputPositiveNumber";
import BoxItemWarehouses from "../Import_Inventory/boxItemWarehouses";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { checkRoleScreen } from "../../utils/roles";

const { Content } = Layout;
let initPage = false;

export default function ExportInventory() {
  const KEY_PAGE = "EXP_INV_2"; // Change key localStorage when column update
  const KEY_MACHINE = "machines";
  const KEY_INPUT_MACHINE = "inputMachine";
  const KEY_QUANTITY_MACHINE = "quantity";
  const KEY_FACILITY = "facilityCode";

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const isUpdate = window.location.pathname.indexOf("/update") > -1;
  const { id, id: viewId } = useParams();
  const modeView = viewId && !isUpdate;
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  // Loading
  const openLoadingSpinner = () => setShowLoading(true);
  const closeLoadingSpinner = () => setShowLoading(false);
  // State screen
  const [grid, setGrid] = useState(new classGrid());
  const [selSearch, setSelSearch] = useState([]);
  const [selAdded, setSelAdded] = useState([]);
  const [data, setData] = useState({
    code: new classText(),
    createDate: new classText(),
    createUser: new classText(),
    workReport: { ...new classDdl() },
    items: [],
  });
  const [canEdit, setCanEdit] = useState(false);
  const [selGrid, setSelGrid] = useState([]);
  const [popupMachine, setPopupMachine] = useState({
    ...new classPopup(),
    list: [],
  });
  const [checkedList, setCheckedList] = useState([]);
  const [facility, setFacility] = useState([]);
  const [flagChangeData, setFlagChangeData] = useState(false);

  const [modeSetting, setModeSetting] = useState(false);
  const [columnsItem, setColumnsItem] = useState(
    getSortColumn_local(KEY_PAGE) || [
      // Item
      {
        name: lang.ITEM,
        dataIndex: "inventoryItemName",
        align: "center",
      },
      // Warehouses
      {
        name: lang.WAREHOUSES_NAME,
        dataIndex: "inventoryWarehouseName",
        width: "100px",
        align: "center",
      },
      // Item code
      {
        name: lang.ITEM_CODE,
        dataIndex: "inventoryWarehouseItemCode",
        width: "100px",
        align: "center",
      },

      // Category
      {
        name: lang.CATEGORY_NAME,
        dataIndex: "categoryName",
        align: "center",
      },
      // 17. Đơn vị tính
      {
        name: lang.INVENTORY_UNIT,
        dataIndex: "inventoryUnit",
        align: "center",
        width: "100px",
      },
      // 18. Số lượng trong kho
      {
        name: lang.STOCK_QUANTITY,
        dataIndex: "stockQuantity",
        width: "150px",
        align: "center",
      },
      // 21. Số lượng xuất kho
      {
        name: lang.QUANTITY_EXPORT,
        dataIndex: "inventoryQuantity",
        width: "200px",
        align: "center",
      },
      // 19-20 . Machine
      {
        name: lang.MACHINES,
        width: "400px",
        dataIndex: lang.MACHINES,
      },
      // Facility
      {
        name: lang.FACILITY,
        dataIndex: KEY_FACILITY,
        width: "180px",
        align: "center",
      },
      // Số phiếu
      {
        name: lang.ORDER_NUMBER,
        dataIndex: "orderNumber",
        align: "center",
      },
      // Mô tả
      {
        name: lang.REMARKS,
        dataIndex: "remarks",
        align: "center",
      },
    ]
  );
  const [columnsItem_draft, setColumnsItem_draft] = useState(null);

  // Addon column
  const addonColumn = (arr) => {
    return arr.map((col) => {
      // Input
      if (["orderNumber", "remarks"].includes(col.dataIndex)) {
        col = {
          ...col,
          ...renderCell(col.dataIndex, {
            type: KEY.TEXT,
            checkRequired: false,
          }),
        };
      }
      // Machine
      if (lang.MACHINES === col.dataIndex) {
        col = {
          ...col,
          render: (txt, row, indexRow) => {
            // List machine
            const selMachine =
              row[KEY_MACHINE] &&
              row[KEY_MACHINE].map((machine, indexMachine) => {
                // Check select
                const sCheck =
                  isEmpty(machine[KEY_QUANTITY_MACHINE]) ||
                  Number(machine[KEY_QUANTITY_MACHINE]) === 0;

                machine[KEY_QUANTITY_MACHINE] = isEmpty(
                  machine[KEY_QUANTITY_MACHINE]
                )
                  ? null
                  : machine[KEY_QUANTITY_MACHINE];

                return (
                  <div key={indexMachine}>
                    <Row gutter={[5, 10]} className="align-center">
                      <Col
                        style={
                          !modeView && {
                            width: "calc(100% - 50px)",
                          }
                        }
                      >
                        <InputPositiveNumber
                          disabled={modeView}
                          addonBefore={
                            <div
                              style={{ width: "200px" }}
                              className="left textOverflow"
                              title={machine.machineForSiteOfficeName}
                            >
                              {machine.machineForSiteOfficeName}
                            </div>
                          }
                          className={
                            (sCheck ? STYLE.BORDER_RED : "") + " right"
                          }
                          maxLength={10}
                          value={machine[KEY_QUANTITY_MACHINE]}
                          onChange={(e) => {
                            onChangeQuantityMachine(e, indexRow, indexMachine);
                          }}
                        />
                      </Col>
                      {!modeView && (
                        <Col
                          style={{
                            width: "50px",
                          }}
                        >
                          <div
                            className="center mt5  pointer"
                            onClick={() => {
                              onDeleteMachine(indexRow, indexMachine);
                            }}
                          >
                            <CloseCircleOutlined />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              });

            // DOM
            return row[KEY_INPUT_MACHINE] ? (
              <>
                {/* Machine | Quantity */}
                {selMachine}
                {/* Button add */}
                {!modeView && (
                  <Row>
                    <Col span={7}>
                      <Button
                        className="buttonPC button--info w100"
                        onClick={() => {
                          openPopupMachine(row, indexRow);
                        }}
                      >
                        <PlusOutlined className="white" /> {lang.ADD_MACHINE}
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                {row[KEY_MACHINE].map((machine, indexMachine) => {
                  return (
                    <Tag
                      className="mb3 mt3 tagMachine"
                      key={machine.machineForSiteOfficeCode}
                      closable={!modeView}
                      onClose={() => {
                        onDeleteMachine(indexRow, indexMachine);
                      }}
                    >
                      {machine.machineForSiteOfficeName}
                    </Tag>
                  );
                })}
                {!modeView && (
                  <Tag
                    color="#0080cb"
                    className="pointer tagMachine"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      openPopupMachine(row, indexRow);
                    }}
                  >
                    {lang.ADD_MACHINE}
                  </Tag>
                )}
              </>
            );
          },
        };
      }
      // total
      if ("inventoryQuantity" === col.dataIndex) {
        col = {
          ...col,
          render: (txt, row, index) => {
            const INPUT_MACHINE = row[KEY_INPUT_MACHINE];
            const totalMachine = row[KEY_MACHINE] // Default = 0
              ? row[KEY_MACHINE].reduce((preV, v) => {
                  return preV + Number(v[KEY_QUANTITY_MACHINE] || 0);
                }, 0)
              : 0;
            const err = inputNumberRequired(INPUT_MACHINE ? totalMachine : txt);

            const domInput = {
              // Error only mode input
              className: (err ? "border-red" : "") + " w100 right",
              // Mode machine: Total machine
              value: INPUT_MACHINE ? totalMachine : txt,
              onChange: (e) => {
                const v = e.target.value;
                // now
                setCellValue(
                  index,
                  "inventoryQuantity",
                  isEmpty(v) ? v : Number(v)
                );
              },
            };

            return modeView ? (
              txt
            ) : (
              // Case buhin
              <Row className="align-center">
                <InputPositiveNumber
                  {...domInput}
                  maxLength={10}
                  disabled={INPUT_MACHINE}
                  addonBefore={
                    <Switch
                      checked={INPUT_MACHINE}
                      checkedChildren={lang.MACHINES}
                      unCheckedChildren={lang.INPUT}
                      onChange={(e) => {
                        onSwitchInput(e, index);
                      }}
                    />
                  }
                />
              </Row>
            );
          },
        };
      }
      // Facility
      if (KEY_FACILITY === col.dataIndex) {
        col = {
          ...col,
          render: (txt, row, index) => {
            const err = isEmpty(txt) ? "border-red" : "";

            return modeView ? (
              row.facilityName
            ) : (
              <Select
                value={txt}
                className={err + " w100"}
                onChange={(v) => {
                  onChangeFacility(v, index);
                }}
              >
                {renderOption(facility)}
              </Select>
            );
          },
        };
      }

      return col;
    });
  };

  const setDataWidthKey = (key, objData) => {
    const obj = { ...data };
    obj[key] = {};
    setData({
      ...data,
      key: objData,
    });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      openLoadingSpinner();
      //
      Promise.all([
        viewId && getDetail(), // Add
        !modeView && getListWorkReport(), // View
        getFacility(),
      ])
        .catch((err) => {
          history.push("/ExportInventory");
        })
        .then(() => {
          initPage = true;
          closeLoadingSpinner();
        });
    }
    return () => {
      initPage = false;
    };
  }, [allowRoute]);
  //  grid -- mapping -- checkSearch
  useEffect(() => {
    const listIdAdded = data.items.map((v) => v.inventoryWarehouseItemCode);
    setSelAdded(listIdAdded);
  }, [data.items]);

  // set state detail
  const getDetail = () => {
    return API.getDetailById(id).then((res) => {
      if (statusRes(res)) {
        const d = res.data;
        setCanEdit(d.canEdit);
        setData((preData) => {
          return {
            ...preData,
            code: { ...preData.code, value: d.inventoryTransactionCode },
            workReport: { ...preData.workReport, value: d.workReportName },
            createDate: { ...preData.createDate, value: d.transactionTime },
            createUser: { ...preData.performer, value: d.performer },
            items: d.items.map((v) => {
              // Add key for item machine
              v[KEY_MACHINE] = v.itemDetails;
              v[KEY_INPUT_MACHINE] =
                v[KEY_MACHINE][0] &&
                Number(v[KEY_MACHINE][0][KEY_QUANTITY_MACHINE]) > 0;
              return v;
            }),
          };
        });
      } else {
        throw new Error();
      }
    });
  };

  // set list workReport
  const getListWorkReport = () => {
    return KBN.getWorkReportForExport({
      offset: 0,
    })
      .then((res) => {
        if (statusRes(res)) {
          //
          setData((preData) => {
            return {
              ...preData,
              workReport: { ...preData.workReport, options: res.data.data },
            };
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const getFacility = () => {
    return KBN.getFacility()
      .then((res) => {
        setFacility(
          res.data.map((v) => {
            v.key = v.facilityCode;
            v.value = v.facilityName;
            return v;
          })
        );
      })
      .catch((err) => {
        throw err;
      });
  };

  // Change WorkReport
  const onChangeWorkReport = (v) => {
    let indexSelect;
    data.workReport.options.forEach((o, i) => {
      if (o.id === v) {
        indexSelect = i;
      }
    });

    // Fill data workReport to grid
    const fillItem2Grid = () => {
      setData({
        ...data,
        workReport: { ...data.workReport, value: v },
        items: JSON.parse(
          JSON.stringify(
            data.workReport.options[indexSelect].items.map((row) => {
              // Add key for item machine
              row[KEY_MACHINE] = row.itemDetails || [];
              row.inventoryQuantity = 0;
              row[KEY_INPUT_MACHINE] = true;
              return row;
            })
          )
        ),
      });
    };

    // Case
    if (
      initPage &&
      !modeView &&
      (grid.data.length > 0 || data.items.length > 0)
    ) {
      showMessageChange(() => {
        setSelAdded([]);
        setSelSearch([]);
        setSelGrid([]);
        setGrid(new classGrid());
        fillItem2Grid();
      }, COMMON_CONSTANT.BOM_C021);
    } else {
      fillItem2Grid();
    }
  };
  // Add item to Grid
  const onAddItems2Grid = () => {
    // Set data export items
    setData({
      ...data,
      items: JSON.parse(
        JSON.stringify([
          ...data.items,
          ...grid.data
            // Only item new
            .filter(
              (v) =>
                selAdded.indexOf(v.inventoryWarehouseItemCode) < 0 && // !Added
                selSearch.indexOf(v.inventoryWarehouseItemCode) >= 0 // Checked
            )
            // List machine draft
            .map((v) => {
              // Add key for item machine
              v[KEY_MACHINE] = [];
              v[KEY_INPUT_MACHINE] = false;
              return v;
            }),
        ])
      ),
    });
  };
  const onDeleteItems = () => {
    showMessageChange(() => {
      // Deleting...
      const listNonSelect = data.items.filter(
        (v) => selGrid.indexOf(v.inventoryWarehouseItemCode) < 0
      );
      // clear select
      setSelGrid([]);
      // Deleting...
      setData({ ...data, items: listNonSelect });
    }, COMMON_CONSTANT.BOM_C001);
  };

  //  Import/Export Items
  const renderCell = (
    colName,
    { type = KEY.TEXT, checkRequired = true, maxLength = 255 }
  ) => {
    // mode: View
    if (modeView) {
      return;
    }
    // mode: ADD
    return {
      render: (txt, row, index) => {
        const err = checkRequired ? inputNumberRequired(txt) : false;

        return (
          <Input
            className={err ? "border-red" : ""}
            value={txt}
            maxLength={maxLength}
            type={type}
            onChange={(e) => {
              let v = e.target.value;

              if (type !== KEY.TEXT) {
                v = isEmpty(v) ? v : Number(v);
              }

              // now
              setCellValue(index, colName, v);
            }}
          />
        );
      },
    };
  };
  const setCellValue = (index, colName, value) => {
    const items = [...data.items];
    items[index][colName] = value;
    setData({
      ...data,
      items: items,
    });
  };
  const columnsItem_checkbox = {
    title: (
      <Checkbox
        checked={data.items.length > 0 && selGrid.length === data.items.length}
        onChange={(e) => {
          setSelGrid(
            e.target.checked
              ? data.items.map((v) => v.inventoryWarehouseItemCode)
              : []
          );
        }}
      />
    ),
    align: "center",
    fixed: modeSetting ? false : "left",
    width: "40px",
    render: (txt, row) => {
      return (
        <Checkbox
          checked={selGrid.indexOf(row.inventoryWarehouseItemCode) >= 0}
          onChange={(e) => {
            let listCheck = [...selGrid];
            if (e.target.checked) {
              // Checked
              listCheck.push(row.inventoryWarehouseItemCode);
            } else {
              //UnChecked
              listCheck = listCheck.filter(
                (v) => v !== row.inventoryWarehouseItemCode
              );
            }
            setSelGrid(listCheck);
          }}
        />
      );
    },
  };

  //
  const onSwitchInput = (e, indexRow) => {
    const listData = [...data.items];
    listData[indexRow][KEY_INPUT_MACHINE] = e;
    setData({
      ...data,
      items: listData,
    });
  };

  const openPopupMachine = (e, indexRow) => {
    setPopupMachine({
      ...popupMachine,
      show: true,
      list: data.items[indexRow][KEY_MACHINE], //List filter machine added
      indexRow: indexRow,
    });
  };
  const onAddMachine = (e) => {
    const listData = [...data.items];
    // Push list machine
    listData[popupMachine.indexRow][KEY_MACHINE] = [
      ...listData[popupMachine.indexRow][KEY_MACHINE],
      ...checkedList.map((obj) => {
        const codeMachine = Object.keys(obj)[0];
        return obj[codeMachine];
      }),
    ];
    setCheckedList([]);
    setPopupMachine({
      ...popupMachine,
      show: false,
    });
    setData({
      ...data,
      items: listData,
    });
  };
  const onDeleteMachine = (indexRow, indexMachine) => {
    const listData = [...data.items];
    // Remove
    listData[indexRow][KEY_MACHINE] = removeItemArr(
      listData[indexRow][KEY_MACHINE],
      listData[indexRow][KEY_MACHINE][indexMachine]
    );

    setData({
      ...data,
      items: listData,
    });
  };
  const onChangeQuantityMachine = (e, indexRow, indexMachine) => {
    const v = e.target.value;
    const listData = [...data.items];
    listData[indexRow][KEY_MACHINE][indexMachine][KEY_QUANTITY_MACHINE] = v;

    setData({
      ...data,
      items: listData,
    });
  };
  const onChangeFacility = (v, indexRow) => {
    const listData = [...data.items];
    listData[indexRow][KEY_FACILITY] = v;

    setData({
      ...data,
      items: listData,
    });
  };

  // Switch render
  const renderColumns = modeSetting
    ? renderSortColumn(
        addonColumn(columnsItem_draft || columnsItem),
        (v, index) => {
          // onSort
          onSortColumn(v, index, [columnsItem_draft, setColumnsItem_draft]);
        }
      )
    : renderDefaultColumn(addonColumn(columnsItem_draft || columnsItem));

  // Open/close sort columns
  const onToggleSetting = () => {
    setColumnsItem_draft(columnsItem);
    setModeSetting(!modeSetting);
  };
  // Save sort columns
  const onSaveSort = () => {
    setColumnsItem(columnsItem_draft);
    saveSortColumn_local(
      KEY_PAGE,
      JSON.parse(
        JSON.stringify(
          columnsItem_draft.map((v) => {
            v.title = undefined;
            return v;
          })
        )
      )
    );
    setModeSetting(false);
  };

  // Param: all
  //   - true : check for save
  //   - false : check for search Item
  const validate = () => {
    let require = false;
    let exceed = false;
    let min = false;

    // List Item
    data.items.forEach((v) => {
      if (v[KEY_INPUT_MACHINE]) {
        // Input Machine
        const totalQuantityMachine = v[KEY_MACHINE].reduce((preV, value) => {
          // Require
          if (isEmpty(value[KEY_QUANTITY_MACHINE])) {
            require = true;
          } else if (
            // Min
            Number(value[KEY_QUANTITY_MACHINE]) === 0 ||
            isNaN(value[KEY_QUANTITY_MACHINE])
          ) {
            min = true;
          } else {
            // Empty
          }
          return preV + Number(value[KEY_QUANTITY_MACHINE] || 0);
        }, 0);
      } else {
        // Require
        if (isEmpty(v.inventoryQuantity)) {
          require = true;
        } else if (
          // Min 0
          Number(v.inventoryQuantity) === 0 ||
          isNaN(v.inventoryQuantity)
        ) {
          min = true;
        } else {
          // Empty
        }
      }
      // Facility
      if (isEmpty(v[KEY_FACILITY])) {
        require = true;
      }
    });

    if (require || min || exceed) {
      // Fail
      const msg = [];
      const firstLine = "・";
      require && msg.push(firstLine + COMMON_CONSTANT.BOM_E031);
      exceed && msg.push(firstLine + COMMON_CONSTANT.BOM_E032);
      min && msg.push(firstLine + COMMON_CONSTANT.BOM_E034);

      showMessage(
        KEY.ERROR,
        <div className="white-space">{msg.join("\n")}</div>
      );
      return false;
    } else {
      // Pass
      return true;
    }
  };
  const onSave = () => {
    // Step 1: Validate
    if (!validate()) {
      return;
    }

    showMessageChange(() => {
      openLoadingSpinner();

      // Step 2: Mapping data
      const d = JSON.parse(JSON.stringify(data));
      d.createDate.value = d.createDate.value || moment().unix();
      d.inventoryTransactionCode = d.code.value;
      d.workReportId = d.workReport.value;
      d.transactionTime = d.createDate.value;
      d.performer = d.createUser.value;
      d.items = d.items.map((item) => {
        // List machine
        item.itemDetails = item[KEY_INPUT_MACHINE]
          ? // Case input quantity
            item[KEY_MACHINE].filter(
              (v) =>
                Number(v[KEY_QUANTITY_MACHINE]) > 0 && // quantity > 0
                !isNaN(Number(v[KEY_QUANTITY_MACHINE])) // is Number
            )
          : // Case only machine name
            item[KEY_MACHINE].map((v) => {
              delete v[KEY_QUANTITY_MACHINE];
              return v;
            });

        // Case input machine
        if (item[KEY_INPUT_MACHINE]) {
          item.inventoryQuantity = Number(
            item[KEY_MACHINE].reduce((preV, v) => {
              return preV + Number(v.quantity);
            }, 0)
          );
        }
        return item;
      });

      // Step 3: Sent
      return (!viewId ? API.saveExport : API.updateTransaction)(d, viewId)
        .then((res) => {
          if (statusRes(res)) {
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
              history.push("/ExportInventory");
            });
          } else {
            showMessage(KEY.ERROR, MSG_CATCH());
          }
        })
        .catch((err) => {
          let msg = "";
          if (
            err.response &&
            err.response.status === 400 &&
            err.response.data
          ) {
            msg = err.response.data.msg;
          } else {
            msg = MSG_CATCH();
          }
          showMessage(KEY.ERROR, msg);
        })
        .then(() => {
          closeLoadingSpinner();
        });
    }, COMMON_CONSTANT.BOM_C009);
  };
  const onCancel = () => {
    if (flagChangeData) {
      showMessageChange(history.goBack, COMMON_CONSTANT.BOM_C018);
    } else {
      history.goBack();
    }
  };
  const onDelete = () => {
    // Validate
    if (!canEdit) return;

    showMessageChange(async () => {
      openLoadingSpinner();
      // API Del
      try {
        // Del
        await API.deleteTransaction(viewId);
        // Open success and go to list screen
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
        history.push("/ExportInventory");
      } catch (err) {
        let msg = "";
        if (err.response && err.response.status === 400 && err.response.data) {
          msg = err.response.data.msg;
        } else {
          msg = MSG_CATCH();
        }
        showMessage(KEY.ERROR, msg);
      }
      closeLoadingSpinner();
    }, COMMON_CONSTANT.BOM_C001);
  };
  useEffect(() => {
    if (
      !modeView &&
      (data.items.length > 0 || !isEmpty(data.workReport.value))
    ) {
      setFlagChangeData(true);
    }
  }, [data.items, data.workReport.value]);

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={
          modeView
            ? lang.EXPORT_INVENTORY_VIEW
            : isUpdate
            ? lang.EXPORT_INVENTORY_UPDATE
            : lang.EXPORT_INVENTORY_ADD
        }
      />
      {/* Common */}
      <Content
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Form layout={"vertical"} className="formDes">
          {/* Code & create Date */}
          <>
            <Space className="d-flex" size={15}>
              <RowInput name={lang.TRADING_CODE} required>
                <Input
                  disabled
                  maxLength={255}
                  value={data.code.value}
                  className="wInput"
                  onChange={(e) =>
                    setDataWidthKey("code", {
                      ...data.code,
                      value: e.target.value,
                    })
                  }
                />
              </RowInput>
              <RowInput name={lang.CREATE_DATE}>
                <DatePicker
                  showTime
                  allowClear={false}
                  className="wInput black"
                  disabled={modeView}
                  format={KEY.DATE_WITH_HOUR_DESKTOP}
                  disabledDate={(current) => current > moment().endOf("day")}
                  onChange={(v) => {
                    setData({
                      ...data,
                      createDate: {
                        ...data.createDate,
                        value:
                          moment(v).diff(moment()) > 0
                            ? moment().unix()
                            : moment(v).unix(),
                      },
                    });
                  }}
                  value={
                    data.createDate.value
                      ? moment(+data.createDate.value * 1000)
                      : moment()
                  }
                />
              </RowInput>
              {/* Auth */}
              <RowInput name={lang.CREATED} required>
                <Input
                  disabled
                  value={data.createUser.value}
                  className="wInput"
                />
              </RowInput>
            </Space>
          </>
          {/* Work report */}
          <Space className="d-flex" size={15}>
            <RowInput name={lang.WORK_REPORT_INFORMATION} required>
              <Select
                disabled={modeView}
                value={data.workReport.value}
                onChange={onChangeWorkReport}
                className={
                  (data.workReport.error ? STYLE.BORDER_RED : "") + " wInput"
                }
              >
                {renderOption(data.workReport.options, false)}
              </Select>
            </RowInput>
          </Space>
        </Form>
      </Content>
      {/* Search Items */}
      {!modeView && (
        <BoxItemWarehouses
          selSearch={[selSearch, setSelSearch]}
          grid={[grid, setGrid]}
          selAdded={[selAdded, setSelAdded]}
          onAddItems2Grid={onAddItems2Grid}
        />
      )}
      {/* Items */}
      <Content
        className={"site-layout-background"}
        style={{
          margin: "24px 16px",
          padding: 20,
        }}
      >
        <div className="titleText2">
          <FileTextFilled className="svg-default" /> {lang.EXPORT_INVENTORY}
        </div>
        {!modeView && (
          <Row justify="space-between" className="mb15 align-center">
            <Col>
              <Button
                className="buttonPC button--error--outline"
                onClick={onDeleteItems}
                disabled={selGrid.length === 0}
              >
                <DeleteOutlined />
                {lang.DELETE}
              </Button>
            </Col>
            <Col>
              {modeSetting ? (
                <>
                  <Button
                    className="buttonPC button--info wAuto mr15"
                    onClick={onSaveSort}
                  >
                    <SaveOutlined />
                    {lang.SAVE}
                  </Button>
                  <Button
                    className="buttonPC button--outline wAuto "
                    onClick={onToggleSetting}
                  >
                    <CloseCircleOutlined />
                    {lang.CANCEL}
                  </Button>
                </>
              ) : (
                <Button
                  type="link"
                  className="btnLink svg-default"
                  onClick={onToggleSetting}
                >
                  <SettingFilled />
                  {lang.SETTING}
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Table
          bordered={modeSetting}
          className="tblInventory"
          rowKey="inventoryWarehouseItemCode"
          dataSource={data.items}
          columns={
            modeView ? renderColumns : [columnsItem_checkbox, ...renderColumns]
          }
          F
          scroll={{ x: 1800 }}
          pagination={false}
        />
      </Content>
      {/* Footer */}
      <FooterPage>
        <Row justify={modeView ? "end" : "space-between"}>
          {!modeView && (
            <Col className="align-center">
              <Button
                className="buttonPC button--info wAuto mr15"
                onClick={onSave}
                disabled={data.items.length === 0 || modeSetting}
              >
                <ExportOutlined />
                {isUpdate
                  ? lang.EXPORT_INVENTORY_UPDATE_BUTTON
                  : lang.EXPORT_INVENTORY}
              </Button>
              <div className="fs16 bold">
                {lang.TOTAL} {data.items.length}
              </div>
            </Col>
          )}
          <Col>
            <Button
              className="buttonPC button--outline wAuto ml15"
              onClick={onCancel}
            >
              {modeView ? (
                <>
                  <RollbackOutlined /> {lang.BACK}
                </>
              ) : (
                <>
                  <CloseCircleOutlined /> {lang.CANCEL}
                </>
              )}
            </Button>
            {modeView && (
              <>
                <Button
                  className="buttonPC button--info wAuto ml15"
                  disabled={!canEdit}
                >
                  <Link
                    to={"/ExportInventoryUpdate/" + viewId + "/update"}
                    className="w100"
                    disabled={!canEdit}
                  >
                    <EditOutlined />
                    <span style={{ marginLeft: 8 }}>{lang.EDIT}</span>
                  </Link>
                </Button>
                <Button
                  className="buttonPC button--error wAuto ml15"
                  disabled={!canEdit}
                  onClick={onDelete}
                >
                  <DeleteOutlined />
                  {lang.DELETE}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </FooterPage>

      {/* Search Machine */}
      <Modal
        className="box-search-machine"
        centered
        title={COMMON_CONSTANT.TITLE_CREATE_GROUP}
        visible={popupMachine.show}
        onCancel={() => {
          setPopupMachine({ ...popupMachine, show: false });
        }}
        footer={[
          <Button
            key={2}
            onClick={() => {
              setPopupMachine({ ...popupMachine, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>,
          <Button
            key={1}
            className="buttonPC button--info"
            onClick={onAddMachine}
          >
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
        ]}
      >
        <BoxSearchMachine
          setLoading={setShowLoading}
          listFilter={popupMachine.list}
          checkedList={[checkedList, setCheckedList]}
        />
      </Modal>
    </div>
  );
}
