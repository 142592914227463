import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Space,
  Input,
  Checkbox,
  Modal,
  Button,
  DatePicker,
  Form,
  Layout,
  Select,
  Tooltip,
} from "antd";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  CONFIG,
} from "../../constants/common.const";
import { useHistory, useLocation } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import { Link } from "react-router-dom";
import API from "../../api/backend/workReport";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  enterSearch,
  MSG_CATCH,
  INPUT_DATE,
  renderOption,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  getCheckboxValue,
} from "../../constants/utils";

import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import classCheckbox from "../../models/control/checkbox";

import {
  SearchOutlined,
  ClearOutlined,
  EyeFilled,
  DeleteOutlined,
  EditFilled,
} from "@ant-design/icons";
import { checkRoleScreen } from "../../utils/roles";
import { useSelector } from "react-redux";

export const REPORT_TYPE = [
  { id: "1", name: lang.SAVE_REPORT },
  { id: "2", name: lang.SAVE_TROUBLE_REPORT },
];
export const PRIORITY = [
  { id: "1", name: lang.HIGH },
  { id: "2", name: lang.MIDDLE },
  { id: "3", name: lang.LOW },
];
export const STATUS = [
  { id: "1", name: COMMON_CONSTANT.WORK_REPORT_STATUS_OPENED },
  { id: "2", name: COMMON_CONSTANT.WORK_REPORT_STATUS_CONFIRMED },
  { id: "3", name: COMMON_CONSTANT.WORK_REPORT_STATUS_APPROVED },
  { id: "4", name: COMMON_CONSTANT.WORK_REPORT_STATUS_RESOLVED },
  { id: "5", name: COMMON_CONSTANT.WORK_REPORT_STATUS_REJECTED },
  { id: "6", name: COMMON_CONSTANT.WORK_REPORT_STATUS_REOPENED },
];
const { Content } = Layout;
const objClear = { value: null, disabled: true, checked: false };
let F5 = true;
let objSearchOrigin = {
  name: null,
  reportType: null,
  priority: null,
  startDate: null,
  endDate: null,
  status: null,
  keyWord: null,
  isMachineForSiteOfficeName: null,
  isSolution: null,
};

let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function WorkReport_List({ history: historyPage }) {
  // Step1: Update param
  if (historyPage.action === "POP") {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }

  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  // Group 1
  // 1
  const [txtReportName, setTxtReportName] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.name),
  });
  const [ddlLevel, setDdlLevel] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.reportType),
  });
  // 2
  const [ddlPriority, setDdlPriority] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.priority),
  });
  const [txtCreateStartDate, setTxtCreateStartDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch.startDate),
  });
  const [txtCreateEndDate, setTxtCreateEndDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch.endDate),
  });
  // 3
  const [ddlStatus, setDdlStatus] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.status),
  });
  // Group 2
  const [txtKeyword, setTxtKeyword] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.keyWord),
  });
  const [isMachineForSiteOfficeName, setIsMachineForSiteOfficeName] = useState({
    ...new classCheckbox(),
    checked: getCheckboxValue(objSearch.isMachineForSiteOfficeName),
  });
  const [isSolution, setIsSolution] = useState({
    ...new classCheckbox(),
    checked: getCheckboxValue(objSearch.isSolution),
  });
  const location = useLocation();
  const { allowRoute } = useSelector((state) => state.auth);
  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.name = txtReportName.value ? txtReportName.value.trim() : null;
    objSearch.reportType = ddlLevel.value;
    objSearch.priority = ddlPriority.value;
    objSearch.startDate = txtCreateStartDate.value;
    objSearch.endDate = txtCreateEndDate.value;
    objSearch.status = ddlStatus.value;
    objSearch.keyWord = txtKeyword.value ? txtKeyword.value.trim() : null;
    objSearch.isMachineForSiteOfficeName = isMachineForSiteOfficeName.checked;
    objSearch.isSolution = isSolution.checked;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = () => {
    // Loading
    openLoadingSpinner();

    // input to objectSearch; push url
    syncInput();

    // Update format
    objSearch = getQuerySearch();
    objSearch.startDate = int2DateString(Number(objSearch.startDate));
    objSearch.endDate = int2DateString(Number(objSearch.endDate));

    // Call
    return API.search({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        setGrid({ ...grid, data: res.data, count: res.data.length });
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };

  // clearInputSearch
  const clearInputSearch = () => {
    setTxtReportName(objClear);
    setDdlLevel({ ...ddlLevel, ...objClear });
    setDdlPriority({ ...ddlPriority, ...objClear });
    setTxtCreateStartDate(objClear);
    setTxtCreateEndDate(objClear);
    setDdlStatus({ ...ddlStatus, ...objClear });
    setTxtKeyword(objClear);
    setIsMachineForSiteOfficeName(objClear);
    setIsSolution(objClear);
    formSearch.resetFields();
    formSearch.submit();
  };

  // Column GRID
  const initColumns = [
    {
      title: lang.ID,
      dataIndex: "id",
      width: "5%",
      align: "center",
    },
    {
      title: lang.REGISTRATION_DATE,
      dataIndex: "createDatetimeDisplay",
      width: "15%",
      align: "center",
    },
    {
      title: lang.REPORT,
      dataIndex: "name",
      width: "25%",
      align: "center",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              <Link to={"/ViewReport/" + row.id} className="white-space">
                {text}
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: lang.REPORT_LEVEL,
      dataIndex: "reportTypeValue",
      align: "center",
    },
    {
      title: lang.PRIORITY,
      dataIndex: "priorityValue",
      align: "center",
    },
    {
      title: lang.STATUS,
      dataIndex: "statusValue",
      align: "center",
    },
    {
      title: lang.PERSON_IN_CHARGE,
      dataIndex: "displayName",
      align: "center",
    },
    {
      title: lang.APPROVER,
      dataIndex: "approver",
      align: "center",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 180,
      render: (row) => {
        let activeEdit = true;
        let activeDelete = true;
        let status = row.statusValue;
        if (
          status === COMMON_CONSTANT.WORK_REPORT_STATUS_RESOLVED ||
          status === COMMON_CONSTANT.WORK_REPORT_STATUS_CONFIRMED ||
          status === COMMON_CONSTANT.WORK_REPORT_STATUS_APPROVED
        ) {
          // ONLY VIEW/DELETE
        } else {
          activeEdit = false;
        }
        if (
          status === COMMON_CONSTANT.WORK_REPORT_STATUS_REJECTED ||
          status === COMMON_CONSTANT.WORK_REPORT_STATUS_OPENED
        ) {
          activeDelete = false;
        }
        //
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={activeEdit} className="mr5 btnEdit">
                <Link to={"/EditReport/" + row.id} className="w100">
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* View */}
            <Tooltip title={lang.VIEW}>
              <Button className="mr5 btnView">
                <Link to={"/ViewReport/" + row.id} className="white-space">
                  <EyeFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={activeDelete}
                className="mr5 btnDelete"
                onClick={() => {
                  showMessageDeleteConfirm(row);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const showMessageDeleteConfirm = (rowData) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        // Close warning
        Modal.destroyAll();

        await API.delete(rowData.id);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute))
      // Load ddl
      setDdlLevel({ ...ddlLevel, options: REPORT_TYPE });
    setDdlPriority({ ...ddlPriority, options: PRIORITY });
    setDdlStatus({ ...ddlStatus, options: STATUS });
    // SEARCH
    search_onClick();

    F5 = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowRoute]);

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.WORK_REPORT_MANAGEMENT} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col span={8}>
              <div className="ant-form-item-label">{lang.REPORT_NAME}</div>
              <Input
                placeholder={lang.REPORT_NAME}
                value={txtReportName.value}
                maxLength={255}
                onChange={(e) =>
                  setTxtReportName({
                    ...txtReportName,
                    value: e.target.value,
                  })
                }
              />
            </Col>
            <Col span={8}>
              <div className="ant-form-item-label">{lang.REPORT_LEVEL}</div>

              <Select
                placeholder={lang.REPORT_LEVEL}
                value={ddlLevel.value}
                className="w100"
                onChange={(v) => {
                  setDdlLevel({ ...ddlLevel, value: v });
                }}
                {...enterSearch(search_onClick)}
              >
                {renderOption(ddlLevel.options)}
              </Select>
            </Col>
            <Col span={8}>
              <div className="ant-form-item-label">{lang.PRIORITY}</div>
              <Select
                placeholder={lang.PRIORITY}
                value={ddlPriority.value}
                className="w100"
                onChange={(v) => {
                  setDdlPriority({ ...ddlPriority, value: v });
                }}
                {...enterSearch(search_onClick)}
              >
                {renderOption(ddlPriority.options)}
              </Select>
            </Col>
            <Col span={8}>
              <div className="ant-form-item-label">
                {lang.REGISTRATION_DATE}
              </div>

              <Row>
                <Col span={10}>
                  <DatePicker
                    // size="large"
                    disabledDate={(currentDate) => {
                      return (
                        txtCreateEndDate.value &&
                        txtCreateEndDate.value.valueOf() < currentDate.valueOf()
                      );
                    }}
                    format={KEY.DATE_DEFAULT}
                    value={txtCreateStartDate.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtCreateStartDate({
                        ...txtCreateStartDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={10}>
                  <DatePicker
                    // size="large"
                    disabledDate={(currentDate) => {
                      return (
                        txtCreateStartDate.value &&
                        txtCreateStartDate.value.valueOf() >
                          currentDate.valueOf()
                      );
                    }}
                    format={KEY.DATE_DEFAULT}
                    value={txtCreateEndDate.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtCreateEndDate({
                        ...txtCreateEndDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <div className="ant-form-item-label">{lang.STATUS}</div>

              <Select
                placeholder={lang.STATUS}
                value={ddlStatus.value}
                className="w100"
                onChange={(v) => {
                  setDdlStatus({ ...ddlStatus, value: v });
                }}
                {...enterSearch(search_onClick)}
              >
                {renderOption(ddlStatus.options)}
              </Select>
            </Col>
            <Col span={8}>
              <div className="ant-form-item-label">{lang.SEARCH_KEYWORD}</div>

              <Input
                placeholder={lang.SEARCH_KEYWORD}
                value={txtKeyword.value}
                maxLength={255}
                onChange={(e) =>
                  setTxtKeyword({ ...txtKeyword, value: e.target.value })
                }
              />
            </Col>
            <Col flex="auto">
              <div className="ant-form-item-label">{lang.REPORT}</div>
              <div>
                <Checkbox
                  checked={isMachineForSiteOfficeName.checked}
                  onChange={(e) => {
                    setIsMachineForSiteOfficeName({
                      ...isMachineForSiteOfficeName,
                      checked: e.target.checked,
                    });
                  }}
                >
                  {lang.EQUIPMENT_NAME}
                </Checkbox>
                <Checkbox
                  checked={isSolution.checked}
                  onChange={(e) => {
                    setIsSolution({
                      ...isSolution,
                      checked: e.target.checked,
                    });
                  }}
                >
                  {lang.SAVE_REPORT}・{lang.REPAIR_METHOD}
                </Checkbox>
              </div>
            </Col>
            {/* Button Search/Clear */}
            <Col flex="none" className="right">
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            <Button
              type="link"
              className="btnAddRecord mb15"
              onClick={() => {
                history.push("/CreateReport");
              }}
            >
              <AddIcon />
              {lang.ADD}
            </Button>
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={CONFIG.paging}
          scroll={{ x: "auto" }}
        />
      </Content>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default WorkReport_List;
