import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Divider, Button } from "antd";
import { DoubleRightOutlined, SearchOutlined } from "@ant-design/icons";
import RowInput from "./rowInput2";
import { KEY, lang } from "../../constants/common.const";
import {
  enterSearch,
  MSG_CATCH,
  renderOption,
  showMessage,
  statusRes,
} from "../../constants/utils";
import ListMachine from "./ListMachine";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import API from "../../api/backend/managementCL";
import { isEmpty } from "lodash";
export default function BoxSearchMachine({
  setLoading,
  listFilter,
  checkedList: chkList,
  ...other
}) {
  const [facility, setFacility] = useState(new classDdl());
  const [building, setBuilding] = useState(new classDdl());
  const [floor, setFloor] = useState(new classDdl());
  const [room, setRoom] = useState(new classDdl());
  const [process, setProcess] = useState(new classDdl());
  const [txtMachineNameSearch, setTxtMachineNameSearch] = useState(
    new classText(other.defaultValue || '')
  );
  const [stateData, setStateData] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const [machineList, setMachineList] = useState([]);
  const [checkedList, setCheckedList] = chkList;

  const closeLoadingSpinner = () => setLoading(false);
  const openLoadingSpinner = () => setLoading(true);

  useEffect(() => {
    openLoadingSpinner();

    Promise.all([getFacility()])
      .then(() => {
        closeLoadingSpinner();
      });
  }, []);

  useEffect(() => {

    setTxtMachineNameSearch({ ...txtMachineNameSearch, value: other.defaultValue });
    searchMachine(0, other.defaultValue);
  }, [other.defaultValue]);

  useEffect(() => {
    openLoadingSpinner();
    searchMachine(0);
  }, [facility.value, building.value, floor.value, room.value, process.value]);

  const funcReset = (preV) => ({ ...preV, disabled: true, value: null });
  const getFacility = () => {
    return API.getFacility().then((res) => {
      if (statusRes(res)) {
        setFacility((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.facilityCode;
            v["value"] = v.facilityName;
            return v;
          }),
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getBuilding = (id) => {
    return API.getBuilding(id).then((res) => {
      if (statusRes(res)) {
        setBuilding((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.buildingCode;
            v["value"] = v.buildingName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getFloor = (id) => {
    return API.getFloor(id).then((res) => {
      if (statusRes(res)) {
        setFloor((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.floorCode;
            v["value"] = v.floorName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getRoom = (id) => {
    return API.getRoom(id).then((res) => {
      if (statusRes(res)) {
        setRoom((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.roomCode;
            v["value"] = v.roomName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getProcess = (id) => {
    return API.getProcess(id).then((res) => {
      if (statusRes(res)) {
        setProcess((preV) => ({
          ...preV,
          options: res.data.map((v) => {
            v["key"] = v.id;
            v["value"] = v.processName;
            return v;
          }),
          disabled: res.data.length === 0,
        }));
      } else {
        throw new Error();
      }
    });
  };
  // Facility
  useEffect(() => {
    if (facility.value && facility.value.length > 0) {
      getBuilding(facility.value);
      getProcess(facility.value);
    } else {
      setBuilding(funcReset);
    }
  }, [facility.value]);
  // Building
  useEffect(() => {
    if (building.value && building.value.length > 0) {
      getFloor(building.value);
    } else {
      setFloor(funcReset);
    }
  }, [building.value]);
  // Floor
  useEffect(() => {
    if (floor.value && floor.value.length > 0) {
      getRoom(floor.value);
    } else {
      setRoom(funcReset);
    }
  }, [floor.value]);

  // Update state data machine
  useEffect(() => {
    setStateData((preV) => ({
      ...preV,
      offset: machineList.length,
      loading: false,
    }));
  }, [machineList]);

  // Search : Search Machine where ...
  function searchMachine(offset = 0, name) {
    // Check loading
    if (stateData.loading && offset !== 0) {
      return;
    } else {
      setStateData((preV) => ({ ...preV, loading: true }));
    }
    // Case event
    if (typeof offset === "object") {
      offset = 0;
    }
    // Open loader
    if (offset === 0) {
      openLoadingSpinner(); //Only init
    }

    // Call api
    API.getMachine({
      machineName: name || (txtMachineNameSearch.value && txtMachineNameSearch.value.trim()) || '',
      facilityCode: facility.value,
      buildingCode: building.value,
      floorCode: floor.value,
      roomCode: room.value,
      processId: process.value,
      offset: offset,
      limit: 20,
      getCheckItem: false,
      getBuhin: false,
    })
      .then((res) => {
        if (offset === 0) {
          // Case: Init search
          setMachineList(res.data.machineList);
        } else {
          // Case load more
          setMachineList((preV) => [...preV, ...res.data.machineList]);
        }
        setStateData((preV) => ({
          hasMore: machineList.length < res.data.machineListCount,
        }));
      })
      .catch((xhr) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  }

  return (
    <Row>
      <Col lg={6} xs={24}>
        <Form layout={"vertical"} className="formDes">
          <RowInput className="mb10" name="">
            <Input
              value={txtMachineNameSearch.value}
              placeholder="機器名"
              style={{
                width: "calc(100% - 87px)",
              }}
              onChange={(e) =>
                setTxtMachineNameSearch({
                  ...txtMachineNameSearch,
                  value: e.target.value,
                })
              }
              {...enterSearch(searchMachine)}
            />
            {/* Button search 検索*/}
            <Button
              className="buttonPC button--info wAuto ml5"
              onClick={() => {
                searchMachine(0);
              }}
            >
              <SearchOutlined className="" /> {lang.SEARCH}
            </Button>
          </RowInput>
          <Divider className="mr0 ml0 mt10 mb5" />
          <div className="mb5">
            <b>{lang.FILTER}</b>
            {lang.COLON}
          </div>
          <RowInput className="mb10" name="施設">
            <Select
              value={facility.value}
              // className="wInput"
              onChange={(v) => {
                // Update for select
                setFacility({ ...facility, value: v });
              }}
            >
              {renderOption(facility.options)}
            </Select>
          </RowInput>
          <RowInput className="mb10" name="棟">
            <Select
              value={building.value}
              // className="wInput"
              disabled={building.disabled}
              onChange={(v) => {
                // Update for select
                setBuilding({ ...building, value: v });
              }}
            >
              {renderOption(building.options)}
            </Select>
          </RowInput>
          <RowInput className="mb10" name="階">
            <Select
              value={floor.value}
              // className="wInput"
              disabled={floor.disabled}
              onChange={(v) => {
                // Update for select
                setFloor({ ...floor, value: v });
              }}
            >
              {renderOption(floor.options)}
            </Select>
          </RowInput>
          <RowInput className="mb10" name="部屋">
            <Select
              value={room.value}
              // className="wInput"
              disabled={room.disabled}
              onChange={(v) => {
                // Update for select
                setRoom({ ...room, value: v });
              }}
            >
              {renderOption(room.options)}
            </Select>
          </RowInput>
          <RowInput className="mb10" name="プロセス">
            <Select
              value={process.value}
              // className="wInput"
              disabled={process.disabled}
              onChange={(v) => {
                // Update for select
                setProcess({ ...process, value: v });
              }}
            >
              {renderOption(process.options)}
            </Select>
          </RowInput>
        </Form>
      </Col>
      {/* From */}
      <Col lg={2} xs={0}>
        <DoubleRightOutlined
          style={{
            fontSize: "60px",
            color: "#b9b9b9",
            width: "100%",
            margin: "200px 0 0",
          }}
        />
      </Col>
      {/* To */}
      <Col lg={16} xs={24}>
        <div className="box-select-row">
          <ListMachine
            loadMore={() => {
              searchMachine(stateData.offset);
            }}
            stateData={stateData}
            style={{
              overflow: "hidden",
              height: "100%",
              overflowY: "auto",
            }}
            listFilter={listFilter}
            options={machineList}
            checkedList={checkedList}
            onCheck={(arrChecked) => {
              other.isOneValue
                ? setCheckedList( !isEmpty(arrChecked) ? [arrChecked[arrChecked.length - 1]] : [])
                : setCheckedList(JSON.parse(JSON.stringify(arrChecked)));
            }}
          ></ListMachine>
        </div>
        {/* Button select 点検表に追加*/}
        {/* <Row justify="end">
          <Button
            className="buttonPC button--info wAuto mt15"
            // disabled={
            //   CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList.length === 0
            // }
            // onClick={addMachineToCheckList}
          >
            <PlusOutlined />
            点検表に追加
          </Button>
        </Row> */}
      </Col>
    </Row>
  );
}
