import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Table,
  Space,
  Input,
  Modal,
  Button,
  DatePicker,
  Form,
  Tooltip,
  Select,
  Spin,
  Empty,
} from "antd";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  CONFIG,
  STYLE,
} from "../../constants/common.const";
import { useHistory, useLocation } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import { Link } from "react-router-dom";
import API from "../../api/backend/homeBanner";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import { ReactComponent as EditIcon } from "@src/styles/icon/edit.svg";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import {
  MSG_CATCH,
  INPUT_DATE,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  renderOption,
  pushObject2QueryReplace,
} from "../../constants/utils";

import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import STATUS from "@src/constants/status.const";
import RoleConst from "@src/routers/roles/roles.const";

import {
  SearchOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditFilled,
} from "@ant-design/icons";
import { checkRoleScreen } from "../../utils/roles";
import kbn from "../../api/backend/kbn";
import BoxContent from "../../component/Desktop/content";
import { useMediaQuery } from "react-responsive";
import Paragraph from "antd/lib/typography/Paragraph";
import LoadingInline from "../../component/Popup/loading-inline";

const objClear = { value: null, disabled: true, checked: false };
let objSearchOrigin = {
  title: null,
  startDate: null,
  endDate: null,
  status: null,
  keyWord: null,
  typeNotify: null,
};

let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function HomeBanner_List({ history: historyPage }) {
  const { selectedFactory, roles } = useSelector((state) => state.auth);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  // Step1: Update param
  if (historyPage.action === "POP") {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  // Group 1
  // 1
  const [txtTitle, setTxtTitle] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.title),
  });
  const [txtCreateStartDate, setTxtCreateStartDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch.startDate),
  });
  const [txtCreateEndDate, setTxtCreateEndDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch.endDate),
  });
  const [txtKeyword, setTxtKeyword] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.keyWord),
  });
  let [ddlType, setddlType] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.type),
  });
  const [currentPage, setCurrentPage] = useState(objSearch.page || 1);

  // Step2: Sync input <-> url
  const syncInput = (init = true) => {
    objSearch.title = txtTitle.value ? txtTitle.value.trim() : null;
    objSearch.startDate = txtCreateStartDate.value;
    objSearch.endDate = txtCreateEndDate.value;
    objSearch.keyWord = txtKeyword.value ? txtKeyword.value.trim() : null;
    objSearch.type = ddlType.value;

    // Push to url
    if (!init) {
      pushObject2Query({
        ...objSearch,
        page: 1,
      });
      setCurrentPage(1);
    } else {
      pushObject2Query({
        ...objSearch,
        page: currentPage,
      });
    }
  };

  // Search
  const search_onClick = (init = true) => {
    // Loading
    openLoadingSpinner();

    // input to objectSearch; push url
    syncInput(init);

    // Update format
    objSearch = getQuerySearch();
    objSearch.startDate = int2DateString(Number(objSearch.startDate));
    objSearch.endDate = int2DateString(Number(objSearch.endDate));
    objSearch.type = ddlType.value;
    // Call
    return API.search({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        setGrid({ ...grid, data: res.data, count: res.data.length });
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };

  // clearInputSearch
  const clearInputSearch = () => {
    setTxtTitle(objClear);
    setTxtCreateStartDate(objClear);
    setTxtCreateEndDate(objClear);
    setTxtKeyword(objClear);
    setddlType({ ...ddlType, value: null });

    formSearch.resetFields();
    formSearch.submit();
  };

  // Column GRID
  const initColumns = [
    {
      title: lang.ID,
      dataIndex: "id",
      width: "10%",
      align: "center",
    },
    {
      title: lang.TITLE,
      dataIndex: "title",
      //  width: "25%",
      render: (text, row) => {
        return (
          <Tooltip trigger={["hover"]} title={row.title}>
            <span
              className="underline"
              style={{ color: STATUS.TYPE_HOME_BANNER[row.type] }}
              onClick={() => history.push(`/ViewHomeBanner/${row.id}`)}
            >
              {"【"}
              {row.typeValue}
              {"】"}
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: lang.SENDER,
      dataIndex: "senderValue",
      width: "15%",
      align: "center",
    },
    {
      title: "送信者",
      dataIndex: "displayName",
      align: "center",
      width: "15%",
    },
    {
      title: lang.REGISTRATION_DATE,
      dataIndex: "createDatetimeDisplay",
      width: "15%",
      align: "center",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 180,
      render: (row) => {
        let disabledEdit = false;
        let disabledDelete = false;
        if (roles === RoleConst.OFFICE_ADMIN) {
          if (selectedFactory !== row.siteOfficeMasterCode) {
            disabledEdit = true;
            disabledDelete = true;
          }
        }
        if (roles !== RoleConst.HEAD_OFFICE_ADMIN) {
          if (Number(row.displayAllSiteOffice) === 1) {
            disabledEdit = true;
            disabledDelete = true;
          }
        }

        if (roles === RoleConst.OFFICE_USER) {
          disabledEdit = true;
          disabledDelete = true;
        }

        //
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={disabledEdit} className="mr5 btnEdit">
                <Link to={"/EditHomeBanner/" + row.id} className="w100">
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* View */}
            {/* <Tooltip title={lang.VIEW}>
              <Button className="mr5 btnView">
                <Link to={"/ViewHomeBanner/" + row.id} className="white-space">
                  <EyeFilled className="" />
                </Link>
              </Button>
            </Tooltip> */}
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={disabledDelete}
                className="mr5 btnDelete"
                onClick={() => {
                  showMessageDeleteConfirm(row);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const showMessageDeleteConfirm = (rowData) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        // Close warning
        Modal.destroyAll();

        await API.delete(rowData.id);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };
  const getKbnTypeNotify = () => {
    kbn
      .GetHomeBannerType()
      .then((res) => {
        let typeOption = res ? res.data.data : [];
        setddlType({ ...ddlType, options: typeOption });
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const menuAction = (row) => {
    let disabledEdit = false;
    let disabledDelete = false;
    if (roles === RoleConst.OFFICE_ADMIN) {
      if (selectedFactory !== row.siteOfficeMasterCode) {
        disabledEdit = true;
        disabledDelete = true;
      }
    }
    if (roles !== RoleConst.HEAD_OFFICE_ADMIN) {
      if (Number(row.displayAllSiteOffice) === 1) {
        disabledEdit = true;
        disabledDelete = true;
      }
    }

    if (roles === RoleConst.OFFICE_USER) {
      disabledEdit = true;
      disabledDelete = true;
    }
    return (
      <Space size={isPC ? 8 : 12}>
        {/* Edit */}
        {!disabledEdit ? (
          <Tooltip title={row.isDraft ? lang.EDIT : lang.VERSION_UP}>
            <Button
              type="link"
              className="iconAction-edit btnLink"
              onClick={(e) => {
                history.push("/EditHomeBanner/" + row.id);
                e.preventDefault();
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        ) : null}
        {/* Delete */}

        {!disabledDelete ? (
          <Tooltip title={lang.DELETE}>
            <Button
              type="link"
              className="iconAction-delete btnLink"
              onClick={(e) => {
                showMessageDeleteConfirm(row);
                e.preventDefault();
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        ) : null}
      </Space>
    );
  };
  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      getKbnTypeNotify();
      search_onClick();
    }
  }, [allowRoute]);

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.HOME_BANNER_MANAGEMENT} />
      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "0 16px",
        }}
      >
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={() => search_onClick(false)}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.TITLE}</div>
              <Input
                placeholder={lang.TITLE}
                value={txtTitle.value}
                maxLength={255}
                onChange={(e) =>
                  setTxtTitle({
                    ...txtTitle,
                    value: e.target.value,
                  })
                }
              />
            </Col>

            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">
                {lang.REGISTRATION_DATE}
              </div>

              <Row>
                <Col span={10}>
                  <DatePicker
                    // size="large"
                    disabledDate={(currentDate) => {
                      return (
                        txtCreateEndDate.value &&
                        txtCreateEndDate.value.valueOf() < currentDate.valueOf()
                      );
                    }}
                    format={KEY.DATE_DEFAULT}
                    value={txtCreateStartDate.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtCreateStartDate({
                        ...txtCreateStartDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={10}>
                  <DatePicker
                    // size="large"
                    disabledDate={(currentDate) => {
                      return (
                        txtCreateStartDate.value &&
                        txtCreateStartDate.value.valueOf() >
                          currentDate.valueOf()
                      );
                    }}
                    format={KEY.DATE_DEFAULT}
                    value={txtCreateEndDate.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtCreateEndDate({
                        ...txtCreateEndDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.SEARCH_KEYWORD}</div>

              <Input
                placeholder={lang.SEARCH_KEYWORD}
                value={txtKeyword.value}
                maxLength={255}
                onChange={(e) =>
                  setTxtKeyword({ ...txtKeyword, value: e.target.value })
                }
              />
            </Col>
            <Col span={24} style={{ padding: 0 }}>
              <Row className="rowNotMargin" gutter={[16, 8]}>
                <Col lg={8} md={12} xs={24}>
                  <div className="ant-form-item-label">{lang.TYPE}</div>
                  <Select
                    placeholder={lang.TYPE}
                    value={ddlType.value}
                    className={
                      ddlType.error ? `${STYLE.BORDER_RED} w100` : "w100"
                    }
                    onChange={(v) => {
                      setddlType({ ...ddlType, value: v });
                    }}
                  >
                    {renderOption(ddlType.options)}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col flex="auto"></Col>
            {/* Button Search/Clear */}
            <Col
              flex="none"
              className="right"
              style={isPC ? null : { padding: "8px 4px" }}
            >
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </BoxContent>

      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "12px 24px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            <Button
              type="link"
              style={{
                display: roles === RoleConst.OFFICE_USER ? "none" : "block",
              }}
              className="btnAddRecord mb15"
              onClick={() => {
                history.push("/CreateHomeBanner");
              }}
            >
              <AddIcon />
              {lang.ADD}
            </Button>
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        {isPC ? (
          <Table
            rowKey={(record) => record.id}
            className="gridSearch "
            columns={initColumns}
            dataSource={grid.data}
            pagination={{
              ...CONFIG.paging,

              current: Number(currentPage) || 1,
              onChange: (curr) => {
                setCurrentPage(curr);
                pushObject2QueryReplace({ page: curr });
              },
            }}
          />
        ) : (
          <Spin spinning={showLoading}>
            <Row gutter={[20, 20]}>
              {grid.data.length > 0 ? (
                <>
                  {grid.data.map((v, key) => {
                    return (
                      <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={key}>
                        <Link to={`/ViewHomeBanner/${v.id}`}>
                          <div className="itemList-2 ">
                            <Row
                              justify="space-between"
                              className="align-center"
                            >
                              <Col className="id">ID: {v.id}</Col>
                              <Col
                                className="icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                {menuAction(v)}
                              </Col>
                            </Row>
                            <div className="title">
                              <Paragraph ellipsis={{ rows: 2 }}>
                                <Tooltip title={v.title}>{v.title}</Tooltip>
                              </Paragraph>
                            </div>
                            <div className="account">{v.senderValue}</div>
                            <Row justify="space-between" className="more">
                              <Col>{v.createDatetimeDisplay}</Col>
                              <Col>
                                <Paragraph
                                  ellipsis={{ rows: 1 }}
                                  className="textStatus"
                                  style={{
                                    color: STATUS.TYPE_HOME_BANNER[v.type],
                                    background:
                                      STATUS.TYPE_HOME_BANNER[v.type] + "1A",
                                  }}
                                >
                                  {v.typeValue}
                                </Paragraph>
                              </Col>
                            </Row>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                  <Col span={24} className="content-center">
                    <LoadingInline isLoadingInline={showLoading} />
                  </Col>
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w100" />
              )}
            </Row>
          </Spin>
        )}
      </BoxContent>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default HomeBanner_List;
