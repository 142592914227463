import Api from "@src/api";
export const ApiCalibration= {
  searchCalibration(name) {
    return Api().get(`Calibration?name=${name}`);
  },
  deleteCalibration(id) {
    return Api().delete(`Calibration/${id}`);
  },
  getDetailCalibraion(id) {
    return Api().get(`Calibration/${id}`);
  },
  createCalibration(data) {
    return Api().post("Calibration", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getMachineOwner(){
    return Api().get("Kbn/GetCalibrationmachineOwner")
  },
  updateCalibration(id,data) {
    return Api().put(`Calibration/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  uploadFile(formData) {
    return Api().post(`FileUpload/CalibrationAttachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
};
