import { KEY, lang } from "@src/constants/common.const";
import ROUTER_PAGE from "@src/constants/router.const";
import { collapsedSideBar } from "@src/redux/sideBar";
import CheckedIcon from "@src/styles/icon/checked.svg";
import { Button, Col, Drawer, Input, Layout, Menu, Row } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./sideBar.scss";
// icon
import { CaretDownOutlined } from "@ant-design/icons";
import { updateSelectedFactory } from "@src/redux/auth";
import { resetStateIsUpdated } from "@src/redux/checkList";
import { openConfirm } from "@src/redux/confirm";
import { updateTitleHeader } from "@src/redux/sideBar";
import Logo from "@src/styles/icon/logo.svg";
import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { COMMON_CONSTANT } from "../../constants/common.const";

// import { listAllMenu } from "../../constants/menu.const";
import { showMessageChange } from "../../constants/utils";
import { createListMenu } from "../../constants/menu.const";
import rolesConst from "../../routers/roles/roles.const";

const { Sider } = Layout;

function SideBar() {
  const { collapsed, listMenu } = useSelector((state) => state.sideBar);
  const { inMultipleSites, selectedFactory, factories, selectedFactoryName } =
    useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.auth);
  const listAllMenu = createListMenu(roles);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, updateCurrentPage] = useState(null);
  const [openSelectOffice, setOpenSelectOffice] = useState(false);
  const [arrayFactories, updateArrayFactories] = useState([]);
  const { isUpdated } = useSelector((state) => state.checklist);
  const toggleButton = () => {
    dispatch(collapsedSideBar(!collapsed));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  useEffect(() => {
    updateArrayFactories(factories);
  }, [factories]);
  const updateArraySearch = (e) => {
    const value = String(e.target.value).toLowerCase();
    const array = factories.filter((item) =>
      item.siteOfficeMasterName.toLowerCase().includes(value)
    );
    updateArrayFactories(array);
  };

  const location = useLocation();

  // checkEndUSer
  const urlParams = new URLSearchParams(window.location.search);
  const queryIsEndUSer = urlParams.get("isEndUser");
  const isEndUser =
    [rolesConst.CUSTOMER, rolesConst.SPECIAL_CUSTOMER].includes(roles) ||
    location.pathname === "/EndUser" ||
    queryIsEndUSer == "true";

  // Current router
  const currentRouter = (route) => {
    if (isUpdated && route) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: route,
        })
      );
    } else {
      let routers = ROUTER_PAGE.ROUTER_PAGE;
      let routerPath = history.location.pathname;
      let keyCurrent = currentPage;
      let isUpdatedRouter = false;
      for (const [key, value] of Object.entries(routers)) {
        if (
          key !== keyCurrent &&
          [
            "HOME_BANNER",
            "WORK_REPORT",
            "CALIBRATION",
            "CL_MANAGEMENT",
            "INPUT_REST_LAST_VALUE",
            "IMPORT_INVENTORY",
            "OFFICE_MANAGEMENT",
            "EXPORT_INVENTORY",
            "WAREHOUSES_MASTER",
            "CATEGORY_MASTER",
            "ITEM_MASTER",
            "MACHINE_MANAGEMENT",
            "FACILITY_MANAGEMENT",
            "PROCESS_MANAGEMENT",
            "DYNAMIC_REPORT_MANAGEMENT",
          ].includes(key)
        ) {
          let isUpdated1 = false;
          value.forEach((item) => {
            if (routerPath.includes(item)) {
              isUpdated1 = true;
            }
          });
          if (isUpdated1) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        } else {
          if (routerPath.includes(value) && key !== keyCurrent) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        }
      }
      if (isUpdatedRouter) {
        if (!isEndUser) {
          dispatch(updateTitleHeader(keyCurrent));
          updateCurrentPage(keyCurrent);
        }
      }
    }
  };
  const selectFactory = (value) => {
    if (selectedFactory !== value) {
      showMessageChange(() => {
        let factoryName;
        factories.forEach((item) => {
          if (item.siteOfficeMasterCode === value) {
            factoryName = item.siteOfficeMasterName;
          }
        });
        dispatch(resetStateIsUpdated());
        dispatch(updateSelectedFactory({ code: value, name: factoryName }));
        setOpenSelectOffice(false);
      }, COMMON_CONSTANT.BOM_C018);
    }
  };
  useEffect(() => {
    if (isEndUser) {
      updateCurrentPage("END_USER");
    } else {
      if (!isUpdated) {
        currentRouter();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, isUpdated]);

  const renderMenuItem = (menuItem, isSub) => {
    if (menuItem.haveScreen === 1) {
      return !isSub ? (
        <Fragment key={menuItem.key}>
          <Menu.Item
            key={menuItem.key}
            icon={<menuItem.icon style={{ fontSize: "20px" }} />}
            className="customMenuItemMain"
            onClick={() => currentRouter(menuItem.path)}
          >
            {!isUpdated ? (
              <Link to={menuItem.path}>
                <span>{menuItem.menuName}</span>
              </Link>
            ) : (
              <span>{menuItem.menuName}</span>
            )}
          </Menu.Item>
          <hr className="menu-bottom-divider" />
        </Fragment>
      ) : (
        <Menu.Item
          key={menuItem.key}
          icon={<menuItem.icon style={{ fontSize: "20px" }} />}
          className="customMenuItemMain"
          onClick={() => currentRouter(menuItem.path)}
        >
          {!isUpdated ? (
            <Link to={menuItem.path}>
              <span>{menuItem.menuName}</span>
            </Link>
          ) : (
            <span>{menuItem.menuName}</span>
          )}
        </Menu.Item>
      );
    }
    if (menuItem.haveScreen === 0 && menuItem.subMenus.length > 0) {
      return (
        <Fragment key={menuItem.key}>
          <Menu.SubMenu
            key={menuItem.key}
            icon={<menuItem.icon style={{ fontSize: "20px" }} />}
            title={menuItem.menuName}
            className={`${
              isDesktopOrLaptop ? "customSubMenu" : "customSubMenu--mobile"
            }`}
          >
            {menuItem.subMenus.map((item) => renderMenuItem(item, true))}
          </Menu.SubMenu>
          <hr className="menu-bottom-divider" />
        </Fragment>
      );
    }
  };

  const filterMenu = (allMenu, currentMenu) => {
    return allMenu
      .filter(
        (item) =>
          currentMenu.findIndex(
            (currentItem) => currentItem.menuCode === item.menuCode
          ) >= 0
      )
      .map((item) => {
        const index = currentMenu.findIndex(
          (currentItem) => currentItem.menuCode === item.menuCode
        );
        if (index >= 0) {
          return { ...item, displayOrder: currentMenu[index].displayOrder };
        }
        return item;
      })
      .sort((item1, item2) => item1.displayOrder - item2.displayOrder);
  };
  const findMenuItem = (menuItem, listCurrent) =>
    listCurrent.find((item) => item.menuCode === menuItem.menuCode);

  const listMenuFinal = (listAllMenu, currentMenu) => {
    let menuFilter = filterMenu(listAllMenu, currentMenu);
    return menuFilter.map((menuFilterItem) => {
      let newSub;
      if (
        menuFilterItem.haveScreen === 0 &&
        menuFilterItem.subMenus.length > 0
      ) {
        const listSubCurrent = findMenuItem(menuFilterItem, currentMenu);
        if (listSubCurrent) {
          newSub = filterMenu(menuFilterItem.subMenus, listSubCurrent.subMenus);
        }
      }
      return { ...menuFilterItem, subMenus: newSub };
    });
  };
  const renderMenu = useMemo(
    () =>
      listMenuFinal(listAllMenu, listMenu).map((item) =>
        renderMenuItem(item, false)
      ),
    [listMenu, isUpdated]
  );
  return listMenu.length ? (
    isDesktopOrLaptop ? (
      <Sider
        collapsible
        collapsed={collapsed}
        width={220}
        onCollapse={toggleButton}
        className="navigation-background"
        collapsedWidth={70}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo">
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col style={{ marginTop: "20px" }}>
              {collapsed ? (
                <img style={{ height: "44px" }} src={Logo} alt="logo" />
              ) : (
                <img style={{ height: "60px" }} src={Logo} alt="logo" />
              )}
            </Col>
          </Row>
        </div>
        {!collapsed && (
          <Row
            justify="center"
            style={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              paddingTop: "8px",
            }}
          >
            {lang.NAME_SYSTEM}
          </Row>
        )}
        <Menu
          mode="vertical"
          theme="dark"
          selectedKeys={[currentPage]}
          style={{
            width: "100%",
            marginTop: collapsed ? "10px" : "20px",
            paddingBottom: 50,
          }}
        >
          {renderMenu}
        </Menu>
        {!collapsed && (
          <Row className="copy-right">
            <Col>{lang.COPYRIGHT}</Col>
          </Row>
        )}
      </Sider>
    ) : (
      <>
        <Drawer
          placement="bottom"
          onClose={() => setOpenSelectOffice(false)}
          visible={openSelectOffice}
          className="custom-drawer--select"
          height="90%"
          title={<span className="title">{lang.SELECT}</span>}
        >
          <Input onChange={updateArraySearch} allowClear />
          <Row className="rowNotMargin">
            {arrayFactories.length !== 0 &&
              arrayFactories.map((item, index) => (
                <Col
                  span={24}
                  key={index}
                  className={
                    Number(selectedFactory) ===
                    Number(item.siteOfficeMasterCode)
                      ? "row-select row-select--selected"
                      : "row-select"
                  }
                  onClick={() => selectFactory(item.siteOfficeMasterCode)}
                >
                  <Row className="rowNotMargin">
                    <Col flex="auto" className="text-center name-office">
                      {item.siteOfficeMasterName}
                    </Col>
                    <Col flex="none" className="icon">
                      <img src={CheckedIcon} alt="icon" />
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </Drawer>
        <Drawer
          placement="left"
          className="custom-drawer"
          onClose={toggleButton}
          closable={false}
          visible={collapsed}
          key={"left"}
          footer={
            <Row className="copy-right__mobile" justify="center">
              <Col className="text-center">{lang.COPYRIGHT}</Col>
            </Row>
          }
        >
          <div className="logo">
            <Row className="rowNotMargin" align="middle" justify="center">
              <Col
                span={20}
                style={{ marginTop: "20px" }}
                className="text-center"
              >
                <img
                  style={{ height: "44px", paddingLeft: "30px" }}
                  src={Logo}
                  alt="logo"
                />
              </Col>
              <Col span={4}>
                <button className="hamburger opened" onClick={toggleButton}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </Col>
            </Row>
          </div>
          {!collapsed && (
            <Row
              justify="center"
              style={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                paddingTop: "8px",
              }}
            >
              {lang.NAME_SYSTEM}
            </Row>
          )}
          <Row className="rowNotMargin" style={{ padding: "24px 8px 8px" }}>
            {!!inMultipleSites ? (
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  setOpenSelectOffice(true);
                  toggleButton();
                }}
                className="selectOffice"
              >
                <Row className="rowNotMargin">
                  <Col flex="auto" className="selectOffice_name">
                    {selectedFactoryName}
                  </Col>
                  <Col flex="none">
                    <CaretDownOutlined />
                  </Col>
                </Row>
              </Button>
            ) : (
              selectedFactoryName && (
                <Input
                  className="selectOffice"
                  value={selectedFactoryName}
                  readOnly
                />
              )
            )}
          </Row>

          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[currentPage]}
            style={{ width: "100%", marginTop: collapsed ? "10px" : "20px" }}
            onClick={() => toggleButton()}
          >
            {renderMenu}
          </Menu>
        </Drawer>
      </>
    )
  ) : (
    <></>
  );
}

export default SideBar;
