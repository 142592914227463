import Api from "@src/api";

export default {
  // 1: Search-----
  // Input search 
  getDdlChecklist() {
    return Api().get("kbn/checklistList");
  },
  getDdlStatus() {
    return Api().get("kbn/checkResultStatus");
  },

  // Grid
  searchHistory(data) {
    return Api().get("ChecklistHistoryList", data);
  },

  deleteHistory(id) {
    return Api().delete(`ChecklistHistoryList/${id}`);
  },

  // 2: View------
  getDetail(id) {
    return Api().get(`ChecklistHistoryDetail/${id}`);
  },
  changeStatus(data,endUserMode) {
    const queryEndUSer = endUserMode ? `?endUserMode=${endUserMode}` : ""
    return Api().post(`ChecklistHistoryDetail/${data.id}/${data.command}` + queryEndUSer, data);
  },
  download(data,endUserMode) {
    const queryEndUSer = endUserMode ? `?endUserMode=${endUserMode}` : ""
    return Api().post(`ChecklistHistoryDetail/DownloadChecklistHistoryDetail` + queryEndUSer, data, { responseType: 'blob' });
  }
};
