import { Button, Col, Input, Modal, Row, Select, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./popup.scss";
import { lang, COMMON_CONSTANT, STYLE } from "../../constants/common.const";
import Api from "../../api/backend/reportManagement";
import { Apifacility } from "../../api/backend/facilityManagement";
import {
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { renderOption } from "../../constants/utils";
import Notification from "./notification";
function GroupReport({ visible, updateVisible }) {
  const [dataSource, setDataSource] = useState([]);
  const [optionUserName, updateOptionUserName] = useState([]);
  const [isDirty, setDirtyFormTable] = useState(false);
  const groupInputRef = useRef();
  const columns = [
    {
      title: " ",
      dataIndex: "delete",
      width: "5%",
      render: (_, row) => {
        return (
          <Button
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRow(row.key, row.isDefault)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    {
      title: (
        <span>
          {COMMON_CONSTANT.GROUP_NAME}
          <label className="blod mark-required">*</label>
        </span>
      ),
      dataIndex: "groupName",
      render: (value, row) => {
        return (
          <Input
            id={`group-${row.key}`}
            ref={groupInputRef}
            style={{ width: "100%" }}
            className={!value && isDirty ? STYLE.BORDER_RED : ""}
            value={value}
            onChange={(e) =>
              onChangeFilledInputColumn(row.key, "groupName", e.target.value)
            }
          />
        );
      },
    },
    {
      title: (
        <span>
          {lang.USER_NAME}
          <label className="blod mark-required">*</label>
        </span>
      ),
      dataIndex: "userName",
      render: (value, row) => {
        return (
          <Select
            className={`select w100 ${
              !value.length && isDirty ? STYLE.BORDER_RED : ""
            }`}
            maxTagCount={5}
            style={{ border: "none !important" }}
            tagRender={tagRender(row.userName)}
            onChange={(value) => value && onChangeUserName(row, value)}
            mode="multiple"
            value={row.userName}
          >
            <Select.Option key={"all"}>
              <span>
                <CheckCircleOutlined
                  style={{
                    color: "#0080CB",
                  }}
                  className="mr5"
                />
                {lang.EVERYONE}
              </span>
            </Select.Option>
            {renderOption(optionUserName, false)}
          </Select>
        );
      },
    },
  ];
  /**
   * call api get all group
   * call api get all user belong this site office
   */
  useEffect(() => {
    if (visible) {
      Api.getGroupReport().then((res) => {
        if (res.data.length) {
          setDataSource(
            res.data.map((item) => ({
              groupName: item.name,
              key: item.id,
              userName: item.usernames,
              isDefault: true,
            }))
          );
        } else {
          handleAddRow();
        }
      });
      Apifacility.getListUserForManangerFacility().then((res) => {
        const dataUser = res.data.map((item) => ({
          key: item.userName,
          value: item.displayName,
        }));
        updateOptionUserName(dataUser);
      });
    } else {
      setDataSource([]);
      updateOptionUserName([]);
      setDirtyFormTable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  /**
   * close modal
   * @returns
   */
  const closeModal = () => updateVisible(false);
  /**
   * delete row includes default row and row just added
   * @param {string} key
   * @param {boolean} isDefault
   */
  const handleDeleteRow = (key, isDefault) => {
    if (isDefault) {
      Api.deleteGroupReport(key).then((res) => {
        if (res.status === 200) {
          const newDataSource = dataSource.filter((item) => {
            return item.key !== key;
          });
          setDataSource(newDataSource);
        }
      });
    } else {
      const newDataSource = dataSource.filter((item) => {
        return item.key !== key;
      });
      setDataSource(newDataSource);
    }
  };
  /**
   * handle renew table to rerender table
   * @param {string} key
   * @param {string} nameColumn
   * @param {string} newValue
   */
  const onChangeFilledInputColumn = (key, nameColumn, newValue) => {
    // remove error border when re-edit form
    if (isDirty) {
      setDirtyFormTable(false);
    }
    const index = dataSource.findIndex((item) => item.key === key);
    const newObject = {
      [nameColumn]: newValue,
    };
    const newDataSource = [
      ...dataSource.slice(0, index),
      { ...dataSource[index], ...newObject },
      ...dataSource.slice(index + 1),
    ];
    setDataSource(newDataSource);
  };
  /**
   * render value to tags value
   * @param {string} menuCode
   * @returns
   */
  const tagRender = (menuCode) => (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    // if (checkFullfactory(menuCode)) return <div></div>;

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  /**
   *
   * @param {object} row
   * @param {array} value
   */
  const onChangeUserName = (row, value) => {
    let newUser = [];
    if (value[value.length - 1] === "all") {
      newUser = [...optionUserName.map((item) => item.key)];
    } else {
      newUser = [...value];
    }
    onChangeFilledInputColumn(row.key, "userName", newUser);
  };
  /**
   * handle add new row
   */

  useEffect(() => {
    const lastKey = dataSource.length
      ? dataSource[dataSource.length - 1].key
      : null;
    if (lastKey) {
      const focusInput = document.getElementById(`group-${lastKey}`);
      if (focusInput) {
        focusInput.focus();
      }
    }
  }, [dataSource.length]);
  const handleAddRow = () => {
    const key = Date.now();
    const newRow = {
      key,
      groupName: null,
      userName: [],
      isDefault: false,
    };
    setDataSource([...dataSource, newRow]);
  };
  /**
   * check required cell in table
   */
  const checkValidate = () => {
    let isErrFlag = false;
    for (let i = 0; i < dataSource.length; i++) {
      if (!dataSource[i].groupName || !dataSource[i].userName.length) {
        isErrFlag = true;
        break;
      }
    }
    setDirtyFormTable(true);
    if (isErrFlag) {
      return;
    } else {
      const params = dataSource.map((item) => ({
        name: item.groupName,
        id: item.isDefault ? item.key : null,
        usernames: item.userName,
      }));
      updateGroup(params);
    }
  };
  const updateGroup = (params) => {
    Api.updateGroupReport(params).then((res) => {
      if (res.status === 200) {
        Notification({
          type: "success",
          text: COMMON_CONSTANT.BOM_C003,
        });
        closeModal();
      }
    });
  };
  /**
   * render footer
   * @returns
   */
  const renderFooter = () => (
    <Row justify="end">
      <Col>
        <Button
          className="buttonPC button--info wAuto ml10"
          onClick={checkValidate}
        >
          <SaveOutlined />
          {lang.SAVE}
        </Button>
      </Col>
      <Col>
        <Button
          className="buttonPC button--outline --todo-- wAuto ml15"
          onClick={closeModal}
        >
          <CloseCircleOutlined />
          {lang.CANCEL}
        </Button>
      </Col>
    </Row>
  );
  return (
    <Modal
      centered
      width={900}
      id="popup-group-report"
      title={lang.GROUP_REPORT}
      visible={visible}
      footer={renderFooter()}
      onCancel={closeModal}
    >
      <Table
        scroll={dataSource && dataSource.length > 0 && { y: 300, x: 768 }}
        locale={{
          emptyText: "",
        }}
        pagination={false}
        dataSource={dataSource}
        className="tableAddMachine"
        columns={columns}
      />
      <div className="mt8">
        <Button
          style={{
            background: "#f7f9fc",
            width: "auto",
            minWidth: "auto",
          }}
          className="pr10 pl10 mt4 mb4 ml10"
          onClick={() => handleAddRow()}
          icon={<PlusOutlined />}
        ></Button>
      </div>
    </Modal>
  );
}
export default GroupReport;
