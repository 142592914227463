/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import API_EM from "../../api/backend/employee";
import API from "../../api/backend/homeBanner";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { KEY, lang } from "../../constants/common.const";

import { CloseCircleOutlined, PaperClipOutlined } from "@ant-design/icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isImage, MSG_CATCH, showMessage } from "../../constants/utils";

import {
  getDataNotification,
  updateReadFlagHomeBanner,
} from "@src/redux/homeBanner";
import { Content } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import StatusConst from "../../constants/status.const";
import classDdl from "../../models/control/dropdownlist";
import classPopup from "../../models/control/popup";
import classText from "../../models/control/text";
import { checkRoleScreen } from "../../utils/roles";
import "./index.scss";
import TextArea from "antd/lib/input/TextArea";

function ViewHomeBanner() {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  let [txtTitle, settxtTitle] = useState(new classText());
  let [txtType, settxtType] = useState(new classText());
  let [txtContent, settxtContent] = useState(new classDdl());
  const [sender, updateSender] = useState("");
  let [txtCreateDatetimeDisplay, settxtCreateDatetimeDisplay] = useState(
    new classDdl()
  );
  const [type, setType] = useState();

  // User
  const [txtUser, setTxtUser] = useState(new classText());
  const [modeUser, setModeUser] = useState(new classText());
  // Popup
  let [popupAttach, setPopupAttach] = useState(new classPopup());
  // In-Popup
  let [tblAttach, setTblAttach] = useState([]);

  const columnsAttach = [
    {
      title: "No",
      dataIndex: "ItemOrder",
    },
    {
      title: "プレビュー",
      dataIndex: "FileName",
      width: "15%",
      render: (text, row) => {
        let urlFile = row.attachmentUrl;
        if (isImage(row.DotName)) {
          return (
            <div className="center">
              <a target="_blank" rel="noopener noreferrer" href={urlFile}>
                <img
                  width="48"
                  height="48"
                  border="0"
                  alt="プレビュー"
                  title={row.FileName}
                  dragid="0"
                  draggable="true"
                  src={urlFile}
                />
              </a>
            </div>
          );
        } else {
          return (
            <div className="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFile}
                title={row.FileName}
              >
                <PaperClipOutlined className="blue iconL" />
              </a>
            </div>
          );
        }
      },
    },
    {
      title: "ファイル名",
      dataIndex: "FileName",
      render: (text) => {
        return text;
      },
    },
    {
      title: "備考とメヂイアの挿入と操作",
      dataIndex: "Description",
      width: "35%",
      render: (text) => {
        return text;
      },
    },
  ];

  const init = () => {
    setInitPage(true);
    // Open loading
    openLoadingSpinner();

    Promise.all([API.getDetail(id)])
      .then((res) => {
        if (res[0] && !res[0].data && id) {
          history.push("/HomeBannerManagement");
          return;
        }
        let data = res[0] ? res[0].data : null;
        //Group1
        if (!data) {
          throw new Error();
        }
        // Normal
        settxtTitle({ ...txtTitle, value: data.title });
        settxtType({ ...txtType, value: data.typeValue });
        setType(Number(data.type));
        settxtContent({ ...txtContent, value: data.content });
        updateSender(data.senderValue);
        settxtCreateDatetimeDisplay({
          ...txtCreateDatetimeDisplay,
          value: data.createDatetimeDisplay,
        });

        // User
        setModeUser({ ...modeUser, value: data.receiver });
        setTxtUser({ ...txtUser, value: data.receiverDetailName });
        // Popup attach
        // data.listHomeBannerAttachment = data.listHomeBannerAttachment.map(
        //   (v) => {
        //     let row = {};
        //     row["ItemOrder"] = v.itemOrder; // Key
        //     row["FileName"] = getNameFile(v.fileName);
        //     row["DotName"] = getDotFile(v.fileName);
        //     row["Description"] = v.description;
        //     row["attachmentUrl"] = v.attachmentUrl;
        //     return row;
        //   }
        // );
        setTblAttach(data.listHomeBannerAttachment);

        // Pass init
        setInitPage(false);
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  // Init
  useEffect(checkRoleScreen(location.pathname, allowRoute) && init, [
    id,
    allowRoute,
  ]);

  useEffect(() => {
    const callback = (res) => {
      dispatch(
        getDataNotification({
          params: {
            readFlag: StatusConst.STATUS_HOME_BANNER.NOTSEEN,
            offset: 0,
            limit: 100,
          },
        })
      );
    };

    dispatch(
      updateReadFlagHomeBanner({
        params: {
          id: id,
        },
        callback,
      })
    );
  }, [id]);

  function backToSearch() {
    history.goBack();
  }

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.HOME_BANNER_INFORMATION} />
      <Content
        className="site-layout-background "
        style={{
          margin: "24px calc(25% - 70px)",
          padding: 24,
        }}
      >
        <Row className="content-notification">
          <Col
            span={24}
            className="box-notification"
            style={{ color: StatusConst.TYPE_HOME_BANNER[type] }}
          >
            {txtTitle.value && (
              <span
                style={{
                  borderBottom: `1px solid ${StatusConst.TYPE_HOME_BANNER[type]}`,
                  color: StatusConst.TYPE_HOME_BANNER[type],
                }}
              >
                【{txtType.value}】{txtTitle.value}
              </span>
            )}
          </Col>
          <Col style={{ whiteSpace: "pre-wrap" }}>{txtContent.value}</Col>
        </Row>
        <Row className="rowNotMargin" style={{ padding: "15px 0 6px 0" }}>
          <Col flex="100px" style={{ fontWeight: "bold" }}>
            {lang.REGISTRATION_DATE}
          </Col>
          <span> : </span>
          <Col style={{ paddingLeft: "6px" }}>
            {txtCreateDatetimeDisplay.value}
          </Col>
        </Row>
        <Row className="rowNotMargin" style={{ padding: "6px 0" }}>
          <Col flex="100px" style={{ fontWeight: "bold" }}>
            {lang.SENDER}
          </Col>
          <span> : </span>
          <Col style={{ paddingLeft: "6px" }}>{sender}</Col>
        </Row>
        <Row className="rowNotMargin" style={{ padding: "6px 0" }}>
          <Col flex="100px" style={{ fontWeight: "bold" }}>
            {lang.ATTACH}
          </Col>
          <span> : </span>
          <Col style={{ paddingLeft: "6px" }}>
            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
              {tblAttach.length
                ? tblAttach.map((item, index) => {
                    return (
                      <li key={index} className="machine-file">
                        <a href={item.attachmentUrl}>{item.fileName}</a>
                      </li>
                    );
                  })
                : "無し"}
            </ul>
          </Col>
        </Row>
        <Row className="rowNotMargin" style={{ padding: "6px 0" }}>
          <Col flex="100px" style={{ fontWeight: "bold" }}>
            {lang.RECIPIENT}
          </Col>
          <span> : </span>
          <Col style={{ paddingLeft: "6px" }}>
            {" "}
            {modeUser.value === "1" ? lang.EVERYONE : lang.DESIGNATED_PERSON}
          </Col>
        </Row>
        {modeUser.value === "0" && (
          <Row className="rowNotMargin" style={{ padding: "6px 0" }}>
            <Col flex="100px" style={{ fontWeight: "bold" }}>
              {lang.USER_NAME}
            </Col>
            <span> : </span>
            <Col style={{ paddingLeft: "6px" }}>
              <span>{txtUser.value}</span>
            </Col>
          </Row>
        )}
      </Content>
      <Row
        justify="end"
        className="mt10 mb10"
        style={{
          margin: "24px calc(25% - 70px)",
        }}
      >
        <Button className="buttonPC button--info ml15" onClick={backToSearch}>
          <CloseCircleOutlined />
          {lang.CLOSE}
        </Button>
      </Row>
      {/* attachFileModal */}
      <Modal
        centered
        width="50%"
        bodyStyle={{ height: "400px", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.ATTACH}</div>}
        visible={popupAttach.show}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              setPopupAttach({ ...popupAttach, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <Table
          className="tbl-original"
          rowKey="ItemOrder"
          dataSource={tblAttach}
          columns={columnsAttach}
          pagination={false}
        />
      </Modal>
    </div>
  );
}

export default ViewHomeBanner;
