/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Layout,
  Col,
  Row,
  Select,
  Input,
  Spin,
  Divider,
  Button,
  Menu,
  Dropdown,
  DatePicker,
  Tooltip,
  Modal,
  Form,
  Empty,
  Space,
  Typography,
  Tabs,
} from "antd";
import { useMediaQuery } from "react-responsive";
import StatusConst from "../../constants/status.const";
// import { ReactComponent as ConfirmIcon } from "@src/styles/icon/comfirm.svg";
import { ReactComponent as EditIcon } from "@src/styles/icon/edit.svg";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import { ReactComponent as CloneIcon } from "@src/styles/icon/clone.svg";
import { ReactComponent as ViewHistoryIcon } from "@src/styles/icon/view_history.svg";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import InfiniteScroll from "react-infinite-scroller";
import TitlePage from "../../component/Desktop/titlePage";
import { Link, useLocation } from "react-router-dom";
import API from "../../api/backend/managementCL";
import Loading from "../../component/Desktop/loading/loading";
import LoadingInline from "../../component/Popup/loading-inline";

import { setTabMode, setTabUser, setTabCommon } from "@src/redux/checkList";
import TabsCustom from "@src/component/TabsComponent/TabsCustom";
import { useSelector, useDispatch } from "react-redux";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";

import {
  MSG_CATCH,
  statusRes,
  INPUT_DATE,
  enterSearch,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
} from "../../constants/utils";
import { useHistory } from "react-router-dom";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import classGrid from "../../models/control/grid";
import { checkRoleScreen } from "../../utils/roles";
const { Paragraph } = Typography;
const { Content } = Layout;
const { Option } = Select;
let objSearchOrigin = {
  checklistName: null,
  isPreUseChecklist: null,
  status: null,
  createDate: null,
};
let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function CheckListManagement({ history: historyPage }) {
  // Step1: Update param
  if (historyPage.action === "POP") {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }
  const { allowRoute } = useSelector((state) => state.auth);
  let [initPage, setInitPage] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [formSearch] = Form.useForm();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [stateDataAll, setStateDataAll] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });

  const [stateDataClDefault, setStateDataCLDefault] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });

  const [stateDataClPreCheck, setStateDataClPreCheck] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Input
  const [txtChecklistName, setTxtChecklistName] = useState(
    getTxtValue(objSearch["checklistName"])
  );
  const [isPreUseChecklist, setIsPreUseChecklist] = useState(
    getTxtValue(objSearch["isPreUseChecklist"])
  );
  const [ddlStatus, setDdlStatus] = useState(getTxtValue(objSearch["status"]));
  const [txtCreateDate, setTxtCreateDate] = useState(
    getDateValue(objSearch["createDate"])
  );
  // Table
  const [gridAll, setGridAll] = useState(new classGrid());
  const [gridClDefault, setGridClDefault] = useState(new classGrid());
  const [gridClPreCheck, setGridClPreCheck] = useState(new classGrid());

  // Column
  const titleVersion = <b>{lang.LIST_VERSION}</b>;

  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.checklistName = txtChecklistName ? txtChecklistName.trim() : null;
    objSearch.isPreUseChecklist = isPreUseChecklist
      ? isPreUseChecklist.trim()
      : null;
    objSearch.status = ddlStatus;
    objSearch.createDate = txtCreateDate;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = (offset = 0) => {
    // Loading
    if (offset === 0) {
      openLoadingSpinner(); //Only init
    }

    setStateDataAll({ ...stateDataAll, loading: true });
    setStateDataCLDefault({ ...stateDataClDefault, loading: true });
    setStateDataClPreCheck({ ...stateDataClPreCheck, loading: true });

    // input to objectSearch; push url
    syncInput();
    // get param form url
    objSearch = getQuerySearch();
    // Update format: date, user
    objSearch.createDate = int2DateString(Number(objSearch.createDate));

    Promise.all([
      API.getData({
        params: {
          ...objSearch,
          isPreUseChecklist: "",
          isVisible: 0,
          limit: 20,
          offset: offset,
        },
      }),
      API.getData({
        params: {
          ...objSearch,
          isPreUseChecklist: "0",
          isVisible: 0,
          limit: 20,
          offset: offset,
        },
      }),
      API.getData({
        params: {
          ...objSearch,
          isPreUseChecklist: "1",
          isVisible: 0,
          limit: 20,
          offset: offset,
        },
      }),
    ])
      .then((res) => {
        let resAll = res[0];
        let resCLDefault = res[1];
        let resCLPreCheck = res[2];

        if (offset !== 0) {
          // Load more
          setGridAll({
            ...gridAll,
            data: [...gridAll.data, ...resAll.data.data],
          });
          setGridClDefault({
            ...gridClDefault,
            data: [...gridClDefault.data, ...resCLDefault.data.data],
          });
          setGridClPreCheck({
            ...gridClPreCheck,
            data: [...gridClPreCheck.data, ...resCLPreCheck.data.data],
          });
        } else {
          // Load init
          setGridAll(resAll.data);
          setGridClDefault(resCLDefault.data);
          setGridClPreCheck(resCLPreCheck.data);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
        setInitPage(true);
      });
  };
  const search_onClick_Default = () => {
    if (initPage) {
      search_onClick();
    }
  };
  // clearInputSearch
  const clearInputSearch = () => {
    setTxtChecklistName("");
    setIsPreUseChecklist("");
    setTxtCreateDate("");
    setDdlStatus(null);
    formSearch.resetFields();
    formSearch.submit();
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      search_onClick();
    }
  }, [allowRoute]);
  // Update offset
  useEffect(() => {
    setStateDataCLDefault({
      ...stateDataClDefault,
      offset: gridClDefault.data.length,
      hasMore: gridClDefault.data.length !== gridClDefault.count,
      loading: false,
    });
  }, [gridClDefault.data]);

  // Update offset
  useEffect(() => {
    setStateDataAll({
      ...stateDataAll,
      offset: gridAll.data.length,
      hasMore: gridAll.data.length !== gridAll.count,
      loading: false,
    });
  }, [gridAll.data]);

  // Update offset
  useEffect(() => {
    setStateDataClPreCheck({
      ...stateDataClPreCheck,
      offset: gridClPreCheck.data.length,
      hasMore: gridClPreCheck.data.length !== gridClPreCheck.count,
      loading: false,
    });
  }, [gridClPreCheck.data]);

  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick_Default();
  }, [txtChecklistName]);
  // Alway device
  useEffect(() => {
    search_onClick_Default();
  }, [ddlStatus, txtCreateDate]);

  // Menu action
  const menuAction = (row) => {
    // List view
    const listVer = [];
    for (let i = row.version; i > 0; i--) {
      listVer.push(
        <Menu.Item
          disabled={row.versions.indexOf(i) === -1}
          key={i}
          onClick={() => {
            history.push(`/ViewChecklistv2/${row.id}?version=${i}`);
          }}
        >
          {lang.VERSION}
          {i}
        </Menu.Item>
      );
    }
    return (
      <Space size={isPC ? 8 : 12}>
        {/* Edit */}
        <Tooltip title={row.isDraft ? lang.EDIT : lang.VERSION_UP}>
          <Button
            type="link"
            className="iconAction-edit btnLink"
            onClick={(e) => {
              history.push(`/EditChecklistv2/${row.id}?version=${row.version}`);
              e.preventDefault();
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {/* View */}
        {row.version === 1 ? (
          <Tooltip title={lang.VIEW}>
            <Button
              type="link"
              className="iconAction-view btnLink"
              onClick={(e) => {
                history.push(
                  `/ViewChecklistv2/${row.id}?version=${row.version}`
                );
                e.preventDefault();
              }}
            >
              <ViewHistoryIcon className="w18px" />
            </Button>
          </Tooltip>
        ) : (
          <Dropdown
            overlay={
              <Menu key="g1" title={titleVersion}>
                {listVer}
              </Menu>
            }
            placement="bottomLeft"
          >
            <Tooltip title={lang.VIEW}>
              <Button
                type="link"
                className="iconAction-view btnLink"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <ViewHistoryIcon className="w18px" />
              </Button>
            </Tooltip>
          </Dropdown>
        )}
        {/* Delete */}
        {row.version === 1 ? (
          <Tooltip title={lang.DELETE}>
            <Button
              type="link"
              className="iconAction-delete btnLink"
              onClick={(e) => {
                if (
                  row.haveCheckResult === true ||
                  (row.haveCheckResult && row.haveCheckResult.length > 0)
                ) {
                  // Exist input CL
                  showMessage(KEY.ERROR, COMMON_CONSTANT.BOM_C035);
                } else {
                  actionDelete(row, null);
                }
                e.preventDefault();
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        ) : (
          menuDelete(row)
        )}
        {/* Clone */}
        <Tooltip title={lang.CLONE}>
          <Button
            type="link"
            className="iconAction-clone btnLink"
            onClick={(e) => {
              history.push(
                `/CloneChecklistv2/${row.id}?version=${row.version}`
              );
              e.preventDefault();
            }}
          >
            <CloneIcon className="w18px" />
          </Button>
        </Tooltip>
      </Space>
    );
  };

  // Delete: Start
  const menuDelete = (row) => {
    const listVer = [];
    for (let i = row.version; i >= 0; i--) {
      listVer.push(
        i === 0 ? (
          // Delete all
          <Menu.Item
            disabled={row.haveCheckResult && row.haveCheckResult.length > 0}
            key={i}
            onClick={() => {
              actionDelete(row, null);
            }}
            className="center btnDeleteAll"
          >
            {lang.DELETE_ALL}
          </Menu.Item>
        ) : (
          // Delete ver
          <Menu.Item
            disabled={
              (row.haveCheckResult && row.haveCheckResult.indexOf(i) >= 0) ||
              row.versions.indexOf(i) === -1
            }
            key={i}
            onClick={() => {
              actionDelete(row, i);
            }}
          >
            {lang.VERSION}
            {i}
          </Menu.Item>
        )
      );
    }
    return (
      <Dropdown placement="bottomLeft" overlay={<Menu>{listVer}</Menu>}>
        <Tooltip title={lang.DELETE}>
          <Button
            type="link"
            className="iconAction-delete btnLink"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </Dropdown>
    );
  };
  const actionDelete = (row, version) => {
    // On del
    const funcDel = async () => {
      openLoadingSpinner();
      // Close warning
      Modal.destroyAll();
      // API Del
      try {
        const res = await API.delete(row.id, version);
        if (statusRes(res)) {
          // API Search
          await search_onClick_Default();
          // Open success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      } catch (err) {
        showMessage(
          KEY.ERROR,
          (err.response && err.response.data.msg) || MSG_CATCH()
        );
      }

      closeLoadingSpinner();
    };

    // Show popup
    Modal.confirm({
      centered: true,
      title: lang.WARMING,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.DELETE,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };
  // function showPopupChecklistInputted() {
  //   showMessage(KEY.ERROR, COMMON_CONSTANT.BOM_C035);
  // }
  // Delete: End

  const activeTab = (tab, type) => {
    dispatch(
      type === KEY.GROUP_TYPE_COMMON
        ? setTabCommon(tab.toString())
        : setTabUser(tab.toString())
    );
  };

  ///// RENDER
  function renderCheckListItem(gridData) {
    return (
      <>
        <Row gutter={[20, 20]} style={{ minHeight: "200px" }}>
          {gridData.data.length > 0 ? (
            <>
              {gridData.data.map((v, key) => {
                v.isPreUseChecklist = v.isPreUseChecklist
                  ? v.isPreUseChecklist
                  : KEY.TYPE_DEFAULT_CHECKLIST;
                return (
                  <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={key}>
                    <Link to={`/ViewChecklistv2/${v.id}?version=${v.version}`}>
                      <div className="itemList-2 ">
                        <Row justify="space-between" className="align-center">
                          <Col className="id">ID: {v.id}</Col>
                          <Col
                            className="icon"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {menuAction(v)}
                          </Col>
                        </Row>
                        <div className="title">
                          <Paragraph ellipsis={{ rows: 2 }}>
                            <Tooltip title={v.checklistName}>
                              {v.checklistName}
                            </Tooltip>
                          </Paragraph>
                        </div>

                        <Row justify="space-between" className="more">
                          <Col>
                            <div className="account">{v.createName}</div>
                          </Col>
                          <Col>
                            <div
                              style={{
                                color:
                                  StatusConst.TYPE_CHECKLIST_COLOR[
                                    v.isPreUseChecklist
                                  ],
                              }}
                            >
                              {
                                StatusConst.TYPE_CHECKLIST_STATUS[
                                  v.isPreUseChecklist
                                ]
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row justify="space-between" className="more">
                          <Col>{v.createDatetime}</Col>
                          <Col>
                            <Paragraph
                              ellipsis={{ rows: 1 }}
                              className="textStatus"
                              style={{
                                color:
                                  StatusConst.STATUS_CL_MANAGEMENT[v.status],
                                background:
                                  StatusConst.STATUS_CL_MANAGEMENT[v.status] +
                                  "1A",
                              }}
                            >
                              {v.status}
                            </Paragraph>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                  </Col>
                );
              })}
              <Col span={24} className="content-center">
                <LoadingInline isLoadingInline={stateDataClPreCheck.loading} />
              </Col>
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w100" />
          )}
        </Row>
      </>
    );
  }

  return (
    <div className="des-content">
      <TitlePage name={lang.LIST_CHECKLIST} />
      <Content
        // className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          onFinish={search_onClick_Default}
        >
          <Row gutter={[8, isPC ? 0 : 16]}>
            <Col span={isPC ? 5 : 24}>
              <Input
                allowClear
                value={txtChecklistName}
                maxLength={255}
                placeholder="点検書式名"
                onChange={(e) => {
                  setTxtChecklistName(e.target.value);
                }}
                {...enterSearch(search_onClick_Default)}
              />
            </Col>
            {isPC && (
              <Col span="8">
                <Button
                  className="buttonPC button--info wAuto"
                  htmlType="submit"
                >
                  <SearchOutlined />
                  {lang.SEARCH}
                </Button>
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml10"
                  onClick={clearInputSearch}
                >
                  <ClearOutlined />
                  {lang.CLEAR}
                </Button>
              </Col>
            )}
          </Row>
          {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
          <Row gutter={[8, isPC ? 0 : 8]} className="align-center">
            {isPC && (
              <Col className="bold mr10">
                {lang.FILTER}
                {lang.COLON}
              </Col>
            )}
            <Col span={isPC ? 5 : 12}>
              <DatePicker
                format={KEY.DATE_DEFAULT}
                value={txtCreateDate}
                placeholder={"点検日時付"}
                className="w100"
                onChange={(v, dateString) => {
                  setTxtCreateDate(v);
                }}
                {...INPUT_DATE}
              />
            </Col>
            <Col span={isPC ? 5 : 12}>
              <Select
                value={ddlStatus}
                placeholder={lang.STATUS}
                onChange={(v) => {
                  setDdlStatus(v);
                }}
                className="w100"
              >
                <Option value=""></Option>
                <Option value="1">{lang.OFFICIAL}</Option>
                <Option value="0">{lang.DRAFT}</Option>
              </Select>
            </Col>
          </Row>
        </Form>
      </Content>
      {/* New */}
      <Content
        className={isPC && "site-layout-background"}
        style={{
          margin: "24px 16px",
          padding: isPC ? 24 : 0,
        }}
      >
        {/* <div className="titleText2">
          <ConfirmIcon className="svg-default" /> {lang.LIST_CHECKLIST}
        </div> */}
        <Row justify="space-between ">
          <Col span={24}>
            <Button
              type="link"
              onClick={() => {
                history.push("/CreateChecklistv2");
              }}
              className="btnAddRecord mb15"
            >
              <AddIcon />
              {lang.ADD}
            </Button>
          </Col>
        </Row>

        <div className={"tabsResponsive " + (!isPC && "tabsResponsive_mobile")}>
          <Tabs type={"card"} defaultActiveKey={0}>
            <Tabs.TabPane tab={lang.ALL_CHECKLIST} key={0} closable={false}>
              <Spin spinning={showLoading}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => {
                    search_onClick(stateDataAll.offset);
                  }}
                  hasMore={!stateDataAll.loading && stateDataAll.hasMore}
                  useWindow={true}
                >
                  {renderCheckListItem(gridAll)}
                </InfiniteScroll>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={lang.PATROL_INSPECTION_CHECKLIST}
              key={1}
              closable={false}
            >
              <Spin spinning={showLoading}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => {
                    search_onClick(stateDataClDefault.offset);
                  }}
                  hasMore={
                    !stateDataClDefault.loading && stateDataClDefault.hasMore
                  }
                  useWindow={true}
                >
                  {renderCheckListItem(gridClDefault)}
                </InfiniteScroll>
              </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane tab={lang.PRE_USE_CHECKLIST} key={2} closable={false}>
              <Spin spinning={showLoading}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => {
                    search_onClick(stateDataClPreCheck.offset);
                  }}
                  hasMore={
                    !stateDataClPreCheck.loading && stateDataClPreCheck.hasMore
                  }
                  useWindow={true}
                >
                  {renderCheckListItem(gridClPreCheck)}
                </InfiniteScroll>
              </Spin>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Content>

      {/* Loader */}
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default CheckListManagement;
