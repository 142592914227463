import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Spin,
  Form,
  Checkbox,
  Empty,
  Tooltip,
} from "antd";
import { lang } from "../../../constants/common.const";
import InfiniteScroll from "react-infinite-scroller";
import { fromUnixTime } from "date-fns";
import format from "@src/utils/format";

const { Paragraph } = Typography;

function PopupSearchCL({
  isDesktopOrLaptop,
  popup,
  _nameChecklist,
  set_nameChecklist,
  gridPopup,
  _listChecklist,
  form,
  loadSpin,
}) {
  useEffect(() => {
    // Event only mobile
    !isDesktopOrLaptop && popup.search(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_nameChecklist]);

  return (
    <div>
      <Row className={isDesktopOrLaptop && "mb15 pl5 pr5"}>
        <Col span={24}>
          <Form
            form={form}
            name="horizontal_login"
            layout={isDesktopOrLaptop && "inline"}
            onFinish={() => {
              popup.search(true);
            }}
          >
            <Form.Item name="checklistName">
              <Input
                width={!isDesktopOrLaptop ? "100%" : ""}
                placeholder={lang.CHECKLIST_NAME}
                value={_nameChecklist.value}
                onChange={async (e) => {
                  set_nameChecklist({
                    ..._nameChecklist,
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
            {isDesktopOrLaptop && (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {lang.SEARCH}
                </Button>
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
      {/* Grid */}
      <div className="scroll-grid pt5 pl5 pb5 pr5">
        <Spin spinning={loadSpin} className="content-center"></Spin>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={popup.gridLoadMore}
          hasMore={!gridPopup.loading && gridPopup.hasMore}
          useWindow={false}
        >
          <Row gutter={[20, 20]}>
            {_listChecklist && _listChecklist.length > 0 ? (
              _listChecklist.map((e, index) => {
                return (
                  <Col xs={24} sm={12} md={8} lg={6} key={e.id}>
                    <Row
                      align="middle"
                      style={{ padding: "13px 16px" }}
                      className="itemList itemList-row "
                      disabled={e.disabled}
                      onClick={() => {
                        !e.disabled &&
                          popup.grid_checkbox(e.id, index, !e["checked"]);
                      }}
                    >
                      {/* Title */}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col className="id">ID: {e.id}</Col>
                          <Col className="created">
                            <Checkbox
                              disabled={e.disabled}
                              checked={e.checked}
                              onChange={(e) => {
                                popup.grid_checkbox(
                                  e.id,
                                  index,
                                  e.target.checked
                                );
                              }}
                            ></Checkbox>
                          </Col>
                        </Row>
                      </Col>
                      {/* Body */}
                      <Col className="title" span={24}>
                        <Paragraph
                          ellipsis={{ rows: 2 }}
                          className="text-weight-700"
                        >
                          <Tooltip title={e.checklistName}>
                            {e.checklistName}
                          </Tooltip>
                        </Paragraph>
                      </Col>
                      {/* Footer */}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col className="created">{e.createName}</Col>
                          <Col className="created">
                            {format(
                              fromUnixTime(Number(e.createDatetime)),
                              "yyyy/MM/dd"
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <div className="center mt20 w100">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default PopupSearchCL;
