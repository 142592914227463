import React, { useEffect } from "react";
import { Tabs, Button, Row } from "antd";
import { useMediaQuery } from "react-responsive";
import { StickyContainer, Sticky } from "react-sticky";
import { ReactComponent as ChevronRight } from "@src/styles/icon/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "@src/styles/icon/chevron-left.svg";
import { KEY } from "../../constants/common.const";

function TabsCustom({ children, sticky = false, ...other }) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  // Custom
  const renderNode = (v) => {
    const data = v.props;
    const titleTab = data.tab;
    return (
      <Tabs.TabPane
        {...data}
        key={v.key}
        tab={
          React.isValidElement(titleTab) ? (
            titleTab
          ) : (
            <div
              className="textOverflow"
              style={{ maxWidth: "155px", fontSize: isPC ? "14px" : "20px" }}
            >
              {titleTab}
            </div>
          )
        }
      >
        {data.children}
      </Tabs.TabPane>
    );
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky>
      {({ style }) => {
        if (style.position === "fixed") {
          style = { ...style, top: "54px" };
        }
        return <DefaultTabBar {...props} style={{ ...style }} />;
      }}
    </Sticky>
  );

  // Wheel tab
  const onWheelTabs = (plus) => {
    document.querySelectorAll("div.ant-tabs-nav-wrap")[0].dispatchEvent(
      new WheelEvent("wheel", {
        deltaY: Number(plus + 450),
      })
    );

    updateStateScroll();
  };

  const updateStateScroll = () => {
    setTimeout(() => {
      const cls = document.querySelector('[class*="ant-tabs-nav-wrap-ping-"]')
        ? document
            .querySelector('[class*="ant-tabs-nav-wrap-ping-"]')
            .getAttribute("class")
        : "";

      //  Show / hide
      showScrollTabs(cls.indexOf("right") !== -1 || cls.indexOf("left") !== -1);
      showScrollTabsLeft(cls.indexOf("left") > -1);
      showScrollTabsRight(cls.indexOf("right") > -1);
    }, 10);
  };
  useEffect(updateStateScroll);
  window.addEventListener("resize", updateStateScroll);

  const showScrollTabs = (show) => {
    if (document.querySelector(".btnNextPrev")) {
      if (show) {
        document.querySelector(".btnNextPrev").classList.add("d-flex");
        //
        document
          .querySelector(".ant-tabs-nav-list")
          .classList.add("pl0", "pr0");
      } else {
        document.querySelector(".btnNextPrev").classList.remove("d-flex");
        //
        document
          .querySelector(".ant-tabs-nav-list")
          .classList.remove("pl0", "pr0");
      }
    }
  };
  const showScrollTabsLeft = (show) => {
    if (document.querySelector(".btnPrev")) {
      document.querySelector(".btnPrev").disabled = !show;
    }
  };
  const showScrollTabsRight = (show) => {
    if (document.querySelector(".btnNext")) {
      document.querySelector(".btnNext").disabled = !show;
    }
  };

  // .ant-tabs-nav-list .ant-tabs-nav-add // Button add in-line

  return (
    <div className={"tabsResponsive " + (!isPC && "tabsResponsive_mobile")}>
      <StickyContainer>
        <Tabs
          renderTabBar={sticky && renderTabBar}
          {...other}
          destroyInactiveTabPane="true"
          tabBarExtraContent={
            isPC && {
              left: (
                <Row
                  className={"scrollTabs  mr5 ml5 btnNextPrev"}
                  justify="space-between"
                >
                  <Button
                    type="link"
                    className="btnLink btnPrev"
                    onClick={() => {
                      onWheelTabs("-");
                    }}
                  >
                    <ChevronLeft />
                  </Button>
                  <Button
                    type="link"
                    className="btnLink btnNext"
                    onClick={() => {
                      onWheelTabs("+");
                    }}
                  >
                    <ChevronRight />
                  </Button>
                </Row>
              ),
            }
          }
        >
          {children.map((v) => {
            if (Array.isArray(v)) {
              // Array
              return v.map((v2) => {
                return v2 ? renderNode(v2) : null;
              });
            } else {
              // Node
              return v ? renderNode(v) : null;
            }
          })}
        </Tabs>
      </StickyContainer>
    </div>
  );
}

export default TabsCustom;
