/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Modal,
  Table,
  Space,
  Tooltip,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import API from "../../api/backend/inventory";
import KBN from "../../api/backend/kbn";
import { CONFIG, KEY, lang, STYLE } from "../../constants/common.const";
import {
  INPUT_DATE,
  isEmpty,
  MSG_CATCH,
  saveFile,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import classPopup from "../../models/control/popup";
import List from "./list";
import classGrid from "../../models/control/grid";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
import {
  ClearOutlined,
  CloudDownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import Loading from "../../component/Desktop/loading/loading";

export default function Warehouses_list() {
  const [grid, setGrid] = useState(new classGrid());
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState(new classDdl());
  const [category, setCategory] = useState(new classDdl());
  const [item, setItem] = useState(new classText());
  const [startDate, setStartDate] = useState(new classText());
  const startDateEl = new useRef(null);
  const [endDate, setEndDate] = useState(new classText());
  const [popupDetail, setPopupDetail] = useState(new classPopup());
  const [showLoading, setShowLoading] = useState(false);
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const disabledButtonDownload = grid.data.length === 0;
  const classBtn = isPC
    ? `${!disabledButtonDownload ? "buttonPC" : ""} button--outline wAuto`
    : `${!disabledButtonDownload ? "buttonPC" : ""} button--outline w100`;

  const inputs = [
    {
      name: lang.WAREHOUSES_NAME,
      get: warehouses,
      type: KEY.DDL,
      onChange: (v) => {
        setWarehouses({ ...warehouses, value: v });
      },
    },
    {
      name: lang.CATEGORY,
      get: category,
      type: KEY.DDL,
      onChange: (v) => {
        setCategory({ ...category, value: v });
      },
    },
    {
      name: lang.ITEM,
      get: item,
      type: KEY.TEXT,
      onChange: (e) => {
        setItem({ ...item, value: e.target.value });
      },
    },
    {
      title: (
        <>
          {lang.TARGET_DATE}
          <span style={{ color: "red" }} className="ml5">
            *
          </span>
        </>
      ),
      render: () => {
        return (
          <Row>
            <Col span={11}>
              <DatePicker
                format={KEY.DATE_DEFAULT}
                disabledDate={
                  (currentDate) => {
                    return endDate.value && (endDate.value.valueOf() < currentDate.valueOf());
                  }
                }
                ref={startDateEl}
                value={startDate.value}
                picker={startDate.picker}
                placeholder={startDate.format || KEY.DATE_DEFAULT}
                className={"w100 " + (startDate.error ? STYLE.BORDER_RED : "")}
                onChange={(v, dateString) => {
                  setStartDate({
                    ...startDate,
                    value: v,
                  });
                }}
                {...INPUT_DATE}
              />
            </Col>
            <Col span={2} className="center" style={{ padding: "7px" }}>
              ～
            </Col>
            <Col span={11}>
              <DatePicker
                disabledDate={
                  (currentDate) => {
                    return startDate.value && (startDate.value.valueOf() > currentDate.valueOf());
                  }
                }
                format={endDate.format || KEY.DATE_DEFAULT}
                value={endDate.value}
                picker={endDate.picker}
                placeholder={endDate.format || KEY.DATE_DEFAULT}
                className={"w100 " + (endDate.error ? STYLE.BORDER_RED : "")}
                onChange={(v, dateString) => {
                  setEndDate({ ...endDate, value: v });
                }}
                {...INPUT_DATE}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const columns = [
    {
      title: lang.ITEM_NAME,
      dataIndex: "inventoryItemName",
      align: "center",
    },
    {
      title: lang.WAREHOUSES_NAME,
      dataIndex: "inventoryWarehouseName",
      align: "center",
    },
    {
      title: lang.BEGINING_STOCK_QUANTITY,
      dataIndex: "beginingStockQuantity",
      align: "right",
    },
    {
      title: lang.STOCK_IN_QUANTITY,
      dataIndex: "stockInQuantity",
      align: "right",
      render: (txt, row) => renderColLink(txt, row, KEY.IMPORT),
    },
    {
      title: lang.STOCK_OUT_QUANTITY,
      dataIndex: "stockOutQuantity",
      align: "right",
      render: (txt, row) => renderColLink(txt, row, KEY.EXPORT),
    },
    {
      title: lang.ENDING_STOCK_QUANTITY,
      align: "right",
      dataIndex: "endingStockQuantity",
    },
    {
      title: lang.CATEGORY_NAME,
      dataIndex: "categoryName",
      align: "center",
    },
    {
      title: lang.INVENTORY_UNIT,
      dataIndex: "inventoryUnit",
      align: "center",
    },
    {
      title: lang.ITEM_CODE,
      dataIndex: "inventoryWarehouseItemCode",
      align: "center",
    },
  ];
  const columnsDetail = [
    {
      title: lang.TRADING_CODE,
      dataIndex: "inventoryTransactionCode",
      align: "center",
    },
    {
      title:
        popupDetail.title === lang.IMPORT_INVENTORY
          ? lang.QUANTITY_IMPORT
          : lang.QUANTITY_EXPORT,
      dataIndex: "inventoryQuantity",
      align: "right",
    },
    {
      title: lang.INVENTORY_UNIT,
      dataIndex: "inventoryUnit",
      align: "center",
    },
    {
      title:
        popupDetail.title === lang.IMPORT_INVENTORY
          ? lang.IMPORTER
          : lang.EXPORTER,
      dataIndex: "performer",
      align: "center",
    },
    {
      title:
        popupDetail.title === lang.IMPORT_INVENTORY
          ? lang.IMPORT_DATE
          : lang.EXPORT_DATE,
      dataIndex: "transactionTime",
      align: "center",
      render: (_) => {
        return moment(_ * 1000).format("YYYY/MM/DD HH:mm:ss");
      },
    },
  ];
  const renderColLink = (txt, row, key) => {
    return !txt || Number(txt) === 0 ? (
      txt
    ) : (
      <Button
        type="link"
        className="btnLink svg-default fs16 right d-inline-block"
        onClick={() => {
          popup.open(row.inventoryWarehouseItemCode, key);
        }}
      >
        {txt}
      </Button>
    );
  };

  const getStartDateString = () => {
    return startDate.value ? startDate.value.format(KEY.DATE_DEFAULT) : null;
  };
  const getEndDateString = () => {
    return endDate.value ? endDate.value.format(KEY.DATE_DEFAULT) : null;
  };

  const validate = () => {
    let pass = true;

    if (isEmpty(startDate.value)) {
      pass = false;
      setStartDate({ ...startDate, error: true });
      startDateEl.current.focus();
    } else {
      setStartDate({ ...startDate, error: false });
    }

    return pass;
  };

  const searchParams = {
    inventoryWarehouseCode: warehouses.value,
    inventoryItemName: isEmpty(item.value) ? null : item.value.trim(),
    categoryName: isEmpty(category.value)
      ? null
      : (category.options.find((x) => x.key === category.value) ? category.options.find((x) => x.key === category.value).value : null),
    transactionTimeStart: getStartDateString(),
    transactionTimeEnd: getEndDateString(),
  };

  const onSearch = (offset = 0) => {
    if (!validate()) {
      return;
    }

    setGrid((preV) => ({ ...preV, loading: true }));

    // Call
    return API.getStock({
      ...searchParams,
      limit: 20,
      offset: offset,
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            // Init, Click
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            // Load more
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setGrid((preV) => ({ ...preV, loading: false }));
      });
  };
  const downLoadStockA = async () => {
    try {
      setShowLoading(true);
      const res = await API.downloadStock(searchParams);
      saveFile(res);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH);
    } finally {
      setShowLoading(false);
    }
  };
  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid((preV) => ({ ...preV, loading: false, hasMore: false }));
    } else {
      // load more
      onSearch(data.length);
    }
  };
  const popup = {
    open: async (id, type) => {
      try {
        const data = await popup.getDetail(id, type);
        //
        setPopupDetail({
          ...popupDetail,
          title:
            type === KEY.IMPORT ? lang.IMPORT_INVENTORY : lang.EXPORT_INVENTORY,
          show: true,
          list: data.data,
        });
      } catch (err) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    },
    close: () => {
      setPopupDetail({
        ...popupDetail,
        show: false,
      });
    },
    getDetail: async (id, type) => {
      setLoading(true);
      let arr = [];

      await API.getDetailStock(id, {
        transactionType: KEY.IMPORT === type ? 1 : 2,
        transactionTimeStart: getStartDateString(),
        transactionTimeEnd: getEndDateString(),
      })
        .then((res) => {
          if (statusRes(res)) {
            arr = res.data;
          } else {
            throw new Error();
          }
        })
        .catch((err) => {
          throw err;
        });

      setLoading(false);
      return arr;
    },
  };
  const clearInput = () => {
    setWarehouses({ ...warehouses, value: null });
    setCategory({ ...category, value: null });
    setItem({ ...item, value: null });
    setStartDate({ ...startDate, value: null, error: false });
    setEndDate({ ...endDate, value: null, error: false });

    setGrid({ ...grid, data: [] });
    // Not re-search
    return false;
  };
  const getWarehouses = () => {
    return KBN.getWarehouses()
      .then((res) => {
        setWarehouses({
          ...warehouses,
          options: res.data.data.map((v) => {
            v.key = v.inventoryWarehouseCode;
            v.value = v.inventoryWarehouseName;
            return v;
          }),
        });
      })
      .catch((err) => {
        throw err;
      });
  };
  const getCategory = () => {
    return KBN.getCategories()
      .then((res) => {
        if (statusRes(res)) {
          setCategory({
            ...category,
            options: res.data.data.map((v) => {
              v["key"] = v.categoryCode;
              v["value"] = v.categoryName;
              return v;
            }),
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setWarehouses({ ...warehouses, value: null });
      searchParams.inventoryWarehouseCode = null;
      setCategory({ ...category, value: null });
      searchParams.categoryCode = null;
      setLoading(true);
      Promise.all([getWarehouses(), getCategory()])
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [allowRoute]);

  return (
    <>
      <List
        className="table-header-auto gridSearch"
        inputs={inputs}
        columns={columns}
        grid={grid}
        rowKey={"inventoryWarehouseItemCode"}
        actionSearch={() => {
          onSearch();
        }}
        loadMore={onLoadMore}
        clearInput={clearInput}
        btnAdd={<></>}
        loading={loading}
      />
      {/* Popup detail */}
      {popupDetail.show && (
        <Modal
          className="modalStock"
          centered
          title={popupDetail.title}
          visible={popupDetail.show}
          // onOk={handleOk}
          onCancel={popup.close}
          width={1200}
          footer={[
            <Button
              key="back"
              className="buttonPC button--outline wAuto "
              onClick={popup.close}
            >
              <CloseCircleOutlined />
              {lang.BACK}
            </Button>,
          ]}
        >
          <Table
            className="table-header-auto tableStock"
            columns={columnsDetail}
            dataSource={popupDetail.list}
            pagination={CONFIG.paging}
            style={{ color: "red" }}
          />
        </Modal>
      )}
      <div className="wrapActionDownload site-layout-background">
        <Row className="action" justify="end" align="middle">
          <Space>
            <Tooltip title={lang.ATTACH}>
              <Button
                disabled={disabledButtonDownload}
                className={classBtn}
                style={{ border: "none !important" }}
                onClick={(e) => {
                  downLoadStockA();
                }}
              >
                <CloudDownloadOutlined className="orange" />
                在庫履歴ファイル出力
              </Button>
            </Tooltip>
          </Space>
        </Row>
      </div>
      <Loading show={showLoading} />
    </>
  );
}
