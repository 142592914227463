import React, { useState, useEffect } from "react";
import { Row, Input, Col, Button, Modal } from "antd";
import { COMMON_CONSTANT, STYLE, lang } from "../../../constants/common.const";
import MessageInput from "../messageInput";
import classText from "../../../models/control/text";

import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";

function EditGroupModal({
  showPopup,
  showDelete = true,
  dataRow,
  editGroupName = new classText(),
  setEditGroupName,
  editCurrentGroup,
  deleteCurrentGroup,
  ...other
}) {
  let [show, setShow] = useState(showPopup);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Modal
      centered
      title={COMMON_CONSTANT.TITLE_UPDATE_GROUP}
      visible={show}
      {...other}
    >
      <Row>
        {/* Input Name */}
        <Col span={4} className="content-center ">
          {COMMON_CONSTANT.GROUP_NAME}
        </Col>
        <Col span={10}>
          <Input
            maxLength={255}
            className={editGroupName.error ? STYLE.BORDER_RED : ""}
            value={editGroupName.value}
            onChange={(e) => {
              setEditGroupName({
                ...editGroupName,
                value: e.target.value,
                error: false,
              });
            }}
          />
          <MessageInput data={editGroupName} />
        </Col>

        <Col span={10}>
          {/* Btn UPDATE */}
          <Button
            className="ml10 buttonPC button--info"
            onClick={() => {
              editCurrentGroup(dataRow);
            }}
          >
            <CheckOutlined />
            {COMMON_CONSTANT.UPDATE}
          </Button>
          {/* Btn DEL */}
          {showDelete && (
            <>
              <Button
                className="ml5"
                type="primary"
                onClick={() => {
                  deleteCurrentGroup(dataRow.Name);
                }}
                danger
              >
                <DeleteOutlined />
                {lang.DELETE}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
}

export default EditGroupModal;
