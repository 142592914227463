import {
  ClearOutlined,
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ApiCalibration } from "../../api/backend/calibration";
import { Apifacility } from "./../../api/backend/facilityManagement";
import Loading from "./../../component/Desktop/loading/loading";
import TitlePage from "./../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  lang,
} from "./../../constants/common.const";
import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  pushObject2QueryReplace,
  showMessage,
} from "./../../constants/utils";
import classGrid from "./../../models/control/grid";
import classText from "./../../models/control/text";
import { checkRoleScreen } from "./../../utils/roles";
function CalibrationList() {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const history = useHistory();
  const location = useLocation();

  const deviceNameInit = new URLSearchParams(location.search).get("deviceName");
  const objSearch = getQuerySearch();
  const [form] = Form.useForm();
  const [deviceName, setDeviceName] = useState(
    new classText(deviceNameInit || "")
  );
  const [grid, setGrid] = useState(new classGrid());
  const [showLoading, setShowLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(objSearch.page || 1);
  const clearObj = {
    value: "",
  };
  const { allowRoute, isLeader } = useSelector((state) => state.auth);

  const initColumns = [
    {
      title: lang.INSTRUMENT_NAME,
      dataIndex: "machineForSiteOfficeName",
      key: "machineForSiteOfficeName",
      width: "40%",
      align: "center",
      render: (text, row) => {
        return (
          <Row justify="space-between" align="middle">
            <Col span={24}>
              <Link
                to={"/CalibrationManagement/View/" + row.id}
                className="white-space"
              >
                {text}
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      width: "40%",
      key: "action",
      render: (text, row) => {
        //
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={!isLeader} className="mr5 btnEdit">
                <Link
                  to={"CalibrationManagement/Edit/" + row.id}
                  className="w100"
                >
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={!isLeader}
                onClick={() => handleDelete(row.id)}
                className="mr5 btnDelete"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const clearInputSearch = () => {
    setDeviceName({ ...deviceName, ...clearObj });
    form.resetFields();
    form.submit();
    history.push(location.pathname);
  };
  const onSearch = async (init = true) => {
    try {
      setShowLoading(true);
      const resCalibration = await ApiCalibration.searchCalibration(
        deviceName.value
      );
      setGrid({
        ...grid,
        data: resCalibration.data,
        count: resCalibration.data.length,
      });
      if (!init) {
        setCurrentPage(1);
        pushObject2Query({
          deviceName: deviceName.value,
          page: 1,
        });
      } else {
        pushObject2Query({
          deviceName: deviceName.value,
          page: currentPage,
        });
      }
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };

  // Delete
  const handleDelete = (id) => {
    const deleteAction = async () => {
      setShowLoading(true);
      try {
        Modal.destroyAll();
        await ApiCalibration.deleteCalibration(id);
        await onSearch();
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (err) {
        showMessage(KEY.ERROR, MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    };

    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: deleteAction,
      centered: true,
    });
  };

  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      onSearch();
    }
  }, [allowRoute]);

  return (
    <div className="des-content">
      <TitlePage name={lang.CALIBRATION_MANAGEMENT} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Form onFinish={() => onSearch(false)} form={form} layout="vertical">
          <Row gutter={[8, 0]}>
            <Col span={isPC ? 12 : 24}>
              <Form.Item name="devicename">
                <Input
                  defaultValue={deviceNameInit || ""}
                  placeholder={lang.INSTRUMENT_NAME}
                  value={deviceName.value}
                  onChange={(e) =>
                    setDeviceName({ ...deviceName, value: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={isPC ? 12 : 24}>
              <Row gutter={[8, 0]}>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    htmlType="submit"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto"
                    onClick={() => clearInputSearch()}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {isLeader && (
              <Button
                type="link"
                className="btnAddRecord mb15"
                onClick={() => {
                  history.push("/CalibrationManagement/Add");
                }}
              >
                <AddIcon />
                機器の新規作成
              </Button>
            )}
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/*grid*/}
        <Table
          rowKey={(record) => record.facilityCode}
          className="gridSearch"
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,

            current: Number(currentPage) || 1,
            onChange: (curr) => {
              setCurrentPage(curr);
              pushObject2QueryReplace({ page: curr });
            },
          }}
        />
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}

export default CalibrationList;
