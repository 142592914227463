export default {
  HEAD_OFFICE_ADMIN: "HEAD_OFFICE_ADMIN",
  OFFICE_ADMIN: "OFFICE_ADMIN",
  OFFICE_USER: "OFFICE_USER",
  CUSTOMER: "CUSTOMER",
  SPECIAL_CUSTOMER: "SPECIAL_CUSTOMER",
  1: "HEAD_OFFICE_ADMIN",
  2: "OFFICE_ADMIN",
  3: "OFFICE_USER",
  4: "CUSTOMER",
  5: "SPECIAL_CUSTOMER",
};

export const RoleHeadOffice = {
  1: "情報システムG",
  11: "運転管理部",
  INFO_SYSTEM_G: "情報システムG",
  OPERATION_MANAGE_UNIT: "運転管理部"
};
