/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import UpdateMaster from "./update";
import { Button, Select, Table } from "antd";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import API from "../../api/backend/inventory";
import KBN from "../../api/backend/kbn";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  isEmpty,
  MSG_CATCH,
  removeItemArr,
  renderOptionOneCheck,
  showMessage,
  showMessageChange,
  statusRes,
} from "../../constants/utils";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import InputPositiveNumber from "../../component/Desktop/inputPositiveNumber";
import { checkRoleScreen } from "../../utils/roles";
import { useSelector } from "react-redux";

export default function ItemUpdate() {
  const { id, id: modeUpdate } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({ ...new classText(), ref: useRef() });
  const [itemCode, setItemCode] = useState(new classText());
  const [itemType, setItemType] = useState({
    ...new classDdl(),
    disabled: false,
    ref: useRef(),
  });
  const [category, setCategory] = useState({
    ...new classDdl(),
    disabled: false,
    ref: useRef(),
  });
  const [warehouses, setWarehouses] = useState([]);
  const [manufacturer, setManufacturer] = useState({
    ...new classText(),
    ref: useRef(),
  });
  const [makerModelNumber, setMakerModelNumber] = useState(new classText());
  const [samples, setSamples] = useState(new classText());
  const [receivingUnit, setReceivingUnit] = useState({
    ...new classText(),
    ref: useRef(),
  });
  const [inventoryUnit, setInventoryUnit] = useState({
    ...new classText(),
    ref: useRef(),
  });
  const [conversionRate, setConversionRate] = useState({
    ...new classText(),
    ref: useRef(),
  });

  const [listWarehouses, setListWarehouses] = useState([]);
  const [listSelectInventory, setListSelectInventory] = useState([]);
  const [note, setNote] = useState(new classText());
  // Common
  const [creator, setCreator] = useState(new classText());
  const [createDate, setCreateDate] = useState(new classText());
  const [updater, setUpdater] = useState(new classText());
  const [updateDate, setUpdateDate] = useState(new classText());
  const [flagChange, setFlagChange] = useState(false);

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  // Key
  const k = {
    warehouseItems: "warehouseItems",
    haveTransaction: "haveTransaction",
    inventoryWarehouseCode: "inventoryWarehouseCode",
    inventoryWarehouseItemCode: "inventoryWarehouseItemCode",
    inventoryWarehouseName: "inventoryWarehouseName",
    minimumRequiredQuantity: "minimumRequiredQuantity",
    stockQuantity: "stockQuantity",
  };

  // Table sub
  const columnsInventory = [
    {
      title: lang.NO_INDEX,
      align: "center",
      render: (txt, row, index) => {
        return <div style={{ minWidth: "45px" }}>{index + 1}</div>;
      },
    },
    {
      dataIndex: k.inventoryWarehouseCode,
      title: (
        <>
          {lang.WAREHOUSES_NAME}
          <label className="blod ml5 mark-required">*</label>
        </>
      ),
      render: (txt, row, indexRow) => {
        const err = isEmpty(txt) ? STYLE.BORDER_RED : "";
        return (
          <Select
            disabled={row[k.haveTransaction]}
            className={err + " w100"}
            style={{ minWidth: "180px" }}
            onChange={(v) => {
              onChangeWarehouses(v, indexRow);
            }}
            value={txt}
          >
            {renderOptionOneCheck(warehouses, true, {
              value: txt,
              list: listSelectInventory,
            })}
          </Select>
        );
      },
    },
    {
      dataIndex: k.stockQuantity,
      title: lang.STOCK_QUANTITY,
      align: "center",
      render: (txt, row, indexRow) => {
        return (
          <InputPositiveNumber
            style={{ minWidth: "90px" }}
            disabled={row[k.haveTransaction]}
            value={txt}
            onChange={(e) => {
              onChangeNumber(e, indexRow, k.stockQuantity);
            }}
          />
        );
      },
    },
    {
      dataIndex: k.minimumRequiredQuantity,
      title: lang.MINIMUM_STOCK_QUANTITY,
      align: "center",
      render: (txt, row, indexRow) => {
        return (
          <InputPositiveNumber
            style={{ minWidth: "90px" }}
            value={txt}
            onChange={(e) => {
              onChangeNumber(e, indexRow, k.minimumRequiredQuantity);
            }}
          />
        );
      },
    },
  ];
  // Mode add
  const columnRemoveWarehouses = {
    dataIndex: k.inventoryWarehouseItemCode,
    title: lang.DELETE,
    align: "center",
    render: (txt, row, indexRow) => {
      return (
        <Button
          disabled={row[k.haveTransaction]}
          className=" btnDelete"
          onClick={() => {
            onDeleteWarehouses(indexRow, row);
          }}
        >
          <DeleteOutlined />
        </Button>
      );
    },
  };
  // Mode update
  const columnInventoryItemCode = {
    dataIndex: k.inventoryWarehouseItemCode,
    title: lang.ITEM_CODE,
    align: "center",
    render: (txt) => {
      return <div style={{ maxWidth: "100px" }}>{txt}</div>;
    },
  };
  const onAddWarehouses = () => {
    const listData = [...listWarehouses];
    const objNew = {};
    objNew[k.inventoryWarehouseCode] = null;
    objNew[k.inventoryWarehouseItemCode] = null;
    objNew[k.stockQuantity] = null;
    objNew[k.minimumRequiredQuantity] = null;
    listData.push(objNew);
    setListWarehouses(listData);
    setFlagChange(true);
  };
  const onChangeWarehouses = (v, indexRow) => {
    const listData = [...listWarehouses];
    listWarehouses[indexRow][k.inventoryWarehouseCode] = v;
    setListWarehouses(listData);
    setFlagChange(true);
  };
  const onChangeNumber = (e, indexRow, key) => {
    const listData = [...listWarehouses];
    listData[indexRow][key] = e.target.value;
    setListWarehouses(listData);
    setFlagChange(true);
  };
  const onDeleteWarehouses = (indexRow, row) => {
    const funcDel = () => {
      let data = [...listWarehouses];
      data = removeItemArr(data, data[indexRow]);
      setListWarehouses(data);
      setFlagChange(true);
    };
    // Case
    if (
      isEmpty(row[k.inventoryWarehouseCode]) &&
      isEmpty(row[k.stockQuantity]) &&
      isEmpty(row[k.minimumRequiredQuantity])
    ) {
      funcDel();
    } else {
      showMessageChange(funcDel);
    }
  };
  const renderWarehousesInformation = () => {
    return (
      <>
        {listWarehouses.length < warehouses.length && (
          <Button
            className="buttonPC button--info wAuto mb10"
            onClick={onAddWarehouses}
          >
            <PlusOutlined />
            {lang.ADD}
          </Button>
        )}
        <Table
          className="gridSearch"
          rowKey={(row, i) => row[k.inventoryWarehouseItemCode] || i}
          columns={
            modeUpdate
              ? [
                  ...columnsInventory,
                  columnInventoryItemCode,
                  columnRemoveWarehouses,
                ]
              : [...columnsInventory, columnRemoveWarehouses]
          }
          dataSource={listWarehouses}
          pagination={false}
        />
      </>
    );
  };
  useEffect(() => {
    const listSelect = [];
    listWarehouses.forEach((row) => {
      listSelect.push(row[k.inventoryWarehouseCode]);
    });
    setListSelectInventory(listSelect);
  }, [listWarehouses]);

  // Item
  const items = [
    {
      name: lang.ITEM_NAME,
      get: item,
      set: setItem,
      type: KEY.TEXT,
      required: true,
    },
    ...(modeUpdate
      ? [
          {
            name: lang.ITEM_CODE,
            get: itemCode,
          },
        ]
      : []),
    {
      name: lang.ITEM_TYPE,
      get: itemType,
      set: setItemType,
      type: KEY.DDL,
      required: true,
    },
    {
      name: lang.CATEGORY,
      get: category,
      set: setCategory,
      type: KEY.DDL,
    },
    {
      name: lang.MANUFACTURER,
      get: manufacturer,
      set: setManufacturer,
      type: KEY.TEXT,
    },
    {
      name: lang.MAKER_MODEL_NUMBER,
      get: makerModelNumber,
      set: setMakerModelNumber,
      type: KEY.TEXT,
    },
    {
      name: lang.NUMBER,
      get: samples,
      set: setSamples,
      type: KEY.TEXT,
    },

    {
      name: lang.INVENTORY_UNIT,
      get: inventoryUnit,
      set: setInventoryUnit,
      type: KEY.TEXT,
      required: true,
    },
    // 在庫情報
    {
      name: lang.INVENTORY_INFORMATION,
      required: true,
      render: renderWarehousesInformation,
    },
    {
      name: lang.NOTE,
      get: note,
      set: setNote,
      type: KEY.TEXTAREA,
    },
    ...(modeUpdate
      ? [
          {
            name: lang.CREATOR,
            get: creator,
          },
          {
            name: lang.CREATE_DATE,
            get: createDate,
          },
          {
            name: lang.UPDATER,
            get: updater,
          },
          {
            name: lang.UPDATE_DATE,
            get: updateDate,
          },
        ]
      : []),
  ];

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setLoading(true);

      Promise.all([
        modeUpdate && getDetail(),
        getCategories(),
        getInventoryType(),
        getWarehouses(),
      ])
        .then((res) => {
          // Empty
        })
        .catch((err) => {
          history.push("/ItemMaster");
        })
        .then(() => {
          setLoading(false);
        });
    }

    return () => {
      // cleanup;
    };
  }, [allowRoute]);

  // Get data
  const getDetail = () => {
    return API.detailItem(id).then((res) => {
      if (statusRes(res)) {
        const data = res.data;

        setListWarehouses(data[k.warehouseItems]);
        setItem({ ...item, value: data.inventoryItemName });
        setItemCode({ ...item, value: data.inventoryItemCode });
        setItemType((preV) => ({
          ...preV,
          value: data.inventoryTypeValue,
        }));
        setReceivingUnit((preV) => ({
          ...preV,
          value: data.receivingUnit,
        }));
        setInventoryUnit({ ...inventoryUnit, value: data.inventoryUnit });
        setCategory((preV) => ({
          ...preV,
          value: data.categoryCode,
        }));
        setConversionRate({ ...conversionRate, value: data.conversionRate });

        setManufacturer({ ...manufacturer, value: data.manufacturer });
        setMakerModelNumber({
          ...makerModelNumber,
          value: data.makerModelNumber,
        });
        setSamples({ ...samples, value: data.samples });
        setNote({ ...note, value: data.remarks });

        setCreator((preV) => ({ ...preV, value: data.creater }));
        setCreateDate((preV) => ({ ...preV, value: data.createDate }));
        setUpdater((preV) => ({ ...preV, value: data.updater }));
        setUpdateDate((preV) => ({ ...preV, value: data.updateDate }));
      } else {
        throw new Error();
      }
    });
  };
  const getInventoryType = () => {
    return KBN.getInventoryType().then((res) => {
      if (statusRes(res)) {
        setItemType((preV) => ({
          ...preV,
          options: res.data.data,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getCategories = () => {
    return KBN.getCategories().then((res) => {
      if (statusRes(res)) {
        setCategory((preV) => ({
          ...preV,
          options: res.data.data.map((v) => {
            v["key"] = v.categoryCode;
            v["value"] = v.categoryName;
            return v;
          }),
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getWarehouses = () => {
    return KBN.getWarehouses().then((res) => {
      if (statusRes(res)) {
        setWarehouses(
          res.data.data.map((v) => {
            v["key"] = v.inventoryWarehouseCode;
            v["value"] = v.inventoryWarehouseName;
            return v;
          })
        );
      } else {
        throw new Error();
      }
    });
  };

  const validate = () => {
    let err = false;

    if (listWarehouses.length > 0) {
      listWarehouses.forEach((row) => {
        if (isEmpty(row[k.inventoryWarehouseCode])) {
          err = true;
        }
      });
    } else {
      err = true;
    }

    return !err;
  };

  // Server
  const onSave = () => {
    if (!validate()) {
      showMessage(KEY.ERROR, COMMON_CONSTANT.BOM_E030);
      return;
    }

    setLoading(true);

    const obj = {
      inventoryItemName: item.value,
      inventoryItemCode: itemCode.value,
      inventoryType: itemType.value,
      receivingUnit: receivingUnit.value,
      inventoryUnit: inventoryUnit.value,
      categoryCode: category.value,
      conversionRate: conversionRate.value,
      manufacturer: manufacturer.value,
      makerModelNumber: makerModelNumber.value,
      samples: samples.value,
      remarks: note.value,
    };
    obj[k.warehouseItems] = listWarehouses;

    (modeUpdate ? API.updateItem : API.createItem)(obj)
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
            history.goBack();
          });
        } else {
          showMessage(KEY.ERROR, (res && res.data.msg) || MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(
          KEY.ERROR,
          (err.response && err.response.data.msg) || MSG_CATCH()
        );
      })
      .then(() => {
        setLoading(false);
      });
  };
  const onBack = () => {
    if (flagChange) {
      showMessageChange(history.goBack, COMMON_CONSTANT.BOM_C018);
    } else {
      history.goBack();
    }
  };

  return (
    <UpdateMaster
      flagChange={[flagChange, setFlagChange]}
      name={lang.ITEM}
      items={items}
      onSave={onSave}
      onBack={onBack}
      loading={loading}
    />
  );
}
