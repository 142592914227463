import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Checkbox, Switch, Button, Row, Col, Select, Table, Input } from "antd";
import {
  formatCheckItemCode,
  getRange,
  renderOption,
  renderOptionForSearch,
} from "../../constants/utils";
import { lang, COMMON_CONSTANT } from "../../constants/common.const";
import { ReactComponent as CopyIcon } from "@src/styles/icon/clone.svg";
import Item from "antd/lib/list/Item";

const { Option } = Select;

function GridCheckList({
  onChangeOrderRow,
  onSwitchTimeCheck,
  onCheckAllTime,
  onCheckTime,
  onCheckboxItem,
  columns,
  dataSource = [],
  MULTIPLE_TIME,
  listTimeCheck = [],
  onAddListTimeCheck,
  onRemoveListTimeCheck,
  onUpdateTimeCheck,
  accumulatedTypeList,
  defaultTypeList,
  defaultJudgement,
  onChangeAccumulatedType,
  onChangeDefaultType,
  onChangeItemJudgement,
  onChangeInventoryItem,
  onChangeMultiplier,
  onChangeDefaultValue,
  onChangeAccumulatedGroupItemCodes,
  onChangeAutoCalculate,
  onChangeItemCodeRefer,
  onChangeInventoryWareHouseCode,
  dataMachine,
  onCheckAllItem,
  ddlTypeChecklist,
  ...other
}) {
  // Count check
  let count = 0;
  dataSource.forEach((v) => {
    v.checked && count++;
  });
  // Select show
  const nodeSelect = (row, indexRow) => {
    return (
      <div className="center">
        <Checkbox
          checked={row.checked}
          onChange={(e) => {
            onCheckboxItem(e, row, dataMachine, indexRow);
          }}
        ></Checkbox>
      </div>
    );
  };
  // Order
  const nodeOrder = (row) => {
    // const v = row.Order;
    const dataSourceCheckedList = dataSource.filter((x) => x.checked);
    const v = dataSourceCheckedList.findIndex((x) => x.ID == row.ID) + 1;
    const listO = dataSourceCheckedList.map((e, i) => {
      return (
        <Option value={i + 1} key={i + 1}>
          {i + 1}
        </Option>
      );
    });
    return (
      <div className="center">
        <Select
          size={"small"}
          className="selectOrder"
          value={v}
          onChange={(value) => {
            onChangeOrderRow(value, row, dataSource);
          }}
          disabled={!row.checked}
        >
          {listO}
        </Select>
      </div>
    );
  };
  // Copy
  const nodeBtnCopy = (row, order) => (
    <CopyToClipboard
      text={formatCheckItemCode(row.CheckItemCode || row.checkItemCode, order)}
    >
      <Button type="link" className="btnLink btnLink-active pr2 pl2 mb2">
        <CopyIcon className="w18px" />
      </Button>
    </CopyToClipboard>
  );
  // Mode
  const nodeMode = (row) => {
    let temp;
    const modeText = row.mode || "";
    if (modeText.indexOf("数値") !== -1 || modeText.indexOf("数字") !== -1) {
      temp = "数字";
    }
    if (modeText.indexOf("選択") !== -1) {
      temp = "選択";
    }
    if (modeText.indexOf("時間") !== -1 || modeText.indexOf("文字") !== -1) {
      temp = "文字";
    }
    if (modeText.indexOf("備考") !== -1) {
      temp = "備考";
    }
    return temp;
  };

  const renderNodeCheckTimeOld = listTimeCheck.map((v, i) => {
    return {
      title: <span className="fs12">{v}</span>,
      align: "center",
      width: i === 0 ? "60px" : "90px",
      render: (text, row) => {
        const obj = {
          props: {},
        };
        if (row.multipleTime) {
          // Multiple time
          const rowN = { ...row };
          rowN.checkOrder = i + 1;
          obj.children = (
            <div className="d-flex content-center">
              <Checkbox
                checked={rowN.timeArr[i]}
                onChange={(e) => {
                  onCheckTime(e.target.checked, i, rowN);
                }}
              ></Checkbox>
              {nodeBtnCopy(rowN, i + 1)}
            </div>
          );
        } else {
          // Only time
          if (i === 0) {
            obj.props.colSpan = listTimeCheck.length;
            obj.children = (
              <span className="content-center">{nodeBtnCopy(row)}</span>
            );
          } else {
            // Other
            obj.props.colSpan = 0;
          }
        }
        return obj;
      },
    };
  });

  let columnMultiple =
    Number(ddlTypeChecklist.value) === 2
      ? [
          {
            title: "複数回",
            width: 60,
            align: "center",
            render: (text, row, indexRow) => {
              return (
                <Switch
                  // checkedChildren="複数回"
                  // unCheckedChildren="1回"
                  size="small"
                  checked={row.multipleTime}
                  onChange={(checked) => {
                    onSwitchTimeCheck(checked, row);
                  }}
                />
              );
            },
          },
          {
            title: "入力値の分類",
            width: 130,
            align: "center",
            render: (text, row, indexRow) => {
              return (
                <Select
                  size={"small"}
                  style={{ width: "130px", fontSize: "12px" }}
                  value={row.accumulatedType}
                  disabled={
                    Number(row.multipleTime) ||
                    row.mode !== COMMON_CONSTANT.INPUT_FORMAT_NUM
                  }
                  onChange={(v) => {
                    onChangeAccumulatedType(v, row);
                  }}
                >
                  {renderOptionForSearch(accumulatedTypeList.options)}
                </Select>
              );
            },
          },
          // multiplier
          {
            title: "乗数",
            width: 50,
            align: "center",
            render: (text, row, indexRow) => {
              return (
                <Input
                  size={"small"}
                  style={{ width: "50px", fontSize: "14px" }}
                  value={row.multiplier}
                  disabled={
                    Number(row.multipleTime) ||
                    Number(row.accumulatedType) !== 4 ||
                    row.mode !== COMMON_CONSTANT.INPUT_FORMAT_NUM
                  }
                  onChange={(e) => {
                    onChangeMultiplier(e.target.value, row);
                  }}
                ></Input>
              );
            },
          },
          {
            title: "複数回の点",
            children: renderNodeCheckTimeOld,
          },
        ]
      : [
          // accumulated type
          {
            title: "入力値の分類",
            width: 130,
            align: "center",
            render: (text, row, indexRow) => {
              return (
                <Select
                  size={"small"}
                  style={{ width: "130px", fontSize: "12px" }}
                  value={row.accumulatedType}
                  disabled={
                    Number(row.multipleTime) ||
                    row.mode !== COMMON_CONSTANT.INPUT_FORMAT_NUM
                  }
                  onChange={(v) => {
                    onChangeAccumulatedType(v, row);
                  }}
                >
                  {renderOptionForSearch(accumulatedTypeList.options)}
                </Select>
              );
            },
          },
          // multiplier
          {
            title: "乗数",
            width: 50,
            align: "center",
            render: (text, row, indexRow) => {
              return (
                <Input
                  size={"small"}
                  style={{ width: "50px", fontSize: "14px" }}
                  value={row.multiplier}
                  disabled={
                    Number(row.multipleTime) ||
                    Number(row.accumulatedType) !== 4 ||
                    row.mode !== COMMON_CONSTANT.INPUT_FORMAT_NUM
                  }
                  onChange={(e) => {
                    onChangeMultiplier(e.target.value, row);
                  }}
                ></Input>
              );
            },
          },
          {
            title: "コピー",
            key: "copy",
            width: 80,
            align: "center",
            render: (text, row, indexRow) => {
              return <div className="d-inline-block">{nodeBtnCopy(row)}</div>;
            },
          },
        ];
  columnMultiple = [
    {
      title: "項目",
      width: 100,
      align: "center",
      render: (text, row, indexRow) => {
        return (
          <Select
            size={"small"}
            style={{ width: "130px", fontSize: "12px" }}
            value={row.inventoryItemCode.value}
            onChange={(v) => {
              onChangeInventoryItem(v, row);
            }}
          >
            {renderOption(row.inventoryItemCode.options)}
          </Select>
        );
      },
    },
    {
      title: "倉庫",
      width: 100,
      align: "center",
      render: (text, row, indexRow) => {
        return (
          <Select
            size={"small"}
            style={{ width: "130px", fontSize: "12px" }}
            value={row.inventoryWarehouseItemCode.value}
            disabled={!row.inventoryItemCode.value}
            onChange={(v) => {
              onChangeInventoryWareHouseCode(v, row);
            }}
          >
            {renderOption(
              row.inventoryWarehouseItemCode
                ? row.inventoryWarehouseItemCode.options
                : []
            )}
          </Select>
        );
      },
    },
  ].concat(columnMultiple);
  let columnTable = [
    {
      title: (
        <label className="pointer">
          <Checkbox
            onChange={onCheckAllItem}
            checked={dataSource.length === count}
          ></Checkbox>
        </label>
      ),
      width: 30,
      render: (text, row, indexRow) => {
        return nodeSelect(row, indexRow);
      },
    },
    {
      title: "順",
      width: 30,
      render: (text, row, indexRow) => {
        return nodeOrder(row);
      },
    },
    {
      title: "点検項目",
      width: 120,
      render: (text, row, indexRow) => {
        return row.CheckItemName;
      },
    },
    {
      title: "判断",
      width: 150,
      render: (text, row, indexRow) => (
        <div className="text-center">
          <Select
            size={"small"}
            style={{ width: "150px", fontSize: "14px" }}
            onChange={(v) => {
              onChangeItemJudgement(v, row);
            }}
            value={row.itemJudgement}
          >
            {renderOptionForSearch(defaultJudgement.options)}
          </Select>
        </div>
      ),
    },
    {
      title: "初期タイプ",
      width: 120,
      render: (text, row, indexRow) => (
        <Select
          size={"small"}
          style={{ width: "150px", fontSize: "14px" }}
          value={row.defaultType}
          onChange={(v) => {
            onChangeDefaultType(v, row);
          }}
        >
          {renderOptionForSearch(defaultTypeList.options)}
        </Select>
      ),
    },
    {
      title: "初期値",
      width: 120,
      render: (text, row, indexRow) => (
        <Input
          size={"small"}
          style={{ width: "80px", fontSize: "14px" }}
          value={row.defaultValue}
          disabled={
            Number(row.defaultType) === 1 || Number(row.defaultType) === 2
          }
          onChange={(e) => {
            onChangeDefaultValue(e.target.value, row);
          }}
        ></Input>
      ),
    },
    {
      title: "累計計算項目",
      width: 120,
      render: (text, row, indexRow) => (
        <Input
          size={"small"}
          style={{ width: "80px", fontSize: "14px" }}
          value={row.accumulatedGroupItemCodes}
          disabled={
            Number(row.multipleTime) ||
            row.mode !== COMMON_CONSTANT.INPUT_FORMAT_NUM
          }
          onChange={(e) => {
            onChangeAccumulatedGroupItemCodes(e.target.value, row);
          }}
        ></Input>
      ),
    },
    ...columnMultiple,
  ];
  return (
    <div>
      <Table
        {...other}
        dataSource={dataSource}
        columns={columnTable}
        expandable={{
          expandedRowRender: (row) => (
            <Row justify="end">
              <Col className="fs14 info-more">
                <div>
                  <span className="mr25">
                    運転めやす{lang.COLON}
                    {getRange(
                      row.NormalLowerControlValue,
                      row.NormalUpperControlValue
                    )}
                  </span>
                  <span className="mr25">
                    入力形式{lang.COLON}
                    {nodeMode(row)}
                  </span>
                  <span className="mr25">
                    単位{lang.COLON}
                    {row.unit}
                  </span>
                </div>
              </Col>
            </Row>
          ),
          expandIconColumnIndex: -1,
          defaultExpandAllRows: true,
        }}
        pagination={false}
      />
    </div>
  );
} // Usage

export default GridCheckList;
