import { EditOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Apifacility } from "../../../api/backend/facilityManagement";
import BreakLine from "../../../component/Desktop/breakLine";
import GroupItem from "../../../component/Desktop/groupItem";
import Loading from "../../../component/Desktop/loading/loading";
import RowInput from "../../../component/Desktop/rowInput";
import TitlePage from "../../../component/Desktop/titlePage";
import { KEY, lang } from "../../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { checkRoleScreen } from "../../../utils/roles";
import "./facility.scss";
import LocationViewTree from "./locationViewTree";
import rolesConst from "../../../routers/roles/roles.const";
function ViewFacility() {
  const [listBuilding, setListBuiding] = useState([]);
  const [listFloor, setListFloor] = useState([]);
  const [listRoom, setListRoom] = useState([]);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { id } = useParams();
  const { allowRoute, isLeader } = useSelector((state) => state.auth);
  const location = useLocation();

  const [data, setData] = useState({
    facilityName: "",
    status: "Nomal",
    completionDate: "",
    note: "",
    address: "",
    startDate: "",
    endDate: "",
    facilityManager: "",
    prefectureName: "",
    processingMethod: "",
    processingAbility: "",
    zipCode1: "",
    zipCode2: "",
    plantName: "",
  });
  const listData = [
    {
      title: lang.NAME_FACILITY,
      value: data.facilityName,
    },
    {
      title: lang.COMPLETION_DATE,
      value: data.completionDate,
    },
    {
      title: lang.REMARK,
      value: data.note,
    },
    {
      title: lang.PLANT_NAME,
      value: data.plantName,
    },
    {
      title: lang.APPLICABLE_START_DATE,
      value: data.startDate,
    },
    {
      title: lang.APPLICABLE_END_DATE,
      value: data.endDate,
    },
    {
      title: lang.FACILITY_MANAGER,
      value: data.facilityManager,
    },
    {
      title: lang.POST_CODE,
      value: data.postalCode,
    },
    {
      title: lang.ADDRESS,
      value: data.address,
    },
    {
      title: lang.NAME_OF_PREFECTURE,
      value: data.prefectureName,
    },
    {
      title: lang.PROCESSING_METHOD,
      value: data.processingMethod,
    },
    {
      title: lang.PROCESSING_POWER,
      value: data.processingAbility,
    },
  ];
  const fetchDetail = async () => {
    try {
      setShowLoading(true);
      if (id) {
        const detailfacilityRes = await Apifacility.getDetail({
          facilityCode: id,
        });
        if (!detailfacilityRes.data) {
          throw { status: 404 };
        }
        const {
          facilityName,
          status,
          address,
          completionDate,
          note,
          startDate,
          endDate,
          processingMethod,
          prefectureName,
          facilityManager,
          processingAbility,
          buildings,
          zipCode1,
          zipCode2,
          plantName,
        } = detailfacilityRes.data;
        setData({
          facilityName,
          status,
          address,
          plantName,
          completionDate: completionDate
            ? moment(completionDate).format(KEY.DATE_DEFAULT)
            : null,
          note,
          startDate: startDate
            ? moment(startDate).format(KEY.DATE_DEFAULT)
            : null,
          endDate: endDate ? moment(endDate).format(KEY.DATE_DEFAULT) : null,
          processingMethod,
          prefectureName,
          postalCode: zipCode1 && zipCode2 ? zipCode1 + "-" + zipCode2 : null,
          facilityManager,
          processingAbility,
        });

        let listBuildingData = [];
        let listFloorData = [];
        let listRoomData = [];
        listBuildingData = buildings.map(({ buildingCode, buildingName }) => ({
          buildingCode,
          buildingName,
        }));
        buildings.forEach((buildingItem) => {
          const floors = buildingItem.floors.map(
            ({ floorCode, floorName, buildingCode }) => ({
              floorCode,
              floorName,
              buildingCode,
              buildingName: buildingItem.buildingName,
            })
          );
          listFloorData = listFloorData.concat(floors);
          buildingItem.floors.forEach((floorItem) => {
            const rooms = floorItem.rooms.map(
              ({ roomCode, roomName, floorCode }) => ({
                roomCode,
                roomName,
                floorCode,
                floorName: floorItem.floorName,
                buildingName: buildingItem.buildingName,
              })
            );
            listRoomData = listRoomData.concat(rooms);
          });
        });
        setListBuiding(listBuildingData);
        setListFloor(listFloorData);
        setListRoom(listRoomData);
      }
    } catch (error) {
      if (error.status === 404) {
        history.goBack();
      } else showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      fetchDetail();
    }
  }, [id, allowRoute]);
  const renderListdata = listData.map((item, index) => (
    <div key={index}>
      <BreakLine />
      <RowInput name={item.title} box>
        {item.value}
      </RowInput>
      <BreakLine />
    </div>
  ));
  return (
    <div className="des-content">
      <TitlePage name={lang.FACILITY_MANAGEMENT_INFOMATION} />
      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">
            {lang.FACILITY_MANAGEMENT_INFOMATION}
          </h1>
        </div>
        <div className="mt15"></div>
        {renderListdata}
        <BreakLine />
        <RowInput name={lang.SITE_MANAGEMENT} box>
          <Row>
            <Col span={24}>
              <Row gutter={[8, 0]}>
                <Col span={5}>
                  <div className="titleLocation">{lang.BUILDING}</div>
                </Col>
                <Col span={5}>
                  <div className="titleLocation">{lang.FLOOR}</div>
                </Col>
                <Col span={5}>
                  <div className="titleLocation">{lang.ROOM}</div>
                </Col>
              </Row>
            </Col>
            <Col span="24">
              <LocationViewTree
                listBuilding={listBuilding}
                listFloor={listFloor}
                listRoom={listRoom}
                facilityCode={id}
                facilityName={data.facilityName}
              />
            </Col>
          </Row>
        </RowInput>
        <BreakLine />
        <Row className="mt20" justify="end" gutter={[8, 8]}>
          <Col>
            {isLeader && (
              <Button
                className="buttonPC button--info wAuto"
                onClick={() => history.push(`/EditFacility/${id}`)}
              >
                <EditOutlined />
                {lang.EDIT}
              </Button>
            )}
          </Col>
          <Col>
            <Button
              className="buttonPC button--outline --todo-- wAuto"
              onClick={() => history.goBack()}
            >
              <RollbackOutlined />
              {lang.BACK}
            </Button>
          </Col>
        </Row>
      </GroupItem>
      <Loading show={showLoading} />
    </div>
  );
}

export default ViewFacility;
