import React from "react";
import Role from "./roles.const";
// Role
import Role_list from "@src/page/Role_OF/list";
// Office
import {
  default as Office_add,
  default as Office_edit,
} from "@src/page/Office/add";
import OfficeDetail from "@src/page/Office/detail";
import Office_list from "@src/page/Office/list";
import Office_view from "@src/page/Office/view";

// HomeBanner
import HomeBanner_add from "@src/page/HomeBanner/add";
import HomeBanner_list from "@src/page/HomeBanner/list";
import HomeBanner_view from "@src/page/HomeBanner/view";

// Management CL2
import ManagementCL_add from "@src/page/Management_CLv2/add";
import ManagementCL_list from "@src/page/Management_CLv2/list";
import ManagementCL_view from "@src/page/Management_CLv2/view";
// ResetLastValue
import ResetLastValue_edit from "@src/page/ResetLastValue/edit";
import ResetLastValue_list from "@src/page/ResetLastValue/list";
// Result Checklist
import HistoryCL_List from "@src/page/History_CL/list";
import HistoryCL_View from "@src/page/History_CL/view";
// WorkReport
import WorkReport_add from "@src/page/WorkReport/add";
import WorkReport_list from "@src/page/WorkReport/list";
import WorkReport_view from "@src/page/WorkReport/view";
// Customer Report
import CustomerReport from "@src/page/CustomerReport/list";

// INVENTORY
// Import
import Import_Inventory_list from "@src/page/Import_Inventory/list";
import Import_Inventory_update from "@src/page/Import_Inventory/update";
// Export
import Export_Inventory_list from "@src/page/Export_Inventory/list";
import Export_Inventory_update from "@src/page/Export_Inventory/update";
// Storage
import Warehouses_list from "@src/page/Master/warehouses";
import Warehouses_update from "@src/page/Master/warehouses_update";
// Category
import Category_list from "@src/page/Master/category";
import Category_update from "@src/page/Master/category_update";
// Item
import Item_list from "@src/page/Master/item";
import Item_update from "@src/page/Master/item_update";
// Inventory Data
import InventoryData from "@src/page/Master/inventory_data";
// Stock

import Stock from "@src/page/Master/stock";
//machine_management
import {
  CarryOutFilled,
  ClusterOutlined,
  CodeSandboxOutlined,
  ContainerOutlined,
  ExportOutlined,
  FileSyncOutlined,
  FileTextFilled,
  FormOutlined,
  GlobalOutlined,
  HddOutlined,
  HistoryOutlined,
  HomeFilled,
  ImportOutlined,
  InfoCircleFilled,
  LayoutFilled,
  LayoutOutlined,
  ReconciliationFilled,
  ScheduleOutlined,
  SettingFilled,
  SnippetsOutlined,
  UnorderedListOutlined,
  LineChartOutlined
} from "@ant-design/icons";
import { lang } from "@src/constants/common.const";
import { ROUTER_PAGE } from "@src/constants/router.const";
import HandleFuncTemplate from "@src/page/CustomerReport/handleFuncTemplate";
import TemplateManagement from "@src/page/CustomerReport/templateManagement";
import SettingGroup from "@src/page/InspectionChecklist/GroupChecklist/settingGroupChecklist";
import SheetImportReport from "../../page/CustomerReport/sheetImportReport";
import FuncMachine from "../../page/Function_machine_management/list";
import Addfacility from "../../page/location_management/facility_management/add";
import FacilityList from "../../page/location_management/facility_management/list";
import ViewFacility from "../../page/location_management/facility_management/view";
import AddProcess from "../../page/location_management/process_management/add";
import ListProcess from "../../page/location_management/process_management/list";
import ViewProcess from "../../page/location_management/process_management/view";
import AddMachine from "../../page/Machine_Management/add1";
import ImportMachine from "../../page/Machine_Management/importMachine";
import Machine_list from "../../page/Machine_Management/list";
import MaintainHistory from "../../page/Machine_Management/maintainHistory";
import ViewMachine from "../../page/Machine_Management/view";
import CalendarComponent from "@src/page/Calendar/index"
// Registration
import ChecklistOverview from "@src/page/InspectionChecklist/checklistOverview";
import DetailCheckList from "@src/page/InspectionChecklist/DetailChecklist/detailChecklist";
import ShowAllChecklistBK from "@src/page/InspectionChecklist/ShowAllChecklist/showAllCLBookMark";
import ShowAllChecklistSaved from "@src/page/InspectionChecklist/ShowAllChecklist/showAllCLSaved";
import ChecklistRegistration from "@src/page/Registration/ChecklistRegistration";
import InventoryRegistration from "@src/page/Registration/InventoryRegistration";
import MachineRegistration from "@src/page/Registration/MachineRegistration";
import ViewUserInfo from "../../page/user_management/view";
import CalibrationList from "../../page/Calibration_management/list";
import CalibrationAdd from "../../page/Calibration_management/add";
import CalibrationView from "../../page/Calibration_management/view";

// dynamic report management
import DynamicReport from "@src/page/DynamicChartManagement";
import DynamicReport_Add from "@src/page/DynamicChartManagement/add";
import HomeForEndUser from "../../page/EndUser/HomeForEndUser";
export default [
  // dashboard (homepage)
  {
    component: OfficeDetail,
    icon: <HomeFilled style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.DASHBOARD,
    title: lang.HOMEPAGE,
    exact: true,
    key: "DASHBOARD",
    menuCode: "M010",
  },
  {
    component: HomeForEndUser,
    path: ROUTER_PAGE.END_USER,
    exact: true,
    key: "END_USER",
    menuCode: "M160",
  },
  
  // Home banner management
  {
    component: HomeBanner_list,
    icon: <HomeFilled style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.HOME_BANNER[0],
    title: lang.HOME_BANNER,
    exact: true,
    menuCode: "M120",
    key: "HOME_BANNER",
  },
  {
    component: HomeBanner_add,
    path: "/CreateHomeBanner",
    title: "Add Home Banner",
    menuCode: "M120",
    exact: true,
  },
  {
    component: HomeBanner_add,
    path: "/EditHomeBanner/:id",
    title: "Add Home Banner",
    menuCode: "M120",
    exact: true,
  },
  {
    component: HomeBanner_view,
    path: "/ViewHomeBanner/:id",
    title: "View Home Banner",
    menuCode: "M120",
    exact: true,
  },
  // checklist management
  {
    key: "checklistManagement",
    title: lang.CL_MANAGEMENT,
    icon: <ScheduleOutlined style={{ fontSize: "20px" }} />,
    menuCode: "M020",
    children: [
      {
        key: "CL_INPUT",
        icon: <FormOutlined style={{ fontSize: "20px" }} />,
        component: ChecklistOverview,
        path: ROUTER_PAGE.CL_INPUT,
        title: lang.TODO_CHECKLIST,
        menuCode: "M021",
        exact: true,
      },
      {
        component: DetailCheckList,
        path: "/list/:idCheckList",
        title: "Detail CheckList",
        menuCode: "M021",
        exact: true,
      },
      {
        component: ShowAllChecklistBK,
        path: "/checklist-list",
        title: "Show All Checklist Bookmark",
        menuCode: "M021",
        exact: true,
      },
      {
        component: ShowAllChecklistSaved,
        path: "/checklist-result",
        title: "Show All Checklist Saved",
        menuCode: "M021",
        exact: true,
      },
      {
        component: SettingGroup,
        path: "/checklist/group-checklist/:idChecklist",
        title: "Setting Group",
        menuCode: "M021",
        exact: true,
      },
      {
        key: "CL_MANAGEMENT",
        icon: <UnorderedListOutlined style={{ fontSize: "20px" }} />,
        component: ManagementCL_list,
        path: ROUTER_PAGE.CL_MANAGEMENT[0],
        title: lang.LIST_CHECKLIST,
        menuCode: "M022",
        exact: true,
      },
      {
        component: ManagementCL_add,
        path: "/CloneChecklistv2/:clone",
        title: "Add Management CL",
        menuCode: "M022",
        exact: false,
      },
      {
        component: ManagementCL_add,
        path: "/CloneChecklistPreCheck/:clone",
        title: "Add Management CL",
        menuCode: "M022",
        exact: false,
      },
      {
        component: ManagementCL_add,
        path: "/EditChecklistv2/:id",
        title: "Add Management CL",
        menuCode: "M022",
        exact: false,
      },
      {
        component: ManagementCL_add,
        path: "/CreateChecklistv2",
        title: "Add Management CL",
        menuCode: "M022",
        exact: false,
      },
      {
        component: ManagementCL_view,
        path: "/ViewChecklistv2/:id",
        title: "View Management CL",
        menuCode: "M022",
        exact: false,
      },
      {
        key: "INPUT_REST_LAST_VALUE",
        icon: <FileSyncOutlined style={{ fontSize: "20px" }} />,
        component: ResetLastValue_list,
        path: ROUTER_PAGE.INPUT_REST_LAST_VALUE[0],
        permission: [Role.HEAD_OFFICE_ADMIN, Role.OFFICE_ADMIN],
        title: lang.SC_REST_LAST_VALUE,
        exact: true,
        menuCode: "M023",
      },
      {
        component: ResetLastValue_edit,
        path: "/EditResetLastValue/:id",
        exact: true,
        menuCode: "M023",
        permission: [Role.HEAD_OFFICE_ADMIN, Role.OFFICE_ADMIN],
      },
      {
        key: "CL_HISTORY",
        icon: <HistoryOutlined style={{ fontSize: "20px" }} />,
        component: HistoryCL_List,
        path: ROUTER_PAGE.CL_HISTORY,
        title: lang.HISTORY_CHECKLIST,
        menuCode: "M024",
        exact: true,
      },
      {
        component: HistoryCL_View,
        path: "/history-detail/:id",
        title: "View History Checklist",
        menuCode: "M024",
        exact: true,
      },
    ],
  },

  //location-managerment

  {
    key: "locationManagement",
    title: lang.LOCATION_MANAGEMENT,
    menuCode: "M030",
    // icon: <ScheduleOutlined style={{ fontSize: "20px" }} />,
    children: [
      {
        key: "MAINTAIN_HISTORY",
        component: MaintainHistory,
        path: ROUTER_PAGE.MAINTAIN_HISTORY[0],
        title: lang.MAINTAINCE_HISTORY,
        exact: true,
        menuCode: "M033",
      },
      {
        key: "MACHINE_MANAGEMENT",
        component: Machine_list,
        path: ROUTER_PAGE.MACHINE_MANAGEMENT[0],
        title: lang.MACHINE_MANAGEMENT,
        exact: true,
        menuCode: "M033",
      },
      {
        component: AddMachine,
        path: "/AddMachine",
        title: "Add Machine",
        exact: true,
        menuCode: "M033",
      },
      {
        component: ImportMachine,
        path: "/ImportMachine",
        title: lang.MACHINE_IMPORT,
        exact: true,
        menuCode: "M033",
      },
      {
        component: AddMachine,
        path: "/EditMachine/:id",
        title: "Edit Machine",
        exact: true,
        menuCode: "M033",
      },
      {
        component: ViewMachine,
        path: "/ViewMachine/:id",
        title: "ViewMachine",
        exact: true,
        menuCode: ["M033","M034"],
      },
      {
        component: ViewMachine,
        path: "/HistoryUpdateMachine/:id/:version",
        title: "HistoryUpdateMachine",
        exact: true,
        menuCode: "M033",
      },
      {
        key: "FACILITY_MANAGEMENT",
        // icon: <FormOutlined style={{ fontSize: "20px" }} />,
        component: FacilityList,
        path: ROUTER_PAGE.FACILITY_MANAGEMENT[0],
        title: lang.FACILITY_MANAGEMENT,
        exact: true,
        menuCode: "M031",
      },
      {
        component: Addfacility,
        path: "/AddFacility",
        exact: true,
        menuCode: "M031",
      },
      {
        component: Addfacility,
        path: "/EditFacility/:id",
        exact: true,
        menuCode: "M031",
      },
      {
        component: ViewFacility,
        path: "/ViewFacility/:id",
        exact: true,
        menuCode: "M031",
      },
      {
        key: "PROCESS_MANAGEMENT",
        // icon: <FormOutlined style={{ fontSize: "20px" }} />,
        component: FacilityList,
        path: ROUTER_PAGE.FACILITY_MANAGEMENT[0],
        title: lang.FACILITY_MANAGEMENT,
        exact: true,
        menuCode: "M032",
      },
      {
        key: "PROCESS_MANAGEMENT",
        // icon: <FormOutlined style={{ fontSize: "20px" }} />,
        component: ListProcess,
        path: ROUTER_PAGE.PROCESS_MANAGEMENT[0],
        title: lang.PROCESS_MANAGEMENT,
        exact: true,
        menuCode: "M032",
      },
      {
        component: ViewProcess,
        path: "/ViewProcess/:id",
        exact: true,
        menuCode: "M032",
      },
      {
        component: AddProcess,
        path: "/AddProcess",
        exact: true,
        menuCode: "M032",
      },
      {
        component: AddProcess,
        path: "/EditProcess/:id",
        exact: true,
        menuCode: "M032",
      },
    ],
  },
  // report management
  {
    title: lang.REPORT_MANAGEMENT,
    key: "reportManagement",
    icon: <FileTextFilled style={{ fontSize: "20px" }} />,
    menuCode: "M040",
    children: [
      {
        key: "CUSTOMER_REPORT",
        icon: <SnippetsOutlined style={{ fontSize: "20px" }} />,
        component: CustomerReport,
        path: ROUTER_PAGE.CUSTOMER_REPORT,
        title: lang.EXPORT_REPORT,
        menuCode: "M041",
        exact: true,
      },
      {
        key: "SHEET_IMPORT_REPORT",
        icon: <ImportOutlined style={{ fontSize: "20px" }} />,
        component: SheetImportReport,
        path: ROUTER_PAGE.SHEET_IMPORT_REPORT,
        title: lang.IMPORT_CSV,
        menuCode: "M042",
        exact: true,
      },
      {
        key: "TEMPLATE_MANAGEMENT",
        icon: <LayoutFilled style={{ fontSize: "20px" }} />,
        component: TemplateManagement,
        path: ROUTER_PAGE.TEMPLATE_MANAGEMENT,
        title: lang.SAMPLE_TEMP_MANAGEMENT,
        menuCode: "M043",
        exact: true,
      },
      {
        component: HandleFuncTemplate,
        path: "/TemplateManagement/:function",
        title: "Template Management",
        menuCode: "M043",
        exact: true,
      },
    ],
  },
  // work report management
  {
    key: "WORK_REPORT",
    icon: <CarryOutFilled style={{ fontSize: "20px" }} />,
    component: WorkReport_list,
    path: ROUTER_PAGE.WORK_REPORT[0],
    title: lang.WORK_REPORT_MANAGEMENT,
    exact: true,
    menuCode: "M050",
  },
  {
    component: WorkReport_add,
    path: "/CreateReport",
    title: "Add Working Report",
    exact: true,
    menuCode: "M050",
  },
  {
    component: WorkReport_add,
    path: "/CreateReport/:typeReport/:idResultCheck",
    title: "Add Working Report",
    exact: true,
    menuCode: "M050",
  },
  {
    component: WorkReport_add,
    path: "/EditReport/:id",
    title: "Add Working Report",
    exact: true,
    menuCode: "M050",
  },
  {
    component: WorkReport_view,
    path: "/ViewReport/:id",
    title: "View Working Report",
    exact: true,
    menuCode: "M050",
  },
  // role setting
  {
    component: Role_list,
    title: lang.ROLE_SETTING,
    icon: <SettingFilled style={{ fontSize: "20px" }} />,
    key: "USER_SETTING",
    menuCode: "M060",
    children: [
      {
        key: "USER_INFO",
        component: ViewUserInfo,
        path: "/ViewUser/:userName?",
        exact: true,
        menuCode: "M062",
      },
      {
        key: "ROLE_SETTING",
        menuCode: "M061",
        component: Role_list,
        path: ROUTER_PAGE.ROLE_SETTING,
        title: lang.ROLE_SETTING,
        icon: <SettingFilled style={{ fontSize: "20px" }} />,
        exact: true,
        permission: [Role.HEAD_OFFICE_ADMIN],
      },
    ],
  },
  // registration
  {
    title: lang.REGISTRY_ORIGIN_DATA,
    icon: <ReconciliationFilled style={{ fontSize: "20px" }} />,
    key: "importExport",
    permission: [Role.HEAD_OFFICE_ADMIN],
    menuCode: "M070",
    children: [
      {
        key: "CL_REGISTRATION",
        component: ChecklistRegistration,
        icon: <LayoutOutlined style={{ fontSize: "20px" }} />,
        path: ROUTER_PAGE.CL_REGISTRATION,
        title: lang.LIST_CHECKLIST,
        menuCode: "M071",
        exact: true,
      },
      {
        key: "MC_REGISTRATION",
        icon: <ClusterOutlined style={{ fontSize: "20px" }} />,
        component: MachineRegistration,
        path: ROUTER_PAGE.MC_REGISTRATION,
        title: lang.REGISTRY_MACHINE,
        exact: true,
        menuCode: "M072",
      },
      {
        key: "INVENTORY_REGISTRATION",
        icon: <ClusterOutlined style={{ fontSize: "20px" }} />,
        component: InventoryRegistration,
        path: ROUTER_PAGE.INVENTORY_REGISTRATION,
        title: lang.REGISTRY_INVENTORY,
        menuCode: "M073",
        exact: true,
      },
    ],
  },
  // facility
  {
    key: "OFFICE_MANAGEMENT",
    icon: <GlobalOutlined style={{ fontSize: "20px" }} />,
    component: Office_list,
    path: ROUTER_PAGE.OFFICE_MANAGEMENT[0],
    title: lang.OFFICE_MANAGEMENT,
    permission: [Role.HEAD_OFFICE_ADMIN],
    menuCode: "M090",
    exact: true,
  },
  {
    component: Office_add,
    path: "/CreateOffice",
    menuCode: "M090",
    exact: true,
  },
  {
    component: Office_add,
    path: "/UpdateOffice/:id",
    menuCode: "M090",
    exact: true,
  },
  {
    component: Office_view,
    path: "/DetailOffice/:id",
    menuCode: "M090",
    exact: true,
  },
  // Import inventory
  {
    title: lang.INVENTORY,
    icon: <CodeSandboxOutlined style={{ fontSize: "20px" }} />,
    key: "INVENTORY",
    menuCode: "M080",
    children: [
      {
        key: "IMPORT_INVENTORY",
        component: Import_Inventory_list,
        icon: <ImportOutlined style={{ fontSize: "20px" }} />,
        path: ROUTER_PAGE.IMPORT_INVENTORY[0],
        title: lang.IMPORT_INVENTORY,
        menuCode: "M081",
        exact: true,
      },
      {
        component: Import_Inventory_update,
        path: ROUTER_PAGE.IMPORT_INVENTORY[1],
        menuCode: "M081",
        exact: true,
      },
      {
        component: Import_Inventory_update,
        path: ROUTER_PAGE.IMPORT_INVENTORY[1] + "/:id",
        menuCode: "M081",
        exact: true,
      },
      {
        component: Import_Inventory_update,
        path: ROUTER_PAGE.IMPORT_INVENTORY[1] + "/:id/update",
        menuCode: "M081",
        exact: true,
      },
      {
        key: "EXPORT_INVENTORY",
        icon: <ExportOutlined style={{ fontSize: "20px" }} />,
        component: Export_Inventory_list,
        path: ROUTER_PAGE.EXPORT_INVENTORY[0],
        title: lang.EXPORT_INVENTORY,
        menuCode: "M082",
        exact: true,
      },
      {
        component: Export_Inventory_update,
        path: ROUTER_PAGE.EXPORT_INVENTORY[1],
        menuCode: "M082",
        exact: true,
      },
      {
        component: Export_Inventory_update,
        path: ROUTER_PAGE.EXPORT_INVENTORY[1] + "/:id",
        menuCode: "M082",
        exact: true,
      },
      {
        component: Export_Inventory_update,
        path: ROUTER_PAGE.EXPORT_INVENTORY[1] + "/:id/update",
        menuCode: "M082",
        exact: true,
      },
      {
        key: "INVENTORY_DATA",
        icon: <ContainerOutlined style={{ fontSize: "20px" }} />,
        component: InventoryData,
        path: ROUTER_PAGE.INVENTORY_DATA,
        title: lang.INVENTORY_DATA,
        menuCode: "M083",
        exact: true,
      },
      {
        key: "STOCK",
        icon: <ContainerOutlined style={{ fontSize: "20px" }} />,
        component: Stock,
        path: ROUTER_PAGE.STOCK,
        title: lang.STOCK,
        menuCode: "M084",
        exact: true,
      },
      {
        key: "WAREHOUSES_MASTER",
        icon: <HddOutlined style={{ fontSize: "20px" }} />,
        component: Warehouses_list,
        path: ROUTER_PAGE.WAREHOUSES_MASTER[0],
        title: lang.WAREHOUSES_MASTER,
        menuCode: "M085",
        exact: true,
      },
      {
        component: Warehouses_update,
        path: ROUTER_PAGE.WAREHOUSES_MASTER[1],
        exact: true,
        menuCode: "M085",
      },
      {
        component: Warehouses_update,
        path: ROUTER_PAGE.WAREHOUSES_MASTER[1] + "/:id",
        exact: true,
        menuCode: "M085",
      },
      {
        key: "CATEGORY_MASTER",
        icon: <HddOutlined style={{ fontSize: "20px" }} />,
        component: Category_list,
        path: ROUTER_PAGE.CATEGORY_MASTER[0],
        title: lang.CATEGORY_MASTER,
        menuCode: "M086",
        exact: true,
      },
      {
        component: Category_update,
        path: ROUTER_PAGE.CATEGORY_MASTER[1],
        menuCode: "M086",
        exact: true,
      },
      {
        component: Category_update,
        path: ROUTER_PAGE.CATEGORY_MASTER[1] + "/:id",
        menuCode: "M086",
        exact: true,
      },
      {
        key: "ITEM_MASTER",
        icon: <HddOutlined style={{ fontSize: "20px" }} />,
        component: Item_list,
        path: ROUTER_PAGE.ITEM_MASTER[0],
        title: lang.ITEM_MASTER,
        menuCode: "M087",
        exact: true,
      },
      {
        component: Item_update,
        path: ROUTER_PAGE.ITEM_MASTER[1],
        menuCode: "M087",
        exact: true,
      },
      {
        component: Item_update,
        path: ROUTER_PAGE.ITEM_MASTER[1] + "/:id",
        menuCode: "M087",
        exact: true,
      },
    ],
  },
  // info office
  {
    key: "OFFICE_INFO",
    icon: <InfoCircleFilled style={{ fontSize: "20px" }} />,
    component: Office_view,
    path: ROUTER_PAGE.OFFICE_INFO,
    title: lang.OFFICE_DETAIL,
    menuCode: "M100",
    exact: true,
  },
  {
    component: Office_edit,
    path: "/EditOffice",
    title: "Edit Office",
    menuCode: "M100",
    exact: true,
  },

  //fuction-machine
  {
    key: "FUNTION_MACHINE_MANAGEMENT",
    // icon: <GlobalOutlined style={{ fontSize: "20px" }} />,
    component: FuncMachine,
    path: ROUTER_PAGE.FUNTION_MACHINE_MANAGEMENT,
    title: lang.FUNTION_MACHINE_MANAGEMENT,
    permission: [Role.HEAD_OFFICE_ADMIN],
    menuCode: "M110",
    exact: true,
  },
  //Calendar
  {
    key: "CALENDAR",
    // icon: <GlobalOutlined style={{ fontSize: "20px" }} />,
    component: CalendarComponent,
    path: ROUTER_PAGE.CALENDAR,
    title: lang.CALENDAR,
    // permission: [Role.HEAD_OFFICE_ADMIN],
    menuCode: "M130",
    exact: true,
  },
  // Calibration management

  // work report management
  {
    key: "CALIBRATION",
    icon: <CarryOutFilled style={{ fontSize: "20px" }} />,
    component: CalibrationList,
    path: ROUTER_PAGE.CALIBRATION[0],
    exact: true,
    menuCode: "M035",
  },
  {
    component: CalibrationAdd,
    path: "/CalibrationManagement/Add",
    exact: true,
    menuCode: "M035",
  },
  {
    component: CalibrationAdd,
    path: "/CalibrationManagement/Edit/:id",
    exact: true,
    menuCode: "M035",
  },
  
  {
    component: CalibrationView,
    path: "/CalibrationManagement/View/:id",
    exact: true,
    menuCode: "M035",
  },
  {
    key: "DYNAMIC_REPORT_MANAGEMENT",
    icon: <LineChartOutlined  style={{ fontSize: "20px" }} />,
    component: DynamicReport,
    path: ROUTER_PAGE.DYNAMIC_REPORT_MANAGEMENT[0],
    title: lang.DYNAMIC_REPORT_MANAGEMENT,
    menuCode: "M130",
    exact: true,
  },
  {
    component: DynamicReport_Add,
    path: `${ROUTER_PAGE.DYNAMIC_REPORT_MANAGEMENT[0]}/add`,
    menuCode: "M130",
    exact: true,
  },
  {
    component: DynamicReport_Add,
    path: `${ROUTER_PAGE.DYNAMIC_REPORT_MANAGEMENT[0]}/edit/:id`,
    menuCode: "M130",
    exact: true,
  },
];
