import Api from "@src/api";

export default {
  // 1: Search-----
  // Grid
  search(data) {
    return Api().get("/Inventory/Transactions", {
      params: data,
    });
  },
  // Detail
  getDetailById(id) {
    return Api().get("/Inventory/Transactions/" + id);
  },
  searchItems(data) {
    return Api().get("/Inventory/Items", {
      params: data,
    });
  },
  searchItemsForTransactions(data) {
    return Api().get("/Inventory/ItemsForTransactions", {
      params: data,
    });
  },
  searchInventoryData(data) {
    return Api().get("/Inventory/InventoryData", {
      params: data,
    });
  },
  // Save
  saveImport(data) {
    return Api().post("/Inventory/Transactions", {
      transactionType: 1,
      ...data,
    });
  },
  saveExport(data) {
    return Api().post("/Inventory/Transactions", {
      transactionType: 2,
      ...data,
    });
  },
  // Update import/export transaction
  updateTransaction(data, id) {
    return Api().put("/Inventory/Transactions/" + id, {
      ...data,
    });
  },
  // Delete import/export transaction
  deleteTransaction(id) {
    return Api().delete("/Inventory/Transactions/" + id);
  },
  // M02
  detailWarehouses(id) {
    return Api().get("/Inventory/Warehouses/" + id);
  },
  createWarehouses(data) {
    // inventoryWarehouseName, facilityCode, buildingCode, floorCode, roomCode, note 
    return Api().post("/Inventory/Warehouses", data);
  },
  updateWarehouses(data) {
    // inventoryWarehouseName, facilityCode, buildingCode, floorCode, roomCode, note 
    return Api().put("/Inventory/Warehouses/" + (data.inventoryWarehouseCode || data.id), data);
  },
  deleteWarehouses(id) {
    return Api().delete("/Inventory/Warehouses/" + id);
  },
  // M04
  detailCategory(id) {
    return Api().get("/Inventory/Categories/" + id);
  },
  createCategory(data) {
    //  { categoryName, remarks }
    return Api().post("/Inventory/Categories", data);
  },
  updateCategory(data) {
    //  { categoryName, remarks }
    return Api().put("/Inventory/Categories/" + (data.categoryCode || data.id), data);
  },
  deleteCategory(id) {
    return Api().delete("/Inventory/Categories/" + id);
  },
  // M06
  detailItem(id) {
    return Api().get("/Inventory/Items/" + id);
  },
  createItem(data) {
    //  inventoryItemName, inventoryType, inventoryWarehouseCode, receivingUnit,
    //  inventoryUnit, categoryCode, conversionRate, minimumRequiredQuantity,
    //  stockQuantity (thêm cái này lên màn hình), manufacturer, makerModelNumber, samples, remarks 
    return Api().post("/Inventory/Items", data);
  },
  updateItem(data) {
    // not update: inventoryWarehouseCode, stockQuantity when haveTransaction == true
    return Api().put("/Inventory/Items/" + (data.inventoryItemCode || data.id), data);
  },
  deleteItem(id) {
    return Api().delete("/Inventory/Items/" + id);
  },
  // S06
  getStock(data) {
    return Api().get("/Inventory/Stock", {
      params: data
    })
  },
  // S07
  getDetailStock(id, data) {
    return Api().get("/Inventory/Stock/" + id, {
      params: data
    })
  },
  // S08
  downloadItemsForTransactions(data) {
    return Api().get("/Inventory/ItemsForTransactions/Download", {
      params: data,
      responseType: 'blob'
    });
  },
  //banner warning
  GetDataForBannerWarning(){
    return Api().get("Inventory/GetDataForBannerWarning");
  },
  //download stock
  downloadStock(data) {
    return Api().get("/Inventory/DownloadStock", {
      params: data,
      responseType:"blob"
    })
  },
  uploadFile(formData) {
    return Api().post(`/FileUpload/Image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  downloadTemplateOrder(){
    return Api().get("Inventory/DownloadTemplateOrder")
  }
};
