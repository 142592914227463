class classGroup {
  constructor(
    ID,
    Name = "",
    Machines = [],
    PreCheckItems = [],
    Order,
    machineShow = []
  ) {
    this.ID = ID;
    this.Name = Name;
    this.Order = Order;
    this.Machines = Machines;
    this.machineShow = machineShow;
    this.PreCheckItems = PreCheckItems;
  }
}

export default classGroup;
