/* eslint-disable react-hooks/exhaustive-deps */
import {
  Layout,
  Form,
  Input,
  Space,
  Row,
  Col,
  Select,
  Button,
  Table,
  Checkbox,
  DatePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput2";
import TitlePage from "../../component/Desktop/titlePage";
import FooterPage from "../../component/footer/footerPage";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import classText from "../../models/control/text";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/inventory";
import {
  ImportOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileTextFilled,
  RollbackOutlined,
  SettingFilled,
  SaveOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  getSortColumn_local,
  inputNumberRequired,
  isEmpty,
  MSG_CATCH,
  onSortColumn,
  renderDefaultColumn,
  renderOption,
  renderSortColumn,
  saveSortColumn_local,
  showMessage,
  showMessageChange,
  statusRes,
  txtRequired,
} from "../../constants/utils";
import InputPositiveNumber from "../../component/Desktop/inputPositiveNumber";
import BoxItemWarehouses from "./boxItemWarehouses";
import classGrid from "../../models/control/grid";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { checkRoleScreen } from "../../utils/roles";

const { Content } = Layout;

export default function ImportInventory() {
  const KEY_PAGE = "IMP_INV_2"; // Change key localStorage when column update
  const KEY_CATEGORY_MACHINE = "machineCategoryCodes";
  const isUpdate = window.location.pathname.indexOf("/update") > -1;
  const { id, id: viewId } = useParams();
  const modeView = viewId && !isUpdate;
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  // Loading
  const openLoadingSpinner = () => setShowLoading(true);
  const closeLoadingSpinner = () => setShowLoading(false);
  // State screen

  const [listCategoryMachine, setListCategoryMachine] = useState([]);
  const [grid, setGrid] = useState(new classGrid());
  const [selSearch, setSelSearch] = useState([]);
  const [selAdded, setSelAdded] = useState([]);
  const [data, setData] = useState({
    code: new classText(),
    createDate: new classText(),
    createUser: new classText(),

    items: [],
  });
  const [selGrid, setSelGrid] = useState([]);
  const [modeSetting, setModeSetting] = useState(false);
  const [flagChangeData, setFlagChangeData] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  //  Import/Export Items
  const renderCell = (
    colName,
    { type = KEY.TEXT, checkRequired = true, maxLength = 255 }
  ) => {
    // mode: View
    if (modeView) {
      return;
    }
    // mode: ADD
    return {
      render: (txt, row, index) => {
        let err;
        if (checkRequired) {
          if (type === KEY.TEXT) {
            err = txtRequired(txt);
          } else {
            // Number
            err = inputNumberRequired(txt);
          }
        }

        const setting = {
          className:
            (err ? "border-red" : "") + (type === KEY.NUMBER ? " right" : ""),
          value: txt,
          maxLength: maxLength,
          onChange: (e) => {
            // now
            setCellValue(index, colName, e.target.value);
          },
        };

        return type === KEY.NUMBER ? (
          <InputPositiveNumber {...setting} />
        ) : (
          <Input {...setting} />
        );
      },
    };
  };
  const [columnsItem, setColumnsItem] = useState(
    getSortColumn_local(KEY_PAGE) || [
      // Item
      {
        name: lang.ITEM,
        dataIndex: "inventoryItemName",
        align: "center",
      },
      // Warehouses
      {
        name: lang.WAREHOUSES_NAME,
        dataIndex: "inventoryWarehouseName",
        align: "center",
      },
      // Item code
      {
        name: lang.ITEM_CODE,
        dataIndex: "inventoryWarehouseItemCode",
        align: "center",
      },

      // Category
      {
        name: lang.CATEGORY_NAME,
        dataIndex: "categoryName",
        align: "center",
      },
      // 17.Số lượng nhận hàng
      {
        name: lang.INVENTORY_QUANTITY,
        dataIndex: "inventoryQuantity",
        align: "center",
      },
      // Đơn vị kho
      {
        name: lang.INVENTORY_UNIT,
        dataIndex: "inventoryUnit",
        align: "center",
      },

      // Loại máy sử dụng
      {
        name: lang.TYPE_OF_MACHINE_USED,
        dataIndex: KEY_CATEGORY_MACHINE,
      },
      // Người bán lẻ
      {
        name: lang.RETAILER,
        dataIndex: "retailer",
        align: "center",
      },
      // Số phiếu
      {
        name: lang.ORDER_NUMBER,
        dataIndex: "orderNumber",
        align: "center",
      },
      // Mô tả
      {
        name: lang.REMARKS,
        dataIndex: "remarks",
        align: "center",
      },
    ]
  );
  const [columnsItem_draft, setColumnsItem_draft] = useState(null);

  const setDataWidthKey = (key, objData) => {
    const obj = { ...data };
    obj[key] = {};
    setData({
      ...data,
      key: objData,
    });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      openLoadingSpinner();
      //
      Promise.all([viewId && getDetail(), getCategoryMachine()])
        .catch((err) => {
          history.push("/ImportInventory");
        })
        .then(() => {
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);
  //  grid -- mapping -- checkSearch
  useEffect(() => {
    const listIdAdded = data.items.map((v) => v.inventoryWarehouseItemCode);
    setSelAdded(listIdAdded);
  }, [data.items]);

  // set state detail
  const getDetail = () => {
    return API.getDetailById(id).then((res) => {
      if (statusRes(res)) {
        const d = res.data;

        setCanEdit(d.canEdit);
        setData((preData) => {
          return {
            ...preData,
            code: { ...preData.code, value: d.inventoryTransactionCode },
            createDate: { ...preData.createDate, value: d.transactionTime },
            createUser: { ...preData.performer, value: d.performer },
            items: d.items,
          };
        });
      } else {
        throw new Error();
      }
    });
  };

  // set state ddl
  const getCategoryMachine = () => {
    return KBN.getCategoryMachine().then((res) => {
      if (statusRes(res)) {
        setListCategoryMachine(res.data.data);
      } else {
        throw new Error();
      }
    });
  };

  const onAddItems2Grid = () => {
    setData({
      ...data,
      items: JSON.parse(
        JSON.stringify([
          ...data.items,
          ...grid.data
            // Only item new
            .filter(
              (v) =>
                selAdded.indexOf(v.inventoryWarehouseItemCode) < 0 && // !Added
                selSearch.indexOf(v.inventoryWarehouseItemCode) >= 0 // Checked
            ) // List machine draft
            .map((v) => {
              v[KEY_CATEGORY_MACHINE] = [];
              return v;
            }),
        ])
      ),
    });
  };
  const onDeleteItems = () => {
    showMessageChange(() => {
      // List delete
      const listNonSelect = data.items.filter(
        (v) => selGrid.indexOf(v.inventoryWarehouseItemCode) < 0
      );
      // clear select
      setSelGrid([]);
      // Deleting...
      setData({ ...data, items: listNonSelect });
    }, COMMON_CONSTANT.BOM_C001);
  };

  const setCellValue = (index, colName, value) => {
    const items = [...data.items];
    items[index][colName] = value;
    setData({
      ...data,
      items: items,
    });
  };
  const onChangeTypeMachine = (arrValue, index) => {
    const itemNew = data.items;
    itemNew[index][KEY_CATEGORY_MACHINE] = arrValue;
    //
    setData({
      ...data,
      items: itemNew,
    });
  };
  const columnsItem_checkbox = {
    title: (
      <Checkbox
        checked={data.items.length > 0 && selGrid.length === data.items.length}
        onChange={(e) => {
          setSelGrid(
            e.target.checked
              ? data.items.map((v) => v.inventoryWarehouseItemCode)
              : []
          );
        }}
      />
    ),
    align: "center",
    fixed: modeSetting ? false : "left",
    width: "40px",
    render: (txt, row) => {
      return (
        <Checkbox
          checked={selGrid.indexOf(row.inventoryWarehouseItemCode) >= 0}
          onChange={(e) => {
            let listCheck = [...selGrid];
            if (e.target.checked) {
              // Checked
              listCheck.push(row.inventoryWarehouseItemCode);
            } else {
              //UnChecked
              listCheck = listCheck.filter(
                (v) => v !== row.inventoryWarehouseItemCode
              );
            }
            setSelGrid(listCheck);
          }}
        />
      );
    },
  };

  // Addon column
  const addonColumn = (arr) => {
    return arr.map((col) => {
      // Input number
      if (["inventoryQuantity"].includes(col.dataIndex)) {
        col = {
          ...col,
          ...renderCell(col.dataIndex, { type: KEY.NUMBER, maxLength: 10 }),
        };
      }
      // Input text
      if (["retailer", "orderNumber", "remarks"].includes(col.dataIndex)) {
        col = {
          ...col,
          ...renderCell(col.dataIndex, {
            type: KEY.TEXT,
            checkRequired: false,
          }),
        };
      }
      // Input machine
      if (KEY_CATEGORY_MACHINE === col.dataIndex) {
        col = {
          ...col,
          width: "280px",
          render: (arr = [], row, index) => {
            arr = arr || [];
            return (
              <Select
                disabled={modeView}
                mode="multiple"
                value={arr}
                optionFilterProp="children"
                onChange={(v) => {
                  onChangeTypeMachine(v, index);
                }}
                style={{ width: "100%" }}
              >
                {renderOption(listCategoryMachine, false)}
              </Select>
            );
          },
        };
      }

      return col;
    });
  };

  // Switch render
  const renderColumns = modeSetting
    ? renderSortColumn(
        addonColumn(columnsItem_draft || columnsItem),
        (v, index) => {
          // onSort
          onSortColumn(v, index, [columnsItem_draft, setColumnsItem_draft]);
        }
      )
    : renderDefaultColumn(addonColumn(columnsItem_draft || columnsItem));

  // Open/close sort columns
  const onToggleSetting = () => {
    setColumnsItem_draft(columnsItem);
    setModeSetting(!modeSetting);
  };
  // Save sort columns
  const onSaveSort = () => {
    setColumnsItem(columnsItem_draft);
    saveSortColumn_local(
      KEY_PAGE,
      JSON.parse(
        JSON.stringify(
          columnsItem_draft.map((v) => {
            v.title = undefined;
            return v;
          })
        )
      )
    );
    setModeSetting(false);
  };

  // Param: all
  //   - true : check for save
  //   - false : check for search Item
  const validate = (all = true) => {
    let require = false;
    let min = false;

    // Item
    all &&
      data.items.forEach((v) => {
        // amount
        if (isEmpty(v.inventoryQuantity)) {
          require = true;
        } else if (
          Number(v.inventoryQuantity) <= 0 ||
          isNaN(v.inventoryQuantity)
        ) {
          min = true;
        } else {
          // Empty
        }
      });

    if (require || min) {
      // Fail
      const msg = [];
      const firstLine = "・";
      require && msg.push(firstLine + COMMON_CONSTANT.BOM_E031);
      min && msg.push(firstLine + COMMON_CONSTANT.BOM_E033);

      showMessage(
        KEY.ERROR,
        <div className="white-space">{msg.join("\n")}</div>
      );
      return false;
    } else {
      // Pass
      return true;
    }
  };
  const onSave = () => {
    // Step 1: Validate
    if (!validate()) {
      return;
    }

    showMessageChange(() => {
      openLoadingSpinner();

      // Step 2: Mapping data
      const d = JSON.parse(JSON.stringify(data));
      d.createDate.value = d.createDate.value || moment().unix();
      d.inventoryTransactionCode = d.code.value;
      d.transactionTime = d.createDate.value;
      d.performer = d.createUser.value;

      // Step 3: Sent
      return (!viewId ? API.saveImport : API.updateTransaction)(d, viewId)
        .then((res) => {
          if (statusRes(res)) {
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
              history.push("/ImportInventory");
            });
          } else {
            showMessage(KEY.ERROR, MSG_CATCH());
          }
        })
        .catch((err) => {
          let msg = "";
          if (
            err.response &&
            err.response.status === 400 &&
            err.response.data
          ) {
            msg = err.response.data.msg;
          } else {
            msg = MSG_CATCH();
          }
          showMessage(KEY.ERROR, msg);
        })
        .then(() => {
          closeLoadingSpinner();
        });
    }, COMMON_CONSTANT.BOM_C009);
  };
  const onCancel = () => {
    if (flagChangeData) {
      showMessageChange(history.goBack, COMMON_CONSTANT.BOM_C018);
    } else {
      history.goBack();
    }
  };
  const onDelete = () => {
    // Validate
    if (!canEdit) return;

    showMessageChange(async () => {
      openLoadingSpinner();
      // API Del
      try {
        // Del
        await API.deleteTransaction(viewId);
        // Open success and go to list screen
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
        history.push("/ImportInventory");
      } catch (err) {
        let msg = "";
        if (err.response && err.response.status === 400 && err.response.data) {
          msg = err.response.data.msg;
        } else {
          msg = MSG_CATCH();
        }
        showMessage(KEY.ERROR, msg);
      }
      closeLoadingSpinner();
    }, COMMON_CONSTANT.BOM_C001);
  };
  useEffect(() => {
    if (!modeView && data.items.length > 0) {
      setFlagChangeData(true);
    }
  }, [data.items]);

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={
          modeView
            ? lang.IMPORT_INVENTORY_VIEW
            : isUpdate
            ? lang.IMPORT_INVENTORY_UPDATE
            : lang.IMPORT_INVENTORY_ADD
        }
      />
      {/* Common */}
      <Content
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Form layout={"vertical"} className="formDes">
          {/* Code & create Date */}
          <Space className="d-flex" size={15}>
            <RowInput name={lang.TRADING_CODE} required>
              <Input
                disabled
                maxLength={255}
                value={data.code.value}
                className="wInput"
                onChange={(e) =>
                  setDataWidthKey("code", {
                    ...data.code,
                    value: e.target.value,
                  })
                }
              />
            </RowInput>
            <RowInput name={lang.CREATE_DATE}>
              <DatePicker
                showTime
                allowClear={false}
                className="wInput black"
                disabled={modeView}
                format={KEY.DATE_WITH_HOUR_DESKTOP}
                disabledDate={(current) => current > moment().endOf("day")}
                onChange={(v) => {
                  setData({
                    ...data,
                    createDate: {
                      ...data.createDate,
                      value:
                        moment(v).diff(moment()) > 0
                          ? moment().unix()
                          : moment(v).unix(),
                    },
                  });
                }}
                value={
                  data.createDate.value
                    ? moment(+data.createDate.value * 1000)
                    : moment()
                }
              />
            </RowInput>
            <RowInput name={lang.CREATED} required>
              <Input
                disabled
                value={data.createUser.value}
                className="wInput"
              />
            </RowInput>
          </Space>
        </Form>
      </Content>
      {/* Search Items */}
      {!modeView && (
        <BoxItemWarehouses
          selSearch={[selSearch, setSelSearch]}
          grid={[grid, setGrid]}
          selAdded={[selAdded, setSelAdded]}
          onAddItems2Grid={onAddItems2Grid}
        />
      )}
      {/* Items */}
      <Content
        className={"site-layout-background"}
        style={{
          margin: "24px 16px",
          padding: 20,
        }}
      >
        <div className="titleText2">
          <FileTextFilled className="svg-default" /> {lang.IMPORT_INVENTORY}
        </div>
        {!modeView && (
          <Row justify="space-between" className="mb15 align-center">
            <Col>
              <Button
                className="buttonPC button--error--outline"
                onClick={onDeleteItems}
                disabled={selGrid.length === 0}
              >
                <DeleteOutlined />
                {lang.DELETE}
              </Button>
            </Col>
            <Col>
              {modeSetting ? (
                <>
                  <Button
                    className="buttonPC button--info wAuto mr15"
                    onClick={onSaveSort}
                  >
                    <SaveOutlined />
                    {lang.SAVE}
                  </Button>
                  <Button
                    className="buttonPC button--outline wAuto "
                    onClick={onToggleSetting}
                  >
                    <CloseCircleOutlined />
                    {lang.CANCEL}
                  </Button>
                </>
              ) : (
                <Button
                  type="link"
                  className="btnLink svg-default"
                  onClick={onToggleSetting}
                >
                  <SettingFilled />
                  {lang.SETTING}
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Table
          bordered={modeSetting}
          className="tblInventory"
          rowKey="inventoryWarehouseItemCode"
          dataSource={data.items}
          columns={
            modeView ? renderColumns : [columnsItem_checkbox, ...renderColumns]
          }
          scroll={{ x: 1500 }}
          pagination={false}
        />
      </Content>
      {/* Footer */}
      <FooterPage>
        <Row justify="space-between">
          <Col className="align-center">
            {!modeView && (
              <Button
                className="buttonPC button--info wAuto mr15"
                onClick={onSave}
                disabled={data.items.length === 0 || modeSetting}
              >
                <ImportOutlined />
                {isUpdate
                  ? lang.IMPORT_INVENTORY_UPDATE_BUTTON
                  : lang.IMPORT_INVENTORY}
              </Button>
            )}
            <div className="fs16 bold">
              {lang.TOTAL} {data.items.length}
            </div>
          </Col>
          <Col>
            <Button
              className="buttonPC button--outline wAuto ml15"
              onClick={onCancel}
            >
              {modeView ? (
                <>
                  <RollbackOutlined /> {lang.BACK}
                </>
              ) : (
                <>
                  <CloseCircleOutlined /> {lang.CANCEL}
                </>
              )}
            </Button>
            {modeView && (
              <>
                <Button
                  className="buttonPC button--info wAuto ml15"
                  disabled={!canEdit}
                >
                  <Link
                    to={"/ImportInventoryUpdate/" + viewId + "/update"}
                    className="w100"
                    disabled={!canEdit}
                  >
                    <EditOutlined />
                    <span style={{ marginLeft: 8 }}>{lang.EDIT}</span>
                  </Link>
                </Button>
                <Button
                  className="buttonPC button--error wAuto ml15"
                  disabled={!canEdit}
                  onClick={onDelete}
                >
                  <DeleteOutlined />
                  {lang.DELETE}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </FooterPage>
    </div>
  );
}
