import Api from "@src/api";

export default {
  // search
  getData(data) {
    return Api().get("Checklists/GetCheckListApprove", { params: data });
  },
  // Get checklist
  getCheckList(id) {
    return Api().get(
      `ChecklistHistoryDetail/GetCheckItemDetail?checklistID=${id}`
      // `ChecklistHistoryDetail/${id}`
    );
  },
  // Save
  save(id, data) {
    return Api().put(`ChecklistHistoryDetail/SaveLastValue/${id}`, data);
  },
};
