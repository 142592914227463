import Api from "@src/api";
export default {
  getFactories() {
    return Api().get("SiteOfficeMaster");
  },
  getUserInfoWithSiteOffice(userName, siteOffice) {
    return Api().get(
      `UserInfo?userName=${userName}&siteOfficeMasterCode=${siteOffice}`
    );
  },
  getUserInfo() {
    let api = `UserInfo`;
    let site = localStorage.getItem('siteOffice');
    if (site) {
      api = site + "/" + api;
    }
    return Api().get(api);
  },
  getUserInfoBySideOfficeCode(){
    let api = `UserInfo`;
    return Api().get(api);
  }
};
