import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ApiProcessMangement } from "../../../api/backend/prcessManagement";
import BreakLine from "../../../component/Desktop/breakLine";
import GroupItem from "../../../component/Desktop/groupItem";
import Loading from "../../../component/Desktop/loading/loading";
import RowInput from "../../../component/Desktop/rowInput";
import TitlePage from "../../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../../constants/common.const";
import { MSG_CATCH, renderOption, showMessage } from "../../../constants/utils";
import classDdl from "../../../models/control/dropdownlist";
import classText from "../../../models/control/text";
import { checkRoleScreen } from "../../../utils/roles";
function AddProcess() {
  const { selectedFactory } = useSelector((state) => state.auth);
  const [nameFacility, setNamefacility] = useState(
    new classDdl(null, [], false)
  );
  const [nameProcess, setNameProcess] = useState(new classText());
  const [nameProcessMaster, setNameProcessMaster] = useState(
    new classDdl(null, [], false)
  );
  const [status, setStatus] = useState(new classDdl(null, [], false));
  const statusRef = useRef();
  const [showLoading, setShowLoading] = useState(false);
  const isFirstRender = useRef(0);
  const history = useHistory();
  const { id } = useParams();
  const facilityNameRef = useRef();
  const nameProcessRef = useRef();
  const processMassterRef = useRef();
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const listInput = [
    {
      title: lang.NAME_FACILITY,
      objectValue: nameFacility,
      onChange: (value) =>
        setNamefacility({
          ...nameFacility,
          value,
          error: value ? false : true,
        }),
      type: "select",
      ref: facilityNameRef,
      required: true,
    },
    {
      title: lang.PROCESS_MASTER_NAME,
      objectValue: nameProcessMaster,
      onChange: (value) =>
        setNameProcessMaster({
          ...nameProcessMaster,
          value,
          error: value ? false : true,
        }),
      type: "select",
      ref: processMassterRef,
      required: true,
    },
    {
      title: lang.NAME_PROCESS,
      objectValue: nameProcess,
      onChange: (e) =>
        setNameProcess({
          ...nameProcess,
          value: e.target.value,
          error: e.target.value.trim() ? false : true,
        }),
      type: "input",
      ref: nameProcessRef,
      required: true,
    },
    {
      title: lang.STATUS,
      objectValue: status,
      onChange: (value) =>
        setStatus({ ...status, value, error: value ? false : true }),
      type: "select",
      ref: statusRef,
      required: true,
    },
  ];
  const renderListInput = listInput.map(
    ({ type, objectValue, onChange, title, ref, required }, index) => {
      switch (type) {
        case "select": {
          return (
            <div key={index}>
              <BreakLine />
              <RowInput required={required} name={title} box>
                <Select
                  disabled={objectValue.disabled}
                  optionFilterProp="children"
                  showSearch
                  ref={ref}
                  className={`${
                    objectValue.error ? STYLE.BORDER_RED : ""
                  } w100`}
                  value={objectValue.value}
                  onChange={onChange}
                >
                  {renderOption(objectValue.options)}
                </Select>
              </RowInput>
              <BreakLine />
            </div>
          );
        }
        case "input": {
          return (
            <div key={index}>
              <BreakLine />
              <RowInput required={required} name={title} box>
                <Input
                  ref={ref}
                  className={objectValue.error ? STYLE.BORDER_RED : ""}
                  value={objectValue.value}
                  onChange={onChange}
                />
              </RowInput>
              <BreakLine />
            </div>
          );
        }
        default: {
          return <></>;
        }
      }
    }
  );
  const onCancel = () => {
    history.goBack();
  };
  const validatedData = () => {
    let isvaliDated = true;
    if (!nameFacility.value) {
      setNamefacility({ ...nameFacility, error: true });
      isvaliDated = false;
    }
    if (!nameProcessMaster.value) {
      setNameProcessMaster({ ...nameProcessMaster, error: true });
      isvaliDated = false;
    }
    if (!nameProcess.value || !nameProcess.value.trim()) {
      setNameProcess({ ...nameProcess, error: true });
      isvaliDated = false;
    }
    if (!status.value) {
      setStatus({ ...status, error: true });
      isvaliDated = false;
    }
    for (let i = 0; i < listInput.length; i++) {
      if (!listInput[i].objectValue.value) {
        listInput[i].ref.current.focus();
        break;
      }
    }
    return isvaliDated;
  };
  const handleSaveData = async () => {
    if (!validatedData()) return;
    const createProcess = async () => {
      setShowLoading(true);
      const formData = new FormData();
      const dataPost = {
        ProcessMasterCode: nameProcessMaster.value,
        FacilityCode: nameFacility.value,
        ProcessName: nameProcess.value,
        Status: status.value,
        ProcessCode: "",
        FacilityName: null,
        ProcessMasterName: null,
      };
      if (id) {
        dataPost.Id = id;
      }
      formData.append("data", JSON.stringify(dataPost));
      try {
        setShowLoading(true);
        !id
          ? await ApiProcessMangement.createProcess(formData)
          : await ApiProcessMangement.updateProcess(formData);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => history.goBack());
      } catch (error) {
        let msg;
        if (error.response) {
          if (error.response.status === 400) {
            msg = error.response.data.msg;
          }
        }
        showMessage(KEY.ERROR, msg || MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    };
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: createProcess,
    });
  };
  useEffect(() => {
    const fetchInitData = async () => {
      if (id) {
        setShowLoading(true);
      }
      try {
        const [detailProcess, facilityResponse, listStatus] = await Promise.all(
          [
            id
              ? ApiProcessMangement.getDetailtProcess({ processId: id })
              : null,
            ApiProcessMangement.fetchFacility({
              siteOfficeMasterCode: selectedFactory,
            }),
            ApiProcessMangement.getProcessMasterStatus(),
          ]
        );
        if (detailProcess && !detailProcess.data && id) {
          throw { status: 404 };
        }
        const listOptionFacility = facilityResponse.data.map((item) => ({
          key: item.facilityCode,
          value: item.facilityName,
        }));
        if (id) {
          const { data: detailData } = detailProcess;
          setNameProcess({ ...nameProcess, value: detailData.processName });
          setNameProcessMaster({
            ...nameProcessMaster,
            value: detailData.processMasterCode,
          });
          processMassterRef.current.initValue = detailData.processMasterCode;
          setNamefacility({
            ...nameFacility,
            options: listOptionFacility,
            value: detailData.facilityCode,
            disabled: false,
          });
          setStatus({
            ...status,
            options: listStatus.data.data,
            value: detailData.status,
            disabled: false,
          });
        } else {
          setNamefacility({
            ...nameFacility,
            options: listOptionFacility,
            disabled: false,
            value: null,
          });
          setStatus({
            ...status,
            options: listStatus.data.data,
            value: null,
            disabled: false,
          });
          setNameProcess({ ...nameProcess, value: "" });
        }
      } catch (error) {
        console.log(error);
        if (error.status === 404) {
          history.push("/ProcessManagement");
        } else showMessage(KEY.ERROR, MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchInitData();
  }, [id, allowRoute]);

  useEffect(() => {
    const fetchProcessMaster = async () => {
      if (nameFacility && nameFacility.value) {
        setNameProcessMaster({ ...nameProcessMaster, value: null });
        try {
          const resProcessMaster = await ApiProcessMangement.fetchProcessMaster(
            {
              facilityCode: nameFacility.value,
            }
          );
          const { data } = resProcessMaster;
          const listProcessMasterOption = data.map((item) => ({
            key: item.processMasterCode,
            value: item.processMasterName,
          }));
          setNameProcessMaster({
            ...nameProcessMaster,
            options: listProcessMasterOption,
            disabled: false,
            value: processMassterRef.current
              ? processMassterRef.current.initValue
              : null,
          });
        } catch (error) {
          console.log(error);
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      } else {
        const originValue = new classDdl();
        !processMassterRef.current.initValue &&
          setNameProcessMaster({
            ...originValue,
            error: isFirstRender.current++ >= 2 ? true : false,
          });
      }
      if (processMassterRef.current && processMassterRef.current.initValue) {
        processMassterRef.current.initValue = null;
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) fetchProcessMaster();
  }, [nameFacility, id, allowRoute]);
  return (
    <div className="des-content">
      <TitlePage
        name={
          id
            ? lang.PROCESS_MANAGEMENT_UPDATE
            : lang.PROCESS_MANAGEMENT_REGISTRATION
        }
      />
      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">
            {id
              ? lang.PROCESS_MANAGEMENT_UPDATE
              : lang.PROCESS_MANAGEMENT_REGISTRATION}
          </h1>
        </div>
        <div className="mt15"></div>
        {renderListInput}
        <Row className="mt20" justify="end" gutter={[8, 8]}>
          <Button
            onClick={() => handleSaveData()}
            className="buttonPC button--info wAuto ml15"
          >
            <SaveOutlined />
            {lang.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={onCancel}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
      <Loading show={showLoading} />
    </div>
  );
}

export default AddProcess;
