import React from "react";
import { Row, Col, Typography, Tooltip, Input, Button, Empty } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingInline from "../Popup/loading-inline";
import { createTempChecklist } from "@src/redux/checkList";
import format from "@src/utils/format";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import {
  getChecklistsFiltered,
  getGroups,
  updateOffsetFilterChecklist,
} from "@src/redux/checkList";
import { fromUnixTime } from "date-fns/esm";
import { lang, KEY, COMMON_CONSTANT } from "@src/constants/common.const";
import StatusConst from "../../constants/status.const";
const { Paragraph } = Typography;
function ListCheckList({ dataList, isLoadingMore, type, groupId, hasMore }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataSearch, updateDataSearch] = React.useState(null);
  const goToDetail = (obj) => {
    dispatch(createTempChecklist(obj, history));
  };
  const inputSearch = React.useRef(null);
  const { isLoadMoreFiltered, offsetFilteredChecklist, offsetGroupChecklist } =
    useSelector((state) => state.checklist);
  const { selectedFactory } = useSelector((state) => state.auth);
  React.useEffect(() => {
    // if (dataSearch && type === "checklistFilter") {
    //   dispatch(updateOffsetFilterChecklist(0));
    // }
  }, [dataSearch, dispatch, type]);
  const updateLoadingData = () => {
    if (type === "checklistFilter") {
      dispatch(
        getChecklistsFiltered(
          { type: "update" },
          selectedFactory,
          offsetFilteredChecklist
        )
      );
    } else if (type === "group") {
      dispatch(
        getGroups({ offset: offsetGroupChecklist[groupId], groupId: groupId })
      );
    } else {
      dispatch(
        getGroups({
          offset: offsetGroupChecklist[groupId],
          groupId: groupId,
          type: Number(type),
        })
      );
    }
  };
  const updateDataFilter = () => {
    if (type === "checklistFilter") {
      dispatch(
        getChecklistsFiltered(
          {
            type: "search",
            name: dataSearch.trim(),
          },
          selectedFactory,
          offsetFilteredChecklist
        )
      );
    }
  };
  // clear
  const clearInputSearch = () => {
    if (inputSearch && inputSearch.current) {
      inputSearch.current.state.value = "";
      searchChecklist("");
    }
  };
  const searchChecklist = (value) => {
    updateDataSearch(value);
    if (type === "checklistFilter") {
      dispatch(
        getChecklistsFiltered(
          { type: "search", name: value ? value.trim() : value },
          selectedFactory,
          0
        )
      );
      sessionStorage.setItem("searchKey", value);
    }
  };
  // const getAllChecklist = (value) => {
  //   // if (value === "" && dataSearch) {
  //   //   dispatch(getChecklistsFiltered({ type: "first" }, selectedFactory));
  //   // }
  // };
  function renderListData(dataList) {
    return (
      <>
        <Row gutter={[20, 20]}>
          {dataList.map((item, index) => (
            <Col id="animation" key={index} xs={24} md={12} lg={6}>
              <div
                className="itemList itemList-row"
                onClick={() => goToDetail(item)}
              >
                <div className="id">
                  <Row>
                    <Col flex="auto">
                      {lang.ID}: {item.id}
                    </Col>
                    <Col flex="none">
                      <div
                        style={{
                          color:
                            StatusConst.TYPE_CHECKLIST_COLOR[
                              item.isPreUseChecklist
                                ? item.isPreUseChecklist
                                : KEY.TYPE_DEFAULT_CHECKLIST
                            ],
                        }}
                      >
                        {
                          StatusConst.TYPE_CHECKLIST_STATUS[
                            item.isPreUseChecklist
                              ? item.isPreUseChecklist
                              : KEY.TYPE_DEFAULT_CHECKLIST
                          ]
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="title">
                  <Paragraph ellipsis={{ rows: 2 }} className="text-weight-600">
                    <Tooltip title={item.checklistName}>
                      {item.checklistName}
                    </Tooltip>
                  </Paragraph>
                </div>
                <div>
                  <Row justify="space-between">
                    <Col>{item.createName}</Col>
                    <Col className="created">
                      {format(
                        fromUnixTime(Number(item.createDatetime)),
                        KEY.DATE_WITH_HOUR
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="rowNotMargin" align="middle" justify="center">
          <Col>
            <LoadingInline isLoadingInline={isLoadingMore} />
          </Col>
        </Row>
      </>
    );
  }

  React.useEffect(() => {
    const keepValueSearch = sessionStorage.getItem("searchKey");
    if (inputSearch && inputSearch.current) {
      inputSearch.current.state.value = keepValueSearch || "";
    }
    searchChecklist(keepValueSearch || "");
  }, []);
  return (
    <Row className="rowNotMargin">
      {type === "checklistFilter" && (
        <Col lg={12} xs={24} style={{ paddingBottom: "20px" }}>
          <Row className="rowNotMargin nowrap">
            <Col flex="auto" style={{ marginRight: "8px" }}>
              <Input
                maxLength="255"
                placeholder={lang.CHECKLIST_NAME}
                allowClear
                // onChange={(e) => getAllChecklist(e.target.value)}
                onPressEnter={(e) => searchChecklist(e.target.value)}
                ref={inputSearch}
              />
            </Col>
            <Col flex="none">
              <Button
                onClick={() => searchChecklist(inputSearch.current.state.value)}
                className="buttonPC button--info"
                icon={<SearchOutlined />}
              >
                {lang.SEARCH}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={() => clearInputSearch()}
              >
                <ClearOutlined />
                {lang.CLEAR}
              </Button>
            </Col>
          </Row>
        </Col>
      )}
      {dataList && dataList.length ? (
        <Col span={24}>
          <Row style={{ paddingBottom: "20px" }} gutter={[16, 16]}>
            <Col span={24}>
              {dataSearch ? (
                dataSearch.length ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    hasMore={!isLoadingMore && isLoadMoreFiltered}
                    loadMore={() => updateDataFilter()}
                    useWindow={true}
                  >
                    {renderListData(dataList)}
                  </InfiniteScroll>
                ) : (
                  <Row
                    className="rowNotMargin"
                    align="middle"
                    justify="center"
                    style={{ paddingTop: "8px" }}
                  >
                    {COMMON_CONSTANT.EMPTY_DATA}
                  </Row>
                )
              ) : (
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  hasMore={
                    type === "checklistFilter"
                      ? !isLoadingMore && isLoadMoreFiltered
                      : !isLoadingMore && hasMore
                  }
                  loadMore={() => updateLoadingData()}
                  useWindow={true}
                >
                  {renderListData(dataList)}
                </InfiniteScroll>
              )}
            </Col>
          </Row>
        </Col>
      ) : isLoadingMore ? (
        <LoadingInline />
      ) : (
        <Col span={24}>
          <div className="center mt20">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </Col>
      )}
    </Row>
  );
}
export default ListCheckList;
