import BoxContent from "@src/component/Desktop/content";
import { Button, Col, Collapse, Row } from "antd";
import React, { useEffect } from "react";
import "../Machine_Management/machine.scss";
import MaintainHistory from "../Machine_Management/maintainHistory";
import "./homeForEndUser.scss";

import {
  AppstoreAddOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import HistoryCheckList from "@src/page/History_CL/list";
import OficeView from "@src/page/Office/view";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { lang } from "../../constants/common.const";
import { updateTitleHeaderWithoutConst } from "../../redux/sideBar";
import DynamicReport from "./../DynamicChartManagement";
import FooterPage from "../../component/footer/footerPage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import rolesConst from "../../routers/roles/roles.const";

const { Panel } = Collapse;

function HomeForEndUser() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateTitleHeaderWithoutConst(lang.END_USER));
  }, []);
  const history = useHistory();
  const { roles } = useSelector((state) => state.auth);
  const isPC = useMediaQuery({
    query: "(min-device-width: 1500px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1200px) and (max-device-width: 1499px)",
  });
  const padding = isPC ? 200 : isLaptop ? 50 : 0;
  const isAdmin = [
    rolesConst.OFFICE_ADMIN,
    rolesConst.HEAD_OFFICE_ADMIN,
  ].includes(roles);

  const listComponent = [
    {
      component: <OficeView isEndUser />,
      title: lang.OFFICE_DETAIL,
      icon: InfoCircleOutlined,
    },

    {
      component: <DynamicReport isEndUser />,
      title: "動的レポート",
      icon: LineChartOutlined,
    },

    {
      component: <MaintainHistory isEndUser />,
      title: lang.MAINTAINCE_HISTORY,
      icon: AppstoreAddOutlined,
    },
    {
      component: <HistoryCheckList isEndUser />,
      title: lang.HISTORY_CHECKLIST,
      icon: HistoryOutlined,
    },
  ];

  const renderListComponent = listComponent.map((item, index) => (
    <BoxContent key={index} style={{ padding: "8px", margin: "20px" }}>
      <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
        <Panel
          header={
            <>
              <item.icon className="first-icon" />{" "}
              <span style={{ fontWeight: "bold" }}>{item.title}</span>
            </>
          }
          key={1}
        >
          <div style={{ marginTop: -70 }}>{item.component}</div>
        </Panel>
      </Collapse>
    </BoxContent>
  ));
  return (
    <>
      <div style={{ marginTop: 70, padding: `0 ${padding}px` }}>
        {renderListComponent}
      </div>
      {isAdmin && (
        <FooterPage>
          <Row justify="space-between">
            <Col></Col>
            <Col>
              <Button
                className="buttonPC button--outline --todo-- wAuto"
                onClick={() => history.goBack()}
              >
                <RollbackOutlined />
                {lang.BACK}
              </Button>
            </Col>
          </Row>
        </FooterPage>
      )}
    </>
  );
}

export default HomeForEndUser;
