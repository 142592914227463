/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Tooltip, Modal, Tag } from "antd";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/inventory";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import {
  getQuerySearch,
  isEmpty,
  MSG_CATCH,
  pushObject2Query,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classText from "../../models/control/text";
import List from "./list";
import classGrid from "../../models/control/grid";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";

export default function CategoryMaster_list() {
  const objSearch = getQuerySearch();
  const [grid, setGrid] = useState(new classGrid());
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(
    new classText(objSearch.category || "")
  );
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const columns = [
    {
      title: lang.CATEGORY_CODE,
      dataIndex: "categoryCode",
      width: "20%",
      align: "center",
    },
    {
      title: lang.CATEGORY_NAME,
      dataIndex: "categoryName",
      // align: "center",
      render: (txt, row) => {
        return (
          <>
            <Link to={"/CategoryUpdate/" + row.categoryCode}>{txt}</Link>
            {row.isNew && (
              <Tag color="magenta" className="ml10">
                {lang.NEW}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: lang.NOTE,
      dataIndex: "remarks",
      // align: "center",
      render: (txt) => {
        return <div className="white-space">{txt}</div>;
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 180,
      render: (row) => {
        let disabledEdit = false;
        let disabledDelete = false;
        if (row.haveItems) {
          disabledDelete = true;
        }
        //
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={disabledEdit} className="mr5 btnEdit">
                <Link
                  to={"/CategoryUpdate/" + row.categoryCode}
                  className="w100"
                >
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={disabledDelete}
                className="mr5 btnDelete"
                onClick={() => {
                  showMessageDeleteConfirm(row.categoryCode);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const onSearch = (offset = 0) => {
    setGrid((preV) => ({ ...preV, loading: true }));

    // Call
    return KBN.getCategories({
      category: isEmpty(category.value) ? null : category.value.trim(),
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            // Init, Click
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            // Load more
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
          pushObject2Query({
            category: category.value,
          });
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setGrid((preV) => ({ ...preV, loading: false }));
      });
  };
  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid((preV) => ({ ...preV, loading: false, hasMore: false }));
    } else {
      // load more
      onSearch(data.length);
    }
  };
  const clearInput = () => {
    setCategory({ ...category, value: null });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setLoading(true);

      Promise.all([onSearch()])
        .then((res) => {
          // Empty
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [allowRoute]);

  const showMessageDeleteConfirm = (id) => {
    // Action del
    const funcDel = async () => {
      setLoading(true);
      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        // Del
        await API.deleteCategory(id);
        // API Search
        await onSearch();
        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      setLoading(false);
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  return (
    <List
      inputs={[
        {
          name: lang.CATEGORY,
          get: category,
          type: KEY.TEXT,
          onChange: (e) => {
            setCategory({ ...category, value: e.target.value });
          },
        },
      ]}
      columns={columns}
      grid={grid}
      rowKey={"categoryCode"}
      actionSearch={() => {
        onSearch();
      }}
      loadMore={onLoadMore}
      clearInput={clearInput}
      linkAdd="/CategoryUpdate"
      loading={loading}
    />
  );
}
