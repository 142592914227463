/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Layout,
  Col,
  Row,
  Input,
  Spin,
  Divider,
  Button,
  DatePicker,
  Tooltip,
  Form,
  Empty,
  Typography,
} from "antd";
import { useMediaQuery } from "react-responsive";
// import { ReactComponent as ConfirmIcon } from "@src/styles/icon/comfirm.svg";
import InfiniteScroll from "react-infinite-scroller";
import TitlePage from "../../component/Desktop/titlePage";
import { Link, useLocation } from "react-router-dom";
import API from "../../api/backend/resetLastValue";
import Loading from "../../component/Desktop/loading/loading";
import LoadingInline from "../../component/Popup/loading-inline";
import { KEY, lang } from "../../constants/common.const";
import {
  MSG_CATCH,
  INPUT_DATE,
  enterSearch,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  statusRes,
} from "../../constants/utils";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import classGrid from "../../models/control/grid";
import { checkRoleScreen } from "../../utils/roles";
import { useSelector } from "react-redux";
const { Paragraph } = Typography;
const { Content } = Layout;
let F6 = true;
let objSearchOrigin = {
  checklistName: null,
  status: null,
  createDate: null,
};
let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function ResetLastValue_list({ history: historyPage }) {
  // Step1: Update param
  if (historyPage.action === "POP") {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }

  const [formSearch] = Form.useForm();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [stateData, setStateData] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Input
  const [txtChecklistName, setTxtChecklistName] = useState(
    getTxtValue(objSearch["checklistName"])
  );
  const [ddlStatus, setDdlStatus] = useState(getTxtValue(objSearch["status"]));
  const [txtCreateDate, setTxtCreateDate] = useState(
    getDateValue(objSearch["createDate"])
  );
  // Table
  const [grid, setGrid] = useState(new classGrid());

  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.checklistName = txtChecklistName ? txtChecklistName.trim() : "";
    objSearch.status = ddlStatus;
    objSearch.createDate = txtCreateDate;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = (offset = 0) => {
    const LOAD_INIT = offset === 0;
    // Loading
    if (LOAD_INIT) {
      openLoadingSpinner(); //Only init
    }
    setStateData({ ...stateData, loading: true });

    // input to objectSearch; push url
    LOAD_INIT && syncInput();
    // get param form url
    objSearch = getQuerySearch();
    // Update format: date, user
    objSearch.createDate = int2DateString(Number(objSearch.createDate));

    return API.getData({
      ...objSearch,

      isVisible: 0,
      limit: 20,
      offset: offset,
    })
      .then((res) => {
        if (statusRes(res)) {
          if (offset !== 0) {
            // Load more
            setGrid({ ...grid, data: [...grid.data, ...res.data.data] });
          } else {
            // Load init
            setGrid(res.data);
          }
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };
  const search_onClick_Default = () => {
    search_onClick();
  };
  // clearInputSearch
  const clearInputSearch = () => {
    setTxtChecklistName("");
    setTxtCreateDate("");
    setDdlStatus(null);
    formSearch.resetFields();
    formSearch.submit();
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      search_onClick();

      F6 = false;
    }
  }, []);
  // Update offset
  useEffect(() => {
    setStateData({
      ...stateData,
      offset: grid.data.length,
      hasMore: grid.data.length !== grid.count,
      loading: false,
    });
  }, [grid.data]);

  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick_Default();
  }, [txtChecklistName]);
  // Alway device
  useEffect(() => {
    search_onClick_Default();
  }, [ddlStatus, txtCreateDate]);

  ///// RENDER
  return (
    <div className="des-content">
      <TitlePage name={lang.SC_REST_LAST_VALUE} />
      <Content
        // className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          onFinish={search_onClick_Default}
        >
          <Row gutter={[8, isPC ? 0 : 16]}>
            <Col span={isPC ? 5 : 24}>
              <Input
                allowClear
                value={txtChecklistName}
                maxLength={255}
                placeholder="点検書式名"
                onChange={(e) => {
                  setTxtChecklistName(e.target.value);
                }}
                {...enterSearch(search_onClick_Default)}
              />
            </Col>
            {isPC && (
              <Col span="8">
                <Button
                  className="buttonPC button--info wAuto"
                  htmlType="submit"
                >
                  <SearchOutlined />
                  {lang.SEARCH}
                </Button>
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml10"
                  onClick={clearInputSearch}
                >
                  <ClearOutlined />
                  {lang.CLEAR}
                </Button>
              </Col>
            )}
          </Row>
          {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
          <Row gutter={[8, isPC ? 0 : 8]} className="align-center">
            {isPC && (
              <Col className="bold mr10">
                {lang.FILTER}
                {lang.COLON}
              </Col>
            )}
            <Col span={isPC ? 5 : 24}>
              <DatePicker
                format={KEY.DATE_DEFAULT}
                value={txtCreateDate}
                placeholder={"点検日時付"}
                className="w100"
                onChange={(v, dateString) => {
                  setTxtCreateDate(v);
                }}
                {...INPUT_DATE}
              />
            </Col>
          </Row>
        </Form>
      </Content>
      {/* New */}
      <Content
        className={isPC && "site-layout-background"}
        style={{
          margin: "24px 16px",
          padding: isPC ? 24 : 0,
        }}
      >
        {/* <div className="titleText2">
          <ConfirmIcon className="svg-default" /> {lang.LIST_CHECKLIST}
        </div> */}
        <Spin spinning={showLoading}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => {
              search_onClick(stateData.offset);
            }}
            hasMore={!stateData.loading && stateData.hasMore}
            useWindow={true}
          >
            <Row gutter={[20, 20]} style={{ minHeight: "200px" }}>
              {grid.data.length > 0 ? (
                <>
                  {grid.data.map((v, key) => {
                    return (
                      <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={key}>
                        <Link to={`/EditResetLastValue/${v.id}`}>
                          <div className="itemList-2 ">
                            <Row
                              justify="space-between"
                              className="align-center"
                            >
                              <Col className="id">ID: {v.id}</Col>
                              <Col
                                className="icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              ></Col>
                            </Row>
                            <div className="title">
                              <Paragraph ellipsis={{ rows: 2 }}>
                                <Tooltip title={v.checklistName}>
                                  {v.checklistName}
                                </Tooltip>
                              </Paragraph>
                            </div>
                            <div className="account">{v.createName}</div>
                            <Row justify="space-between" className="more">
                              <Col>{v.createDatetime}</Col>
                            </Row>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                  <Col span={24} className="content-center">
                    <LoadingInline isLoadingInline={stateData.loading} />
                  </Col>
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="w100" />
              )}
            </Row>
          </InfiniteScroll>
        </Spin>
      </Content>

      {/* Loader */}
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default ResetLastValue_list;
