import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import { useEffect } from "react";
import API from "@src/api/backend/checkList";
import ApiChart from "@src/api/backend/dynamicChart";
import Loading from "../Desktop/loading/loading";
import DynamicChart from "../DynamicChart/index2";
import { useMediaQuery } from "react-responsive";
import { Button, Col, Row, Select } from "antd";
import { renderOption, showMessage } from "../../constants/utils";
import { SaveFilled } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

function PopupItemChart({
  itemCodes,
  isPreUseChecklist,
  visible,
  type,
  checklistId,
  closeModal,
}) {
  switch (type) {
    case lang.DATE:
      type = 1;
      break;
    case lang.WEEK:
      type = 2;
      break;
    case lang.MONTH:
      type = 3;
      break;
    case lang.YEAR:
      type = 4;
      break;
    default:
      break;
  }
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  // detect rotate mobile screen horizontally or vertically
  const isPortrait = useMediaQuery({ query: KEY.PORTRAIT_MOBILE_WIDTH });
  const [dataTable, updateDataTable] = useState([]);
  const [isLoadingShowChart, updateIsLoadingShowChart] = useState(false);
  const [listSelectReport, setListSelectReport] = useState([]);
  useEffect(() => {
    if (visible) {
      updateData(itemCodes, type);
    } else {
      updateDataTable([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getListReportSelect = async () => {
    if (String(itemCodes) !== "") {
      if (String(itemCodes).length) {
        const arrCode = String(itemCodes).split(",");

        let resArr = await Promise.all(
          arrCode.map((item) => ApiChart.getReportForAddItem(item))
        );
        resArr = resArr.map((item) => {
          const { data } = item;
          const listOptions = data.map((dataItem) => ({
            key: dataItem.id,
            value: dataItem.name,
          }));
          return {
            value: null,
            options: listOptions,
          };
        });
        setListSelectReport(resArr);
      }
    }
  };
  useEffect(() => {
    getListReportSelect();
  }, [itemCodes]);

  const filterDuplicateOptions = (arr) => {
    const map = new Map();
    const newReportOption = [...arr];
    newReportOption.forEach((obj) => map.set(JSON.stringify(obj), obj));
    return Array.from(map.values());
  };
  const updateData = (itemCodes, type) => {
    updateIsLoadingShowChart(true);

    const arrCode = String(itemCodes).split(",");
    const promiseAll = arrCode.map((item) =>
      API.getDataItemChart(item, checklistId, type, isPreUseChecklist)
    );

    Promise.all(promiseAll)
      .then((allCharts) => {
        const allData = [];
        allCharts.forEach((eachChart, index) => {
          const dataItem = [];
          eachChart.data.forEach((itemUpdated) => {
            const dataChart = itemUpdated.data;
            const arrLabels = [];
            const arrData = [];
            let hasDataLabel = false;
            dataChart.forEach((line) => {
              // handle line data
              const arrLineValue = [];
              let hasDataValue = false;
              if (line.data.length) {
                const dataLine = line.data;
                dataLine.forEach((itemDayOfLine) => {
                  if (!hasDataLabel) {
                    let label;
                    if (
                      itemDayOfLine.groupTitle &&
                      Number(itemUpdated.type) !== 2
                    ) {
                      label = itemDayOfLine.groupTitle;
                    } else {
                      label = itemDayOfLine.title || "";
                    }
                    arrLabels.push(label);
                  }
                  if (itemDayOfLine.value !== null) {
                    hasDataValue = true;
                  }
                  arrLineValue.push(Number(itemDayOfLine.value) || null);
                });
                hasDataLabel = true;
              }
              const lineData = {
                name: line.checkItemName,
                data: hasDataValue ? arrLineValue : [],
              };
              arrData.push(lineData);
            });
            let isHasData = false;
            for (let i = 0; i < arrData.length; i++) {
              if (arrData[i].data.length) {
                isHasData = true;
                continue;
              }
            }
            const stringCheckItemName = arrData
              .map((item) => item.name)
              .join(",");
            dataItem.push({
              id: itemUpdated.id,
              itemCode: arrCode[index],
              checkItemName: itemUpdated.checkItemName || stringCheckItemName,
              labels: arrLabels,
              type: itemUpdated.type,
              machineName: itemUpdated.data[0].machineForSiteOfficeName,
              normalLowerControlValue: itemUpdated.normalLowerControlValue,
              normalUpperControlValue: itemUpdated.normalUpperControlValue,
              unit: itemUpdated.unit,
              min: itemUpdated.min,
              max: itemUpdated.max,
              series: arrData,
              categories: arrLabels,
              isHasData: isHasData,
              checklistType: itemUpdated.checklistType,
            });
          });
          allData.push(dataItem[0]);
        });
        updateDataTable(allData);
      })
      .finally(() => updateIsLoadingShowChart(false));
  };
  const updateItemChart = (itemUpdated) => {
    const arrData = dataTable.map((item) => {
      if (item.itemCode === itemUpdated.itemCode) {
        return { ...itemUpdated };
      } else {
        return item;
      }
    });
    updateDataTable(arrData);
  };

  const handleSetListSelectReport = (index, value) => {
    const newListSelect = [...listSelectReport];
    newListSelect[index].value = value;
    setListSelectReport(newListSelect);
  };
  const saveReportWithItem = (index) => {
    updateIsLoadingShowChart(true);
    ApiChart.saveReportWithItem(
      listSelectReport[index].value,
      itemCodes.split(",")[index]
    )
      .then((res) => {
        if (res.status === 200) {
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
          getListReportSelect();
        }
      })
      .finally(() => updateIsLoadingShowChart(false));
  };
  const renderTitle = () => {
    return (
      <Row
        className="rowNoMargin"
        justify="space-around"
        align="middle"
        gutter={[16, 0]}
      >
        <Col
          span={!isPC && 24}
          flex={isPC && "none"}
          style={!isPC && { paddingBottom: "8px" }}
        >
          {lang.DYNAMIC_CHART}
        </Col>
      </Row>
    );
  };
  return (
    <Modal
      centered
      width={isPC ? 800 : "100vw"}
      id="popup-chart"
      title={renderTitle()}
      visible={visible}
      footer={null}
      bodyStyle={{
        height: isPC ? "50vh" : isPortrait ? "80px" : "80vh",
        overflowY: "auto",
        background: "#f5f5f5",
        padding: 10,
      }}
      onCancel={() => closeModal(false)}
    >
      {isPortrait ? (
        <Row justify="center" className="text-mobile">
          画面を横向きに回転してください
        </Row>
      ) : (
        <>
          <Loading show={isLoadingShowChart}></Loading>
          {dataTable.length ? (
            dataTable.map((item, index) => {
              return (
                <Content
                  className="site-layout-background"
                  style={{
                    margin: "10px 0px",

                    padding: 16,
                  }}
                >
                  <Row
                    className="rowNoMargin"
                    justify="center"
                    align="middle"
                    gutter={[16, 0]}
                    // style={isPC && { width: "90%" }}
                  >
                    <Col flex="auto">
                      <Select
                        optionFilterProp="children"
                        showSearch
                        style={{ width: "100%" }}
                        value={
                          listSelectReport[index]
                            ? listSelectReport[index].value
                            : null
                        }
                        onChange={(value) =>
                          handleSetListSelectReport(index, value)
                        }
                      >
                        {renderOption(
                          listSelectReport[index]
                            ? listSelectReport[index].options
                            : [],
                          false
                        )}
                      </Select>
                    </Col>
                    <Col flex="none">
                      <Button
                        onClick={() => saveReportWithItem(index)}
                        icon={<SaveFilled />}
                        className="buttonPC button--info"
                        disabled={
                          !(
                            listSelectReport[index] &&
                            listSelectReport[index].value
                          )
                        }
                      >
                        {lang.SAVE_NORMAL}
                      </Button>
                    </Col>
                    <Col span={24}>
                      <DynamicChart
                        isPreUseChecklist={
                          isPreUseChecklist || item.checklistType
                        }
                        index={index}
                        item={item}
                        updateItem={(item) => updateItemChart(item)}
                        isPopup={true}
                      />
                    </Col>
                  </Row>
                </Content>
              );
            })
          ) : (
            <></>
          )}
          {!dataTable.length && !isLoadingShowChart ? (
            <p>{COMMON_CONSTANT.EMPTY_DATA}</p>
          ) : (
            <></>
          )}
        </>
      )}
    </Modal>
  );
}
export default PopupItemChart;
