import React from "react";
import { Table, Checkbox, Switch } from "antd";
import { getRange } from "../../constants/utils";

function GridCheckList({
  onChangeOrderRow,
  onCheckboxItem,
  columns,
  dataSource = [],
  dataMachine,
  onCheckAllItem,
  ddlInputContentOption,
  ...props
}) {
  // Format column
  const formatColumn = () => {
    return columns.map((e) => {
      if (e["render"]) {
        // Keep
      } else {
        e["render"] = (text, row, indexRow) => {
          let temp = "";
          switch (e.key) {
            // Swith
            case "switchTime":
              return (
                <Switch
                  checkedChildren="複数回"
                  unCheckedChildren="1回"
                  defaultChecked={text}
                  disabled
                />
              );

            // Order
            case "CheckOrder":
              const v = row.Order;
              let listO = dataSource.map((e, i) => {
                let order = e.Order;
                return (
                  <option value={order} key={order}>
                    {order}
                  </option>
                );
              });
              return (
                <div className="center">
                  <select
                    value={v}
                    onChange={(e) => {
                      onChangeOrderRow(e, row, dataSource);
                    }}
                  >
                    {listO}
                  </select>
                </div>
              );

            case "mode":
              text = text || "";
              if (text.indexOf("数値") !== -1 || text.indexOf("数字") !== -1) {
                temp = "数字";
              }
              if (text.indexOf("選択") !== -1) {
                temp = "選択";
              }
              if (text.indexOf("時間") !== -1 || text.indexOf("文字") !== -1) {
                temp = "文字";
              }
              return <div className="center">{temp}</div>;

            case "minMax":
              let low =
                row.NormalLowerControlValue || row.normalLowerControlValue;
              let up =
                row.NormalUpperControlValue || row.normalUpperControlValue;

              return <div className="center">{getRange(low, up)}</div>;

            // Checkbox
            case "checked":
              return (
                <div className="center">
                  <Checkbox
                    checked={text}
                    onChange={(e) => {
                      onCheckboxItem(e, row, dataMachine, indexRow);
                    }}
                  ></Checkbox>
                </div>
              );

            // Text
            default:
              return <div className="center">{text}</div>;
          }
        };
      }
      return e;
    });
  };

  return (
    <Table
      {...props}
      dataSource={dataSource}
      columns={formatColumn()}
      pagination={false}
    />
  );
} // Usage

export default GridCheckList;
