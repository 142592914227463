import { Col, Tabs, Row, Empty } from "antd";
import React, { useState, useLayoutEffect } from "react";
import TabsCustom from "@src/component/TabsComponent/TabsCustom";
import { DeleteOutlined } from "@ant-design/icons";
import { lang } from "../../constants/common.const";

const { TabPane } = Tabs;
const position = {
  TOP: "TOP",
  LEFT: "LEFT",
  ITEM: "ITEM",
};

function TabCheckList({
  panes,
  setPanes,
  activeKey,
  setActiveKey,
  onAddTab,
  onRemoveLeft,
  view = false,
  body,
}) {
  let [tabIndex, setTabIndex] = useState(panes[0].ID || 1);
  let [widthLeft, setWidthLeft] = useState([0, 0]);

  // Update size
  useLayoutEffect(() => {
    function updateSize() {
      setWidthLeft([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // change
  const changeOrder = (event, rowData, list, where, indexTab, indexTabLeft) => {
    let pageY = window.pageYOffset; // Get Y
    // let pageX = window.pageXOffset; // Get X

    const listNew = [...list];
    let indexNew = event.target.value - 1;
    let indexOld = rowData.Order - 1;

    // Remove
    listNew.splice(indexOld, 1);
    // Add
    listNew.splice(indexNew, 0, rowData);
    // Re-order
    listNew.forEach((el, i) => {
      el.Order = i + 1;
    });

    // TOP
    if (where === position.TOP) {
      setPanes(listNew);
      // setActiveKey(rowData.ID);
    }
    if (where === position.LEFT) {
      // LEFT
      panes[indexTab].Machines = listNew;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    if (where === position.ITEM) {
      // ITEM
      panes[indexTab].Machines[indexTabLeft].Items = listNew;
      let listNewP = [...panes];
      setPanes(listNewP);
    }
    // Re - Scroll
    setTimeout(() => {
      window.scrollTo(0, pageY);
    }, 0);
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  // RENDER - Tab button
  const renderTabTitle = (dataRow, isLeft, list, where, indexTab) => {
    //
    let groupNameShow = (name) => {
      return name.length > 10 ? name.substr(0, 10) + "..." : name;
    };

    // Show title when
    let title = () => {
      // Tab LEFT
      if (isLeft) {
        return <span title={dataRow.Name}>{dataRow.Name}</span>;
      }
      // Tab TOP
      let name = view ? dataRow.Name : groupNameShow(dataRow.Name);

      return (
        <div
          className="textOverflow"
          style={{
            maxWidth: "155px",
          }}
        >
          {name}
        </div>
      );
    };

    // Render tab
    return (
      <Row
        // justify="space-between"
        className="left"
      >
        <Col
          span={24}
          style={isLeft && { width: (widthLeft[0] / 100) * 13 + "px" }}
          className="textOverflow "
        >
          {!view && (
            <select
              className="d-inline-block"
              onChange={(event) => {
                changeOrder(event, dataRow, list, where, indexTab);
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
              }}
              value={dataRow.Order}
            >
              {list.map((el, i) => {
                // debugger;
                // if (++i === e.Order)
                // return (<option key={el.Order} value={el.Order} selected>{el.Order}</option>);
                return (
                  <option key={el.Order} value={el.Order}>
                    {el.Order}
                  </option>
                );
              })}
            </select>
          )}
          {title()}
        </Col>
      </Row>
    );
  };

  // REMOVE
  const remove = (key, data) => {
    let lastIndex;

    // 1. Get tab active
    data.forEach((e, i) => {
      if (e.ID === key) {
        // Pre-tab
        lastIndex = i - 1;
      }
    });
    // 2. Delete tab
    data = data.filter((e) => e.ID !== key);
    // 3. Re-Order tab
    data.forEach((e, i) => {
      e.Order = i + 1;
    });

    return {
      index: lastIndex < 0 ? 0 : lastIndex,
      data: data,
    };
  };

  // TOP ------------ CRUD
  // Active tab
  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  // Update tab
  const onEdit = (key, action) => {
    if (action === "add") {
      add();
    }
    // else {
    //     // REMOVE
    //     debugger;
    //     let obj = remove(key, panes);
    //     setPanes(obj.data);
    //     setActiveKey(obj.data[obj.index].ID);
    // }
  };
  // ADD
  const add = () => {
    tabIndex = ++tabIndex;
    setTabIndex(tabIndex);
    onAddTab(tabIndex); // Add to Group tab
    setActiveKey(tabIndex);
  };

  // LEFT ------------- CRUD
  const delTabLeft = (idRow, idParent, leftData) => {
    // REMOVE
    let obj = remove(idRow, leftData);

    const newP = panes.map((e) => {
      if (e.ID === idParent) {
        e.Machines = obj.data;
      }
      return e;
    });
    setPanes(newP);
  };
  // Update tab
  const onEditLeft = (key, action) => {
    // Get tab
    let idParent;
    let rowData;
    let leftData;
    panes.forEach((e) => {
      e.Machines &&
        e.Machines.length > 0 &&
        e.Machines.forEach((e2) => {
          if (String(e.ID + "" + e2.ID) === String(key)) {
            rowData = e2;
            idParent = e.ID;
            leftData = e.Machines;
          }
        });
    });

    if (action === "add") {
    } else {
      onRemoveLeft(delTabLeft, rowData, idParent, leftData);
    }
  };

  // Render------ Tab LEFT
  const renderTabLeft = (subData, indexTabTop, idTabTop) => {
    return (
      <Tabs
        style={{ height: "568px" }}
        tabPosition="left"
        className="tbl-left-createCL"
        // defaultActiveKey={activeKeyLeft}
        hideAdd={true}
        onEdit={onEditLeft}
        tabBarExtraContent={{
          left: <div className="titleTabLeft">{lang.MACHINES}</div>,
        }}
      >
        {subData.map((machine, indexTabLeft) => {
          return (
            <TabPane
              tab={renderTabTitle(
                machine,
                true,
                subData,
                position.LEFT,
                indexTabTop
              )}
              // key={idTabTop + "" + machine.ID}
              key={idTabTop + "" + machine.ID + "" + indexTabLeft}
              closeIcon={<DeleteOutlined style={{ color: "red" }} />}
            >
              {machine.Items ? (
                body(machine)
              ) : (
                <div className="center mt20">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </TabPane>
          );
        })}
      </Tabs>
    );
  };

  // Render Tab TOP
  return (
    <TabsCustom
      style={{ minHeight: "600px" }}
      //   className="tbl-view"
      onChange={onChange}
      activeKey={activeKey + ""}
      onEdit={onEdit}
      type="card"
      hideAdd={view}
    >
      {panes.map((pane, indexTab) => {
        return (
          <TabPane
            tab={renderTabTitle(pane, false, panes, position.TOP, indexTab)}
            // key={pane.ID}
            key={view ? pane.ID + "" + indexTab : pane.ID}
            closable={false}
          >
            {pane.Machines && pane.Machines.length > 0 ? (
              renderTabLeft(pane.Machines, indexTab, pane.ID)
            ) : (
              <div className="center mt20">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </TabPane>
        );
      })}
    </TabsCustom>
  );
}

export default TabCheckList;
