/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Form,
  DatePicker,
  Select,
  Layout,
  Modal,
  Typography,
  Spin,
  Tooltip,
  Checkbox,
  Empty,
  Input,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import LoadingInline from "../../component/Popup/loading-inline";

import { lang, COMMON_CONSTANT, KEY } from "../../constants/common.const";
import StatusConst from "../../constants/status.const";
import { useMediaQuery } from "react-responsive";
import TitlePage from "../../component/Desktop/titlePage";
import AutoMail from "../../component/Desktop/autoMail";
// import { ReactComponent as ConfirmIcon } from "@src/styles/icon/comfirm.svg";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import API from "../../api/backend/historyCL";
import {
  MSG_CATCH,
  statusRes,
  INPUT_DATE,
  renderOption,
  enterSearch,
  showMessage,
} from "../../constants/utils";
import moment from "moment";
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;
const objColorStatus = {};
const { Paragraph } = Typography;

function HistoryCL_List({
  screen = true,
  setDataSelected,
  idSelect,
  setIdSelect,
  ...other
}) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const [stateData, setStateData] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  // Loading
  const [showLoading, setShowLoading] = useState(true);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  // useEffect(() => {
  // });
  // Create object status color
  StatusConst.STATUS_LIST.forEach((v) => {
    objColorStatus[v.key] = v.color;
    objColorStatus[v.value] = v.color;
  });

  const [formSearch] = Form.useForm();
  //  Grid
  const [initPage, setInitPage] = useState(true);
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  const [txtChecklistName, setTxtChecklistName] = useState(new classText());
  const [ddlChecklistName, setDdlChecklistName] = useState(new classDdl());
  const [txtInspector, setTxtInspector] = useState(new classText());
  const [ddlStatus, setDdlStatus] = useState(new classDdl());
  const [txtCheckStartDate, setTxtCheckStartDate] = useState(new classText());
  const [txtCheckEndDate, setTxtCheckEndDate] = useState(new classText());

  // Search
  const search_onClick = (init, offset = 0) => {
    // Loading
    if (offset === 0) {
      openLoadingSpinner(); //Only init
    }
    setStateData({ ...stateData, loading: true });

    // Re-value
    let users = txtInspector.list.filter((v) => {
      return !v.error;
    });
    users = users.map((v) => {
      return v.value;
    });

    // Call
    return API.searchHistory({
      params: {
        // checklistId:
        //   ddlChecklistName.value !== null
        //     ? ddlChecklistName.value.trim()
        //     : null,
        checklistName:
          txtChecklistName.value && txtChecklistName.value.length > 0
            ? txtChecklistName.value.trim()
            : null,
        // inspector: users.length > 0 ? users[0].value : null,
        inspectors: users.length > 0 ? users.toString() : null,
        status: ddlStatus.value,
        startDate: txtCheckStartDate.value
          ? moment(txtCheckStartDate.value).format("YYYY/MM/DD")
          : "",
        endDate: txtCheckEndDate.value
          ? moment(txtCheckEndDate.value).format("YYYY/MM/DD")
          : "",
        limit: 20,
        offset: offset,
      },
    })
      .then((res) => {
        if (statusRes(res)) {
          if (offset !== 0) {
            // Load more
            setGrid({ ...grid, data: [...grid.data, ...res.data.data] });
          } else {
            // Load init
            setGrid(res.data);
          }
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        init !== true && showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        setTimeout(closeLoadingSpinner, 200);
      });
  };

  const onDelete = (rowData) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        await API.deleteHistory(rowData.id);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    // Show popup
    Modal.confirm({
      centered: true,
      title: lang.WARMING,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.DELETE,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  const onSelect = (checked, index) => {
    setIdSelect(checked ? grid.data[index].id : null);
    setDataSelected(checked ? grid.data[index] : null);
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Init
  useEffect(() => {
    // Open loading
    screen && openLoadingSpinner();

    let checklist,
      status = [];
    // Load pulldown
    Promise.all(
      [API.getDdlChecklist(), API.getDdlStatus()],
      search_onClick(true)
    )
      .then((res) => {
        checklist = res[0] ? res[0].data.data : [];
        status = res[1] ? res[1].data.data : [];
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Set pulldown
        setDdlChecklistName({ ...ddlChecklistName, options: checklist });
        setDdlStatus({ ...ddlStatus, options: status });
        // Close loading
        screen && closeLoadingSpinner();
      });
  }, []);

  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick();
  }, [txtChecklistName.value]);
  // Alway device
  useEffect(() => {
    !initPage && search_onClick();
  }, [
    txtCheckEndDate.value,
    txtCheckStartDate.value,
    ddlStatus.value,
    txtInspector.list,
  ]);
  // Update offset
  useEffect(() => {
    setStateData({
      ...stateData,
      offset: grid.data.length,
      hasMore: grid.data.length !== grid.count,
      loading: false,
    });
  }, [grid.data]);

  // Render
  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={() => {
        search_onClick(false, stateData.offset);
      }}
      hasMore={!stateData.loading && stateData.hasMore}
      useWindow={screen}
    >
      <div className={screen ? "des-content" : ""}>
        {screen && <TitlePage name={lang.HISTORY_CHECKLIST} />}
        {/* Group input */}
        <Content
          style={
            screen
              ? {
                  margin: "24px 16px",
                  padding: 0,
                }
              : {
                  margin: "16px",
                  padding: 0,
                }
          }
        >
          <Form
            layout={"vertical"}
            name="searchCL"
            className="formStyle"
            form={formSearch}
          >
            <Row gutter={[8, isPC ? 0 : 16]}>
              <Col span={isPC ? 5 : 24}>
                <Form.Item
                  name="ddlChecklistName"
                  hidden={true}
                  label=""
                  className="mb0"
                >
                  <Select
                    value={ddlChecklistName.value}
                    placeholder="点検表名"
                    onChange={(v) => {
                      setDdlChecklistName({
                        ...ddlChecklistName,
                        value: v,
                      });
                    }}
                    {...enterSearch(search_onClick)}
                  >
                    {renderOption(ddlChecklistName.options)}
                  </Select>
                </Form.Item>
                <Form.Item name="txtChecklistName" label="" className="mb0">
                  <Input
                    value={txtChecklistName.value}
                    placeholder="点検表名"
                    maxLength={255}
                    onChange={(e) => {
                      setTxtChecklistName({
                        ...txtChecklistName,
                        value: e.target.value,
                      });
                    }}
                    {...enterSearch(search_onClick)}
                  />
                </Form.Item>
              </Col>
              {isPC && (
                <Col span="5">
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={search_onClick}
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
              )}
            </Row>
            {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
            <Row gutter={[8, isPC ? 0 : 8]} className="align-center">
              {isPC && (
                <Col className="bold mr10">
                  {lang.FILTER}
                  {lang.COLON}
                </Col>
              )}
              <Col span={isPC ? 5 : 12}>
                <Form.Item name="ddlStatus" label="" className="mb0">
                  <Select
                    placeholder={lang.STATUS}
                    value={ddlStatus.value}
                    onChange={(v) => {
                      setDdlStatus({ ...ddlStatus, value: v });
                    }}
                    {...enterSearch(search_onClick)}
                  >
                    {renderOption(ddlStatus.options)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isPC ? 5 : 12}>
                {/* 2 auto picker */}
                <Form.Item name="txtInspector" label="" className="mb0">
                  <AutoMail
                    get={txtInspector}
                    set={setTxtInspector}
                    placeholder="点検者"
                  ></AutoMail>
                </Form.Item>
              </Col>
              <Col span={isPC ? 5 : 12}>
                {/* 4 */}
                <Form.Item name="txtCheckStartDate" label="" className="mb0">
                  <DatePicker
                    format={KEY.DATE_DEFAULT}
                    value={txtCheckStartDate.value}
                    placeholder={"点検日付から"}
                    className="w100"
                    onChange={(v, dateString) => {
                      setTxtCheckStartDate({
                        ...txtCheckStartDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Form.Item>
              </Col>
              <Col span={isPC ? 5 : 12}>
                {/* 4 */}
                <Form.Item name="txtCheckEndDate" label="" className="mb0">
                  <DatePicker
                    format={KEY.DATE_DEFAULT}
                    value={txtCheckEndDate.value}
                    placeholder={"点検日付に"}
                    className="w100"
                    onChange={(v, dateString) => {
                      setTxtCheckEndDate({ ...txtCheckEndDate, value: v });
                    }}
                    {...INPUT_DATE}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
        {/* Table data */}
        <Content
          className={isPC ? "site-layout-background" : ""}
          style={
            screen
              ? {
                  margin: "24px 16px",
                  padding: isPC ? 24 : 0,
                }
              : { margin: "0 16px" }
          }
        >
          {/* <div className="titleText2">
          <ConfirmIcon className="svg-default" /> {lang.HISTORY_CHECKLIST}
        </div> */}
          <Spin spinning={showLoading}>
            <Row gutter={[20, 20]} style={{ minHeight: "200px" }}>
              {grid.data.length > 0 ? (
                <>
                  {grid.data.map((v, key) => {
                    return (
                      <Col xs={24} sm={24} md={12} lg={8} xxl={6} key={key}>
                        <div
                          className="itemList-2"
                          onClick={() => {
                            !screen && onSelect(!v.checked, key);
                          }}
                        >
                          <Row justify="space-between" className="align-center">
                            <Col className="id">ID: {v.id}</Col>
                            {screen ? (
                              <Col className="icon">
                                {v.status !== "承認済" && (
                                  <Button
                                    type="link"
                                    className="iconAction-delete btnLink"
                                    onClick={(e) => {
                                      onDelete(v);
                                      e.preventDefault();
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                )}
                              </Col>
                            ) : (
                              <Col className="created">
                                <Checkbox
                                  checked={v.id === idSelect}
                                  onChange={(e) => {
                                    onSelect(e.target.checked, key);
                                  }}
                                ></Checkbox>
                              </Col>
                            )}
                          </Row>
                          <div className="title">
                            <Paragraph ellipsis={{ rows: 2 }}>
                              <Tooltip title={v.checklistName}>
                                {v.checklistName}
                              </Tooltip>
                            </Paragraph>
                          </div>
                          <div className="account">{v.inspectorName}</div>
                          <Row justify="space-between" className="more">
                            <Col>{v.checkDate}</Col>
                            <Col>
                              {/* {v.status} */}

                              <Paragraph
                                ellipsis={{ rows: 1 }}
                                className="textStatus"
                                style={{
                                  color: objColorStatus[v.status],
                                  background: objColorStatus[v.status] + "1A",
                                }}
                              >
                                {v.status}
                              </Paragraph>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                  <Col span={24} className="content-center">
                    <LoadingInline isLoadingInline={stateData.loading} />
                  </Col>
                </>
              ) : (
                !initPage && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    className="w100"
                  />
                )
              )}
            </Row>
          </Spin>
        </Content>
      </div>
    </InfiniteScroll>
  );
}
export default HistoryCL_List;
