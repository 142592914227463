import React, { useState, useEffect } from "react";
import "../inspection.scss";
import { Row, Col, Typography, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { createTempChecklist, selectChecklist } from "@src/redux/checkList";
import { Link, useHistory } from "react-router-dom";
import { fromUnixTime } from "date-fns";
import format from "@src/utils/format";
import LoadingInline from "@src/component/Popup/loading-inline";
import { useMediaQuery } from "react-responsive";
import BookmarkIcon from "@src/styles/icon/bookmark.svg";
import ResultIcon from "@src/styles/icon/result.svg";
import { lang, KEY, COMMON_CONSTANT } from "@src/constants/common.const";
const { Paragraph } = Typography;
function ChecklistGroup({ title, data, type, isLoading }) {
  let [dataChecklist, updateDataChecklist] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  useEffect(() => {
    updateDataChecklist(data);
  }, [data]);
  const goToDetail = (obj) => {
    if (type === "bookMark") {
      dispatch(createTempChecklist(obj, history));
    } else {
      dispatch(selectChecklist(obj, history));
    }
  };
  return (
    <div className={isDesktopOrLaptop ? "card-detail" : ""}>
      <Row align="middle" gutter={[8, 16]}>
        <Col span={12} className="titleText">
          <Row className="rowNotMargin" align="middle" gutter={[8, 0]}>
            <Col flex="none">
              {type === "bookMark" ? (
                <img src={BookmarkIcon} alt="bookmark" />
              ) : (
                <img
                  style={{ paddingBottom: "2px" }}
                  src={ResultIcon}
                  alt="result"
                />
              )}
            </Col>
            <Col flex="none">{title}</Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" className="view-all">
            {type === "bookMark" ? (
              <Link to="/checklist-list">
                {dataChecklist.length ? lang.SEE_ALL : ""}
              </Link>
            ) : (
              <Link to="/checklist-result">
                {dataChecklist.length ? lang.SEE_ALL : ""}
              </Link>
            )}
          </Row>
        </Col>
        <Col span={24} className="text-center  pt0 pl0 pr0 pb0">
          <LoadingInline isLoadingInline={isLoading} />
        </Col>
      </Row>
      <Row
        gutter={[20, 20]}
        className={
          isDesktopOrLaptop ? " listCheck" : " listCheck listCheck__mobile"
        }
        style={!isDesktopOrLaptop ? { flexWrap: "nowrap" } : {}}
      >
        {!isLoading && data.length ? (
          data.map((dataItem, index) => (
            <Col
              span={
                isDesktopOrLaptop ? (type === "bookMark" ? "12" : "8") : "18"
              }
              key={index}
            >
              <div
                id="animation"
                // align="middle"
                // justify="center"
                className={`itemList itemList__${type} itemList__grid__${type}`}
                onClick={() => goToDetail(dataItem)}
              >
                {type === "bookMark" && (
                  <div className="id">
                    {lang.ID} {dataItem.id}
                  </div>
                )}
                <Paragraph ellipsis={{ rows: 2 }} className="title">
                  <Tooltip title={dataItem.checklistName}>
                    {dataItem.checklistName}
                  </Tooltip>
                </Paragraph>
                {type === "notBookMark" ? (
                  <>
                    <div span={24} className="status">
                      <Paragraph
                        ellipsis={{ rows: 1 }}
                        className="textStatus"
                        style={{
                          color: dataItem.color,
                          background: dataItem.color + "1A",
                        }}
                      >
                        {dataItem.statusValue}
                      </Paragraph>
                    </div>
                    <div className="created" span={24}>
                      {format(
                        fromUnixTime(Number(dataItem.checkDate)),
                        KEY.DATE_WITHOUT_HOUR
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          ))
        ) : !isLoading && !data.length ? (
          <Row className="rowNotMargin" align="middle" justify="center" style={{paddingTop: '8px'}}>
            {COMMON_CONSTANT.EMPTY_DATA}
          </Row>
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
}

export default ChecklistGroup;
