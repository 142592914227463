import {
  CloseCircleOutlined,
  DeleteOutlined,
  InfoCircleFilled,
  PlusOutlined,
  SaveOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import { ArchiveTick } from "iconsax-react";

import BoxContent from "@src/component/Desktop/content";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ApiCalibration } from "../../api/backend/calibration";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import FooterPage from "../../component/footer/footerPage";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import { MSG_CATCH, renderOption, showMessage } from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import { checkRoleScreen } from "../../utils/roles";
import "./Calibration.scss";
import classPopup from "../../models/control/popup";
import { isNumber } from "lodash";
import Text from "antd/lib/typography/Text";

const { Panel } = Collapse;

function CalibrationAdd(props) {
  const history = useHistory();
  const machineNameRef = useRef();
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const fileEl = useRef(null);

  const { id } = useParams();
  const [showLoading, setShowLoading] = useState(false);
  const [details, setDetails] = useState(
    !id
      ? [
          {
            key: Date.now(),
            checkItemName: new classText(),
            value: new classText(),
            standardValue: new classText(),
            remarks: new classText(),
            nextCalibrationDate: new classText(),
            attachments: [],
          },
        ]
      : []
  );
  const [data, setData] = useState({
    machineForSiteOfficeName: new classText(),
    owner: new classDdl(""),
    manufactureName: new classText(),
    rating: new classText(),
    modelNumber: new classText(),
    sn: new classText(),
    firstRegistDate: new classText(),
    calibrationCertNumber: new classText(),
    calibrationCycle: new classText(),
    remarks: new classText(),
  });

  // PopUp attach
  const [popupAttach, setPopupAttach] = useState(new classPopup());
  const [modal_attach_data, set_modal_attach_data] = useState([]);

  // Onchange Field
  const handleChangeInput =
    (key, type = null, require = false) =>
    (eV) => {
      const newValue = type === "input" ? eV.target.value : eV;
      setData({
        ...data,
        [key]: {
          ...data[key],
          value: newValue,
          error: require
            ? type === "input"
              ? !newValue.trim()
              : !newValue
            : false,
        },
      });
    };

  // columns add
  const columns = [
    {
      title: " ",
      dataIndex: "delete",
      width: "5%",
      render: (_, row) => {
        return (
          <Button
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRow(row.key)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    {
      title: "管理No",
      width: "70px",
      render: (_, _2, index) => {
        return <div className="center">{++index}</div>;
      },
    },
    {
      title: (
        <span>
          {lang.CALIBRATION_RECORD_ITEMS}
          <label className="blod  mark-required">*</label>
        </span>
      ),
      dataIndex: "checkItemName",
      width: "20%",
      align: "center",
      render: (_, row) => {
        return (
          <Input
            value={row.checkItemName.value}
            className={row.checkItemName.error ? STYLE.BORDER_RED : ""}
            onChange={onChangeInputField(row.key, "checkItemName")}
          />
        );
      },
    },
    {
      title: <span>{lang.VALUE}</span>,
      dataIndex: "value",
      width: "15%",
      align: "center",

      render: (_, row) => {
        return (
          <Input
            value={row.value.value}
            onChange={onChangeInputField(row.key, "value")}
          />
        );
      },
    },
    {
      title: <span>{lang.STANDARD_VALUE}</span>,
      dataIndex: "standardValue",
      width: "15%",
      align: "center",

      render: (_, row) => {
        return (
          <Input
            value={row.standardValue.value}
            onChange={onChangeInputField(row.key, "standardValue")}
          />
        );
      },
    },

    {
      title: <span>{lang.NEXT_CALIBRATION_DATE}</span>,
      dataIndex: "nextCalibrationDate",
      width: "20%",
      align: "center",

      render: (_, row) => {
        return (
          <DatePicker
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
            placeholder={""}
            value={row.nextCalibrationDate.value}
            onChange={onChangeInputField(
              row.key,
              "nextCalibrationDate",
              "date"
            )}
          />
        );
      },
    },

    {
      title: <span>{lang.REMARK}</span>,
      dataIndex: "remarks",
      width: "20%",
      align: "start",

      render: (_, row) => {
        return (
          <TextArea
            value={row.remarks.value}
            rows={1}
            onChange={onChangeInputField(row.key, "remarks")}
          />
        );
      },
    },
    {
      title: lang.ATTACH,
      dataIndex: "listAttachment",
      key: "listAttachment",
      width: "10%",
      align: "center",
      render: (listAttachment, row, index) => (
        <Button
          className="buttonPC button--outline --todo-- wAuto"
          onClick={() => {
            setPopupAttach({ ...popupAttach, show: row.key });
          }}
        >
          {row.attachments.length} <PaperClipOutlined className="orange " />
        </Button>
      ),
    },
  ];

  // Column Attach
  const columnsAttach = [
    {
      title: "No",
      render: (_, _2, index) => {
        return <div className="center">{++index}</div>;
      },
    },
    {
      title: "プレビュー",
      dataIndex: "attachFileUrl",
      render: (url, row) => {
        return (
          <div className="center">
            <a href={url} title={row.attachFileName}>
              <PaperClipOutlined className="blue iconM" />
            </a>
          </div>
        );
      },
    },
    {
      title: "ファイル名",
      dataIndex: "attachFileName",
      render: (text) => {
        return <Text>{text}</Text>;
      },
    },
    {
      title: "削除",
      align: "center",
      render: (_, row) => {
        return (
          <DeleteOutlined
            className="iconM red"
            onClick={() => {
              const list = [...modal_attach_data].filter(
                (item) => item.attachFileUrl !== row.attachFileUrl
              );
              const newDetail = [...details];
              const this_details = details.find(
                (x) => x.key === popupAttach.show
              );
              this_details.attachments = list;

              setDetails(newDetail);
            }}
          />
        );
      },
    },
  ];

  const handleDeleteRow = (key) => {
    setDetails(details.filter((item) => item.key !== key));
  };

  // Add row
  const handleAddRow = () => {
    const newRow = {
      key: Date.now(),
      checkItemName: new classText(),
      value: new classText(),
      standardValue: new classText(),
      remarks: new classText(),
      nextCalibrationDate: new classText(),
      attachments: [],
    };
    setDetails([...details, newRow]);
  };

  // Handle onChange Field input
  const onChangeInputField =
    (key, field, type = "input") =>
    (eV) => {
      const newValue = type === "input" ? eV.target.value : eV;
      const indexRow = details.findIndex((item) => item.key === key);
      if (indexRow >= 0) {
        const newData = [...details];
        newData[indexRow][field].value = newValue;
        newData[indexRow][field].error =
          type === "input" ? !newValue.trim() : !newValue;
        setDetails(newData);
      }
    };
  // Valiadate
  const validate = () => {
    let flagError = false;
    const checkdetails = details.map((item) => {
      if (!item["checkItemName"].value.trim()) {
        flagError = true;
        item["checkItemName"].error = true;
      }
      return item;
    });
    setDetails(checkdetails);

    // Validate machinename
    if (!data.machineForSiteOfficeName.value.trim()) {
      flagError = true;
      setData({
        ...data,
        machineForSiteOfficeName: {
          ...data.machineForSiteOfficeName,
          error: true,
        },
      });
      machineNameRef.current.focus();
    }
    return !flagError;
  };

  // Post Data
  const postCalibration = async () => {
    const dataPost = {};
    for (let key in data) {
      dataPost[key] = data[key].value;
    }
    dataPost["firstRegistDate"] = dataPost["firstRegistDate"]
      ? moment(dataPost["firstRegistDate"]).format(KEY.DATE_DEFAULT)
      : "";
    const currentOwner = data.owner.options.find(
      (item) => item.key === data.owner.value
    );
    dataPost["ownerValue"] = currentOwner ? currentOwner.value : "";
    dataPost.details = details.map(({ key, ...others }) => {
      const valueData = {};
      for (let key in others) {
        if (key === "nextCalibrationDate") {
          valueData[key] = others[key].value
            ? moment(others[key].value).format(KEY.DATE_DEFAULT)
            : "";
        } else if (Array.isArray(others[key])) {
          valueData[key] = others[key];
        } else {
          valueData[key] = others[key].value;
        }
      }
      return valueData;
    });
    try {
      setShowLoading(true);
      !id
        ? await ApiCalibration.createCalibration(dataPost)
        : await ApiCalibration.updateCalibration(id, dataPost);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => history.goBack());
    } catch (error) {
      let msg;
      if (error.response && error.response.status === 400) {
        msg =
          "点検の名前は既にシステムに存在しています。再度ご検証しください。";
      }
      showMessage(KEY.ERROR, msg || MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  // Save data
  const onSave = () => {
    if (!validate()) {
      return;
    }
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: postCalibration,
    });
  };

  // Init Data
  const getInitData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [dataDetail, listMachineOwner] = await Promise.all([
        id ? ApiCalibration.getDetailCalibraion(id) : null,
        ApiCalibration.getMachineOwner(),
      ]);

      // Trang Edit
      if (id) {
        let {
          id,
          details: detailsArr,
          ownerValue,
          owner,
          firstRegistDate,
          ...inputField
        } = dataDetail.data;

        // Set cac truong la input
        let dataInputInputField = {};
        for (let key in inputField) {
          dataInputInputField[key] = { ...data[key], value: inputField[key] };
        }
        setData({
          ...data,
          owner: {
            ...data.owner,
            options: listMachineOwner.data.data,
            value: owner,
          },
          firstRegistDate: {
            ...data.firstRegistDate,
            value: firstRegistDate ? moment(firstRegistDate) : null,
          },
          ...dataInputInputField,
        });

        // Set data table detail
        if (detailsArr.length) {
          detailsArr = detailsArr
            .map((detailItem) => {
              for (let key in detailItem) {
                if (key === "nextCalibrationDate") {
                  detailItem[key] = new classText(
                    detailItem[key] ? moment(detailItem[key]) : null
                  );
                } else if (!Array.isArray(detailItem[key])) {
                  detailItem[key] = new classText(detailItem[key]);
                } else {
                  // Empty
                }
              }
              return detailItem;
            })
            .map((item, index) => ({ ...item, key: index + 1 }));
          setDetails(detailsArr);
        }
      } else {
        setData({
          ...data,
          owner: {
            ...data.owner,
            options: listMachineOwner.data.data,
          },
        });
      }
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
      history.push("/CalibrationManagement");
    } finally {
      setShowLoading(false);
    }
  };

  // upload attach
  async function readAttachFileUrl(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    const body = {
      attachFileName: file.name,
      type: 3,
    };
    formData.append("data", JSON.stringify(body));
    formData.append("file", file);
    const fileRes = await ApiCalibration.uploadFile(formData);

    const newDetails = [...details];
    const current_detail_item = newDetails.find(
      (x) => x.key === popupAttach.show
    );
    current_detail_item.attachments.push({
      attachFileUrl: fileRes.data.url,
      attachFileName: file.name,
    });

    setDetails(newDetails);
    fileEl.current.value = null;
  }
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      getInitData();
    }
  }, [id, allowRoute]);

  useEffect(() => {
    if (!isNumber(popupAttach.show)) {
      return;
    }
    const current_details = details.find((x) => x.key === popupAttach.show);
    if (current_details) {
      set_modal_attach_data([...current_details.attachments]);
    }
  }, [details, popupAttach.show]);
  return (
    <div className="des-content">
      <TitlePage name={id ? lang.EDIT_DEVICE : lang.ADD_NEW_DEVICE} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          {id ? lang.EDIT_DEVICE : lang.ADD_NEW_DEVICE}
        </h1>
        <Row>
          <Col lg={24} md={24} xs={24}>
            <BoxContent style={{ padding: "8px", margin: "8px" }}>
              <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                <Panel
                  header={
                    <>
                      <InfoCircleFilled className="first-icon" /> 計測機器情報
                    </>
                  }
                  key="1"
                >
                  <Descriptions
                    size="small"
                    column={2}
                    bordered
                    style={{ maxHeight: "720px", overflow: "auto" }}
                  >
                    <Descriptions.Item
                      span={2}
                      label={
                        <>
                          {lang.INSTRUMENT_NAME}
                          <label className="blod ml5 mark-required">*</label>
                        </>
                      }
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        ref={machineNameRef}
                        maxLength={255}
                        className={
                          data.machineForSiteOfficeName.error
                            ? STYLE.BORDER_RED
                            : ""
                        }
                        value={data.machineForSiteOfficeName.value}
                        onChange={handleChangeInput(
                          "machineForSiteOfficeName",
                          "input",
                          true
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={1}
                      label={<>{lang.OWNER}</>}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Select
                        value={data.owner.value}
                        className={
                          (data.owner.error ? STYLE.BORDER_RED : "") + " w100"
                        }
                        onChange={handleChangeInput("owner", "select")}
                      >
                        {renderOption(data.owner.options, false)}
                      </Select>
                    </Descriptions.Item>

                    <Descriptions.Item
                      span={1}
                      label={<>{lang.MANUFACTURE_NAME}</>}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        maxLength={255}
                        value={data.manufactureName.value}
                        onChange={handleChangeInput("manufactureName", "input")}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={1}
                      label={<>{lang.RATING}</>}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        maxLength={255}
                        value={data.rating.value}
                        onChange={handleChangeInput("rating", "input")}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={1}
                      label={lang.MODEL_NUMBER}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        maxLength={255}
                        value={data.modelNumber.value}
                        onChange={handleChangeInput("modelNumber", "input")}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={1}
                      label={lang.SN}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        maxLength={255}
                        value={data.sn.value}
                        onChange={handleChangeInput("sn", "input")}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item
                      span={1}
                      label={lang.FIRST_REGIST_DATE}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <DatePicker
                        value={data.firstRegistDate.value}
                        className="w100"
                        placeholder={""}
                        onChange={handleChangeInput("firstRegistDate", "date")}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item
                      span={1}
                      label={lang.CALIBRATION_CERT_NUMBER}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        maxLength={255}
                        value={data.calibrationCertNumber.value}
                        onChange={handleChangeInput(
                          "calibrationCertNumber",
                          "input"
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={1}
                      label={lang.CALIBRATION_CYCLE}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "8px 8px",
                      }}
                      contentStyle={{ padding: "8px 8px" }}
                    >
                      <Input
                        maxLength={255}
                        value={data.calibrationCycle.value}
                        onChange={handleChangeInput(
                          "calibrationCycle",
                          "input"
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      label={lang.REMARK}
                      labelStyle={{
                        width: "15%",
                        fontWeight: "bold",
                        padding: "4px 4px",
                      }}
                      contentStyle={{ padding: "4px 4px" }}
                    >
                      <TextArea
                        rows={3}
                        maxLength={255}
                        value={data.remarks.value}
                        onChange={handleChangeInput("remarks", "input")}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </BoxContent>
          </Col>
          <Col span={24}>
            {/* <BoxContent>{renderListInput()}</BoxContent> */}
            <BoxContent style={{ padding: "8px", margin: "8px" }}>
              <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                <Panel
                  header={
                    <>
                      <ArchiveTick className="first-icon" variant="Bold" />
                      {lang.CALIBRATION_RECORD_ITEM_LIST}
                    </>
                  }
                  key="1"
                >
                  {details.length > 0 && (
                    <Table
                      locale={{
                        emptyText: "",
                      }}
                      pagination={false}
                      dataSource={details}
                      className="tableAddMachine"
                      columns={columns}
                    />
                  )}
                  <Button
                    style={{
                      background: "#f7f9fc",
                      width: "auto",
                      minWidth: "auto",
                    }}
                    className="pr10 pl10 mt4 mb4 ml10"
                    onClick={() => handleAddRow()}
                    icon={<PlusOutlined />}
                  ></Button>
                </Panel>
              </Collapse>
            </BoxContent>
          </Col>
        </Row>
      </Content>
      <FooterPage>
        <Row justify="space-between">
          <Col></Col>
          <Col>
            <Button
              onClick={() => onSave()}
              className="buttonPC button--info wAuto ml15"
            >
              <SaveOutlined />
              {COMMON_CONSTANT.SAVE}
            </Button>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15"
              onClick={() => history.goBack()}
            >
              <CloseCircleOutlined />
              {lang.CANCEL}
            </Button>
          </Col>
        </Row>
      </FooterPage>
      <Modal
        centered
        width="40%"
        bodyStyle={{ height: "400px", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.ATTACH}</div>}
        visible={isNumber(popupAttach.show)}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              setPopupAttach({ ...popupAttach, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <div className="mb15">
          新規挿入:
          <Button
            type="link"
            onClick={() => {
              fileEl.current.click();
            }}
          >
            <PaperClipOutlined className="iconM blue" />
          </Button>
        </div>
        <input
          type="file"
          className="d-none"
          ref={fileEl}
          onChange={readAttachFileUrl}
        />
        {
          <Table
            className="tbl-original"
            rowKey="ItemOrder"
            dataSource={modal_attach_data}
            columns={columnsAttach}
            pagination={false}
            bordered
          />
        }
      </Modal>
      <Loading show={showLoading} />
    </div>
  );
}

export default CalibrationAdd;
