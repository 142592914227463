import Api from "@src/api";
import { get } from "lodash";

export default {
    // 1: Search-----
    search(data) {
        return Api().get("HomeBanner/SearchHomeBanner", data);
    },
    // 2: Create/Update
    save(formData, id) {
        return id ?
            Api().put(`HomeBanner/UpdateHomeBanner/${id}`, formData, { // UPDATE
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            :
            Api().post("HomeBanner/CreateHomeBanner", formData, { // CREATE
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    },
    // 3: View-----
    getDetail(id) {
        return Api().get(`HomeBanner/GetHomeBannerById/${id}`);
    },
    getCheckResult(id) {
        return Api().get(`/CheckResult/${id}`);
    },
    updateReadFlag(data) {
        return Api().put(`HomeBanner/UpdateReadFlagHomeBanner/${data.id}`);
    },
    updateReadFlagCalendar(data) {
        return Api().put(`HomeBanner/UpdateReadFlagCalendarRemind/${data.id}`);
    },
    // 4: DEL-----
    delete(id) {
        return Api().delete(`HomeBanner/DeleteHomeBanner/${id}`);
    },
    getHomeBannerForNotification(data) {
        return Api().get("HomeBanner/getHomeBannerForNotification", data);
    },
//search user
searchUserForBanner(params){
    return Api().get("HomeBanner/SearchUser",{params});
}

};
