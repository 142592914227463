import Api from "@src/api";

export default {
  // Search history
  searchHistory(data) {
    return Api().get("CheckResult/SearchCheckResultOfficeDetail", {
      params: data,
    });
  },
  // update Shortcut
  updateShortcut(formData) {
    return Api().put(`Shortcut/UpdateShortcutByUserName`, formData);
  },
  // Work report
  getWorkReport(data) {
    return Api().get("WorkReport/GetWorkReportForOfficeDetail", {
      params: data,
    });
  },
  // getDetail default
  getDetail() {
    return Api().get("SiteOfficeMaster/GetSiteOffice");
  },
  // getDetail
  getDetailByID(id) {
    return Api().get("SiteOfficeMaster/GetSiteOfficeByCode?code=" + id);
  },

  // Search office
  searchOffice(data) {
    return Api().get("/SiteOfficeMaster/SearchSiteOfficeMaster", {
      params: data,
    });
  },
  // edit
  edit(formData) {
    return Api().put("SiteOfficeMaster/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // create
  create(formData) {
    return Api().post("SiteOfficeMaster/CreateSiteOffice", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // get data statistic office detail
  getSummaryOfficeDetail() {
    return Api().get("SiteOfficeMaster/Summary");
  },
  // get data statistic checklist in office detail
  getStatisticChecklist(type) {
    return Api().get("Checklists/Summary", { params: { type: type } });
  },
  getDataDynamicChart(params = {}) {
    return Api().get("DynamicReport", { params });
  },
  deleteChart(checkItemCode) {
    return Api().delete(`DynamicReport/${checkItemCode}`);
  },
  getDataItemChartByType(
    stringData = null,
    id,
    type,
    isPreUseChecklist,
    back = null,
  ) {
    const endpoint = stringData
      ? `DynamicReport/show?checkItemCodes=${stringData}&type=${type}&isPreUseChecklist=${isPreUseChecklist}${
          back ? "&back=" + back : ""
        }`
      : `DynamicReport/show?id=${id}&type=${type}&isPreUseChecklist=${isPreUseChecklist}${
          back ? "&back=" + back : ""
        }`;
    return Api().get(endpoint);
  },
  updateHideFlagHomeBanner (id) {
    return Api().put(`HomeBanner/UpdateHideFlagHomeBanner/${id}`);
  },
};
