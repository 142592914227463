import { combineReducers } from "redux";
import navBarReducer from "./navBar";
import loadingReducer from "./loading";
import confirmReducer from "./confirm";
import checkListReducer from "./checkList";
import settingReducer from "./setting";
import reportReducer from "./reportManagement";
import authReducer from "./auth";
import registrationReducer from "./registration";
import sideBarReducer from "./sideBar";
import officeDetailReducer from "./office-detail";
import homeBannerReducer from "./homeBanner";
const rootReducer = combineReducers({
  auth: authReducer,
  navBar: navBarReducer,
  loading: loadingReducer,
  confirm: confirmReducer,
  checklist: checkListReducer,
  setting: settingReducer,
  reportManagement: reportReducer,
  registration: registrationReducer,
  sideBar: sideBarReducer,
  officeDetail: officeDetailReducer,
  homeBanner: homeBannerReducer,
});

export default rootReducer;
