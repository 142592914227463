import {
  ClearOutlined,
  SaveOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { FunctionCustomApi } from "../../api/backend/functionCustom";
import GroupItem from "../../component/Desktop/groupItem";
import TitlePage from "../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  lang,
} from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import classGrid from "../../models/control/grid";
import { loadMenuAction } from "../../redux/sideBar";
import { checkRoleScreen } from "../../utils/roles";
import ApiKbn from "./../../api/backend/kbn";
import Loading from "./../../component/Desktop/loading/loading";
import "./functionMachine.scss";
function FuncMachine() {
  const [txtSearch, setTxtSearch] = useState("");
  const [grid, setGrid] = useState(new classGrid());
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [listSiteOffice, setListSiteOffice] = useState([]);
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const clearInputSearch = () => {
    setTxtSearch("");
    formSearch.resetFields();
    formSearch.submit();
  };
  const initColumns = [
    {
      title: lang.FUNCTION_ID,
      dataIndex: "menuCode",
      width: "10%",
      align: "center",
    },
    {
      title: lang.FUNCITION_NAME,
      dataIndex: "menuName",
      width: "30%",
      align: "center",
    },
    {
      title: lang.FUNCTION_PARENT_NAME,
      dataIndex: "parentMenuName",
      width: "30%",
      align: "center",
    },
    {
      title: lang.OFFICE,
      dataIndex: "office",
      width: "30%",
      align: "center",
      render: (text, row) => {
        const checkFull = checkFullfactory(row.menuCode);
        return (
          <div
            className={`wrap-select ${
              !checkFull ? "wrap-select-noborder" : "wrap-select-border"
            }`}
          >
            {checkFull && (
              <Tag color={"#0080CB"} className="ml5">
                {lang.ALL_FACTORY}
              </Tag>
            )}
            <Select
              className={`select w100 ${checkFull ? "noborder" : ""}`}
              maxTagCount={checkFull ? "undefined" : 5}
              style={{ border: "none !important" }}
              tagRender={tagRender(row.menuCode)}
              onChange={(value) =>
                value && handleChangeSiteOfficeMasterCodes(row.menuCode, value)
              }
              mode="multiple"
              value={row.siteOfficeMasterCodes}
            >
              <Select.Option value={""} disabled={checkFull}></Select.Option>
              <Select.Option value={"all"} disabled={checkFull}>
                <span>
                  <CheckCircleOutlined
                    style={{
                      color: !checkFull ? "#0080CB" : "lightgray",
                    }}
                    className="mr5"
                  />
                  {lang.ALL_FACTORY}
                </span>
              </Select.Option>
              {renderOption(listSiteOffice)}
            </Select>
          </div>
        );
      },
    },
  ];
  const renderOption = (options) => {
    return (
      <>
        {options.map((e) => (
          <Select.Option value={e.key} key={e.key}>
            {e.value}
          </Select.Option>
        ))}
      </>
    );
  };
  const checkFullfactory = (menuCode) =>
    grid.data.find((item) => item.menuCode === menuCode).siteOfficeMasterCodes
      .length === listSiteOffice.length;
  const tagRender = (menuCode) => (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (checkFullfactory(menuCode)) return <div></div>;

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const handleChangeSiteOfficeMasterCodes = (menuCode, newValue) => {
    const index = grid.data.findIndex((item) => item.menuCode === menuCode);
    if (index >= 0) {
      const newGridData = JSON.parse(JSON.stringify(grid.data));
      if (newValue.indexOf("all") >= 0) {
        newGridData[index].siteOfficeMasterCodes = listSiteOffice.map(
          (item) => item.key
        );
        setGrid({ ...grid, data: newGridData });
        return;
      }
      if (newValue.indexOf("") >= 0) {
        newGridData[index].siteOfficeMasterCodes = [];
        setGrid({ ...grid, data: newGridData });
        return;
      }
      newGridData[index].siteOfficeMasterCodes = newValue;
      setGrid({ ...grid, data: newGridData });
    }
  };

  const onSearch = async () => {
    try {
      setShowLoading(true);
      const res = await FunctionCustomApi.getMenuOffice(txtSearch);
      const finalData = res.data.map((item) => {
        if (item.siteOfficeMasterCodes.indexOf("99999") >= 0) {
          item.siteOfficeMasterCodes = listSiteOffice.map(
            (siteItem) => siteItem.key
          );
        }
        return item;
      });
      setGrid({ ...grid, data: finalData, count: res.data.length });
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  const handleSave = () => {
    const saveData = async () => {
      try {
        setShowLoading(true);
        const dataPost = grid.data.map((dataItem) => {
          if (dataItem.siteOfficeMasterCodes.length === listSiteOffice.length) {
            dataItem.siteOfficeMasterCodes = ["99999"];
          }
          return dataItem;
        });
        await FunctionCustomApi.postMenuOffice(dataPost);
        await onSearch();
        dispatch(loadMenuAction());
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } catch (error) {
        showMessage(KEY.ERROR, MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: saveData,
    });
  };

  useEffect(() => {
    const getSiteOffice = async () => {
      try {
        setShowLoading(true);
        const resSiteOffice = await ApiKbn.getSiteOffice();
        setListSiteOffice(resSiteOffice.data.data);
      } catch (error) {
        showMessage(KEY.ERROR, MSG_CATCH);
      }
    };
    getSiteOffice();
  }, []);
  useEffect(() => {
    if (listSiteOffice.length > 0) {
      checkRoleScreen(location.pathname, allowRoute) && onSearch();
    }
  }, [listSiteOffice, allowRoute]);
  return (
    <div className="des-content">
      <TitlePage name={lang.FUNCTION_CUSTOMIZATION_SETTINGS} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Form layout="vertical" onFinish={onSearch} form={formSearch}>
          <Row align="middle" gutter={[9, isPC ? 0 : 8]}>
            <Col span={isPC ? 12 : 24}>
              <Input
                placeholder={lang.FUNCITION_NAME}
                value={txtSearch}
                onChange={(e) => setTxtSearch(e.target.value)}
              />
            </Col>
            <Col span={isPC ? 12 : 24}>
              <Row className="rowNotMargin" justify="start">
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Row justify="end" className="mb10">
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey={(record) => record.menuCode}
          columns={initColumns}
          dataSource={grid.data}
          pagination={CONFIG.paging}
          className="gridSearch"
        />
      </Content>
      <Content
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Row justify="end">
          <Col>
            <Button
              className="buttonPC button--info wAuto "
              onClick={() => {
                handleSave();
              }}
            >
              <SaveOutlined />
              {COMMON_CONSTANT.SAVE}
            </Button>
          </Col>
          <Col>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml10"
              onClick={() => onSearch()}
            >
              <UndoOutlined />
              {lang.RESET}
            </Button>
          </Col>
        </Row>
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}

export default FuncMachine;
