import React from "react";
import { useSelector } from "react-redux";
import configRoles from "./roles/configRoles";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import E404 from "@src/page/Error/404";
import { useMediaQuery } from "react-responsive";
import { getAllowedRoutes } from "@src/utils/roles";
import { KEY } from "../constants/common.const";
import { updateAllowRoute } from "@src/redux/auth";
import { useDispatch } from "react-redux";
import { checkCoincidePath } from "../utils/roles";
import rolesConst from "./roles/roles.const";
function PrivateRouter() {
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { listMenuCode } = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [allowedRoutes, updateAllowedRoutes] = React.useState([]);

  // Check Enduser
  // Check admin dang vao man enduser
  const urlParams = new URLSearchParams(window.location.search);
  const queryIsEndUSer = urlParams.get("isEndUser");

  const { roles } = useSelector((state) => state.auth);
  const isEndUser =
    [rolesConst.CUSTOMER, rolesConst.SPECIAL_CUSTOMER].includes(roles) ||
    location.pathname === "/EndUser" ||
    queryIsEndUSer == "true";
  React.useEffect(() => {
    const arrayRoutes = getAllowedRoutes(configRoles, listMenuCode);
    const array = [];
    arrayRoutes.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => array.push(child));
      } else {
        array.push(item);
      }
    });
    updateAllowedRoutes(array);
    dispatch(updateAllowRoute(array));
    if (array.length) {
      let isRedirect = true;
      array.forEach((item) => {
        if (checkCoincidePath(item.path, location.pathname)) {
          isRedirect = false;
        }
      });
      if (isRedirect) {
        history.push(array[0].path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMenuCode]);
  return (
    <Switch>
      <Route exact path="/">
        {isDesktopOrLaptop ? (
          <Redirect to={"/OfficeDetail"} />
        ) : (
          <Redirect to="/checklist" />
        )}
      </Route>
      {allowedRoutes.map((route) => {
        const { exact, path, component: Component } = route;
        return (
          <Route
            exact={exact}
            key={path}
            path={path}
            render={(props) => <Component {...props} isEndUser={isEndUser} />}
          ></Route>
        );
      })}
      {/* Error */}
      <Route exact path="/404" component={E404}></Route>
    </Switch>
  );
}

export default PrivateRouter;
