/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Tooltip, Modal, Tag } from "antd";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as CopyIcon } from "@src/styles/icon/clone.svg";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/inventory";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import {
  getQuerySearch,
  isEmpty,
  MSG_CATCH,
  pushObject2Query,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import List from "./list";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classGrid from "../../models/control/grid";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";

export default function ItemMaster_list() {
  const TYPE_GET = {
    CHANGE_OFFICE: "0",
    DEFAULT: "1",
  };
  const objSearch = getQuerySearch();
  const [grid, setGrid] = useState(new classGrid());
  const [loading, setLoading] = useState(false);
  const [initRender, setInitRender] = useState(true);
  const history = useHistory();
  const [item, setItem] = useState(
    new classText(objSearch.inventoryItem || null)
  );
  const [category, setCategory] = useState(
    new classDdl(objSearch.categoryCode || null)
  );
  const [warehouses, setWarehouses] = useState(
    new classDdl(objSearch.inventoryWarehouseCode || null)
  );
  const [itemType, setItemType] = useState(
    new classDdl(objSearch.inventoryType || null)
  );

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const inputs = [
    {
      name: lang.ITEM,
      get: item,
      set: setItem,
      type: KEY.TEXT,
    },
    {
      name: lang.ITEM_TYPE,
      get: itemType,
      set: setItemType,
      type: KEY.DDL,
    },
    {
      name: lang.CATEGORY,
      get: category,
      set: setCategory,
      type: KEY.DDL,
    },
    {
      name: lang.WAREHOUSES_MASTER,
      get: warehouses,
      set: setWarehouses,
      type: KEY.DDL,
    },
  ];
  const columns = [
    {
      title: lang.ITEM_NAME,
      dataIndex: "inventoryItemName",
      // align: "center",
      render: (txt, row) => {
        return (
          <div className="white-space" style={{ minWidth: "200px" }}>
            <Link className="mr5" to={"/ItemUpdate/" + row.inventoryItemCode}>
              {txt}
            </Link>
            {row.isNew && <Tag color="magenta">{lang.NEW}</Tag>}
          </div>
        );
      },
    },
    {
      title: lang.CATEGORY_NAME,
      dataIndex: "categoryName",
      align: "center",
      render: (txt) => {
        return (
          <div className="white-space" style={{ minWidth: "80px" }}>
            {txt}
          </div>
        );
      },
    },
    {
      title: lang.ITEM_TYPE,
      dataIndex: "inventoryType",
      align: "center",
      render: (txt) => {
        return (
          <div className="white-space" style={{ minWidth: "60px" }}>
            {txt}
          </div>
        );
      },
    },
    {
      title: lang.ITEM_CODE,
      // align: "center",
      width: 220,
      render: (txt, row) => {
        const code = row.inventoryWarehouseItemCode || row.inventoryItemCode;
        return (
          <div className="align-center">
            <div
              className="d-flex"
              style={{
                maxWidth: "180px",
                width: "fit-content",
                wordBreak: "break-all",
              }}
            >
              {code}
            </div>
            {/* Child */}
            {row.inventoryWarehouseItemCode && (
              <CopyToClipboard text={code}>
                <Button
                  type="link"
                  className="btnLink btnLink-active pr2 pl2 mb2"
                >
                  <CopyIcon className="w18px" />
                </Button>
              </CopyToClipboard>
            )}
          </div>
        );
      },
    },
    {
      title: lang.WAREHOUSES_NAME,
      dataIndex: "inventoryWarehouseName",
      width: 155,
      align: "center",
      render: (txt) => {
        return <div className="white-space">{txt}</div>;
      },
    },
    {
      title: lang.MINIMUM_STOCK_QUANTITY,
      dataIndex: "inventoryWarehouseName",
      width: 102,
      align: "right",
      render: (txt, row) => {
        return (
          <div style={{ maxWidth: "100px" }}>{row.minimumRequiredQuantity}</div>
        );
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 124,
      render: (row) => {
        let disabledEdit = false;
        let disabledDelete = false;
        if (row.haveTransaction) {
          disabledDelete = true;
        }
        //
        return (
          !row.inventoryWarehouseName && (
            <>
              {/* Edit */}
              <Tooltip title={lang.EDIT}>
                <Button disabled={disabledEdit} className="mr5 btnEdit">
                  <Link
                    to={"/ItemUpdate/" + row.inventoryItemCode}
                    className="w100"
                  >
                    <EditFilled className="" />
                  </Link>
                </Button>
              </Tooltip>
              {/* Delete */}
              <Tooltip title={lang.DELETE}>
                <Button
                  disabled={disabledDelete}
                  className="mr5 btnDelete"
                  onClick={() => {
                    showMessageDeleteConfirm(row.inventoryItemCode);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </>
          )
        );
      },
    },
  ];

  const getInventoryType = (type = TYPE_GET.DEFAULT) => {
    return KBN.getInventoryType().then((res) => {
      if (statusRes(res)) {
        setItemType((preV) => ({
          ...preV,
          value: type === TYPE_GET.CHANGE_OFFICE && null,
          options: res.data.data,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getCategory = (type = TYPE_GET.DEFAULT) => {
    return KBN.getCategories()
      .then((res) => {
        if (statusRes(res)) {
          setCategory({
            ...category,
            value: type === TYPE_GET.CHANGE_OFFICE && null,
            options: res.data.data.map((v) => {
              v["key"] = v.categoryCode;
              v["value"] = v.categoryName;
              return v;
            }),
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const getFacility = (type = TYPE_GET.DEFAULT) => {
    return KBN.getWarehouses()
      .then((res) => {
        if (statusRes(res)) {
          setWarehouses({
            ...warehouses,
            value: type === TYPE_GET.CHANGE_OFFICE && null,
            options: res.data.data.map((v) => {
              v["key"] = v.inventoryWarehouseCode;
              v["value"] = v.inventoryWarehouseName;
              return v;
            }),
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        throw new Error();
      });
  };
  const paramsSearchInit = {
    inventoryItem: null,
    inventoryType: null,
    categoryCode: null,
    inventoryWarehouseCode: null,
  };
  let paramsSearch = {
    inventoryItem: isEmpty(item.value) ? null : item.value.trim(),
    inventoryType: itemType.value,
    categoryCode: category.value,
    inventoryWarehouseCode: warehouses.value,
  };
  const onSearch = (offset = 0) => {
    setGrid((preV) => ({ ...preV, loading: true }));

    // Call
    return API.searchItems({
      ...paramsSearch,
      limit: 20,
      offset: offset,
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            // Init, Click
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            // Load more
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
          pushObject2Query(paramsSearch);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setGrid((preV) => ({ ...preV, loading: false }));
      });
  };

  // Download template
  const downloadTemplateOrder = () => {
    API.downloadTemplateOrder().then((res) => {
      const a = document.createElement("a");
      a.href = res.data;
      a.download = "templateOrder";
      a.click();
    });
  };
  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid((preV) => ({ ...preV, loading: false, hasMore: false }));
    } else {
      // load more
      onSearch(data.length);
    }
  };
  const clearInput = () => {
    setItem({ ...item, value: null });
    setItemType({ ...itemType, value: null });
    setCategory({ ...category, value: null });
    setWarehouses({ ...warehouses, value: null });
  };
  useEffect(() => {
  }, [category]);

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (initRender) {
        Promise.all([
          onSearch(),
          getCategory(),
          getFacility(),
          getInventoryType(),
        ]).catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
        setInitRender(false);
      } else {
        paramsSearch = { ...paramsSearchInit };
        setCategory({ ...category, value: null });
        setWarehouses({ ...warehouses, value: null });
        Promise.all([
          onSearch(),
          getCategory(TYPE_GET.CHANGE_OFFICE),
          getFacility(TYPE_GET.CHANGE_OFFICE),
          getInventoryType(TYPE_GET.CHANGE_OFFICE),
        ]).catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });

        history.push(location.pathname);
      }
    }
  }, [allowRoute]);

  const showMessageDeleteConfirm = (id) => {
    // Action del
    const funcDel = async () => {
      setLoading(true);
      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        // Del
        await API.deleteItem(id);
        // API Search
        await onSearch();
        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      setLoading(false);
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  return (
    <>
      <List
        inputs={inputs}
        columns={columns}
        grid={grid}
        rowKey={(row) =>
          row.inventoryItemCode || row.inventoryWarehouseItemCode
        }
        actionSearch={() => {
          onSearch();
        }}
        loadMore={onLoadMore}
        clearInput={clearInput}
        linkAdd="/ItemUpdate"
        loading={loading}
        expandable={{
          childrenColumnName: "warehouseItems",
        }}
        isDownloadTemplate
        actionDownloadTemplate={() => {
          downloadTemplateOrder();
        }}
      />
    </>
  );
}
