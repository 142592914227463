import React, { useState, useEffect, useRef } from "react";
import "./detailChecklist.scss";
import {
  Row,
  Col,
  Button,
  Modal,
  Drawer,
  Typography,
  Tooltip,
  Switch,
} from "antd";
import Tabs from "rc-tabs";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import loadable from "@loadable/component";
import WaringIcon from "@src/styles/icon/notify_icon.svg";
import UpIcon from "@src/styles/icon/icon-up-page.svg";
import TabsCustom from "@src/component/TabsComponent/TabsCustom";
import {
  LeftOutlined,
  SaveFilled,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  getChecklist,
  createResultPOMM,
  updateResultPOMM,
  updateCurrentChecklist,
  updateCurrentTabChecklistGroup,
  setCurrentCLGroup,
  updateCurrentCLGroup,
  updateNoteMachine,
  updateWorkingStatusMachine,
  updateAttachmentMachine,
  setShowAlert,
  setStatusUpdateState,
  bookmarkById,
  importTemplateChecklist,
  updateCurrentStatusCL,
  updateShowMultiChecker,
  updateIsImportTemplate,
  updateSaveStatusMachine,
} from "@src/redux/checkList";
import API from "@src/api/backend/checkList";
import { openConfirm } from "@src/redux/confirm";
import { useMediaQuery } from "react-responsive";
import DraftIcon from "@src/styles/icon/draft.svg";
import ReportIcon from "@src/styles/icon/report.svg";
import TroubleIcon from "@src/styles/icon/trouble.svg";
import BookmarkIcon from "@src/styles/icon/bookmark.svg";
import UnBookmarkIcon from "@src/styles/icon/unbookmark.svg";
import UnBMIcon from "@src/styles/icon/unbookmark1.svg";
import { lang, COMMON_CONSTANT, KEY } from "@src/constants/common.const";
import {
  statusRes,
  MSG_CATCH,
  saveFile,
  showMessage,
} from "@src/constants/utils";
import { resetStateIsUpdated } from "../../../redux/checkList";
import PopupItemChart from "../../../component/Popup/popup-chart";
import { useMemo } from "react";
const ListOverview = loadable(() => import("./listOverview"));
const ListItems = loadable(() => import("./listItems"));
const { Paragraph } = Typography;

function DetailCheckListMobile() {
  // config time by fns-date
  window.__localeId__ = "ja";
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const checklistRef = useRef(null);
  const inputFile = useRef(null);
  // set init state
  let { collapsed } = useSelector((state) => state.sideBar);
  let {
    indexSelectedTab,
    checklistId,
    checklistTmp,
    checklistSelected,
    currentTabCLGroup,
    currentMachine,
    currentPreCheckItems,
    isUpdated,
    updateDone,
    tempBookmarked,
    tempBookmarkId,
    tempChecklistId,
    checklistUpdated,
    showMultiChecker,
    dataMultiCheck,
    arrayCLToyota,
    isImportTemplate,
    arrayItemChart,
  } = useSelector((state) => state.checklist);
  const { isUpdatedTime, isLoadingBookmark } = useSelector(
    (state) => state.checklist
  );
  const { statusNetwork, selectedFactory, displayName } = useSelector(
    (state) => state.auth
  );
  const [tabGroups, setTabGroups] = useState([]);
  const [checklist, updateChecklist] = useState(null);
  const [openSaveCL, updateOpenSaveCL] = useState(false);
  const [typeSave, updateTypeSave] = useState(null);
  const [hasError, updateHasError] = useState(false);
  const [hasNoneInput, updateHasNoneInput] = useState(false);
  const [visibleDrawerSave, setVisibleDrawer] = useState(false);
  const [currentTab, updateCurrentTab] = useState(0);
  let stateCL = useSelector((state) => state.checklist);
  const [dataFileReader, updateFileReader] = useState(null);
  const [nameFile, updateNameFile] = useState(null);
  const [remarkType, updateRemarkType] = useState("1");
  const [kind, updateKind] = useState(null);
  const [hideRemark, updateHideRemark] = useState(false);
  const [isCLToyota, updateIsCLToyota] = useState(false);
  const [isPreUseChecklist, updateIsPreUseChecklist] = useState("");
  const [dataItemChart, updateDataItemChart] = useState([]);
  const [dataItemChartPreCheck, updateDataItemChartPreCheck] = useState([]);
  const [visibleItemChart, updateVisibleItemChart] = useState(false);
  const dispatch = useDispatch();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    handleColorTab();
  }, []);
  useEffect(() => {
    if (currentMachine) {
      dispatch(updateAttachmentMachine());
      dispatch(updateNoteMachine());
      dispatch(updateWorkingStatusMachine());
      dispatch(updateSaveStatusMachine());
      dispatch(updateCurrentCLGroup());
      dispatch(updateCurrentTabChecklistGroup());
    }
    if (currentPreCheckItems) {
      dispatch(updateCurrentCLGroup());
      dispatch(updateCurrentTabChecklistGroup());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(setShowAlert(true));
    dispatch(setStatusUpdateState(false));
    if (!checklist) {
      if (getChecklist(stateCL)) {
        updateChecklist(getChecklist(stateCL));
      } else {
        history.push("/checklist");
      }
    } else {
      updateIsPreUseChecklist(checklist.isPreUseChecklist);
      updateRemarkType(Number(checklist.remarkType));
      dispatch(updateCurrentStatusCL(checklist.status));
      if (
        arrayCLToyota.includes(
          checklist.checklistId
            ? checklist.checklistId
            : checklist.ChecklistId + ""
        )
      ) {
        updateIsCLToyota(true);
      }
    }
  }, [arrayCLToyota, checklist, dispatch, history, stateCL]);
  useEffect(() => {
    if (isImportTemplate) {
      updateCurrentTab(0);
      dispatch(updateIsImportTemplate(false));
    }
  }, [dispatch, isImportTemplate]);
  useEffect(() => {
    if (currentTabCLGroup) {
      let data = [];
      setTabGroups(currentTabCLGroup);
      currentTabCLGroup.forEach((item, index) => {
        data.push({
          title: item.title,
          index: index,
          value: item.title,
        });
      });
    }
  }, [currentTabCLGroup]);
  useEffect(() => {
    if (checklistUpdated) {
      updateChecklist(checklistUpdated);
    }
  }, [checklistUpdated]);
  useEffect(() => {
    if (checklist) {
      updateHideRemark(checklist.hideRemark);
    }
  }, [checklist]);
  useEffect(() => {
    if (updateDone && typeSave) {
      handleTypeSaveAfterUpdateDone(typeSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDone, typeSave]);
  // set index tab in redux
  useEffect(() => {
    if (currentTab) {
      changeTab(currentTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  useEffect(() => {
    if (dataFileReader && nameFile) {
      dispatch(
        importTemplateChecklist(
          {
            id: checklist.ChecklistId || checklist.checklistId,
            fileData: dataFileReader,
            fileName: nameFile.name,
            isChecklist: checklist.id === "new-checklist" ? true : false,
          },
          history
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFileReader, dispatch]);
  const changeTab = (tab) => {
    if (currentMachine) {
      dispatch(updateAttachmentMachine());
      dispatch(updateNoteMachine());
      dispatch(updateWorkingStatusMachine());
      dispatch(updateSaveStatusMachine());
      dispatch(updateCurrentCLGroup());
    }
    if (currentPreCheckItems) {
      dispatch(updateCurrentCLGroup());
    }
    dispatch(updateCurrentTabChecklistGroup());
    dispatch(setCurrentCLGroup(tabGroups[Number(tab)]));
    window.scrollTo(0, 0);
    handleColorTab();
  };
  const handleColorTab = () => {
    setTimeout(() => {
      const domTab = document.getElementsByClassName("ant-tabs-tab");
      for (let i = 0; i < domTab.length; i++) {
        domTab[i].style.backgroundColor = "#fff";
      }
      const dom = document.getElementsByClassName("tab-complete");
      for (let i = 0; i < dom.length; i++) {
        dom[i].parentNode.parentNode.style.backgroundColor = "#00800063";
      }
    }, 0);
  };
  // open Modal to save normal
  const openModalSaveNormal = (type) => {
    const machineStatus = checkDataMachineHasError();
    if (type === "report") {
      updateOrCreateCheckResult("normal", "workReport");
    } else {
      if (machineStatus[0] || machineStatus[1]) {
        updateTypeSave("normal");
        updateOpenSaveCL(true);
      } else {
        updateOrCreateCheckResult("normal");
      }
    }
  };
  const changeHideRemark = (data) => {
    updateHideRemark(data);
  };
  // open modal to save abnormal
  const openModalSaveAbNormal = () => {
    updateOrCreateCheckResult("abnormal", "troubleReport");
  };
  // update or create check result
  const updateOrCreateCheckResult = (typeData, kindData, mergeStatus) => {
    if (!mergeStatus) {
      updateKind(kindData);
    }
    let checklistResult;
    if (checklistId === "new-checklist") {
      checklistResult = JSON.parse(JSON.stringify(checklistTmp));
    } else {
      checklistResult = JSON.parse(JSON.stringify(checklistSelected));
    }
    let currentTab = JSON.parse(JSON.stringify(currentTabCLGroup));
    currentTab.shift();
    checklistResult.checklistGroups = currentTab;
    handleTypeSave(typeData, checklistResult, kindData, mergeStatus);
  };
  // call api
  const handleTypeSave = (type, checklistResult, kind, mergeStatus) => {
    let params = {
      isOnline: navigator.onLine,
      type: type,
      factoryCode: selectedFactory,
      hideRemark: hideRemark,
    };
    dispatch(setShowAlert(false));
    if (checklist.id !== "new-checklist") {
      dispatch(
        updateResultPOMM(
          params,
          stateCL,
          history,
          checklistResult,
          kind,
          mergeStatus
        )
      );
    } else {
      dispatch(
        createResultPOMM(
          params,
          stateCL,
          history,
          checklistResult,
          kind,
          mergeStatus
        )
      );
    }
    updateOpenSaveCL(false);
    dispatch(updateShowMultiChecker(false));
  };
  const handleMultiChecker = (typeStatus) => {
    switch (typeStatus) {
      case "merge":
        Modal.confirm({
          centered: true,
          title: lang.WARMING,
          content: "未入力データのみを保存しますが、よろしいでしょうか。",
          okText: lang.OK,
          cancelText: lang.CANCEL,
          onOk: () => updateOrCreateCheckResult(typeSave, kind, 1),
        });
        break;
      case "replace":
        updateOrCreateCheckResult(typeSave, kind, 3);
        break;
      case "replaceAll":
        updateOrCreateCheckResult(typeSave, kind, 3);
        break;
      case "replaceItem":
        Modal.confirm({
          centered: true,
          title: lang.WARMING,
          content: "入力した項目を全て上書きしますが、よろしいでしょか。",
          okText: lang.OK,
          cancelText: lang.CANCEL,
          onOk: () => updateOrCreateCheckResult(typeSave, kind, 2),
        });
        break;
      default:
        break;
    }
    updateKind(null);
  };
  const clearFile = () => {
    updateFileReader(null);
    updateNameFile(null);
    inputFile.current.value = null;
  };
  // update cl group when prepare save checklist
  function updateCLGroup(type) {
    setVisibleDrawer(false);
    updateTypeSave(type);
    updateStateChecklist();
  }
  function updateStateChecklist() {
    if (currentMachine) {
      dispatch(updateAttachmentMachine());
      dispatch(updateNoteMachine());
      dispatch(updateWorkingStatusMachine());
      dispatch(updateSaveStatusMachine());
      dispatch(updateCurrentCLGroup());
    }
    if (currentPreCheckItems) {
      dispatch(updateCurrentCLGroup());
    }
    if (isUpdated || isUpdatedTime) {
      if (isUpdated) {
        dispatch(updateCurrentTabChecklistGroup());
      }
      dispatch(updateCurrentChecklist());
    } else {
      dispatch(setStatusUpdateState(true));
    }
  }
  // open drawer save
  function openDrawerSaveOrReport(type) {
    updateTypeSave(type);
    setVisibleDrawer(true);
  }
  // change status bookmark of this checklist
  const handleBookMark = () => {
    let params;
    if (tempBookmarked) {
      params = {
        status: tempBookmarked,
        id: tempBookmarkId,
        factoryCode: selectedFactory,
      };
    } else {
      params = {
        status: tempBookmarked,
        id: tempChecklistId,
        factoryCode: selectedFactory,
      };
    }
    dispatch(bookmarkById(params));
  };
  // handle type save to call api
  function handleTypeSaveAfterUpdateDone(type) {
    switch (type) {
      case "normal":
        openModalSaveNormal();
        break;
      case "draft":
        updateOrCreateCheckResult("draft");
        break;
      case "report":
        openModalSaveNormal("report");
        break;
      case "trouble":
        openModalSaveAbNormal();
        break;
      default:
        break;
    }
  }
  // check checklist machine error
  const checkDataMachineHasError = () => {
    let checklist;
    let error = false;
    let noneInput = false;
    updateHasError(false);
    updateHasNoneInput(false);
    if (checklistId === "new-checklist") {
      checklist = JSON.parse(JSON.stringify(checklistTmp));
    } else {
      checklist = JSON.parse(JSON.stringify(checklistSelected));
    }
    checklist.ChecklistMachine.forEach((item) => {
      let arrStatus = [];
      let arrNoneInput = [];
      if (
        typeof item.resultMachineStatus === "object" &&
        item.resultMachineStatus
      ) {
        arrStatus = Object.values(item.resultMachineStatus);
        arrNoneInput = Object.values(item.noneInput);
      } else {
        arrStatus = [item.resultMachineStatus];
        arrNoneInput = [item.noneInput];
      }
      arrNoneInput.length &&
        arrNoneInput.forEach((i) => {
          if (Number(i) !== 0 || i === null) {
            updateHasNoneInput(true);
            noneInput = true;
          }
        });
      arrStatus.length &&
        arrStatus.forEach((i) => {
          if (Number(i) === 2) {
            updateHasError(true);
            error = true;
          }
        });
    });
    return [noneInput, error];
  };
  // render icon
  const renderIcon = () => {
    return <img className="icon" alt="logo.png" src={WaringIcon} />;
  };
  // render modal confirm save checklist with type
  const renderModalSaveChecklist = () => (
    <Modal
      className="modal"
      visible={openSaveCL}
      footer={null}
      centered="true"
      zIndex={10000}
      closeIcon={<></>}
      width={300}
    >
      <Row align="middle" justify="center" gutter={[8, 8]}>
        <Col span={24}>
          <Row justify="center">{renderIcon()}</Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <p className="text-warning">
              {typeSave === "normal"
                ? hasError && lang.WARNING_SAVE_NORMAL
                : ""}
            </p>
            <p className="text-warning">
              {hasNoneInput && lang.WARNING_HAS_NONE_INPUT}
            </p>
          </Row>
        </Col>
        <Col span={12}>
          <Button
            onClick={() => updateOrCreateCheckResult(typeSave)}
            className="button button--info"
          >
            {lang.OK}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="button button--cancel"
            onClick={() => {
              updateOpenSaveCL(false);
              dispatch(setStatusUpdateState(false));
              updateTypeSave(null);
            }}
          >
            {lang.CANCEL}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
  const renderModalMultiChecker = () => (
    <Modal
      visible={showMultiChecker}
      title={lang.WARMING}
      closeIcon={<></>}
      footer={
        dataMultiCheck.hasDuplicate
          ? dataMultiCheck.haveMerge
            ? [
                <Button
                  style={{ marginTop: "5px" }}
                  key="merge"
                  type="primary"
                  onClick={() => handleMultiChecker("merge")}
                >
                  未入力データのみ保存
                </Button>,
                <Button
                  style={{ marginTop: "5px" }}
                  key="replaceItem"
                  type="primary"
                  onClick={() => handleMultiChecker("replaceItem")}
                >
                  入力した項目すべて上書き保存
                </Button>,
                <Button
                  style={{ marginTop: "5px" }}
                  key="cancel"
                  onClick={() => onCancelMultiCheck()}
                >
                  {lang.CANCEL}
                </Button>,
              ]
            : [
                <Button
                  style={{ marginTop: "5px" }}
                  key="replaceItem"
                  type="primary"
                  onClick={() => handleMultiChecker("replaceItem")}
                >
                  入力した項目すべて上書き保存
                </Button>,
                <Button
                  style={{ marginTop: "5px" }}
                  key="cancel"
                  onClick={() => onCancelMultiCheck()}
                >
                  {lang.CANCEL}
                </Button>,
              ]
          : dataMultiCheck.haveMerge
          ? [
              <Button
                style={{ marginTop: "5px" }}
                key="merge"
                type="primary"
                onClick={() => handleMultiChecker("merge")}
              >
                未入力データのみ保存
              </Button>,
              <Button
                style={{ marginTop: "5px" }}
                key="cancel"
                onClick={() => onCancelMultiCheck()}
              >
                {lang.CANCEL}
              </Button>,
            ]
          : [
              <Button
                style={{ marginTop: "5px" }}
                key="replace"
                type="primary"
                onClick={() => handleMultiChecker("replace")}
              >
                {lang.OK}
              </Button>,
              <Button
                style={{ marginTop: "5px" }}
                key="cancel"
                onClick={() => onCancelMultiCheck()}
              >
                {lang.CANCEL}
              </Button>,
            ]
      }
    >
      <Row>
        <Col span={24}>
          <span className="info--text">'{dataMultiCheck.checklistName}'</span>{" "}
          は{" "}
          {!dataMultiCheck.haveMerge ? (
            <>
              <span className="info--text">{dataMultiCheck.time}</span> に{" "}
            </>
          ) : (
            "に"
          )}
          <span className="info--text">'{dataMultiCheck.inspector}'</span>
          {dataMultiCheck.haveMerge
            ? lang.SAVE_CL_DRAFT
            : lang.SAVE_CL_CRAFT_WITH_NO_MERGE}
        </Col>
      </Row>
    </Modal>
  );
  const openModal = () => {
    if (isUpdated) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: "/checklist",
        })
      );
    } else {
      dispatch(resetStateIsUpdated());
      history.push("/checklist");
    }
  };
  // download template
  const downloadTemplate = () => {
    // Call API download
    const param = {
      checklistId: checklist.ChecklistId
        ? checklist.ChecklistId
        : checklist.checklistId,
      checklistVersion: checklist.version
        ? checklist.version
        : checklist.checklistVersion,
    };
    API.downloadTemplate(param)
      .then((res) => {
        if (statusRes(res)) {
          saveFile(res);
        } else {
          showMessage(KEY.ERROR, res.data.msg);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const onImportExcel = (file) => {
    // Get the uploaded file object
    const { files } = file.target;
    // Read the file through the FileReader object
    updateNameFile(files[0]);
    getBase64(files[0], (result) => {
      updateFileReader(result);
    });
  };
  const getBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {
    };
  };
  // click export template
  const exportTemplate = () => {
    updateStateChecklist();
    clearFile();
    inputFile.current.click();
  };
  const onCancelMultiCheck = () => {
    dispatch(updateShowMultiChecker(false));
    if (window.location.pathname === "/checklist") {
      window.location.reload();
    }
  };
  const openModalItemChart = () => {
    updateVisibleItemChart(false);
    const string = arrayItemChart.join(",");
    updateDataItemChart(string);
    updateVisibleItemChart(true);
  };
  // render tab
  const renderContent = (tab) => {
    return (
      <Row className="rowNotMargin">
        <Col span={24}>
          {tab.title === lang.COMMON ? (
            <ListOverview
              startDate={checklist.timeStartCheck}
              endDate={checklist.timeEndCheck}
              id={checklist.id}
              status={checklist.status}
              baseTitle={checklist.base_title}
              editor={checklist.editor}
              inspector={checklist.inspector || displayName}
              inspectorId={checklist.inspectorId}
              inspector1={checklist.inspector1}
              inspector1Id={checklist.inspector1Id}
              hasTimeGroups={checklist.hasTimeGroups}
              timeGroups={checklist.timeGroups}
              maxTimeCheck={checklist.maxTimeCheck}
              fullTimeCheck={checklist.fullTimeCheck}
              historyCheck={checklist.checkResultHistories}
              isDesktop={isDesktopOrLaptop}
              isCLToyota={isCLToyota}
            />
          ) : (
            <ListItems
              checklistId={
                checklist.checklistId
                  ? checklist.checklistId
                  : checklist.ChecklistId
              }
              remarkType={remarkType}
              hideRemark={hideRemark}
            />
          )}
        </Col>
      </Row>
    );
  };
  const renderChart = useMemo(
    () => (
      <PopupItemChart
        itemCodes={dataItemChart}
        visible={visibleItemChart}
        type={checklist ? checklist.checkTiming : null}
        checklistId={
          checklist ? checklist.ChecklistId || checklist.checklistId : null
        }
        isPreUseChecklist={isPreUseChecklist}
        closeModal={(status) => updateVisibleItemChart(status)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleItemChart]
  );

  return (
    <div
      className={
        isDesktopOrLaptop ? "des-content" : "des-content des-content__mobile"
      }
      style={!statusNetwork ? { marginTop: "190px" } : {}}
    >
      {renderChart}
      <Row
        className="rowNotMargin"
        align="middle"
        style={{
          paddingRight: isDesktopOrLaptop ? "16px" : "24px",
          paddingBottom: "16px",
        }}
      >
        <Col span={12}>
          <Row
            className="rowNotMargin button-back"
            align="middle"
            gutter={[8, 0]}
            onClick={openModal}
          >
            <Col className="icon-back">
              <LeftOutlined />
            </Col>
            <Col className="text">{COMMON_CONSTANT.BACK}</Col>
          </Row>
        </Col>
        <Col span={12} className="bookmark">
          <Row className="rowNotMargin" justify="end">
            {isDesktopOrLaptop ? (
              <Button
                className={
                  tempBookmarked
                    ? "buttonPC button--info"
                    : "buttonPC button--outline"
                }
                onClick={() => handleBookMark()}
                icon={
                  <img
                    src={tempBookmarked ? UnBMIcon : BookmarkIcon}
                    alt=""
                    className="icon__confirm"
                  />
                }
                loading={isLoadingBookmark}
              >
                {tempBookmarked ? lang.DELETE_BOOKMARK : lang.ADD_BOOKMARK}
              </Button>
            ) : (
              <img
                src={tempBookmarked ? BookmarkIcon : UnBookmarkIcon}
                alt=""
                style={{ height: "20px" }}
                onClick={() => handleBookMark()}
              />
            )}
          </Row>
        </Col>
      </Row>
      <div
        className={
          isDesktopOrLaptop
            ? "detailChecklist"
            : "detailChecklist detailChecklist__mobile"
        }
        ref={checklistRef}
      >
        {renderModalSaveChecklist()}
        {renderModalMultiChecker()}
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={[0, 16]}>
              <Col
                span={24}
                className="checklist__name"
                style={isDesktopOrLaptop ? { paddingBottom: "20px" } : {}}
              >
                <Paragraph ellipsis={{ rows: 2 }} className="text-weight-600">
                  <Tooltip
                    placement="bottom"
                    title={checklist ? checklist.checklistName : ""}
                  >
                    {checklist ? checklist.checklistName : ""}
                  </Tooltip>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Row className="rowNotMargin">
                  <Col flex="120px">{lang.NUMBER_GROUP}:</Col>
                  <Col className="checklist-group__number" flex="none">
                    {checklist && checklist.checklistGroups.length}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="rowNotMargin">
                  <Col flex="120px">{lang.TYPE_CHECKLIST}:</Col>
                  <Col className="checklist__type" flex="none">
                    {checklist && checklist.checkTiming}
                  </Col>
                </Row>
              </Col>
              {checklist && checklist.statusValue ? (
                <Col span={24}>
                  <Row className="rowNotMargin">
                    <Col flex="120px">{lang.STATE}:</Col>
                    <Col className="checklist__type" flex="none">
                      {checklist.statusValue}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            {checklist &&
              checklist.isPreUseChecklist !== KEY.TYPE_PRE_USE_CHECKLIST && (
                <>
                  <Row className="rowNotMargin">
                    <Col flex="120px">備考・写真を隠す</Col>
                    <Switch checked={hideRemark} onChange={changeHideRemark} />
                  </Row>
                </>
              )}
          </Col>
          <Col span={24} className="checklist__input">
            {checklist && (
              <TabsCustom
                onChange={(tab) => updateCurrentTab(tab)}
                type="card"
                sticky="true"
                activeKey={indexSelectedTab + ""}
              >
                {tabGroups.map((pane, index) => (
                  <Tabs.TabPane
                    tab={
                      <div
                        className={
                          pane.tabComplete
                            ? "tab-complete textOverflow"
                            : "textOverflow"
                        }
                        style={{
                          maxWidth: "155px",
                          fontSize: isPC ? "14px" : "20px",
                        }}
                      >
                        {pane.title}
                      </div>
                    }
                    key={index}
                    closable={false}
                  >
                    {renderContent(pane)}
                  </Tabs.TabPane>
                ))}
              </TabsCustom>
            )}
          </Col>
        </Row>
      </div>
      {isDesktopOrLaptop ? (
        <Row
          className="confirmButton"
          style={{ width: `calc(100vw - ${collapsed ? "70px" : "220px"})` }}
          gutter={[16, 24]}
        >
          <Col flex="none">
            <Button
              onClick={() => updateCLGroup("normal")}
              icon={<SaveFilled />}
              className="buttonPC button--info"
            >
              {lang.SAVE_NORMAL}
            </Button>
          </Col>
          <Col flex="none">
            <Button
              onClick={() => updateCLGroup("draft")}
              icon={<img src={DraftIcon} alt="" className="icon__confirm" />}
              className="buttonPC button--outline"
            >
              {lang.SAVE_DRAFT}
            </Button>
          </Col>

          {checklist &&
            checklist.isPreUseChecklist !== KEY.TYPE_PRE_USE_CHECKLIST && (
              <>
                <Col flex="none">
                  <Button
                    disabled={!statusNetwork}
                    onClick={() => exportTemplate()}
                    icon={<CloudUploadOutlined />}
                    className="buttonPC button--info"
                  >
                    {lang.IMPORT_TEMPLATE_CL}
                  </Button>
                </Col>
                <Col flex="none">
                  <Button
                    disabled={!statusNetwork}
                    onClick={() => downloadTemplate()}
                    icon={
                      <CloudDownloadOutlined
                        style={{
                          color: "#f50",
                        }}
                      />
                    }
                    className="buttonPC button--outline"
                  >
                    {lang.EXPORT_TEMPLATE_CL}
                  </Button>
                  <input
                    type="file"
                    hidden
                    accept=".xlsx, .xls"
                    onChange={onImportExcel}
                    ref={inputFile}
                  />
                </Col>
              </>
            )}

          <Col flex="none">
            <Button
              disabled={!statusNetwork || !arrayItemChart.length}
              onClick={() => openModalItemChart()}
              className="buttonPC button--info"
              icon={
                <LineChartOutlined
                  style={{
                    color: "#f50",
                  }}
                />
              }
            >
              {lang.DYNAMIC_CHART}
            </Button>
          </Col>
          <Col flex="auto"></Col>
          <Col flex="none">
            <Button
              onClick={() => updateCLGroup("report")}
              icon={<img src={ReportIcon} alt="" className="icon__confirm" />}
              className="buttonPC button--outline"
            >
              {lang.SAVE_REPORT}
            </Button>
          </Col>
          <Col flex="none">
            <Button
              onClick={() => updateCLGroup("trouble")}
              icon={<img src={TroubleIcon} alt="" className="icon__confirm" />}
              className="buttonPC button--outline"
            >
              {lang.SAVE_TROUBLE_REPORT}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="confirmButton confirmButton__mobile">
          <Col
            span={12}
            className="save"
            onClick={() => openDrawerSaveOrReport("save")}
          >
            {lang.SAVE}
          </Col>
          <Col
            span={12}
            className="report"
            onClick={() => openDrawerSaveOrReport("report")}
          >
            {lang.REPORT}
          </Col>
        </Row>
      )}
      <Drawer
        className="drawerSave"
        title={typeSave === "save" ? lang.SAVE : lang.REPORT}
        placement="bottom"
        onClose={() => setVisibleDrawer(false)}
        visible={visibleDrawerSave}
        height="200"
      >
        {typeSave === "save" ? (
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col
              span={24}
              className="save text-center"
              onClick={() => updateCLGroup("normal")}
            >
              {lang.SAVE_NORMAL}
            </Col>
            <Col
              span={24}
              className="draft text-center"
              onClick={() => updateCLGroup("draft")}
            >
              {lang.SAVE_DRAFT}
            </Col>
          </Row>
        ) : (
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col
              span={24}
              className="report text-center"
              onClick={() => updateCLGroup("report")}
            >
              {lang.SAVE_REPORT}
            </Col>
            <Col
              span={24}
              className="trouble text-center"
              onClick={() => updateCLGroup("trouble")}
            >
              {lang.SAVE_TROUBLE_REPORT}
            </Col>
          </Row>
        )}
      </Drawer>
      <Button
        type="text"
        size="large"
        icon={<img height={25} width={25} src={UpIcon} alt="" />}
        style={{
          position: "fixed",
          bottom: "60px",
          right: "6px",
          minWidth: "10px",
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      />
      {isPC ? (
        <></>
      ) : (
        <Button
          type="text"
          size="large"
          shape="circle"
          icon={<LineChartOutlined style={{ fontSize: "26px" }} />}
          disabled={!statusNetwork || !arrayItemChart.length}
          style={{
            position: "fixed",
            bottom: "120px",
            right: "6px",
            minWidth: "10px",
            color: !statusNetwork || !arrayItemChart.length ? "grey" : "black",
          }}
          onClick={() => openModalItemChart()}
        />
      )}
    </div>
  );
}
export default DetailCheckListMobile;
