import React from "react";
import classText from "../../models/control/text";

function MessageInput({ data = new classText() }) {
  return data.error && data.message ? (
    <div className="red">{data.message}</div>
  ) : (
    <></>
  );
}

export default MessageInput;
