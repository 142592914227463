/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Col,
  Row,
  Form,
  Select,
  Table,
  Space,
  Input,
  Checkbox,
  Button,
} from "antd";
import { lang, KEY, STYLE } from "../../constants/common.const";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import { useHistory } from "react-router";
import { enterSearch, renderOption } from "../../constants/utils";
import {
  SearchOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import classGrid from "../../models/control/grid";
import LoadingInline from "../../component/Popup/loading-inline";
import { useEffect } from "react";

export default function SearchMaster({
  inputs = [],
  columns = [],
  actionSearch,
  clearInput,
  grid = new classGrid(),
  rowKey,
  loading,
  linkAdd,
  btnAdd,
  loadMore,
  isDownloadTemplate = false,
  actionDownloadTemplate,
  ...other
}) {
  const history = new useHistory();
  // Loading
  const [formSearch] = Form.useForm();

  // clearInputSearch
  const clearInputSearch = () => {
    formSearch.resetFields();
    if (clearInput() !== false) {
      formSearch.submit();
    }
  };

  const oneLine = inputs.length < 3;
  useEffect(() => {
    inputs.forEach((item) => {
      if (item.get && !item.get.value) {
        formSearch.setFieldsValue({ [item.name]: null });
      }
    });
  }, [inputs]);

  // Render
  return (
    <div className="des-content">
      {/* Group input */}
      <GroupItem>
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          onFinish={actionSearch}
        >
          <Row gutter={[16, 8]} align={oneLine ? "bottom" : "middle"}>
            {inputs.map(
              (
                { title, name, onChange, render, type, get, set, required },
                index
              ) => {
                let item;
                switch (type) {
                  case KEY.TEXT:
                    item = (
                      <Input
                        className={get.error ? STYLE.BORDER_RED : ""}
                        placeholder={name}
                        value={get.value}
                        defaultValue={get.value}
                        onChange={
                          onChange ||
                          ((e) => {
                            set({ ...get, value: e.target.value });
                          })
                        }
                        {...enterSearch(actionSearch)}
                      />
                    );
                    break;
                  case KEY.DDL:
                    item = (
                      <Select
                        defaultValue={get.value || null}
                        className={get.error ? STYLE.BORDER_RED : ""}
                        placeholder={name}
                        value={get.value}
                        onChange={
                          onChange ||
                          ((v) => {
                            set({ ...get, value: v });
                          })
                        }
                        {...enterSearch(actionSearch)}
                      >
                        {renderOption(get.options)}
                      </Select>
                    );
                    break;
                  case KEY.CHECKBOX:
                    item = (
                      <Checkbox
                        onChange={(e) => {
                          set({ ...get, checked: e.target.checked });
                        }}
                        checked={get.checked}
                      >
                        {name}
                      </Checkbox>
                    );
                    break;
                  default:
                    break;
                }

                return (
                  <Col span="8" key={index}>
                    <Form.Item
                      name={title || name}
                      className="rowNotMargin"
                      label={title || name}
                      rules={[{ required: required }]}
                    >
                      {render ? render() : item}
                    </Form.Item>
                  </Col>
                );
              }
            )}
            {/* Button Search/Clear */}

            <Col span={oneLine ? 8 : 24} className={oneLine ? "" : "right"}>
              <Row>
                <Col className="left" span={12}>
                  {isDownloadTemplate && (
                    <Button
                      className="buttonPC button--back wAuto "
                      onClick={actionDownloadTemplate}
                    >
                      <CloudDownloadOutlined
                        style={{
                          color: "#f50",
                        }}
                      />
                      {lang.EXPORT_TEMPLATE_CL}
                    </Button>
                  )}
                </Col>
                <Col span={isDownloadTemplate ? 12 : 24}>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={actionSearch}
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </GroupItem>
      <GroupItem>
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {btnAdd ? (
              btnAdd
            ) : (
              <Button
                type="link"
                onClick={() => {
                  history.push(linkAdd);
                }}
                className="btnAddRecord"
              >
                <AddIcon />
                {lang.ADD}
              </Button>
            )}
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count || 0}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {
            if (!grid.loading) {
              loadMore();
            }
          }}
          hasMore={!grid.loading && grid.hasMore}
          useWindow={true}
        >
          <Table
            className="gridSearch tblRole mt10"
            columns={columns}
            rowKey={rowKey}
            dataSource={grid.data}
            pagination={false}
            loading={grid.loading}
            {...other}
            scroll={{ x: "auto" }}
          />
          <Col span={24} className="mt10 content-center">
            <LoadingInline isLoadingInline={grid.loading} />
          </Col>
        </InfiniteScroll>
      </GroupItem>
      <Loading show={loading}></Loading>
    </div>
  );
}
