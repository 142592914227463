import Api from "@src/api";

export default {
  getUser(data) {
    return Api().get(`UserInfo/GetUserByUserName`, {
      params: data,
    });
  },
  searchUser(data) {
    return Api().get(`UserInfo/SearchUserByKeyword`, {
      params: data,
    });
  },
};
