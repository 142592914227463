/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Col,
  Row,
  InputNumber,
  Select,
  DatePicker,
  Button,
  Checkbox,
} from "antd";
import GroupItem from "../../component/Desktop/groupItem";
import AutoMail from "../../component/Desktop/autoMail";
import moment from "moment";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import { getFactories } from "@src/redux/auth";
import { useDispatch, useSelector } from "react-redux";

import {
  KEY,
  lang,
  COMMON_CONSTANT,
  STYLE,
} from "../../constants/common.const";
import API from "../../api/backend/officeDetail";
import KBN from "../../api/backend/kbn";
import BreakLine from "../../component/Desktop/breakLine";
import {
  MSG_CATCH,
  renderOption,
  showMessage,
  INPUT_DATE,
  statusRes,
  isAdminOffice,
  isHeadOffice,
  showMessageChange,
} from "../../constants/utils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CloseCircleOutlined,
  PaperClipOutlined,
  SaveOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import TitlePage from "../../component/Desktop/titlePage";
import { checkRoleScreen } from "../../utils/roles";

const { TextArea } = Input;

function EditOffice() {
  let { id } = useParams();
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const modeEdit =
    window.location.pathname.indexOf("EditOffice") > -1 ||
    window.location.pathname.indexOf("UpdateOffice") > -1; // Use path /EditOffice
  const modeEditInside = modeEdit && !id; // Use path /EditOffice AND not ID
  const history = useHistory();
  const dispatch = useDispatch();
  let initPage = true;
  let [vInit, setvInit] = useState(false);
  let [showLoading, setShowLoading] = useState(false);

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  let [txtOfficeID, settxtOfficeID] = useState(new classText());
  let [txtTitle, settxtTitle] = useState(new classText());
  const txtTitleRef = useRef(null);

  let [txtOfficeServer, settxtOfficeServer] = useState(new classText());
  let [txtNote, settxtNote] = useState(new classText());
  let [txtZipCode1, settxtZipCode1] = useState(new classText());
  let [txtZipCode2, settxtZipCode2] = useState(new classText());
  let [txtAddress, settxtAddress] = useState(new classText());
  let [txtState, settxtState] = useState(new classDdl());
  let [ddlStatus, setddlStatus] = useState(new classDdl());
  const ddlStatusRef = useRef(null);

  let [txtEffectStartDate, settxtEffectStartDate] = useState(new classText());
  let [txtEffectEndDate, settxtEffectEndDate] = useState(new classText());

  let [txtAdmin1, setTxtAdmin1] = useState(new classText());
  let [txtAdmin2, setTxtAdmin2] = useState(new classText());
  let [txtHead, setTxtHead] = useState(new classText());
  const [txtAdmin1Ref, setTxtAdmin1Ref] = useState(null);
  const [txtAdmin2Ref, setTxtAdmin2Ref] = useState(null);
  const [txtHeadRef, setTxtHeadRef] = useState(null);

  let [txtAttachFile, setTxtAttachFile] = useState(new classText());
  const fileEl = useRef(null);
  const txtAttachFileEl = useRef(null);

  // Custoemer Approve
  const [customerApprove, setCustormerApprove] = useState(false);

  // Init
  const init = () => {
    // Open loading
    openLoadingSpinner();

    Promise.all([
      modeEdit ? (id ? API.getDetailByID(id) : API.getDetail()) : null,
      KBN.getPrefectures(),
      KBN.getSiteOfficeMasterStatus(),
    ])
      .then((res) => {
        if (res[0] && !res[0].data && id) {
          history.push("/OfficeManagement");
          return;
        }
        let data = res[0] ? res[0].data : null;
        let stateOption = res[1] ? res[1].data : null;
        let statusOption = res[2] ? res[2].data : null;

        ((stateOption || {}).data || []).map((o) => {
          o.value = `${o.key} - ${o.value}`;
          return o;
        });

        if (modeEdit) {
          // Edit
          data &&
            stateOption &&
            statusOption &&
            showDetail(data, stateOption.data, statusOption.data);
        } else {
          // Create
          settxtState({
            ...txtState,
            options: stateOption.data,
          });
          setddlStatus({
            ...ddlStatus,
            options: statusOption ? statusOption.data : [],
          });
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setvInit(true);
        initPage = true;
        closeLoadingSpinner();
      });
  };
  useEffect(checkRoleScreen(location.pathname, allowRoute) && init, [
    allowRoute,
  ]);

  // Change data
  useEffect(() => {
    initPage = false;
  }, [
    txtOfficeID.value,
    txtTitle.value,
    txtOfficeServer.value,
    txtNote.value,
    txtZipCode1.value,
    txtZipCode2.value,
    txtAddress.value,
    txtState.value,
    ddlStatus.value,
    txtEffectStartDate.value,
    txtEffectEndDate.value,
    txtAttachFile.value,
  ]);
  useEffect(() => {
    if (!txtAdmin1.init || !txtAdmin2.init || !txtHead.init) {
      initPage = false;
    }
  }, [txtAdmin1, txtAdmin2, txtHead]);

  const showDetail = (data, stateOption, statusOption) => {
    settxtOfficeID({ ...txtOfficeID, value: data.siteOfficeMasterCode });
    settxtTitle({ ...txtTitle, value: data.siteOfficeMasterName });
    settxtOfficeServer({
      ...txtOfficeServer,
      value: data.officeServer,
    }); // 事業所サーバー
    settxtNote({ ...txtNote, value: data.note });
    settxtZipCode1({ ...txtZipCode1, value: data.zipCode1 });
    settxtZipCode2({ ...txtZipCode2, value: data.zipCode2 });
    settxtAddress({ ...txtAddress, value: data.address });
    settxtState({
      ...txtState,
      value: data.prefectureCode,
      options: stateOption,
    }); // 都道府県名
    setddlStatus({
      ...ddlStatus,
      value: data.status,
      options: statusOption,
    });
    settxtEffectStartDate({
      ...txtEffectStartDate,
      value: moment(data.startDate),
    });
    settxtEffectEndDate({
      ...txtEffectEndDate,
      value: data.endDate ? moment(data.endDate) : null,
    });
    setCustormerApprove(data.customerApprove);

    // User
    txtAdmin1.users = [data.businessManagerName];
    txtAdmin2.users = [data.depucityDirector1Name];
    txtHead.users = [data.headquartersChargeName];
    setTxtAdmin1({
      ...txtAdmin1,
      users: [data.businessManager],
    });
    setTxtAdmin2({
      ...txtAdmin2,
      users: [data.depucityDirector1],
    });
    setTxtHead({
      ...txtHead,
      users: [data.headquartersCharge],
    });

    setTxtAttachFile({
      ...txtAttachFile,
      value: data.imageName,
    });
  };

  const onCancel = () => {
    if (initPage) {
      backTo();
    } else {
      showMessageChange(backTo, COMMON_CONSTANT.BOM_C018);
    }
  };

  function backTo() {
    modeEditInside ? history.push("/ViewOffice") : history.goBack();
  }

  const onChangFileImg = (e) => {
    let fullFileName = e.target.files[0] ? e.target.files[0].name : "";
    let arrSplit = fullFileName ? fullFileName.split("\\") : [];
    fullFileName =
      arrSplit && arrSplit.length > 0 ? arrSplit[arrSplit.length - 1] : "";
    let fileName = fullFileName.split(".");
    if (fileName.length > 1) {
      // Case not extension
      fileName.pop();
    }

    setTxtAttachFile({ ...txtAttachFile, value: fileName[0] });
    // setTxtFullNameTemplate({ ...txtFullNameTemplate, value: fileName });
    // fileNameProcess(fileName);
  };

  const onSave = () => {
    if (!validate()) {
      return;
    } else {
      showMessageChange(reqServer, COMMON_CONSTANT.BOM_C006);
    }
  };

  const reqServer = () => {
    openLoadingSpinner();
    let data = {
      siteOfficeMasterCode: txtOfficeID.value,
      prefectureCode: txtState.value,
      // prefectureName: "埼玉県",
      siteOfficeMasterName: txtTitle.value.trim(), // Required
      zipCode1: txtZipCode1.value,
      zipCode2: txtZipCode2.value,
      address: txtAddress.value,
      status: ddlStatus.value, // Required
      startDate: txtEffectStartDate.value
        ? moment(txtEffectStartDate.value).format(KEY.DATE_DEFAULT)
        : "",
      endDate: txtEffectEndDate.value
        ? moment(txtEffectEndDate.value).format(KEY.DATE_DEFAULT)
        : "",
      note: txtNote.value,

      // 3 user
      businessManager:
        txtAdmin1.users && txtAdmin1.list.length > 0
          ? txtAdmin1.list[0].value
          : "", // Required
      depucityDirector1:
        txtAdmin2.users && txtAdmin2.list.length > 0
          ? txtAdmin2.list[0].value
          : "",
      headquartersCharge:
        txtHead.users && txtHead.list.length > 0 ? txtHead.list[0].value : "", // Required

      officeServer: txtOfficeServer.value,
      imageName: txtAttachFile.value,
      customerApprove,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append(
      "file",
      fileEl.current.files ? fileEl.current.files[0] : null
    );

    // Call API
    (modeEdit ? API.edit(formData) : API.create(formData))
      .then((res) => {
        dispatch(getFactories());

        if (statusRes(res)) {
          // Success
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backTo);
        } else {
          showMessage(KEY.ERROR, (res && res.data.msg) || MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(
          KEY.ERROR,
          (err.response && err.response.data.msg) || MSG_CATCH()
        );
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  // Validate search
  const validate = () => {
    txtTitle = { ...txtTitle, error: false };
    txtZipCode1 = { ...txtZipCode1, error: false };
    txtZipCode2 = { ...txtZipCode2, error: false };
    txtState = { ...txtState, error: false };
    ddlStatus = { ...ddlStatus, error: false };
    txtEffectStartDate = { ...txtEffectStartDate, error: false };
    txtEffectEndDate = { ...txtEffectEndDate, error: false };

    txtAdmin1 = { ...txtAdmin1, error: false };
    txtAdmin2 = { ...txtAdmin2, error: false };
    txtHead = { ...txtHead, error: false };

    let flagErr = false;

    // Date
    if (txtEffectStartDate.value === null || txtEffectStartDate.value === "") {
      flagErr = true;
      txtEffectStartDate = { ...txtEffectStartDate, error: true };
    } else {
      // End date >= start date
      if (
        txtEffectEndDate.value !== null &&
        txtEffectEndDate.value !== "" &&
        moment(txtEffectEndDate.value).isBefore(
          moment(txtEffectStartDate.value)
        )
      ) {
        flagErr = true;
        txtEffectEndDate = { ...txtEffectEndDate, error: true };
      }
    }
    // Status
    if (ddlStatus.value === null || ddlStatus.value === "") {
      flagErr = true;
      ddlStatus = { ...ddlStatus, error: true };
      ddlStatusRef.current.focus();
    }
    // State
    if (txtState.value === null || txtState.value === "") {
      flagErr = true;
      txtState = { ...txtState, error: true };
    }
    // Postcode
    if (txtZipCode2.value === null || txtZipCode2.value === "") {
      flagErr = true;
      txtZipCode2 = { ...txtZipCode2, error: true };
    }
    if (txtZipCode1.value === null || txtZipCode1.value === "") {
      flagErr = true;
      txtZipCode1 = { ...txtZipCode1, error: true };
    }
    // Title
    if (txtTitle.value === null || txtTitle.value.trim() === "") {
      flagErr = true;
      txtTitle = { ...txtTitle, error: true };
      txtTitleRef.current.focus();
    }

    // Update state
    settxtTitle(txtTitle);
    settxtZipCode1(txtZipCode1);
    settxtZipCode2(txtZipCode2);
    settxtState(txtState);
    setddlStatus(ddlStatus);
    settxtEffectStartDate(txtEffectStartDate);
    settxtEffectEndDate(txtEffectEndDate);

    setTxtAdmin1(txtAdmin1);
    setTxtAdmin2(txtAdmin2);
    setTxtHead(txtHead);

    return !flagErr;
  };

  return (
    <div className="des-content mt70">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={modeEdit ? lang.EDIT_OFFICE_DETAIL : lang.CREATE_OFFICE_DETAIL}
      />
      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">
            {modeEdit ? lang.EDIT_OFFICE_DETAIL : lang.CREATE_OFFICE_DETAIL}
          </h1>
        </div>
        <div className="mt15"></div>
        {/* List item */}
        {modeEdit && (
          <>
            <BreakLine />
            <RowInput name="事業所コード" box>
              {txtOfficeID.value}
            </RowInput>
          </>
        )}
        <BreakLine />
        <RowInput name="事業所名" box required>
          <Input
            ref={txtTitleRef}
            className={(txtTitle.error ? STYLE.BORDER_RED : "") + " w100"}
            value={txtTitle.value}
            maxLength={255}
            onChange={(e) => {
              settxtTitle({ ...txtTitle, value: e.target.value });
            }}
          />
        </RowInput>
        <BreakLine />
        {/* <RowInput name="事業所サーバー" box>
          <Input
            value={txtOfficeServer.value}
            maxLength={255}
            onChange={(e) => {
              settxtOfficeServer({ ...txtOfficeServer, value: e.target.value });
            }}
          />
        </RowInput>
        <BreakLine /> */}

        <RowInput name="郵便番号" box required>
          <Row>
            <Col span="3">
              <Input
                type="text"
                disabled={modeEdit}
                className={txtZipCode1.error ? STYLE.BORDER_RED : ""}
                value={txtZipCode1.value}
                maxLength={3}
                onChange={(v) => {
                  settxtZipCode1({ ...txtZipCode1, value: v.target.value });
                }}
              />
            </Col>
            <Col span="1" className="ml20 mr-5">
              {"-"}
            </Col>
            <Col span="3">
              <Input
                type="text"
                disabled={modeEdit}
                className={txtZipCode2.error ? STYLE.BORDER_RED : ""}
                value={txtZipCode2.value}
                maxLength={4}
                onChange={(v) => {
                  settxtZipCode2({ ...txtZipCode2, value: v.target.value });
                }}
              />
            </Col>
          </Row>
        </RowInput>
        <BreakLine />
        <RowInput name="住所" box>
          <TextArea
            rows={4}
            value={txtAddress.value}
            onChange={(e) => {
              settxtAddress({ ...txtAddress, value: e.target.value });
            }}
          />
        </RowInput>
        <BreakLine />
        <RowInput name="都道府県名" box required>
          <Select
            disabled={modeEdit}
            value={txtState.value}
            className={(txtState.error ? STYLE.BORDER_RED : "") + " w100"}
            onChange={(v) => {
              settxtState({ ...txtState, value: v });
            }}
          >
            {renderOption(txtState.options)}
          </Select>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.STATUS} box required>
          <Select
            ref={ddlStatusRef}
            value={ddlStatus.value}
            className={(ddlStatus.error ? STYLE.BORDER_RED : "") + " w100"}
            onChange={(v) => {
              setddlStatus({ ...ddlStatus, value: v });
            }}
          >
            {renderOption(ddlStatus.options)}
          </Select>
        </RowInput>
        <BreakLine />
        <RowInput name="契約開始日" box required>
          <DatePicker
            className={txtEffectStartDate.error ? STYLE.BORDER_RED : ""}
            format={KEY.DATE_DEFAULT}
            value={txtEffectStartDate.value}
            placeholder=""
            onChange={(v) => {
              settxtEffectStartDate({ ...txtEffectStartDate, value: v });
            }}
            {...INPUT_DATE}
          />
        </RowInput>
        <BreakLine />
        <RowInput name="契約終了日" box>
          <DatePicker
            className={txtEffectEndDate.error ? STYLE.BORDER_RED : ""}
            format={KEY.DATE_DEFAULT}
            value={txtEffectEndDate.value}
            onChange={(v) => {
              settxtEffectEndDate({ ...txtEffectEndDate, value: v });
            }}
            placeholder=""
            {...INPUT_DATE}
          />
        </RowInput>
        <BreakLine />
        <RowInput name="事業所長" box>
          {vInit && (
            <AutoMail
              className={txtAdmin1.error ? STYLE.BORDER_RED : ""}
              setRef={setTxtAdmin1Ref}
              get={txtAdmin1}
              set={setTxtAdmin1}
              placeholder="点検者"
            ></AutoMail>
          )}
        </RowInput>
        <BreakLine />
        <RowInput name="副所長" box>
          {vInit && (
            <AutoMail
              className={txtAdmin2.error ? STYLE.BORDER_RED : ""}
              setRef={setTxtAdmin2Ref}
              get={txtAdmin2}
              set={setTxtAdmin2}
              placeholder="点検者"
            ></AutoMail>
          )}
        </RowInput>
        <BreakLine />
        <RowInput name="本社担当" box>
          {vInit && (
            <AutoMail
              className={txtHead.error ? STYLE.BORDER_RED : ""}
              setRef={setTxtHeadRef}
              get={txtHead}
              set={setTxtHead}
              placeholder="点検者"
            ></AutoMail>
          )}
        </RowInput>
        <BreakLine />
        <RowInput name="備考" box>
          <TextArea
            rows={4}
            value={txtNote.value}
            onChange={(e) => {
              settxtNote({ ...txtNote, value: e.target.value });
            }}
          />
        </RowInput>
        <BreakLine />
        <RowInput name="写真" box>
          <Row>
            <Col span={16} xl={18}>
              <input
                type="file"
                className="d-none"
                accept=".jpg,.jpeg,.png,.bmp"
                ref={fileEl}
                onChange={onChangFileImg}
              />
              <Input
                disabled
                className={
                  (txtAttachFile.error ? STYLE.BORDER_RED : "") + " input-clear"
                }
                ref={txtAttachFileEl}
                value={txtAttachFile.value}
              />
              {txtAttachFile.value && txtAttachFile.value !== "" && (
                <span
                  className="input-clear-icon"
                  onClick={() => {
                    // Clear input file name
                    setTxtAttachFile({
                      ...txtAttachFile,
                      value: "",
                    });
                    // Clear file
                    fileEl.current.value = "";
                  }}
                >
                  <CloseCircleFilled />
                </span>
              )}
            </Col>
            <Col span={8} xl={6} className="pl5">
              <Button
                type="button"
                icon={<PaperClipOutlined className="orange" />}
                className="w100 buttonPC button--outline --todo--"
                onClick={(e) => fileEl.current.click()}
              >
                {lang.ATTACH}
              </Button>
            </Col>
          </Row>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.CUSTORMER_APPROVE} box>
          <Checkbox
            checked={customerApprove}
            onChange={(e) => setCustormerApprove(e.target.checked)}
          >
            {" "}
            {customerApprove ? lang.YES : lang.NO}
          </Checkbox>
        </RowInput>
        <BreakLine />
        <Row justify="end" className="mt10">
          {(isAdminOffice() || isHeadOffice()) && (
            <Button
              className="buttonPC button--info wAuto ml15"
              onClick={onSave}
            >
              <SaveOutlined />
              {lang.SAVE}
            </Button>
          )}
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={onCancel}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
    </div>
  );
}

export default EditOffice;
