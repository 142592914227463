import Api from "@src/api";
const headers = {
  "Content-Type": "multipart/form-data",
};
export default {
  searchChecklistTemplate(templateName, reportType) {
    let endpoints = `ChecklistTemplate`;
    let params = {
      templateName: templateName ? templateName : "",
      reportType: reportType ? reportType : "",
    };
    return Api().get(endpoints, { params });
  },
  getReportType() {
    let endpoint = "Kbn/TemplateReportType";
    return Api().get(endpoint);
  },
  deleteTemplate(id) {
    let endpoint = `ChecklistTemplate/${id}`;
    return Api().delete(endpoint);
  },
  addChecklistTemplate(params, fileData) {
    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        IsNewTemplate: true,
        TemplateTitle: params.name,
        ReportType: params.type,
      })
    );
    formData.append("file", fileData);

    let endpoints = "ChecklistTemplate";
    return Api().post(endpoints, formData, { headers: headers });
  },
  editChecklistTemplate(params, fileData) {
    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        IsNewTemplate: false,
        TemplateTitle: params.name,
        ReportType: params.type,
      })
    );
    fileData && formData.append("file", fileData);

    let endpoints = `ChecklistTemplate/${params.id}`;
    return Api().put(endpoints, formData, { headers: headers });
  },
  uploadFileReport(formData) {
    let endpoints = "FileUpload/DynamicReport";
    return Api().post(endpoints, formData, { headers: headers });
  },
  getDetailReport(id) {
return Api().get("DynamicReport/"+id)
  },
  getGroupReport() {
    return Api().get("DynamicReport/group");
  },
  getGroupReportKbn(params = {}) {
    return Api().get("kbn/getDynamicReportGroup",{params});
  },
  updateGroupReport(params) {
    return Api().post("DynamicReport/group", params);
  },
  deleteGroupReport(id) {
    return Api().delete(`DynamicReport/group/${id}`)
  },
  createReport(params) {
    return Api().post('DynamicReport',params)
  },
  updateReport(id,params) {
    return Api().put(`DynamicReport/${id}`,params)
  },
  getCheckItem(params){
    return Api().get("CheckItem",{params});
  },
  getCheckItemPreCheck(params){
    return Api().get("CheckItem/GetPreCheckItems",{params});
  },
  getChecklistPreCheck(params){
    return Api().get("Checklists?", {params});
  },
  getMachine(params) {
      return Api().get("MachineForSiteOffice/SearchMachine", { params });
  },
  swapOrder(id1,id2){
    return Api().post(`DynamicReport/swapOrder?id1=${id1}&id2=${id2}`)
  },
  downLoadTemPlate(){
    return Api().get(`DynamicReport/DownloadTemplateImportDynamicReport`)
  }
};
