import Api from "@src/api";
export default {
  getEvents(type, startTime, endTime) {
    const typeText = type ? `type=${type}&` : '';
    const startTimeText = startTime ? `startTime=${startTime}&` : '';
    const endTimeText = endTime ? `endTime=${endTime}` : '';

    return Api().get(`Calendar?${typeText}${startTimeText}${endTimeText}`);
  },
  setEvent(body) {
    return Api().post(`Calendar`, body);
  },
  updateEvent(body, id) {
    return Api().put(`Calendar/${id}`, body);
  },
  deleteEvent(id) {
    return Api().delete(`Calendar/${id}`);
  },
  getCalendarConfig() {
    return Api().get(`Calendar/CalendarConfig`);
  },
  setCalendarConfig(body) {
    return Api().post(`Calendar/UpdateCalendarConfig`, body);
  },
};
