// this function help to check exactly type of value
// typeOf([]) => 'Array'
// typeOf(new Date()) => 'Date'
// typeOf(new Function()) => 'Function'


export function typeOf(value) {
    return Object.prototype.toString.call(value).slice(8, -1);
}

//add table pdf

// var rows = [
//     {
//       "col1": status, 
//       "col2": `${data1}\n${data2}`, 
//       "col3": creator, 
//       "col4": date.getUTCDate()
//     },

export const addTablePdf = (doc,columns,rows) => {
    
   
const listRow = rows.map(rowItem => {
    let rowItemResult = {};
    columns.forEach(({key},index) => {
        rowItemResult[key] = rowItem[key]
    })
    return rowItemResult
})
doc.autoTable(columns.map(item => ({...item,title:"oll"})), listRow, {
    styles: {
      fillColor:"blue",
      lineColor: 240, 
      lineWidth: 1,
    },
    columnStyles: {
      col1: {fillColor: false},
      col2: {fillColor: false},
      col3: {fillColor: false},
      col4: {fillColor: false},
      col5: {fillColor: false},
      col6: {fillColor: false},        
    },
    margin: {top: 60},
    addPageContent: function(data) {
      doc.text("", 40, 30);
    }
});
}

export function roundToOneDecimal(number) { 
  return Math.round(number * 10) / 10;
}