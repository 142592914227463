import React, { useEffect, useState } from "react";
import { Collapse, Form, Row, Col, Modal, Drawer } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import "./group.scss";
import { COMMON_CONSTANT, KEY, lang } from "../../../constants/common.const";
import { useMediaQuery } from "react-responsive";

const { Panel } = Collapse;

function CustomSelectCL({ data, visible, onCreate, onCancel }) {
  const [form] = Form.useForm();
  const [listForm, updateListForm] = useState([]);
  const [checkAll, updateCheckAll] = useState({});
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  useEffect(() => {
    if (visible) {
      const arrayData = [];
      let defaultValue = {};
      let objCheck = {};
      data.checklistGroups.forEach((tab) => {
        arrayData.push(tab);
        let arrayOption = [];
        tab.checklistMachines.forEach((machine) => {
          arrayOption.push({
            label: machine.machineForSiteOfficeName,
            value: machine.id,
          });
        });
        tab.options = arrayOption;
        defaultValue = Object.assign(defaultValue, {
          [tab.id]: tab.machineIds,
        });
        if (tab.machineIds.length === tab.options.length) {
          objCheck =  Object.assign(objCheck, { [tab.id]: true });
        }
      });
      updateCheckAll(objCheck);
      form.setFieldsValue(defaultValue);
      updateListForm(arrayData);
    } else {
      updateListForm([]);
      updateCheckAll({});
    }
  }, [visible]);
  const onFinish = () => {
    data.checklistGroups.forEach((tab) => {
      for (const id in form.getFieldsValue()) {
        if (tab.id === Number(id)) {
          tab.machineIds = form.getFieldValue(id);
        }
      }
    });
    onCreate(data);
  };
  const checkAllCB = (event, id) => {
    let objCheckAll = Object.assign({}, checkAll);
    objCheckAll[id] = !objCheckAll[id];
    updateCheckAll(objCheckAll);
    if (event.target.checked) {
      const arrayMachineIds = [];
      listForm.forEach((tab) => {
        if (tab.id === id) {
          tab.checklistMachines.forEach((machine) => {
            arrayMachineIds.push(machine.id);
          });
        }
      });
      form.setFieldsValue({ [id]: arrayMachineIds });
    } else {
      form.setFieldsValue({ [id]: [] });
    }
  };
  const onChangeCheckGroup = (data, tab) => {
    if (data.length === tab.options.length) {
      let objCheckAll = Object.assign({}, checkAll);
      objCheckAll[tab.id] = true;
      updateCheckAll(objCheckAll);
    } else {
      let objCheckAll = Object.assign({}, checkAll);
      objCheckAll[tab.id] = false;
      updateCheckAll(objCheckAll);
    }
  };
  return isDesktopOrLaptop ? (
    <Modal
      visible={visible}
      title="点検表カスタマイズ"
      okText="確認"
      cancelText="キャンセル"
      onCancel={onCancel}
      onOk={onFinish}
    >
      <Form form={form}>
        <Collapse className="custom-checklist" defaultActiveKey={"0"} ghost>
          {listForm.map((tab, index) => (
            <Panel
              forceRender={true}
              header={
                <Row className="rowNotMargin">
                  <Col flex="auto">{tab.checklistGroupName}</Col>
                  <Col onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      checked={checkAll[tab.id]}
                      onChange={(e) => checkAllCB(e, tab.id)}
                    >
                      全機器選択
                    </Checkbox>
                  </Col>
                </Row>
              }
              key={index}
            >
              <Form.Item name={tab.id}>
                <Checkbox.Group
                  options={tab.options}
                  onChange={(event) => onChangeCheckGroup(event, tab)}
                />
              </Form.Item>
            </Panel>
          ))}
        </Collapse>
      </Form>
    </Modal>
  ) : (
    <Drawer
      className="menuBottom"
      title="点検表カスタマイズ"
      placement="bottom"
      onClose={onCancel}
      visible={visible}
      // visible={true}
    >
      <Form form={form}>
        <Collapse className="custom-checklist" defaultActiveKey={"0"} ghost>
          {listForm.map((tab, index) => (
            <Panel
              forceRender={true}
              header={
                <Row className="rowNotMargin">
                  <Col flex="auto">{tab.checklistGroupName}</Col>
                  <Col onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      checked={checkAll[tab.id] !== undefined ? checkAll[tab.id] : tab.options.length === tab.machineIds.length}
                      onChange={(e) => checkAllCB(e, tab.id)}
                    >
                      全機器選択
                    </Checkbox>
                  </Col>
                </Row>
              }
              key={index}
            >
              <Form.Item name={tab.id}>
                <Checkbox.Group
                  options={tab.options}
                  onChange={(event) => onChangeCheckGroup(event, tab)}
                />
              </Form.Item>
            </Panel>
          ))}
        </Collapse>
      </Form>
      <Row className="menuBottom-footer">
        <Col span={12}>
          <div
            className="menuBottom-footer-btnCancel content-center"
            onClick={onCancel}
          >
            {lang.CANCEL}
          </div>
        </Col>
        <Col span={12}>
          <div
            className="menuBottom-footer-btnConfirm content-center "
            onClick={onFinish}
          >
            {COMMON_CONSTANT.ADD}
          </div>
        </Col>
      </Row>
    </Drawer>
  );
}

export default CustomSelectCL;
