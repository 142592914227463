import {
  CloseCircleOutlined,
  CloudDownloadOutlined,
  PaperClipOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import BoxContent from "@src/component/Desktop/content";
import { COMMON_CONSTANT, lang } from "@src/constants/common.const";
import {
  addTemplateChecklist,
  editTemplateChecklist,
  updateCurrentTemplate,
} from "@src/redux/reportManagement";
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function HandleFuncTemplate() {
  const dispatch = useDispatch();
  const { currentTemplate, reportType } = useSelector(
    (state) => state.reportManagement
  );
  const [status, setStatus] = useState("");
  const [nameFile, updateNameFile] = useState(null);
  const [dataFile, updateDataFile] = useState(null);
  const [validateFile, setValidateFile] = useState(false);
  const { allowRoute } = useSelector((state) => state.auth);
  const checkFirstRender = useRef(0);

  const history = useHistory();
  const inputFile = useRef(null);
  const [formModal] = Form.useForm();
  // init
  useEffect(() => {
    if (currentTemplate) {
      if (currentTemplate.type !== null) {
        if (currentTemplate.type === "delete") {
          setStatus("warning");
        } else {
          setStatus("info");
        }
      } else if (currentTemplate && !currentTemplate.data) {
        history.push("/TemplateManagement");
      } else {
        //Empty
      }
    } else {
      history.push("/TemplateManagement");
    }
  }, [currentTemplate, history]);

  useEffect(() => {
    if (
      checkFirstRender.current++ >= 1 &&
      currentTemplate &&
      ["edit", "view"].includes(currentTemplate.type)
    ) {
      dispatch(
        updateCurrentTemplate({
          type: null,
          data: null,
        })
      );
    }
  }, [allowRoute]);
  // update name file excel at currentTemplate.type edit
  useEffect(() => {
    if (
      currentTemplate &&
      currentTemplate.data &&
      currentTemplate.data.attachment
    ) {
      updateNameFile(currentTemplate.data.attachment);
    }
  }, [currentTemplate]);
  // validate form export excel
  useEffect(() => {
    if (nameFile) {
      let validExts = [".xlsx", ".xls"];
      let fileExt = nameFile;
      fileExt = fileExt.substring(fileExt.lastIndexOf("."));
      if (validExts.indexOf(fileExt) < 0) {
        setValidateFile("wrongFormat");
      } else {
        setValidateFile("");
      }
    }
  }, [nameFile]);
  // cancel modal
  const handleCancel = () => {
    const { tempName, category, tempCode } = formModal.getFieldsValue(true);
    if (
      ((tempName || category || tempCode || nameFile) &&
        currentTemplate.type === "add") ||
      (currentTemplate.type === "edit" &&
        !(
          tempName === currentTemplate.data.tempName &&
          category === currentTemplate.data.categoryValue &&
          tempCode === currentTemplate.data.tempCode &&
          nameFile === currentTemplate.data.attachment
        ))
    ) {
      Modal.confirm({
        centered: true,
        title: <span style={{ fontWeight: "bold" }}>{lang.WARMING}</span>,
        content: COMMON_CONSTANT.BOM_C018,
        okText: lang.OK,
        cancelText: COMMON_CONSTANT.CLEAR,
        onOk: () => history.goBack(),
      });
    } else {
      history.goBack();
    }
  };
  const callAPI = (values) => {
    if (currentTemplate && currentTemplate.type === "add") {
      dispatch(
        addTemplateChecklist(
          {
            name: values.tempName ? values.tempName.trim() : "",
            type: values.category,
          },
          dataFile,
          history
        )
      );
    }
    if (currentTemplate && currentTemplate.type === "edit") {
      dispatch(
        editTemplateChecklist(
          {
            name: values.tempName ? values.tempName.trim() : "",
            type: values.category,
            id: values.tempCode,
          },
          dataFile,
          history
        )
      );
    }
  };
  // currentTemplate.data submit from modal
  const onSubmitModal = (values) => {
    const { tempCode, tempName, category } = values;
    if (!nameFile) {
      setValidateFile("required");
    } else {
      if (tempName || category || tempCode) {
        Modal.confirm({
          centered: true,
          title: <span style={{ fontWeight: "bold" }}>{lang.WARMING}</span>,
          content: lang.DO_YOU_WANT_TO_REGISTRY,
          okText: lang.OK,
          cancelText: COMMON_CONSTANT.CLEAR,
          onOk: () => callAPI(formModal.getFieldsValue(true)),
        });
      }
    }
  };
  // render select report currentTemplate.type
  const optionSelectReportType = (data) => {
    if (data && data.length) {
      let array = [];
      data.forEach((item) => {
        array.push(
          <Select.Option key={item.key} value={item.key}>
            {item.value}
          </Select.Option>
        );
      });
      return array;
    } else {
      return null;
    }
  };
  const validateTempName = async (rule, value) => {
    if ((value && value.trim() === "") || !value) {
      throw new Error(lang.PLEASE_INPUT_NAME_TEMPLATE);
    } else {
      if (
        (currentTemplate.type === "edit" && currentTemplate.data) ||
        (currentTemplate && currentTemplate.type !== "edit")
      ) {
        currentTemplate.listNameTemp &&
          currentTemplate.listNameTemp.forEach((item) => {
            if (currentTemplate.type === "edit") {
              if (
                item === value.trim() &&
                item !== currentTemplate.data.tempName
              ) {
                throw new Error(lang.NAME_OF_TEMPLATE_HAS_EXISTED);
              } else {
                return null;
              }
            } else {
              if (item === value.trim()) {
                throw new Error(lang.NAME_OF_TEMPLATE_HAS_EXISTED);
              } else {
                return null;
              }
            }
          });
      } else {
        return "";
      }
    }
  };
  // handle upload and display name file attach
  const uploadFile = () => {
    clearFile();
    inputFile.current.click();
  };
  const clearFile = () => {
    // updateFileReader(null);
    updateNameFile(null);
    updateDataFile(null);
    inputFile.current.value = null;
  };
  // get currentTemplate.data file
  const onImportExcel = (file) => {
    // Get the uploaded file object
    const { files } = file.target;
    updateNameFile(files[0].name);
    updateDataFile(files[0]);
  };
  return (
    (currentTemplate &&
      (currentTemplate.type === "add" || currentTemplate.type === "edit") && (
        <div className="des-content">
          <BoxContent>
            <Form
              form={formModal}
              onFinish={onSubmitModal}
              initialValues={
                currentTemplate.type === "edit"
                  ? {
                      tempName: currentTemplate.data.tempName,
                      category: currentTemplate.data.categoryValue,
                      tempCode: currentTemplate.data.tempCode,
                    }
                  : {
                      tempName: null,
                      category: null,
                      tempCode: null,
                    }
              }
            >
              <Row
                gutter={[28, 28]}
                align="middle"
                justify="center"
                className="rowNotMargin"
              >
                <Divider className="customDivider" />
                {currentTemplate.type === "edit" && (
                  <>
                    <Col span={4} className="custom"></Col>
                    <Col span={4} className="title-form-item text-end">
                      {lang.TEMPLATE_CODE}
                    </Col>
                    <Col span={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <Form.Item name="tempCode" className="rowNotMargin">
                        <Input
                          maxLength="255"
                          disabled
                          placeholder={lang.TEMPLATE_CODE}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col span={4} className="custom"></Col>
                    <Divider className="customDivider" />
                  </>
                )}
                <Col span={4} className="custom"></Col>
                <Col span={4} className="title-form-item text-end">
                  {lang.TEMPLATE_NAME} <span style={{ color: "red" }}>*</span>
                </Col>
                <Col span={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Form.Item
                    name="tempName"
                    className="rowNotMargin"
                    rules={[{ validator: validateTempName }]}
                  >
                    <Input
                      maxLength="255"
                      placeholder={lang.TEMPLATE_NAME}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={4} className="custom"></Col>
                <Divider className="customDivider" />
                <Col span={4} className="custom"></Col>
                <Col span={4} className="title-form-item text-end">
                  {lang.CATEGORY} <span style={{ color: "red" }}>*</span>
                </Col>
                <Col span={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Form.Item
                    name="category"
                    className="rowNotMargin"
                    rules={[
                      {
                        required: true,
                        message: lang.PLEASE_INPUT_CATEGORY,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder={lang.CATEGORY}
                    >
                      {optionSelectReportType(reportType)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} className="custom"></Col>
                <Divider className="customDivider" />
                <Col span={4} className="custom"></Col>
                <Col span={4} className="title-form-item text-end">
                  {lang.ATTACH} <span style={{ color: "red" }}>*</span>
                </Col>
                <Col span={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Row className="rowNotMargin" wrap="false">
                    <Col span={15}>
                      <Input
                        type="text"
                        disabled
                        value={nameFile ? nameFile : ""}
                      />
                      <input
                        type="file"
                        hidden
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={onImportExcel}
                        ref={inputFile}
                      />
                    </Col>
                    <Col span={1} />
                    <Col span={8}>
                      <Button
                        className="buttonPC button--outline --todo-- wAuto"
                        onClick={uploadFile}
                        icon={
                          <PaperClipOutlined
                            style={{ color: "#f50", fontSize: "18px" }}
                          />
                        }
                      >
                        {lang.ATTACH}
                      </Button>
                    </Col>
                    <Col span={24}>
                      {validateFile === "wrongFormat" && (
                        <span className="textError">
                          {lang.TEMPLATE_MUST_IS_EXCEL_FILE}
                        </span>
                      )}
                      {validateFile === "required" && (
                        <span className="textError">
                          {lang.PLEASE_INPUT_NAME_TEMPLATE}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={4} className="custom"></Col>
                <Divider className="customDivider" />
                <Col span={24} style={{ paddingTop: "30px", paddingRight: 0 }}>
                  <Row className="rowNotMargin" align="middle">
                    <Col flex="auto"></Col>
                    {currentTemplate.type === "edit" && (
                      <Col flex="none">
                        <a
                          href={currentTemplate.data.linkDown}
                          download={currentTemplate.data.attachment}
                        >
                          <Button
                            className="buttonPC button--back"
                            icon={
                              <CloudDownloadOutlined
                                style={{ color: "#f50", fontSize: "18px" }}
                              />
                            }
                          >
                            {lang.EXPORT}
                          </Button>
                        </a>
                      </Col>
                    )}

                    <Col flex="none" style={{ paddingLeft: "8px" }}>
                      <Button
                        className={"buttonPC button--" + status}
                        htmlType="submit"
                        icon={<SaveOutlined />}
                      >
                        {lang.SAVE}
                      </Button>
                    </Col>
                    <Col
                      flex="none"
                      style={{ paddingLeft: "8px", paddingRight: 0 }}
                    >
                      <Button
                        className="buttonPC button--outline --todo-- wAuto"
                        onClick={() => {
                          handleCancel();
                        }}
                        icon={<CloseCircleOutlined />}
                      >
                        {COMMON_CONSTANT.CLEAR}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </BoxContent>
        </div>
      )) ||
    (currentTemplate && currentTemplate.type === "view" && (
      <div className="des-content">
        <BoxContent>
          <Divider className="customDivider" />
          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.TEMPLATE_CODE}
            </Col>
            <Col span={18} className="content-form-item">
              {currentTemplate.data.tempCode}
            </Col>
          </Row>
          <Divider className="customDivider" />
          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.TEMPLATE_NAME}
            </Col>
            <Col span={18} className="content-form-item text-cut">
              <span title={currentTemplate.data.tempName}>
                {currentTemplate.data.tempName}
              </span>
            </Col>
          </Row>
          <Divider className="customDivider" />

          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.CATEGORY}
            </Col>
            <Col span={18} className="content-form-item">
              {currentTemplate.data.category}
            </Col>
          </Row>
          <Divider className="customDivider" />
          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.ATTACH}
            </Col>
            <Col span={12}>{currentTemplate.data.attachment}</Col>
            <Col span={6} style={{ padding: 0 }} className="content-form-item">
              <a
                href={currentTemplate.data.linkDown}
                download={currentTemplate.data.attachment}
              >
                <Button
                  className="buttonPC button--outline --todo-- wAuto"
                  icon={
                    <CloudDownloadOutlined
                      style={{
                        color: "#f50",
                      }}
                    />
                  }
                >
                  {lang.EXPORT}
                </Button>
              </a>
            </Col>
          </Row>
          <Divider className="customDivider" />
          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.EDITOR}
            </Col>
            <Col span={18} className="content-form-item">
              {currentTemplate.data.user}
            </Col>
          </Row>
          <Divider className="customDivider" />
          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.REGISTRATION_DATE}
            </Col>
            <Col span={18} className="content-form-item">
              {currentTemplate.data.createTime}
            </Col>
          </Row>
          <Divider className="customDivider" />
          <Row
            className="rowNotMargin"
            gutter={[28, 28]}
            align="middle"
            justify="center"
          >
            <Col span={6} className="title-form-item text-end custom-title">
              {lang.EDITING_DATE}
            </Col>
            <Col span={18} className="content-form-item">
              {currentTemplate.data.updateTime}
            </Col>
          </Row>
          <Divider className="customDivider" />

          <Row
            className="rowNotMargin"
            gutter={[0, 28]}
            align="middle"
            justify="center"
          >
            <Col span={24} style={{ paddingTop: "30px" }}>
              <Row className="rowNotMargin" align="middle">
                <Col flex="auto"></Col>
                <Col flex="none" style={{ paddingLeft: "16px" }}>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto"
                    htmlType="submit"
                    icon={<RollbackOutlined style={{ fontSize: "14px" }} />}
                    onClick={handleCancel}
                  >
                    {lang.BACK}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </BoxContent>
      </div>
    ))
  );
}
export default HandleFuncTemplate;
