/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Button,
  Input,
  Checkbox,
  Select,
  Divider,
} from "antd";
import {
  SearchOutlined,
  FilterFilled,
  DoubleRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import API from "../../api/backend/inventory";
import InfiniteScroll from "react-infinite-scroller";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import KBN from "../../api/backend/kbn";
import classText from "../../models/control/text";
import {
  enterSearch,
  MSG_CATCH,
  renderOption,
  showMessage,
  statusRes,
} from "../../constants/utils";
import Loading from "../../component/Desktop/loading/loading";
import classDdl from "../../models/control/dropdownlist";
const { Content } = Layout;

export default function BoxItemWarehouses({
  onAddItems2Grid,
  selSearch: _selSearch,
  grid: _grid,
  selAdded: _selAdded,
}) {
  const [selSearch, setSelSearch] = _selSearch;
  const [grid, setGrid] = _grid;
  const [selAdded, setSelAdded] = _selAdded;
  const [txtItem, setTxtItem] = useState(new classText());
  const [txtItemCode, setTxtItemCode] = useState(new classText());
  const [ddlWarehouses, setDdlWarehouses] = useState(new classDdl());
  const [txtCategory, setTxtCategory] = useState(new classText());
  const [loading, setLoading] = useState(false);

  // Column search
  const columnsSearchItem = [
    // Checkbox
    {
      title: () => {
        const { data } = grid;
        return (
          <Checkbox
            checked={data.length > 0 && selSearch.length === data.length}
            onChange={(e) => {
              setSelSearch(
                e.target.checked
                  ? data.map((v) => v.inventoryWarehouseItemCode)
                  : []
              );
            }}
          />
        );
      },
      align: "center",
      width: "40px",
      render: (txt, row) => {
        const added = selAdded.indexOf(row.inventoryWarehouseItemCode) >= 0;

        return (
          <Checkbox
            checked={
              selSearch.indexOf(row.inventoryWarehouseItemCode) >= 0 || added
            }
            onChange={(e) => {
              let listCheck = [...selSearch];
              if (e.target.checked) {
                // Checked
                listCheck.push(row.inventoryWarehouseItemCode);
              } else {
                //UnChecked
                listCheck = listCheck.filter(
                  (v) => v !== row.inventoryWarehouseItemCode
                );
              }
              setSelSearch(listCheck);
            }}
            disabled={added}
          />
        );
      },
    },
    // Item
    {
      title: lang.ITEM_NAME,
      dataIndex: "inventoryItemName",
      align: "center",
    },
    // Warehouses
    {
      title: lang.WAREHOUSES_NAME,
      dataIndex: "inventoryWarehouseName",
      align: "center",
    },
    // Item code
    {
      title: lang.ITEM_CODE,
      dataIndex: "inventoryWarehouseItemCode",
      align: "center",
      width: 200,
    },
    // Category
    {
      title: lang.CATEGORY_NAME,
      dataIndex: "categoryName",
      align: "center",
    },
  ];
  const onSearch = (offset = 0) => {
    setGrid((preV) => ({ ...preV, loading: true }));

    API.searchItemsForTransactions({
      inventoryItemName: txtItem.value.trim(),
      inventoryWarehouseItemCode: txtItemCode.value.trim(),
      categoryName: txtCategory.value.trim(),
      inventoryWarehouseCode: ddlWarehouses.value,
      offset: offset,
      limit: 20,
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            // Clear checkbox
            setSelSearch([]);
            // Init, Click
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            // Load more
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setGrid((preV) => ({ ...preV, loading: false }));
      });
  };
  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid((preV) => ({ ...preV, loading: false, hasMore: false }));
    } else {
      // load more
      onSearch(data.length);
    }
  };

  // Init
  useEffect(() => {
    //
    onSearch();
    // set state ddl
    KBN.getStorage().then((res) => {
      if (statusRes(res)) {
        setDdlWarehouses({ ...ddlWarehouses, options: res.data.data });
      } else {
        throw new Error();
      }
    });
  }, []);
  // on Filter
  useEffect(() => {
    onSearch();
  }, [txtItemCode.value, ddlWarehouses.value, txtCategory.value]);

  // Change warehouses
  const onChangeWarehouses = (v) => {
    setDdlWarehouses({ ...ddlWarehouses, value: v });
  };

  return (
    <Content
      className={"site-layout-background"}
      style={{
        margin: "24px 16px",
        padding: 20,
      }}
    >
      <div className="titleText2">
        <FilterFilled className="svg-default" /> {lang.SEARCH}
      </div>
      <Row>
        {/* From */}
        <Col xs={24} lg={6}>
          {/* Input search */}
          <Row className="align-center mt10">
            {/* Item name */}
            <Col span={24} className="content-center">
              <Input
                style={{
                  width: "calc(100% - 108px)",
                }}
                placeholder={lang.ITEM_NAME}
                maxLength={255}
                value={txtItem.value}
                onChange={(e) => {
                  setTxtItem({ ...txtItem, value: e.target.value });
                }}
                {...enterSearch(onSearch)}
              />
              <Button
                className="buttonPC button--info wAuto ml5"
                onClick={() => {
                  onSearch();
                }}
              >
                <SearchOutlined />
                {lang.SEARCH}
              </Button>
            </Col>
          </Row>
          {/* Filter */}
          <Divider className="mr0 ml0 mt10 mb5" />
          <div className="mb5">
            <b>{lang.FILTER}</b>
            {lang.COLON}
          </div>
          {/* Item code */}
          <Row className="align-center mt10">
            <Col span={24}>{lang.ITEM_CODE}</Col>
            <Col span={24}>
              <Input
                maxLength={255}
                value={txtItemCode.value}
                onChange={(e) => {
                  setTxtItemCode({ ...txtItemCode, value: e.target.value });
                }}
                {...enterSearch(onSearch)}
              />
            </Col>
          </Row>
          {/* Warehouses */}
          <Row className="align-center mt10">
            <Col span={24}>{lang.WAREHOUSES_MASTER}</Col>
            <Col span={24}>
              <Select
                value={ddlWarehouses.value}
                className="w100"
                onChange={onChangeWarehouses}
              >
                {renderOption(ddlWarehouses.options)}
              </Select>
            </Col>
          </Row>
          {/* Category */}
          <Row className="align-center mt10">
            <Col span={24}>{lang.CATEGORY}</Col>
            <Col span={24}>
              <Input
                maxLength={255}
                value={txtCategory.value}
                onChange={(e) => {
                  setTxtCategory({ ...txtCategory, value: e.target.value });
                }}
                {...enterSearch(onSearch)}
              />
            </Col>
          </Row>
        </Col>
        {/* >> */}
        <Col xs={0} lg={2}>
          <DoubleRightOutlined
            style={{
              fontSize: "60px",
              color: "#b9b9b9",
              width: "100%",
              margin: "80px 0 0",
            }}
          />
        </Col>
        {/* To */}
        <Col xs={24} lg={16}>
          {/* Result items */}
          <Row>
            <Col
              span={24}
              style={{
                height: "240px",
                overflowY: "auto",
              }}
            >
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={onLoadMore}
                hasMore={!grid.loading && grid.hasMore}
                useWindow={false}
              >
                <Table
                  rowKey="inventoryWarehouseItemCode"
                  dataSource={grid.data}
                  columns={columnsSearchItem}
                  pagination={false}
                  loading={grid.loading}
                />
              </InfiniteScroll>
            </Col>
            <Col span={24} className="right">
              <Button
                className="buttonPC button--info wAuto mt15"
                onClick={() => {
                  setSelSearch([]);
                  onAddItems2Grid();
                }}
                disabled={selSearch.length === 0}
              >
                <PlusOutlined />
                {COMMON_CONSTANT.ADD}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Loading show={loading}></Loading>
    </Content>
  );
}
