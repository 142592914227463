class classMachine {
  constructor(
    ID,
    Name = "",
    Order,
    Items = [],
    Remark,
    MachineforSiteOfficeId,
    MachineForSiteOfficeCode,
    MachineForSiteOfficeName,
    facilityName,
    processName,
    buildingName,
    floorName,
    roomName,
    checked,
    status,
    counts = {
      notCheck: 0,
      total: 0,
    },
    attachments = [],
    LstBuhin = []
  ) {
    this.ID = ID;
    this.Name = Name;
    this.Order = Order;
    this.Items = Items;
    this.Remark = Remark;
    this.MachineforSiteOfficeId = MachineforSiteOfficeId;
    this.MachineForSiteOfficeCode = MachineForSiteOfficeCode;
    this.MachineForSiteOfficeName = MachineForSiteOfficeName;

    this.facilityName = facilityName;
    this.processName = processName;
    this.buildingName = buildingName;
    this.floorName = floorName;
    this.roomName = roomName;

    this.checked = checked;
    this.status = status;
    this.counts = counts;

    this.attachments = attachments;
    this.LstBuhin = LstBuhin;
  }
}

export default classMachine;