/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Row, Select, Input, Button, Modal } from "antd";
import RowInput from "../../component/Desktop/rowInput";
import API from "../../api/backend/managementCL";
import Loading from "../../component/Desktop/loading/loading";
import ComboCheckBox from "../../component/Desktop/comboCheckbox";
import {
  SearchOutlined,
  DoubleRightOutlined,
  CloseCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { COMMON_CONSTANT, KEY } from "../../constants/common.const";
import { renderOption, MSG_CATCH } from "../../constants/utils";

import classBtn from "../../models/control/button";
import classDdl from "../../models/control/dropdownlist";
import classOption from "../../models/control/option";
import classText from "../../models/control/text";
import classPopup from "../../models/control/popup";
import classBuhin from "../../models/machineBuhin";
import classMachine from "../../models/machineDes";
import { lang } from "@src/constants/common.const";

let GROUP_LIST = [];
let objClear = { value: "", disabled: true };
let CURRENT_INSPECTION_TIMING;

function SearchMachine({
  onAdd,
  listMachineAdded,
  getCheckItem = false,
  getBuhin = false,
  ...other
}) {
  let [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => setShowLoading(true);
  let closeLoadingSpinner = () => setShowLoading(false);
  // Group
  let [ddlFacility, setDdlFacility] = useState(new classDdl());
  let [ddlBuilding, setDdlBuilding] = useState(new classDdl());
  let [ddlFloor, setDdlFloor] = useState(new classDdl());
  let [ddlRoom, setDdlRoom] = useState(new classDdl());
  let [ddlProcess, setDdlProcess] = useState(new classDdl());
  let [txtMachineNameSearch, setTxtMachineNameSearch] = useState({
    ...new classText(),
    disabled: true,
  });
  let [btnSearch, setBtnSearch] = useState(new classBtn());
  let [CURRENT_MACHINE_SEARCH_LIST, setCURRENT_MACHINE_SEARCH_LIST] = useState(
    []
  ); /// List
  let [
    CURRENT_MACHINE_SEARCH_LIST_Setting,
    setCURRENT_MACHINE_SEARCH_LIST_Setting,
  ] = useState({
    options: [],
    checkedList: [],
    indeterminate: false,
    checkAll: false,
  });

  // Popup
  let [popupError, setPopupError] = useState(new classPopup());
  const showMessage = (type, msg, callbackCancel) => {
    switch (type) {
      case KEY.ERROR:
        setPopupError({ ...popupError, title: msg, show: true });
        break;
      default:
        break;
    }
  };

  // Load ddl Facility
  useEffect(() => {
    // Load pulldown
    Promise.all([API.getFacility()])
      .then((res) => {
        let facility = res[0] ? res[0].data : [];
        showDdlFacility(facility);
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  }, []);

  const showDdlFacility = (data) => {
    let fa = new classDdl();
    // Update Ddl Facility
    data &&
      data.length > 0 &&
      data.forEach((v) => {
        let op = new classOption();
        op.id = v.facilityCode;
        op.name = v.facilityName;
        fa.options.push(op);
      });
    setDdlFacility(fa);
  };

  // Render COMBO-CHECKBOX
  useEffect(() => {
    // List option
    let options = [];
    CURRENT_MACHINE_SEARCH_LIST.forEach((e) => {
      options.push({
        value: e.ID,
        label: e.MachineName,
      });
    });

    // Clear machine added
    options = options.filter((v) => {
      let f = true;
      listMachineAdded.forEach((v2) => {
        if (String(v.value) === String(v2.ID)) {
          f = false;
        }
      });
      return f;
    });

    let noData = CURRENT_MACHINE_SEARCH_LIST.length === 0;
    let noDataNew = options.length === 0;
    // Message
    let msg = noData
      ? COMMON_CONSTANT.EMPTY_DATA
      : noDataNew
      ? COMMON_CONSTANT.BOM_I003
      : null;

    // Set
    setCURRENT_MACHINE_SEARCH_LIST_Setting({
      ...CURRENT_MACHINE_SEARCH_LIST_Setting,
      checkedList: [],
      checkAll: false,
      options: options,
      msg: msg,
    });
  }, [CURRENT_MACHINE_SEARCH_LIST, listMachineAdded]);

  // Item 1
  const selectFacility = (allowOpenLoading) => {
    allowOpenLoading && openLoadingSpinner();

    // Clear 4 dropdown list
    let building = new classDdl();
    let floor = new classDdl();
    let room = new classDdl();
    let process = new classDdl();

    if (ddlFacility.value !== "") {
      // Enable textfield, btn search
      setTxtMachineNameSearch({ ...txtMachineNameSearch, disabled: false });
      setBtnSearch({ ...btnSearch, disabled: false });

      const facilityId = ddlFacility.value;
      // 1. Load building
      API.getBuilding(facilityId)
        .then((res) => {
          let data = res.data;

          if (data.length > 0) {
            // Push
            data.forEach((element) => {
              building.options.push(
                new classOption(element.buildingCode, element.buildingName)
              );
            });

            ddlBuilding.value = "";
            setDdlBuilding({ ...building, value: "", disabled: false });

            // Call func select Building
            selectBuilding(false);
          } else {
            setDdlBuilding({ ...building, ...objClear });
            setDdlFloor({ ...floor, ...objClear });
            setDdlRoom({ ...room, ...objClear });
          }
        })
        .catch((error) => {
          showMessage("ERROR", MSG_CATCH());
        });

      // 2. Load Process
      API.getProcess(facilityId)
        .then((res) => {
          let data = res.data;

          // Push
          if (data.length > 0) {
            data.forEach((element) => {
              process.options.push(
                new classOption(element.id, element.processName)
              );
            });

            // setDdlProcess({ ...process, value: selectedProcess, disabled: false });
            setDdlProcess({ ...process, disabled: false });
          } else {
            setDdlProcess({ ...process, ...objClear });
          }

          allowOpenLoading && closeLoadingSpinner();
        })
        .catch((error) => {
          showMessage("ERROR", MSG_CATCH());
        });
    }
    if (ddlFacility.value === "") {
      // Clear data pulldown
      setDdlBuilding({ ...building, ...objClear });
      setDdlFloor({ ...floor, ...objClear });
      setDdlRoom({ ...room, ...objClear });
      setDdlProcess({ ...process, ...objClear });
      // setTxtMachineNameSearch({ ...txtMachineNameSearch, ...objClear });
      setBtnSearch({ ...btnSearch, ...objClear });
      allowOpenLoading && closeLoadingSpinner();
    }
  };
  // Item 2
  function selectBuilding(allowOpenLoading) {
    allowOpenLoading && openLoadingSpinner();

    // Clear 2 dropdown list
    let newFloor = new classDdl();
    let newRoom = new classDdl();

    if (ddlBuilding.value !== "") {
      const buildingId = ddlBuilding.value;

      // Call API get Floor
      API.getFloor(buildingId)
        .then((res) => {
          let data = res.data;

          if (data.length > 0) {
            // Push
            data.forEach((element) => {
              newFloor.options.push(
                new classOption(element.floorCode, element.floorName)
              );
            });
            ddlFloor.value = "";
            setDdlFloor({ ...newFloor, ...objClear, disabled: false });

            // Call API load Floor
            selectFloor(false);
          } else {
            setDdlFloor({ ...newFloor, ...objClear });
            setDdlRoom({ ...newRoom, ...objClear });
          }
        })
        .catch((error) => {
          showMessage("ERROR", MSG_CATCH());
        })
        .then(() => {
          allowOpenLoading && closeLoadingSpinner();
        });
    } else {
      setDdlFloor({ ...newFloor, ...objClear });
      setDdlRoom({ ...newRoom, ...objClear });
      allowOpenLoading && closeLoadingSpinner();
    }
  }
  // Item 3
  function selectFloor(allowOpenLoading) {
    allowOpenLoading && openLoadingSpinner();

    // Clear option
    let newRoom = new classDdl();

    if (ddlFloor.value !== "") {
      const floorId = ddlFloor.value;

      API.getRoom(floorId)
        .then((res) => {
          let data = res.data;

          if (data.length > 0) {
            // Push
            data.forEach((element) => {
              newRoom.options.push(
                new classOption(element.roomCode, element.roomName)
              );
            });
            setDdlRoom({ ...newRoom, ...objClear, disabled: false });
          } else {
            setDdlRoom({ ...newRoom, ...objClear, disabled: true });
          }
        })
        .catch((err) => {
          showMessage("ERROR", MSG_CATCH());
        })
        .then(() => {
          allowOpenLoading && closeLoadingSpinner();
        });
    } else {
      setDdlRoom({ ...newRoom, disabled: true });
      allowOpenLoading && closeLoadingSpinner();
    }
  }

  // Search
  function searchMachine() {
    // Open loader
    openLoadingSpinner();
    // Setting params
    let dataSearch = {
      machineName: txtMachineNameSearch.value,
      facilityCode: ddlFacility.value,
      buildingCode: ddlBuilding.value,
      floorCode: ddlFloor.value,
      roomCode: ddlRoom.value,
      processId: ddlProcess.value,
      checkTiming: CURRENT_INSPECTION_TIMING,
      getCheckItem: getCheckItem,
      getBuhin: getBuhin,
    };

    // Call api
    API.getMachine(dataSearch)
      .then((res) => {
        const machineList = res.data.machineList;
        const checkItemList = res.data.checkItems;
        if (machineList.length === 0) {
          // Clear combobox list
          setCURRENT_MACHINE_SEARCH_LIST([]);
        } else {
          // Render combobox list
          getCheckItemList_SearchMachine(machineList, checkItemList);
        }
      })
      .catch((xhr) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then((err) => {
        closeLoadingSpinner();
      });
  }

  // Merge Machine vs Item
  function getCheckItemList_SearchMachine(
    listMachine = [],
    checkItemList = []
  ) {
    // Loop machine
    listMachine.forEach((machineObj = new classMachine()) => {
      machineObj.Items = [];

      // Loop item machine
      checkItemList.forEach((item) => {
        if (
          item["machineForSiteOfficeCode"] ===
          machineObj["machineForSiteOfficeCode"]
        ) {
          machineObj.Items.push(item);
        }
      });
      machineObj.LstBuhin = [];
    });

    showMachineAfterSearch(listMachine);
  }

  // Trigger render Combo-Checkbox
  function showMachineAfterSearch(MachineList) {
    const cbl = [];
    MachineList &&
      MachineList.forEach((machineObj = new classMachine()) => {
        if (machineObj.Items.length > 0 || machineObj.LstBuhin) {
          let m = {
            ...machineObj,
            ID: machineObj["machineForSiteOfficeCode"],
            MachineName: machineObj["machineForSiteOfficeName"],
          };
          cbl.push(m);
        }
      });
    setCURRENT_MACHINE_SEARCH_LIST(cbl);
  }

  // Choose Machine
  const chooseMachine = () => {
    let list = [];
    // Loop list checked
    CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList.forEach((id) => {
      const machineObj = getSelectedSearchMachine(id);
      let machine = new classMachine();
      machine.ID = id;
      machine.Name = machineObj.MachineName;
      // Location
      machine.facilityName = machineObj.facilityName;
      machine.buildingName = machineObj.buildingName;
      machine.floorName = machineObj.floorName;
      machine.roomName = machineObj.roomName;
      machine.processName = machineObj.processName;
      machine.LstBuhin = machineObj.LstBuhin;

      list.push(machine);
    });
    onAdd(list);
  };

  function getSelectedSearchMachine(machineID) {
    if (CURRENT_MACHINE_SEARCH_LIST && CURRENT_MACHINE_SEARCH_LIST.length > 0) {
      for (let i = 0; i < CURRENT_MACHINE_SEARCH_LIST.length; i++) {
        if (CURRENT_MACHINE_SEARCH_LIST[i].ID === machineID) {
          return CURRENT_MACHINE_SEARCH_LIST[i];
        }
      }
    }
    return null;
  }

  return (
    <>
      <Row>
        <Col span={10}>
          <RowInput name={lang.FACILITY}>
            <Select
              value={ddlFacility.value}
              className="w100"
              onChange={(v) => {
                // Update for select
                ddlFacility.value = v;
                setDdlFacility({ ...ddlFacility, value: v });
                selectFacility(true);
              }}
            >
              {renderOption(ddlFacility.options)}
            </Select>
          </RowInput>
          <RowInput name={lang.BUILDING}>
            <Select
              value={ddlBuilding.value}
              className="w100"
              disabled={ddlBuilding.disabled}
              onChange={(v) => {
                // Update for select
                ddlBuilding.value = v;
                setDdlBuilding({ ...ddlBuilding, value: v });
                selectBuilding(true);
              }}
            >
              {renderOption(ddlBuilding.options)}
            </Select>
          </RowInput>
          <RowInput name={lang.FLOOR}>
            <Select
              value={ddlFloor.value}
              className="w100"
              disabled={ddlFloor.disabled}
              onChange={(v) => {
                // Update for select
                ddlFloor.value = v;
                setDdlFloor({ ...ddlFloor, value: v });
                selectFloor(true);
              }}
            >
              {renderOption(ddlFloor.options)}
            </Select>
          </RowInput>
          <RowInput name={lang.ROOM}>
            <Select
              value={ddlRoom.value}
              className="w100"
              disabled={ddlRoom.disabled}
              onChange={(v) => {
                // Update for select
                ddlRoom.value = v;
                setDdlRoom({ ...ddlRoom, value: v });
              }}
            >
              {renderOption(ddlRoom.options)}
            </Select>
          </RowInput>
          <RowInput name={lang.PROCESS}>
            <Select
              value={ddlProcess.value}
              className="w100"
              disabled={ddlProcess.disabled}
              onChange={(v) => {
                // Update for select
                ddlProcess.value = v;
                setDdlProcess({ ...ddlProcess, value: v });
              }}
            >
              {renderOption(ddlProcess.options)}
            </Select>
          </RowInput>
          <RowInput name={lang.EQUIPMENT_NAME}>
            <Input
              value={txtMachineNameSearch.value}
              // disabled={txtMachineNameSearch.disabled}
              onChange={(e) =>
                setTxtMachineNameSearch({
                  ...txtMachineNameSearch,
                  value: e.target.value,
                })
              }
            />
          </RowInput>
          {/* Button search 検索*/}
          <Row justify="end">
            <Button
              className="buttonPC button--info wAuto"
              // disabled={btnSearch.disabled}
              onClick={searchMachine}
            >
              <SearchOutlined className="" /> {lang.SEARCH}
            </Button>
          </Row>
        </Col>
        {/* From */}
        <Col span={4}>
          <DoubleRightOutlined
            style={{
              fontSize: "60px",
              color: "#b9b9b9",
              width: "100%",
              margin: "100px 0 0",
            }}
          />
        </Col>
        {/* To */}
        <Col span={10}>
          <div className="box-select">
            <div className="box-select-title">{lang.LIST_TARGET_CHECKLIST}</div>
            <div className="box-select-note sub-title-group">
              {lang.CHECKLIST_HAS_ADDED_IS_NOT_DISPLAYED_IN_RESULT}
            </div>
            <ComboCheckBox
              style={{
                overflow: "hidden",
                height: "222px",
                overflowY: "auto",
              }}
              dataGroup={GROUP_LIST}
              {...CURRENT_MACHINE_SEARCH_LIST_Setting}
              onCheck={(checkedList) => {
                setCURRENT_MACHINE_SEARCH_LIST_Setting({
                  ...CURRENT_MACHINE_SEARCH_LIST_Setting,
                  checkedList: checkedList,
                  indeterminate:
                    !!checkedList.length &&
                    checkedList.length <
                      CURRENT_MACHINE_SEARCH_LIST_Setting.options.length,
                  checkAll:
                    checkedList.length ===
                    CURRENT_MACHINE_SEARCH_LIST_Setting.options.length,
                });
              }}
              onCheckAll={(e) =>
                setCURRENT_MACHINE_SEARCH_LIST_Setting({
                  ...CURRENT_MACHINE_SEARCH_LIST_Setting,
                  checkedList: e.target.checked
                    ? CURRENT_MACHINE_SEARCH_LIST_Setting.options.map((e) => {
                        return e.value;
                      })
                    : [],
                  indeterminate: false,
                  checkAll: e.target.checked,
                })
              }
            ></ComboCheckBox>
          </div>
          {/* Button select 点検表に追加*/}
          <Row justify="end">
            <Button
              className="buttonPC button--outline"
              disabled={
                CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList.length === 0
              }
              onClick={chooseMachine}
            >
              <PlusOutlined />
              {lang.ADD_CHECKLIST}
            </Button>
          </Row>
        </Col>
      </Row>
      {/* Message Error */}
      <Modal
        centered
        width={580}
        closeIcon={<></>}
        className="modal-msg modal-error"
        title={
          <div>
            <CloseCircleFilled /> {popupError.title}
          </div>
        }
        visible={popupError.show}
        footer={[
          <Button
            key={0}
            className="buttonPC button--error wAuto"
            onClick={() => {
              setPopupError({ ...popupError, show: false });
            }}
          >
            OK
          </Button>,
        ]}
      ></Modal>
      <Loading show={showLoading}></Loading>
    </>
  );
}

export default SearchMachine;
