/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/inventory";
import { KEY, lang } from "../../constants/common.const";
import {
  isEmpty,
  MSG_CATCH,
  saveFile,
  showMessage,
  statusRes,
} from "../../constants/utils";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import classCheckbox from "../../models/control/checkbox";
import List from "./list";
import classGrid from "../../models/control/grid";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function InventoryData() {
  let query = useQuery();
  const [grid, setGrid] = useState(new classGrid());
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(
    new classText(query.get("inventory") || null)
  );
  const [itemType, setItemType] = useState(new classDdl());
  const [category, setCategory] = useState(new classDdl());
  const [warehouses, setWarehouses] = useState(new classDdl());
  const [chkUnderMinimum, setChkUnderMinimum] = useState(
    new classCheckbox(query.get("status") || false)
  );
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const inputs = [
    {
      name: lang.WAREHOUSES_NAME,
      get: warehouses,
      type: KEY.DDL,
      onChange: (v) => {
        setWarehouses({ ...warehouses, value: v });
      },
    },
    {
      name: lang.CATEGORY,
      get: category,
      type: KEY.DDL,
      onChange: (v) => {
        setCategory({ ...category, value: v });
      },
    },
    {
      name: lang.ITEM,
      get: item,
      type: KEY.TEXT,
      onChange: (e) => {
        setItem({ ...item, value: e.target.value });
      },
    },
    {
      name: lang.ITEM_TYPE,
      get: itemType,
      set: setItemType,
      type: KEY.DDL,
    },
    {
      title: " ",
      name: lang.SHOW_ONLY_ALERTED_ITEMS,
      get: chkUnderMinimum,
      set: setChkUnderMinimum,
      type: KEY.CHECKBOX,
    },
  ];
  const columns = [
    {
      title: lang.ITEM_NAME,
      dataIndex: "inventoryItemName",
      align: "center",
    },
    {
      title: lang.INVENTORY_UNIT,
      dataIndex: "inventoryUnit",
      align: "center",
    },
    {
      title: lang.WAREHOUSES_NAME,
      dataIndex: "inventoryWarehouseName",
      align: "center",
    },

    {
      title: lang.STOCK_QUANTITY,
      dataIndex: "sumStockQuantity",
      align: "center",
      render: (txt, row) => {
        let code = row.stockQuantity || row.sumStockQuantity;
        if (row.stockQuantity == 0) code = 0;
        return <div style={{ fontWeight: "bold" }}>{code}</div>;
      },
    },
    {
      title: lang.MINIMUM_STOCK_QUANTITY,
      dataIndex: "sumMinimumRequiredQuantity",
      align: "center",
      render: (txt, row) => {
        let code = row.minimumRequiredQuantity || "";
        if (row.minimumRequiredQuantity == 0) code = 0;
        return <div>{code}</div>;
      },
    },
    {
      title: lang.CATEGORY_NAME,
      dataIndex: "categoryName",
      align: "center",
    },
    {
      title: lang.ITEM_TYPE,
      dataIndex: "inventoryType",
      align: "center",
    },
    {
      title: lang.ITEM_CODE,
      dataIndex: "inventoryWarehouseItemCode",
      align: "center",
      render: (txt, row) => {
        let code = row.inventoryItemCode || row.inventoryWarehouseItemCode;
        return <div>{code}</div>;
      },
    },
  ];

  const getFacility = () => {
    return KBN.getWarehouses()
      .then((res) => {
        if (statusRes(res)) {
          setWarehouses({
            ...warehouses,
            options: res.data.data.map((v) => {
              v["key"] = v.inventoryWarehouseCode;
              v["value"] = v.inventoryWarehouseName;
              return v;
            }),
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const getInventoryType = () => {
    return KBN.getInventoryType().then((res) => {
      if (statusRes(res)) {
        setItemType((preV) => ({
          ...preV,
          options: res.data.data,
        }));
      } else {
        throw new Error();
      }
    });
  };
  const getParams = {
    inventoryItem: isEmpty(item.value) ? null : item.value.trim(),
    category: isEmpty(category.value) ? null : (category.options.find(x => x.key === category.value) ? category.options.find(x => x.key === category.value).value : null),
    inventoryWarehouseCode: warehouses.value,
    inventoryType: itemType.value,
    underMinimumStockOnly: chkUnderMinimum.checked,
  };
  const onSearch = (offset = 0) => {
    setGrid((preV) => ({ ...preV, loading: true }));

    // Call
    return API.searchInventoryData({
      ...getParams,
      offset: offset,
      limit: 20,
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            // Init, Click
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            // Load more
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setGrid((preV) => ({ ...preV, loading: false }));
      });
  };
  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid((preV) => ({ ...preV, loading: false, hasMore: false }));
    } else {
      // load more
      onSearch(data.length);
    }
  };
  const clearInput = () => {
    setItem({ ...item, value: null });
    setItemType({ ...itemType, value: null });
    setCategory({ ...category, value: null });
    setWarehouses({...warehouses, value: null});
    setChkUnderMinimum(new classCheckbox());
  };
  const onExport = () => {
    setLoading(true);

    // Call
    return API.downloadItemsForTransactions(getParams)
      .then((res) => {
        if (statusRes(res)) {
          saveFile(res);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setLoading(false);
      });
  };
  const getCategory = () => {
    return KBN.getCategories()
      .then((res) => {
        if (statusRes(res)) {
          setCategory({
            ...category,
            options: res.data.data.map((v) => {
              v["key"] = v.categoryCode;
              v["value"] = v.categoryName;
              return v;
            }),
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setWarehouses({ ...warehouses, value: null });
      getParams.inventoryWarehouseCode = null;
      setCategory({ ...category, value: null });
      setLoading(true);
      Promise.all([onSearch(), getFacility(), getInventoryType(), getCategory()])
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [allowRoute]);

  return (
    <List
      expandable={{
        childrenColumnName: "warehouseItems",
      }}
      className="table-header-auto gridSearch"
      inputs={inputs}
      columns={columns}
      rowKey={(row) => row.inventoryItemCode || row.inventoryWarehouseItemCode}
      // grid={grid}
      grid={grid}
      actionSearch={() => {
        onSearch();
      }}
      loadMore={onLoadMore}
      clearInput={clearInput}
      btnAdd={
        <>
          <Button
            disabled={grid.data.length === 0}
            className="buttonPC button--back mb10"
            onClick={onExport}
            icon={
              <CloudDownloadOutlined
                style={{
                  color: "#f50",
                }}
              />
            }
          >
            {lang.EXPORT}
          </Button>
        </>
      }
      linkAdd="/ItemUpdate"
      loading={loading}
      rowClassName={(row, index) => {
        const err =
          Number(row.minimumRequiredQuantity) >
            Number(row.currentStockQuantity || row.stockQuantity) ||
          (row.warehouseItems &&
            row.warehouseItems.some(
              (item) => item.stockQuantity < item.minimumRequiredQuantity
            ));

        return err ? "row-highlight" : "";
      }}
    />
  );
}
