class classGroupPreCheck {
  constructor(ID, Name = "", Items = [], Order) {
    this.ID = ID;
    this.Name = Name;
    this.Order = Order;
    this.Items = Items;
  }
}

export default classGroupPreCheck;
