import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfFont from "./../assets/vfs_fonts";
import html2canvas from "html2canvas";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs = pdfFont.pdfMake.vfs;
pdfMake.fonts = {
  MPLUS1p: {
    normal: "MPLUS1p-Regular.ttf",
    bold: "MPLUS1p-Regular.ttf",
    italics: "MPLUS1p-Regular.ttf",
    bolditalics: "MPLUS1p-Regular.ttf",
  },
};
const documentDefinition = {
    pageSize: "A4",
    defaultStyle: {
      font: "MPLUS1p",
      fontSize: 9,
    },
    footer: function (currentPage, pageCount) {
        return {
          text: currentPage.toString() + " / " + pageCount,
          alignment: "center",
        };
  }
}

export const exportSingleDynamicchart = async (id, name) => {
  const element = document.getElementById(id);
  html2canvas(element)
    .then((canvas) => {
        
      // Chuyển đổi canvas thành ảnh (data URL)
      const dataUrl = canvas.toDataURL();
      // Tiếp tục xử lý ảnh, ví dụ: tải xuống hoặc hiển thị
     
        documentDefinition.content = [
          {
            text: `動的レポート - ${name}`,
            fontSize: 18,
            marginBottom: 10,
            alignment: "center",
          },
          {
            columns: [
              {
                width: "100%",
                image: dataUrl,
                fit: [515, 841.89],
              },
            ],
          },
        ]
      pdfMake.createPdf(documentDefinition).download(`動的レポート - ${name}`);
    })
    .catch((error) => {
      console.error("Lỗi khi chuyển đổi ảnh:", error);
    });
};

export const exportMultipleDynamicchart = async (className, name) => {

  const elements = document.getElementsByClassName(`${className}`);
  try {
    const imageUrlsPromise = Array.from(elements).map(async (element, index) => {
      const canvas = await html2canvas(element);
      return canvas.toDataURL();
    });

    const listUrl = await Promise.all(imageUrlsPromise);
   

    const listImagePdf = listUrl.map((url) => ({
      image: url,
      fit: [515, 841.89],
      margin:[0,20,0,0]
    }));
    documentDefinition.content = [ {
        text: `動的レポート - ${name}`,
        fontSize: 15,
        marginBottom: 5,
        alignment: "center",
      },...listImagePdf];
    pdfMake.createPdf(documentDefinition).download(`動的レポート - ${name}`);
  } catch (error) {
    console.error("Lỗi khi chuyển đổi ảnh:", error);
  }
};
export const exportLongImageToPDF = async (elementId, name) => {
  const element = document.getElementById(elementId);
  const canvas = await html2canvas(element);

  const imgData = canvas.toDataURL('image/jpeg');
  const pdfWidth = 595; // Kích thước trang A4
  const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

  const newDocument = JSON.parse(JSON.stringify(documentDefinition))
  newDocument.content = [
      {
        text: `動的レポート - ${name}`,
        fontSize: 15,
        marginBottom: 5,
        alignment: "center",
        marginTop:-30
      },
      {
        image: imgData,
        fit:[515,pdfHeight ]
      },
    ]
    newDocument.pageSize = { width: pdfWidth, height: pdfHeight }

  const pdfName = `動的レポート - ${name}`;
  pdfMake.createPdf(newDocument).download(pdfName);
};