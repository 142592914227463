import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { STYLE } from "../../../constants/common.const";

function LocationAddTree({
  id,
  listBuilding,
  listFloor,
  listRoom,
  handleAddBuilding,
  handleAddFloor,
  handleAddRoom,
  handleChangeBuildingName,
  handleChangeFloorName,
  handleChangeRoomName,
  handleDeleteBuilding,
  handleDeleteFloor,
  handleDeleteRoom,
}) {
  const checkFirstRender = useRef(0);
  useEffect(() => {
    if (listRoom.length > 0) {
      const newRoom = listRoom[listRoom.length - 1];
      const { roomCode, floorCode, buildingCode } = newRoom;
      const newElement = document.getElementById(
        `${buildingCode}-${floorCode}-${roomCode}`
      );
      if (newElement) {
        newElement.focus();
      }
    }
  }, [listRoom.length]);
  useEffect(() => {
    if (listFloor.length > 0) {
      const newFloor = listFloor[listFloor.length - 1];
      const { buildingCode, floorCode } = newFloor;
      const newElement = document.getElementById(
        `${buildingCode}-${floorCode}`
      );
      if (newElement) {
        newElement.focus();
      }
    }
    if (id && checkFirstRender.current++ < 2 ) {
      window.scrollTo(0, 0);
    }
  }, [listFloor.length]);
  useEffect(() => {
    if (listBuilding.length > 0) {
      const newFloor = listBuilding[listBuilding.length - 1];
      const { buildingCode } = newFloor;
      const newElement = document.getElementById(`${buildingCode}`);
      if (newElement) {
        newElement.focus();
      }
    }
  }, [listBuilding.length]);
  const onKeyDownFloor = (building, floorCode) => (e) => {
    const listFloorWithBuilding = listFloor.filter(
      (item) => item.buildingCode === building
    );
    let newElement = null;
    if (e.key === "ArrowUp") {
      const index = listFloorWithBuilding.findIndex(
        (item) => item.floorCode === floorCode
      );

      if (index >= 1) {
        const { buildingCode, floorCode } = listFloorWithBuilding[index - 1];
        newElement = document.getElementById(`${buildingCode}-${floorCode}`);
      } else if (index === 0) {
        const { buildingCode, floorCode } =
          listFloorWithBuilding[listFloorWithBuilding.length - 1];
        newElement = document.getElementById(`${buildingCode}-${floorCode}`);
      } else {
        // Empty
      }
    }
    if (e.key === "ArrowDown") {
      const index = listFloorWithBuilding.findIndex(
        (item) => item.floorCode === floorCode
      );

      if (index < listFloorWithBuilding.length - 1) {
        const { buildingCode, floorCode } = listFloorWithBuilding[index + 1];
        newElement = document.getElementById(`${buildingCode}-${floorCode}`);
      } else if (index === listFloorWithBuilding.length - 1) {
        const { buildingCode, floorCode } = listFloorWithBuilding[0];
        newElement = document.getElementById(`${buildingCode}-${floorCode}`);
      } else {
        // Empty
      }
    }
    if (newElement) {
      newElement.focus();
    }
    if (e.key === "Enter") {
      handleAddFloor(building);
    }
  };

  const onKeyDownBuilding = (codeBuilding) => (e) => {
    let newElement = null;
    if (e.key === "ArrowUp") {
      const index = listBuilding.findIndex(
        (item) => item.buildingCode === codeBuilding
      );

      if (index >= 1) {
        const { buildingCode } = listBuilding[index - 1];
        newElement = document.getElementById(`${buildingCode}`);
      } else if (index === 0) {
        const { buildingCode } = listBuilding[listBuilding.length - 1];
        newElement = document.getElementById(`${buildingCode}`);
      } else {
        // Empty
      }
    }
    if (e.key === "ArrowDown") {
      const index = listBuilding.findIndex(
        (item) => item.buildingCode === codeBuilding
      );

      if (index < listBuilding.length - 1) {
        const { buildingCode, floorCode } = listBuilding[index + 1];
        newElement = document.getElementById(`${buildingCode}`);
      } else if (index === listBuilding.length - 1) {
        const { buildingCode } = listBuilding[0];
        newElement = document.getElementById(`${buildingCode}`);
      } else {
        // Empty
      }
    }
    if (newElement) {
      newElement.focus();
    }
    if (e.key === "Enter") {
      handleAddBuilding();
    }
  };

  const onKeyDownRoom = (codeBuilding, codeFloor, codeRoom) => (e) => {
    const listRoomOfFloor = listRoom.filter(
      (item) => item.floorCode === codeFloor
    );
    let newElement = null;
    if (e.key === "ArrowUp") {
      const index = listRoomOfFloor.findIndex(
        (item) => item.roomCode === codeRoom
      );

      if (index >= 1) {
        const { floorCode, roomCode } = listRoomOfFloor[index - 1];
        newElement = document.getElementById(
          `${codeBuilding}-${floorCode}-${roomCode}`
        );
      } else if (index === 0) {
        const { floorCode, roomCode } =
          listRoomOfFloor[listRoomOfFloor.length - 1];
        newElement = document.getElementById(
          `${codeBuilding}-${floorCode}-${roomCode}`
        );
      } else {
        // Empty
      }
    }
    if (e.key === "ArrowDown") {
      const index = listRoomOfFloor.findIndex(
        (item) => item.roomCode === codeRoom
      );

      if (index < listRoomOfFloor.length - 1) {
        const { roomCode, floorCode } = listRoomOfFloor[index + 1];
        newElement = document.getElementById(
          `${codeBuilding}-${floorCode}-${roomCode}`
        );
      } else if (index === listRoomOfFloor.length - 1) {
        const { roomCode, floorCode } = listRoomOfFloor[0];
        newElement = document.getElementById(
          `${codeBuilding}-${floorCode}-${roomCode}`
        );
      } else {
        // Empty
      }
    }
    if (newElement) {
      newElement.focus();
    }
    if (e.key === "Enter") {
      handleAddRoom(codeFloor, codeBuilding);
    }
  };
  const renderTreeLocation = () => {
    let result = (
      <div>
        <Button
          style={{ marginTop: 10 }}
          onClick={() => handleAddBuilding()}
          className="button--add"
        >
          <PlusOutlined />
        </Button>
      </div>
    );
    if (listBuilding.length > 0) {
      result = listBuilding.map((buildingItem, buildIndex) => {
        const listFloorOfBuilding = listFloor.filter(
          (floorItem) => floorItem.buildingCode === buildingItem.buildingCode
        );
        return (
          <Row key={buildingItem.buildingCode} style={{marginTop: '12px'}}>
            <Col span="5">
              <div
                style={{
                  height: 80,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {buildingItem.isNew || id ? (
                  <Input
                    id={buildingItem.buildingCode}
                    onKeyDown={onKeyDownBuilding(buildingItem.buildingCode)}
                    className={buildingItem.error ? STYLE.BORDER_RED : ""}
                    onChange={(e) =>
                      handleChangeBuildingName(buildingItem, e.target.value)
                    }
                    value={buildingItem.buildingName}
                  />
                ) : (
                  <span>{buildingItem.buildingName}</span>
                )}
                <CloseOutlined
                  onClick={() =>
                    handleDeleteBuilding(
                      buildingItem.buildingCode,
                      listFloorOfBuilding
                    )
                  }
                  className="closeIcon"
                />
              </div>
            </Col>

            <Col span="3">
              {listFloorOfBuilding.map((floorItem, index, arr) => {
                let lengthRoom = 0;
                if (index !== 0) {
                  for (let i = 0; i < index; i++) {
                    const listRoomOfFloor = listRoom.filter(
                      (roomItem) => roomItem.floorCode === arr[i].floorCode
                    );
                    if (listRoomOfFloor.length > 0)
                      lengthRoom += listRoomOfFloor.length;
                    else lengthRoom += 1;
                  }
                }
                return (
                  <div
                    key={floorItem.floorCode}
                    style={{
                      width: "100%",
                      height: 80 * lengthRoom,
                      borderLeft: index !== 0 && "2px solid #1890ff",
                      borderBottom: "2px solid #1890ff",
                      position: "absolute",
                      top: 40,
                      left: 0,
                      marginLeft: 4,
                    }}
                  ></div>
                );
              })}
            </Col>
            <Col span={15} style={{ marginLeft: "10px" }}>
              {listFloorOfBuilding.length === 0 ? (
                <Row className="mt20">
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      onClick={() => handleAddFloor(buildingItem.buildingCode)}
                      className="button--add"
                    >
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
              ) : (
                listFloorOfBuilding.map((floorItem, floorIndex) => {
                  const listRoomOfFloor = listRoom.filter(
                    (roomItem) => roomItem.floorCode === floorItem.floorCode
                  );
                  return (
                    <Row key={floorItem.floorCode}>
                      <Col span={8}>
                        <div
                          style={{
                            height: 80,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginRight: 5,
                          }}
                        >
                          {floorItem.isNew || id ? (
                            <Input
                              id={`${buildingItem.buildingCode}-${floorItem.floorCode}`}
                              onKeyDown={onKeyDownFloor(
                                buildingItem.buildingCode,
                                floorItem.floorCode
                              )}
                              className={
                                floorItem.error ? STYLE.BORDER_RED : ""
                              }
                              onChange={(e) =>
                                handleChangeFloorName(
                                  buildingItem.buildingCode,
                                  floorItem.floorCode,
                                  e.target.value
                                )
                              }
                              value={floorItem.floorName}
                            />
                          ) : (
                            <span>{floorItem.floorName}</span>
                          )}
                          <CloseOutlined
                            onClick={() =>
                              handleDeleteFloor(
                                buildingItem.buildingCode,
                                floorItem.floorCode,
                                listRoomOfFloor
                              )
                            }
                            className="closeIcon"
                          />
                        </div>
                        {floorIndex === listFloorOfBuilding.length - 1 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginTop: floorItem.isNew || id ? -15 : -25,
                            }}
                          >
                            <Button
                              onClick={() =>
                                handleAddFloor(buildingItem.buildingCode)
                              }
                              className="button--add mt3"
                            >
                              <PlusOutlined />
                            </Button>
                          </div>
                        )}
                      </Col>
                      <Col span={5}>
                        {listRoomOfFloor.map((item, index) => {
                          return (
                            <div
                              key={item.roomCode}
                              style={{
                                width: "100%",
                                height: 80 * index,
                                borderLeft: index !== 0 && "2px solid #1890ff",
                                borderBottom: "2px solid #1890ff",
                                position: "absolute",
                                top: 40,
                                left: 0,
                              }}
                            ></div>
                          );
                        })}
                      </Col>
                      <Col span={8}>
                        {listRoomOfFloor.length <= 0 ? (
                          <div
                            style={{
                              display: "flex",
                              marginTop: 30,
                              justifyContent: "flex-start",
                              marginLeft: "10px",
                            }}
                          >
                            <Button
                              onClick={() =>
                                handleAddRoom(
                                  floorItem.floorCode,
                                  buildingItem.buildingCode
                                )
                              }
                              className="button--add"
                            >
                              <PlusOutlined />
                            </Button>
                          </div>
                        ) : (
                          listRoomOfFloor.map(
                            (roomItem, roomIndex, roomArr) => {
                              return (
                                <div
                                  key={roomItem.roomCode}
                                  style={{
                                    height: 80,
                                    marginLeft: 10,
                                    paddingTop: 2,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      // marginTop: 2,
                                    }}
                                  >
                                    {roomItem.isNew || id ? (
                                      <Input
                                        id={`${buildingItem.buildingCode}-${floorItem.floorCode}-${roomItem.roomCode}`}
                                        className={
                                          roomItem.error ? STYLE.BORDER_RED : ""
                                        }
                                        onKeyDown={onKeyDownRoom(
                                          buildingItem.buildingCode,
                                          floorItem.floorCode,
                                          roomItem.roomCode
                                        )}
                                        onChange={(e) =>
                                          handleChangeRoomName(
                                            floorItem.floorCode,
                                            roomItem.roomCode,
                                            e.target.value
                                          )
                                        }
                                        style={{ marginTop: 20 }}
                                        value={roomItem.roomName}
                                      />
                                    ) : (
                                      <span style={{ marginTop: 30 }}>
                                        {roomItem.roomName}
                                      </span>
                                    )}
                                    <CloseOutlined
                                      onClick={() =>
                                        handleDeleteRoom(
                                          floorItem.floorCode,
                                          roomItem.roomCode
                                        )
                                      }
                                      style={{
                                        marginTop: 28,
                                      }}
                                      className="closeIcon"
                                    />
                                  </div>
                                  {roomIndex === roomArr.length - 1 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          marginTop:
                                            roomItem.isNew || id ? 12 : 0,
                                        }}
                                        onClick={() =>
                                          handleAddRoom(floorItem.floorCode)
                                        }
                                        className="button--add"
                                      >
                                        <PlusOutlined />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )
                        )}
                      </Col>
                    </Row>
                  );
                })
              )}
            </Col>
            {buildIndex === listBuilding.length - 1 && (
              <Col span={24}>
                <Button
                  onClick={() => handleAddBuilding()}
                  className="button--add"
                  style={{
                    marginTop: listFloorOfBuilding.length > 0 ? -25 : -10,
                  }}
                >
                  <PlusOutlined />
                </Button>
              </Col>
            )}
          </Row>
        );
      });
    }
    return result;
  };

  return <div>{renderTreeLocation()}</div>;
}

export default LocationAddTree;
