class classItem {
    constructor(checkItemName, checklistItemId, attachFileId, value, checkItemCode,
        valueHis, normalLowerControlValue, normalUpperControlValue,
        unit, mode = "", isRelate, machineforSiteOfficeId, checkItemId, isNext,
        status, error, accumulatedTypeValue, accumulatedType,multiplier,inputJudgment
        ) {

        this.checkItemName = checkItemName;
        this.checklistItemId = checklistItemId;
        this.attachFileId = attachFileId;
        this.value = value;
        this.checkItemCode = checkItemCode;
        this.accumulatedTypeValue = accumulatedTypeValue;
        this.accumulatedType = accumulatedType;
        this.multiplier = multiplier;
        this.valueHis = valueHis;
        this.normalLowerControlValue = normalLowerControlValue;
        this.normalUpperControlValue = normalUpperControlValue;
        this.mode = mode;
        this.unit = unit;
        this.isRelate = isRelate;
        this.machineforSiteOfficeId = machineforSiteOfficeId;
        this.checkItemId = checkItemId;
        this.isNext = isNext;
        this.status = status;
        this.error = error;
        this.inputJudgment = inputJudgment;
    }
};

export default classItem;