import { logOut, getUser } from "@src/api/config";
import API from "@src/api/backend/auth";
import Axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { config, toyotaKamoId } from "@src/api/config";
import Roles, { RoleHeadOffice } from "@src/routers/roles/roles.const";
import { KEY } from "@src/constants/common.const";
import {
  updateCurrentUser,
  updateAndChangeCurrentRoute,
  resetStateIsUpdated,
} from "./checkList";
import rolesConst from "../routers/roles/roles.const";
function getUserName() {
  if (getUser()) {
    let username = getUser().userName.split("@");
    return username[0];
  } else {
    return null;
  }
}
const initialState = {
  username: getUserName(),
  displayName: "",
  userName: "",
  status: "",
  error: "",
  factories: [],
  selectedFactory: null,
  selectedFactoryName: null,
  userGroups: [],
  statusNetwork: true,
  inMultipleSites: null,
  isGroupManager: null,
  roles: null,
  roleKey: null,
  allowRoute: [],
  isToyota: false,
  avatarUrl:null,
  isLeader: false
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      logOut();
      delete Axios.defaults.headers.common["Authorization"];
    },
    setFactories: (state, { payload }) => {
      state.factories = payload;
    },
    updateSelectedFactory: (state, { payload }) => {
      localStorage.setItem("siteOffice", payload.code);
      state.selectedFactory = payload.code;
      state.selectedFactoryName = payload.name;
      config.backendEndpoint = config.defaultEndpoint + payload.code + "/";
      if (toyotaKamoId === Number(payload.code)) {
        state.isToyota = true;
      } else {
        state.isToyota = false;
      }
    },
    setInfoUser: (state, { payload }) => {
      state.displayName = payload.displayName;
      state.userName = payload.userName;
      state.inMultipleSites = payload.inMultipleSites;
      state.isGroupManager = payload.isGroupManager;
      state.selectedFactory = payload.siteOfficeMasterCode;
      state.selectedFactoryName = payload.siteOfficeMasterName;
      state.roles = Roles[Number(payload.role)];
      state.isLeader = [
        rolesConst.HEAD_OFFICE_ADMIN,
        rolesConst.OFFICE_ADMIN,
      ].includes(Roles[Number(payload.role)]);
      state.roleKey = payload.role;
      state.avatarUrl = payload.avatarUrl;
      localStorage.setItem("displayName", payload.displayName);
      state.headOfficeRole = RoleHeadOffice[Number(payload.headOfficeRoleType)];
      localStorage.setItem(KEY.LOC_USER_INFO, JSON.stringify(payload));
      config.backendEndpoint =
        config.defaultEndpoint + payload.siteOfficeMasterCode + "/";
      if (toyotaKamoId === Number(payload.siteOfficeMasterCode)) {
        state.isToyota = true;
      } else {
        state.isToyota = false;
      }
    },
    changeMode: (state, { payload }) => {
      state.mode = payload;
    },
    changeStatusNetWork: (state, { payload }) => {
      state.statusNetwork = payload;
    },
    updateAllowRoute: (state, { payload }) => {
      state.allowRoute = payload;
    },
    changeAvatar : (state, { payload }) => {
      state.avatarUrl  = payload.url
    }
  },
});
// the action
export const {
  logout,
  setFactories,
  updateSelectedFactory,
  setInfoUser,
  changeMode,
  changeStatusNetWork,
  updateAllowRoute,
  changeAvatar
} = authSlice.actions;

// the selector
export const authSelector = (state) => state.auth;

//the reducer
export default authSlice.reducer;

export const getFactories = () => (dispatch) => {
  API.getFactories().then((res) => {
    if (res && res.status === 200) {
      dispatch(setFactories(res.data));
    }
  });
};
export const getUserInfo = (beforeUser) => (dispatch) => {
  API.getUserInfo().then((res) => {
    if (res && res.status === 200) {
      dispatch(setInfoUser(res.data));
      dispatch(updateCurrentUser(res.data.displayName));
      if (beforeUser === res.data.displayName) {
        dispatch(updateAndChangeCurrentRoute(false));
      } else {
        dispatch(updateAndChangeCurrentRoute(true));
        dispatch(resetStateIsUpdated());
      }
      dispatch(getFactories());
    }
  });
};

export const getUserInfoByCodeOffice = (beforeUser) => (dispatch) => {
  API.getUserInfoBySideOfficeCode().then((res) => {
    if (res && res.status === 200) {
      dispatch(setInfoUser(res.data));
      dispatch(updateCurrentUser(res.data.displayName));
      if (beforeUser === res.data.displayName) {
        dispatch(updateAndChangeCurrentRoute(false));
      } else {
        dispatch(updateAndChangeCurrentRoute(true));
        dispatch(resetStateIsUpdated());
      }
     
        dispatch(getFactories());
    
    }
  });
};
