import Api from "@src/api";
export default {
  getDataDynamicChart(params={}) {
    return Api().get("DynamicReport",{params});
  },
  countDataDynamicChart(params={}) {
    return Api().get("DynamicReport/Count",{params});
  },
  deleteChart(checkItemCode) {
    return Api().delete(`DynamicReport/${checkItemCode}`);
  },
  getDataItemChartByType(stringData = null, id, type, back = null) {
    const endpoint = stringData
      ? `DynamicReport/show?checkItemCodes=${stringData}&type=${type}${
          back ? "&back=" + back : ""
        }`
      : `DynamicReport/show?id=${id}&type=${type}${
          back ? "&back=" + back : ""
        }`;
    return Api().get(endpoint);
  },
  getReportForAddItem(itemCodes) {
    return Api().get(
      `DynamicReport/GetReportForAddItem?checkItemCode=${itemCodes}`
    );
  },
  saveReportWithItem(id, itemCodes) {
    return Api().post(`DynamicReport/${id}?checkItemCodes=${ String(itemCodes)}`, {}, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
