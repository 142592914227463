import Api from "@src/api";

export default {
    // 1: Search-----
    search(data) {
        return Api().get("WorkReport/SearchWorkReport", data);
    },
    // 2: Create/Update
    save(formData, id) {
        return id ?
            Api().put(`WorkReport/UpdateWorkReport/${id}`, formData, { // UPDATE
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            :
            Api().post("WorkReport/CreateWorkReport", formData, { // CREATE
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    },
    // 3: View-----
    getDetail(id) {
        return Api().get(`WorkReport/GetWorkReportById/${id}`);
    },
    getCheckResult(id) {
        return Api().get(`/CheckResult/${id}`);
    },
    changeStatus(data) {
        return Api().put(`WorkReport/UpdateStatusWorkReport/${data.id}`, data);
    },
    // 4: DEL-----
    delete(id) {
        return Api().delete(`WorkReport/DeleteWorkReport/${id}`);
    },
    getWorkReportByCheckResultId(id) {
        return Api().get(`WorkReport/GetWorkReportByCheckResultId?checkresultId=${id}`);
    }
};
