/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./detail.scss";
import { Col, Row, Select, Table, Tooltip, Button } from "antd";
import {
  ClockCircleOutlined,
  ScheduleOutlined,
  SnippetsOutlined,
  WarningOutlined,
  PieChartFilled,
  AppstoreFilled,
  NotificationFilled,
  ScheduleFilled,
  InfoCircleFilled,
  FundFilled,
  CalendarFilled,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { KEY, lang, COMMON_CONSTANT } from "@src/constants/common.const";
import Loading from "@src/component/Desktop/loading/loading";
import { Link, useHistory, useLocation } from "react-router-dom";
import API from "@src/api/backend/officeDetail";
import API_CALENDAR from "../../api/backend/calendar";
import ApiReportManagement from "@src/api/backend/reportManagement";
import { useDispatch, useSelector } from "react-redux";
import STATUS from "@src/constants/status.const";
import { MSG_CATCH, statusRes, showMessage } from "@src/constants/utils";
import format from "@src/utils/format";
import { fromUnixTime } from "date-fns";
import { useMediaQuery } from "react-responsive";

import {
  getSummary,
  getChecklistSummary,
  resetData,
} from "@src/redux/office-detail";
import {
  getChecklistsHistory,
  getDataJudgment,
  getWorkingStatus,
  selectChecklist,
} from "@src/redux/checkList";

import { getDataHomeBanner } from "@src/redux/homeBanner";
import { Doughnut } from "react-chartjs-2";
import DynamicChart from "../../component/DynamicChart/index2";
import { checkRoleScreen } from "../../utils/roles";
import BannerMotion from "../../component/banner-motion/bannerMotion";
import { useMemo } from "react";
import classDdl from "../../models/control/dropdownlist";
import moment from "moment";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import listPlugin from "@fullcalendar/list";
import { dayCellClassNames } from "@src/page/Calendar/index.jsx";
import jaLocale from "@fullcalendar/core/locales/ja";
import rolesConst from "../../routers/roles/roles.const";
import { CLASS_NAME_BY_FIXED_TYPE } from "@src/page/Calendar/index";
function OfficeDetail() {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [tblWorkReport, setTblWorkReport] = useState([]);
  const [tblHistory, setTblHistory] = useState([]);
  const [tblHomeBanner, setTblHomeBanner] = useState([]);
  const [dataChartPie, setDataChartPie] = useState(null);
  // const [dataChartLine, setDataChartLine] = useState(null);
  const { dataChartReport, dataOverview } = useSelector(
    (state) => state.officeDetail
  );
  const { dataHistory } = useSelector((state) => state.checklist);
  const { selectedFactory, allowRoute, userName, roles } = useSelector(
    (state) => state.auth
  );
  const { dataHomeBanner } = useSelector((state) => state.homeBanner);
  const [dataTable, updateDataTable] = useState([]);
  const [optionGroup, setOptionGroup] = useState(new classDdl("01"));
  const history = useHistory();
  const location = useLocation();
  const goToDetail = (obj, status) => {
    if (Number(status) === 3) {
      dispatch(selectChecklist(obj, history));
    } else {
      history.push(`/history-detail/${obj.CheckResultId}`);
    }
  };
  // Loading
  let openLoadingSpinner = () => setShowLoading(true);
  let closeLoadingSpinner = () => setShowLoading(false);

  // Small Screnn
  const isSmallScreen = useMediaQuery({ query: "(max-width: 720px)" });
  // set column checklist management
  const columns = [
    {
      title: lang.ID,
      dataIndex: "CheckResultId",
      width: 50,
      render: (text) => (
        <Row className="rowNotMargin" justify="center">
          {text}
        </Row>
      ),
    },
    {
      title: lang.ITEM,
      dataIndex: "checklistName",
      className: "text-cut",
      width: "20%",
      render: (txt, row) => {
        return (
          <Tooltip trigger={["hover"]} title={row.checklistName}>
            <span
              className="underline"
              onClick={() => goToDetail(row, row.status)}
            >
              {row.checklistName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: lang.TIME,
      dataIndex: "timeStartCheck",
      width: 150,
      align: "center",
      render: (txt) => {
        const times = format(fromUnixTime(Number(txt)), KEY.DATE_WITH_HOUR);
        return <span>{times}</span>;
      },
    },
    {
      title: lang.INSPECTOR,
      dataIndex: "inspector",
      className: "text-cut",
      align: "center",
      width: 90,
    },
    {
      title: lang.APPROVER,
      dataIndex: "approver",
      className: "text-cut",
      align: "center",
      width: 90,
    },
    {
      title: lang.STATUS,
      width: 100,
      align: "center",
      render: (txt, row) => {
        return (
          <Row className="rowNotMargin" justify="center">
            <span
              className="underline"
              onClick={() => goToDetail(row, row.status)}
            >
              <span style={{ color: row.color }}>{row.statusValue}</span>
            </span>
          </Row>
        );
      },
    },
  ];
  // set column home banner
  const hbColumns = [
    {
      title: lang.TITLE,
      dataIndex: "title",
      className: "text-cut",
      // width: "20%",
      render: (text, row) => {
        return (
          <Tooltip trigger={["hover"]} title={row.title}>
            <span
              className="underline"
              style={{ color: STATUS.TYPE_HOME_BANNER[row.type] }}
              onClick={() => history.push(`/ViewHomeBanner/${row.id}`)}
            >
              {"【"}
              {row.typeValue}
              {"】"}
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "送信元",
      dataIndex: "senderValue",
      width: 110,
      align: "center",
      className: "text-cut",
      render: (text, row) => {
        return (
          <Tooltip trigger={["hover"]} title={text}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "日付",
      dataIndex: "updateDatetimeDisplay",
      width: 120,
      align: "center",
    },
    {
      title: "",
      dataIndex: "isHiddenValue",
      width: 30,
      align: "center",
      render: (text, row) => {
        return (
          <Tooltip trigger={["hover"]} title={"ホームページ非表示"}>
            <EyeInvisibleOutlined
              onClick={() => hideBanner(row.id)}
              style={{ cursor: "pointer", color: "rgb(241, 105, 0)" }}
            />
          </Tooltip>
        );
      },
    },
  ];
  const hideBanner = (id) => {
    API.updateHideFlagHomeBanner(id).then((res) => {
      if (statusRes(res)) {
        showMessage("success", "非表示にしました。");
        dispatch(
          getDataHomeBanner({
            params: {
              offset: 0,
              limit: 11,
              hideFlag: 0,
            },
          })
        );
      } else {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    });
  };
  // get table
  useEffect(() => {
    openLoadingSpinner();
    getWorkReport();
    closeLoadingSpinner();
  }, []);
  useEffect(() => {
    if (dataHistory) {
      const data = [];
      dataHistory.forEach((item, index) => {
        data.push(Object.assign({ key: index }, item));
      });
      setTblHistory(data);
    }
  }, [dataHistory]);
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (dataHomeBanner) {
        const data = [];
        dataHomeBanner.forEach((item, index) => {
          data.push(Object.assign({ key: index }, item));
        });
        setTblHomeBanner(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHomeBanner, allowRoute]);
  //get chart and statistic box
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      dispatch(resetData());
      dispatch(getChecklistsHistory(selectedFactory, "first"));
      dispatch(getDataJudgment());
      dispatch(getWorkingStatus());
      dispatch(getSummary());
      dispatch(getChecklistSummary(1));
      dispatch(
        getDataHomeBanner({
          params: {
            offset: 0,
            limit: 11,
            hideFlag: 0,
          },
        })
      );
    }
  }, [allowRoute]);
  //get data dynamic chart

  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      ApiReportManagement.getGroupReportKbn({ homePage: true }).then((res) => {
        const data = res.data.data;
        if (data.length) {
          const options = data.map((item) => ({
            ...item,
            key: item.key + "",
          }));
          setOptionGroup({ ...optionGroup, options, value: "01" });
        }
      });
    }
  }, [allowRoute, location.pathname]);
  useEffect(() => {
    updateDataTable([]);
    if (checkRoleScreen(location.pathname, allowRoute)) {
      API.getDataDynamicChart({
        displayType: optionGroup.value || "01",
        limit: 2,
      }).then((res) => {
        const dataItem = [];
        res.data.forEach((itemUpdated) => {
          const dataChart = itemUpdated.data;
          const arrLabels = [];
          const arrData = [];
          let hasDataLabel = false;
          dataChart.forEach((line) => {
            // handle line data
            const arrLineValue = [];
            let hasDataValue = false;
            if (line.data.length) {
              const dataLine = line.data;
              dataLine.forEach((itemDayOfLine) => {
                if (!hasDataLabel) {
                  let label;
                  if (
                    itemDayOfLine.groupTitle &&
                    Number(itemUpdated.type) !== 2
                  ) {
                    label = itemDayOfLine.groupTitle;
                  } else {
                    label = itemDayOfLine.title || "";
                  }
                  arrLabels.push(label);
                }
                if (itemDayOfLine.value !== null) {
                  hasDataValue = true;
                }
                arrLineValue.push(Number(itemDayOfLine.value) || null);
              });
              hasDataLabel = true;
            }
            const lineData = {
              name: line.checkItemName,
              data: hasDataValue ? arrLineValue : [],
              machineName: line.machineForSiteOfficeName,
              color: line.color,
              unit: line.unit,
              min: line.min,
              max: line.max,
              normalLowerControlValue: line.normalLowerControlValue,
              normalUpperControlValue: line.normalUpperControlValue,
            };
            arrData.push(lineData);
          });
          let isHasData = false;
          for (let i = 0; i < arrData.length; i++) {
            if (arrData[i].data.length) {
              isHasData = true;
              continue;
            }
          }
          const stringCheckItemName = arrData
            .map((item) => item.name)
            .join(",");
          dataItem.push({
            id: itemUpdated.id,
            itemCode: itemUpdated.checkItemCode,
            checkItemName: itemUpdated.data[0].checkItemName
              ? itemUpdated.data[0].checkItemName
              : stringCheckItemName,
            labels: arrLabels,
            type: itemUpdated.type,
            machineName: itemUpdated.data[0].machineForSiteOfficeName,
            normalLowerControlValue: itemUpdated.normalLowerControlValue,
            normalUpperControlValue: itemUpdated.normalUpperControlValue,
            unit: itemUpdated.unit,
            min: itemUpdated.min,
            max: itemUpdated.max,
            series: arrData,
            categories: arrLabels,
            isHasData: isHasData,
            reportName: itemUpdated.name,
            checklistType: itemUpdated.checklistType,
          });
        });
        updateDataTable((pre, val) => [...dataItem]);
      });
    }
  }, [allowRoute, location.pathname, optionGroup.value]);

  // get data table report
  const getWorkReport = () => {
    return API.getWorkReport({
      offset: 0,
      limit: 13,
    })
      .then((res) => {
        if (statusRes(res)) {
          setTblWorkReport(res.data);
        } else {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  // options in chart
  const optionsPie = {
    maintainAspectRatio: false,
    animation: false,
    responsive: true,
    cutout: "60%",
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };
  // init chart report
  useEffect(() => {
    if (dataChartReport) {
      const dataChart = {
        labels: [
          lang.APPROVED,
          lang.UNAPPROVED,
          COMMON_CONSTANT.WORK_REPORT_STATUS_RESOLVED,
        ],
        datasets: [
          {
            data: [
              dataChartReport.approved,
              dataChartReport.unapproved,
              dataChartReport.resolved,
            ],
            backgroundColor: ["#0280ce", "#FD8F2E", "#00a65a"],
            borderWidth: 1,
          },
        ],
      };
      setDataChartPie(dataChart);
    }
  }, [dataChartReport]);
  // get data table report

  const renderIconOverview = (type) => {
    let icon;
    switch (type) {
      case "checklist":
        icon = <ScheduleOutlined />;
        break;
      case "history":
        icon = <ClockCircleOutlined />;
        break;
      case "workingCheckResult":
        icon = <SnippetsOutlined />;
        break;
      case "troubleReport":
        icon = <WarningOutlined />;
        break;
      default:
        break;
    }
    return icon;
  };
  const renderNameOverview = (type) => {
    let name;
    switch (type) {
      case "checklist":
        name = lang.CHECKLIST_SCREEN;
        break;
      case "history":
        name = lang.HISTORY_CHECKLIST;
        break;
      case "workingCheckResult":
        name = lang.CHECK_RESULT;
        break;
      case "troubleReport":
        name = lang.SAVE_TROUBLE_REPORT;
        break;
      default:
        break;
    }
    return name;
  };
  // render 4 statistic overview
  const renderStatisticOverview = (data) => {
    const render = [];
    let i = 0;
    for (const props in data) {
      if (data.hasOwnProperty(props)) {
        render.push(
          <Col key={i++} span={12} style={{ padding: 5 }}>
            <Row
              className="overview rowNotMargin"
              justify="space-between"
              align="middle"
              gutter={[30, 30]}
              wrap={false}
              style={{ backgroundColor: dataOverview[props][1] }}
            >
              <Col flex="none">
                <Row
                  className="rowNotMargin"
                  justify="center"
                  gutter={[40, 40]}
                  align="middle"
                >
                  <Col
                    span={24}
                    className="icon"
                    style={{ backgroundColor: dataOverview[props][1] }}
                  >
                    {renderIconOverview(props)}
                  </Col>
                </Row>
              </Col>
              <Col flex="auto">
                <Row className="rowNotMargin">
                  <Col span={24} className="data text-end">
                    {dataOverview[props][0]}
                  </Col>
                  <Col span={24} className="title text-end">
                    {renderNameOverview(props)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        );
      }
    }
    return render;
  };
  const removeItemChart = (id) => {
    const arrData = dataTable.filter((item) => item.id !== id);
    updateDataTable(arrData);
  };
  const updateItemChart = (itemUpdated) => {
    const arrData = dataTable.map((item) => {
      if (item.id === itemUpdated.id) {
        return { ...itemUpdated };
      } else {
        return item;
      }
    });
    updateDataTable(arrData);
  };
  // render dynamic chart
  const renderDynamicChart = useMemo(
    () => (
      <Row className="rowNotMargin chart-bg-white" gutter={[0, 16]}>
        <Col span={24} className="box-title pl0 pr0 pt0 pb0">
          <Row className="rowNotMargin" align="middle">
            <Col span={isSmallScreen ? 24 : 12}>
              <FundFilled className="icon" />
              <span className="titleForm">{lang.DYNAMIC_CHART}</span>
            </Col>
            <Col
              span={isSmallScreen ? 24 : 12}
              className={isSmallScreen ? "left" : "right"}
            >
              <Select
                onChange={(value) => setOptionGroup({ ...optionGroup, value })}
                maxLength="255"
                placeholder={lang.GROUP}
                value={optionGroup.value}
                style={{ width: "150px", maxWidth: "100%" }}
              >
                {optionGroup.options.map(({ key, value }) => (
                  <Select.Option value={key} className="text-center">
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        {dataTable.length ? (
          dataTable.map((item, index) =>
            index < 2 ? (
              <Col span={24} key={index}>
                <DynamicChart
                  index={index}
                  item={item}
                  isPreUseChecklist={item.checklistType}
                  removeItem={(id) => removeItemChart(id)}
                  updateItem={(item) => updateItemChart(item)}
                  disabledEdit={
                    optionGroup.value === "01" &&
                    roles === rolesConst.OFFICE_USER
                  }
                />
              </Col>
            ) : null
          )
        ) : (
          <div
            style={{
              width: "100%",
              height: 700,
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {COMMON_CONSTANT.EMPTY_DATA}
          </div>
        )}
      </Row>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTable]
  );
  return (
    <div>
      <Row style={{ marginTop: 54 }}>
        <BannerMotion />
      </Row>

      <div className="dashboard" style={{ marginTop: 0 }}>
        <Row className="rowNotMargin" gutter={[16, 16]} wrap={true}>
          {/* お知らせ*/}
          <Col span={12}>
            <Row
              className="rowNotMargin chart"
              style={{
                height: "calc(100% - 4px)",
                boxShadow: "0 5px 3px rgba(0, 29, 46, 0.08)",
              }}
            >
              <Col span={24} className="box-title">
                <Row className="rowNotMargin " align="middle">
                  <Col>
                    <NotificationFilled className="icon" />
                  </Col>
                  <Col>
                    <span className="titleForm">{lang.HOME_BANNER}</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="box-content">
                {/* List home banner */}
                {tblHomeBanner.length ? (
                  <>
                    <Table
                      className="gridSearch gridDashboard"
                      dataSource={tblHomeBanner}
                      columns={hbColumns}
                      scroll={{ y: "100%" }}
                      pagination={false}
                    />
                    <Row
                      className="rowNotMargin "
                      align="middle"
                      style={{ justifyContent: "end", paddingTop: 5 }}
                    >
                      <Col>
                        <span
                          className="underline"
                          onClick={() => history.push(`/HomeBannerManagement`)}
                        >
                          {lang.SEE_MORE}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row className="rowNotMargin" align="middle" justify="center">
                    {COMMON_CONSTANT.EMPTY_DATA}
                  </Row>
                )}
              </Col>
            </Row>
          </Col>

          {/* お知らせ*/}
          <Col span={12}>
            <Row className="rowNotMargin chart">
              <Col span={24} className="box-title">
                <Row className="rowNotMargin" align="middle">
                  <Col>
                    <AppstoreFilled className="icon" />
                  </Col>
                  <Col>
                    <span className="titleForm">統計概要</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="box-content">
                <Row className="rowNotMargin" align="middle" justify="center">
                  {renderStatisticOverview(dataOverview)}
                </Row>
              </Col>
            </Row>
            <Row className="rowNotMargin chart mt16">
              <Col span={24} className="box-title">
                <Row className="rowNotMargin" align="middle">
                  <Col span={isSmallScreen ? 24 : 12}>
                    <CalendarFilled className="icon ml6" />
                    <span className="titleForm">
                      {moment().format("YYYY年MM月DD日")}
                    </span>
                  </Col>
                  <Col
                    span={isSmallScreen ? 24 : 12}
                    className={isSmallScreen ? "left" : "right"}
                  >
                    <Button className="buttonPC button--outline mr6">
                      <a href="/calendar">{lang.CALENDAR}</a>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                className="box-content chart-bg-white calendar-content"
              >
                <FullCalendarList />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="rowNotMargin" gutter={[16, 16]} wrap={false}>
          <Col span={9}>
            <Row className="rowNotMargin chart-bg-white" gutter={[24, 32]}>
              <Col span={24} className="box-title center">
                <PieChartFilled className="title--icon" />
                {lang.REPORT_STATUS}
              </Col>
              <Col span={24}>
                {dataChartPie && dataChartReport && !!dataChartReport.total ? (
                  <Doughnut
                    height={400}
                    data={dataChartPie}
                    options={optionsPie}
                  />
                ) : (
                  <Row className="rowNotMargin" align="middle" justify="center">
                    {COMMON_CONSTANT.EMPTY_DATA}
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={15}>{renderDynamicChart}</Col>
        </Row>

        <Row className="rowNotMargin" gutter={[12, 12]}>
          {/* 点検管理 */}
          <Col span={15}>
            <Row className="box">
              <Col span={24} className="box-title">
                <Row className="rowNotMargin" align="middle">
                  <Col>
                    <ScheduleFilled className="icon" />
                  </Col>
                  <Col>
                    <span className="titleForm">{lang.HISTORY_CHECKLIST}</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="box-content">
                {/* List history */}
                {tblHistory.length ? (
                  <Table
                    className="gridSearch gridDashboard"
                    dataSource={tblHistory}
                    columns={columns}
                    scroll={{ y: "100%" }}
                    pagination={false}
                  />
                ) : (
                  <Row className="rowNotMargin" align="middle" justify="center">
                    {COMMON_CONSTANT.EMPTY_DATA}
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          {/* 保守情報 */}
          <Col span={9}>
            <Row className="box">
              <Col span={24} className="box-title">
                <Row className="rowNotMargin" align="middle">
                  <Col>
                    <InfoCircleFilled className="icon" />
                  </Col>
                  <Col>
                    <span className="titleForm">{lang.REPORT_INFO}</span>
                  </Col>
                </Row>
              </Col>

              {/* List work Report */}
              <Col span={24} className="box-content">
                <Row className="tblWork">
                  <div className="tblWork-body">
                    {tblWorkReport.length ? (
                      tblWorkReport.map((v, i) => {
                        return (
                          <div key={i} className="itemWorkReport">
                            <Row
                              justify="space-between"
                              className="border-bottom-custom"
                            >
                              <Col span={12}>【{v.reportTypeValue}】</Col>
                              <Col span={12} className="right">
                                {v.createDatetimeDisplay}
                              </Col>
                            </Row>
                            <Row style={{ flexWrap: "nowrap" }}>
                              <label className="square"></label>
                              <Link
                                to={`/ViewReport/${v.id}`}
                                className="white-space"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {v.name}
                              </Link>
                            </Row>
                          </div>
                        );
                      })
                    ) : (
                      <Row
                        className="rowNotMargin"
                        align="middle"
                        justify="center"
                      >
                        {COMMON_CONSTANT.EMPTY_DATA}
                      </Row>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Loading show={showLoading}></Loading>
      </div>
    </div>
  );
}
export default OfficeDetail;

const eventTimeFormat = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  meridiem: false,
};

export const FullCalendarList = (props) => {
  const location = useLocation();
  const [events, setEvents] = useState([]);
  const { allowRoute, userName } = useSelector((state) => state.auth);

  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      const fetchCalendar = async () => {
        const startTime = moment().startOf("week").format("YYYY/MM/DD");
        const endTime = moment().endOf("week").format("YYYY/MM/DD");
        const res = await API_CALENDAR.getEvents("", startTime, endTime);
        const newToOldFormat = [];
        res.data.forEach((x) => {
          x.details.forEach((y) => {
            newToOldFormat.push({
              ...x,
              startTime: y.startTime,
              endTime: y.endTime,
              allDayEvent: y.allDayEvent,
            });
          });
        });
        const eventsData = newToOldFormat.map((x) => {
          const title = (x.checklistName || x.taskName) + (x.displayName ? ": " + x.displayName : "") ;
          const momentStartTime = moment.unix(x.startTime);
          const momentEndTime = moment.unix(+x.endTime + 1);

          return {
            ...x,
            title: title,
            start: momentStartTime.format(
              x.allDayEvent ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
            ),
            end: momentEndTime.format(
              x.allDayEvent ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
            ),
            classNames: CLASS_NAME_BY_FIXED_TYPE[x.fixedType],
          };
        });
        setEvents(eventsData);
      };
      fetchCalendar();
    }
  }, [allowRoute, location.pathname]);

  return (
    <FullCalendar
      plugins={[listPlugin]}
      height="200px"
      initialView="listWeek"
      initialDate={new Date()}
      events={events}
      eventTimeFormat={eventTimeFormat}
      dayCellClassNames={dayCellClassNames}
      dayMaxEvents={4}
      eventMaxStack={4}
      headerToolbar={false}
      locales={[jaLocale]}
      locale="ja"
      {...props}
    />
  );
};
