import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import loadable from "@loadable/component";
// Side Bar
import SideBar from "@src/component/SideBar/sideBar";
import { useMediaQuery } from "react-responsive";
import PrivateRouter from "@src/routers/privateRouter";
import { useDispatch } from "react-redux";
import { updateCurrentRouter } from "@src/redux/checkList";
import { useHistory, withRouter } from "react-router-dom";
import {
  updateAttachmentMachine,
  updateNoteMachine,
  updateWorkingStatusMachine,
  updateCurrentCLGroup,
  updateCurrentTabChecklistGroup,
  updateCurrentChecklist,
  updateSaveStatusMachine,
} from "@src/redux/checkList";
import { openConfirm } from "@src/redux/confirm";
import { KEY } from "../constants/common.const";
import rolesConst from "./roles/roles.const";
import { useLocation } from "react-router-dom";
const Headers = loadable(() => import("@src/component/Header/header"));

const NetworkStatus = loadable(() =>
  import("@src/component/NetworkStatus/networkStatus")
);
function Router() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentRouter, setCurrentRouter] = React.useState("/");
  const { collapsed } = useSelector((state) => state.sideBar);
  const { selectedFactory } = useSelector((state) => state.auth);
  const {
    isUpdated,
    currentMachine,
    updateDone,
    checklistId,
    checklistTmp,
    checklistSelected,
  } = useSelector((state) => state.checklist);

  // check end user
  const urlParams = new URLSearchParams(window.location.search);
  const queryIsEndUSer = urlParams.get("isEndUser");

  const { roles } = useSelector((state) => state.auth);
  const isEndUser =
    [rolesConst.CUSTOMER, rolesConst.SPECIAL_CUSTOMER].includes(roles) ||
    location.pathname === "/EndUser" ||
    queryIsEndUSer == "true";

  const stateCL = useSelector((state) => state.checklist);
  const [showPopupSave, setShowPopup] = useState(false);
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  history.listen((location) => {
    setCurrentRouter(location.pathname);
  });
  React.useEffect(() => {
    dispatch(updateCurrentRouter(currentRouter));
    if (isUpdated) {
      updateStateChecklist();
      setShowPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouter, dispatch]);
  function updateStateChecklist() {
    if (currentMachine) {
      dispatch(updateAttachmentMachine());
      dispatch(updateNoteMachine());
      dispatch(updateWorkingStatusMachine());
      dispatch(updateSaveStatusMachine());
      dispatch(updateCurrentCLGroup());
    }
    dispatch(updateCurrentTabChecklistGroup());
    dispatch(updateCurrentChecklist());
  }
  React.useEffect(() => {
    let checklist = null;
    if (updateDone && showPopupSave) {
      if (checklistId === "new-checklist") {
        checklist = checklistTmp;
      } else {
        checklist = checklistSelected;
      }
      if (checklist) {
        dispatch(
          openConfirm({
            redirectUrl: null,
            open: true,
            typeConfirm: "saveCL",
            data: {
              checklist: checklist,
              type: checklistId === "new-checklist" ? "create" : "edit",
              state: stateCL,
            },
          })
        );
        setShowPopup(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDone, showPopupSave]);
  const routerContent = useMemo(() => {
    return (
      <div className={isDesktopOrLaptop ? "des" : "des des--white"}>
        <Headers isEndUser={isEndUser} />
        <NetworkStatus />
        <PrivateRouter />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopOrLaptop, isEndUser]);
  return (
    <Layout>
      {!isEndUser && <SideBar />}
      {selectedFactory && (
        <Layout
          className="site-layout"
          style={
            isDesktopOrLaptop && !isEndUser
              ? collapsed
                ? { paddingLeft: "64px" }
                : { paddingLeft: "220px" }
              : {}
          }
        >
          {routerContent}
        </Layout>
      )}
    </Layout>
  );
}
export default withRouter(Router);
