/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ClearOutlined,
  CloudDownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import API from "@src/api/backend/dynamicChart";
import ApiManagement from "@src/api/backend/reportManagement";
import BoxContent from "@src/component/Desktop/content";
import { KEY, lang } from "@src/constants/common.const";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import DynamicChart from "../../component/DynamicChart/index2";
import LoadingInline from "../../component/Popup/loading-inline";
import FooterPage from "../../component/footer/footerPage";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import {
  exportLongImageToPDF,
  exportMultipleDynamicchart,
} from "../../utils/exportPdf";
import { checkRoleScreen } from "../../utils/roles";
import "./index.scss";
import rolesConst from "../../routers/roles/roles.const";

function DynamicChartManagement({ isEndUser }) {
  const dispatch = useDispatch();
  const [formSearch] = Form.useForm();
  const history = useHistory();
  const { allowRoute, roles } = useSelector((state) => state.auth);
  const [dataCharts, updateDataCharts] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [arrayShowHome, updateArrayShowHome] = useState([]);
  const [disableSelectChart, updateDisableSelect] = useState(false);
  const [optionGroup, setOptionGroup] = useState([]);
  const location = useLocation();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    ApiManagement.getGroupReportKbn({ homePage: true }).then((res) => {
      setOptionGroup(
        res.data.data.map((item) => ({ ...item, key: item.key + "" }))
      );
    });
  }, []);
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (isEndUser) {
        searchByEndUser();
      } else {
        const initValue = {
          displayType: searchParams.get("displayType") || "01",
          name: searchParams.get("name") || "",
          machineForSiteOfficeName:
            searchParams.get("machineForSiteOfficeName") || "",
          checkItemName: searchParams.get("checkItemName") || "",
        };
        formSearch.setFieldsValue(initValue);
        formSearch.submit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, allowRoute, isEndUser]);
  useEffect(() => {
    if (dataCharts.length) {
      const arrShow = [];
      dataCharts.forEach((item) => {
        const obj = {
          id: item.id,
          show: false,
        };
        arrShow.push(obj);
      });
      updateArrayShowHome(arrShow);
    }
  }, [dataCharts]);
  useEffect(() => {
    if (arrayShowHome.length) {
      let total = 0;
      arrayShowHome.forEach((item) => {
        item.show && total++;
      });
      if (total > 1) {
        updateDisableSelect(true);
      } else updateDisableSelect(false);
    }
  }, [arrayShowHome]);

  const searchByEndUser = () => {
    getDataDynamicChart({
      displayType: "01",
      name: "",
      machineForSiteOfficeName: "",
      checkItemName: "",
    });
  };
  const getDataDynamicChart = (dataForm) => {
    updateLoading(true);
    API.getDataDynamicChart(dataForm)
      .then((res) => {
        const sortData = res.data.sort((a, b) => a.order - b.order);
        const dataItem = [];
        sortData.forEach((itemUpdated, index) => {
          const dataChart = itemUpdated.data;
          const arrLabels = [];
          const arrData = [];
          let hasDataLabel = false;
          dataChart.forEach((line) => {
            // handle line data
            const arrLineValue = [];
            let hasDataValue = false;
            if (line.data.length) {
              const dataLine = line.data;
              dataLine.forEach((itemDayOfLine) => {
                if (!hasDataLabel) {
                  let label;
                  if (
                    itemDayOfLine.groupTitle &&
                    Number(itemUpdated.type) !== 2
                  ) {
                    label = itemDayOfLine.groupTitle;
                  } else {
                    label = itemDayOfLine.title || "";
                  }
                  arrLabels.push(label);
                }
                if (itemDayOfLine.value !== null) {
                  hasDataValue = true;
                }
                arrLineValue.push(Number(itemDayOfLine.value) || null);
              });
              hasDataLabel = true;
            }
            const lineData = {
              name: line.checkItemName,
              data: hasDataValue ? arrLineValue : [],
              machineName: line.machineForSiteOfficeName || "",
              color: line.color,
              unit: line.unit,
              min: line.min,
              max: line.max,
              normalLowerControlValue: line.normalLowerControlValue,
              normalUpperControlValue: line.normalUpperControlValue,
            };
            arrData.push(lineData);
          });
          let isHasData = false;
          for (let i = 0; i < arrData.length; i++) {
            if (arrData[i].data.length) {
              isHasData = true;
              continue;
            }
          }
          const stringCheckItemName = arrData
            .map((item) => item.name)
            .join(",");
          dataItem.push({
            id: itemUpdated.id,
            itemCode: itemUpdated.checkItemCode,
            checkItemName:
              itemUpdated.data[0] && itemUpdated.data[0].checkItemName
                ? itemUpdated.data[0].checkItemName
                : stringCheckItemName,
            labels: arrLabels,
            type: itemUpdated.type,
            machineName:
              itemUpdated.data[0] &&
              itemUpdated.data[0].machineForSiteOfficeName
                ? itemUpdated.data[0].machineForSiteOfficeName
                : "",
            normalLowerControlValue: itemUpdated.normalLowerControlValue,
            normalUpperControlValue: itemUpdated.normalUpperControlValue,
            unit: itemUpdated.unit,
            min: itemUpdated.min,
            max: itemUpdated.max,
            series: arrData,
            categories: arrLabels,
            isHasData: isHasData,
            order: index + 1,
            reportName: itemUpdated.name,
            checklistType: itemUpdated.checklistType,
          });
        });
        updateDataCharts(dataItem);
      })
      .finally(() => updateLoading(false));
  };

  // get info search
  const onSearchForm = (values) => {
    getDataDynamicChart(values);

    if (!isEndUser) {
      let queryParams = "?";
      Object.keys(values).forEach((key, index) => {
        if (values[key]) {
          queryParams += `${index !== 0 ? "&" : ""}${key}=${values[key]}`;
        }
      });
      history.replace(location.pathname + queryParams);
    }
  };
  // reset form data
  const onReset = () => {
    formSearch.resetFields([
      "machineForSiteOfficeName",
      "checkItemName",
      "name",
    ]);
    formSearch.submit();
  };
  // update data to form modal
  const updateCurrentTemp = (type, data) => {
    history.push("/DynamicReportManagement/" + type);
  };

  // Delete
  const removeItemChart = async (id) => {
    let arrData = dataCharts.filter((item) => item.id !== id);
    arrData = arrData.map((item, index) => ({ ...item, order: index + 1 }));
    updateDataCharts(arrData);
    formSearch.submit();
  };

  // handle hide delete,edit,change order Butt
  const handleHideButton = () => {
    if (
      isEndUser ||
      (roles === rolesConst.OFFICE_USER &&
        formSearch.getFieldValue("displayType") === "01")
    ) {
      return true;
    }
    return false;
  };
  // Change Order
  const handleChangeOrder = (oldOrder) => async (newOrder) => {
    try {
      if (oldOrder == newOrder) return;
      setLoadingScreen(true);
      const oldId = dataCharts[oldOrder - 1].id;
      const newId = dataCharts[newOrder - 1].id;
      await ApiManagement.swapOrder(oldId, newId);
      formSearch.submit();
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoadingScreen(false);
    }
  };

  const updateItemChart = (itemUpdated) => {
    const arrData = dataCharts.map((item) => {
      if (item.id === itemUpdated.id) {
        return { ...itemUpdated, order: item.order };
      } else {
        return item;
      }
    });
    updateDataCharts(arrData);
  };

  // render dynamic chart
  const renderDynamicChart = () => {
    let result = (
      <div
        style={{
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        データがありません
      </div>
    );

    if (dataCharts.length)
      result = (
        <Row className="rowNotMargin" gutter={[0, 16]}>
          {dataCharts.map((item, index) => (
            <Col span={24} key={index}>
              <Row
                id={`chart-${index}`}
                className={`rowNotMargin chart-bg all-dynamic-chart`}
              >
                <DynamicChart
                  disabledEdit={handleHideButton()}
                  index={index}
                  item={item}
                  isPreUseChecklist={item.checklistType}
                  removeItem={(id) => removeItemChart(id)}
                  updateItem={(item) => updateItemChart(item)}
                  isDynamicScreen={true}
                  orderNumber={item.order}
                  onChangeOrder={handleChangeOrder(index + 1)}
                  dataChartsLength={dataCharts.length}
                  setLoadingDelete={setLoadingScreen}
                  isEndUser={isEndUser}
                />
              </Row>
            </Col>
          ))}
        </Row>
      );
    return result;
  };

  const handleDownloadPdf = async () => {
    setLoadingScreen(true);
    await exportLongImageToPDF(
      "parent-chart",
      !isEndUser
        ? optionGroup.find(
            (item) => item.key === formSearch.getFieldValue("displayType")
          ).value
        : "共通"
    );
    setLoadingScreen(false);
  };
  return (
    <div className="des-content dynamic-chart-content">
      <TitlePage name={lang.DYNAMIC_REPORT_MANAGEMENT} />
      <Form
        className="formSearchTemp"
        name="templateSearch"
        form={formSearch}
        onFinish={onSearchForm}
      >
        {!isEndUser && (
          <BoxContent>
            <Row
              className="rowNotMargin"
              align="bottom"
              gutter={[12, 12]}
              justify="end"
            >
              <Col span={8}>
                <Row className="rowNotMargin" align="middle">
                  <Col span={24} className="title-form-item">
                    {lang.NAME_REPORT}
                  </Col>
                  <Col span={24}>
                    <Form.Item name="name" className="rowNotMargin">
                      <Input
                        maxLength="255"
                        placeholder={lang.NAME_REPORT}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row className="rowNotMargin" align="middle">
                  <Col span={24} className="title-form-item">
                    {lang.MACHINE_NAME}
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="machineForSiteOfficeName"
                      className="rowNotMargin"
                    >
                      <Input
                        maxLength="255"
                        placeholder={lang.MACHINE_NAME}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row className="rowNotMargin" align="middle">
                  <Col span={24} className="title-form-item">
                    {lang.ITEM_NAME}
                  </Col>
                  <Col span={24}>
                    <Form.Item name="checkItemName" className="rowNotMargin">
                      <Input
                        maxLength="255"
                        placeholder={lang.ITEM_NAME}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row align="bottom" className="rowNotMargin" justify="end">
                  <Col flex="none">
                    <Button
                      className="buttonPC button--info"
                      htmlType="submit"
                      icon={<SearchOutlined />}
                    >
                      {lang.SEARCH}
                    </Button>
                  </Col>
                  <Col flex="none" style={{ paddingLeft: "12px" }}>
                    <Button
                      className="buttonPC button--outline --todo-- wAuto"
                      onClick={onReset}
                      icon={<ClearOutlined />}
                    >
                      {lang.CLEAR}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </BoxContent>
        )}
        <BoxContent>
          <Row className="rowNotMargin" gutter={[12, 12]}>
            {!isEndUser && (
              <Col span={24}>
                <Row
                  className="rowNotMargin"
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <Button
                      type="link"
                      className="btnAddRecord "
                      onClick={() => updateCurrentTemp("add", null)}
                    >
                      <AddIcon />
                      {lang.ADD_REPORT}
                    </Button>
                  </Col>
                  <Col>
                    <Form.Item name="displayType" className="rowNotMargin">
                      <Select
                        disabled={loading}
                        onChange={(v) => formSearch.submit()}
                        maxLength="255"
                        style={{ width: 200 }}
                        placeholder={lang.GROUP}
                      >
                        {optionGroup.map(({ key, value }) => (
                          <Select.Option className="text-center" value={key}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Row id="parent-chart" className="rowNotMargin" align="center">
                {loading ? (
                  <LoadingInline isLoadingInline={loading} />
                ) : (
                  renderDynamicChart()
                )}
              </Row>
            </Col>
          </Row>
        </BoxContent>
        <Loading show={loadingScreen} />
      </Form>
      {!isEndUser ? (
        <FooterPage>
          <Row justify="end">
            <Button
              disabled={!dataCharts.length || loading}
              onClick={() => handleDownloadPdf()}
              className="buttonPC button--outline --todo-- wAuto mr10"
            >
              <CloudDownloadOutlined
                style={{
                  color: "#f50",
                }}
              />
              {lang.DOWNLOAD_PDF}
            </Button>
          </Row>
        </FooterPage>
      ) : (
        <Row justify="end">
          <Col style={{ marginTop: 0, marginRight: 30 }}>
            <Button
              disabled={!dataCharts.length || loading}
              onClick={() => handleDownloadPdf()}
              className="buttonPC button--outline --todo-- wAuto mr10"
            >
              <CloudDownloadOutlined
                style={{
                  color: "#f50",
                }}
              />
              {lang.DOWNLOAD_PDF}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default DynamicChartManagement;
