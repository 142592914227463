/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./report.scss";
import BoxContent from "@src/component/Desktop/content";
import {
  SearchOutlined,
  EditFilled,
  EyeFilled,
  DeleteOutlined,
  CloudDownloadOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Table,
  Modal,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplateChecklist,
  getReportType,
  updateCurrentTemplate,
  deleteTemplateChecklist,
} from "@src/redux/reportManagement";
import TemplateChecklist from "@src/models/templateChecklist";
import { useHistory, useLocation } from "react-router-dom";
import { lang, COMMON_CONSTANT } from "@src/constants/common.const";
import { checkRoleScreen } from "../../utils/roles";
import { getQuerySearch, pushObject2Query } from "../../constants/utils";
function TemplateManagement() {
  const TYPE_SUBMIT = {
    SEARCH: "SEARCH",
    INIT: "INIT",
  };
  const dispatch = useDispatch();
  const objSearch = getQuerySearch();
  const [formSearch] = Form.useForm();
  const { templateChecklist, reportType, numberOfTemp, loadingTable } =
    useSelector((state) => state.reportManagement);
  const [dataSource, updateDataSource] = useState(null);
  const [listNameTemp, updateListNameTemp] = useState([]);
  const history = useHistory();
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      // dispatch(getTemplateChecklist());
      // formSearch.submit();
      onSearchTempChecklist(TYPE_SUBMIT.INIT);
      dispatch(getReportType());
    }
  }, [dispatch, allowRoute]);
  useEffect(() => {
    if (templateChecklist && templateChecklist.length) {
      let arrayTemp = [];
      let arrayNameTemp = [];
      templateChecklist.forEach((item, index) => {
        arrayTemp.push(
          Object.assign(new TemplateChecklist(item), { key: index })
        );
        arrayNameTemp.push(item.title);
      });
      updateDataSource(arrayTemp);
      updateListNameTemp(arrayNameTemp);
    } else {
      updateDataSource([]);
    }
  }, [templateChecklist]);
  // render selection
  const optionSelectReportType = (data) => {
    if (data && data.length) {
      let array = [];
      data.forEach((item) => {
        array.push(
          <Select.Option key={item.key} value={item.key}>
            {item.value}
          </Select.Option>
        );
      });
      return array;
    } else {
      return null;
    }
  };
  const getValueObjWithOutUndefined = (obj) => {
    for (let i in obj) {
      if (obj[i] === undefined) {
        obj[i] = "";
      }
    }
    return obj;
  };
  // get info search
  const onSearchTempChecklist = (type = TYPE_SUBMIT.SEARCH) => {
    const values = formSearch.getFieldsValue();
    if (type === TYPE_SUBMIT.INIT) {
      formSearch.setFieldsValue(getValueObjWithOutUndefined(objSearch));
      dispatch(
        getTemplateChecklist(
          objSearch.tempName ? objSearch.tempName.trim() : "",
          objSearch.category || ""
        )
      );
    } else {
      dispatch(
        getTemplateChecklist(
          values.tempName ? values.tempName.trim() : "",
          values.category
        )
      );
      pushObject2Query(getValueObjWithOutUndefined(values));
    }
  };
  // reset form data
  const onReset = () => {
    formSearch.resetFields();
    onSearchTempChecklist();
  };
  // update data to form modal
  const updateCurrentTemp = (type, data) => {
    dispatch(
      updateCurrentTemplate({
        type: type,
        data: data,
        listNameTemp: listNameTemp,
      })
    );
    history.push("/TemplateManagement/" + type);
  };
  const columns = [
    {
      title: lang.ID,
      dataIndex: "id",
      width: "5%",
      align: "center",
    },
    {
      title: lang.TEMPLATE_NAME,
      dataIndex: "tempName",
      width: "30%",
      // ...getColumnSearchProps("tempName"),
      render: (text, row) => (
        <Row
          className="rowNotMargin white-space"
          align="middle"
          style={{ flexWrap: "nowrap" }}
        >
          <Col flex="auto">
            <a onClick={() => updateCurrentTemp("view", row)}>{text}</a>
          </Col>
        </Row>
      ),
    },
    {
      title: lang.CATEGORY,
      width: "10%",
      dataIndex: "category",
      // ...getColumnSearchProps("category"),
      render: (text, row) => (
        <Row className="rowNotMargin" justify="center">
          {text}
        </Row>
      ),
    },
    {
      title: lang.REGISTRATION_DATE,
      width: "20%",
      dataIndex: "createTime",
      // ...getColumnSearchProps("createTime"),
      render: (text, row) => (
        <Row className="rowNotMargin" justify="center">
          {text}
        </Row>
      ),
    },
    {
      title: lang.EDITING_DATE,
      width: "20%",
      dataIndex: "updateTime",
      // ...getColumnSearchProps("updateTime"),
      render: (text, row) => (
        <Row className="rowNotMargin" justify="center">
          {text}
        </Row>
      ),
    },

    {
      title: lang.ACTION,
      width: "15%",
      dataIndex: "actions",
      render: (text, row) => (
        <Row className="rowNotMargin" gutter={[8, 0]} wrap={false}>
          <Col>
            <Button
              onClick={() => updateCurrentTemp("edit", row)}
              className="btn--edit"
            >
              <Tooltip title={lang.EDIT}>
                <EditFilled />
              </Tooltip>
            </Button>
          </Col>
          <Col>
            <Tooltip title={lang.VIEW}>
              <Button onClick={() => updateCurrentTemp("view", row)}>
                <EyeFilled />
              </Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={lang.DELETE} className="btn--delete">
              <Button
                onClick={() => {
                  Modal.confirm({
                    centered: true,
                    title: (
                      <span style={{ fontWeight: "bold" }}>{lang.DELETE}</span>
                    ),
                    content: COMMON_CONSTANT.BOM_C001,
                    okText: lang.OK,
                    cancelText: COMMON_CONSTANT.CLEAR,
                    onOk: () => deleteTemplate(row.id),
                  });
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={lang.EXPORT}>
              <a href={row.linkDown} download={row.attachment}>
                <Button className="btn--download">
                  <CloudDownloadOutlined />
                </Button>
              </a>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];
  // confirm delete template checklist
  const deleteTemplate = (id) => {
    dispatch(deleteTemplateChecklist(id));
  };

  return (
    <div className="des-content">
      <BoxContent>
        <Form
          className="formSearchTemp"
          name="templateSearch"
          form={formSearch}
          // onFinish={(}
        >
          <Row className="rowNotMargin" align="bottom" gutter={[12, 12]}>
            <Col span={8}>
              <Row className="rowNotMargin" align="middle">
                <Col span={24} className="title-form-item">
                  {lang.TEMPLATE_NAME}
                </Col>
                <Col span={24}>
                  <Form.Item name="tempName" className="rowNotMargin">
                    <Input
                      defaultValue={objSearch.tempName || ""}
                      maxLength="255"
                      placeholder={lang.TEMPLATE_NAME}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row className="rowNotMargin" align="middle">
                <Col span={24} className="title-form-item">
                  {lang.CATEGORY}
                </Col>
                <Col span={24}>
                  <Form.Item name="category" className="rowNotMargin">
                    <Select
                      style={{ width: "100%" }}
                      placeholder={lang.CATEGORY}
                    >
                      {optionSelectReportType(reportType)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="bottom" className="rowNotMargin">
                <Col flex="none">
                  <Button
                    className="buttonPC button--info"
                    // htmlType="submit"
                    onClick={() => onSearchTempChecklist(TYPE_SUBMIT.SEARCH)}
                    icon={<SearchOutlined />}
                  >
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col flex="none" style={{ paddingLeft: "12px" }}>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto"
                    onClick={onReset}
                    icon={<ClearOutlined />}
                  >
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </BoxContent>
      <BoxContent>
        <Row className="rowNotMargin" gutter={[12, 12]}>
          <Col span={24}>
            <Row
              className="rowNotMargin"
              align="middle"
              justify="space-between"
            >
              <Col>
                <Button
                  type="link"
                  className="btnAddRecord "
                  onClick={() => updateCurrentTemp("add", null)}
                >
                  <AddIcon />
                  {lang.ADD}
                </Button>
              </Col>
              <Col>
                {lang.ACCESS_NUMBERS} {numberOfTemp} {lang.CASE}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              className="gridSearch tblRole"
              columns={columns}
              dataSource={dataSource}
              loading={loadingTable}
              pagination={{ position: ["bottomCenter"], pageSize: 30 }}
              scroll={{ x: "auto" }}
            />
          </Col>
        </Row>
      </BoxContent>
    </div>
  );
}

export default TemplateManagement;
