/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Empty,
  Input,
  Spin,
  Form,
  Checkbox,
} from "antd";
import { lang } from "../../constants/common.const";
import InfiniteScroll from "react-infinite-scroller";

const { Paragraph } = Typography;

function PopupAllUser({
  isPC,
  popup,
  _nameChecklist,
  set_nameChecklist,
  gridPopup,
  _listChecklist,
  grid_checkbox,
  gridLoadMore,
  form,
  loadSpin,
}) {
  useEffect(() => {
    // Event only mobile
    !isPC && popup.search(true);
  }, [_nameChecklist]);

  return (
    <div>
      <Row className={isPC && "mb15 pl5 pr5"}>
        <Col span={24}>
          <Form
            form={form}
            name="horizontal_login"
            layout={isPC && "inline"}
            onFinish={() => {
              popup.search(true);
            }}
          >
            <Form.Item name="checklistName">
              <Input
                width={!isPC ? "100%" : ""}
                placeholder={lang.SEARCH}
                value={_nameChecklist.value}
                onChange={async (e) => {
                  set_nameChecklist({
                    ..._nameChecklist,
                    value: e.target.value,
                  });
                }}
              />
            </Form.Item>
            {isPC && (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {lang.SEARCH}
                </Button>
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
      {/* Grid */}
      <div className="scroll-grid pt5 pl5 pb5 pr5">
        <Spin spinning={loadSpin} className="content-center"></Spin>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={gridLoadMore}
          hasMore={!gridPopup.loading && gridPopup.hasMore}
          useWindow={false}
        >
          <Row gutter={[20, 20]}>
            {_listChecklist && _listChecklist.length > 0 ? (
              _listChecklist.map((e, index) => {
                return (
                  <Col xs={24} sm={12} md={8} lg={6} key={e.userPrincipalName}>
                    <Row
                      align="middle"
                      style={{ padding: "13px 16px", height: "130px" }}
                      className="itemList itemList-row "
                      disabled={e.disabled}
                      onClick={() => {
                        !e.disabled &&
                          grid_checkbox(e.id, index, !e["checked"]);
                      }}
                    >
                      {/* Title */}
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col className="id">{e.userPrincipalName}</Col>
                          <Col className="created">
                            <Checkbox
                              disabled={e.disabled}
                              checked={e.checked}
                              onChange={(e) => {
                                grid_checkbox(e.id, index, e.target.checked);
                              }}
                            ></Checkbox>
                          </Col>
                        </Row>
                      </Col>
                      {/* Body */}
                      <Col className="title mb0" span={24}>
                        <Paragraph
                          ellipsis={{ rows: 2 }}
                          className="text-weight-700"
                        >
                          {e.displayName}
                        </Paragraph>
                      </Col>
                      {/* Footer */}
                      <Col span={24}>
                        <Row>
                          <Col className="created">{e.mail}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <div className="center mt20 w100">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default PopupAllUser;
