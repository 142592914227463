import {
  CaretLeftFilled,
  CaretRightFilled,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import API from "@src/api/backend/officeDetail";
import { lang } from "@src/constants/common.const";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import { ReactComponent as EditIcon } from "@src/styles/icon/edit.svg";
import { Button, Col, Modal, Radio, Row, Tooltip, Select } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { COMMON_CONSTANT, KEY } from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import { chartLine } from "./chart.options";
import "./index.scss";
import moment from "moment";
import { Checkbox } from "antd";
import { exportSingleDynamicchart } from "../../utils/exportPdf";

function DynamicChart({
  isPreUseChecklist,
  item,
  index,
  removeItem,
  updateItem,
  isPopup,
  setLoadingDelete,
  disabledEdit = false,
  orderNumber,
  onChangeOrder,
  dataChartsLength,
  isEndUser,
  isDynamicScreen,
}) {
  const getYAxis = (x) => {
    const isCustomMinMax = x.min && x.max;
    const objMinMax = isCustomMinMax ? { min: +x.min, max: +x.max } : {};
    const plotLinesOption = {
      value: "",
      label: {
        text: "",
        align: "right",
        style: {
          color: "red",
        },
        x: -10,
      },
      color: "red",
      width: 2,
      dashStyle: "dash",
    };

    return {
      ...objMinMax,
      tickPixelInterval: 40,
      scrollablePlotArea: {
        minHeight: 100,
        maxHeight: 200,
      },
      title: {
        text: "",
        style: {
          color: x.color,
        },
      },
      labels: {
        overflow: "allow",
        padding: 1,
        allowOverlap: true,
        style: {
          color: x.color,
          fontSize: 10,
        },
      },
      plotLines:
        showThreshold === x.name + x.machineName
          ? [
              {
                ...plotLinesOption,
                value: x.normalLowerControlValue,
                label: {
                  ...plotLinesOption.label,
                  text: "下限値: " + x.normalLowerControlValue,
                  align: "left",
                },
              },
              {
                ...plotLinesOption,
                value: x.normalUpperControlValue,
                label: {
                  ...plotLinesOption.label,
                  text: "上限値: " + x.normalUpperControlValue,
                },
              },
            ]
          : [],
    };
  };
  const handleMouseOver = (e) => {
    const id = e.target.userOptions.name + e.target.userOptions.machineName;
    if (!showThreshold) {
      setShowThreshold(id);
    }
  };
  const handleMouseOut = () => {
    if (showThreshold) {
      setShowThreshold(false);
    }
  };

  const linkYaxis = (x, i, item) => ({
    ...x,
    yAxis: item.length - i - 1,
    events: {
      mouseOver: handleMouseOver,
      mouseOut: handleMouseOut,
    },
  });
  const [type, updateType] = useState(item.type);
  const [data, updateData] = useState(item);
  const [options, updateOptions] = useState(null);
  const [disableWhenLoading, updateDisableRadio] = useState(false);
  let [currentIndex, updateIndex] = useState(0);
  let [isFirstRender, updateIsFirstRender] = useState(true);
  const history = useHistory();
  const defaultType = item.type || 1;
  const [showThreshold, setShowThreshold] = useState(false);
  useEffect(() => {
    updateData(item);
    updateIsFirstRender(false);
    updateOptions({
      ...chartLine,
      chart: {
        scrollablePlotArea: {
          minWidth: 800,
          scrollPositionX: 1,
        },
      },
      series: [...item.series.map(linkYaxis)],
      xAxis: {
        ...chartLine.xAxis,
        categories: item.categories,
        labels: {
          step: item.categories.length
            ? Math.ceil(item.categories.length / 10)
            : 1,
          rotation: -30,
          y: 30,
        },
      },
      yAxis: [...item.series].reverse().map(getYAxis),
      legend: {
        enabled: true,
        useHTML: true,
        labelFormatter: function () {
          return !isPopup
            ? `<span title="${this.userOptions.machineName}">${this.name} (${this.userOptions.unit || ""})</span>`
            : "<span>" + this.name + "</span>";
        },
      },
    });
    const newDisplayItems = {};
    item.series.forEach((item) => {
      newDisplayItems[item.name + item.machineName] =
        displayItems[item.name + item.machineName] == undefined
          ? true
          : displayItems[item.name + item.machineName];
    });
    setDisplayItems(newDisplayItems);
  }, [item]);
  useEffect(() => {
    if (currentIndex >= 0 && !isPopup && !isFirstRender) {
      API.getDataItemChartByType(
        data.itemCode,
        data.id,
        type,
        isPreUseChecklist,
        currentIndex
      ).then(
        (itemUpdate) => {
          updateDisableRadio(false);
          handleDataChart(itemUpdate);
        },
        (err) => {
          updateDisableRadio(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);
  const handleDataChart = (itemUpdate, typeChange = type) => {
    const itemUpdated = itemUpdate.data[0];
    const dataChart = itemUpdated.data;
    const arrLabels = [];
    const arrData = [];
    let hasDataLabel = false;
    dataChart.forEach((line) => {
      // handle line data
      const arrLineValue = [];
      let hasDataValue = false;
      if (line.data.length) {
        const dataLine = line.data;
        dataLine.forEach((itemDayOfLine) => {
          if (!hasDataLabel) {
            let label;
            if (itemDayOfLine.groupTitle && Number(typeChange) !== 2) {
              label = itemDayOfLine.groupTitle;
            } else {
              label = itemDayOfLine.title || "";
            }
            arrLabels.push(label);
          }
          if (itemDayOfLine.value !== null) {
            hasDataValue = true;
          }
          arrLineValue.push(Number(itemDayOfLine.value) || null);
        });
        hasDataLabel = true;
      }
      const lineData = {
        name: line.checkItemName,
        data: hasDataValue ? arrLineValue : [],
        machineName: line.machineForSiteOfficeName || "",
        color: line.color,
        unit: line.unit,
        min: line.min,
        max: line.max,
        normalLowerControlValue: line.normalLowerControlValue,
        normalUpperControlValue: line.normalUpperControlValue,
      };
      arrData.push(lineData);
    });
    let isHasData = false;
    for (let i = 0; i < arrData.length; i++) {
      if (arrData[i].data.length) {
        isHasData = true;
        break;
      }
    }
    let stringCheckItemName = arrData.map((item) => item.name).join(",");
    if (itemUpdated.data[0] && itemUpdated.data[0].checkItemName) {
      stringCheckItemName = itemUpdated.data[0].checkItemName;
    }
    let machineForSiteOfficeName = "";
    if (itemUpdated.data[0] && itemUpdated.data[0].machineForSiteOfficeName) {
      machineForSiteOfficeName = itemUpdated.data[0].checkItemName;
    }
    const dataItem = {
      id: itemUpdated.id,
      itemCode: itemUpdated.checkItemCode || item.itemCode,
      checkItemName: stringCheckItemName,
      labels: arrLabels,
      type: itemUpdated.type,
      machineName: machineForSiteOfficeName || "",
      normalLowerControlValue: itemUpdated.normalLowerControlValue,
      normalUpperControlValue: itemUpdated.normalUpperControlValue,
      min: itemUpdate.min,
      max: itemUpdate.max,
      unit: itemUpdated.unit,
      series: arrData,
      categories: arrLabels,
      isHasData: isHasData,
      reportName: itemUpdated.name,
      checklistType: itemUpdated.checklistType,
    };
    updateItem(dataItem);
  };
  const onChangeDateDynamic = (valueDate, checkCode, id) => {
    updateDisableRadio(true);
    updateType(String(valueDate.target.value));
    !isPopup && updateIndex(0);
    API.getDataItemChartByType(
      checkCode,
      id,
      valueDate.target.value,
      isPreUseChecklist
    ).then(
      (itemUpdate) => {
        updateDisableRadio(false);
        handleDataChart(itemUpdate, String(valueDate.target.value));
      },
      (err) => {
        updateDisableRadio(false);
      }
    );
  };
  const deleteChart = (id) => {
    async function handleDelete() {
      setLoadingDelete && setLoadingDelete(true);
      API.deleteChart(id)
        .then((res) => {
          if (res.status === 200) {
            removeItem(id);
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => setLoadingDelete && setLoadingDelete(false));
    }

    Modal.confirm({
      title: lang.DELETE,
      content: COMMON_CONSTANT.BOM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: handleDelete,
      centered: true,
    });
  };
  const editReport = (id) => {
    history.push(`/DynamicReportManagement/edit/${id}`);
  };
  const clickBack = () => {
    const ind = currentIndex + 1;
    updateIndex(ind);
  };
  const clickNext = () => {
    const ind = currentIndex - 1;
    updateIndex(ind);
  };
  const renderRangeValue = (item) => (
    <Col span={24}>
      <Row
        className="rowNotMargin"
        style={{ fontWeight: "normal", fontSize: "14px" }}
        justify="center"
      >
        <Col flex="none" className="rangeValue">
          {lang.RANGE}:{" "}
        </Col>
        <Col flex="none" className="rangeValue">
          {item.normalLowerControlValue === null &&
          item.normalUpperControlValue !== null
            ? "無し"
            : item.normalLowerControlValue}
          {item.normalLowerControlValue === null &&
          item.normalUpperControlValue === null
            ? "無し"
            : "~"}
          {item.normalUpperControlValue === null &&
          item.normalLowerControlValue !== null
            ? "無し"
            : item.normalUpperControlValue}
          ({item.unit})
        </Col>
      </Row>
    </Col>
  );
  const transTextEllipsis = (text, skip = 30) => {
    if (text && text.length) {
      let newText = "";
      for (let i = 0; i < text.length; i++) {
        if (i < skip) {
          newText += text[i];
        } else {
          newText += " ...";
          break;
        }
      }
      return newText;
    } else {
      return null;
    }
  };

  const [displayItems, setDisplayItems] = useState([]);
  const onChangeCheckBox = (e) => {
    const newDisplayItems = {
      ...displayItems,
      [e.target.value]: e.target.checked,
    };
    setDisplayItems(newDisplayItems);
    setOptionsDisplay({
      ...optionsDisplay,
      series: [],
    });
  };

  const [optionsDisplay, setOptionsDisplay] = useState(options);
  useEffect(() => {
    if (!options) return;
    const newSeries = (item.series || []).filter((item) => {
      return displayItems[item.name + item.machineName];
    });

    setOptionsDisplay({
      ...options,
      series: newSeries.map(linkYaxis),
      yAxis: [...newSeries].reverse().map(getYAxis),
    });
  }, [displayItems, options, showThreshold]);

  const handleDownloadPdf = async () => {
    exportSingleDynamicchart(`chart-${index}`, data.reportName);
  };
  return (
    <Row
      key={index + item.id}
      className="rowNotMargin chart dynamic-report"
      gutter={[24, 32]}
    >
      <Col span={24} className="mb8" style={{ padding: "4px" }}>
        <Row
          className="rowNotMargin"
          justify="space-between"
          align="middle"
          gutter={[0, 8]}
        >
          <Col>
            {orderNumber && !disabledEdit && (
              <Select
                style={{
                  height: "32px",
                  marginRight: 4,
                  fontSize: "14px",
                  verticalAlign: "bottom",
                }}
                onChange={onChangeOrder}
                value={orderNumber}
              >
                {[...Array(dataChartsLength)].map((_, i) => (
                  <Select.Option key={i + 1}>{i + 1}</Select.Option>
                ))}
              </Select>
            )}
            <Radio.Group
              buttonStyle="solid"
              value={type}
              disabled={disableWhenLoading}
              onChange={(e) => onChangeDateDynamic(e, item.itemCode, item.id)}
            >
              <Radio.Button value="1">
                {/*  disabled={Number(defaultType) > 1} */}
                {lang.OTHER_DATE}
              </Radio.Button>
              <Radio.Button value="2">
                {" "}
                {/*  disabled={Number(defaultType) > 2} */}
                {lang.WEEK}
              </Radio.Button>
              <Radio.Button value="3">
                {" "}
                {/*  disabled={Number(defaultType) > 3} */}
                {lang.MONTH}
              </Radio.Button>
              <Radio.Button value="4">{lang.YEAR}</Radio.Button>
            </Radio.Group>
          </Col>
          <Col className="title text-center">
            {isPopup ? (
              <>
                <span style={{ fontWeight: "normal" }}>機器：</span>
                <span style={{ fontWeight: "bold" }}>
                  <Tooltip title={data.machineName} zIndex="100000">
                    {transTextEllipsis(data.machineName)}
                  </Tooltip>
                </span>
                <span style={{ fontWeight: "normal" }}> ー 項目：</span>
                <span style={{ fontWeight: "bold" }}>{data.checkItemName}</span>
              </>
            ) : (
              <>
                <span style={{ fontWeight: "normal" }}>{lang.REPORT}：</span>
                <span style={{ fontWeight: "bold" }}>
                  <Tooltip title={data.reportName} zIndex="100000">
                    {transTextEllipsis(data.reportName)}
                  </Tooltip>
                </span>
              </>
            )}

            {/* {renderRangeValue(data)} */}
          </Col>
          {isPopup ? (
            <></>
          ) : (
            <Row className="nowrap" gutter={[16, 0]} align="middle">
              {!disabledEdit && (
                <Col>
                  <Button
                    type="link"
                    className="iconAction-edit btnLink"
                    onClick={(e) => {
                      editReport(data.id);
                      e.preventDefault();
                    }}
                  >
                    <EditIcon />
                  </Button>
                </Col>
              )}
              {!disabledEdit && (
                <Col>
                  <Button
                    type="link"
                    className="iconAction-edit btnLink"
                    onClick={(e) => {
                      deleteChart(data.id);
                      e.preventDefault();
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Col>
              )}

              {isDynamicScreen && (
                <Col>
                  <Tooltip title={lang.DOWNLOAD_PDF}>
                    <Button
                      type="link"
                      className="iconAction-edit btnLink"
                      onClick={() => handleDownloadPdf()}
                    >
                      <CloudDownloadOutlined style={{ marginTop: 5 }} />
                    </Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
          )}
        </Row>
      </Col>

      {!item.isHasData && (
        <>
          <Col span={24} className="p4 text-center">
            <p>データがありません。</p>
          </Col>
        </>
      )}

      <Col span={3} className="p4 left">
        <Row className="p0" style={{ maxHeight: 420, overflow: "auto" }}>
          {item.isHasData &&
            item.series.map((item, index) => {
              return (
                <Col span={24}>
                  <Checkbox
                    checked={displayItems[item.name + item.machineName]}
                    onChange={onChangeCheckBox}
                    key={index}
                    value={item.name + item.machineName}
                  >
                    <Tooltip title={item.machineName}>{item.name}</Tooltip>
                  </Checkbox>
                </Col>
              );
            })}
        </Row>
      </Col>
      <Col span={21} className="text-center p4">
        {item.isHasData && (
          <HighchartsReact highcharts={Highcharts} options={optionsDisplay} />
        )}
        {!isPopup && (
          <div className="mt8">
            <Button
              disabled={type == 4}
              style={{ minWidth: "unset" }}
              type="text"
              onClick={() => clickBack()}
              icon={
                <CaretLeftFilled
                  style={{ fontSize: "20px", paddingRight: "10px" }}
                />
              }
            />
            {data.labels[0] || moment().subtract(1, "m").format("YYYY/MM/DD")} ~{" "}
            {data.labels[data.labels.length - 1] ||
              moment().format("YYYY/MM/DD")}
            <Button
              style={{ minWidth: "unset" }}
              type="text"
              onClick={() => clickNext()}
              disabled={currentIndex == 0 || type == 4}
              icon={
                <CaretRightFilled
                  style={{ fontSize: "20px", paddingLeft: "10px" }}
                />
              }
            />
          </div>
        )}
      </Col>
    </Row>
  );
}

export default DynamicChart;
