import React, { useEffect } from "react";
import { Row, Col, Typography, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingInline from "@src/component/Popup/loading-inline";
import { selectChecklist } from "@src/redux/checkList";
import { fromUnixTime } from "date-fns";
import format from "@src/utils/format";
import { getChecklistsSaved } from "@src/redux/checkList";
import { useMediaQuery } from "react-responsive";
import TitlePage from "@src/component/Desktop/titlePage";
import InfiniteScroll from "react-infinite-scroller";
import { LeftOutlined } from "@ant-design/icons";
import { lang } from "@src/constants/common.const";
import { KEY } from "../../../constants/common.const";
const { Paragraph } = Typography;
function ShowAllChecklistSaved() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    dataSaved,
    isLoadingSaved,
    offsetSavedChecklist,
    isLoadMore,
  } = useSelector((state) => state.checklist);
  const { statusNetwork } = useSelector((state) => state.auth);
  const goToDetail = (obj) => {
    dispatch(selectChecklist(obj, history));
  };
  useEffect(() => {
    if (navigator.onLine) {
      dispatch(getChecklistsSaved("first", 11001, 20));
    }
  }, [dispatch]);
  const loadMore = () => {
    dispatch(getChecklistsSaved(offsetSavedChecklist, 11001, 20));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  return (
    <div
      className={
        isDesktopOrLaptop ? "des-content" : "des-content des-content__mobile"
      }
      style={!statusNetwork ? { marginTop: "110px" } : {}}
    >
      <Row
        className="rowNotMargin button-back"
        align="middle"
        gutter={[8, 8]}
        onClick={() => history.goBack()}
      >
        <Col className="icon-back">
          <LeftOutlined />
        </Col>
        <Col className="text">{lang.BACK}</Col>
      </Row>
      <TitlePage name={lang.LIST_CHECK_RESULT} />
      <div
        id="animation"
        className={isDesktopOrLaptop ? "showAll" : "showAll showAll__mobile"}
      >
        <div className={isDesktopOrLaptop ? "card-detail" : ""}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadMore}
            hasMore={isLoadMore && !isLoadingSaved}
            useWindow={true}
          >
            <Row gutter={[16, 16]} justify="center" className="rowNotMargin">
              {dataSaved.map((item, index) => (
                <Col id="animation" xs={24} md={12} lg={6} key={index}>
                  <div
                    id="animation"
                    // align="middle"
                    // justify="center"
                    className={`itemList itemList__notBookMark itemList__grid__notBookMark`}
                    onClick={() => goToDetail(item)}
                  >
                    <Paragraph ellipsis={{ rows: 2 }} className="title">
                      <Tooltip title={item.checklistName}>
                        {item.checklistName}
                      </Tooltip>
                    </Paragraph>
                    <div span={24} className="status">
                      <Paragraph
                        ellipsis={{ rows: 1 }}
                        className="textStatus"
                        style={{
                          color: item.color,
                          background: item.color + "1A",
                        }}
                      >
                        {item.statusValue}
                      </Paragraph>
                    </div>
                    <div className="created" span={24}>
                      {format(
                        fromUnixTime(Number(item.checkDate)),
                        "yyyy/MM/dd"
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>

          <Col span={24} className="text-center">
            <LoadingInline isLoadingInline={isLoadingSaved} />
          </Col>
        </div>
      </div>
    </div>
  );
}

export default ShowAllChecklistSaved;
