class classItem {
  constructor(
    Order,
    CheckItemCode,
    CheckItemName,
    CheckOrder,
    ID,
    accumulatedType,
    accumulatedTypeValue,
    multiplier,
    InspectionTiming,
    MachineforSiteOfficeIDId,
    NormalLowerControlValue,
    NormalUpperControlValue,
    mode = "",
    unit,
    checked,
    multipleTime = false,
    timeArr = [],
    inputContentOptions,
    defaultType = "1",
    defaultValue = "",
    autoCalculate = false,
    itemCodeRefer,
    itemJudgement = "1"
  ) {
    this.Order = Order;
    this.CheckItemCode = CheckItemCode;
    this.CheckItemName = CheckItemName;
    this.ID = ID;
    this.accumulatedType = accumulatedType;
    this.accumulatedTypeValue = accumulatedTypeValue;
    this.multiplier = multiplier;
    this.InspectionTiming = InspectionTiming;
    this.MachineforSiteOfficeIDId = MachineforSiteOfficeIDId;
    this.NormalLowerControlValue = NormalLowerControlValue;
    this.NormalUpperControlValue = NormalUpperControlValue;
    this.mode = mode;
    this.unit = unit;
    this.CheckOrder = CheckOrder;
    this.checked = checked;
    this.multipleTime = multipleTime;
    this.timeArr = timeArr;
    this.inputContentOptions = inputContentOptions;
    this.defaultType = defaultType;
    this.defaultValue = defaultValue;
    this.autoCalculate = autoCalculate;
    this.itemCodeRefer = itemCodeRefer;
    this.itemJudgement = itemJudgement;
  }
}

export default classItem;
