import { EditOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ApiProcessMangement } from "../../../api/backend/prcessManagement";
import BreakLine from "../../../component/Desktop/breakLine";
import GroupItem from "../../../component/Desktop/groupItem";
import Loading from "../../../component/Desktop/loading/loading";
import RowInput from "../../../component/Desktop/rowInput";
import TitlePage from "../../../component/Desktop/titlePage";
import { KEY, lang } from "../../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import { checkRoleScreen } from "../../../utils/roles";
function ViewProcess() {
  const [showLoading, setShowLoading] = useState(false);
  const [nameFacility, setNamefacility] = useState();
  const [nameProcess, setNameProcess] = useState();
  const [nameProcessMaster, setNameProcessMaster] = useState();
  const [status, setStatus] = useState();
  const history = useHistory();
  const { id } = useParams();
  const { allowRoute, isLeader } = useSelector((state) => state.auth);
  const location = useLocation();
  const listData = [
    {
      title: lang.NAME_FACILITY,
      value: nameFacility,
    },
    {
      title: lang.PROCESS_MASTER_NAME,
      value: nameProcessMaster,
    },
    {
      title: lang.NAME_PROCESS,
      value: nameProcess,
    },
    {
      title: lang.STATUS,
      value: status,
    },
  ];
  const renderListData = listData.map((item, index) => (
    <div key={index}>
      <BreakLine />
      <RowInput name={item.title} box>
        {item.value}
      </RowInput>
      <BreakLine />
    </div>
  ));
  useEffect(() => {
    const getDetailt = async () => {
      try {
        if (id) {
          setShowLoading(true);
          const response = await ApiProcessMangement.getDetailtProcess({
            processId: id,
          });
          if (response.data) {
            const { processMasterName, facilityName, processName, status } =
              response.data;
            setNameProcessMaster(processMasterName);
            setNameProcess(processName);
            setNamefacility(facilityName);
            setStatus(status);
          } else {
            throw { status: 404 };
          }
        } else {
          showMessage(KEY.ERROR, MSG_CATCH(), () => history.goBack());
        }
      } catch (error) {
        if (error.status === 404) {
          history.goBack();
        } else {
          showMessage(KEY.ERROR, MSG_CATCH(), () => history.goBack());
        }
      } finally {
        setShowLoading(false);
      }
    };
    if (checkRoleScreen(location.pathname, allowRoute)) getDetailt();
  }, [id, allowRoute]);
  return (
    <div className="des-content">
      <TitlePage name={lang.PROCESS_CONTROL_INFOMATION} />
      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">
            {lang.PROCESS_CONTROL_INFOMATION}
          </h1>
        </div>
        <div className="mt15"></div>
        {renderListData}
        <Row className="mt20" justify="end" gutter={[8, 8]}>
          {isLeader && (
            <Button
              className="buttonPC button--info wAuto"
              onClick={() => history.push(`/EditProcess/${id}`)}
            >
              <EditOutlined />
              {lang.EDIT}
            </Button>
          )}
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={() => history.goBack()}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Row>
      </GroupItem>
      <Loading show={showLoading} />
    </div>
  );
}

export default ViewProcess;
