/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  Modal,
  Table,
  Checkbox,
  DatePicker,
} from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import InputPositiveNumber from "../../component/Desktop/inputPositiveNumber";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import SearchMachine from "../../component/Desktop/searchMachine";
import Loading from "../../component/Desktop/loading/loading";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import API from "../../api/backend/workReport";
import API_INVENTORY from "../../api/backend/inventory";
import KBN from "../../api/backend/kbn";
import {
  renderOption,
  MSG_CATCH,
  statusRes,
  getNameFile,
  getDotFile,
  isImage,
  hasPreview,
  showMessage,
  removeItemArr,
  isEmpty,
  renderOptionOneCheck,
} from "../../constants/utils";
import { Link, useHistory, useLocation } from "react-router-dom";
import ListHistoryCL from "../../page/History_CL/listPopup";
import AutoMail from "../../component/Desktop/autoMail";
import { useParams } from "react-router-dom";

import {
  CloseCircleOutlined,
  CloseCircleFilled,
  SaveOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import classCheckbox from "../../models/control/checkbox";
import classBuhin from "../../models/machineBuhin";
import classText from "../../models/control/text";
import classMachine from "../../models/machineDes";
import classPopup from "../../models/control/popup";
import moment from "moment";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";

//Declare global variables
const { TextArea } = Input;
let FLAG_CHANGE_DATA = false;
const formDataAttach = new FormData();
let indexFile = 0;

export const REPORT_TYPE = [
  { id: "1", name: "作業報告" },
  { id: "2", name: "トラブル報告" },
];

function CreateWorkReport() {
  let { id, id: modeUpdate, typeReport, idResultCheck } = useParams();
  const history = useHistory();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  let [hdnChecklistID, sethdnChecklistID] = useState(new classText());
  let [txtName, settxtName] = useState(new classText()); //Required
  let [reportDate, setReportDate] = useState(new classText());
  let txtNameEl = useRef(null);
  let reportDateRef = useRef(null);
  let [ddlReportType, setddlReportType] = useState(new classDdl()); //Required
  let [ddlPriority, setddlPriority] = useState(new classDdl());
  let [txtStatus, settxtStatus] = useState(new classText());
  let [peConfirm, setpeConfirm] = useState(new classText());
  let [peApprove, setpeApprove] = useState(new classText()); //Required
  let [peApproveEl, setpeApproveEl] = useState(null);
  const [buhins, setBuhins] = useState([]);

  // Focus
  const fileEl = useRef(null);
  const fileElManagement = useRef(null);

  // Table
  let [dataMachine, setdataMachine] = useState([]);
  let [rowShow, setrowShow] = useState([]);

  //
  let [txtTroubleInformation, settxtTroubleInformation] = useState(
    new classText()
  ); //Required
  let txtTroubleInformationEl = useRef(null);
  let [txtRenovationMethod, settxtRenovationMethod] = useState(new classText()); //Required
  let txtRenovationMethodEl = useRef(null);
  const [chkAutoExport, setChkAutoExport] = useState({
    ...new classCheckbox(),
    checked: true,
  });

  // Popup
  let [popupHistoryCL, setPopupHistoryCL] = useState(new classPopup());
  let [popupAttach, setPopupAttach] = useState(new classPopup());
  const [idSelect, setIdSelect] = useState(null);
  const [dataSelected, setDataSelected] = useState(null);
  // In-Popup
  let [tblAttach, setTblAttach] = useState([]);

  // Show hide expand
  const showHideRow = (v, row) => {
    if (v) {
      rowShow.push(row.ID);
    } else {
      rowShow = removeItemArr(rowShow, row.ID);
    }
    setrowShow([...rowShow]);
  };
  const enableUpdateMachine = !(modeUpdate && chkAutoExport.checked);

  // Column Machine
  const colFix = [
    { title: "機器名", dataIndex: "Name", width: "20%" },
    { title: "施設", dataIndex: "facilityName", align: "center", width: "16%" },
    { title: "棟", dataIndex: "buildingName", align: "center", width: "14%" },
    { title: "階", dataIndex: "floorName", align: "center", width: "10%" },
    { title: "部屋", dataIndex: "roomName", align: "center", width: "12%" },
    {
      title: "プロセス",
      dataIndex: "processName",
      align: "center",
      width: "9%",
    },
  ];
  const colSelect = {
    title: "交換", // Select
    width: "9%",
    align: "center",
    dataIndex: "checked",
    render: (txt, row = classMachine(), id) => {
      return (
        <Checkbox
          checked={txt}
          onChange={(v) => {
            let checked = v.target.checked;
            showHideRow(checked, row);
            dataMachine[id].checked = checked;
            setdataMachine(dataMachine);
          }}
        ></Checkbox>
      );
    },
  };
  const colDel = {
    title: "削除", // Del
    width: "9%",
    align: "center",
    render: (txt, row, index) => {
      return (
        <Button
          type="link"
          onClick={() => {
            let lNew = [...dataMachine];
            lNew = lNew.filter((v) => v.ID !== row.ID);
            setdataMachine(lNew);
          }}
        >
          <CloseCircleFilled className="iconM red" />
        </Button>
      );
    },
  };
  const columnsMachineSelect = enableUpdateMachine
    ? [...colFix, colSelect, colDel]
    : [...colFix];

  // Column Attach
  const columnsAttach = [
    {
      title: "No",
      render: (text, item, index) => {
        return <div className="center">{++index}</div>;
      },
    },
    {
      title: "プレビュー",
      dataIndex: "FileName",
      render: (text, row) => {
        let urlFile = row.file
          ? URL.createObjectURL(row.file)
          : row.attachmentUrl;
        if (isImage(row.DotName)) {
          return (
            <div className="center">
              <a target="_blank" rel="noopener noreferrer" href={urlFile}>
                <img
                  width="48"
                  height="48"
                  border="0"
                  alt="プレビュー"
                  title={row.FileName}
                  dragid="0"
                  draggable="true"
                  src={urlFile}
                />
              </a>
            </div>
          );
        } else {
          return (
            <div className="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFile}
                title={row.FileName}
                download={hasPreview(row.DotName) ? null : row.FileName}
              >
                <PaperClipOutlined className="blue iconM" />
              </a>
            </div>
          );
        }
      },
    },
    {
      title: "ファイル名",
      dataIndex: "FileName",
      render: (text, row, index) => {
        return (
          <Input
            maxLength={255}
            defaultValue={text}
            onChange={(v) => {
              tblAttach[index]["FileName"] = v.target.value;
              setTblAttach(tblAttach);
            }}
          />
        );
      },
    },
    {
      title: "備考とメヂイアの挿入と操作",
      dataIndex: "Description",
      render: (text, row, index) => {
        return (
          <Input
            maxLength={255}
            defaultValue={text}
            onChange={(v) => {
              tblAttach[index]["Description"] = v.target.value;
              setTblAttach(tblAttach);
            }}
          />
        );
      },
    },
    {
      title: "削除",
      align: "center",
      render: (text, row, index) => {
        return (
          <DeleteOutlined
            className="iconM red"
            onClick={() => {
              let list = [...tblAttach].filter(
                (item) => item["ItemOrder"] !== row["ItemOrder"]
              );
              setTblAttach(list);
            }}
          />
        );
      },
    },
  ];

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [
    hdnChecklistID,
    txtName,
    txtNameEl,
    ddlReportType,
    ddlPriority,
    txtStatus,
    peConfirm,
    peApprove,
    dataMachine,
    txtTroubleInformation,
    txtRenovationMethod,
  ]);

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      openLoadingSpinner();
      setInitPage(true);

      // Detail
      Promise.all([
        id ? API.getDetail(id) : null,
        KBN.getPriority(),
        KBN.getWorkReportType(),
        idResultCheck ? API.getCheckResult(idResultCheck) : null,
        API_INVENTORY.searchItems({
          inventoryType: 2,
        }),
      ])
        .then((res) => {
          if (res[0] && !res[0].data && id) {
            history.push("/WorkReportManagement");
            return;
          }
          let d1 = res[0] ? res[0].data : {};
          let d2 = res[1] ? res[1].data.data : []; // ddlPriority
          let d3 = res[2] ? res[2].data.data : []; // ddlReportType
          let d4 = res[3] ? res[3].data : {}; // result check name
          let d5 = res[4] ? res[4].data : {}; // listBuhin

          // Buhin
          setBuhins(
            d5.data.map((item) => {
              item["key"] = item.inventoryItemCode;
              item["name"] = item.inventoryItemName;
              return item;
            })
          );
          let reportDate = d1.reportDate
            ? moment.unix(d1.reportDate)
            : moment(new Date());
          setReportDate({ ...reportDate, value: reportDate });
          // Data detail
          if (id) {
            setddlPriority({ ...ddlPriority, options: d2, value: d1.priority });
            // Detail - 0
            showDetail(d1, d3);
          } else {
            // DDL Priority
            setddlPriority({ ...ddlPriority, options: d2 });
            setddlReportType({
              ...ddlReportType,
              options: d3,
              value: typeReport || d3[0].key,
            });
            if (idResultCheck && d4.data) {
              sethdnChecklistID({
                ...hdnChecklistID,
                value: idResultCheck,
                message: d4.data,
              });
            }
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          setTimeout(() => {
            setInitPage(false);
          }, 500); // waiting for setup
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);

  useEffect(() => {
    let trouble = { ...txtTroubleInformation },
      confrim = { ...peConfirm },
      approve = { ...peApprove },
      priority = { ...ddlPriority };

    if (Number(ddlReportType.value) === 1) {
      // 作業報告
      trouble.show = false;

      // Hide email confirm/approve
      confrim.show = false;
      approve.show = false;

      // //F11B11SHIEV-2725
      // // Disable 優先度
      priority.value = "";
      priority.disabled = true;
    } else {
      /// トラブル報告
      trouble.show = true;

      // Show email confirm/approve
      confrim.show = true;
      approve.show = true;

      // //F11B11SHIEV-2725
      priority.disabled = false;
    }
    //check permission from other factory
    // TODO

    // Update state
    settxtTroubleInformation(trouble);
    setpeConfirm(confrim);
    setpeApprove(approve);
    setddlPriority(priority);
  }, [ddlReportType.value]);

  const showDetail = (data, optionReportType) => {
    //Group1
    sethdnChecklistID({
      ...hdnChecklistID,
      message: data.checkResultName,
      value: data.checkResultId,
    });
    settxtName({ ...txtName, value: data.name });

    settxtStatus({ ...txtStatus, value: data.statusValue });
    peConfirm.users = [data.confirmer];
    setpeConfirm({ ...peConfirm, users: [data.confirmer] });

    peApprove.users = [data.approver];
    setpeApprove({ ...peApprove, users: [data.approver] });
    //Group2
    settxtRenovationMethod({ ...txtRenovationMethod, value: data.solution });
    txtTroubleInformation.value = data.description;
    settxtTroubleInformation({
      ...txtTroubleInformation,
      value: data.description,
    });
    setChkAutoExport({ ...chkAutoExport, checked: data.createExport });
    // txtReason.value = data.rejectReason;
    // settxtReason({ ...txtReason, value: data.rejectReason });
    // Table machine
    let show = [];
    data.listWorkReportDetail = data.listWorkReportDetail.map(
      (v = new classMachine()) => {
        let row = new classMachine();
        row = v;
        row.ID = v.machineForSiteOfficeCode; // Key
        row.Name = v.machineForSiteOfficeName;
        row.LstBuhin = v.buhins.map((b = new classBuhin()) => {
          return {
            inventoryWarehouseName: b.inventoryWarehouseName,
            inventoryWarehouseCode: b.inventoryWarehouseCode,
            inventoryWarehouseItemCode: b.inventoryWarehouseItemCode,
            inventoryItemName: b.inventoryItemName,
            inventoryItemCode: b.inventoryItemCode,
            stockQuantity: b.stockQuantity,
            Quantity: b.inventoryQuantity,
            machineForSiteOfficeCode: b.machineForSiteOfficeCode,
          };
        });
        // Show sub
        if (row.LstBuhin.length > 0) {
          row.checked = true;
          // Show list buhin
          show.push(row.ID);
        }
        return row;
      }
    );
    setrowShow(show);
    setdataMachine(data.listWorkReportDetail);
    // Popup attach
    data.listWorkReportAttachment = data.listWorkReportAttachment.map((v) => {
      let row = {};
      row["ItemOrder"] = v.itemOrder; // Key
      row["FileName"] = getNameFile(v.fileName);
      row["DotName"] = getDotFile(v.fileName);
      row["Description"] = v.description;
      row["attachmentUrl"] = v.attachmentUrl;
      return row;
    });
    indexFile = data.listWorkReportAttachment.length + 1;
    setTblAttach(data.listWorkReportAttachment);
    // update screen
    setddlReportType({
      ...ddlReportType,
      value: data.reportType,
      options: optionReportType,
    });
  };

  // Popup common
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  const onCloseSearchHistory = () => {
    setPopupHistoryCL({ ...popupHistoryCL, show: false });
    setDataSelected(null);
    setIdSelect(null);
  };

  //Save function: 1
  function confirmSaveData() {
    if (validateSave()) {
      showConfirmMessage(COMMON_CONSTANT.BOM_C008, function () {
        openLoadingSpinner();
        // Create Form data
        const formData = new FormData();

        // Create Object
        let formInput = {
          CheckResultId: hdnChecklistID.value,
          Name: txtName.value,
          ReportType: ddlReportType.value,
          ReportDate: moment(reportDate.value).unix(),
          Priority: ddlPriority.value,
          Confirmer: peConfirm.list.length > 0 ? peConfirm.list[0].value : null,
          MailConfirm:
            peConfirm.list.length > 0 ? peConfirm.list[0].email : null,
          Approver: peApprove.list.length > 0 ? peApprove.list[0].value : null,
          MailApprove:
            peApprove.list.length > 0 ? peApprove.list[0].email : null,
          Description: txtTroubleInformation.value,
          Solution: txtRenovationMethod.value,
        };
        if (!modeUpdate && Number(ddlReportType.value) === 1) {
          formInput["createExport"] = chkAutoExport.checked;
        }
        //MACHINE
        if (dataMachine.length > 0 && enableUpdateMachine) {
          // Add to obj
          formInput["ListMachineInfo"] = dataMachine.map(
            (m = new classMachine()) => {
              let o = new classMachine();
              o.MachineForSiteOfficeCode = m.ID;
              o.MachineForSiteOfficeName = m.Name;
              o.LstBuhin = m.checked ? m.LstBuhin : [];
              return o;
            }
          );
        }
        // FILE
        if (tblAttach.length > 0) {
          // Add to obj
          formInput["ListAttachment"] = tblAttach.map((v) => {
            v["FileName"] = v.FileName + "." + v.DotName; // Full file name
            return v;
          });
          // Add list file to FormDATA
          tblAttach.forEach((v, i) => {
            formData.append(`file[${i}]`, v.file);
          });
        }

        formData.append("data", JSON.stringify(formInput));
        // Sent data to Server
        API.save(formData, id)
          .then((res) => {
            if (statusRes(res)) {
              // Success
              showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backToSearch);
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            showMessage(
              KEY.ERROR,
              (err.response.data && err.response.data.msg.value) || MSG_CATCH()
            );
          })
          .then(() => {
            closeLoadingSpinner();
          });
      });
    }
    return false;
  }

  function validateSave() {
    let flagErr = false,
      elmFocus;
    // txtName, peApprove, txtTroubleInformation, txtRenovationMethod
    txtName = { ...txtName, value: txtName.value.trim(), error: false };
    reportDate = { ...reportDate, value: reportDate.value, error: false };
    peApprove = { ...peApprove, value: peApprove.value, error: false };
    txtTroubleInformation = {
      ...txtTroubleInformation,
      value: txtTroubleInformation.value.trim(),
      error: false,
    };
    txtRenovationMethod = {
      ...txtRenovationMethod,
      value: txtRenovationMethod.value.trim(),
      error: false,
    };

    if (String(txtRenovationMethod.value) === "") {
      flagErr = true;
      // Border red
      txtRenovationMethod = { ...txtRenovationMethod, error: true };
      elmFocus = txtRenovationMethodEl;
    }
    if (Number(ddlReportType.value) === 2) {
      //トラブル報告
      if (String(txtTroubleInformation.value) === "") {
        flagErr = true;
        // Border red
        txtTroubleInformation = { ...txtTroubleInformation, error: true };
        elmFocus = txtTroubleInformationEl;
      }
      if (peApprove.list.length === 0 || peApprove.message.length > 0) {
        flagErr = true;
        // Border red
        peApprove = { ...peApprove, error: true };
        elmFocus = peApproveEl;
      }
    }

    if (reportDate.value === null || reportDate.value === "") {
      flagErr = true;
      // Border red
      reportDate = { ...reportDate, error: true };
      elmFocus = reportDateRef;
    }

    if (txtName.value.length === 0) {
      flagErr = true;
      // Border red
      txtName = { ...txtName, error: true };
      elmFocus = txtNameEl;
    }
    // Focus item error
    elmFocus && elmFocus.current.focus();

    // Update state
    settxtName(txtName);
    setReportDate(reportDate);
    setpeApprove(peApprove);
    settxtTroubleInformation(txtTroubleInformation);
    settxtRenovationMethod(txtRenovationMethod);

    if (!flagErr) {
      let require = false;
      let exceed = false;
      let min = false;

      // Validate table item
      dataMachine.forEach((m = new classMachine()) => {
        m.LstBuhin.forEach((buhin = new classBuhin()) => {
          // Require
          if (
            isEmpty(buhin.inventoryItemCode) ||
            isEmpty(buhin.inventoryWarehouseItemCode)
          ) {
            require = true;
          }
          if (isEmpty(buhin.Quantity)) {
            require = true;
          } else if (isNaN(buhin.Quantity) || Number(buhin.Quantity) === 0) {
            // Min
            min = true;
          } else {
            // Empty
          }

          // Exceed
          if (
            chkAutoExport.checked &&
            !isEmpty(buhin.stockQuantity) &&
            !isNaN(buhin.stockQuantity) &&
            Number(buhin.Quantity) > Number(buhin.stockQuantity)
          ) {
            exceed = true;
          }
        });
      });
      // Show message err
      if (require || min || exceed) {
        const msg = [];
        const firstLine = "・";
        require && msg.push(firstLine + COMMON_CONSTANT.BOM_E031);
        exceed && msg.push(firstLine + COMMON_CONSTANT.BOM_E032);
        min && msg.push(firstLine + COMMON_CONSTANT.BOM_E034);

        // Set fail
        flagErr = true;
        // Show message
        showMessage(
          KEY.ERROR,
          <div className="white-space">{msg.join("\n")}</div>
        );
      }
    }

    return !flagErr;
  }

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA && !idResultCheck) {
      // No update
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(
        COMMON_CONSTANT.BOM_C018,
        function () {
          backToSearch();
        },
        function () {
          FLAG_CHANGE_DATA = true;
        }
      );
    }
  }

  // Table Machine+Buhin
  const addMachineToTable = (list = []) => {
    // Filter duplicate
    dataMachine.forEach((m = new classMachine()) => {
      list = list.filter(
        (l = new classMachine()) => String(m.ID) !== String(l.ID)
      );
    });

    //
    list.map((m = new classMachine()) => {
      m.checked = false;
      return m;
    });
    // Update
    let d = [...dataMachine];
    d.push(...list);
    setdataMachine(d);
  };

  // Start: Tbl Attach
  function showAttachFileLst() {
    // Show popup
    setPopupAttach({ ...popupAttach, show: true });
  }

  function readAttachFileUrl(ev) {
    let el = ev.target;
    if (el.files && el.files[0]) {
      formDataAttach.append(indexFile++, el.files[0]);
      el.value = ""; // Clear file
      // Render
      renderTableAttach();
    }
  }

  function renderTableAttach() {
    let list = [...tblAttach];
    let id = indexFile - 1;
    let file = formDataAttach.get(id);
    let row = {};
    row["ItemOrder"] = id; // Key
    row["FileName"] = getNameFile(file.name);
    row["DotName"] = getDotFile(file.name);
    row["Description"] = "";
    row["file"] = file; // File
    list.push(row);
    setTblAttach(list);
  }
  // End: Tbl Attach

  const listWarehouseByItem = (itemCode) => {
    return (
      buhins
        // Get list warehouse by item
        .filter((i = new classBuhin()) => i.inventoryItemCode === itemCode)[0]
        // to Option
        .warehouseItems.map((wh) => {
          wh["key"] = wh.inventoryWarehouseItemCode;
          wh["name"] = wh.inventoryWarehouseName;
          return wh;
        })
    );
  };
  const renderListItem = (listItem, indexMachine) => {
    return (
      <>
        {/* Item, Warehouse, Quantity, Delete */}
        {listItem.map((b = new classBuhin(), indexItem) => {
          let optionsWarehouse = [];
          if (!isEmpty(b.inventoryItemCode)) {
            optionsWarehouse = listWarehouseByItem(b.inventoryItemCode);
          }
          const stockQuantity = b.stockQuantity || "";
          const item_err = isEmpty(b.inventoryItemCode) ? STYLE.BORDER_RED : "";
          const warehouse_err = isEmpty(b.inventoryWarehouseItemCode)
            ? STYLE.BORDER_RED
            : "";
          const quantity_error =
            isEmpty(b.Quantity) ||
            isNaN(b.Quantity) ||
            (!isEmpty(b.Quantity) && Number(b.Quantity) <= 0) ||
            (chkAutoExport.checked &&
              !isEmpty(b.stockQuantity) &&
              Number(b.Quantity) > Number(b.stockQuantity))
              ? STYLE.BORDER_RED
              : "";

          return enableUpdateMachine ? (
            <tr key={indexItem}>
              {/* Item */}
              <td>
                <Select
                  className={item_err + " w100"}
                  onChange={(v) => {
                    onChangeItem(v, indexMachine, indexItem);
                  }}
                  value={b.inventoryItemCode}
                >
                  {renderOption(buhins)}
                </Select>
              </td>
              {/* Warehouse */}
              <td>
                <Select
                  className={warehouse_err + " w100"}
                  disabled={isEmpty(b.inventoryItemCode)}
                  onChange={(v) => {
                    onChangeWarehouse(
                      v,
                      optionsWarehouse,
                      indexMachine,
                      indexItem
                    );
                  }}
                  value={b.inventoryWarehouseItemCode}
                >
                  {renderOptionOneCheck(optionsWarehouse, true, {
                    value: b.inventoryWarehouseItemCode,
                    list: listItem.map((i) => i.inventoryWarehouseItemCode),
                  })}
                </Select>
              </td>
              {/* Stock quantity */}
              {chkAutoExport.checked && <td>{stockQuantity}</td>}
              {/* Quantity */}
              <td>
                <InputPositiveNumber
                  value={b.Quantity}
                  maxLength="4"
                  min={0}
                  className={
                    quantity_error + " quantity-buhin oss-number w100 center"
                  }
                  onChange={(e) => {
                    changeQuantity(e, indexMachine, indexItem);
                  }}
                />
              </td>
              {/* Delete */}
              <td>
                <Button
                  className=" btnDelete"
                  onClick={() => {
                    onDeleteBuhin(indexMachine, indexItem);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </td>
            </tr>
          ) : (
            <tr key={indexItem}>
              <td>{b.inventoryItemName}</td>
              <td>{b.inventoryWarehouseName}</td>
              <td>{b.Quantity}</td>
            </tr>
          );
        })}
        {/* Button add */}
        {enableUpdateMachine && (
          <tr>
            <td>
              <Button
                className="buttonPC button--info wAuto w100"
                onClick={() => {
                  onAddBuhin(indexMachine);
                }}
              >
                <PlusOutlined />
                {lang.ADD}
              </Button>
            </td>
          </tr>
        )}
      </>
    );
  };

  const onAddBuhin = (indexMachine) => {
    const data = [...dataMachine];
    data[indexMachine].LstBuhin.push(new classBuhin());
    setdataMachine(data);
  };
  const onChangeItem = (v, indexMachine, indexItem) => {
    const data = [...dataMachine];
    // Set column item
    data[indexMachine].LstBuhin[indexItem].inventoryItemCode = v;
    // Check auto fill
    const lWarehouse = isEmpty(v) ? [] : listWarehouseByItem(v);
    if (isEmpty(v) || lWarehouse.length > 1) {
      // Clear warehouse
      data[indexMachine].LstBuhin[indexItem].inventoryWarehouseItemCode = null;
      // Clear quantity
      data[indexMachine].LstBuhin[indexItem].stockQuantity = null;
    } else {
      //  Auto fill
      onChangeWarehouse(lWarehouse[0].key, lWarehouse, indexMachine, indexItem);
    }
    // Set
    setdataMachine(data);
  };
  const onChangeWarehouse = (v, optionsWarehouse, indexMachine, indexItem) => {
    const data = [...dataMachine];
    // Column warehouse
    data[indexMachine].LstBuhin[indexItem].inventoryWarehouseItemCode = v;
    // Column current quantity
    const itemSel = optionsWarehouse.filter(
      (o) => o.inventoryWarehouseItemCode === v
    );
    if (!isEmpty(v) && itemSel.length > 0) {
      data[indexMachine].LstBuhin[indexItem].stockQuantity =
        itemSel[0].stockQuantity;
    } else {
      data[indexMachine].LstBuhin[indexItem].stockQuantity = null;
    }
    // Set
    setdataMachine(data);
  };
  const changeQuantity = (e, indexRow, indexItem) => {
    const data = [...dataMachine];
    data[indexRow].LstBuhin[indexItem].Quantity = e.target.value;

    setdataMachine(data);
  };
  const onDeleteBuhin = (indexMachine, indexItem) => {
    const data = [...dataMachine];
    data[indexMachine].LstBuhin = removeItemArr(
      data[indexMachine].LstBuhin,
      data[indexMachine].LstBuhin[indexItem]
    );
    setdataMachine(data);
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={id ? "作業報告編集" : "作業報告作成"} />
      {/* Group 1 */}
      <GroupItem name="報告情報" sub="報告の情報をご入力ください。" center>
        <RowInput name={lang.CHECK_RESULT_DESTINATION}>
          <Row justify="space-between" gutter={[16, 8]}>
            <Col span="24" className="textOverflow ">
              <Button
                className="buttonPC button--info wAuto"
                onClick={() => {
                  setPopupHistoryCL({ ...popupHistoryCL, show: true });
                }}
              >
                <SearchOutlined className="" />
                {lang.SEARCH}
              </Button>
              <Link
                className="ml15"
                target="_blank"
                to={"/history-detail/" + hdnChecklistID.value}
              >
                {hdnChecklistID.message}
              </Link>
            </Col>
          </Row>
        </RowInput>

        <RowInput name={lang.REPORT_NAME} required>
          <Input
            ref={txtNameEl}
            maxLength={255}
            className={txtName.error ? STYLE.BORDER_RED : ""}
            value={txtName.value}
            onChange={(e) => settxtName({ ...txtName, value: e.target.value })}
          />
        </RowInput>

        <RowInput name={lang.REPORT_DATE} required>
          <DatePicker
            format={reportDate.format || KEY.DATE_DEFAULT}
            ref={reportDateRef}
            value={reportDate.value}
            picker={reportDate.picker}
            placeholder={reportDate.format || KEY.DATE_DEFAULT}
            disabledDate={(d) => !d || d.isAfter(new Date())}
            className={"w100 " + (reportDate.error ? STYLE.BORDER_RED : "")}
            onChange={(v, dateString) => {
              setReportDate({
                ...reportDate,
                value: v,
              });
            }}
            {...reportDate.input}
          />
        </RowInput>

        <RowInput name={lang.REPORT_LEVEL} required>
          <Row>
            <Col span="10">
              <Select
                disabled={modeUpdate}
                value={ddlReportType.value}
                className="w100"
                onChange={(v) => {
                  setddlReportType({ ...ddlReportType, value: v });
                }}
              >
                {renderOption(ddlReportType.options, false)}
              </Select>
            </Col>
            <Col
              span="14"
              style={{ marginTop: "-10px", marginBottom: "-10px" }}
            >
              <RowInput name={lang.PRIORITY}>
                <Select
                  value={ddlPriority.value}
                  className="w100"
                  disabled={ddlPriority.disabled}
                  onChange={(v) => {
                    setddlPriority({ ...ddlPriority, value: v });
                  }}
                >
                  {renderOption(ddlPriority.options)}
                </Select>
              </RowInput>
            </Col>
          </Row>
        </RowInput>
        {peConfirm.show && (
          <>
            <RowInput name="確認者のメールアドレス">
              <AutoMail get={peConfirm} set={setpeConfirm}></AutoMail>
            </RowInput>
          </>
        )}

        {peApprove.show && (
          <>
            <RowInput name="承認者のメールアドレス" required>
              <AutoMail
                className={peApprove.error ? STYLE.BORDER_RED : ""}
                setRef={setpeApproveEl}
                get={peApprove}
                set={setpeApprove}
              ></AutoMail>
            </RowInput>
          </>
        )}
        {id && (
          <>
            <RowInput name={lang.STATUS} required>
              <Input value={txtStatus.value} disabled />
            </RowInput>
          </>
        )}
      </GroupItem>
      {/* Group 2 select machine */}
      {enableUpdateMachine && (
        <GroupItem
          name="機器交換"
          sub="機器の部品等を交換する場合、以下の情報をご入力ください。"
          expand
          center
          initExpand={false}
        >
          <SearchMachine
            listMachineAdded={dataMachine}
            onAdd={addMachineToTable}
            getBuhin={false}
          />
        </GroupItem>
      )}
      {/* Grid */}
      {dataMachine.length > 0 && (
        <GroupItem name="交換対象の機器一覧" center>
          <Table
            rowKey="ID"
            className="tbl-original mt15"
            scroll={{ x: 100, y: 500 }}
            pagination={false}
            columns={columnsMachineSelect}
            dataSource={dataMachine}
            expandable={{
              expandedRowKeys: rowShow,
              rowExpandable: (row) => row.checked,
              onExpand: showHideRow,
              expandedRowRender: (row = new classMachine(), indexMachine) => {
                return (
                  <table className="sub-table">
                    <thead>
                      <tr>
                        <th>部品名</th>
                        <th>{lang.WAREHOUSES_NAME}</th>
                        {enableUpdateMachine && chkAutoExport.checked && (
                          <th>{lang.STOCK_QUANTITY}</th>
                        )}
                        <th>数量</th>
                        {enableUpdateMachine && <th>{lang.DELETE}</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {renderListItem(row.LstBuhin || [], indexMachine)}
                    </tbody>
                  </table>
                );
              },
            }}
          />
        </GroupItem>
      )}
      {/* Comment */}
      <GroupItem center>
        <div className="mt15"></div>
        {txtTroubleInformation.show && (
          <>
            <RowInput name="注意事項・トラブル内容" required>
              <TextArea
                ref={txtTroubleInformationEl}
                className={txtTroubleInformation.error ? STYLE.BORDER_RED : ""}
                value={txtTroubleInformation.value}
                onChange={(v) => {
                  settxtTroubleInformation({
                    ...txtTroubleInformation,
                    value: v.target.value,
                  });
                }}
                rows={4}
              />
            </RowInput>
          </>
        )}

        <RowInput name="作業報告・改修方法" required>
          <TextArea
            ref={txtRenovationMethodEl}
            className={txtRenovationMethod.error ? STYLE.BORDER_RED : ""}
            value={txtRenovationMethod.value}
            onChange={(v) => {
              settxtRenovationMethod({
                ...txtRenovationMethod,
                value: v.target.value,
              });
            }}
            rows={4}
          />
        </RowInput>

        {Number(ddlReportType.value) === 1 && (
          <RowInput name={lang.EXPORT_INVENTORY}>
            <Checkbox
              className="mt7 nonSelect"
              checked={chkAutoExport.checked}
              disabled={modeUpdate}
              onChange={(e) => {
                setChkAutoExport({
                  ...chkAutoExport,
                  checked: e.target.checked,
                });
              }}
            >
              {lang.AUTO_EXPORT_INVENTORY}
            </Checkbox>
          </RowInput>
        )}

        {/* Button footer */}
        <Row justify="end" className="mt10 mb10">
          <Button
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              showAttachFileLst();
            }}
          >
            <PaperClipOutlined className="orange " /> {lang.ATTACH}
          </Button>
          <Button
            className="buttonPC button--info wAuto ml15"
            onClick={() => {
              confirmSaveData();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={cancelSaveData}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
      {/* Search history check */}
      {popupHistoryCL.show && (
        <Modal
          centered
          width={1300}
          onCancel={onCloseSearchHistory}
          className="modal-msg modal-screen"
          title={<div className="center">{lang.HISTORY_CHECKLIST}</div>}
          visible={true}
          footer={[
            <Button
              disabled={!dataSelected}
              key={0}
              className="buttonPC button--info ml10"
              onClick={() => {
                sethdnChecklistID({
                  ...hdnChecklistID,
                  value: dataSelected.id,
                  message: dataSelected.checklistName,
                });
                onCloseSearchHistory();
              }}
            >
              <PlusOutlined />
              {COMMON_CONSTANT.ADD}
            </Button>,
            <Button
              key={1}
              className="buttonPC button--outline --todo-- wAuto"
              onClick={onCloseSearchHistory}
            >
              <CloseCircleOutlined />
              {lang.CLOSE}
            </Button>,
          ]}
        >
          <ListHistoryCL
            screen={false}
            setDataSelected={setDataSelected}
            idSelect={idSelect}
            setIdSelect={setIdSelect}
          />
        </Modal>
      )}
      {/* attachFileModal */}
      <Modal
        centered
        width="50%"
        bodyStyle={{ height: "400px", overflow: "auto" }}
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.ATTACH}</div>}
        visible={popupAttach.show}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline --todo-- wAuto"
            onClick={() => {
              setPopupAttach({ ...popupAttach, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        <div className="mb15">
          新規挿入:
          <Button
            type="link"
            onClick={() => {
              fileEl.current.click();
            }}
          >
            <PaperClipOutlined className="iconM blue" />
          </Button>
          {/* <Button type="link">
            <CameraOutlined className="iconM blue" />
          </Button> */}
        </div>
        <input
          type="file"
          className="d-none"
          // accept=".xls,.xlsx"
          ref={fileEl}
          onChange={readAttachFileUrl}
        />
        <input
          type="file"
          multiple
          className="d-none"
          // accept=".xls,.xlsx"
          ref={fileElManagement}
        />
        <Table
          className="tbl-original"
          rowKey="ItemOrder"
          dataSource={tblAttach}
          columns={columnsAttach}
          pagination={false}
        />
      </Modal>
    </div>
  );
}

export default CreateWorkReport;
