import { Col, Row, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";
function LocationViewTree({
  listBuilding,
  listFloor,
  listRoom,
  facilityCode,
  facilityName,
}) {
  const renderLocationViewTree = () => {
    let result = null;
    if (listBuilding.length > 0) {
      result = listBuilding.map((buildingItem) => {
        const listFloorOfBuilding = listFloor.filter(
          (floorItem) => floorItem.buildingCode === buildingItem.buildingCode
        );
        return (
          <Row key={buildingItem.buildingCode}>
            <Col span="3">
              <div
                style={{
                  height: 80,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  marginTop: 12,
                }}
              >
                <Tooltip title={buildingItem.buildingName}>
                  <Paragraph ellipsis={{ rows: 3, expandable: false }}>
                    <span
                      onClick={() => handleClickNameTree(buildingItem)}
                      className="nameTree"
                    >
                      {buildingItem.buildingName}
                    </span>
                  </Paragraph>
                </Tooltip>
              </div>
            </Col>

            <Col span="3">
              {listFloorOfBuilding.map((floorItem, index, arr) => {
                let lengthRoom = 0;
                if (index !== 0) {
                  for (let i = 0; i < index; i++) {
                    const listRoomOfFloor = listRoom.filter(
                      (roomItem) => roomItem.floorCode === arr[i].floorCode
                    );
                    if (listRoomOfFloor.length > 0)
                      lengthRoom += listRoomOfFloor.length;
                    else lengthRoom += 1;
                  }
                }
                return (
                  <div
                    key={floorItem.floorCode}
                    style={{
                      width: "100%",
                      height: 80 * lengthRoom,
                      borderLeft: index != 0 && "2px solid #1890ff",
                      borderBottom: "2px solid #1890ff",
                      position: "absolute",
                      top: 20,
                      left: 10,
                    }}
                  ></div>
                );
              })}
            </Col>
            <Col span={15}>
              {listFloorOfBuilding.map((floorItem) => {
                const listRoomOfFloor = listRoom.filter(
                  (roomItem) => roomItem.floorCode === floorItem.floorCode
                );
                return (
                  <Row key={floorItem.floorCode}>
                    <Col span={4}>
                      <div
                        style={{
                          height: 80,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      >
                        <Tooltip title={floorItem.floorName}>
                          <Paragraph
                            style={{ marginTop: 10 }}
                            ellipsis={{ rows: 3, expandable: false }}
                          >
                            <span
                              onClick={() => handleClickNameTree(floorItem)}
                              className="nameTree"
                            >
                              {floorItem.floorName}
                            </span>
                          </Paragraph>
                        </Tooltip>
                      </div>
                    </Col>
                    <Col span={4}>
                      {listRoomOfFloor.map((item, index) => {
                        return (
                          <div
                            key={item.roomCode}
                            style={{
                              width: "100%",
                              height: 80 * index,
                              borderLeft: index != 0 && "2px solid #1890ff",
                              borderBottom: "2px solid #1890ff",
                              position: "absolute",
                              top: 20,
                              left: 0,
                            }}
                          ></div>
                        );
                      })}
                    </Col>
                    <Col span={5}>
                      {listRoomOfFloor.map((roomItem) => {
                        return (
                          <div
                            key={roomItem.roomCode}
                            style={{
                              height: 80,
                              marginLeft: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title={roomItem.roomName}>
                                <Paragraph
                                  style={{ marginTop: 10 }}
                                  ellipsis={{ rows: 3, expandable: false }}
                                >
                                  <span
                                    onClick={() =>
                                      handleClickNameTree(roomItem)
                                    }
                                    className="nameTree"
                                    style={{ marginTop: 10 }}
                                  >
                                    {roomItem.roomName}
                                  </span>
                                </Paragraph>
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        );
      });
    }
    return result;
  };
  const handleClickNameTree = (itemTree) => {
    const { buildingName, floorName, roomName } = itemTree;
    const buldingStr = buildingName ? `buildingName=${buildingName}&` : "";
    const floorStr = floorName ? `floorName=${floorName}&` : "";
    const roomStr = roomName ? `roomName=${roomName}` : "";
    const queryString = buldingStr + floorStr + roomStr;
    window.open(
      "/machineManagement" +
        "?" +
        `facilityName=${facilityName}` +
        "&" +
        queryString,
      "_blank"
    );
  };
  return <div>{renderLocationViewTree()}</div>;
}

export default LocationViewTree;
