import React from "react";
import { Button, Select, Table, Switch, Input, Checkbox } from "antd";
import {
  renderOptionForSearch,
  formatCheckItemCode,
} from "../../constants/utils";
import { TYPE_INPUT, STYLE } from "../../constants/common.const";
import { DeleteOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "@src/styles/icon/clone.svg";

const { Option } = Select;

function GridCheckListPreCheck({
  onChangeOrderRow,
  onSwitchTimeCheck,
  onCheckAllTime,
  onCheckTime,
  onCheckboxItem,
  columns,
  dataSource = [],
  MULTIPLE_TIME,
  listTimeCheck = [],
  onDeleteRow,
  onAddListTimeCheck,
  onRemoveListTimeCheck,
  onUpdateTimeCheck,
  accumulatedTypeList,
  defaultInspectionTiming,
  defaultTypeList,
  defaultJudgement,
  defaultInputMode,
  // On Change event
  onChangeCheckItemName,
  onChangeNormalLowerControlValue,
  onChangeNormalUpperControlValue,
  onChangeMode,
  onChangeUnit,
  onChangeChoices,
  onChangeInspectionTiming,
  onChangeItemJudgement,
  onChangeDefaultType,
  onChangeDefaultValue,
  onChangeAccumulatedType,
  onChangeAccumulatedGroupItemCodes,
  onChangeMultiplier,
  ddlTypeChecklist,
  ...other
}) {
  let showCopyBtn = false;
  dataSource.forEach((v) => {
    if (!showCopyBtn && (v.CheckItemCode || v.checkItemCode)) {
      showCopyBtn = true;
    }
  });
  // Select show
  const nodeBtnDelete = (row, indexRow) => {
    return (
      <div className="center">
        <Button
          className="btnDelete"
          style={{ height: 24, width: 35, marginBottom: 2 }}
          onClick={() => {
            onDeleteRow(indexRow);
          }}
        >
          <DeleteOutlined style={{ position: "absolute", top: 3, left: 10 }} />
        </Button>
      </div>
    );
  };

  // Copy
  const nodeBtnCopy = (row, order) => (
    <CopyToClipboard
      text={formatCheckItemCode(row.CheckItemCode || row.checkItemCode, order)}
    >
      <Button type="link" className="btnLink btnLink-active pr2 pl2 mb2">
        <CopyIcon className="w18px" />
      </Button>
    </CopyToClipboard>
  );

  // Order
  const nodeOrder = (row) => {
    // const v = row.Order;
    //const dataSourceCheckedList = dataSource.filter((x) => x.checked);
    const v = dataSource.findIndex((x) => x.ID == row.ID) + 1;
    const listO = dataSource.map((e, i) => {
      return (
        <Option value={i + 1} key={i + 1}>
          {i + 1}
        </Option>
      );
    });
    return (
      <div className="center">
        <Select
          size={"small"}
          className="selectOrder"
          value={v}
          onChange={(value) => {
            onChangeOrderRow(value, row, dataSource);
          }}
          //disabled={!row.checked}
        >
          {listO}
        </Select>
      </div>
    );
  };

  const renderNodeCheckTimeOld = listTimeCheck.map((v, i) => {
    return {
      title: <span className="fs12">{v}</span>,
      align: "center",
      width: i === 0 ? "60px" : "70px",
      render: (text, row) => {
        const obj = {
          props: {},
        };
        if (row.MultipleTime) {
          // Multiple time
          const rowN = { ...row };
          rowN.checkOrder = i + 1;
          obj.children = (
            <div className="d-flex content-center">
              <Checkbox
                checked={rowN.TimeArr[i]}
                onChange={(e) => {
                  onCheckTime(e.target.checked, i, rowN);
                }}
              ></Checkbox>
              {showCopyBtn && nodeBtnCopy(rowN, i + 1)}
            </div>
          );
        } else {
          // Only time
          if (i === 0) {
            obj.props.colSpan = listTimeCheck.length;
            obj.children = (
              <span className="content-center">
                {showCopyBtn && nodeBtnCopy(row)}
              </span>
            );
          } else {
            // Other
            obj.props.colSpan = 0;
          }
        }
        return obj;
      },
    };
  });

  const columnCopy = showCopyBtn
    ? [
        {
          title: "コピー",
          key: "copy",
          width: 80,
          align: "center",
          render: (text, row, indexRow) => {
            return <div className="d-inline-block">{nodeBtnCopy(row)}</div>;
          },
        },
      ]
    : [];

  const columnMultiple =
    Number(ddlTypeChecklist.value) === 2
      ? [
          {
            title: "複数回",
            width: 70,
            align: "center",
            render: (text, row, indexRow) => {
              return (
                <Switch
                  // checkedChildren="複数回"
                  // unCheckedChildren="1回"
                  size="small"
                  checked={row.MultipleTime}
                  onChange={(checked) => {
                    onSwitchTimeCheck(checked, row);
                  }}
                />
              );
            },
          },
          {
            title: "複数回の点",
            children: renderNodeCheckTimeOld,
          },
        ]
      : [...columnCopy];

  let columnTable = [
    {
      title: "",
      className: "mw-45",
      width: 45,
      render: (text, row, indexRow) => {
        return nodeBtnDelete(row, indexRow);
      },
    },
    {
      title: "順",
      width: 45,
      render: (text, row, indexRow) => {
        return nodeOrder(row);
      },
    },
    {
      title: (
        <>
          点検項目名
          <label className="blod ml5 mark-required">*</label>
        </>
      ),
      width: 150,
      render: (text, row, indexRow) => (
        <Input
          size={"small"}
          className={
            (row.CheckItemName === null ? STYLE.BORDER_RED : "") + " w100"
          }
          style={{ width: "100%", fontSize: "14px" }}
          value={row.CheckItemName}
          onChange={(e) => {
            onChangeCheckItemName(e.target.value, row);
          }}
        ></Input>
      ),
    },
    {
      title: (
        <span>
          運転めやす <br />
          (下限位)
        </span>
      ),
      width: 100,
      render: (text, row, indexRow) => (
        <Input
          type="number"
          size={"small"}
          style={{ width: "100%", fontSize: "14px", paddingRight: 0 }}
          value={row.NormalLowerControlValue}
          onChange={(e) => {
            onChangeNormalLowerControlValue(e.target.value, row);
          }}
        ></Input>
      ),
    },
    {
      title: (
        <span>
          運転めやす <br />
          (上限値)
        </span>
      ),
      width: 100,
      render: (text, row, indexRow) => (
        <Input
          type="number"
          size={"small"}
          style={{ width: "100%", fontSize: "14px", paddingRight: 0 }}
          value={row.NormalUpperControlValue}
          onChange={(e) => {
            onChangeNormalUpperControlValue(e.target.value, row);
          }}
        ></Input>
      ),
    },
    {
      title: (
        <>
          入力形式
          <label className="blod ml5 mark-required">*</label>
        </>
      ),
      width: 100,
      render: (text, row) => (
        <div className="text-center">
          <Select
            size={"small"}
            className={(row.Mode === false ? STYLE.BORDER_RED : "") + " w100"}
            style={{ width: "100%", fontSize: "14px" }}
            onChange={(v) => {
              onChangeMode(v, row);
            }}
            value={row.Mode}
          >
            {renderOptionForSearch(defaultInputMode.options)}
          </Select>
        </div>
      ),
    },

    {
      title: "单位",
      width: 50,
      render: (text, row, indexRow) => (
        <Input
          size={"small"}
          style={{ width: "100%", fontSize: "14px" }}
          disabled={
            ![TYPE_INPUT.TEXT, TYPE_INPUT.NUMBER].some(
              (mode) => mode === row.Mode
            )
          }
          value={row.Unit}
          onChange={(e) => {
            onChangeUnit(e.target.value, row);
          }}
        ></Input>
      ),
    },

    {
      title: "選択",
      width: 150,
      render: (text, row, indexRow) => (
        <Input
          size={"small"}
          style={{ width: "100%", fontSize: "14px" }}
          disabled={![TYPE_INPUT.SELECT].some((mode) => mode === row.Mode)}
          value={row.Choices}
          onChange={(e) => {
            onChangeChoices(e.target.value, row);
          }}
        ></Input>
      ),
    },

    {
      title: (
        <>
          点検頻度
          {/* <label className="blod ml5 mark-required">*</label> */}
        </>
      ),
      width: 100,
      render: (text, row, indexRow) => (
        <div className="text-center">
          <Select
            size={"small"}
            className={
              (row.InspectionTiming === false ? STYLE.BORDER_RED : "") + " w100"
            }
            style={{ width: "100px", fontSize: "14px" }}
            onChange={(v) => {
              onChangeInspectionTiming(v, row);
            }}
            disabled
            value={row.InspectionTiming}
          >
            {renderOptionForSearch(defaultInspectionTiming.options)}
          </Select>
        </div>
      ),
    },
    {
      title: "判断",
      width: 100,
      render: (text, row, indexRow) => (
        <div className="text-center">
          <Select
            size={"small"}
            style={{ width: "100%", fontSize: "14px" }}
            onChange={(v) => {
              onChangeItemJudgement(v, row);
            }}
            value={row.ItemJudgement}
          >
            {renderOptionForSearch(defaultJudgement.options)}
          </Select>
        </div>
      ),
    },
    {
      title: "初期タイプ",
      width: 100,
      render: (text, row, indexRow) => (
        <Select
          size={"small"}
          style={{ width: "100%", fontSize: "14px" }}
          value={row.DefaultType}
          onChange={(v) => {
            onChangeDefaultType(v, row);
          }}
        >
          {renderOptionForSearch(defaultTypeList.options)}
        </Select>
      ),
    },
    {
      title: "初期値",
      width: 80,
      render: (text, row, indexRow) => (
        <Input
          size={"small"}
          style={{ width: "100%", fontSize: "14px" }}
          value={row.DefaultValue}
          disabled={
            Number(row.DefaultType) === 1 || Number(row.DefaultType) === 2
          }
          onChange={(e) => {
            onChangeDefaultValue(e.target.value, row);
          }}
        ></Input>
      ),
    },
    // {
    //   title: "累計計算項目",
    //   width: 110,
    //   render: (text, row, indexRow) => (
    //     <Input
    //       size={"small"}
    //       style={{ fontSize: "14px" }}
    //       value={row.AccumulatedGroupItemCodes}
    //       disabled={Number(row.MultipleTime) || row.Mode !== TYPE_INPUT.NUMBER}
    //       onChange={(e) => {
    //         onChangeAccumulatedGroupItemCodes(e.target.value, row);
    //       }}
    //     ></Input>
    //   ),
    // },
    // {
    //   title: "入力値の分類",
    //   width: 130,
    //   align: "center",
    //   render: (text, row, indexRow) => {
    //     return (
    //       <Select
    //         size={"small"}
    //         style={{ width: "130px", fontSize: "12px" }}
    //         value={row.AccumulatedType}
    //         disabled={
    //           Number(row.MultipleTime) || row.Mode !== TYPE_INPUT.NUMBER
    //         }
    //         onChange={(v) => {
    //           onChangeAccumulatedType(v, row);
    //         }}
    //       >
    //         {renderOptionForSearch(accumulatedTypeList.options)}
    //       </Select>
    //     );
    //   },
    // },
    // // multiplier
    // {
    //   title: "乗数",
    //   width: 50,
    //   align: "center",
    //   render: (text, row, indexRow) => {
    //     return (
    //       <Input
    //         size={"small"}
    //         style={{ width: "50px", fontSize: "14px" }}
    //         value={row.Multiplier}
    //         disabled={
    //           Number(row.AccumulatedType) !== 4 ||
    //           row.Mode !== TYPE_INPUT.NUMBER
    //         }
    //         onChange={(e) => {
    //           onChangeMultiplier(e.target.value, row);
    //         }}
    //       ></Input>
    //     );
    //   },
    // },
    ...columnMultiple,
  ];
  return (
    <div className="precheck">
      <Table
        {...other}
        dataSource={dataSource}
        columns={columnTable}
        pagination={false}
      />
    </div>
  );
} // Usage

export default GridCheckListPreCheck;
