/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  Input,
  Button,
  Collapse,
  Tabs,
  Switch,
  Card,
  Tooltip,
} from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import BoxContent from "../../component/Desktop/content";
import FooterPage from "../../component/footer/footerPage";
import { useMediaQuery } from "react-responsive";
import Loading from "../../component/Desktop/loading/loading";
import TabsCustom from "@src/component/TabsComponent/TabsCustom"; // Tab
import { lang, COMMON_CONSTANT, KEY } from "../../constants/common.const";
import { ReactComponent as ChevronDown } from "@src/styles/icon/chevron-down.svg";
import { ReactComponent as ChevronUp } from "@src/styles/icon/chevron-up.svg";
import { statusRes, MSG_CATCH, showMessage } from "../../constants/utils";
import API from "../../api/backend/resetLastValue";
import KBN from "../../api/backend/kbn";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FillIcon from "@src/styles/icon/fillup.svg";
import classPopup from "../../models/control/popup";

import {
  CloseCircleOutlined,
  SaveOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import classMachine from "../../models/machineDes";
import classGroup from "../../models/groupMachine";
import classItem from "../../models/itemMachine_view";
import { checkRoleScreen } from "../../utils/roles";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
let objSttWorking = {};
let flgChangeData = false;

function EditResetLastValue() {
  // let idTabMachine;
  const history = useHistory();
  let { id } = useParams();
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [lblChecklistName, setLblChecklistName] = useState("");
  // Group Tab
  const [GROUP_LIST, setGROUP_LIST] = useState([]);

  const [showLoading, setShowLoading] = useState(false);

  // Popup
  const [popupLocationMachine, setPopupLocationMachine] = useState(
    new classPopup()
  );
  const [popupConfirm, setPopupConfirm] = useState(new classPopup());
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      window.scrollTo(0, 0);
      // Load info
      openLoadingSpinner();

      Promise.all([API.getCheckList(id), KBN.getWorkingStatus()])
        .then((res) => {
          // eslint-disable-next-line no-throw-literal
          if (!res[0].data && id) throw { status: 404 };
          showDetail(res[0]);
          // Get list kbn status working
          res[1].data &&
            res[1].data.data &&
            res[1].data.data.forEach((v) => {
              objSttWorking[v.key] = v.value;
            });
        })
        .catch((err) => {
          if (err.status === 404) {
            history.push("/ResetLastValue");
          } else showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Close loading
          closeLoadingSpinner();
        });
    }
  }, [id, allowRoute]);

  const transTextElipsis = (number) => {
    let text = String(number);
    if (text.length > 8) {
      return (
        <>
          {text.substring(0, 8)}
          {".."}
          <Tooltip placement="top" title={text}>
            <span className="clear-value-input pointer">{lang.SHOW}</span>
          </Tooltip>
        </>
      );
    } else {
      return text;
    }
  };
  const showDetail = (res) => {
    let data = res.data;
    // Title ---
    setLblChecklistName(data.checklistName);

    // Group ---
    let listGroup = [];
    // Mapping
    data.groups.forEach((grp) => {
      let group = new classGroup();
      group.ID = grp["groupId"];
      group.Name = grp["groupName"];
      group.Machines = [];

      // Machines
      grp["checklistMachines"].forEach((mc) => {
        let machine = new classMachine();
        machine = mc;
        machine.ID = mc["checklistMachineId"];
        machine.Name = mc["machineforSiteOfficeName"];
        machine.Remark = mc["note"];
        machine.MachineforSiteOfficeId = mc["machineforSiteOfficeCode"];

        mc["checklistItems"].forEach((item) => {
          item["defaultAccumulatedValue"] = item.accumulatedValue;
        });
        // Check error item
        machine.Items = mc["checklistItems"];
        group.Machines.push(machine);
      });

      // Default: show machine first
      group.machineShow =
        group.Machines.length > 0
          ? group.Machines.map((v) => {
              return v.ID;
            })
          : [];
      // idTabMachine = group.Machines[0].ID;

      // Push
      listGroup.push(group);
    });
    setGROUP_LIST(listGroup);
  };

  const onShowAllMachine = (checked, index) => {
    const lNew = JSON.parse(JSON.stringify(GROUP_LIST));
    const listID = [];

    if (checked) {
      // show all machine
      GROUP_LIST[index].Machines &&
        GROUP_LIST[index].Machines.forEach((m = new classMachine()) => {
          listID.push(m.ID);
        });
    }

    lNew[index].machineShow = listID;
    setGROUP_LIST(lNew);
  };

  const onShowMachine = (keys, index) => {
    const lNew = JSON.parse(JSON.stringify(GROUP_LIST));
    lNew[index].machineShow = keys;
    setGROUP_LIST(lNew);
  };

  const onShowOneMachine = (keys, index) => {
    //  Show all not active OR exist one machine
    if (
      GROUP_LIST[index].machineShow.length !==
        GROUP_LIST[index].Machines.length ||
      GROUP_LIST[index].Machines.length === 1
    ) {
      if (keys.length > 0) {
        keys = [keys[keys.length - 1]];
      }
      onShowMachine(keys, index);
    }
  };

  // Approve, Reject checklist
  const showMessageConfirm = () => {
    // Warning
    Modal.confirm({
      centered: true,
      title: lang.WARMING,
      content: COMMON_CONSTANT.BOM_C006,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        callServer();
      },
    });
  };

  // Check connect to server

  function callServer() {
    // Hide popup confirm
    Modal.destroyAll();
    setPopupConfirm({ ...popupConfirm, show: false });

    openLoadingSpinner();
    // Call API change

    API.save(
      id,
      GROUP_LIST.map((g = new classGroup()) => {
        g.groupId = g.ID;
        g["checklistMachines"] = g.Machines.map((m = new classMachine()) => {
          m.checklistMachineId = m.ID;
          m["checklistItems"] = m.Items;
          return m;
        });
        return g;
      })
    )
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, res.data.msg, () => {
            history.goBack();
          });
        } else {
          showMessage(KEY.ERROR, res.data.msg);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  }

  // ITEMS
  const renderItems = (lstItem, objIndex) => {
    return lstItem.map((item = new classItem(), indItem) => {
      let domInputValue = (
        <Input
          className="bg-disable inputView w100"
          maxLength={10}
          type="text"
          inputMode="numeric"
          value={item.accumulatedValue}
          allowClear
          onChange={(e) => {
            let v = e.target.value;
            // Validate number
            if ((v !== null && /^[0-9.+-]+$/s.exec(v)) || v === "") {
              onChangeV(e);
            }
          }}
        ></Input>
      );
      const onChangeV = (e) => {
        onChangeValue(e.target.value, {
          ...objIndex,
          item: indItem,
        });
      };

      // Render item
      return (
        <Col lg={8} md={12} xs={24} key={item.checkItemCode}>
          <Card
            type="inner"
            className="center"
            title={<b>{item.checkItemName}</b>}
          >
            <Row
              className="rowNotMargin nowrap"
              align="middle"
              justify="start"
              gutter={[16, 0]}
            >
              <Col span={8}>
                <b>{lang.ACCUMULATED_VALUE}</b>
              </Col>
              <Col span={16}>{domInputValue}</Col>
            </Row>
            {/* More */}
            <Row className="rowNotMargin nowrap" justify="end" gutter={[8, 0]}>
              <Col className="info-more pointer">
                {lang.CURRENT_VALUE}
                {lang.COLON}{" "}
                {item.defaultAccumulatedValue !== null
                  ? transTextElipsis(item.defaultAccumulatedValue)
                  : "-"}
              </Col>
              <Col
                className="clear-value-input pointer"
                onClick={() => {
                  onChangeValue(
                    item.defaultAccumulatedValue
                      ? item.defaultAccumulatedValue
                      : 0,
                    {
                      ...objIndex,
                      item: indItem,
                    }
                  );
                }}
              >
                <img
                  src={FillIcon}
                  alt="icon"
                  style={{ paddingRight: "8px" }}
                />
                {lang.REFER_VALUE_PREVIEW}
              </Col>
            </Row>
          </Card>
        </Col>
      );
    });
  };

  const onChangeValue = (value, objIndex) => {
    flgChangeData = true;
    const groupData = [...GROUP_LIST];
    groupData[objIndex.group].Machines[objIndex.machine].Items[
      objIndex.item
    ].accumulatedValue = value;
    setGROUP_LIST(groupData);
  };

  const onBackPage = () => {
    if (flgChangeData === false) {
      // No update
      history.goBack();
    } else {
      // Updated
      const wPopup = Modal.confirm({
        centered: true,
        title: lang.WARMING,
        content: COMMON_CONSTANT.BOM_C018,
        okText: lang.OK,
        cancelText: lang.CANCEL,
        onOk: () => {
          flgChangeData = false;
          history.goBack();
        },
        onCancel: () => {
          flgChangeData = true;
          wPopup.destroy();
        },
      });
    }
  };

  // Layout
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.SC_REST_LAST_VALUE} />
      <BoxContent>
        <h1 className="white-space">{lblChecklistName}</h1>
        {/* Table */}
        {GROUP_LIST.length > 0 && (
          <>
            <TabsCustom sticky={true} type="card">
              {GROUP_LIST.map((grp = new classGroup(), indGroup) => {
                return (
                  <Tabs.TabPane tab={grp.Name} key={grp.ID} closable={false}>
                    {/* EXPEND ALL */}
                    <Row justify="end" className="mb15">
                      <Col>
                        {lang.EXPEND_ALL}{" "}
                        <Switch
                          checked={
                            grp.machineShow.length === grp.Machines.length
                          }
                          onChange={(checked) => {
                            onShowAllMachine(checked, indGroup);
                          }}
                        />
                      </Col>
                    </Row>
                    {/* Machine */}
                    <Collapse
                      className="tabMachine"
                      expandIconPosition="right"
                      expandIcon={({ isActive }) =>
                        isActive ? <ChevronDown /> : <ChevronUp />
                      }
                      activeKey={grp.machineShow}
                      onChange={(keys, a, b) => {
                        onShowOneMachine(keys, indGroup);
                      }}
                    >
                      {grp.Machines.map(
                        (m = new classMachine(), indMachine) => {
                          const sttM = "info";
                          return (
                            <Panel
                              className={"titleMachine_" + sttM}
                              key={m.ID}
                              header={
                                <>
                                  <Row>
                                    <Col
                                      style={{
                                        width:
                                          `calc(100% - ` +
                                          (isPC ? "118px" : "52px") +
                                          `)`,
                                      }}
                                      className="bold"
                                    >
                                      {m.Name}
                                    </Col>
                                  </Row>
                                </>
                              }
                            >
                              {/* Item */}
                              <Row
                                style={{
                                  padding: "20px",
                                }}
                              >
                                {renderItems(m.Items, {
                                  group: indGroup,
                                  machine: indMachine,
                                })}
                              </Row>
                            </Panel>
                          );
                        }
                      )}
                    </Collapse>
                  </Tabs.TabPane>
                );
              })}
            </TabsCustom>
          </>
        )}
      </BoxContent>

      <FooterPage>
        {isPC ? (
          <Row gutter={[8, 0]} justify="space-between">
            <Col>
              <Button
                className="buttonPC button--info wAuto mr15"
                onClick={() => {
                  showMessageConfirm();
                }}
              >
                <SaveOutlined />
                {lang.SAVE}
              </Button>
            </Col>
            <Col>
              <Button
                className="buttonPC button--outline wAuto ml15"
                onClick={onBackPage}
              >
                <RollbackOutlined />
                {lang.BACK}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Row className="menuBottom-footer">
              <Col span={12}>
                <div
                  className="menuBottom-footer-btnCancel content-center"
                  onClick={onBackPage}
                >
                  {lang.BACK}
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="menuBottom-footer-btnConfirm content-center "
                  onClick={() => {
                    showMessageConfirm();
                  }}
                >
                  {/* <SaveOutlined className="svg-white" /> */}
                  {lang.SAVE}
                </div>
              </Col>
            </Row>
          </Row>
        )}
      </FooterPage>
      {/* Location Machine */}
      <Modal
        centered
        width="590px"
        closeIcon={<></>}
        className="modal-msg modal-padding"
        title={<div className="center">{lang.LOCATION_MACHINE}</div>}
        visible={popupLocationMachine.show}
        footer={[
          <Button
            key={1}
            className="buttonPC button--outline wAuto"
            onClick={() => {
              setPopupLocationMachine({ ...popupLocationMachine, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CLOSE}
          </Button>,
        ]}
      >
        {popupLocationMachine.body}
      </Modal>
    </div>
  );
}

export default EditResetLastValue;
