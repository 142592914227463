/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../inspection.scss";
import { Row, Col, Button, Tabs, Modal, Input, Empty, Radio } from "antd";
import ListAllCheckList from "@src/component/TabsComponent/ListAllCheckList";
import MessageInput from "@src/component/Desktop/messageInput";
import TabsCustom from "@src/component/TabsComponent/TabsCustom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  GlobalOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import API from "../../../api/backend/setting";
import { setTabMode, setTabUser, setTabCommon } from "@src/redux/checkList";
import {
  checkSpecialCharacterSingle,
  isAdminOffice,
  isGroupManager,
  isHeadOffice,
  showMessage,
  statusRes,
} from "../../../constants/utils";
import EditIcon from "@src/styles/icon/edit.svg";
import classPopup from "../../../models/control/popup";
import classText from "../../../models/control/text";
import {
  lang,
  COMMON_CONSTANT,
  KEY,
  STYLE,
} from "../../../constants/common.const";
import { MSG_CATCH } from "../../../constants/utils";
import GroupCLIcon from "@src/styles/icon/group-checklist.svg";
import AutoMail from "../../../component/Desktop/autoMail";
import Loading from "../../../component/Desktop/loading/loading";

function ChecklistFilter({ dataCheckList, isDesktopOrLaptop, dataGroups }) {
  let {
    isLoadingFilter,
    idSelectTabCommon,
    idSelectTabUser,
    isLoadingGroupCL,
    modeTab,
  } = useSelector((state) => state.checklist);
  // Loading
  let [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const dispatch = useDispatch();
  const [tabGroupsData, setTabGroupsData] = useState([]);
  const [tabGroupsCommon, updateTabGroupsCommon] = useState([]);
  const [tabGroupsUser, updateTabGroupsUser] = useState([]);
  const [popupAddGroup, setPopupAddGroup] = useState(new classPopup());
  const [popupAdminGroup, setPopupAdminGroup] = useState(new classPopup());
  const [newGroupName, setNewGroupName] = useState(new classText());
  const [txtAdminGroup, setTxtAdminGroup] = useState(new classText());
  useEffect(() => {
    if (txtAdminGroup.init) {
      closeLoadingSpinner();
    }
  }, [txtAdminGroup]);
  useEffect(() => {
    if (dataGroups && dataGroups.length > 0) {
      let arrayGroup = JSON.parse(JSON.stringify(dataGroups)) || [];
      arrayGroup.forEach((item, index) => {
        item["index"] = index;
      });
      setTabGroupsData([...arrayGroup]);
    } else {
      setTabGroupsData([]);
    }
  }, [dataGroups]);
  // Sync
  useEffect(() => {
    updateTabGroupsCommon(
      tabGroupsData.filter(
        (t) => t.publicType === KEY.GROUP_TYPE_COMMON || t.type
      )
    );
    updateTabGroupsUser(
      tabGroupsData.filter((t) => t.publicType === KEY.GROUP_TYPE_USER)
    );
  }, [tabGroupsData]);

  const activeTab = (tab, type) => {
    dispatch(
      type === KEY.GROUP_TYPE_COMMON
        ? setTabCommon(tab.toString())
        : setTabUser(tab.toString())
    );
  };
  // List checklist
  const renderContentGroup = (tab) => {
    return tab.checklists && tab.checklists.length > 0 ? (
      <ListAllCheckList
        isLoadingMore={isLoadingGroupCL}
        hasMore={tab.isLoadMore}
        dataList={tab.checklists}
        type={tab.type ? tab.type : "group"}
        groupId={tab.id}
      />
    ) : (
      emptyTab()
    );
  };
  // ----Start: ADD GROUP
  // Show popup ADD GROUP
  const onAdd = (type) => {
    setPopupAddGroup({ ...popupAddGroup, show: true, type: type });
  };
  // action ADD GROUP
  const addGroup = () => {
    if (validate()) {
      return;
    }
    const nameNewGR = newGroupName.value.trim();
    // Call add
    API.createGroupChecklist(nameNewGR, popupAddGroup.type)
      .then((res) => {
        if (statusRes(res)) {
          const key = res.data.id;
          const newPanes = [...tabGroupsData];
          newPanes.push({
            title: nameNewGR,
            // content: "Content of new Tab",
            id: key,
            publicType: popupAddGroup.type,
          });
          // Add new group to list
          setTabGroupsData(newPanes);
          // Active tab new group
          activeTab(key, popupAddGroup.type);
          // Hide popup
          closeAddGroup();
        } else {
          showMessage(KEY.ERROR, res.data.msg || MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  // Validate group name
  const validate = () => {
    let error = false;
    const nameNew = newGroupName.value;

    if (
      nameNew === null ||
      checkSpecialCharacterSingle(newGroupName) === false ||
      nameNew.trim().length === 0
    ) {
      error = true;
      setNewGroupName({ ...newGroupName, error: true, message: "" });
      return error;
    }

    (popupAddGroup.type === KEY.GROUP_TYPE_COMMON
      ? tabGroupsCommon
      : tabGroupsUser
    ).forEach((v) => {
      if (popupAddGroup.type === KEY.GROUP_TYPE_COMMON) {
        if (
          String(lang.ALL_CHECKLIST).toLowerCase() ===
            String(nameNew.trim()).toLowerCase() ||
          String(lang.PATROL_INSPECTION_CHECKLIST).toLowerCase() ===
            String(nameNew.trim()).toLowerCase() ||
          String(lang.PRE_USE_CHECKLIST).toLowerCase() ===
            String(nameNew.trim()).toLowerCase()
        ) {
          error = true;
          setNewGroupName({
            ...newGroupName,
            error: true,
            message: COMMON_CONSTANT.GROUP_NAME_EXISTS,
          });
          return error;
        }
      }

      if (
        String(v.title).toLowerCase() === String(nameNew.trim()).toLowerCase()
      ) {
        error = true;
        setNewGroupName({
          ...newGroupName,
          error: true,
          message: COMMON_CONSTANT.GROUP_NAME_EXISTS,
        });
        return error;
      }
    });

    return error;
  };
  // Hide popup ADD GROUP
  const closeAddGroup = () => {
    setNewGroupName({ ...newGroupName, value: "", error: false, message: "" });
    setPopupAddGroup({ ...popupAddGroup, show: false });
  };
  // ----End: ADD GROUP

  // ----Start: GROUP ADMIN
  const funcPopupAdminGroup = {
    getAdmin: () => {
      if (isHeadOffice() || isAdminOffice()) {
        // Get list admin group
        return API.getAdminGroup()
          .then((res) => {
            if (statusRes(res)) {
              // update state
              setTxtAdminGroup({
                ...txtAdminGroup,
                list: [], // clear html
                init: res.data.length === 0, // loading
                message: "", // clear message
                users: res.data.map((u) => {
                  return u.userName;
                }),
              });
            } else {
              showMessage(KEY.ERROR, res.data.msg || MSG_CATCH());
              closeLoadingSpinner();
            }
          })
          .catch((err) => {
            showMessage(KEY.ERROR, MSG_CATCH());
            closeLoadingSpinner();
          });
      }
    },
    open: function () {
      openLoadingSpinner();
      // Reload list admin
      this.getAdmin().then(() => {
        setPopupAdminGroup({ popupAdminGroup, show: true });
      });
    },
    hide: () => {
      setPopupAdminGroup({ popupAdminGroup, show: false });
    },
    save: () => {
      const LIST_USER = txtAdminGroup.list
        .filter((u) => {
          return !u.error;
        })
        .map((v) => v.value);
      API.saveAdminGroup(LIST_USER)
        .then((res) => {
          if (statusRes(res)) {
            funcPopupAdminGroup.hide();
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
          } else {
            showMessage(KEY.ERROR, res.data.msg || MSG_CATCH());
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Empty
        });
    },
  };
  const accessAssistant = (e) => {
    funcPopupAdminGroup.open();
  };
  // ----End: GROUP ADMIN

  // Tab Common
  const tabsCommon = () => {
    return (
      <>
        <TabsCustom
          onChange={(tab) => activeTab(tab, KEY.GROUP_TYPE_COMMON)}
          type={
            isHeadOffice() || isAdminOffice() || isGroupManager()
              ? "editable-card"
              : "card"
          }
          activeKey={idSelectTabCommon}
          onEdit={() => {
            onAdd(KEY.GROUP_TYPE_COMMON);
          }}
        >
          <Tabs.TabPane tab={lang.ALL_CHECKLIST} key={0} closable={false}>
            <ListAllCheckList
              isLoadingMore={isLoadingFilter}
              dataList={dataCheckList}
              type={"checklistFilter"}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={lang.PATROL_INSPECTION_CHECKLIST}
            key={1}
            closable={false}
          >
            <ListAllCheckList
              isLoadingMore={isLoadingFilter}
              dataList={dataCheckList.filter(
                (x) => x.isPreUseChecklist !== "1"
              )}
              type={"checklistFilter"}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={lang.PRE_USE_CHECKLIST} key={2} closable={false}>
            <ListAllCheckList
              isLoadingMore={isLoadingFilter}
              dataList={dataCheckList.filter(
                (x) => x.isPreUseChecklist === "1"
              )}
              type={"checklistFilter"}
            />
          </Tabs.TabPane>
          {tabGroupsCommon.map((pane, index) => (
            <Tabs.TabPane tab={pane.title} key={pane.id} closable={false}>
              {/* Group system can't edit */}
              {!pane.system &&
                (isHeadOffice() || isAdminOffice() || isGroupManager()) && (
                  <Row className="mb15">
                    <Col className={!isDesktopOrLaptop && "right w100"}>
                      <Link
                        to={"/checklist/group-checklist/" + pane.id}
                        className="linkButton"
                      >
                        <img
                          src={EditIcon}
                          alt=""
                          style={{ paddingRight: "8px" }}
                        />
                        <span>{lang.EDIT_GROUP}</span>
                      </Link>
                    </Col>
                  </Row>
                )}
              {/* Checklist */}
              {renderContentGroup(pane)}
            </Tabs.TabPane>
          ))}
        </TabsCustom>
      </>
    );
  };

  // Tab User
  const tabsUser = () => {
    return (
      <>
        <TabsCustom
          onChange={(tab) => activeTab(tab, KEY.GROUP_TYPE_USER)}
          type="editable-card"
          activeKey={idSelectTabUser}
          onEdit={() => {
            onAdd(KEY.GROUP_TYPE_USER);
          }}
        >
          {tabGroupsUser.map((pane, index) => (
            <Tabs.TabPane tab={pane.title} key={pane.id} closable={false}>
              {/* Group system can't edit */}
              {!pane.system && (
                <Row className="mb15">
                  <Col className={!isDesktopOrLaptop && "right w100"}>
                    <Link
                      to={"/checklist/group-checklist/" + pane.id}
                      className="linkButton"
                    >
                      <img
                        src={EditIcon}
                        alt=""
                        style={{ paddingRight: "8px" }}
                      />
                      <span>{lang.EDIT_GROUP}</span>
                    </Link>
                  </Col>
                </Row>
              )}
              {/* Checklist */}
              {renderContentGroup(pane)}
            </Tabs.TabPane>
          ))}
        </TabsCustom>
        {tabGroupsUser.length === 0 && <>{emptyTab()}</>}
      </>
    );
  };

  const emptyTab = () => {
    return (
      <div className="center mt20">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  };

  // DOM
  return (
    <div>
      <Row id="checklist" align="middle">
        <Col flex="auto" className="titleText">
          <Row className="rowNotMargin align-center" justify="space-between">
            <Col>
              <img src={GroupCLIcon} alt="icon" />
              {lang.LIST_GROUP_CHECKLIST}
            </Col>
            <Col>
              <Row className=" align-center" gutter={[8, 0]}>
                <Col>
                  {(isHeadOffice() || isAdminOffice()) && (
                    <Button
                      type="link"
                      onClick={accessAssistant}
                      className="bold pointer"
                    >
                      <SettingOutlined /> {lang.ADMIN_GROUP}
                    </Button>
                  )}
                </Col>
                <Col>
                  <Radio.Group
                    value={modeTab}
                    onChange={(e) => {
                      dispatch(setTabMode(e.target.value));
                    }}
                  >
                    <Radio.Button value={KEY.GROUP_TYPE_COMMON}>
                      <GlobalOutlined /> {lang.GROUP_COMMON}
                    </Radio.Button>
                    <Radio.Button value={KEY.GROUP_TYPE_USER}>
                      <UserOutlined /> {lang.GROUP_USER}
                    </Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          {/* TAB COMMON */}
          {modeTab === KEY.GROUP_TYPE_COMMON ? tabsCommon() : tabsUser()}
        </Col>
      </Row>

      {/* Admin Group */}
      {popupAdminGroup.show ? ( // Fix bug reload
        <Modal
          centered
          title={lang.ADMIN_GROUP}
          footer={[
            <Button
              key="oki"
              className="buttonPC button--info"
              onClick={funcPopupAdminGroup.save}
            >
              <SaveOutlined />
              {lang.OK}
            </Button>,
            <Button
              key="back"
              className="buttonPC button--back"
              onClick={funcPopupAdminGroup.hide}
            >
              <CloseCircleOutlined />
              {COMMON_CONSTANT.CLOSE}
            </Button>,
          ]}
          onCancel={funcPopupAdminGroup.hide}
          visible={popupAdminGroup.show}
        >
          <Row>
            <Col span={24}>
              {popupAdminGroup.show ? ( // Fix bug reload
                <AutoMail
                  typeSearch={"user"}
                  get={txtAdminGroup}
                  set={setTxtAdminGroup}
                  placeholder={lang.ADMIN_GROUP}
                ></AutoMail>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Modal>
      ) : (
        <></>
      )}
      {/* Create Group */}
      <Modal
        centered
        title={COMMON_CONSTANT.TITLE_CREATE_GROUP}
        footer={[
          <Button
            key="back"
            className="buttonPC button--outline --todo-- wAuto"
            onClick={closeAddGroup}
          >
            <CloseCircleOutlined />
            {COMMON_CONSTANT.CLOSE}
          </Button>,
        ]}
        onCancel={closeAddGroup}
        visible={popupAddGroup.show}
      >
        <Row>
          <Col sm={5} xs={24} className={isDesktopOrLaptop && "center"}>
            {COMMON_CONSTANT.GROUP_NAME}
          </Col>
          <Col sm={11} xs={16}>
            <Input
              maxLength={255}
              className={newGroupName.error ? STYLE.BORDER_RED : ""}
              value={newGroupName.value}
              onChange={(e) => {
                setNewGroupName({
                  ...newGroupName,
                  value: e.target.value,
                  error: false,
                });
              }}
            />
            <MessageInput data={newGroupName} />
          </Col>
          <Col span={4}>
            <Button className="buttonPC button--info ml10" onClick={addGroup}>
              <PlusOutlined />
              {COMMON_CONSTANT.ADD}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default ChecklistFilter;
