import React from "react";
import { Input, Row, Select, Button } from "antd";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import UploadImage from "../../component/uploadImage/UploadImage"
import { KEY, lang, STYLE } from "../../constants/common.const";
import BreakLine from "../../component/Desktop/breakLine";
import { isEmpty, renderOption } from "../../constants/utils";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";

import TitlePage from "../../component/Desktop/titlePage";

const { TextArea } = Input;

export default function UpdateMaster({
  loading,
  items = [],
  name,
  flagChange: getSetFlagChange,
  onSave,
  onBack,
}) {
  const [flagChange, setFlagChange] = getSetFlagChange;

  const validateSave = () => {
    let flgErr = false;
    items.reverse().forEach((item) => {
      if (item.type === KEY.IMAGE) return;
      // Skip item common: createDate, creator, updateDate,..
      if (item.set) {
        const error = item.required && isEmpty(item.get.value);
        // Border
        item.set((preV) => ({ ...preV, error: error }));
        // Focus item err
        if (error) {
          //
          flgErr = error;
          // focus
          item.get.ref.current.focus();
        }
      }
    });

    if (!flgErr) {
      onSave();
    }
  };

  return (
    <div className="des-content mt70">
      <Loading show={loading}></Loading>
      <TitlePage name={name} />
      <GroupItem center>
        <div className="title-page d-block center mt0 mb0">
          <h1 className="bold white-space">{name}</h1>
        </div>
        <div className="mt15"></div>
        {/* List item */}
        {items.map(
          (
            {
              get,
              set,
              name,
              disabled,
              type,
              onChange,
              required = false,
              render,
            },
            index
          ) => {
            let domInput;
            switch (type) {
              case KEY.TEXT:
                domInput = (
                  <Input
                    disabled={get.disabled}
                    ref={get.ref}
                    className={(get.error ? STYLE.BORDER_RED : "") + " w100"}
                    value={get.value}
                    maxLength={255}
                    onChange={(e) => {
                      setFlagChange(true);
                      (onChange && onChange(e)) ||
                        set({ ...get, value: e.target.value });
                    }}
                  />
                );
                break;
              case KEY.NUMBER:
                domInput = (
                  <Input
                    disabled={get.disabled}
                    type={"number"}
                    ref={get.ref}
                    className={(get.error ? STYLE.BORDER_RED : "") + " w100"}
                    value={get.value}
                    maxLength={255}
                    onChange={(e) => {
                      setFlagChange(true);
                      (onChange && onChange(e)) ||
                        set({ ...get, value: e.target.value });
                    }}
                  />
                );
                break;
              case KEY.DDL:
                domInput = (
                  <Select
                    disabled={get.disabled}
                    ref={get.ref}
                    value={get.value}
                    className={(get.error ? STYLE.BORDER_RED : "") + " w100"}
                    onChange={(v) => {
                      setFlagChange(true);
                      (onChange && onChange(v)) || set({ ...get, value: v });
                    }}
                  >
                    {renderOption(get.options)}
                  </Select>
                );
                break;
              case KEY.TEXTAREA:
                domInput = (
                  <TextArea
                    disabled={disabled}
                    ref={get.ref}
                    rows={4}
                    value={get.value}
                    onChange={(e) => {
                      setFlagChange(true);
                      set({ ...get, value: e.target.value });
                    }}
                  />
                );
                break;
              case KEY.IMAGE:
                domInput = (
                  <UploadImage imageUrl={get} handleUpload={set} />
                );
                break;
              default:
                break;
            }

            return (
              <div key={index}>
                <BreakLine />
                <RowInput name={name} box required={required}>
                  {render ? render() : domInput ? domInput : get.value}
                </RowInput>
              </div>
            );
          }
        )}
        <BreakLine />

        <Row justify="end" className="mt10">
          {
            <Button
              className="buttonPC button--info wAuto ml15"
              onClick={validateSave}
            >
              <SaveOutlined />
              {lang.SAVE}
            </Button>
          }
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15"
            onClick={onBack}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>
        </Row>
      </GroupItem>
    </div>
  );
}
