import {
  CloseCircleOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  InfoCircleFilled,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ApiKbn from "@src/api/backend/kbn";
import Api from "@src/api/backend/reportManagement";
import BoxContent from "@src/component/Desktop/content";
import ApiChart from "@src/api/backend/dynamicChart";

import {
  AutoComplete,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import { ArchiveTick } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import GroupReport from "../../component/Popup/popup-group-report";
import FooterPage from "../../component/footer/footerPage";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import { MSG_CATCH, renderOption, showMessage } from "../../constants/utils";
import "./index.scss";
import { useSelector } from "react-redux";
import rolesConst from "../../routers/roles/roles.const";
import { ChromePicker } from "react-color";
import { useMediaQuery } from "react-responsive";
import classDdl from "../../models/control/dropdownlist";
import BoxSearchMachine from "@src/component/Desktop/BoxSearchMachine";
import classPopup from "@src/models/control/popup";
import { isEmpty, isObject, isString } from "lodash";
import { roundToOneDecimal } from "@src/utils/helper";

const { Panel } = Collapse;

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function AddDynamicReport({ isEndUser }) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { id } = useParams();
  const history = useHistory();
  const { roles, allowRoute } = useSelector((state) => state.auth);
  const [formSearch] = Form.useForm();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [ddlPreUseChecklist, setddlPreUseChecklist] = useState({
    ...new classDdl(),
    options: [
      { key: "0", value: "巡視点検用" },
      { key: "1", value: "使用前点検表" },
    ],
  });
  const [dataSource, setDataSource] = useState([
    {
      key: Date.now(),
      machineForSiteOfficeName: "",
      checkItemName: "",
      normalLowerControlValue: "",
      normalUpperControlValue: "",
      min: null,
      max: null,
    },
  ]);

  const [optionTime, setOptionTime] = useState([]);
  const [optionGroup, setOptionGroup] = useState([]);
  const [visibleGroup, setVisibleGroup] = useState(false);
  const [isDirty, setDirtyFormTable] = useState(false);
  const [optionsItemCheck, setOptionItemCheck] = useState([]);
  const [optionMachine, setOptionMachine] = useState([]);
  const [optionChecklist, setOptionChecklist] = useState([]);
  // check units
  const isHadUnit = dataSource.find((item) => item.checkItemCode);
  const isLeader = [
    rolesConst.HEAD_OFFICE_ADMIN,
    rolesConst.OFFICE_ADMIN,
  ].includes(roles);
  const columnsDefaut = [
    {
      title: (
        <span>
          {lang.NAME_MACHINE}
          {/* <label className="blod mark-required">*</label> */}
        </span>
      ),
      dataIndex: "machineForSiteOfficeName",
      render: (value, row) => {
        return (
          <div className="wrap-row-dynamic">
            <ModalBoxMachine
              clicksaved={isDirty}
              setShowLoading={setShowLoading}
              value={row.machineForSiteOfficeName}
              onSubmit={(value) => {
                const selected = Object.values(value)[0];
                onChangeFilledInputColumnObject(row.key, selected);
              }}
              selectedMachine={row}
              disabled={row.checkItemCode || row.machineForSiteOfficeCode}
              className={!value && isDirty ? STYLE.BORDER_RED : ""}
              onChange={(e) => {
                onChangeFilledInputColumn(
                  row.key,
                  "machineForSiteOfficeName",
                  e.target.value
                );
              }}
            />
            <div className="wrap-col-delete" style={{ width: 30 }}>
              <div
                onClick={() =>
                  onChangeFilledInputColumnObject(row.key, {
                    checklistName: null,
                    checklistId: null,
                    checkItemCode: null,
                    checkItemId: null,
                    checkItemPreCheckId: null,
                    machineForSiteOfficeCode: null,
                    machineForSiteOfficeName: null,
                  })
                }
                className="buttonClearText"
              >
                <span className="buttonClearText-icon">x</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span>
          {lang.INSPECTION_TIME_NAME}
          <label className="blod mark-required">*</label>
        </span>
      ),
      dataIndex: "checkItemName",
      render: (value, row) => {
        return (
          <div className="wrap-row-dynamic">
            <div style={{ flex: 1 }}>
              <AutoComplete
                disabled={row.checkItemCode}
                style={{ width: "100%" }}
                options={optionsItemCheck}
                value={row.checkItemName}
                onSelect={handleOnselectCheckItem(row.key)}
                onChange={(v) =>
                  onChangeFilledInputColumn(row.key, "checkItemName", v)
                }
                onBlur={() => setOptionItemCheck([])}
                onFocus={() => {
                  handleSearchCheckItem(row.key, row.checkItemName);
                }}
              >
                <Input.Search
                  className={
                    !hadValueInput(row.checkItemName) && isDirty
                      ? STYLE.BORDER_RED
                      : ""
                  }
                  onSearch={() =>
                    handleSearchCheckItem(row.key, row.checkItemName)
                  }
                />
              </AutoComplete>
            </div>
            <div className="wrap-col-delete" style={{ width: 30 }}>
              <div
                onClick={() =>
                  onChangeFilledInputColumnObject(row.key, {
                    checklistName: null,
                    checklistId: null,
                    checkItemCode: null,
                    checkItemId: null,
                    checkItemPreCheckId: null,
                    checkItemName: null,
                  })
                }
                className="buttonClearText"
              >
                <span className="buttonClearText-icon">x</span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  const columnsPreCheck = [
    {
      title: (
        <span>
          {lang.CHECKLIST_NAME}
          {/* <label className="blod mark-required">*</label> */}
        </span>
      ),
      dataIndex: "checklistName",
      render: (value, row) => {
        return (
          <div className="wrap-row-dynamic">
            <div style={{ flex: 1 }}>
              <AutoComplete
                style={{ width: "100%" }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: "auto", maxWidth: 400 }}
                disabled={row.checkItemCode || row.checklistId}
                options={optionChecklist}
                className={!value && isDirty ? STYLE.BORDER_RED : ""}
                value={row.checklistName}
                onChange={(v) =>
                  onChangeFilledInputColumn(row.key, "checklistName", v)
                }
                onSelect={handleSelectChecklist(row.key)}
              >
                <Input.Search
                  className={!value && isDirty ? STYLE.BORDER_RED : ""}
                  onSearch={() =>
                    handleSearchCheckListPreCheck(row.checklistName)
                  }
                />
              </AutoComplete>
            </div>
            <div className="wrap-col-delete" style={{ width: 30 }}>
              <div
                onClick={() =>
                  onChangeFilledInputColumnObject(row.key, {
                    checkItemCode: null,
                    checkItemId: null,
                    checklistName: null,
                    checklistId: null,
                    checkItemPreCheckId: null,
                    machineForSiteOfficeCode: null,
                    machineForSiteOfficeName: null,
                  })
                }
                className="buttonClearText"
              >
                <span className="buttonClearText-icon">x</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span>
          {lang.INSPECTION_TIME_NAME}
          <label className="blod mark-required">*</label>
        </span>
      ),
      dataIndex: "checkItemName",
      render: (value, row) => {
        return (
          <div className="wrap-row-dynamic">
            <div style={{ flex: 1 }}>
              <AutoComplete
                disabled={row.checkItemCode}
                style={{ width: "100%" }}
                options={optionsItemCheck}
                className={!value && isDirty ? STYLE.BORDER_RED : ""}
                value={row.checkItemName}
                onSelect={handleOnselectCheckItem(row.key)}
                onChange={(v) =>
                  onChangeFilledInputColumn(row.key, "checkItemName", v)
                }
                onBlur={() => setOptionItemCheck([])}
                onFocus={() => {
                  handleSearchCheckItemPreCheck(row.key, row.checkItemName);
                }}
              >
                <Input.Search
                  className={!value && isDirty ? STYLE.BORDER_RED : ""}
                  onSearch={() =>
                    handleSearchCheckItemPreCheck(row.key, row.checkItemName)
                  }
                />
              </AutoComplete>
            </div>
            <div className="wrap-col-delete" style={{ width: 30 }}>
              <div
                onClick={() =>
                  onChangeFilledInputColumnObject(row.key, {
                    checkItemCode: null,
                    checkItemId: null,
                    checkItemPreCheckId: null,
                    checkItemName: null,
                  })
                }
                className="buttonClearText"
              >
                <span className="buttonClearText-icon">x</span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  const columnLast = [
    {
      title: lang.UNIT,
      dataIndex: "unit",
      width: "7%",
      textAlign: "center",
      render: (value, row) => {
        const isDisabled = row.checkItemCode;
        return (
          <div className="wrap-row-dynamic">
            <div style={{ flex: 1 }}>
              <Input
                type="text"
                style={{ width: "100%" }}
                disabled={isDisabled}
                value={row.unit}
                onChange={(e) =>
                  onChangeFilledInputColumn(row.key, "unit", e.target.value)
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <p>
          運転めやす <br />
          (下限値)
        </p>
      ),
      dataIndex: "lowerValue",
      width: "7%",
      render: (value, row) => {
        const isDisabled = row.checkItemCode;
        return (
          <div className="wrap-row-dynamic">
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                disabled={isDisabled}
                style={{ width: "100%" }}
                value={row.normalLowerControlValue}
                onChange={(e) =>
                  onChangeFilledInputColumn(
                    row.key,
                    "normalLowerControlValue",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <p>
          運転めやす
          <br />
          (上限値)
        </p>
      ),
      dataIndex: "upperValue",
      width: "7%",
      render: (value, row) => {
        return (
          <div className="wrap-row-dynamic">
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                disabled={row.checkItemCode}
                style={{ width: "100%" }}
                value={row.normalUpperControlValue}
                onChange={(e) =>
                  onChangeFilledInputColumn(
                    row.key,
                    "normalUpperControlValue",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "最小",
      dataIndex: "min",
      width: "7%",
      textAlign: "center",
      render: (value, row) => {
        return (
          <Input
            type="number"
            style={{ width: "100%" }}
            value={roundToOneDecimal(
              value === null &&
                row.normalLowerControlValue &&
                row.normalUpperControlValue
                ? row.normalLowerControlValue -
                    (row.normalUpperControlValue - row.normalLowerControlValue)
                : value
            )}
            onChange={(e) => {
              onChangeFilledInputColumn(row.key, "min", e.target.value);
            }}
          />
        );
      },
    },
    {
      title: "最大",
      dataIndex: "max",
      width: "7%",
      textAlign: "center",
      render: (value, row) => {
        return (
          <Input
            type="number"
            style={{ width: "100%" }}
            value={roundToOneDecimal(
              value === null &&
                row.normalLowerControlValue &&
                row.normalUpperControlValue
                ? +row.normalUpperControlValue +
                    (+row.normalUpperControlValue -
                      +row.normalLowerControlValue)
                : value
            )}
            onChange={(e) =>
              onChangeFilledInputColumn(row.key, "max", e.target.value)
            }
          />
        );
      },
    },
    {
      title: "色",
      dataIndex: "color",
      width: "60px",
      textAlign: "center",
      render: (value, row) => {
        if (!value) {
          onChangeFilledInputColumn(row.key, "color", getRandomColor());
        }
        return (
          <ColorPicker
            color={value}
            onChange={(color) => {
              onChangeFilledInputColumn(row.key, "color", color);
            }}
          />
        );
      },
    },
    {
      title: lang.ACTION,
      dataIndex: "action",
      width: "200px",
      render: (value, row) => {
        const haveFile = row.importFileName;
        return (
          <Row
            style={{ marginTop: haveFile ? 35 : 0 }}
            gutter={[8, 0]}
            justify="center"
          >
            <Col span={11}>
              <Button
                onClick={() => {
                  document.getElementById(row.key + "").click();
                }}
                disabled={loadingUpload}
                style={{
                  background: "#f7f9fc",
                  width: "100%",
                  color: "#0080cb",
                }}
                className="pr10 pl10 mt4 mb4 ml10"
                icon={
                  <CloudUploadOutlined
                    style={{
                      color: "#f50",
                    }}
                  />
                }
              >
                {lang.RECEIPT}
              </Button>
            </Col>
            <Col span={11}>
              <a
                download={row.importFileName}
                href={row.importFileUrl || null}
                className="ml4"
              >
                <Button
                  onClick={() => downLoadTemPlate(row.importFileUrl)}
                  // disabled={!row.importFileUrl}
                  style={{
                    background: "#f7f9fc",
                    width: "100%",
                    color: "#0080cb",
                  }}
                  className="pr10 pl10 mt4 mb4 ml10"
                  icon={
                    <CloudDownloadOutlined
                      style={{
                        color: "#f50",
                      }}
                    />
                  }
                >
                  出力
                </Button>
              </a>
            </Col>

            <input
              className="d-none"
              id={row.key + ""}
              type="file"
              onChange={readAttachFileUrl(row.key)}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            {haveFile && (
              <Col span={24}>
                <Row className="mt5" align="middle" justify="space-evenly">
                  <Col span={20}>
                    <Tooltip title={row.importFileName} className="ml20">
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                          color: "#0080cb",
                          fontSize: 14,
                        }}
                      >
                        {row.importFileName}
                      </Typography>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                    <Button
                      style={{ background: "#f7f9fc", marginLeft: 3 }}
                      onClick={() =>
                        onChangeFilledInputColumnObject(row.key, {
                          importFileName: null,
                          importFileUrl: null,
                        })
                      }
                      icon={<DeleteOutlined />}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const columnsFirst = [
    {
      title: " ",
      dataIndex: "delete",
      width: "60px",
      render: (_, row) => {
        return (
          <Button
            disabled={dataSource.length === 1}
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRow(row.key)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    {
      title: " ",
      dataIndex: "order",
      width: "60px",
      render: (_, row) => nodeOrder(row),
    },
  ];

  const columnMultiple =
    Number(formSearch.getFieldValue("checklistType")) !== 1
      ? [...columnsDefaut]
      : [...columnsPreCheck];

  const columns = [...columnsFirst, ...columnMultiple, ...columnLast];

  useEffect(() => {
    ApiKbn.getTiming().then((res) => {
      setOptionTime(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (isEndUser) {
      setOptionGroup([
        {
          key: "02",
          name: "個人",
        },
      ]);
    } else {
      Api.getGroupReportKbn({ homePage: true }).then((res) => {
        const dataOption = res.data.data;
        if (roles === rolesConst.OFFICE_USER) {
          dataOption.shift();
        }
        setOptionGroup(
          dataOption.map((item) => ({ ...item, key: item.key + "" }))
        );
      });
    }
  }, [visibleGroup, isEndUser]);

  useEffect(() => {
    if (id) {
      getDetailData();
    } else {
      formSearch.setFieldsValue({
        checklistType: "0",
      });
    }
  }, [id, allowRoute]);

  // Init data
  const getDetailData = async () => {
    if (id) {
      try {
        setShowLoading(true);
        const resDetail = await Api.getDetailReport(id);

        if (resDetail.data) {
          const { details, ...othersData } = resDetail.data;
          formSearch.setFieldsValue(othersData);
          setDataSource(
            details.map((item, index) => ({ ...item, key: index }))
          );
        }
      } catch (error) {
        console.log(error.response);
        if (error.response && error.response.status === 404) {
          history.push("/DynamicReportManagement");
        } else showMessage(KEY.ERROR, MSG_CATCH());
      } finally {
        setShowLoading(false);
      }
    }
  };

  // Select Item
  const handleOnselectCheckItem = (key) => (value) => {
    const valueObj = JSON.parse(value);

    const newUnit = valueObj.unit;
    const newType = valueObj.inspectionTiming;
    const currentUnit = formSearch.getFieldValue("unit");
    const currentType = formSearch.getFieldValue("type");
    const checklistType = formSearch.getFieldValue("checklistType");

    let flagErrUnit = false;
    // unit rong nhung la cua item co san
    if (isHadUnit) {
      if (
        (!newUnit && currentUnit) ||
        (newUnit && !currentUnit) ||
        (!newType && currentType) ||
        (newType && !currentType)
      )
        flagErrUnit = true;
      if (typeof newUnit === "string" && typeof currentUnit === "string") {
        if (newUnit.toLowerCase() !== currentUnit.toLowerCase())
          flagErrUnit = true;
      }
      if (typeof newType === "string" && typeof currentType === "string") {
        if (newType.toLowerCase() !== currentType.toLowerCase())
          flagErrUnit = true;
      }
    } else if (!isHadUnit) {
      // Khi do unit rong la chua nhap,van them duoc
      if (currentUnit) {
        if (typeof newUnit === "string" && typeof currentUnit === "string") {
          if (newUnit.toLowerCase() !== currentUnit.toLowerCase())
            flagErrUnit = true;
        }
      }
      if (currentType) {
        if (newType.toLowerCase() !== currentType.toLowerCase())
          flagErrUnit = true;
      }
    } else {
      // Empty
    }
    // if (flagErrUnit) {
    //   const newData = {
    //     checkItemName: null,
    //     checkItemCode: null,
    //     checkItemId: null,
    //   };
    //   onChangeFilledInputColumnObject(key, newData);
    //   showMessage(
    //     KEY.ERROR,
    //     "動的レポートの各項目に同じ単位とテスト頻度を設定します"
    //   );
    //   return;
    // }
    if (!isHadUnit) {
      formSearch.setFieldsValue({
        unit: valueObj.unit,
        type: valueObj.inspectionTiming,
      });
    }
    const newData = {
      checkItemName: valueObj.checkItemName,
      checkItemCode: valueObj.checkItemCode,
      checkItemId: Number(checklistType) !== 1 ? valueObj.id : null,
      checkItemPreCheckId: Number(checklistType) === 1 ? valueObj.id : null,
      checklistName: valueObj.checklistName,
      normalLowerControlValue: valueObj.normalLowerControlValue,
      normalUpperControlValue: valueObj.normalUpperControlValue,
      min: null,
      max: null,
      unit: valueObj.unit,
      dynamicReportId: valueObj.dynamicReportId,
      machineForSiteOfficeName: valueObj.machineForSiteOfficeName,
    };
    onChangeFilledInputColumnObject(key, newData);
    setOptionItemCheck([]);
  };

  //Select Machine
  const handleSelectMachine = (key) => (value) => {
    const valueObj = JSON.parse(value);
    onChangeFilledInputColumnObject(key, valueObj);
    setOptionMachine([]);
  };

  const handleSelectChecklist = (key) => (value) => {
    const valueObj = JSON.parse(value);
    onChangeFilledInputColumnObject(key, valueObj);
    setOptionChecklist([]);
  };

  const onChangeFilledInputColumnObject = (key, object) => {
    if (isDirty) {
      setDirtyFormTable(false);
    }
    const index = dataSource.findIndex((item) => item.key === key);
    const newDataSource = [
      ...dataSource.slice(0, index),
      { ...dataSource[index], ...object },
      ...dataSource.slice(index + 1),
    ];
    setDataSource(newDataSource);
  };

  const onChangeFilledInputColumn = (key, nameColumn, newValue) => {
    if (isDirty) {
      setDirtyFormTable(false);
    }
    const index = dataSource.findIndex((item) => item.key === key);
    const newObject = {
      [nameColumn]: newValue,
    };
    const newDataSource = [
      ...dataSource.slice(0, index),
      { ...dataSource[index], ...newObject },
      ...dataSource.slice(index + 1),
    ];
    setDataSource(newDataSource);
  };

  // Search Item
  const handleSearchCheckItem = async (key, value) => {
    const index = dataSource.findIndex((item) => item.key === key);
    if (index >= 0) {
      const paramsSearch = { name: value, mode: "10000", limit: 100 };
      if (dataSource[index].machineForSiteOfficeCode) {
        paramsSearch.machineCode = dataSource[index].machineForSiteOfficeCode;
      }
      Api.getCheckItem(paramsSearch).then((res) => {
        const listCheckItemCode = dataSource.map((item) => item.checkItemCode);
        setOptionItemCheck(
          res.data
            .filter(
              (checkItem) =>
                !listCheckItemCode.includes(checkItem.checkItemCode)
            )
            .map((item) => ({
              value: JSON.stringify(item),
              label: item.checkItemName,
            }))
        );
      });
    }
  };

  // Search Item Pre check
  const handleSearchCheckItemPreCheck = async (key, value) => {
    const index = dataSource.findIndex((item) => item.key === key);
    if (index >= 0) {
      const paramsSearch = { name: value, mode: "10000", limit: 100 };
      if (dataSource[index].checklistName) {
        paramsSearch.checklistName = dataSource[index].checklistName;
      }
      Api.getCheckItemPreCheck(paramsSearch).then((res) => {
        const listCheckItemCode = dataSource.map((item) => item.checkItemCode);
        setOptionItemCheck(
          res.data
            .filter(
              (checkItem) =>
                !listCheckItemCode.includes(checkItem.checkItemCode)
            )
            .map((item) => ({
              value: JSON.stringify(item),
              label: item.checkItemName,
            }))
        );
      });
    }
  };
  // Search Machine
  const handleSearchMachine = (value) => {
    Api.getMachine({ machineName: value, limit: 100 }).then((res) => {
      if (res.data) {
        setOptionMachine(
          res.data.data.map((item) => ({
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  overlayStyle={{ zIndex: 100000000000 }}
                  title={item.machineForSiteOfficeName}
                >
                  <Text ellipsis style={{ maxWidth: "50%" }}>
                    {item.machineForSiteOfficeName}
                  </Text>
                </Tooltip>
                <Text ellipsis style={{ maxWidth: "50%" }}>
                  {item.machineForSiteOfficeCode}
                </Text>
              </div>
            ),
            value: JSON.stringify({
              machineForSiteOfficeName: item.machineForSiteOfficeName,
              machineForSiteOfficeCode: item.machineForSiteOfficeCode,
            }),
          }))
        );
      }
    });
  };

  const handleSearchCheckListPreCheck = (value) => {
    Api.getChecklistPreCheck({
      checklistName: value,
      isPreUseChecklist: 1,
      limit: 100,
    }).then((res) => {
      if (res.data) {
        setOptionChecklist(
          res.data.data.map((item) => ({
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  overlayStyle={{ zIndex: 100000000000 }}
                  title={item.checklistName}
                >
                  <Text ellipsis>{item.checklistName}</Text>
                </Tooltip>
              </div>
            ),
            value: JSON.stringify({
              checklistId: item.id,
              checklistName: item.checklistName,
            }),
          }))
        );
      }
    });
  };
  const handleDeleteRow = (key) => {
    const newDataSource = dataSource.filter((item) => {
      return item.key !== key;
    });
    setDataSource(newDataSource);
  };
  const nodeOrder = (row) => {
    const dataSourceCheckedList = [...dataSource];
    const v = dataSourceCheckedList.findIndex((x) => x.key === row.key) + 1;
    const listOptions = dataSourceCheckedList.map((e, i) => {
      return (
        <Select.Option value={i + 1} key={i + 1}>
          {i + 1}
        </Select.Option>
      );
    });
    return (
      <div className="center">
        <Select
          size={"small"}
          className="selectOrder"
          value={v}
          onChange={(value) => {
            onChangeOrderRow(value, row);
          }}
        >
          {listOptions}
        </Select>
      </div>
    );
  };
  const onChangeOrderRow = (value, row) => {
    const data = [...dataSource];
    const itemIndex = data.findIndex((item) => item.key === row.key);
    // remove item from data source
    const removedItem = data.splice(itemIndex, 1)[0];
    // insert item into new array source
    data.splice(value - 1, 0, removedItem);
    setDataSource(data);
  };
  const readAttachFileUrl = (key) => async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const fileRes = await uploadFile(file);
    if (fileRes) {
      onChangeFilledInputColumnObject(key, {
        importFileName: file.name,
        importFileUrl: fileRes.url,
      });
      document.getElementById(key + "").value = null;
    }
  };
  const uploadFile = async (file, type = 3) => {
    const formData = new FormData();
    const body = {
      attachFileName: file.name,
      type,
    };
    formData.append("data", JSON.stringify(body));
    formData.append("file", file);

    try {
      setLoadingUpload(true);
      const res = await Api.uploadFileReport(formData);
      return res.data;
    } catch (err) {
      let msg;
      if (err.response && err.response.status === 400) {
        msg =
          "入力ファイルのフォーマットが間違っているので、確認してください。";
      }
      showMessage(KEY.ERROR, msg || MSG_CATCH());
    } finally {
      setLoadingUpload(false);
    }
  };
  const handleAddRow = () => {
    const key = Date.now();
    const newRow = {
      key,
      machineForSiteOfficeName: "",
      checkItemName: "",
      normalLowerControlValue: "",
      normalUpperControlValue: "",
      min: null,
      max: null,
    };
    setDataSource([...dataSource, newRow]);
  };

  // DownLoad Template
  const downLoadTemPlate = async (url) => {
    let urlDownlaod;
    if (!url) {
      const res = await Api.downLoadTemPlate();
      urlDownlaod = res.data;
    }
    const aElemet = document.createElement("a");
    aElemet.href = url || urlDownlaod;
    aElemet.download = "TemplateImportDynamicReport";
    aElemet.click();
  };
  const onSaveData = async () => {
    // OK
    const data = formSearch.getFieldsValue();
    const details = dataSource.map((item, index) => ({
      id: item.id,
      order: index + 1,
      color: item.color,
      machineForSiteOfficeName: item.machineForSiteOfficeName,
      checkItemName: item.checkItemName,
      checkItemId: item.checkItemId || null,
      checkItemPreCheckId: item.checkItemPreCheckId || null,
      checklistName: item.checklistName || null,
      checkItemCode: item.checkItemCode || null,
      normalLowerControlValue: item.normalLowerControlValue || null,
      normalUpperControlValue: item.normalUpperControlValue || null,
      min: roundToOneDecimal(
        item.min === null &&
          item.normalLowerControlValue &&
          item.normalUpperControlValue
          ? +item.normalLowerControlValue -
              (+item.normalUpperControlValue - +item.normalLowerControlValue)
          : item.min
      ),
      max: roundToOneDecimal(
        item.max === null &&
          item.normalLowerControlValue &&
          item.normalUpperControlValue
          ? +item.normalUpperControlValue +
              (+item.normalUpperControlValue - +item.normalLowerControlValue)
          : item.max
      ),
      unit: item.unit || null,
      importFileUrl: item.importFileUrl || null,
      importFileName: item.importFileName || null,
      dynamicReportId: item.dynamicReportId || null,
      checklistType: item.checklistType || null,
    }));

    const postData = { ...data, details };
    try {
      setShowLoading(true);
      if (!id) {
        const listAllReport = await ApiChart.countDataDynamicChart();
        const length = listAllReport.data.length;
        const order = length > 0 ? listAllReport.data[length - 1].order + 1 : 1;
        postData.order = order;
      }

      !id
        ? await Api.createReport(postData)
        : await Api.updateReport(id, postData);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
        history.goBack();
      });
    } catch (error) {
      let msg = MSG_CATCH();
      if (
        !isEmpty(error.response) &&
        isString(error.response.data) &&
        !isEmpty(error.response.data)
      ) {
        msg = error.response.data;
      }
      if (
        !isEmpty(error.response) &&
        isObject(error.response.data) &&
        isString(error.response.data.msg)
      ) {
        msg = error.response.data.msg;
      }
      showMessage(KEY.ERROR, msg);
    } finally {
      setShowLoading(false);
    }
  };
  const checkValidate = () => {
    setDirtyFormTable(true);
    formSearch.validateFields(["name", "displayType"]).then((data) => {
      if (!data.errorFields) {
        const passValidate = checkValidateTable();
        if (passValidate) {
          showConfirmMessage(COMMON_CONSTANT.BOM_C008, onSaveData, function () {
            // Empty
          });
        }
      }
    });
  };
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    const wPopup = Modal[typeInfo ? "warning" : "confirm"]({
      centered: true,
      title: typeInfo ? lang.WARMING : lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: () => {
        callbackCancel && callbackCancel();
        wPopup.destroy();
      },
      onOk: () => {
        callbackOK && callbackOK();
        wPopup.destroy();
      },
    });
  };

  const checkValidateTable = () => {
    let flagErr = false;
    for (let i = 0; i < dataSource.length; i++) {
      if (Number(formSearch.getFieldValue("checklistType")) === 1) {
        if (!hadValueInput(dataSource[i].checkItemName)) {
          flagErr = true;
          break;
        }
      } else {
        if (!hadValueInput(dataSource[i].checkItemName)) {
          flagErr = true;
          break;
        }
      }
    }
    return !flagErr;
  };
  const openPopupGroup = () => {
    setVisibleGroup(true);
  };
  const renderGroupReport = () => (
    <GroupReport
      visible={visibleGroup}
      updateVisible={(val) => setVisibleGroup(val)}
    ></GroupReport>
  );

  const onChangePreUseChecklist = (value) => {
    setDataSource([
      {
        key: Date.now(),
        machineForSiteOfficeName: "",
        checkItemName: "",
        checklistName: "",
        normalLowerControlValue: "",
        normalUpperControlValue: "",
        min: null,
        max: null,
      },
    ]);
    setddlPreUseChecklist({ ...ddlPreUseChecklist, value: value });
  };

  function hadValueInput(value) {
    return value && value.trim();
  }
  return (
    <div className="des-content dynamic-chart-content">
      {renderGroupReport()}
      <Loading show={showLoading}></Loading>
      <TitlePage name={id ? lang.UPDATE_REPORT : lang.CREATE_REPORT} />
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <BoxContent className="p0">
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <InfoCircleFilled className="first-icon" /> 報告情報
                  </>
                }
                key="1"
              >
                <Form
                  className="formSearchTemp"
                  name="templateSearch"
                  form={formSearch}
                >
                  <Row
                    className="rowNotMargin"
                    align="bottom"
                    gutter={[12, 12]}
                    justify="start"
                  >
                    <Col span={16}>
                      <Row className="rowNotMargin" align="middle">
                        <Col span={6} className="title-form-item">
                          {lang.NAME_REPORT}
                          <label className="blod mark-required">*</label>
                        </Col>
                        <Col span={18}>
                          <Form.Item
                            name="name"
                            className="rowNotMargin"
                            rules={[
                              {
                                required: true,
                                message: `${lang.NAME_REPORT}を入力してください`,
                                validator: (_, value, callback) =>
                                  value && value.trim() !== ""
                                    ? callback()
                                    : callback(
                                        `${lang.NAME_REPORT}を入力してください`
                                      ),
                              },
                            ]}
                          >
                            <Input
                              maxLength="255"
                              placeholder={lang.NAME_REPORT}
                            ></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={16}>
                      <Row className="rowNotMargin" align="middle">
                        <Col span={6} className="title-form-item">
                          {lang.GROUP}
                          <label className="blod mark-required">*</label>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name="displayType"
                            className="rowNotMargin"
                            rules={[
                              {
                                required: true,
                                message: `${lang.GROUP}を入力してください`,
                              },
                            ]}
                          >
                            <Select maxLength="255" placeholder={lang.GROUP}>
                              {renderOption(optionGroup, false)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8} style={{ paddingLeft: "16px" }}>
                          {isLeader && !isEndUser && (
                            <Button
                              className="buttonPC button--info w100"
                              onClick={openPopupGroup}
                              style={{ width: "100%", minWidth: "unset" }}
                            >
                              <PlusOutlined />
                              {isPC && lang.ADD_TO_GROUP}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={16}>
                      <Row className="rowNotMargin" align="middle">
                        <Col span={6} className="title-form-item">
                          {lang.TYPE_CHECKLIST}
                        </Col>
                        <Col span={10}>
                          <Form.Item name="type" className="rowNotMargin">
                            <Select
                              disabled={isHadUnit}
                              style={{ width: "100%" }}
                              placeholder={lang.TYPE_CHECKLIST}
                            >
                              {renderOption(optionTime, false)}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={16}>
                      <Row className="rowNotMargin" align="middle">
                        <Col span={6} className="title-form-item">
                          点検表種類
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name="checklistType"
                            className="rowNotMargin"
                          >
                            <Select
                              disabled={id}
                              value={ddlPreUseChecklist.value}
                              style={{ width: "100%" }}
                              placeholder={"点検表種類"}
                              onChange={onChangePreUseChecklist}
                            >
                              {renderOption(ddlPreUseChecklist.options, false)}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={16}>
                      <Row className="rowNotMargin" align="middle">
                        <Col span={6} className="title-form-item">
                          {lang.NOTE}
                        </Col>
                        <Col span={18}>
                          <Form.Item name="remarks" className="rowNotMargin">
                            <TextArea
                              rows={3}
                              placeholder={lang.NOTE}
                            ></TextArea>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
        <Col span={24}>
          <BoxContent className="p0">
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
              <Panel
                header={
                  <>
                    <ArchiveTick className="first-icon" variant="Bold" />{" "}
                    プロジェクト
                  </>
                }
                key="1"
              >
                <Table
                  scroll={dataSource && dataSource.length > 0 && { x: 1200 }}
                  locale={{
                    emptyText: "",
                  }}
                  pagination={false}
                  dataSource={dataSource}
                  className="tableAddMachine"
                  columns={columns}
                />
                <div className="mt8">
                  <Button
                    style={{
                      background: "#f7f9fc",
                      width: "auto",
                      minWidth: "auto",
                    }}
                    className="pr10 pl10 mt4 mb4 ml10"
                    onClick={() => handleAddRow()}
                    icon={<PlusOutlined />}
                  ></Button>
                </div>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
      </Row>
      <FooterPage>
        <Row justify="end">
          <Col>
            <Button
              className="buttonPC button--info wAuto ml10"
              onClick={checkValidate}
            >
              <SaveOutlined />
              {lang.SAVE}
            </Button>
          </Col>
          <Col>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15"
              onClick={() => {
                history.goBack();
              }}
            >
              <CloseCircleOutlined />
              {lang.CANCEL}
            </Button>
          </Col>
        </Row>
      </FooterPage>
    </div>
  );
}

export default AddDynamicReport;

export function ColorPicker({ color, onChange }) {
  const [display, setDisplay] = useState(false);
  return (
    <>
      <div>
        <div
          onClick={() => setDisplay(true)}
          style={{
            cursor: "pointer",
            padding: 2,
            border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          <div style={{ width: "100%", height: 14, backgroundColor: color }} />
        </div>
        <Modal
          visible={display}
          onCancel={() => setDisplay(false)}
          footer={null}
          closable={false}
        >
          <ChromePicker
            color={color}
            onChangeComplete={(value) => onChange(value.hex)}
            className="w100"
          />
        </Modal>
      </div>
    </>
  );
}

export function ModalBoxMachine({
  onSubmit,
  setShowLoading,
  value,
  clicksaved,
  ...props
}) {
  const [popupMachine, setPopupMachine] = useState({
    ...new classPopup(),
    list: [],
  });

  const [checkedList, setCheckedList] = useState([]);

  const onAddMachine = () => {
    if (checkedList.length > 0) {
      onSubmit(checkedList[0]);
      setCheckedList([]);
      setPopupMachine({ ...popupMachine, show: false });
    } else {
      showMessage(KEY.WARMING, "機械を選択してください");
    }
  };
  return (
    <>
      <Input.Search
        disabled={props.disabled}
        style={props.style}
        value={value}
        onChange={props.onChange}
        onSearch={() => setPopupMachine({ ...popupMachine, show: true })}
      />
      <Modal
        className="box-search-machine"
        centered
        title={COMMON_CONSTANT.TITLE_CREATE_GROUP}
        visible={popupMachine.show}
        onCancel={() => {
          setPopupMachine({ ...popupMachine, show: false });
        }}
        footer={[
          <Button
            key={2}
            onClick={() => {
              setPopupMachine({ ...popupMachine, show: false });
            }}
          >
            <CloseCircleOutlined />
            {lang.CANCEL}
          </Button>,
          <Button
            key={1}
            className="buttonPC button--info"
            onClick={onAddMachine}
          >
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
        ]}
      >
        <BoxSearchMachine
          setLoading={setShowLoading}
          listFilter={popupMachine.list}
          checkedList={[checkedList, setCheckedList]}
          isOneValue={true}
          defaultValue={value}
        />
      </Modal>
    </>
  );
}
