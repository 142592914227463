/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  Modal,
  Divider,
  Form,
  Space,
  Layout,
  Empty,
  Switch,
} from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import RowInput from "../../component/Desktop/rowInput2";
import { ReactComponent as DeleteIcon } from "@src/styles/icon/delete.svg";
import FooterPage from "../../component/footer/footerPage";
import { ReactComponent as SettingIcon } from "@src/styles/icon/setting.svg";
import { ReactComponent as TimeIcon } from "@src/styles/icon/time.svg";
import { ReactComponent as ChecklistIcon } from "@src/styles/icon/checklist.svg";
import { ReactComponent as UploadIcon } from "@src/styles/icon/upload.svg";
import ComboCheckBox from "../../component/Desktop/comboCheckboxRow";
import Loading from "../../component/Desktop/loading/loading";
import {
  COMMON_CONSTANT,
  INSPECTION_TIME,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import API from "../../api/backend/managementCL";
import KBN from "../../api/backend/kbn";
import INVENTORY_API from "../../api/backend/inventory";
import EditGroupModal from "../../component/Desktop/popup/editGroupModal";
import AddGroupModal from "../../component/Desktop/popup/addGroupModal";
import {
  validateGroup,
  isChecklistDeleted,
  renderOption,
  MSG_CATCH,
  statusRes,
  removeItemArr,
  checkSpecialCharacterSingle,
  showMessage,
  enterSearch,
  isEmpty,
} from "../../constants/utils";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useParams } from "react-router-dom";

import {
  PlusCircleFilled,
  SaveOutlined,
  SnippetsOutlined,
  SearchOutlined,
  DoubleRightOutlined,
  PlusOutlined,
  WarningFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import TabCheckList from "../../component/Desktop/tabAdd"; /// Tab group
import TabCheckListPreCheck from "../../component/Desktop/tabAddPreCheck";
import classBtn from "../../models/control/button";
import classDdl from "../../models/control/dropdownlist";
import classOption from "../../models/control/option";
import classText from "../../models/control/text";
import classItem from "../../models/itemMachine";
import classItemPreCheck from "../../models/itemPreCheck";
import classMachine from "../../models/machineDes";
import classGroupPreCheck from "../../models/groupPreCheck";
import classGroup from "../../models/groupMachine";
import classPopup from "../../models/control/popup";
import { useSelector } from "react-redux";
import Roles from "@src/routers/roles/roles.const";
import { checkRoleScreen } from "../../utils/roles";
import { key } from "localforage";
import inventory from "../../api/backend/inventory";
import { isObject, isString } from "lodash";

//Declare global variables
let CURRENT_INSPECTION_TIMMING = KEY.TIMING_DATE; // Default check date
let CURRENT_REMARK_TYPE = KEY.REMARK_DEFAULT; // Default check date
let MACHINE_PICKED = [];
let MAXIMUM_GROUP_ID = 0;
let FLAG_CHANGE_DATA = false;
let formatTemplate = "";
let CHECKLIST_ID;
let PARAMS;
let clickSearch = true;
const { Content } = Layout;
const isPC = true;

let DDL_TIMING = [];
const OBJ_TIMING = {};
let DDL_TEMPLATE = [];
let DDL_TYPE = [];
let DDL_REMARKTYPE = [];

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function CreateCL(props) {
  let { id, clone } = useParams();
  const { Option } = Select;
  const history = useHistory();
  let [initPage, setInitPage] = useState(true);
  const objClear = { value: "", disabled: true };
  let [showLoading, setShowLoading] = useState(false);

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  let [txtChecklistName, setTxtChecklistName] = useState(new classText());
  let [txtStatus, setTxtStatus] = useState(new classText());
  let [ddlInspectionTiming, setDdlInspectionTiming] = useState(new classDdl());
  let [ddlTypeChecklist, setddlTypeChecklist] = useState(new classDdl()); //v2
  let [ddlPreUseChecklist, setddlPreUseChecklist] = useState({
    ...new classDdl(),
    options: [
      { key: "", value: "巡視点検用" },
      { key: "1", value: "使用前点検表" },
    ],
  });
  let [remarkType, setRemarkType] = useState(new classDdl());
  let [MULTIPLE_TIME, setMULTIPLE_TIME] = useState(false);
  let [accumulatedTypeList, setOptionAccumulatedType] = useState(
    new classDdl()
  );
  let [defaultInspectionTiming, setOptionDefaultInspectionTiming] = useState(
    new classDdl()
  ); //v2
  let [defaultTypeList, setOptionDefaultType] = useState(new classDdl()); //v2
  let [defaultJudgement, setOptionDefaultJudgement] = useState(new classDdl()); //v2
  let [defaultInputMode, setOptionDefaultInputMode] = useState(new classDdl());
  let [hdnUploadFlag, setHdnUploadFlag] = useState("0"); // Tab active
  let [ddlTemplate, setDdlTemplate] = useState(new classDdl());
  let [txtAttachFile, setTxtAttachFile] = useState(new classText());
  let [txtFullNameTemplate, setTxtFullNameTemplate] = useState(new classText());
  const [txtFileArray, setTxtFileArray] = useState(null);
  const [btnImport, setBtnImport] = useState(new classBtn());

  // Inventory
  const [inventoryData, setInventoryData] = useState([]);
  const [inventoryItem, setInventoryItem] = useState(new classDdl());

  // Focus
  const txtChecklistNameEl = useRef(null);
  const ddlTemplateEl = useRef(null);
  const fileEl = useRef(null);
  const txtAttachFileEl = useRef(null);
  const btnImportEl = useRef(null);

  // Group 2
  let [ddlFacility, setDdlFacility] = useState(new classDdl());
  let [ddlBuilding, setDdlBuilding] = useState(new classDdl());
  let [ddlFloor, setDdlFloor] = useState(new classDdl());
  let [ddlRoom, setDdlRoom] = useState(new classDdl());
  let [ddlProcess, setDdlProcess] = useState(new classDdl());
  let [txtMachineNameSearch, setTxtMachineNameSearch] = useState({
    ...new classText(),
    disabled: true,
  });
  let [btnSearch, setBtnSearch] = useState(new classBtn());
  let [CURRENT_MACHINE_SEARCH_LIST, setCURRENT_MACHINE_SEARCH_LIST] = useState(
    []
  ); /// List
  let [
    CURRENT_MACHINE_SEARCH_LIST_Setting,
    setCURRENT_MACHINE_SEARCH_LIST_Setting,
  ] = useState({
    options: [],
    checkedList: [],
    indeterminate: false,
    checkAll: false,
  });

  const [stateData, setStateData] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  let [checkItemList, setCheckItemList] = useState([]);
  let [machineList, setMachineList] = useState([]);
  let [machineListCount, setMachineListCount] = useState(0);

  //GROUP 3
  let [listTimeCheck, setlistTimeCheck] = useState([
    "09:00",
    "11:00",
    "13:00",
    "15:00",
    "17:00",
  ]); //v2

  // Group Tab
  let tabInit = new classGroup();
  tabInit.ID = 1;
  tabInit.Name = "共有";
  tabInit.Order = 1;
  let [GROUP_LIST, setGROUP_LIST] = useState([tabInit]);
  let [GROUP_PRE_CHECK_LIST, setGROUP_PRE_CHECK_LIST] = useState([tabInit]);

  let [CURRENT_GROUP, setCURRENT_GROUP] = useState(GROUP_LIST[0].ID);
  let [CURRENT_GROUP_PRE_CHECK, setCURRENT_GROUP_PRE_CHECK] = useState(
    GROUP_PRE_CHECK_LIST[0].ID
  );
  // Popup
  // Add group
  let [newGroupName, setNewGroupName] = useState(new classText());
  let [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [indexTabNew, setIndexTabNew] = useState(null);

  // Popup edit group
  let [editGroupName, setEditGroupName] = useState(new classText());
  let [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [editGroupModal_Config, setEditGroupModal_Config] = useState({
    dataRow: null,
    funcDel: null,
    funcUpdateName: null,
  });
  let [popupLocationMachine, setPopupLocationMachine] = useState(
    new classPopup()
  );

  // Popup Pre Check
  // Add group
  let [newGroupNamePreCheck, setNewGroupNamePreCheck] = useState(
    new classText()
  );
  let [showAddGroupModalPreCheck, setShowAddGroupModalPreCheck] =
    useState(false);
  const [indexTabNewPreCheck, setIndexTabNewPreCheck] = useState(null);

  // Popup edit group
  let [editGroupNamePreCheck, setEditGroupNamePreCheck] = useState(
    new classText()
  );
  let [showEditGroupModalPreCheck, setShowEditGroupModalPreCheck] =
    useState(false);
  const [editGroupModal_ConfigPreCheck, setEditGroupModal_ConfigPreCheck] =
    useState({
      dataRow: null,
      funcDel: null,
      funcUpdateName: null,
    });

  //show checklist
  const [showCheckList, setShoewCheckList] = useState(true);
  const { roles, allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [
    txtChecklistName.value,
    ddlInspectionTiming.value,
    remarkType.value,
    ddlTemplate.value,
    txtAttachFile.value,
    GROUP_LIST,
  ]);

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setInitPage(true);
      window.scrollTo(0, 0);
      // Open loading
      openLoadingSpinner();

      MACHINE_PICKED = []; // SHIEVAIDP1-68: Clear msg Warning
      PARAMS = queryString.parse(props.location.search);

      // Screen type
      if (id) {
        // Empty
      } else if (clone) {
        id = clone;
        clone = true;
      } else {
        // Empty
      }

      const loadDetail = () => {
        // View data: CLONE/EDIT
        if (id) {
          return API.getCheckList({
            id: id,
            version: PARAMS.version ? PARAMS.version : null,
          });
        }
        // Create
        return;
      };

      const showDdlFacility = (data) => {
        let fa = new classDdl();
        // Update Ddl Facility
        data &&
          data.length > 0 &&
          data.forEach((v) => {
            let op = new classOption();
            op.id = v.facilityCode;
            op.name = v.facilityName;
            fa.options.push(op);
          });
        setDdlFacility(fa);
      };

      const showDetail = (data, inventoryData) => {
        setTxtChecklistName({ ...txtChecklistName, value: data.checklistName });
        setTxtStatus({ ...txtStatus, value: data.status, show: true });
        setDdlInspectionTiming({
          ...ddlInspectionTiming,
          options: DDL_TIMING,
          value: data.checkTimingKey,
        });
        setRemarkType({
          ...remarkType,
          options: DDL_REMARKTYPE,
          value: data.remarkType,
        });
        setddlTypeChecklist({
          ...ddlTypeChecklist,
          options: DDL_TYPE,
          value: data.typeValue,
        });
        setDdlTemplate({
          ...ddlTemplate,
          options: DDL_TEMPLATE,
          value: data.templateId,
        });
        MULTIPLE_TIME = Number(data.typeValue) === 2;
        setMULTIPLE_TIME(MULTIPLE_TIME);
        if (MULTIPLE_TIME) {
          listTimeCheck = data.times.split(",");
          setlistTimeCheck(listTimeCheck);
        }
        setShoewCheckList(data.isDisplay);

        setddlPreUseChecklist({
          ...ddlPreUseChecklist,
          value: data.isPreUseChecklist,
        });

        if (data.isPreUseChecklist == KEY.TYPE_PRE_USE_CHECKLIST) {
          // Group ---
          let listGroup = [];

          // Group Mapping
          data.groups = data.groups.map((grp) => {
            let group = new classGroupPreCheck();
            group.ID = grp["id"];
            group.Name = grp["groupName"];
            group.Order = grp["itemOrder"];
            group.Items = [];

            // ItemPreChecks
            grp["itemPreChecks"].forEach((i) => {
              let item = new classItemPreCheck();

              // item = i;
              item.ID = i["id"]; ///
              item.Order = i["itemOrder"];
              item.CheckItemName = i["checkItemName"];
              item.CheckItemCode = i["checkItemCode"];
              item.AccumulatedType = i["accumulatedType"]
                ? i["accumulatedType"]
                : "1";
              item.AccumulatedTypeValue = i["accumulatedTypeValue"];
              item.DefaultType = i["defaultType"];
              item.DefaultValue = i["defaultValue"];
              item.AccumulatedGroupItemCodes = i["accumulatedGroupItemCodes"];
              item.InspectionTiming = i["inspectionTiming"]
                ? i["inspectionTiming"]
                : "1";
              item.ItemJudgement = i["itemJudgement"]
                ? i["itemJudgement"]
                : "1";
              item.Multiplier = i["multiplier"];
              item.NormalLowerControlValue = i["normalLowerControlValue"];
              item.NormalUpperControlValue = i["normalUpperControlValue"];
              item.Mode = i["mode"];
              item.Unit = i["unit"];
              item.CheckOrder = i["checkOrder"];
              item.Choices = i["choices"];

              // Add 2 item
              item.InventoryItemCode = i["inventoryItemCode"];
              item.InventoryWarehouseItemCode = i["inventoryWarehouseItemCode"];

              item.MultipleTime = Number(i.typeValue) === 2;
              if (MULTIPLE_TIME) {
                item.TimeArr.length = listTimeCheck.length;
                i.times &&
                  i.times.split(",").forEach((v) => {
                    item.TimeArr[v - 1] = true;
                  });
              }

              group.Items.push(item);
            });
            listGroup.push(group);
            setGROUP_PRE_CHECK_LIST(listGroup);
            setCURRENT_GROUP_PRE_CHECK(listGroup[0].ID);
          });
        } else {
          // Group ---
          let listGroup = [];

          // Mapping
          data.groups.forEach((grp) => {
            let group = new classGroup();
            group.ID = grp["id"];
            group.Name = grp["groupName"];
            group.Order = grp["itemOrder"];
            group.Machines = [];

            // Machine
            grp["machines"].forEach((mc) => {
              let machine = new classMachine();

              machine.ID = mc["machineForSiteOfficeCode"];
              machine.Name = mc["machineforSiteOfficeName"];
              machine.Order = mc["itemOrder"];
              machine.MachineForSiteOfficeCode = mc["machineForSiteOfficeCode"];
              // machine.MachineforSiteOfficeId = mc["machineForSiteOfficeCode"];
              // Location
              machine.facilityName = mc["facilityName"];
              machine.processName = mc["processName"];
              machine.buildingName = mc["buildingName"];
              machine.floorName = mc["floorName"];
              machine.roomName = mc["roomName"];

              // Item
              machine.Items = [];
              mc["items"].forEach((i) => {
                let item = new classItem();
                if (
                  machine.Items.some(
                    (x) => x.CheckItemCode == i["checkItemCode"]
                  )
                ) {
                  return;
                }
                // item = i;
                item.ID = i["checkItemId"]; ///
                item.Order = i["itemOrder"];
                item.CheckItemName = i["checkItemName"];
                item.CheckItemCode = i["checkItemCode"];
                item.accumulatedType = i["accumulatedType"]
                  ? i["accumulatedType"]
                  : "1";
                item.accumulatedTypeValue = i["accumulatedTypeValue"];
                item.defaultType = i["defaultType"];
                item.defaultValue = i["defaultValue"];
                item.accumulatedGroupItemCodes = i["accumulatedGroupItemCodes"];
                item.itemJudgement = i["itemJudgement"]
                  ? i["itemJudgement"]
                  : "1";
                item.autoCalculate = i["autoCalculate"];
                item.itemCodeRefer = i["itemCodeRefer"];
                item.multiplier = i["multiplier"];
                item.MachineforSiteOfficeIDId =
                  machine.MachineForSiteOfficeCode;
                item.NormalLowerControlValue = i["normalLowerControlValue"];
                item.NormalUpperControlValue = i["normalUpperControlValue"];
                item.InspectionTimingText = i["inspectionTiming"];
                item.mode = i["mode"];
                item.unit = i["unit"];
                item.CheckOrder = i["checkOrder"];
                item.checked = Number(i["isChecked"]) === 1 ? true : false;

                // Add 2 new Item
                const listInventoryItemOptions = inventoryData.map((item) => ({
                  key: item.inventoryItemCode,
                  name: item.inventoryItemName,
                }));
                item.inventoryItemCode = new classDdl(
                  i["inventoryItemCode"],
                  listInventoryItemOptions
                );
                const indexWarehouse = inventoryData.findIndex(
                  (item) => item.inventoryItemCode === i["inventoryItemCode"]
                );
                let optionWareHouse = [];
                if (indexWarehouse >= 0) {
                  optionWareHouse = inventoryData[
                    indexWarehouse
                  ].warehouseItems.map((item) => ({
                    key: item.inventoryWarehouseItemCode,
                    value: item.inventoryWarehouseName,
                  }));
                }
                item.inventoryWarehouseItemCode = new classDdl(
                  i["inventoryWarehouseItemCode"],
                  optionWareHouse
                );

                // V2
                item.inputContentOptions = i["inputContentOptionsValue"];
                item.multipleTime = Number(i.typeValue) === 2;
                if (MULTIPLE_TIME) {
                  item.timeArr.length = listTimeCheck.length;
                  i.times &&
                    i.times.split(",").forEach((v) => {
                      item.timeArr[v - 1] = true;
                    });
                }

                machine.Items.push(item);
              });
              group.Machines.push(machine);
              MACHINE_PICKED.push(machine.ID);
            });

            listGroup.push(group);
            setGROUP_LIST(listGroup);
            setCURRENT_GROUP(listGroup[0].ID);
          });
        }
      };

      // Load pulldown
      Promise.all([
        API.getTiming(),
        API.getChecklistTemplate(),
        API.getFacility(),
        loadDetail(),
        KBN.getCheckListType(),
        KBN.getOptionsAccummulateType(),
        KBN.getRemarkType(),
        KBN.getDefaultType(),
        KBN.getItemJudgement(),
        KBN.getInputMode(),
        INVENTORY_API.searchItems({}),
      ])
        .then((res) => {
          // DDL Time
          DDL_REMARKTYPE = res[6].data.data;
          DDL_TIMING = res[0] ? res[0].data.data : INSPECTION_TIME;
          setDdlInspectionTiming({
            ...ddlInspectionTiming,
            options: DDL_TIMING,
            value: KEY.TIMING_DATE,
          });
          DDL_TIMING.forEach((v) => {
            OBJ_TIMING[v.key] = v.value;
          });
          setOptionDefaultInspectionTiming({
            ...defaultInspectionTiming,
            options: DDL_TIMING,
          });
          //DDL Remark
          DDL_REMARKTYPE.forEach((item) => {
            if (Number(item.key) === 1) {
              item.value = lang.MACHINE_ONLY;
            } else {
              item.value = lang.REMARK_ITEM;
            }
          });
          setRemarkType({
            ...remarkType,
            options: DDL_REMARKTYPE,
            value: KEY.REMARK_DEFAULT,
          });
          // DDL Template
          DDL_TEMPLATE = res[1] ? res[1].data.data : [];
          setDdlTemplate({ ...ddlTemplate, options: DDL_TEMPLATE });
          // DDL Facility
          let facility = res[2] ? res[2].data : [];
          showDdlFacility(facility);

          // Type Check
          DDL_TYPE = res[4] ? res[4].data.data : [];
          setddlTypeChecklist({
            ...ddlTypeChecklist,
            options: DDL_TYPE,
            value: DDL_TYPE[0].key,
          });
          // Option
          let ipContent = res[5] ? res[5].data.data : [];
          setOptionAccumulatedType({
            ...accumulatedTypeList,
            options: ipContent,
          });
          //array type
          let arrType = res[7] ? res[7].data.data : [];
          setOptionDefaultType({
            ...defaultTypeList,
            options: arrType,
          });
          //array judgement
          let arrJudgement = res[8] ? res[8].data.data : [];
          setOptionDefaultJudgement({
            ...defaultJudgement,
            options: arrJudgement,
          });

          //array input mode
          let arrInputMode = res[9] ? res[9].data.data : [];
          setOptionDefaultInputMode({
            ...defaultInputMode,
            options: arrInputMode,
          });

          // inventory
          const inventoryData = res[10] ? res[10].data.data : [];
          setInventoryData(inventoryData);

          // Detail -- Alway lasted
          if (res[3] && !res[3].data && id) {
            // eslint-disable-next-line no-throw-literal
            throw { status: 404 };
          }
          let data = res[3] ? res[3].data.data : [];
          id && showDetail(data, inventoryData);
        })
        .catch((err) => {
          if (err.status === 404) {
            history.push("/ChecklistManagementv2");
          } else showMessage(KEY.ERROR, MSG_CATCH(), toParent);
        })
        .then(() => {
          setInitPage(false);
          closeLoadingSpinner();
        });

      // Group  pre -use check item init
      initNewGroupPreCheck();
    }
  }, [allowRoute]);

  const toParent = () => {
    history.goBack();
  };

  // Popup common
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    const wPopup = Modal[typeInfo ? "warning" : "confirm"]({
      centered: true,
      title: typeInfo ? lang.WARMING : lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: () => {
        callbackCancel && callbackCancel();
        wPopup.destroy();
      },
      onOk: () => {
        callbackOK && callbackOK();
        wPopup.destroy();
      },
    });
  };
  const onChangeRemarkType = (value) => {
    CURRENT_REMARK_TYPE = value;
    setRemarkType({
      ...remarkType,
      value: CURRENT_REMARK_TYPE,
    });
  };
  // --------------------Group 1
  const changeDdlInspectionTiming = (value) => {
    // Update ddl
    CURRENT_INSPECTION_TIMMING = value;
    setDdlInspectionTiming({
      ...ddlInspectionTiming,
      value: CURRENT_INSPECTION_TIMMING,
    });

    // Case machine exist in group
    if (MACHINE_PICKED.length > 0) {
      // Popup YES/NO
      showConfirmMessage(
        COMMON_CONSTANT.BOM_C021,
        function () {
          // YES
          // init values;
          initValuesInspectionTiming();
          // Re-call API search machine
          // selectFacility(); - Fix SHIEVAIDP1-50
        },
        function () {
          // NO
          CURRENT_INSPECTION_TIMMING = ddlInspectionTiming.value; // Reset value
          setDdlInspectionTiming({
            ...ddlInspectionTiming,
            value: CURRENT_INSPECTION_TIMMING,
          });
        }
      );
    } else {
      // Clear comboboxlist
      setCURRENT_MACHINE_SEARCH_LIST([]);
      // Re-call API search machine
      // selectFacility(); - Fix SHIEVAIDP1-50
    }

    // Change for preuse-checklist
    const list = [...GROUP_PRE_CHECK_LIST];
    list.forEach((group = new classGroupPreCheck()) => {
      group.Items.forEach((item = new classItemPreCheck()) => {
        item.InspectionTiming = value;
      });
    });
    setGROUP_PRE_CHECK_LIST(list);
  };

  const onChangeTypeChecklist = (value) => {
    let vOld = ddlTypeChecklist.value;

    if (ddlPreUseChecklist.value === KEY.TYPE_PRE_USE_CHECKLIST) {
      // Popup YES/NO
      showConfirmMessage(
        COMMON_CONSTANT.BOM_E028,
        function () {
          setddlTypeChecklist({ ...ddlTypeChecklist, value: value });
          MULTIPLE_TIME = Number(value) === 2;
          setMULTIPLE_TIME(MULTIPLE_TIME);
          // YES
          initNewGroupPreCheck(true);
        },
        function () {
          // NO
          setddlTypeChecklist({ ...ddlTypeChecklist, value: vOld });
          MULTIPLE_TIME = Number(vOld) === 2;
          setMULTIPLE_TIME(MULTIPLE_TIME);
        }
      );
    } else {
      setddlTypeChecklist({ ...ddlTypeChecklist, value: value });
      MULTIPLE_TIME = Number(value) === 2;
      setMULTIPLE_TIME(MULTIPLE_TIME);

      // Case machine exist in group
      if (MACHINE_PICKED.length > 0) {
        // Popup YES/NO
        showConfirmMessage(
          COMMON_CONSTANT.BOM_E028,
          function () {
            // YES
            initValuesInspectionTiming(false);
          },
          function () {
            // NO
            setddlTypeChecklist({ ...ddlTypeChecklist, value: vOld });
            MULTIPLE_TIME = Number(vOld) === 2;
            setMULTIPLE_TIME(MULTIPLE_TIME);
          }
        );
      } else {
        // Clear comboboxlist
        // setCURRENT_MACHINE_SEARCH_LIST([]);
      }
    }
  };

  const onChangePreUseChecklist = (value) => {
    setddlPreUseChecklist({ ...ddlPreUseChecklist, value: value });
  };

  // Clear value const marchine
  function initValuesInspectionTiming(clearSearchMachine = true) {
    // Clear list machine added
    MACHINE_PICKED = [];
    // Clear comboboxlist
    clearSearchMachine && setCURRENT_MACHINE_SEARCH_LIST([]);
    // Clear all machine in tab
    let dataGroup = [...GROUP_LIST];
    for (let i = 0; i < dataGroup.length; i++) {
      dataGroup[i].Machines = [];
    }
    setGROUP_LIST(dataGroup);
  }

  // Group 2 - item FIRST
  const selectFacility = (allowOpenLoading) => {
    allowOpenLoading && openLoadingSpinner();

    // Clear 4 dropdown list
    let building = new classDdl();
    let floor = new classDdl();
    let room = new classDdl();
    let process = new classDdl();

    if (ddlFacility.value !== "") {
      // Enable textfield, btn search
      setTxtMachineNameSearch({ ...txtMachineNameSearch, disabled: false });
      setBtnSearch({ ...btnSearch, disabled: false });

      const facilityId = ddlFacility.value;
      // 1. Load building
      API.getBuilding(facilityId)
        .then((res) => {
          let data = res.data;

          if (data.length > 0) {
            // Push
            data.forEach((element) => {
              building.options.push(
                new classOption(element.buildingCode, element.buildingName)
              );
            });

            ddlBuilding.value = "";
            setDdlBuilding({ ...building, value: "", disabled: false });

            // Call func select Building
            selectBuilding(false);
          } else {
            setDdlBuilding({ ...building, ...objClear });
            setDdlFloor({ ...floor, ...objClear });
            setDdlRoom({ ...room, ...objClear });
          }
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });

      // 2. Load Process
      API.getProcess(facilityId)
        .then((res) => {
          let data = res.data;

          // Push
          if (data.length > 0) {
            data.forEach((element) => {
              process.options.push(
                new classOption(element.id, element.processName)
              );
            });

            // setDdlProcess({ ...process, value: selectedProcess, disabled: false });
            setDdlProcess({ ...process, disabled: false });
          } else {
            setDdlProcess({ ...process, ...objClear });
          }

          allowOpenLoading && closeLoadingSpinner();
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    }
    if (ddlFacility.value === "") {
      // Clear data pulldown
      setDdlBuilding({ ...building, ...objClear });
      setDdlFloor({ ...floor, ...objClear });
      setDdlRoom({ ...room, ...objClear });
      setDdlProcess({ ...process, ...objClear });
      // setTxtMachineNameSearch({ ...txtMachineNameSearch, ...objClear });
      setBtnSearch({ ...btnSearch, ...objClear });
      allowOpenLoading && closeLoadingSpinner();
    }
  };

  // Group 2 - item no.2. Get floor
  function selectBuilding(allowOpenLoading) {
    allowOpenLoading && openLoadingSpinner();

    // Clear 2 dropdown list
    let newFloor = new classDdl();
    let newRoom = new classDdl();

    if (ddlBuilding.value !== "") {
      const buildingId = ddlBuilding.value;

      // Call API get Floor
      API.getFloor(buildingId)
        .then((res) => {
          let data = res.data;

          if (data.length > 0) {
            // Push
            data.forEach((element) => {
              newFloor.options.push(
                new classOption(element.floorCode, element.floorName)
              );
            });
            ddlFloor.value = "";
            setDdlFloor({ ...newFloor, ...objClear, disabled: false });

            // Call API load Floor
            selectFloor(false);
          } else {
            setDdlFloor({ ...newFloor, ...objClear });
            setDdlRoom({ ...newRoom, ...objClear });
          }
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          allowOpenLoading && closeLoadingSpinner();
        });
    } else {
      setDdlFloor({ ...newFloor, ...objClear });
      setDdlRoom({ ...newRoom, ...objClear });
      allowOpenLoading && closeLoadingSpinner();
    }
  }
  // Group 2 - item no.3. Get Room
  function selectFloor(allowOpenLoading) {
    allowOpenLoading && openLoadingSpinner();

    // Clear option
    let newRoom = new classDdl();

    if (ddlFloor.value !== "") {
      const floorId = ddlFloor.value;

      API.getRoom(floorId)
        .then((res) => {
          let data = res.data;

          if (data.length > 0) {
            // Push
            data.forEach((element) => {
              newRoom.options.push(
                new classOption(element.roomCode, element.roomName)
              );
            });
            setDdlRoom({ ...newRoom, ...objClear, disabled: false });
          } else {
            setDdlRoom({ ...newRoom, ...objClear, disabled: true });
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          allowOpenLoading && closeLoadingSpinner();
        });
    } else {
      setDdlRoom({ ...newRoom, disabled: true });
      allowOpenLoading && closeLoadingSpinner();
    }
  }

  useEffect(() => {
    searchMachine();
  }, [
    ddlProcess.value,
    ddlRoom.value,
    ddlFloor.value,
    ddlBuilding.value,
    ddlFacility.value,
  ]);

  // Update offset
  useEffect(() => {
    setStateData({
      ...stateData,
      offset: machineList.length,
      hasMore: machineList.length <= machineListCount,
      loading: false,
    });
  }, [machineList]);

  // Search : Search Machine where ...
  function searchMachine(offset = 0) {
    // Check loading
    if (stateData.loading && offset !== 0) {
      return;
    } else {
      setStateData({ ...stateData, loading: true });
    }
    // Case event
    if (typeof offset === "object") {
      offset = 0;
    }
    // Open loader
    if (offset === 0) {
      openLoadingSpinner(); //Only init
    }
    // Setting params
    let dataSearch = {
      machineName: txtMachineNameSearch.value.trim(),
      facilityCode: ddlFacility.value,
      buildingCode: ddlBuilding.value,
      floorCode: ddlFloor.value,
      roomCode: ddlRoom.value,
      processId: ddlProcess.value,
      checkTiming: CURRENT_INSPECTION_TIMMING,
      offset: offset,
      limit: 20,
    };

    // Call api
    API.getMachine(dataSearch)
      .then((res) => {
        machineListCount = res.data.machineListCount;
        setMachineListCount(machineListCount);

        if (offset === 0) {
          clickSearch = true;
          // Case: Init search
          machineList = res.data.machineList;
          //
          if (machineList.length === 0) {
            // Clear combobox list
            setCURRENT_MACHINE_SEARCH_LIST([]);
          } else {
            setMachineList(machineList);
            setCheckItemList(res.data.checkItems);
            // Render combobox list
            getCheckItemList_SearchMachine(machineList, res.data.checkItems);
          }
        } else {
          clickSearch = false;
          // Case load more
          machineList = [...machineList, ...res.data.machineList];
          checkItemList = [...checkItemList, ...res.data.checkItems];
          setMachineList(machineList);
          setCheckItemList(checkItemList);

          // Render combobox list
          getCheckItemList_SearchMachine(machineList, checkItemList);
        }
      })
      .catch((xhr) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  }

  // Merge data
  function getCheckItemList_SearchMachine(
    listMachine = [],
    checkItemList = []
  ) {
    // Loop machine
    listMachine.forEach((machineObj) => {
      machineObj["Items"] = [];
      // Loop item machine
      checkItemList.forEach((checkItemObj) => {
        // checkItem IN Machine
        if (
          checkItemObj["machineForSiteOfficeCode"] ===
          machineObj["machineForSiteOfficeCode"]
        ) {
          machineObj["Items"].push(checkItemObj);
        }
      });
    });
    showMachineAfterSearch(listMachine);
  }

  // Update data CURRENT_MACHINE_SEARCH_LIST
  function showMachineAfterSearch(MachineList) {
    const cbl = [];
    MachineList &&
      MachineList.forEach((machineObj) => {
        if (machineObj.Items && machineObj.Items.length > 0) {
          let m = {
            ...machineObj,
            ID: machineObj["machineForSiteOfficeCode"],
            MachineName: machineObj["machineForSiteOfficeName"],
            Items: machineObj.Items,
          };
          cbl.push(m);
        }
      });

    setCURRENT_MACHINE_SEARCH_LIST(cbl);

    closeLoadingSpinner();
  }

  // Check Machine add to Tab
  function checkMachineAdded(id) {
    for (let i = 0; i < MACHINE_PICKED.length; i++) {
      if (MACHINE_PICKED[i] === id) {
        return true;
      }
    }
    return false;
  }

  // Check machine existed other group
  function checkMachineExistedOtherGroup(machineID, groupIDNow) {
    const listGroupConstain = [];
    for (let i = 0; i < GROUP_LIST.length; i++) {
      if (GROUP_LIST[i].ID !== groupIDNow) {
        // Tab top
        for (let j = 0; j < GROUP_LIST[i].Machines.length; j++) {
          // Tab left
          if (GROUP_LIST[i].Machines[j].ID === machineID) {
            listGroupConstain.push(GROUP_LIST[i].Name);
          }
        }
      }
    }
    return listGroupConstain;
  }

  // Add Machine to Tab
  function addMachineToCheckList() {
    //Show loading
    openLoadingSpinner();

    //Get current Group object
    let groupObj;
    let indexGroup = 0;
    if (GROUP_LIST.length > 1) {
      for (let i = 0; i < GROUP_LIST.length; i++) {
        if (Number(GROUP_LIST[i].ID) === Number(CURRENT_GROUP)) {
          groupObj = GROUP_LIST[i];
          indexGroup = i;
          break;
        }
      }
      if (!groupObj) {
        //default
        groupObj = GROUP_LIST[0];
      }
    } else {
      groupObj = GROUP_LIST[0];
    }

    const grpNew = GROUP_LIST;
    let flagAdded = false;
    const errorMachineNames = [];
    const warmingMachineNames = [];
    // check existed in current group
    let isExistedInGroup = false;
    //check existed in other group
    let isExistedInOtherGroup = false;

    // Loop list Machine checked
    for (
      let index = 0;
      index < CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList.length;
      index++
    ) {
      isExistedInGroup = false;
      isExistedInOtherGroup = false;
      //get Machine ID
      const machineID = CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList[index];
      /// -------- Check ERROR
      // check existed in current group

      for (let i = 0; i < groupObj.Machines.length; i++) {
        if (groupObj.Machines[i].ID === machineID) {
          isExistedInGroup = true;
          break;
        }
      }
      const machineObj = getSelectedSearchMachine(machineID); /// Get data Machine selected
      if (isExistedInGroup && machineObj) {
        errorMachineNames.push(machineObj.MachineName);
      }

      //Clone Items list
      const checkItemsList = [];

      machineObj.Items.forEach(function (itemObj, i) {
        // Push
        let item = new classItem();

        item.CheckItemCode = itemObj.checkItemCode;
        item.CheckItemName = itemObj.checkItemName;
        item.Order = i + 1;
        item.ID = itemObj.id;
        item.InspectionTiming = itemObj.inspectionTiming;
        item.InspectionTimingText = OBJ_TIMING[itemObj.inspectionTiming];
        item.MachineforSiteOfficeIDId = itemObj.machineForSiteOfficeCode;
        item.NormalLowerControlValue = itemObj.normalLowerControlValue;
        item.NormalUpperControlValue = itemObj.normalUpperControlValue;
        item.accumulatedType = i["accumulatedType"]
          ? i["accumulatedType"]
          : "1";
        item.accumulatedTypeValue = i["accumulatedTypeValue"];
        item.multiplier = i["multiplier"];
        item.defaultType = i["defaultType"];
        item.defaultValue = i["defaultValue"];
        item.accumulatedGroupItemCodes = i["accumulatedGroupItemCodes"];
        item.itemJudgement = i["itemJudgement"] ? i["itemJudgement"] : "1";
        item.autoCalculate = i["autoCalculate"];
        item.itemCodeRefer = i["itemCodeRefer"];
        item.mode = itemObj.mode;
        item.unit = itemObj.unit;
        item.CheckOrder = itemObj.CheckOrder;
        item.checked = true;
        item.multipleTime =
          ddlTypeChecklist.value !== ddlTypeChecklist.options[0].key;

        if (MULTIPLE_TIME) {
          item.timeArr = listTimeCheck.map((v, i) => {
            return true;
          });
        }

        const listInventoryItemOptions = inventoryData.map((item) => ({
          key: item.inventoryItemCode,
          name: item.inventoryItemName,
        }));
        item.inventoryItemCode = new classDdl(
          i["inventoryItemCode"],
          listInventoryItemOptions
        );
        const indexWarehouse = inventoryData.findIndex(
          (item) => item.inventoryItemCode === i["inventoryItemCode"]
        );
        let optionWareHouse = [];
        if (indexWarehouse >= 0) {
          optionWareHouse = inventoryData[indexWarehouse].warehouseItems.map(
            (item) => ({
              key: item.inventoryWarehouseItemCode,
              value: item.inventoryWarehouseName,
            })
          );
        }
        item.inventoryWarehouseItemCode = new classDdl(
          i["inventoryWarehouseItemCode"],
          optionWareHouse
        );

        checkItemsList.push(item);
      });
      /// -------- Check WARRING
      //check existed in other group
      if (!isExistedInGroup) {
        isExistedInOtherGroup = checkMachineAdded(machineID);
      }
      if (isExistedInOtherGroup && machineObj) {
        //Uncheck all item check when add other groups
        //find item machine in other group;
        const addedMachinesItems = [];
        GROUP_LIST.forEach(function (groupObj) {
          groupObj.Machines.forEach(function (machineObj) {
            if (machineObj.ID === machineID) {
              machineObj.Items.forEach(function (itemObj) {
                if (itemObj.checked) {
                  addedMachinesItems.push(itemObj.ID);
                }
              });
            }
          });
        });
        //find machine in other group; Exist -> checked: false
        checkItemsList.forEach((itemObj) => {
          if (addedMachinesItems.indexOf(itemObj.ID) > -1) {
            itemObj.checked = false;
          }
        });

        const groupsConstains = checkMachineExistedOtherGroup(
          machineID,
          groupObj.ID
        );

        warmingMachineNames.push({
          name: machineObj.MachineName,
          groups: groupsConstains,
        });
      }
      //process add machine to checklist
      if (machineObj && !isExistedInGroup) {
        if (!isExistedInOtherGroup) {
          MACHINE_PICKED.push(machineID);
        }
        flagAdded = true;
        FLAG_CHANGE_DATA = true;
        //add to current group
        let machine = new classMachine();
        machine.ID = machineID;
        machine.Name = machineObj.MachineName;
        machine.Order = grpNew[indexGroup].Machines.length + 1;
        machine.Items = checkItemsList;
        // Location
        machine.facilityName = machineObj.facilityName;
        machine.buildingName = machineObj.buildingName;
        machine.floorName = machineObj.floorName;
        machine.roomName = machineObj.roomName;
        machine.processName = machineObj.processName;
        grpNew[indexGroup].Machines.push(machine);
      }
    }

    setGROUP_LIST(grpNew);

    if (flagAdded) {
      //UnCheck btn All
      setCURRENT_MACHINE_SEARCH_LIST_Setting({
        ...CURRENT_MACHINE_SEARCH_LIST_Setting,
        checkAll: false,
        checkedList: [],
      });
    }

    if (errorMachineNames.length > 0) {
      // Show ERROR
      let errMsg =
        "タブ「" +
        groupObj.Name +
        "」には既に以下の同名の機器が登録されています。";
      errorMachineNames.forEach(function (errMachine) {
        errMsg += "<br/> ・ " + errMachine;
      });
      errMsg += "<br/>他のタブを選択してください。";

      let html = <span dangerouslySetInnerHTML={{ __html: errMsg }}></span>;

      showMessage(KEY.ERROR, html);
    } else if (warmingMachineNames.length > 0) {
      // Show WARNING
      const groupNameList = [];
      const groupList = [];
      warmingMachineNames.forEach(function (errMachine) {
        errMachine.groups.forEach(function (groupsName) {
          const idGroup = groupNameList.indexOf(groupsName);

          if (idGroup < 0) {
            // Not exist
            groupNameList.push(groupsName);
            groupList.push({
              name: groupsName,
              machines: [errMachine.name],
            });
          } else {
            // Exist group
            groupList[idGroup].machines.push(errMachine.name);
          }
        });
      });

      let html = (
        <>
          {groupList.map((groupEl, i) => {
            return (
              <>
                {i === 0 ? (
                  <WarningFilled />
                ) : (
                  <>
                    <br />
                    <br />
                    <WarningFilled />
                  </>
                )}{" "}
                タブ「{groupEl.name}
                」には既に以下の同名の機器が登録されています。
                {groupEl.machines.map((machineEl, index) => {
                  return <div key={index}> ・ {machineEl}</div>;
                })}
                他タブ「{groupObj.Name}
                」に登録する場合は、機器を追加した後点検項目を選択してください。その場合、タブ「
                {groupObj.Name}」に選択された点検項目はタブ「{groupEl.name}
                」において未選択となります。
              </>
            );
          })}
        </>
      );

      showMessage(KEY.WARMING, html);
    } else {
      // Empty
    }

    //Close loading after process
    closeLoadingSpinner();
  }

  function getSelectedSearchMachine(machineID) {
    if (CURRENT_MACHINE_SEARCH_LIST && CURRENT_MACHINE_SEARCH_LIST.length > 0) {
      for (let i = 0; i < CURRENT_MACHINE_SEARCH_LIST.length; i++) {
        if (CURRENT_MACHINE_SEARCH_LIST[i].ID === machineID) {
          return CURRENT_MACHINE_SEARCH_LIST[i];
        }
      }
    }
    return null;
  }

  // Choose file: START
  function attachTemplate(element) {
    // Read file as array
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        // $("[id$='txtFileArray']").val(reader.result);
        setTxtFileArray(reader.result);
      },
      false
    );
    reader.readAsDataURL(element.target.files[0]);

    let fileName = element.target.files[0].name;
    const arrSplit = fileName ? fileName.split("\\") : [];
    fileName =
      arrSplit && arrSplit.length > 0 ? arrSplit[arrSplit.length - 1] : "";
    // $("[id$='txtFullNameTemplate']").val(fileName);
    setTxtFullNameTemplate({ ...txtFullNameTemplate, value: fileName });
    fileNameProcess(fileName);
  }

  function fileNameProcess(fileName) {
    const name = fileName;
    if (name) {
      const indexOfDot = name.lastIndexOf(".");
      formatTemplate = "";
      setTxtAttachFile({ ...txtAttachFile, value: "" });

      // Update name view
      if (indexOfDot) {
        formatTemplate = name.substring(indexOfDot + 1);
        setTxtAttachFile({
          ...txtAttachFile,
          value: name.substring(0, indexOfDot),
        });
      }
    }
  }
  // Choose file: END
  const validateItems = () => {
    let flagErr = false;
    if (ddlPreUseChecklist.value === KEY.TYPE_PRE_USE_CHECKLIST) {
      let groupPreCheckData = JSON.parse(JSON.stringify(GROUP_PRE_CHECK_LIST)); // Clone arr
      groupPreCheckData.forEach((g = new classGroupPreCheck()) => {
        g.Items.forEach((i = new classItemPreCheck()) => {
          if (Number(i.defaultType) === 3 || Number(i.defaultType) === 4) {
            if (!i.defaultValue || i.defaultValue === "") {
              flagErr = true;
            }
          }
        });
      });
    } else {
      let groupData = JSON.parse(JSON.stringify(GROUP_LIST)); // Clone arr
      groupData.forEach((g = new classGroup()) => {
        g.Machines.forEach((m = new classMachine()) => {
          m.Items.forEach((i = new classItem()) => {
            if (Number(i.defaultType) === 3 || Number(i.defaultType) === 4) {
              if (!i.defaultValue || i.defaultValue === "") {
                flagErr = true;
              }
            }
          });
        });
      });
    }
    return !flagErr;
  };

  const validatePreCheckItems = () => {
    let flagErr = false;
    if (ddlPreUseChecklist.value === KEY.TYPE_PRE_USE_CHECKLIST) {
      let list = [...GROUP_PRE_CHECK_LIST];
      list.forEach((g = new classGroupPreCheck()) => {
        g.Items.forEach((i = new classItemPreCheck()) => {
          if (
            i.Mode === "" ||
            i.Mode === null ||
            i.Mode === false ||
            i.CheckItemName === "" ||
            i.CheckItemName === null ||
            i.CheckItemName === false ||
            i.InspectionTiming === "" ||
            i.InspectionTiming === null ||
            i.InspectionTiming === false
          ) {
            flagErr = true;
            i.Mode = i.Mode || false;
            i.CheckItemName = i.CheckItemName || null;
            i.InspectionTiming = i.InspectionTiming || false;
          }
        });
      });
      setGROUP_PRE_CHECK_LIST(list);
    }
    return !flagErr;
  };
  //Save function: 1
  function confirmSaveData(isOffical) {
    if (!validateItems()) {
      Modal.error({
        centered: true,
        title: lang.ERROR,
        content: "初期値を入力してください!",
      });
    }
    if (validateSave() && validateItems()) {
      showConfirmMessage(
        id ? COMMON_CONSTANT.BOM_C006 : COMMON_CONSTANT.BOM_C008,
        function () {
          saveData(isOffical);
        },
        function () {
          //do nothing
        }
      );
    }
    return false;
  }

  //Save function: 2 End
  function saveData(isOffical) {
    openLoadingSpinner();
    // 0: Draft
    // 1: Offical
    let flagStatus = isOffical ? 1 : 0;

    // Check for update
    if (CHECKLIST_ID && isChecklistDeleted(CHECKLIST_ID)) {
      showPopupChecklistDeleted();
      return;
    } else {
      // Create Form data
      const formData = new FormData();
      // Format Object data
      let groupData;
      if (ddlPreUseChecklist.value === KEY.TYPE_PRE_USE_CHECKLIST) {
        groupData = JSON.parse(JSON.stringify(GROUP_PRE_CHECK_LIST)); // Clone arr
        groupData = groupData.map((g = new classGroupPreCheck()) => {
          g.ItemPreChecks = [...g.Items];
          delete g.Items;
          return g;
        });

        groupData = groupData.map((g = new classGroupPreCheck()) => {
          g.ItemPreChecks.map((i = new classItemPreCheck()) => {
            i["Type"] = i.MultipleTime ? "2" : "1";
            if (Number(ddlTypeChecklist.value) === 2) {
              i["Times"] = i.TimeArr.map((v, i) => (v ? i + 1 : null))
                .filter((v) => typeof v === "number")
                .toString();
            }
            return i;
          });
          return g;
        });

        if (isOffical) {
          // Case save official
          groupData = groupData.filter((v = new classGroupPreCheck()) => {
            return v.Name === "共有" || v.ItemPreChecks.length > 0;
          });

          // Re-order after filter
          groupData = groupData.map(
            (g = new classGroupPreCheck(), indexGroup) => {
              g.Order = ++indexGroup;
              return g;
            }
          );
        }
      } else {
        groupData = JSON.parse(JSON.stringify(GROUP_LIST)); // Clone arr
        groupData = groupData.map((g = new classGroup()) => {
          g.Machines.map((m = new classMachine()) => {
            m.MachineForSiteOfficeCode = m.ID;
            m.Items.map((i = new classItem()) => {
              i["IsChecked"] = i.checked ? 1 : 0;
              i["CheckItemID"] = i.ID;
              // Case multiple time
              i["Type"] = i.multipleTime ? "2" : "1";
              if (Number(ddlTypeChecklist.value) === 2) {
                i["Times"] = i.timeArr
                  .map((v, i) => (v ? i + 1 : null))
                  .filter((v) => typeof v === "number")
                  .toString();
              }
              // Inventory value
              i["inventoryItemCode"] = i.inventoryItemCode.value;
              i["inventoryWarehouseItemCode"] =
                i.inventoryWarehouseItemCode.value;
              return i;
            });
            return m;
          });
          return g;
        });
        if (isOffical) {
          // Case save official
          groupData = groupData.filter((v = new classGroup()) => {
            // Only tab exist Machine OR Tab 共有
            v.Machines = v.Machines.filter((m = new classMachine()) => {
              let count = 0;
              m.Items.forEach((i = new classItem()) => {
                i.checked && count++;
              });
              // Add machine exist item checked
              return count > 0;
            });
            return v.Name === "共有" || v.Machines.length > 0;
          });

          // Re-order after filter
          groupData = groupData.map((g = new classGroup(), indexGroup) => {
            g.Order = ++indexGroup;
            g.Machines.map((m = new classMachine(), indexMachine) => {
              m.Order = ++indexMachine;
              return m;
            });
            return g;
          });
        }
      }

      // Create Object
      let formInput = {
        checklistName: txtChecklistName.value,
        isPreUseChecklist: ddlPreUseChecklist.value,
        Status: flagStatus,
        CheckTiming: ddlInspectionTiming.value,
        RemarkType: remarkType.value,
        isNewTemplate: Number(hdnUploadFlag) === 0 ? false : true,
        TemplateTitle: txtAttachFile.value,
        ChecklistTemplateId: ddlTemplate.value,
        Type: ddlTypeChecklist.value,
        NumberOfTimes: listTimeCheck.length,
        Times: listTimeCheck.toString(),
        Groups: groupData,
      };
      if (id) {
        formInput.IsDisplay = Number(showCheckList) + "";
      }
      formData.append("data", JSON.stringify(formInput));
      formData.append(
        "file",
        formInput.isNewTemplate ? fileEl.current.files[0] : null
      );

      // Sent data to Server
      API.save(formData, id, clone)
        .then((res) => {
          if (statusRes(res)) {
            // Success
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, toParent);
          } else {
            // Fail
            showMessage(KEY.ERROR, (res && res.data.msg) || MSG_CATCH());
          }
        })
        .catch((err) => {
          let msg = MSG_CATCH();
          if (
            !isEmpty(err.response) &&
            isString(err.response.data) &&
            !isEmpty(err.response.data)
          ) {
            msg = err.response.data;
          }
          if (
            !isEmpty(err.response) &&
            isObject(err.response.data) &&
            isString(err.response.data.msg)
          ) {
            msg = err.response.data.msg;
          }
          showMessage(KEY.ERROR, msg);
        })
        .then(() => {
          closeLoadingSpinner();
        });
    }
  }

  function showPopupChecklistDeleted() {
    closeLoadingSpinner();
    FLAG_CHANGE_DATA = false;
    showMessage(KEY.ERROR, COMMON_CONSTANT.BOM_C022, function () {
      // window.location.href = _spPageContextInfo.webServerRelativeUrl + "/Pages/ChecklistManagement.aspx";
    });
  }

  function validateSave() {
    let classBtnImport = "";
    txtChecklistName = {
      ...txtChecklistName,
      value: txtChecklistName.value.trim(),
      error: false,
    };
    ddlTemplate = { ...ddlTemplate, error: false };
    txtAttachFile = {
      ...txtAttachFile,
      value: txtAttachFile.value.trim(),
      error: false,
    };

    const checkListName = txtChecklistName.value;
    let flagErr = false;
    let templateName;

    // Group 1
    // 3: Template
    if (hdnUploadFlag === "0") {
      // Tab 0: Pulldown
      templateName = ddlTemplate.value;
      if (!templateName || templateName === "") {
        flagErr = true;
        // Border red
        ddlTemplate = { ...ddlTemplate, error: true };
        ddlTemplateEl.current.focus();
      }
    } else {
      // Tab 1: Import file
      templateName = txtAttachFile.value;
      if (!txtFileArray) {
        flagErr = true;
        // Border red
        classBtnImport = " btnLink-error ";
        btnImportEl.current.focus();
      } else {
        // Check input
        if (
          templateName === "" ||
          (formatTemplate.toLowerCase() !== "xlsx" &&
            formatTemplate.toLowerCase() !== "xls")
        ) {
          // Border red
          txtAttachFile = { ...txtAttachFile, error: true };
          txtAttachFileEl.current.focus();
          //
          flagErr = true;
        }
      }
    }

    // 1: Checklist name
    if (
      checkListName === "" ||
      checkListName.length > 255 ||
      !checkSpecialCharacterSingle(txtChecklistName)
    ) {
      flagErr = true;
      //
      txtChecklistName = { ...txtChecklistName, error: true };
      txtChecklistNameEl.current.focus();
    }

    // Update state
    setBtnImport({ ...btnImport, class: classBtnImport });
    setTxtChecklistName(txtChecklistName);
    setDdlTemplate(ddlTemplate);
    setTxtAttachFile(txtAttachFile);

    // Validate pre check items
    if (!validatePreCheckItems()) {
      flagErr = true;
    }

    return !flagErr;
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      // No update
      toParent();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.BOM_C018, toParent, function () {
        FLAG_CHANGE_DATA = true;
      });
    }
  }

  // Clear message
  useEffect(() => {
    if (!editGroupName.error) {
      setEditGroupName({ ...editGroupName, message: "" });
    }
    if (!newGroupName.error) {
      setNewGroupName({ ...newGroupName, message: "" });
    }
  }, [editGroupName.error, newGroupName.error]);

  // Render object DOM
  useEffect(() => {
    // List option
    const options = [];

    CURRENT_MACHINE_SEARCH_LIST.forEach((e) => {
      options.push({
        value: e.ID,
        label: e.MachineName,
        other: e,
      });
    });

    let noData = options.length === 0;
    // Message
    const msg = noData ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null;

    // Set
    setCURRENT_MACHINE_SEARCH_LIST_Setting({
      ...CURRENT_MACHINE_SEARCH_LIST_Setting,
      checkedList: clickSearch
        ? []
        : CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList,
      checkAll: false,
      options: options,
      msg: msg,
    });
  }, [CURRENT_MACHINE_SEARCH_LIST]);

  function existedGroup(groupName, groupId) {
    for (let z = 0; z < GROUP_LIST.length; z++) {
      if (
        String(GROUP_LIST[z].Name) === String(groupName) &&
        String(GROUP_LIST[z].ID) !== String(groupId)
      ) {
        return true;
      }
    }
    return false;
  }

  const executeCreateNewGroup = (indexTabNew) => {
    const g = [...GROUP_LIST];
    let tabNew = new classGroup();
    tabNew.ID = indexTabNew;
    tabNew.Name = newGroupName.value;
    tabNew.Order = g.length + 1;
    // Add tab new
    g.push(tabNew);
    // Update state
    setGROUP_LIST(g);
  };

  // Check validate add
  const createNewGroup = () => {
    // Trim
    newGroupName = { ...newGroupName, value: newGroupName.value.trim() };

    if (validateGroup(newGroupName)) {
      if (!existedGroup(newGroupName.value, 0)) {
        MAXIMUM_GROUP_ID = MAXIMUM_GROUP_ID + 1;
        // Add group
        executeCreateNewGroup(indexTabNew);
        // Reset value input
        setNewGroupName({ ...newGroupName, value: "" });
        // Active tab new
        setCURRENT_GROUP(indexTabNew);
        // Hide popup
        setShowAddGroupModal(false);
      } else {
        // Show mess & border
        setNewGroupName({
          ...newGroupName,
          error: true,
          message: COMMON_CONSTANT.GROUP_NAME_EXISTS,
        });
      }
    } else {
      // Show border validate
      setNewGroupName({ ...newGroupName, error: true });
    }
  };

  function showLocation(machine = new classMachine()) {
    // Show popup location
    setPopupLocationMachine({
      ...popupLocationMachine,
      show: true,
      body: machine,
    });
  }

  const onAddListTimeCheck = () => {
    setlistTimeCheck([...listTimeCheck, ""]);
  };
  const onRemoveListTimeCheck = (index) => {
    listTimeCheck.splice(index, 1);
    setlistTimeCheck([...listTimeCheck]);

    GROUP_LIST.forEach((g) => {
      g.Machines.forEach((m) => {
        m.Items.forEach((i) => {
          i.timeArr.splice(index, 1);
        });
      });
    });
    setGROUP_LIST(GROUP_LIST);
  };
  const onUpdateTimeCheck = (event, indexTime) => {
    let v = event.target.value;
    let lNew = [...listTimeCheck];
    lNew[indexTime] = v;
    setlistTimeCheck(lNew);
  };

  // NEW:  Pre-check list item

  const initNewGroupPreCheck = (multipleTime) => {
    let listGroup = [];
    let group = new classGroupPreCheck();
    group.ID = 1;
    group.Name = "共有";
    group.Order = 1;
    let items = [];
    let item = new classItemPreCheck();
    item.ID = Date.now();
    item.Order = 1;

    item.InspectionTiming = ddlInspectionTiming.value
      ? ddlInspectionTiming.value
      : "1";

    item.MultipleTime = multipleTime;
    if (MULTIPLE_TIME) {
      item.TimeArr = listTimeCheck.map((v, i) => {
        return true;
      });
    }

    items.push(item);
    group.Items = items;
    listGroup.push(group);
    setGROUP_PRE_CHECK_LIST(listGroup);
    setCURRENT_GROUP_PRE_CHECK(listGroup[0].ID);
  };

  const executeCreateNewGroupPreCheck = (indexTabNew) => {
    const g = [...GROUP_PRE_CHECK_LIST];
    let tabNew = new classGroupPreCheck();
    tabNew.ID = indexTabNew;
    tabNew.Name = newGroupNamePreCheck.value;
    tabNew.Order = g.length + 1;
    let items = [];
    let item = new classItemPreCheck();
    item.ID = Date.now();
    item.Order = 1;
    item.InspectionTiming = ddlInspectionTiming.value
      ? ddlInspectionTiming.value
      : "1";
    item.MultipleTime = Number(ddlTypeChecklist.value) === 2;
    if (MULTIPLE_TIME) {
      item.TimeArr = listTimeCheck.map((v, i) => {
        return true;
      });
    }
    items.push(item);
    tabNew.Items = items;
    // Add tab new
    g.push(tabNew);

    // Update state
    setGROUP_PRE_CHECK_LIST(g);
  };

  // Check validate add
  const createNewGroupPreCheck = () => {
    // Trim
    newGroupNamePreCheck = {
      ...newGroupNamePreCheck,
      value: newGroupNamePreCheck.value.trim(),
    };

    if (validateGroup(newGroupNamePreCheck)) {
      if (!existedGroupPreCheck(newGroupNamePreCheck.value, 0)) {
        MAXIMUM_GROUP_ID = MAXIMUM_GROUP_ID + 1;
        // Add group
        executeCreateNewGroupPreCheck(indexTabNewPreCheck);
        // Reset value input
        setNewGroupNamePreCheck({ ...newGroupNamePreCheck, value: "" });
        // Active tab new
        setCURRENT_GROUP_PRE_CHECK(indexTabNewPreCheck);
        // Hide popup
        setShowAddGroupModalPreCheck(false);
      } else {
        // Show mess & border
        setNewGroupNamePreCheck({
          ...newGroupNamePreCheck,
          error: true,
          message: COMMON_CONSTANT.GROUP_NAME_EXISTS,
        });
      }
    } else {
      // Show border validate
      setNewGroupNamePreCheck({ ...newGroupNamePreCheck, error: true });
    }
  };

  function existedGroupPreCheck(groupName, groupId) {
    for (let z = 0; z < GROUP_PRE_CHECK_LIST.length; z++) {
      if (
        String(GROUP_PRE_CHECK_LIST[z].Name) === String(groupName) &&
        String(GROUP_PRE_CHECK_LIST[z].ID) !== String(groupId)
      ) {
        return true;
      }
    }
    return false;
  }

  const onAddPreCheckItemRow = (index) => {
    let list = [...GROUP_PRE_CHECK_LIST];
    let item = new classItemPreCheck();
    let countItem = 0;
    if (list[index].Items) {
      countItem = list[index].Items.length;
    }
    item.ID = Date.now();
    item.Order = countItem + 1;
    item.InspectionTiming = ddlInspectionTiming.value;
    item.MultipleTime = Number(ddlTypeChecklist.value) === 2;
    if (MULTIPLE_TIME) {
      item.TimeArr = listTimeCheck.map((v, i) => {
        return true;
      });
    }
    if (list[index].Items) {
      list[index].Items = [...list[index].Items, item];
    } else {
      list[index].Items = [item];
    }
    setGROUP_PRE_CHECK_LIST(list);
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage
        name={
          id
            ? lang.EDIT_CHECKLIST
            : clone
            ? lang.CLONE_CHECKLIST
            : lang.CREATE_CHECKLIST
        }
      />
      {/* Group 1 */}
      <Content
        // className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        <Form layout={"vertical"} className="formDes">
          <Space className="d-flex" size={15}>
            <RowInput name="点検書式名" required>
              <Input
                maxLength={255}
                className={
                  (txtChecklistName.error ? STYLE.BORDER_RED : "") + " wInputx2"
                }
                ref={txtChecklistNameEl}
                value={txtChecklistName.value}
                onChange={(e) =>
                  setTxtChecklistName({
                    ...txtChecklistName,
                    value: e.target.value,
                  })
                }
              />
            </RowInput>
            <RowInput name="点検表種類" required>
              <Select
                value={ddlPreUseChecklist.value}
                disabled={id}
                className="wInput"
                onChange={onChangePreUseChecklist}
              >
                {renderOption(ddlPreUseChecklist.options, false)}
              </Select>
            </RowInput>
            {txtStatus.show && (
              <RowInput name={lang.STATUS}>
                <Input disabled value={txtStatus.value} className="wInput" />
              </RowInput>
            )}
            {[Roles.OFFICE_ADMIN, Roles.HEAD_OFFICE_ADMIN].includes(roles) &&
              id && (
                <Space className="mt10">
                  <label style={{ fontWeight: "bold" }}>画面</label>{" "}
                  <Switch
                    checked={showCheckList}
                    onChange={(e) => setShoewCheckList(!showCheckList)}
                  />
                </Space>
              )}
          </Space>
          <Row gutter={[15, 0]}>
            <Col>
              <Row gutter={[15, 0]}>
                <Col>
                  <RowInput name="点検頻度" required>
                    <Select
                      value={ddlInspectionTiming.value}
                      className="wInput"
                      onChange={changeDdlInspectionTiming}
                    >
                      {renderOption(ddlInspectionTiming.options, false)}
                    </Select>
                  </RowInput>
                </Col>
                <Col>
                  <RowInput name="点検区分" required>
                    <Select
                      value={ddlTypeChecklist.value}
                      className="wInput"
                      onChange={onChangeTypeChecklist}
                    >
                      {renderOption(ddlTypeChecklist.options, false)}
                    </Select>
                  </RowInput>
                </Col>
                <Col>
                  <RowInput name={lang.REMARK_TYPE} required>
                    <Select
                      value={remarkType.value}
                      className="wInput"
                      onChange={onChangeRemarkType}
                    >
                      {renderOption(remarkType.options, false)}
                    </Select>
                  </RowInput>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[15, 0]}>
                <Col>
                  <RowInput name="出力テンプレート" required>
                    <Select
                      onChange={(v) => {
                        setHdnUploadFlag(v);
                      }}
                      defaultValue={hdnUploadFlag}
                      className="wInput"
                    >
                      <Option value={"0"}>既存ファイルを選択する</Option>
                      <Option value={"1"}>
                        新規ファイルをアップロードする
                      </Option>
                    </Select>
                  </RowInput>
                </Col>
                <Col>
                  {Number(hdnUploadFlag) === 0 ? (
                    <RowInput name=" ">
                      <Select
                        ref={ddlTemplateEl}
                        className={`${
                          ddlTemplate.error ? STYLE.BORDER_RED : ""
                        } wInputx2`}
                        value={ddlTemplate.value}
                        onChange={(v) =>
                          setDdlTemplate({ ...ddlTemplate, value: v })
                        }
                      >
                        {renderOption(ddlTemplate.options)}
                      </Select>
                    </RowInput>
                  ) : (
                    <RowInput name=" ">
                      <input
                        type="file"
                        className="d-none"
                        accept=".xls,.xlsx"
                        hidden={true}
                        ref={fileEl}
                        onChange={attachTemplate}
                      />
                      {!txtFileArray ? (
                        <Button
                          ref={btnImportEl}
                          type="link"
                          className={"wAuto btnLink d-inline" + btnImport.class}
                          onClick={(e) => fileEl.current.click()}
                        >
                          <UploadIcon className="svg-default mr10" />{" "}
                          {lang.ATTACH}
                        </Button>
                      ) : (
                        <Input.Group>
                          <Input
                            ref={txtAttachFileEl}
                            className={
                              (txtAttachFile.error ? STYLE.BORDER_RED : "") +
                              " wInput"
                            }
                            value={txtAttachFile.value}
                            onChange={(e) => {
                              setTxtAttachFile({
                                ...txtAttachFile,
                                value: e.target.value,
                              });
                            }}
                          />
                          <Button
                            className="delTimeCheck"
                            onClick={() => {
                              // Clear input file
                              fileEl.current.value = null;
                              setTxtFileArray(null);
                              formatTemplate = "";
                              setTxtAttachFile({
                                ...txtAttachFile,
                                value: txtAttachFile.value,
                              });
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Input.Group>
                      )}
                    </RowInput>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>

      {ddlPreUseChecklist.value === KEY.TYPE_PRE_USE_CHECKLIST ? (
        <>
          {/* Group 3 */}
          {Number(ddlTypeChecklist.value) === 2 && (
            <Content
              className={isPC && "site-layout-background"}
              style={{
                margin: "24px 16px",
                padding: isPC ? 20 : 0,
              }}
            >
              <div className="titleText2">
                <TimeIcon className="svg-default w17px" />
                {`複数回の点検 (${listTimeCheck.length})`}
              </div>
              <Row gutter={[16, 16]}>
                {listTimeCheck.map((v, i) => {
                  return (
                    <Col lg={4} xs={8} key={i}>
                      <Row>
                        <Col span={16}>
                          <Input
                            value={v}
                            width={30}
                            maxLength={10}
                            onChange={(e) => {
                              onUpdateTimeCheck(e, i);
                            }}
                          ></Input>
                        </Col>
                        <Col span={8}>
                          {listTimeCheck.length > 1 && (
                            <Button
                              className="delTimeCheck"
                              onClick={(e) => {
                                onRemoveListTimeCheck(i);
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Col>
                  {listTimeCheck.length < 10 && (
                    <PlusCircleFilled
                      className="iconL blue"
                      onClick={onAddListTimeCheck}
                    />
                  )}
                </Col>
              </Row>
            </Content>
          )}
          {/* Group 4 */}
          <Content
            className={isPC && "site-layout-background"}
            style={{
              margin: "24px 16px",
              padding: isPC ? 20 : 0,
            }}
          >
            <div className="titleText2">
              <ChecklistIcon className="svg-default w17px" /> {lang.CHECKLIST}
            </div>
            {/* Table Select Machine */}
            <TabCheckListPreCheck
              ddlTypeChecklist={ddlTypeChecklist}
              panes={GROUP_PRE_CHECK_LIST}
              setPanes={setGROUP_PRE_CHECK_LIST}
              activeKey={CURRENT_GROUP_PRE_CHECK}
              setActiveKey={setCURRENT_GROUP_PRE_CHECK}
              MULTIPLE_TIME={MULTIPLE_TIME}
              listTimeCheck={listTimeCheck}
              accumulatedTypeList={accumulatedTypeList}
              defaultInspectionTiming={defaultInspectionTiming}
              defaultTypeList={defaultTypeList}
              defaultJudgement={defaultJudgement}
              defaultInputMode={defaultInputMode}
              // Add Group
              onAddRow={(indexTabNew) => {
                onAddPreCheckItemRow(indexTabNew);
              }}
              onDeleteRow={(indexTabNew, id) => {
                let preCheckItems = [...GROUP_PRE_CHECK_LIST];
                let items = [...preCheckItems[indexTabNew].Items];
                items.splice(id, 1);
                preCheckItems[indexTabNew].Items = [...items];
                setGROUP_PRE_CHECK_LIST(preCheckItems);
              }}
              // Add Group
              onAddTab={(indexTabNew) => {
                setIndexTabNewPreCheck(indexTabNew);
                // Show popup
                setShowAddGroupModalPreCheck(true);
              }}
              // Update Group
              onUpdateTab={(
                dataRow = new classGroup(),
                funcDel,
                funcUpdateName
              ) => {
                // Update field name
                setEditGroupNamePreCheck({
                  ...editGroupNamePreCheck,
                  value: dataRow.Name,
                });
                // Upadate function
                setEditGroupModal_ConfigPreCheck({
                  dataRow: dataRow,
                  funcDel: funcDel,
                  funcUpdateName: funcUpdateName,
                });
                // Show popup
                setShowEditGroupModalPreCheck(true);
              }}
              // Remove left
              onRemoveLeft={(
                delTabLeft,
                rowData = new classMachine(),
                idParent,
                leftData
              ) => {
                Modal.confirm({
                  centered: true,
                  title: "機器削除",
                  content: (
                    <>
                      <span className="bold">{rowData.Name}</span>
                      {COMMON_CONSTANT.BOM_C032}
                    </>
                  ),
                  okText: lang.OK,
                  cancelText: lang.CANCEL,
                  onOk: () => {
                    delTabLeft(rowData.ID, idParent, leftData);
                    MACHINE_PICKED = removeItemArr(MACHINE_PICKED, rowData.ID); // REMOVE list pick
                    Modal.destroyAll();
                  },
                });
              }}
              showLocation={showLocation}
            ></TabCheckListPreCheck>

            {/* Button footer */}
          </Content>
        </>
      ) : (
        <>
          {/* Group 2 select machine */}
          <Content
            className={isPC && "site-layout-background"}
            style={{
              margin: "24px 16px",
              padding: isPC ? 20 : 0,
            }}
          >
            <div className="titleText2">
              <SettingIcon className="svg-default w17px" /> 点検対象
            </div>
            <Row>
              <Col lg={6} xs={24}>
                <Form layout={"vertical"} className="formDes">
                  <RowInput name="">
                    <Input
                      value={txtMachineNameSearch.value}
                      placeholder="機器名"
                      // className="wInput"
                      style={{
                        width: "calc(100% - 108px)",
                      }}
                      onChange={(e) =>
                        setTxtMachineNameSearch({
                          ...txtMachineNameSearch,
                          value: e.target.value,
                        })
                      }
                      {...enterSearch(searchMachine)}
                    />
                    {/* Button search 検索*/}
                    <Button
                      className="buttonPC button--info wAuto ml5"
                      // disabled={btnSearch.disabled}
                      onClick={() => {
                        searchMachine(0);
                      }}
                    >
                      <SearchOutlined className="" /> 検索
                    </Button>
                  </RowInput>
                  <Divider className="mr0 ml0 mt20 mb15" />
                  <div className="mb5">
                    <b>{lang.FILTER}</b>
                    {lang.COLON}
                  </div>
                  <RowInput name="施設">
                    <Select
                      value={ddlFacility.value}
                      // className="wInput"
                      onChange={(v) => {
                        // Update for select
                        ddlFacility.value = v;
                        setDdlFacility({ ...ddlFacility, value: v });
                        selectFacility(true);
                      }}
                    >
                      {renderOption(ddlFacility.options)}
                    </Select>
                  </RowInput>
                  <RowInput name="棟">
                    <Select
                      value={ddlBuilding.value}
                      // className="wInput"
                      disabled={ddlBuilding.disabled}
                      onChange={(v) => {
                        // Update for select
                        ddlBuilding.value = v;
                        setDdlBuilding({ ...ddlBuilding, value: v });
                        selectBuilding(true);
                      }}
                    >
                      {renderOption(ddlBuilding.options)}
                    </Select>
                  </RowInput>
                  <RowInput name="階">
                    <Select
                      value={ddlFloor.value}
                      // className="wInput"
                      disabled={ddlFloor.disabled}
                      onChange={(v) => {
                        // Update for select
                        ddlFloor.value = v;
                        setDdlFloor({ ...ddlFloor, value: v });
                        selectFloor(true);
                      }}
                    >
                      {renderOption(ddlFloor.options)}
                    </Select>
                  </RowInput>
                  <RowInput name="部屋">
                    <Select
                      value={ddlRoom.value}
                      // className="wInput"
                      disabled={ddlRoom.disabled}
                      onChange={(v) => {
                        // Update for select
                        ddlRoom.value = v;
                        setDdlRoom({ ...ddlRoom, value: v });
                      }}
                    >
                      {renderOption(ddlRoom.options)}
                    </Select>
                  </RowInput>
                  <RowInput name="プロセス">
                    <Select
                      value={ddlProcess.value}
                      // className="wInput"
                      disabled={ddlProcess.disabled}
                      onChange={(v) => {
                        // Update for select
                        ddlProcess.value = v;
                        setDdlProcess({ ...ddlProcess, value: v });
                      }}
                    >
                      {renderOption(ddlProcess.options)}
                    </Select>
                  </RowInput>
                </Form>
              </Col>
              {/* From */}
              <Col lg={2} xs={0}>
                <DoubleRightOutlined
                  style={{
                    fontSize: "60px",
                    color: "#b9b9b9",
                    width: "100%",
                    margin: "200px 0 0",
                  }}
                />
              </Col>
              {/* To */}
              <Col lg={16} xs={24}>
                <div className="box-select-row">
                  <ComboCheckBox
                    loadMore={() => {
                      searchMachine(stateData.offset);
                    }}
                    stateData={stateData}
                    style={{
                      overflow: "hidden",
                      height: "100%",
                      overflowY: "auto",
                    }}
                    dataGroup={GROUP_LIST}
                    {...CURRENT_MACHINE_SEARCH_LIST_Setting}
                    onCheck={(checkedList) => {
                      setCURRENT_MACHINE_SEARCH_LIST_Setting({
                        ...CURRENT_MACHINE_SEARCH_LIST_Setting,
                        checkedList: checkedList,
                        indeterminate:
                          !!checkedList.length &&
                          checkedList.length <
                            CURRENT_MACHINE_SEARCH_LIST_Setting.options.length,
                        checkAll:
                          checkedList.length ===
                          CURRENT_MACHINE_SEARCH_LIST_Setting.options.length,
                      });
                    }}
                    onCheckAll={(e) =>
                      setCURRENT_MACHINE_SEARCH_LIST_Setting({
                        ...CURRENT_MACHINE_SEARCH_LIST_Setting,
                        checkedList: e.target.checked
                          ? CURRENT_MACHINE_SEARCH_LIST_Setting.options.map(
                              (e) => {
                                return e.value;
                              }
                            )
                          : [],
                        indeterminate: false,
                        checkAll: e.target.checked,
                      })
                    }
                  ></ComboCheckBox>
                </div>
                {/* Button select 点検表に追加*/}
                <Row justify="end">
                  <Button
                    className="buttonPC button--info wAuto mt15"
                    disabled={
                      CURRENT_MACHINE_SEARCH_LIST_Setting.checkedList.length ===
                      0
                    }
                    onClick={addMachineToCheckList}
                  >
                    <PlusOutlined />
                    点検表に追加
                  </Button>
                </Row>
              </Col>
            </Row>
          </Content>
          {/* Group 3 */}
          {Number(ddlTypeChecklist.value) === 2 && (
            <Content
              className={isPC && "site-layout-background"}
              style={{
                margin: "24px 16px",
                padding: isPC ? 20 : 0,
              }}
            >
              <div className="titleText2">
                <TimeIcon className="svg-default w17px" />
                {`複数回の点検 (${listTimeCheck.length})`}
              </div>
              <Row gutter={[16, 16]}>
                {listTimeCheck.map((v, i) => {
                  return (
                    <Col lg={4} xs={8} key={i}>
                      <Row>
                        <Col span={16}>
                          <Input
                            value={v}
                            width={30}
                            maxLength={10}
                            onChange={(e) => {
                              onUpdateTimeCheck(e, i);
                            }}
                          ></Input>
                        </Col>
                        <Col span={8}>
                          {listTimeCheck.length > 1 && (
                            <Button
                              className="delTimeCheck"
                              onClick={(e) => {
                                onRemoveListTimeCheck(i);
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Col>
                  {listTimeCheck.length < 10 && (
                    <PlusCircleFilled
                      className="iconL blue"
                      onClick={onAddListTimeCheck}
                    />
                  )}
                </Col>
              </Row>
            </Content>
          )}
          {/* Group 4 */}
          <Content
            className={isPC && "site-layout-background"}
            style={{
              margin: "24px 16px",
              padding: isPC ? 20 : 0,
            }}
          >
            <div className="titleText2">
              <ChecklistIcon className="svg-default w17px" /> {lang.CHECKLIST}
            </div>
            {/* Table Select Machine */}
            <TabCheckList
              inventoryData={inventoryData}
              ddlTypeChecklist={ddlTypeChecklist}
              panes={GROUP_LIST}
              setPanes={setGROUP_LIST}
              activeKey={CURRENT_GROUP}
              setActiveKey={setCURRENT_GROUP}
              // V2
              MULTIPLE_TIME={MULTIPLE_TIME}
              listTimeCheck={listTimeCheck}
              accumulatedTypeList={accumulatedTypeList}
              defaultTypeList={defaultTypeList}
              defaultJudgement={defaultJudgement}
              inventoryItem={inventoryItem}
              // Add Group
              onAddTab={(indexTabNew) => {
                setIndexTabNew(indexTabNew);
                // Show popup
                setShowAddGroupModal(true);
              }}
              // Update Group
              onUpdateTab={(
                dataRow = new classGroup(),
                funcDel,
                funcUpdateName
              ) => {
                // Update field name
                setEditGroupName({ ...editGroupName, value: dataRow.Name });
                // Upadate function
                setEditGroupModal_Config({
                  dataRow: dataRow,
                  funcDel: funcDel,
                  funcUpdateName: funcUpdateName,
                });
                // Show popup
                setShowEditGroupModal(true);
              }}
              // Remove left
              onRemoveLeft={(
                delTabLeft,
                rowData = new classMachine(),
                idParent,
                leftData
              ) => {
                Modal.confirm({
                  centered: true,
                  title: "機器削除",
                  content: (
                    <>
                      <span className="bold">{rowData.Name}</span>
                      {COMMON_CONSTANT.BOM_C032}
                    </>
                  ),
                  okText: lang.OK,
                  cancelText: lang.CANCEL,
                  onOk: () => {
                    delTabLeft(rowData.ID, idParent, leftData);
                    MACHINE_PICKED = removeItemArr(MACHINE_PICKED, rowData.ID); // REMOVE list pick
                    Modal.destroyAll();
                  },
                });
              }}
              showLocation={showLocation}
            ></TabCheckList>

            {/* Button footer */}
          </Content>
        </>
      )}

      <FooterPage>
        <Row justify="space-between">
          <Col>
            <Button
              className="buttonPC button--info wAuto "
              onClick={() => {
                confirmSaveData(true);
              }}
            >
              <SaveOutlined />
              {COMMON_CONSTANT.SAVE}
            </Button>
            <Button
              className="buttonPC button--outline wAuto ml15"
              onClick={() => {
                confirmSaveData(false);
              }}
            >
              <SnippetsOutlined />
              {COMMON_CONSTANT.SAVE_DRAFT}
            </Button>
          </Col>
          <Col>
            <Button
              className="buttonPC button--outline wAuto ml15"
              onClick={cancelSaveData}
            >
              <CloseCircleOutlined />
              {lang.CANCEL}
            </Button>
          </Col>
        </Row>
      </FooterPage>
      {/* Add group */}
      <AddGroupModal
        showPopup={showAddGroupModal}
        newGroupName={newGroupName}
        setNewGroupName={setNewGroupName}
        footer={[
          <Button
            key="back"
            className="buttonPC button--outline --todo--"
            onClick={() => {
              setNewGroupName({ ...newGroupName, value: "", error: false });
              setShowAddGroupModal(false);
            }}
          >
            <CloseCircleOutlined />
            {COMMON_CONSTANT.CLOSE}
          </Button>,
        ]}
        onCancel={() => {
          // Reset value input
          setNewGroupName({ ...newGroupName, value: "", error: false });
          setShowAddGroupModal(false);
        }}
        funcCreateGroup={createNewGroup}
      ></AddGroupModal>

      {/* Edit group */}
      <EditGroupModal
        showDelete={GROUP_LIST.length > 1}
        showPopup={showEditGroupModal}
        editGroupName={editGroupName}
        setEditGroupName={setEditGroupName}
        dataRow={editGroupModal_Config.dataRow}
        footer={[
          <Button
            key="back"
            className="buttonPC button--outline --todo--"
            onClick={() => {
              setShowEditGroupModal(false);
              setEditGroupName({ ...editGroupName, error: false });
            }}
          >
            <CloseCircleOutlined />
            {COMMON_CONSTANT.CLOSE}
          </Button>,
        ]}
        onCancel={() => {
          setShowEditGroupModal(false);
          setEditGroupName({ ...editGroupName, error: false });
        }}
        editCurrentGroup={(dataRow) => {
          let groupId = dataRow.ID;
          // Trim
          editGroupName.value = editGroupName.value.trim();

          const groupName = editGroupName.value;
          if (validateGroup(editGroupName)) {
            if (
              groupName.length > 0
              // && groupName.indexOf("共有") < 0
            ) {
              //check existed
              if (!existedGroup(groupName, groupId)) {
                // Update name
                editGroupModal_Config.funcUpdateName(groupName);
                // Hide popup
                setShowEditGroupModal(false);
                // hide message exist
              } else {
                setEditGroupName({
                  ...editGroupName,
                  error: true,
                  message: COMMON_CONSTANT.GROUP_NAME_EXISTS,
                });
              }
            }
          } else {
            // Show mess & border
            setEditGroupName({ ...editGroupName, error: true });
          }
        }}
        // Del group
        deleteCurrentGroup={(nameGroup) => {
          // Hide popup
          setShowEditGroupModal(false);
          // Call popup confirm

          Modal.confirm({
            centered: true,
            title: COMMON_CONSTANT.TITLE_DEL_GROUP,
            content: (
              <>
                <span className="bold">{nameGroup}</span>
                {COMMON_CONSTANT.BOM_C033}
              </>
            ),
            okText: lang.OK,
            cancelText: lang.CANCEL,
            onOk: () => {
              // Excute del
              let dataRemove = editGroupModal_Config.funcDel();

              // Remove list pick
              dataRemove.Machines.forEach((m = new classMachine()) => {
                // Update list pick
                MACHINE_PICKED = removeItemArr(MACHINE_PICKED, m.ID);
              });
            },
          });
        }}
      ></EditGroupModal>

      {/* Add group pre check */}
      <AddGroupModal
        showPopup={showAddGroupModalPreCheck}
        newGroupName={newGroupNamePreCheck}
        setNewGroupName={setNewGroupNamePreCheck}
        footer={[
          <Button
            key="back"
            className="buttonPC button--outline --todo--"
            onClick={() => {
              setNewGroupNamePreCheck({
                ...newGroupNamePreCheck,
                value: "",
                error: false,
              });
              setShowAddGroupModalPreCheck(false);
            }}
          >
            <CloseCircleOutlined />
            {COMMON_CONSTANT.CLOSE}
          </Button>,
        ]}
        onCancel={() => {
          // Reset value input
          setNewGroupNamePreCheck({
            ...newGroupNamePreCheck,
            value: "",
            error: false,
          });
          setShowAddGroupModalPreCheck(false);
        }}
        funcCreateGroup={createNewGroupPreCheck}
      ></AddGroupModal>

      {/* Edit group pre check*/}
      <EditGroupModal
        showPopup={showEditGroupModalPreCheck}
        showDelete={GROUP_PRE_CHECK_LIST.length > 1}
        editGroupName={editGroupNamePreCheck}
        setEditGroupName={setEditGroupNamePreCheck}
        dataRow={editGroupModal_ConfigPreCheck.dataRow}
        footer={[
          <Button
            key="back"
            className="buttonPC button--outline --todo--"
            onClick={() => {
              setShowEditGroupModalPreCheck(false);
              setEditGroupNamePreCheck({
                ...editGroupNamePreCheck,
                error: false,
              });
            }}
          >
            <CloseCircleOutlined />
            {COMMON_CONSTANT.CLOSE}
          </Button>,
        ]}
        onCancel={() => {
          setShowEditGroupModalPreCheck(false);
          setEditGroupNamePreCheck({ ...editGroupNamePreCheck, error: false });
        }}
        editCurrentGroup={(dataRow) => {
          let groupId = dataRow.ID;
          // Trim
          editGroupNamePreCheck.value = editGroupNamePreCheck.value.trim();

          const groupName = editGroupNamePreCheck.value;
          if (validateGroup(editGroupNamePreCheck)) {
            if (
              groupName.length > 0
              // && groupName.indexOf("共有") < 0
            ) {
              //check existed
              if (!existedGroupPreCheck(groupName, groupId)) {
                // Update name
                editGroupModal_ConfigPreCheck.funcUpdateName(groupName);
                // Hide popup
                setShowEditGroupModalPreCheck(false);
                // hide message exist
              } else {
                setEditGroupNamePreCheck({
                  ...editGroupNamePreCheck,
                  error: true,
                  message: COMMON_CONSTANT.GROUP_NAME_EXISTS,
                });
              }
            }
          } else {
            // Show mess & border
            setEditGroupNamePreCheck({ ...editGroupNamePreCheck, error: true });
          }
        }}
        // Del group
        deleteCurrentGroup={(nameGroup) => {
          // Hide popup
          setShowEditGroupModalPreCheck(false);
          // Call popup confirm

          Modal.confirm({
            centered: true,
            title: COMMON_CONSTANT.TITLE_DEL_GROUP,
            content: (
              <>
                <span className="bold">{nameGroup}</span>
                {COMMON_CONSTANT.BOM_C033}
              </>
            ),
            okText: lang.OK,
            cancelText: lang.CANCEL,
            onOk: () => {
              // Excute del
              let dataRemove = editGroupModal_ConfigPreCheck.funcDel();
              // Remove list pick
              // dataRemove.Groups.forEach((m = new classMachine()) => {
              //   // Update list pick
              //   MACHINE_PICKED = removeItemArr(MACHINE_PICKED, m.ID);
              // });
            },
          });
        }}
      ></EditGroupModal>
    </div>
  );
}

export default CreateCL;
