import Api from "@src/api";

export default {
  // searchUserRole
  searchUserRole(data) {
    return Api().get("UserRole", { params: data });
  },

  // update Shortcut
  updateRole(data) {
    return Api().post(`UserRole`, data);
  },

  // update Shortcut
  deleteUser(username) {
    return Api().delete(`/UserRole/Users/${username}`);
  },

  // all User AD
  searchUserAD(data) {
    return Api().get(`UserRole/SearchUsers`, { params: data });
  },

  // Add User AD
  addUserAD(data) {
    return Api().post(`UserRole/AddUsers`, data);
  },

  //Request Role
  requestRole(data) {
    return Api().post(`UserRole/RequestRole`, data);
  },
};
