/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import UpdateMaster from "./update";
import classText from "../../models/control/text";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import API from "../../api/backend/inventory";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  MSG_CATCH,
  showMessage,
  statusRes,
  showMessageChange,
} from "../../constants/utils";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";

export default function CategoryUpdate() {
  const { id, id: modeUpdate } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({
    ...new classText(),
    ref: useRef(),
  });
  const [categoryCode, setCategoryCode] = useState(new classText());
  const [note, setNote] = useState(new classText());
  // Common
  const [creator, setCreator] = useState(new classText());
  const [createDate, setCreateDate] = useState(new classText());
  const [updater, setUpdater] = useState(new classText());
  const [updateDate, setUpdateDate] = useState(new classText());
  const [flagChange, setFlagChange] = useState(false);

  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  const items = [
    {
      name: lang.CATEGORY_NAME,
      get: category,
      set: setCategory,
      type: KEY.TEXT,
      required: true,
    },
    ...(modeUpdate
      ? [
          {
            name: lang.CATEGORY_CODE,
            get: categoryCode,
          },
        ]
      : []),
    {
      name: lang.NOTE,
      get: note,
      set: setNote,
      type: KEY.TEXTAREA,
    },
    ...(modeUpdate
      ? [
          {
            name: lang.CREATOR,
            get: creator,
          },
          {
            name: lang.CREATE_DATE,
            get: createDate,
          },
          {
            name: lang.UPDATER,
            get: updater,
          },
          {
            name: lang.UPDATE_DATE,
            get: updateDate,
          },
        ]
      : []),
  ];

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      setLoading(true);

      Promise.all([modeUpdate && getDetail()])
        .then((res) => {
          // Empty
        })
        .catch((err) => {
          history.push("/CategoryMaster");
        })
        .then(() => {
          setLoading(false);
        });
    }

    return () => {
      // cleanup;
    };
  }, [allowRoute]);

  const getDetail = () => {
    return API.detailCategory(id).then((res) => {
      if (statusRes(res)) {
        const data = res.data;
        setCategory((preV) => ({ ...preV, value: data.categoryName }));
        setCategoryCode((preV) => ({ ...preV, value: data.categoryCode }));
        setNote((preV) => ({ ...preV, value: data.remarks }));
        //
        setCreator((preV) => ({ ...preV, value: data.creater }));
        setCreateDate((preV) => ({ ...preV, value: data.createDate }));
        setUpdater((preV) => ({ ...preV, value: data.updater }));
        setUpdateDate((preV) => ({ ...preV, value: data.updateDate }));
      } else {
        throw new Error();
      }
    });
  };

  const onSave = () => {
    setLoading(true);

    (modeUpdate ? API.updateCategory : API.createCategory)({
      categoryCode: categoryCode.value,
      categoryName: category.value,
      remarks: note.value,
    })
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
            history.goBack();
          });
        } else {
          showMessage(KEY.ERROR, (res && res.data.msg) || MSG_CATCH());
        }
      })
      .catch((err) => {
        showMessage(
          KEY.ERROR,
          (err.response && err.response.data.msg) || MSG_CATCH()
        );
      })
      .then(() => {
        setLoading(false);
      });
  };
  const onBack = () => {
    if (flagChange) {
      showMessageChange(history.goBack, COMMON_CONSTANT.BOM_C018);
    } else {
      history.goBack();
    }
  };

  return (
    <UpdateMaster
      flagChange={[flagChange, setFlagChange]}
      name={lang.CATEGORY_MASTER}
      items={items}
      onSave={onSave}
      onBack={onBack}
      loading={loading}
    />
  );
}
