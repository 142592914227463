import React, { useState, useRef, useEffect } from "react";
import "@src/page/Registration/registration.scss";
import { Row, Col, Button, Input, Table, Select, DatePicker, Tooltip } from "antd";
import BoxContent from "@src/component/Desktop/content";
import { useSelector } from "react-redux";
import { lang, COMMON_CONSTANT, STYLE, KEY } from "@src/constants/common.const";
import {
  SaveOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  renderOption,
  MSG_CATCH,
  showMessage,
  showMessageChange,
} from "../../constants/utils";
import "./machine.scss";
import KBN from "../../api/backend/kbn";
import API from "../../api/backend/machineManagement";
import moment from 'moment';
import { isEmpty, isNumber, isObject, isString } from 'lodash';
import { checkRoleScreen } from "../../utils/roles";
import * as XLSX from 'xlsx/xlsx.mjs';
import * as fs from 'fs';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
import { Readable } from 'stream';
import { useHistory, useLocation } from "react-router";
import TitlePage from "../../component/Desktop/titlePage";

XLSX.set_fs(fs);
XLSX.stream.set_readable(Readable);
XLSX.set_cptable(cpexcel);

const filterEmptyDataSourceXlSX = (dataSource) => {
  return !Object.values({ ...dataSource, key: null, id: null }).every(value => !value || isEmpty(value));
};

const filterEmptyDataSource = (dataSource) => {
  return !Object.values({ ...dataSource, key: { value: null } }).every(item => !item.value || isEmpty(item.value));
};


const MACHINE_TABLE = [
  {
    title: lang.MACHINE_NAME,
    type: KEY.TEXT,
    required: true,
  },
  {
    title: lang.NAME_FACILITY,
    type: KEY.DDL,
    required: true,
  },
  {
    title: lang.NAME_BUILDING,
    type: KEY.DDL,
  },
  {
    title: lang.NAME_FLOOR,
    type: KEY.DDL,
  },
  {
    title: lang.NAME_ROOM,
    type: KEY.DDL,
  },
  {
    title: lang.NAME_PROCESS,
    type: KEY.DDL,
    required: true,
  },
  {
    title: lang.MACHINE_MANUFACTURE_NAME,
    type: KEY.TEXT,
  },
  {
    title: lang.MODEL,
    type: KEY.TEXT,
    required: true,
  },
  {
    title: lang.SERI_NUMBER,
    type: KEY.TEXT,
  },
  {
    title: lang.STATUS_MACHINE,
    type: KEY.DDL,
  },
  {
    title: lang.SET_TIME,
    type: KEY.DATEPICKER,
  },
  {
    title: lang.REPLACEMENT_DISPOSAL_YEAR,
    type: KEY.DATEPICKER,
  },
  {
    title: lang.SPECIFICATIONS,
    type: KEY.TEXTAREA,
  },
  {
    title: lang.SERVICE_LIFE,
    type: KEY.NUMBER,
  },
  {
    title: lang.YEAR_OF_MANUFACTURE,
    type: KEY.NUMBER,
  },
  {
    title: lang.SERVICE_LIFE_IN_HOUSE,
    type: KEY.NUMBER,
  },
  {
    title: lang.DUABILITY,
    type: KEY.NUMBER,
  },
  {
    title: lang.DRAWING_NUMBER,
    type: KEY.TEXT,
  },
  {
    title: lang.MADE,
    type: KEY.TEXT,
  },
  {
    title: lang.USE,
    type: KEY.TEXT,
  },
  {
    title: lang.CONDITION,
    type: KEY.TEXT,
  },
  {
    title: lang.REMARK,
    type: KEY.TEXTAREA,
  },
];

const ERROR = {
  EMPTY: 'この項目が空白できません。',
  DUPLICATE: 'この項目が重複しています。',
}

function MachineRegistration() {
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();

  const fileElUpload = useRef(null);
  const [listMachine, setListMachine] = useState([]);
  const [nameCount, setNameCount] = useState({});

  const onChangeMaintaince = (key, nameColums, newValue) => {
    const index = listMachine.findIndex((item) => item.key === key);
    const nameObj = MACHINE_TABLE.find(x => x.title === nameColums);
    const newObject = {
      [nameColums]: {
        ...listMachine[index][nameColums],
        value: newValue,
        // err: newValue ? null : listMachine[index][nameColums].err,
        err: nameObj.required && !newValue ? ERROR.EMPTY : null,
      },
    };

    const newDataSource = [
      ...listMachine.slice(0, index),
      { ...listMachine[index], ...newObject },
      ...listMachine.slice(index + 1),
    ];
    setListMachine(newDataSource);
  };

  const handleDeleteRowMaintaince = (key) => {
    const newListAttachMachine = listMachine.filter((item) => {
      return item.key !== key;
    });
    setListMachine(newListAttachMachine);
  };

  const column_machine = [
    {
      title: " ",
      dataIndex: "delete",
      width: 55,
      render: (_, row) => {
        return (
          <Button
            style={{ background: "#f7f9fc" }}
            onClick={() => handleDeleteRowMaintaince(row.key)}
            icon={<DeleteOutlined />}
          />
        );
      },
    },
    ...MACHINE_TABLE.map(item => {
      let render = (_, row) => {
        let textErr = _.err
        if (item.title === lang.MACHINE_NAME && _ && nameCount[_.value] > 1) {
          textErr = ERROR.DUPLICATE;
        }

        return (<>
          <Tooltip title={textErr}>
            <Input
              value={_.value}
              className={textErr ? STYLE.BORDER_RED : ""}
              onChange={(e) =>
                onChangeMaintaince(row.key, item.title, e.target.value)
              }
            />
          </Tooltip>
        </>
        );
      };

      if (item.type === KEY.DATEPICKER) {
        render = (_, row) => {
          return (
            <DatePicker
              value={_.value && moment(_.value).isValid() ? moment(_.value) : null}
              className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
              placeholder={""}
              onChange={(v) => {
                onChangeMaintaince(row.key, item.title, v)
              }}
            />
          );
        };
      };

      if (item.type === KEY.TEXTAREA) {
        render = (_, row) => {
          return (
            <TextArea
              className={_.err ? STYLE.BORDER_RED : ""}
              rows={1}
              value={_.value}
              onChange={(e) =>
                onChangeMaintaince(row.key, item.title, e.target.value)}
            />
          );
        };
      };

      if (item.type === KEY.DDL) {
        render = (_, row) => {
          let this_facility = null,
            this_building = null,
            this_floor = null,
            this_room = null,
            this_process = null;
          if (!isEmpty(list_facility)) {
            this_facility = list_facility.find(x => x.facilityCode == row[lang.NAME_FACILITY].value);
          }
          if (this_facility) {
            this_building = this_facility.buildings.find(x => x.buildingCode == row[lang.NAME_BUILDING].value);
            this_process = this_facility.processes.find(x => x.id == row[lang.NAME_PROCESS].value);
          };
          if (this_building) {
            this_floor = this_building.floors.find(x => x.floorCode == row[lang.NAME_FLOOR].value);
          };
          if (this_floor) {
            this_room = this_floor.rooms.find(x => x.roomCode == row[lang.NAME_ROOM].value);
          };

          if (item.title === lang.NAME_FACILITY) {
            return <Tooltip title={_.err}>
              <Select
                value={_.value}
                className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
                onChange={(v) => onChangeMaintaince(row.key, item.title, v)}
              >
                {
                  renderOption(list_facility.map(x => (
                    {
                      key: x.facilityCode,
                      value: x.facilityName,
                    }
                  )))
                }
              </Select>
            </Tooltip>
          }

          if (item.title === lang.NAME_BUILDING) {
            return <Tooltip title={_.err}>
              <Select
                value={this_building ? _.value : null}
                className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
                disabled={!this_facility}
                onChange={(v) => onChangeMaintaince(row.key, item.title, v)}
              >
                {
                  this_facility && renderOption(this_facility.buildings.map(x => (
                    {
                      key: x.buildingCode,
                      value: x.buildingName,
                    }
                  )))
                }
              </Select>
            </Tooltip>
          }

          if (item.title === lang.NAME_FLOOR) {
            return <Tooltip title={_.err}>
              <Select
                value={this_floor ? _.value : null}
                className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
                disabled={!this_building}
                onChange={(v) => onChangeMaintaince(row.key, item.title, v)}
              >
                {
                  this_building && renderOption(this_building.floors.map(x => (
                    {
                      key: x.floorCode,
                      value: x.floorName,
                    }
                  )))
                }
              </Select>
            </Tooltip>
          }

          if (item.title === lang.NAME_ROOM) {
            return <Tooltip title={_.err}>
              <Select
                value={this_room ? _.value : null}
                className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
                disabled={!this_floor}
                onChange={(v) => onChangeMaintaince(row.key, item.title, v)}
              >
                {
                  this_floor && renderOption(this_floor.rooms.map(x => (
                    {
                      key: x.roomCode,
                      value: x.roomName,
                    }
                  )))
                }
              </Select>
            </Tooltip>
          }

          if (item.title === lang.NAME_PROCESS) {
            return <Tooltip title={_.err}>
              <Select
                value={this_process ? _.value : null}
                className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
                disabled={!this_facility}
                onChange={(v) => onChangeMaintaince(row.key, item.title, v)}
              >
                {
                  this_facility && renderOption(this_facility.processes.map(x => (
                    {
                      key: x.id,
                      value: x.processName,
                    }
                  )))
                }
              </Select>
            </Tooltip>
          }

          if (item.title === lang.STATUS_MACHINE) {
            const this_status = lang.MACHINE_STATUS_VALUE.find(x => x.key == row[lang.STATUS_MACHINE].value);
            return <Select
              value={this_status ? _.value : null}
              className={_.err ? STYLE.BORDER_RED + " w100" : "w100"}
              onChange={(v) => onChangeMaintaince(row.key, item.title, v)}
            >
              {
                renderOption(lang.MACHINE_STATUS_VALUE)
              }
            </Select>
          }
        };
      };

      return {
        title: <>{item.title}{item.required && <label className="blod ml5 mark-required">*</label>}</>,
        key: item.title,
        dataIndex: item.title,
        render: render,
        width: '180px'
      };
    }),
  ];

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const arrayData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        //Check empty
        if (isEmpty(json)) return;

        //Check header
        const isMapKey = MACHINE_TABLE.every(item => {
          const name = item.title;
          return arrayData[0].some(nameTemplate => {
            return `${nameTemplate}`.indexOf(name) >= 0;
          });
        });

        if (!isMapKey) {
          showMessage(KEY.ERROR, '入力ファイルのフォーマットが間違っているので、確認してください。');
          return;
        }

        const newListAttachMachine = json
          .filter(filterEmptyDataSourceXlSX)
          .map((item, index) => {
            const newItem = { key: '' + Date.now() + index };

            arrayData[0].forEach(name => {
              const newName = name.split('\r\n')[0];
              const nameObj = MACHINE_TABLE.find(x => x.title === newName);
              newItem[newName] = {
                value: item[name],
                err: nameObj.required && !item[name] ? ERROR.EMPTY : null,
              };
            });

            let this_facility, this_building, this_floor, this_room, this_process;
            if (!isEmpty(list_facility)) {
              this_facility = list_facility.find(x => x.facilityName === newItem[lang.NAME_FACILITY].value);
            }
            if (this_facility) {
              this_building = this_facility.buildings.find(x => x.buildingName == newItem[lang.NAME_BUILDING].value);
              this_process = this_facility.processes.find(x => x.processName == newItem[lang.NAME_PROCESS].value);
            };
            if (this_building) {
              this_floor = this_building.floors.find(x => x.floorName == newItem[lang.NAME_FLOOR].value);
            };
            if (this_floor) {
              this_room = this_floor.rooms.find(x => x.roomName == newItem[lang.NAME_ROOM].value);
            };
            const this_status = lang.MACHINE_STATUS_VALUE.find(x => x.value === newItem[lang.STATUS_MACHINE].value);

            return {
              ...newItem,
              [lang.NAME_FACILITY]: {
                ...newItem[lang.NAME_FACILITY],
                value: this_facility ? this_facility.facilityCode : null,
                err: this_facility ? null : ERROR.EMPTY
              },
              [lang.NAME_BUILDING]: {
                ...newItem[lang.NAME_BUILDING],
                value: this_building ? this_building.buildingCode : null
              },
              [lang.NAME_FLOOR]: {
                ...newItem[lang.NAME_FLOOR],
                value: this_floor ? this_floor.floorCode : null
              },
              [lang.NAME_ROOM]: {
                ...newItem[lang.NAME_ROOM],
                value: this_room ? this_room.roomCode : null
              },
              [lang.NAME_PROCESS]: {
                ...newItem[lang.NAME_PROCESS],
                value: this_process ? this_process.id : null,
                err: this_process ? null : ERROR.EMPTY
              },
              [lang.STATUS_MACHINE]: {
                ...newItem[lang.STATUS_MACHINE],
                value: this_status ? this_status.key : null
              },
            };
          });

        setListMachine([...listMachine.filter(filterEmptyDataSource)].concat(newListAttachMachine));
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    fileElUpload.current.value = null;
  }

  const handleAddRowMaintaince = () => {
    const newObj = {
      value: null,
      err: false
    }
    const newRow = {
      key: Date.now(),
    }

    MACHINE_TABLE.forEach(item => {
      const name = item.title;
      newRow[name] = { ...newObj };
    });

    setListMachine([...listMachine, newRow]);
  };

  const validate = () => {
    let flagErr = false;
    const newListMachine = listMachine.filter(filterEmptyDataSource);
    if (isEmpty(newListMachine)) flagErr = true;

    newListMachine.forEach((item) => {
      //Check empty
      MACHINE_TABLE.forEach(label => {
        if (!label.required) return;
        if (item[label.title].value === '' || item[label.title].value === null || item[label.title].value === undefined) {
          item[label.title].err = ERROR.EMPTY;
          flagErr = true;
        }
      });
    });

    setListMachine(newListMachine);
    return !flagErr;
  }

  const submit = async () => {
    if (!validate()) {
      showMessage(KEY.ERROR, "データが間違っていますので、確認してください。");
      return;
    }
    const body = listMachine.filter(filterEmptyDataSource).map(item => {
      return {
        MachineForSiteOfficeName: item[lang.MACHINE_NAME].value,
        FacilityCode: item[lang.NAME_FACILITY].value,
        BuildingCode: item[lang.NAME_BUILDING].value,
        FloorCode: item[lang.NAME_FLOOR].value,
        RoomCode: item[lang.NAME_ROOM].value,
        ProcessId: item[lang.NAME_PROCESS].value,
        ManufactureName: item[lang.MACHINE_MANUFACTURE_NAME].value,
        ModelNumber: item[lang.MODEL].value,
        Sn: item[lang.SERI_NUMBER].value,
        Status: item[lang.STATUS_MACHINE].value,
        ApplyDate: item[lang.SET_TIME].value && moment(item[lang.SET_TIME].value).isValid() ? moment(item[lang.SET_TIME].value).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
        Specification: item[lang.SPECIFICATIONS].value,
        ServiceLife: item[lang.SERVICE_LIFE].value ? +item[lang.SERVICE_LIFE].value : null,
        DrawingLineItemNumber: item[lang.DRAWING_NUMBER].value,
        Saiban: item[lang.MADE].value,
        Use: item[lang.USE].value,
        EnduranceYear: item[lang.SERVICE_LIFE_IN_HOUSE].value ? +item[lang.SERVICE_LIFE_IN_HOUSE].value : null,
        YearOfManufacture: item[lang.YEAR_OF_MANUFACTURE].value ? +item[lang.YEAR_OF_MANUFACTURE].value : null,
        ServiceTime: item[lang.DUABILITY].value ? +item[lang.DUABILITY].value : null,
        ReplacementDate: item[lang.REPLACEMENT_DISPOSAL_YEAR].value && moment(item[lang.REPLACEMENT_DISPOSAL_YEAR].value).isValid() ? moment(item[lang.REPLACEMENT_DISPOSAL_YEAR].value).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
        Condition: item[lang.CONDITION].value,
        Note: item[lang.REMARK].value,
        CheckItems: [],
        ImageUrl: '',
        ListMaintenance: [],
        ListAttachment: [],
      };
    });
    const formData = new FormData();
    showMessageChange(async () => {
      try {
        formData.append("data", JSON.stringify(body));
        const res = await API.saveMulti(formData);
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, () => {
          history.push('/machineManagement');
        });
      } catch (err) {
        let msg = MSG_CATCH();
        if (!isEmpty(err.response) && isString(err.response.data) && !isEmpty(err.response.data)) {
          msg = err.response.data;
        }
        if (!isEmpty(err.response) && isObject(err.response.data) && isString(err.response.data.msg)) {
          msg = err.response.data.msg;
        }
        showMessage(KEY.ERROR, msg);
      }
    }, COMMON_CONSTANT.BOM_C008);
  };

  useEffect(() => {
    const nameCountTemp = {};
    const newListMachine = listMachine.filter(filterEmptyDataSource);
    newListMachine.forEach((item) => {
      const machineName = item[lang.MACHINE_NAME].value;
      if (!machineName) return;
      nameCountTemp[machineName] = (nameCountTemp[machineName] || 0) + 1;
    });

    setNameCount(nameCountTemp);
  }, [listMachine]);

  const [list_facility, set_list_facility] = useState([]);
  const getDetailFacility = async (code, list) => {
    try {
      const res = await KBN.getDetailFacility({
        facilityCode: code,
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getFacility = async () => {
    try {
      const res = await KBN.getFacility();
      const list_facility = !isEmpty(res.data) ? res.data : [];

      const promises = list_facility.map(facility => {
        return getDetailFacility(facility.facilityCode, list_facility);
      });

      const full_data_facility = await Promise.all(promises);
      const res_process = await KBN.searchProcess({});
      const list_process = res_process.data || [];
      full_data_facility.forEach(x => {
        x.processes = list_process.filter(y => y.facilityCode == x.facilityCode);
      });
      set_list_facility(full_data_facility);
    } catch (err) {
      throw err;
    }
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      try {
        Promise.all([
          getFacility(),
        ]);
      } catch (e) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    }
  }, [allowRoute]);

  return (
    <div className="des-content machine-content">
      <TitlePage name={lang.MACHINE_IMPORT} />

      <BoxContent>
        <Row className="rowNotMargin">
          <Col span={24}>
            <div>
              <Button
                style={{ background: "#f7f9fc", width: 'auto', minWidth: 'auto' }}
                className="pr10 pl10 mb8 ml10"
                onClick={() => handleAddRowMaintaince()}
                icon={<PlusOutlined />}
              ></Button>
              <Button
                style={{ background: "#f7f9fc", width: 'auto', minWidth: 'auto', color: '#0080cb' }}
                className="pr10 pl10 mt4 mb4 ml10"
                onClick={() => {
                  fileElUpload.current.click();
                }}
                icon={<CloudUploadOutlined
                  style={{
                    color: "#f50",
                  }}
                />}
              >
                機器台帳入力
              </Button>
              <ButtonTemplate />
              <input
                type="file"
                className="d-none"
                ref={fileElUpload}
                onChange={readUploadFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
            <Table
              scroll={isEmpty(listMachine) ? { x: 1300 } : { x: 1300, y: '70vh' }}
              locale={{ emptyText: "" }}
              pagination={false}
              dataSource={listMachine}
              className="tableAddMachine"
              columns={column_machine}
            />
          </Col>
          <Col span={24}>
            <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
              <Col flex="auto" />
              <Col flex="none">
                <Button
                  className="buttonPC button--info"
                  onClick={submit}
                  icon={<SaveOutlined />}
                >
                  {COMMON_CONSTANT.SAVE}
                </Button>
              </Col>
              <Col flex="none" style={{ paddingRight: 0 }}>
                <Button
                  className="buttonPC button--outline"
                  onClick={() => history.push('/machineManagement')}
                  icon={<CloseCircleOutlined />}
                >
                  {lang.CANCEL}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </BoxContent>
    </div>
  );
};

function ButtonTemplate() {
  const [urlTemplate, setUrlTemplates] = useState(null);
  useEffect(() => {
    const getUrlTemplate = async () => {
      const res = await API.downloadTemplateImportMachine();
      setUrlTemplates(res.data);
    }
    getUrlTemplate();
  }, []);
  return <a href={urlTemplate} className="ml4" >
    <Button
      disabled={!urlTemplate}
      style={{ background: "#f7f9fc", width: 'auto', minWidth: 'auto', color: '#0080cb' }}
      className="pr10 pl10 mt4 mb4 ml10"
      icon={<CloudDownloadOutlined
        style={{
          color: "#f50",
        }}
      />}
    >
      {lang.MAINTAINCE_TEMPLATE}
    </Button>
  </a>;
};

export default MachineRegistration;
