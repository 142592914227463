/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tag } from "antd";
import {
  Col,
  Row,
  Form,
  Select,
  Table,
  Space,
  DatePicker,
  Input,
  Button,
  Tooltip,
} from "antd";
import {
  lang,
  KEY,
  STYLE,
  COMMON_CONSTANT,
} from "../../constants/common.const";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/inventory";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import KBN from "../../api/backend/kbn";
import { useHistory, useLocation } from "react-router";
import {
  MSG_CATCH,
  enterSearch,
  renderOption,
  statusRes,
  showMessage,
  isEmpty,
  INPUT_DATE,
  showMessageChange,
  getQuerySearch,
  pushObject2Query,
} from "../../constants/utils";
import InfiniteScroll from "react-infinite-scroller";
// Class
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
// Icon
import {
  SearchOutlined,
  ClearOutlined,
  EditFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import LoadingInline from "../../component/Popup/loading-inline";
import { useSelector } from "react-redux";
import { checkRoleScreen } from "../../utils/roles";
import moment from "moment";

export default function InventoryImport({ modeExport = false }) {
  const history = new useHistory();
  const objSearch = getQuerySearch();
  // Loading
  let [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  // Input search
  // const searchParams = {
  //   transactionType: modeExport ? 2 : 1,
  //   inventoryTransactionCode: isEmpty(txtTradingCode.value)
  //     ? null
  //     : txtTradingCode.value.trim(),
  //   performer: isEmpty(txtCreater.value) ? null : txtCreater.value.trim(),
  //   transactionTimeStart: getStartDateString(),
  //   transactionTimeEnd: getEndDateString(),
  //   inventoryWarehouseCode: ddlStorage.value,
  //   categoryName: isEmpty(category.value)
  //     ? null
  //     : category.options.find((x) => x.key === category.value)
  //     ? category.options.find((x) => x.key === category.value).value
  //     : null,
  //   inventoryItemName: isEmpty(txtItem.value) ? null : txtItem.value.trim(),
  // };
  const [initRender, setInitRender] = useState(true);
  let [txtTradingCode, setTxtTradingCode] = useState(
    new classText(objSearch.inventoryTransactionCode || null)
  );
  let [txtCreater, setTxtCreater] = useState(
    new classText(objSearch.performer || null)
  );
  let [txtStartDate, settxtStartDate] = useState(
    new classText(
      objSearch.transactionTimeStart
        ? moment(objSearch.transactionTimeStart)
        : null
    )
  );
  let [txtEndDate, settxtEndDate] = useState(
    new classText(
      objSearch.transactionTimeEnd ? moment(objSearch.transactionTimeEnd) : null
    )
  );
  let [ddlStorage, setDdlStorage] = useState(
    new classDdl(objSearch.inventoryWarehouseCode || null)
  );
  let [category, setCategory] = useState(
    new classDdl(objSearch.categoryName || null)
  );
  let [txtItem, setTxtItem] = useState(
    new classText(objSearch.inventoryItemName || "")
  );
  const { allowRoute } = useSelector((state) => state.auth);
  const location = useLocation();

  //  Grid
  let [grid, setGrid] = useState(new classGrid());

  // Column GRID
  const initColumns = [
    {
      title: lang.TRADING_CODE,
      dataIndex: "inventoryTransactionCode",
      width: "15%",
      render: (txt, row) => {
        return (
          <div>
            <Link
              className="mr5"
              to={
                (modeExport
                  ? "/ExportInventoryUpdate/"
                  : "/ImportInventoryUpdate/") + txt
              }
            >
              {txt}
            </Link>
            {row.isNew && <Tag color="magenta">{lang.NEW}</Tag>}
          </div>
        );
      },
    },
    {
      title: modeExport ? lang.EXPORTER : lang.IMPORTER,
      width: "15%",
      dataIndex: "performer",
      align: "center",
    },
    {
      title: modeExport ? lang.EXPORT_DATE : lang.IMPORT_DATE,
      dataIndex: "transactionTime",
      width: "20%",
      align: "center",
    },
    {
      title: lang.ACTION,
      align: "center",
      width: "15%",
      render: (row) => {
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.EDIT}>
              <Button disabled={!row.canEdit} className="mr5 btnEdit">
                <Link
                  to={
                    (modeExport
                      ? "/ExportInventoryUpdate/"
                      : "/ImportInventoryUpdate/") +
                    row.inventoryTransactionCode +
                    "/update"
                  }
                  className="w100"
                >
                  <EditFilled className="" />
                </Link>
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                disabled={!row.canEdit}
                className="mr5 btnDelete"
                onClick={() => {
                  onDelete(row);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const onDelete = (data) => {
    if (!data.canEdit) return;
    showMessageChange(async () => {
      openLoadingSpinner();
      // API Del
      try {
        // Del
        await API.deleteTransaction(data.inventoryTransactionCode);
        // API Search
        await search_onClick();
        // Open success and go to list screen
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (err) {
        let msg = "";
        if (err.response && err.response.status === 400 && err.response.data) {
          msg = err.response.data.msg;
        } else {
          msg = MSG_CATCH();
        }
        showMessage(KEY.ERROR, msg);
      }
      closeLoadingSpinner();
    }, COMMON_CONSTANT.BOM_C001);
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const getStartDateString = () => {
    return txtStartDate.value
      ? txtStartDate.value.format(KEY.DATE_DEFAULT)
      : null;
  };
  const getEndDateString = () => {
    return txtEndDate.value ? txtEndDate.value.format(KEY.DATE_DEFAULT) : null;
  };
  const getCategory = () => {
    return KBN.getCategories()
      .then((res) => {
        if (statusRes(res)) {
          setCategory({
            ...category,
            value: initRender ? category.value : null,
            options: res.data.data.map((v) => {
              v["key"] = v.categoryName;
              v["value"] = v.categoryName;
              return v;
            }),
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  // Params Search
  const searchParams = {
    transactionType: modeExport ? 2 : 1,
    inventoryTransactionCode: isEmpty(txtTradingCode.value)
      ? null
      : txtTradingCode.value.trim(),
    performer: isEmpty(txtCreater.value) ? null : txtCreater.value.trim(),
    transactionTimeStart: getStartDateString(),
    transactionTimeEnd: getEndDateString(),
    inventoryWarehouseCode: ddlStorage.value,
    categoryName: category.value || null,
    inventoryItemName: isEmpty(txtItem.value) ? null : txtItem.value.trim(),
  };
  // Search
  const search_onClick = (offset = 0) => {
    // Loading
    setGrid({ ...grid, loading: true });

    // Call
    return API.search({
      ...searchParams,
      limit: 20,
      offset: offset,
    })
      .then((res) => {
        if (statusRes(res)) {
          const { data, count } = res.data;
          if (offset === 0) {
            setGrid((preV) => ({
              ...preV,
              data: data,
              count: count,
              hasMore: true,
              loading: false,
            }));
          } else {
            setGrid((preV) => ({
              ...preV,
              data: [...preV.data, ...data],
              count: count,
              loading: false,
            }));
          }
          pushObject2Query(searchParams);
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessageError(MSG_CATCH());
      });
  };

  const onLoadMore = () => {
    let { data, count } = grid;

    if (data.length >= count) {
      // max
      setGrid({ ...grid, loading: false, hasMore: false });
    } else {
      // load more
      search_onClick(data.length);
    }
  };

  // clearInputSearch
  const clearInputSearch = () => {
    const reset = (preV) => ({ ...preV, value: null });
    setTxtTradingCode(reset);
    setTxtCreater(reset);
    settxtStartDate(reset);
    settxtEndDate(reset);
    setDdlStorage(reset);
    setCategory(reset);
    setTxtItem(reset);
    formSearch.resetFields();
    formSearch.submit();
  };

  const renderDdlStorage = () => {
    return KBN.getStorage().then((res) => {
      setDdlStorage({
        ...ddlStorage,
        options: res.data.data,
        value: initRender ? ddlStorage.value : null,
      });
    });
  };

  // Init
  useEffect(() => {
    if (checkRoleScreen(location.pathname, allowRoute)) {
      if (initRender) {
        setInitRender(false);
      }
      // Open loading
      openLoadingSpinner();

      // Load pulldown
      Promise.all([
        initRender ? search_onClick() : clearInputSearch(),
        renderDdlStorage(),
        getCategory(),
      ])
        .catch((err) => {
          showMessageError(MSG_CATCH());
        })
        .then(() => {
          // Close loading
          closeLoadingSpinner();
        });
    }
  }, [allowRoute]);

  // Render
  return (
    <div className="des-content">
      <TitlePage
        name={modeExport ? lang.EXPORT_INVENTORY : lang.IMPORT_INVENTORY}
      />
      {/* Group input */}
      <GroupItem>
        <Form
          layout={"vertical"}
          name="searchCL"
          className="formStyle"
          form={formSearch}
          onFinish={() => {
            search_onClick();
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span="8">
              <Form.Item
                name="TRADING_CODE"
                className="rowNotMargin"
                label={lang.TRADING_CODE}
              >
                <Input
                  defaultValue={txtTradingCode.value}
                  placeholder={lang.TRADING_CODE}
                  value={txtTradingCode.value}
                  maxLength={12}
                  onChange={(e) => {
                    setTxtTradingCode({
                      ...txtTradingCode,
                      value: e.target.value,
                    });
                  }}
                  {...enterSearch(search_onClick)}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                name="txtStartDate"
                className="rowNotMargin"
                label={modeExport ? lang.EXPORT_DATE : lang.IMPORT_DATE}
              >
                <Row>
                  <Col span={11}>
                    <DatePicker
                      defaultValue={
                        objSearch.transactionTimeStart
                          ? moment(objSearch.transactionTimeStart)
                          : null
                      }
                      disabledDate={(currentDate) => {
                        return (
                          txtEndDate.value &&
                          txtEndDate.value.valueOf() < currentDate.valueOf()
                        );
                      }}
                      format={KEY.DATE_DEFAULT}
                      value={txtStartDate.value}
                      placeholder={KEY.DATE_DEFAULT}
                      className={
                        "w100 " + (txtStartDate.error ? STYLE.BORDER_RED : "")
                      }
                      onChange={(v, dateString) => {
                        settxtStartDate({
                          ...txtStartDate,
                          value: v,
                        });
                      }}
                      {...INPUT_DATE}
                    />
                  </Col>
                  <Col span={2} className="center" style={{ padding: "7px" }}>
                    ～
                  </Col>
                  <Col span={11}>
                    <DatePicker
                      defaultValue={txtEndDate.value}
                      disabledDate={(currentDate) => {
                        return (
                          txtStartDate.value &&
                          txtStartDate.value.valueOf() > currentDate.valueOf()
                        );
                      }}
                      input={INPUT_DATE}
                      format={KEY.DATE_DEFAULT}
                      value={txtEndDate.value}
                      placeholder={KEY.DATE_DEFAULT}
                      className={
                        "w100 " + (txtEndDate.error ? STYLE.BORDER_RED : "")
                      }
                      onChange={(v, dateString) => {
                        settxtEndDate({ ...txtEndDate, value: v });
                      }}
                      {...INPUT_DATE}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                name="IMPORTER"
                className="rowNotMargin"
                label={modeExport ? lang.EXPORTER : lang.IMPORTER}
              >
                <Input
                  defaultValue={txtCreater.value}
                  placeholder={modeExport ? lang.EXPORTER : lang.IMPORTER}
                  value={txtCreater.value}
                  maxLength={255}
                  onChange={(e) => {
                    setTxtCreater({ ...txtCreater, value: e.target.value });
                  }}
                  {...enterSearch(search_onClick)}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                name="STORAGE"
                className="rowNotMargin"
                label={lang.STORAGE}
              >
                <Select
                  defaultValue={ddlStorage.value}
                  placeholder={lang.STORAGE}
                  value={ddlStorage.value}
                  className={ddlStorage.error ? STYLE.BORDER_RED : ""}
                  onChange={(v) => {
                    setDdlStorage({ ...ddlStorage, value: v });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlStorage.options)}
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                name="CATEGORY"
                label={lang.CATEGORY}
                className="rowNotMargin"
              >
                <Select
                  defaultValue={category.value}
                  placeholder={lang.CATEGORY}
                  value={category.value}
                  className={category.error ? STYLE.BORDER_RED : ""}
                  onChange={(v) => {
                    setCategory({ ...category, value: v });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(category.options)}
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item name="ITEM" className="rowNotMargin" label={lang.ITEM}>
                <Input
                  defaultValue={txtItem.value}
                  maxLength={255}
                  placeholder={lang.ITEM}
                  value={txtItem.value}
                  onChange={(e) => {
                    setTxtItem({ ...txtItem, value: e.target.value });
                  }}
                  {...enterSearch(search_onClick)}
                />
              </Form.Item>
            </Col>
            {/* Button Search/Clear */}
            <Col span={24} className="right">
              <Button
                className="buttonPC button--info wAuto"
                onClick={() => {
                  search_onClick();
                }}
              >
                <SearchOutlined className="" />
                {lang.SEARCH}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={clearInputSearch}
              >
                <ClearOutlined />
                {lang.CLEAR}
              </Button>
            </Col>
          </Row>
        </Form>
      </GroupItem>
      <GroupItem>
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            <Button
              type="link"
              onClick={() => {
                history.push(
                  modeExport
                    ? "/ExportInventoryUpdate"
                    : "/ImportInventoryUpdate"
                );
              }}
              className="btnAddRecord"
            >
              <AddIcon />
              {modeExport
                ? lang.EXPORT_INVENTORY_ADD
                : lang.IMPORT_INVENTORY_ADD}
            </Button>
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count || 0}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={!grid.loading && grid.hasMore}
          useWindow={true}
        >
          <Table
            rowKey="inventoryTransactionCode"
            className="gridSearch tblRole mt10"
            columns={initColumns}
            dataSource={grid.data}
            pagination={false}
            loading={grid.loading}
            scroll={{ x: "auto" }}
          />
          <Col span={24} className="mt10 content-center">
            <LoadingInline isLoadingInline={grid.loading} />
          </Col>
        </InfiniteScroll>
      </GroupItem>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
